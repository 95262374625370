import React, { useEffect, useState } from 'react';
import UserService from '../rest-services/UserServices';

export default function HoverButton({ onClick, title, 
  icons,
  className }) {

    
      const [rolesPermissions, setRolesPermissions] = useState([]);
        const [assignee, setAssignee] = useState({ id: "", username: "" });
        const iconId = parseInt(icons, 10);
        
        console.log("ICOn id is",iconId);
    
      console.log("Check user permissions", rolesPermissions);

      const hasPermissionForSaveANDContinue = iconId === 108 && rolesPermissions.some(
        (permission) => permission.permissionsId === 18 && permission.iconId === iconId
      );
      console.log("Check user permissions to user save&CONTINUE 18", hasPermissionForSaveANDContinue);


      useEffect(() => {
        fetchAssignee();
      }, []);
  
  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          clientId: userDetail.clientId || "",
          id: userDetail.id || "",
          assigner: userDetail.username || "",
          userId: userDetail.userId || "",
        };
        setAssignee(assigneeData);
      } else {
      }
    } catch (error) { }
  };
  
      useEffect(() => {
        if (assignee.userId) {
          fetchUserPermissionDetails();
        }
      }, [assignee.userId]);
  
    const fetchUserPermissionDetails = async () => {
      try {
        const data = { userId: assignee.userId };
        console.log("Request Data:", data);
  
        const response = await UserService.saveContactDetails(
          `${process.env.REACT_APP_BASE_URL}/get-users-permissions-by-userId`,
          data
        );
        console.log("user permissions details", response);
        const responseData = response.data;
        setRolesPermissions(responseData.rolesPermissionsTables || []); // Save permission details
        console.log("Set Permission details for bam screen", responseData.rolesPermissionsTables);
  
  
      } catch (error) {
        console.error(error.message); // Set error message
      } 
    };

    
  return (
    // <button
    //   onClick={onClick}
    //   className={`bg-white text-sm border border-customBlue text-customBlue py-1 px-2 rounded hover:bg-customBlue hover:text-white transition duration-200 ease-in-out ${className}`}
    // >
    //   {title}
    // </button>
    <button
    onClick={iconId === 108 ? (hasPermissionForSaveANDContinue ? onClick : null) : onClick }
    disabled={iconId === 108 ? !hasPermissionForSaveANDContinue : '' } // Disable button if permission exists
      title={iconId === 108 ? (!hasPermissionForSaveANDContinue ? "You don't have permission" : "") : ''} // Tooltip
    className={`text-sm border py-1 px-2 rounded 
      transition duration-200 ease-in-out 
      ${className}
       ${iconId === 108 ?  (!hasPermissionForSaveANDContinue ? " text-white bg-gray-400" : "bg-white border-customBlue text-customBlue hover:bg-customBlue hover:text-white ") : 'bg-white border-customBlue text-customBlue hover:bg-customBlue hover:text-white ' }
      `}
  >
    {title}
  </button>
  );
}
