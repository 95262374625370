import React, { useCallback, useEffect, useRef, useState } from "react";
import Select, { components } from "react-select";
import NoActionLayout from "../../../../../../components/NoActionLayout";
import AdminActions from "../../../Components/AdminActions";
import { AdminActionsConfig } from "../../../Components/ActionConfig";
import { useParams, useNavigate } from "react-router-dom";
import { IoAddCircleOutline, IoSettingsOutline } from "react-icons/io5";
import { TbDeviceDesktopCheck, TbFlag3Filled } from "react-icons/tb";
import { LuKeyRound } from "react-icons/lu";
import {
  IoMdCheckmark,
  IoMdClose,
  IoMdCreate,
  IoMdTrash,
  IoMdRefresh,
} from "react-icons/io";
import exportTableToExcel from "../../../Components/exportToExcel";

import {
  MdOutlineFileDownload,
  MdOutlineFileUpload,
  MdOutlineHistory,
  MdOutlineLock,
  MdOutlineLockOpen,
} from "react-icons/md";
import axios from "axios";
import * as XLSX from "xlsx"; // Import the xlsx library
import UserService from "../../../../../../rest-services/UserServices";
import { useToast } from "../../../../../../components/toast/toast";
import { GiSettingsKnobs } from "react-icons/gi";
import Header from "../../../../../../components/Header";
import ReTable from "../../../../../../components/Table";
import ColumnSettingsPopup from "../../../../Masterdata/Components/ColumnSettingPopup";
import { RxDropdownMenu } from "react-icons/rx";
import CustomeLoading from "../../../../../../components/CustomeLoading";
import HoverButton from "../../../../../../components/HoverButton";
import CustomButton from "../../../../../../components/CustomButton";
import LegalEntityServices from "../../../../../../rest-services/LegalEntityServices";
import { VscVmActive, VscVmConnect, VscVmOutline } from "react-icons/vsc";
import SearchInput from "../../../../../../components/SearchInput";
import Pagination from "../../../../../../components/PageNation";
import { debounce } from "lodash";
import FilterDropdown from "../../../../../../components/FilterDropdown";
import { PiCopy } from "react-icons/pi";
import ConfirmationDialog from "../../../../../../components/Deletionpopup";
import Draggable from "react-draggable";

export default function User() {
  const { iconId } = useParams();
  const navigate = useNavigate();
  const refreshTable = useRef(null);
  const [file, setFile] = useState(null);
  const [userTypes, setUserTypes] = useState([]);
  const [userType, setUserType] = useState('');
  const [usersfilter, setUsersState] = useState([]);
  const [userStatus, setUserStatus] = useState('');

  const [alert, setAlert] = useState(null);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState(null); // To store the selected calendar ID
  const [selectedRows, setSelectedRows] = useState([]); // Track selected rows by IDs

  const [assignee, setAssignee] = useState({ id: "", username: "" });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [isCountryModalOpen, setIsCountryModalOpen] = useState(false);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0); // Add refreshKey state
  const [userPreference, setUserPreference] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUsers, setSelectedUsers] = useState({});
  const [selectedCountry, setSelectedCountry] = useState({});
  const [usersToShow, setUsersToShow] = useState([]);
  const [countryToShow, setCountryToShow] = useState([]);
  const [rolesPermissions, setRolesPermissions] = useState([]);
  const [checkingPswdPolicy, setCheckingPswdPolicy] = useState("");
  const [checkingOtpPolicy, setCheckingOtpPolicy] = useState("");
  const [filterType, setFilterType] = useState("1"); // 'createdBy' or 'updatedBy'
  const [isCreatedByPopupOpen, setIsCreatedByPopupOpen] = useState(false);
  const [isUpdatedByPopupOpen, setIsUpdatedByPopupOpen] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedCreatedBy, setSelectedCreatedBy] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // Manage search input
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showConfirmationForLock, setShowConfirmationForLock] = useState(false);
    const [updatedbysearchquery, setupdatedbysearchquery] = useState("");

  const [tempSelectedcreatedby, setTempSelectedcreatedby] =
    useState(selectedCreatedBy);

  const [tempSelectedcountries, setTempSelectedcountries] =
    useState(selectedCountries);
  const [isCountryPopupOpen, setIsCountryPopupOpen] = useState(false); // Controls popup visibility

  const [selectedUpdatedBy, setSelectedUpdatedBy] = useState([]);
  const [tempSelectedupdatedby, setTempSelectedupdatedby] =
    useState(selectedUpdatedBy);
  const [createdbydatasearchQuery, setcreatedbydatasearchQuery] = useState("");
  const [filteredcreatedbydatas, setFilteredcreatedbydatas] = useState([]);
  const [createdbydatas, setcreatedbydatas] = useState([]); // For fetched company codes
  const [countryCodeSearchQuery, setCountryCodeSearchQuery] = useState("");
  const [filteredCountryCodes, setFilteredCountryCodes] = useState([]);
  const [countryCodes, setcountryCodes] = useState([]); // For fetched company codes

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [hasFiltersApplied, setHasFiltersApplied] = useState(false);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const [checkboxes, setCheckboxes] = useState({
    option1: false,
    option2: false,
    option3: false,
    option4: false,
  });
  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleApplyUpdatedby = () => {
    setSelectedUpdatedBy(tempSelectedupdatedby); // Apply temporary selection to actual selection state
    setFilters((prevFilters) => ({
      ...prevFilters,
      updated_by: tempSelectedupdatedby, // Set the filter
    }));
    setIsUpdatedByPopupOpen(false); // Close the popup
  };

  // const handleSelectionChange = (selectedIds) => {
  //   setSelectedUserIds(selectedIds);

  // };

  // const fetchCountryCodes = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await LegalEntityServices.getAllLegalEntity(
  //       `/getalllgecountrycodes`
  //     );
  //     const responseData = response.data;

  //     if (responseData.error) {
  //       setAlert({ severity: "error", message: responseData.message });
  //       return;
  //     }

  //     if (responseData && Array.isArray(responseData)) {
  //       setcountryCodes(responseData); // assuming responseData contains an array of legal entities
  //       setFilteredCountryCodes(responseData);
  //     }
  //   } catch (error) {
  //     setAlert({
  //       severity: "error",
  //       message: `Error fetching legal entities: ${error.message}`,
  //     });
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   if (countryCodeSearchQuery.trim() === "") {
  //     setFilteredCountryCodes(countryCodes); // Show all if search query is empty
  //   } else {
  //     const filtered = countryCodes.filter((code) =>
  //       code.toLowerCase().includes(countryCodeSearchQuery.toLowerCase())
  //     );
  //     setFilteredCountryCodes(filtered);
  //   }
  // }, [countryCodeSearchQuery, countryCodes]);

  useEffect(() => {
    if (assignee.clientId && rolesPermissions.length > 0) {
      fetchUsers();
    }
  }, [assignee.clientId, rolesPermissions]);

  useEffect(() => {
    fetchAssignee();
    fetchUserTypes();
    fetchPasswordPolicy();
    fetchOTPPolicy();
    // fetchCountryCodes();
  }, []);

  const fetchOTPPolicy = async () => {
    try {
      const response = await UserService.getUsers(
        `${process.env.REACT_APP_BASE_URL}/getting-otp-policy`
      );
      console.log("Response from server fetching OTP policy:", response);
      const responseData = response.data.data;
      console.log("checking policy", responseData);
      setCheckingOtpPolicy(responseData);
      console.log("double check users", response.data);
      if (responseData.error) {
        console.error("Error fetching Users data:", responseData.message);
        setAlert({ severity: "error", message: responseData.message });
        return;
      }
    } catch (error) {
      console.error("Error fetching Users data", error);
      setAlert({
        severity: "error",
        message: `Error fetching Users data, kindly check the connection ${error.message}`,
      });
    }
  };

  const handleUpdateBySelection = (changedby) => {
    setTempSelectedupdatedby((prev) => {
      return prev.includes(changedby)
        ? prev.filter((item) => item !== changedby)
        : [...prev, changedby];
    });
  };

  const handleBankAccountIdReset = () => {
    setSelectedUsers([]); // Reset selection
  };
  
  const fetchPasswordPolicy = async () => {
    try {
      const response = await UserService.getUsers(
        `${process.env.REACT_APP_BASE_URL}/getting-password-policy`
      );
      console.log("Response from server fetching users:", response);
      const responseData = response.data.data;
      console.log("checking policy", responseData);
      setCheckingPswdPolicy(responseData);
      console.log("double check users", response.data);
      if (responseData.error) {
        console.error("Error fetching Users data:", responseData.message);
        setAlert({ severity: "error", message: responseData.message });
        return;
      }
    } catch (error) {
      console.error("Error fetching Users data", error);
      setAlert({
        severity: "error",
        message: `Error fetching Users data, kindly check the connection ${error.message}`,
      });
    }
  };

  const pageSize = 20;

  const fetchUsers = async (page = 1, searchQuery = "") => {
    setLoading(true);
    try {
      if (!assignee.clientId) {
        return false;
      }
      console.log("assignees data", assignee);
      const searchParam = searchQuery ? `&searchQuery=${encodeURIComponent(searchQuery)}` : "";

      const data = {
        clientId: assignee.clientId,
        limit: pageSize,
        offset: (page - 1) * pageSize,
        searchParam
      };
      console.log("Request Data for users:", data);

      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/user/get-user`,
        data
      );
      console.log("Response from server fetching users:", response);
      let { searchUsers, totalCount } = response;


      // console.log("Check user permisions is", rolesPermissions);
      // const hasPermission = rolesPermissions.some(
      //   (permission) => permission.permissionsId === 3 && permission.iconId === parseInt(iconId)
      // );

      // console.log("Check user permissions to user view list", hasPermission);

      // if(hasPermission){

      if (!searchUsers) {
        searchUsers = [];
      } else if (!Array.isArray(searchUsers)) {
        searchUsers = [searchUsers];
      }

      // }
      // else{
      //   searchUsers = [];
      // }


      // const responseData = response.data;
      console.log("users data", searchUsers);
      setUsers(searchUsers);
      setTotalCount(totalCount);
      setTotalPages(Math.ceil(totalCount / pageSize));

      // console.log("double check users", response.data);
      // if (responseData.error) {
      //   console.error("Error fetching Users data:", responseData.message);
      //   setAlert({ severity: "error", message: responseData.message });
      //   return;
      // }
    } catch (error) {
      console.error("Error fetching Users data", error);
      setAlert({
        severity: "error",
        message: `Error fetching Users data, kindly check the connection ${error.message}`,
      });
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchUsers = useCallback(
    debounce((page, query) => {
      fetchUsers(page, query);
    }, 500), // 500ms debounce delay
    []
  );


  // UseEffect to trigger API call on query or page change
  useEffect(() => {
    debouncedFetchUsers(currentPage, searchQuery);

    // Cleanup debounce on unmount
    return () => debouncedFetchUsers.cancel();

  }, [currentPage, searchQuery, debouncedFetchUsers]);

  const fetchUserTypes = async () => {
    try {
      const response = await UserService.getUsers(
        `${process.env.REACT_APP_BASE_URL}/get-user-type`
      );
      console.log("set user types", response.data.data);
      setUserTypes(response.data.data);
    } catch (error) {
      console.error("Error fetching user types:", error);
    }
  };

  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");

      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.username || "",
          clientId: userDetail.clientId || "",
          assignerId: userDetail.userId || "",
        };
        console.log("assigneeData is", assigneeData);

        setAssignee(assigneeData);

        // Log the id and assigner values to the console
        console.log("Assignee ID:", assigneeData.id);
        console.log("Assigner:", assigneeData.assigner);
        console.log("Assigner userId:", assigneeData.assignerId);
        console.log("Assigner clientId:", assigneeData.clientId);
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

  const handleFilterDateFrom = (e) => {
    console.log(filterType);
    if (filterType === "1") {
      setFilters((prevFilters) => ({
        ...prevFilters,
        created_date_from: e.target.value,
      }));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        updated_date_from: e.target.value,
      }));
    }
  };

  const handleFilterDateto = (e) => {
    if (filterType === "1") {
      setFilters((prevFilters) => ({
        ...prevFilters,
        created_date_to: e.target.value,
      }));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        updated_date_to: e.target.value,
      }));
    }
  };
  useEffect(() => {
    if (assignee.assignerId) {
      console.log("Assigner ID has changed:", assignee.assignerId);
      fetchUserPermissionDetails();
    }
  }, [assignee.assignerId]);

  useEffect(() => {
    if (assignee.id) {
      fetchUserPreferenceDetails();
    }
  }, [assignee.id]);

  const fetchUserPermissionDetails = async () => {
    try {
      const data = { userId: assignee.assignerId };
      console.log("Request Data:", data);

      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-users-permissions-by-userId`,
        data
      );
      console.log("user permissions details", response);
      const responseData = response.data;
      setRolesPermissions(responseData.rolesPermissionsTables || []); // Save permission details
      console.log("Set Permission details for userscreen", responseData.rolesPermissionsTables);
    } catch (error) {
      console.error(error.message); // Set error message
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  const fetchUserPreferenceDetails = async () => {
    try {
      const data = { userId: assignee.assignerId }; // Assuming assignee.id is the user ID
      console.log("Requestfor user pref Data:", data);

      setLoading(true); // Set loading state
      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-edit-user-preference-details-by-Id`,
        data
      );
      console.log("user prefference details", response.data);
      setUserPreference(response.data); // Set user preference data
    } catch (error) {
      setError(error.message); // Set error message
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  const handleCheckboxChangefilter2 = (e) => {
    const { name, checked } = e.target;
    setSelectedCountry((prev) => ({ ...prev, [name]: checked }));
  };

  const handleCheckboxChangefilter = (e) => {
    const { name, checked } = e.target;
    setSelectedUsers((prev) => ({ ...prev, [name]: checked }));
  };

  const handleCheckboxChange = (id) => {
    console.log("checked user id", id);
    const updatedSelectedRows = selectedRows.includes(id)
      ? selectedRows.filter((rowId) => rowId !== id) // Deselect if already selected
      : [...selectedRows, id]; // Add to selection if not selected

    setSelectedRows(updatedSelectedRows);
    console.log("check box:", updatedSelectedRows);

    fetchArrayUsers(updatedSelectedRows);
    
    setUser(id); // Set the selected calendar ID for navigation or further actions
    // setShowExtraIcons(updatedSelectedRows.length > 0); // Show extra icons if any row is selected
  };

  const fetchArrayUsers = async (userIds) => {
    try {
      // const userId = userIds.map((id) => ({ id }));
      // Prepare the request data
      // const requestData = userIds.map((id) => ({ id })); // Transforming to the desired format
      console.log("Request Data from admin userids actions:", userIds); // Debugging the request data

      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-array-users`,
        { userIds: userIds } // Send the correctly formatted request data
      );

      console.log("API Response from users actions data:", response.data);

      const approved = response.data[0]?.approved;

      // Log the extracted approveds
      console.log("Extracted approveds:", approved);
      setUserStatus(approved);
      // Handle the API response as needed, e.g., update state or notify user
    } catch (error) {
      console.error("Error fetching user details:", error);
      // Optionally handle the error state
    }
  };
  const handleApplyCreatedby = () => {
    setSelectedCreatedBy(tempSelectedcreatedby); // Apply temporary selection to actual selection state
    setFilters((prevFilters) => ({
      ...prevFilters,
      created_by: tempSelectedcreatedby, // Set the filter
    }));
    setIsCreatedByPopupOpen(false); // Close the popup
  };

  const handleCreatedbyReset = () => {
    setTempSelectedcreatedby([]); // Reset selection
  };

  const resetSelectedUpdatedBy =() => {
    setTempSelectedupdatedby([]); // Reset selection
  };

  const handleCountrySelection = (countryCode) => {
    setTempSelectedcountries((prev) => {
      // Add or remove company from temporary selection list
      return prev.includes(countryCode)
        ? prev.filter((item) => item !== countryCode) // Remove if already selected
        : [...prev, countryCode]; // Add if not selected
    });
  };

  const handleCreatedBySelection = (createdBy) => {
    console.log("select created by",createdBy);
    setTempSelectedcreatedby((prev) => {
      // Add or remove company from temporary selection list
      return prev.includes(createdBy)
        ? prev.filter((item) => item !== createdBy) // Remove if already selected
        : [...prev, createdBy]; // Add if not selected
    });
  };

  useEffect(() => {
    if (createdbydatasearchQuery.trim() === "") {
      setFilteredcreatedbydatas(createdbydatas); // Show all if search query is empty
    } else {
      const filtered = createdbydatas.filter((code) =>
        code.created_by
          .toLowerCase()
          .includes(createdbydatasearchQuery.toLowerCase())
      );
      setFilteredcreatedbydatas(filtered);
    }
  }, [createdbydatasearchQuery, createdbydatas]);



  const handleSubmitUsers = () => {
    // Collect selected user IDs and close modal
    const selectedUserIds = Object.keys(selectedUsers).filter(
      (userId) => selectedUsers[userId]
    );
    console.log("selected users:", selectedUsers);
    console.log("selected :", selectedUserIds);
    setUsersToShow(selectedUserIds); // Set selected IDs to be shown in the form
    closeUserModal(); // Close modal after selection
  };

  useEffect(() => {
    const savedFilters = localStorage.getItem("filterData");
    const savedUsersToShow = localStorage.getItem("usersToShow");
    const savedUsers = localStorage.getItem("fetchUsersData");
    if (savedFilters) {
      // If filters exist in session storage, load them
      setFilters(JSON.parse(savedFilters));
    }

    if (savedUsersToShow) {
      setUsersToShow(JSON.parse(savedUsersToShow)); // Restore selected users from session storage
    }

    if (savedUsers) {
      // If data exists in session storage, load it
      setUsers(JSON.parse(savedUsers));
    } else {
      // Otherwise, fetch the users from the server
      fetchUsers();
    }
  }, []);

  const handleTriggerOTPClick = async () => {
    if (selectedRows.length > 0) {
      setLoading(true);
      try {
        const response = await UserService.saveapproved(
          `${process.env.REACT_APP_BASE_URL}/edit-trigger-otp`,
          { userIds: selectedRows, createdBy: assignee.assignerId } // Pass selectedUserIds in the body
        );

        if (response.status === 200) {
          toast("success", "User successfully sent OTP.");
          await fetchUsers();
        } else if (response.status === 550) {
          toast("error", "The user cannot send mail to this recipient.");
        } else {
          console.log("Error updating users.");
        }
      } catch (error) {
        console.error("Error updating users:", error);
      }
      finally {
        setLoading(false);
      }
    } else {
      console.log("Please select at least one user to Inactive.");
    }
  };

  const closeUserModal = () => {
    setIsUserModalOpen(false);
  };
     

  const handleInactiveClick = async () => {
    if (selectedRows.length > 0) {
      try {
        const response = await UserService.saveapproved(
          `${process.env.REACT_APP_BASE_URL}/edit-inactive`,
          { userIds: selectedRows, createdBy: assignee.assignerId } // Pass selectedUserIds in the body
        );

        if (response.status === 200) {
          toast("success", "User successfully Change Status.");
          // toast("success", "User successfully Change Status");
          // navigate("/userscreen");
          // window.location.reload(); // This will refresh the page
          
          await fetchUsers();
          setRefreshKey((prevKey) => prevKey + 1); // Update refreshKey to trigger AdminActions refresh
          await reload();
        } else if (response.status === 400) {
          toast("error", "The user cannot be deactivated at this time.");
        } else {
          console.log("Error updating users.");
        }
      } catch (error) {
        console.error("Error updating users:", error);
      }
    } else {
      console.log("Please select at least one user to Inactive.");
    }
  };

  // const handleDeleteClick = async () => {
  //   if (selectedRows.length > 0) {
  //     try {
  //       const response = await UserService.saveapproved(
  //         `${process.env.REACT_APP_BASE_URL}/delete-user`,
  //         { userIds: selectedRows[0], createdBy: assignee.assignerId } // Pass selectedUserIds in the body
  //       );

  //       if (response.status === 200) {
  //         toast("error", "User successfully Deleted.");

  //         // Remove deleted users from the selectedRows
  //         setSelectedRows((prevSelectedRows) =>
  //           prevSelectedRows.filter((rowId) => !selectedRows.includes(rowId))
  //         );
  //         await fetchUsers();
  //       } else {
  //         console.log("Error updating users.");
  //       }
  //     } catch (error) {
  //       console.error("Error updating users:", error);
  //     }
  //   } else {
  //     console.log("Please select at least one user to Delete.");
  //   }
  // };

  const handleDeleteClick = async () => {
    if (selectedRows.length === 0) return;
    setShowConfirmation(true);
  };

  const cancelDeletion = () => {
    setShowConfirmation(false);
  };

  const confirmDeletion = async () => {
    setShowConfirmation(false);
    setLoading(true);

    try {
            const response = await UserService.saveapproved(
              `${process.env.REACT_APP_BASE_URL}/delete-user`,
              { userIds: selectedRows[0], createdBy: assignee.assignerId } // Pass selectedUserIds in the body
            );
    
            if (response.status === 200) {
              toast("error", "User successfully Deleted.");
    
              // Remove deleted users from the selectedRows
              setSelectedRows((prevSelectedRows) =>
                prevSelectedRows.filter((rowId) => !selectedRows.includes(rowId))
              );
              await fetchUsers();
              await reload();
            } else {
              console.log("Error updating users.");
            }
          } catch (error) {
            console.error("Error updating users:", error);
          } finally {
            setLoading(false);
          }
        };


        // const handleLockUserClick = async () => {
        //   if (selectedRows.length > 0) {
        //     try {
        //       console.log("selected user rows id", selectedRows);
        //       const response = await UserService.saveapproved(
        //         `${process.env.REACT_APP_BASE_URL}/edit-lock-user`,
        //         { userIds: selectedRows, createdBy: assignee.assignerId } // Pass selectedUserIds in the body
        //       );
      
        //       if (response.status === 200) {
        //         toast("success", "User has successfully changed the lock Status.");
        //         // navigate("/userscreen");
        //         await fetchUsers();
        //         await reload();
        //         // if (refreshTable.current) {
        //         //   refreshTable.current();  // Call the refresh function to reload the user data
        //         // }
        //       } else {
        //         console.log("Error updating users.");
        //       }
        //     } catch (error) {
        //       console.error("Error updating users:", error);
        //     }
        //   } else {
        //     console.log("Please select at least one user to Approve.");
        //   }
        // };

        const handleLockUserClick = async () => {
          setShowConfirmationForLock(true);
        }
        const cancelLockStatus = () => {
          setShowConfirmationForLock(false);
        };

        const confirmLockStatus = async () => {
          setShowConfirmationForLock(false);
          setLoading(true);
          try {
            console.log("selected user rows id", selectedRows);
            const response = await UserService.saveapproved(
              `${process.env.REACT_APP_BASE_URL}/edit-lock-user`,
              { userIds: selectedRows, createdBy: assignee.assignerId } // Pass selectedUserIds in the body
            );
    
            if (response.status === 200) {
              toast("success", "User has successfully changed the lock Status.");
              // navigate("/userscreen");
              await fetchUsers();
              await reload();
              // if (refreshTable.current) {
              //   refreshTable.current();  // Call the refresh function to reload the user data
              // }
            } else {
              console.log("Error updating users.");
            }
          } catch (error) {
            console.error("Error updating users:", error);
          } finally {
            setLoading(false);
          }
        };

  const handleCancelClick = async () => {
    if (selectedRows.length > 0) {
      try {
        const response = await UserService.saveapproved(
          `${process.env.REACT_APP_BASE_URL}/edit-rejected`,
          { userIds: selectedRows, createdBy: assignee.assignerId } // Pass selectedUserIds in the body
        );

        if (response.status === 200) {
          toast("error", "User successfully Rejected");
          // navigate("/userscreen");
          await fetchUsers();
          await reload();
        } else if (response.status === 400) {
          toast("error", "User is unable to Reject.");
        } else {
          console.log("Error updating users.");
        }
      } catch (error) {
        console.error("Error updating users:", error);
      }
    } else {
      console.log("Please select at least one user to Reject.");
    }
  };

  const handleApproveClick = async () => {
    if (selectedRows.length > 0) {
      setLoading(true); // Start loading

      try {
        const response = await UserService.saveapproved(
          `${process.env.REACT_APP_BASE_URL}/edit-approved`,
          { userIds: selectedRows, createdBy: assignee.assignerId } // Pass selectedUserIds in the body
        );
        console.log("Response status:", response.status);
        if (response.status === 200) {
          toast("success", "User successfully Approved");
          // navigate("/userscreen");
          await fetchUsers();
          await reload();
          // if (refreshTable.current) {
          //   refreshTable.current();  // Call the refresh function to reload the user data
          // }
        } else if (response.status === 400) {
          toast("error", "User is unable to approve.");
        } else if (response.status === 550) {
          toast("warning", "User Email is Not Sent.");
        } else {
          console.log("Error updating users.");
        }
      } catch (error) {
        console.error("Error updating users:", error);
      } finally {
        setLoading(false); // Stop loading
      }
    } else {
      console.log("Please select at least one user to Approve.");
    }
  };



  const handleAuditTrail = () => {
    if (selectedRows.length === 1) {
      const selectedUser = filteredUsers.find(
        (user) => user.userId === selectedRows[0]
      );
      console.log("user id", selectedRows[0]);
      console.log("Icons id", iconId);

      if (selectedUser) {
        // Show the selected user's details using toast
        // toast("info", `Selected User: \nID: ${selectedUser.userId}\nName: ${selectedUser.username}`);

        // Navigate to the edit screen, passing the selected user as state
        navigate(`/audit-trail-screen/${iconId}/${selectedRows[0]}`);
      } else {
        toast("error", "Selected user not found.");
      }
    } else {
      toast("error", "Please select exactly one user to edit.");
    }
  };

  const handleEditClick = () => {
    // console.log('edit user id',selectedUserIds);
    if (selectedRows.length === 1) {
      console.log("entered user id");
      // console.log()
      const selectedUser = filteredUsers.find(
        (user) => user.userId === selectedRows[0]
      );
      console.log("get user id", selectedUser);
      // console.log('single user id', selectedUser);

      if (selectedUser) {
        // Show the selected user's details using toast
        // toast("info", `Selected User: \nID: ${selectedUser.userId}\nName: ${selectedUser.username}`);

        // Navigate to the edit screen, passing the selected user as state
        navigate(`/user-edit-screen/${selectedRows[0]}`, {
          state: { mode: "Edit" },
        });
        // navigate(`/user-edit-screen/${selectedRows[0]}`, { state: { mode: 'edit' } });
      } else {
        toast("error", "Selected user not found.");
      }
    } else {
      toast("error", "Please select exactly one user to edit.", {
        duration: 10000, // Set the toast duration to 10 seconds (5000ms)
      });
    }
  };

  const handleAddcopyClick = () => {
    if (selectedRows.length === 1) {
      console.log("check user ids", selectedRows[0]);
      const selectedUser = filteredUsers.find(
        (user) => user.userId === selectedRows[0]
      );
      console.log("user id", selectedUser);

      if (selectedUser) {
        // Show the selected user's details using toast
        // toast("info", `Selected User: \nID: ${selectedUser.userId}\nName: ${selectedUser.username}`);

        // Navigate to the edit screen, passing the selected user as state
        navigate(`/user-copy-Add-screen/${selectedRows[0]}`, {
          state: { mode: "add" },
        });
      } else {
        toast("error", "Selected user not found.");
      }
    } else {
      toast("error", "Please select exactly one user to Copy.", {
        duration: 10000, // Set the toast duration to 10 seconds (5000ms)
      });
    }
  };

  const handleDownload = () => {
    if (users.length === 0) {
      setAlert({
        severity: "warning",
        message: "No data available to export!",
      });
      return;
    }
    console.log("donwload filteredUsers", filteredUsers);
    // Prepare data for export
    const formattedUsers = filteredUsers.map((user) => ({
      "User ID": user.userId,
      Status:
        user.approved === "0"
          ? "Created"
          : user.approved === "1"
            ? "Changed"
            : user.approved === "2"
            ? "Waiting for Approval"
            : user.approved === "3"
            ? "Modified"
            : user.approved === "4"
            ? "Approved"
            : user.approved === "5"
                  ? "Rejected"
                  : "Inactive",
      Lock: user.userStatus === "0" ? "Unlock" : "Lock",
      "Employee No": user.employeeNo,
      Salute: user.salute,
      Username: user.username,
      "First Name": user.firstName,
      "Middle Name": user.middleName,
      "Last Name": user.lastName,
      Language: user.UserPreference?.language || "",
      "Time Format": user.UserPreference?.timeFormat || "N/A",
      "Time Zone": user.UserPreference?.timeZone || "",
      "User Type": user.UserType?.name || "N/A",
      "Company Code": user.companyCodeId,
      Department: user.department,
      "Valid From": user.validFrom,
      "Valid To": user.validTo,
      "Email ID": user.email,
      "Address 1": user.Address?.addressLine1 || "",
      "Address 2": user.Address?.addressLine2 || "",
      "Address 3": user.Address?.addressLine3 || "",
      Country: user.Address?.Country?.country_name ?? "",
      "Mobile No": user.mobileNo,
      Nationality: user.nationality,
      "Passport No": user.passportNo,
      "Driving License No": user.drivingLicenseNo,
      "Local ID": user.localId,
      "Joining Date": user.joiningDate,
      "Resign Date": user.resignationDate,
      "Amount Format": user.UserPreference?.amountFormat || "N/A",
      "Date Format": user.UserPreference?.dateFormat || "N/A",

      // 'Amount Format': user.userPreference?.amountFormat || '',
      // 'Date Format': user.userPreference?.dateFormat || '',
      "Login Status": user.loginStatus === 0 ? "Offline" : "Online",
      "Last Login Date": user.lastSeenDate,
      "Last Login Time": user.lastSeenTime,
      // 'Creation Date': user.creationDate,
      "Creation Date": user.creationDate
        ? user.creationDate.split("-").reverse().join(".")
        : "",
      "Creation Time": user.creationTime
        ? new Date(user.creationTime)
          .toLocaleString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: true,
          })
          .toLowerCase()
        : "",

      // 'Creation Time':user.creationTime,
      "Creation By": user.createdBy,
      // 'Update Date': user.changedDate,
      // 'Update Date': user.changedDate ? user.changedDate.split('-').reverse().join('.') : '',
      "Update Date": user.changedDate
        ? user.changedDate.split(" ")[0].split("-").reverse().join(".")
        : "",

      // 'Update Time': user.changedTime ? new Date(user.changedTime).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true }).toLowerCase() : '',
      "Update Time": user.changedTime ? user.changedTime : "",
      "Update By": user.changedBy ? user.changedBy : "",
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedUsers); // Converts JSON to sheet
    const workbook = XLSX.utils.book_new(); // Creates a new workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Users"); // Appends the sheet to the workbook

    // Export as an Excel file
    XLSX.writeFile(workbook, "Users_Data.xlsx");
  };

  const passwordPolicy = async () => {
    toast("error", "Password Policy Needed.");
  };

  const otpPolicy = async () => {
    toast("error", "OTP Policy Needed.");
  };

  const reload = async () => {
    setLoading(true); // Start loading
    try {
      await fetchUsers();
      await fetchPasswordPolicy(); // Fetch users
      setRefreshKey((prevKey) => prevKey + 1); // Update refreshKey to trigger AdminActions refresh
    } catch (error) {
      console.error("Error reloading users:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const onFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      console.log("Selected file:", selectedFile);
    }
  };

  const onFileUpload = () => {
    if (!file) {
      console.error("No file selected");
      return;
    }
    const formData = new FormData();
    formData.append("file", file);

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/admin/upload-user`, formData)
      .then((response) => {
        console.log("File uploaded successfully:", response.data);
      })
      .catch((error) => {
        console.error("There was an error uploading the file!", error);
      });
  };

  const handleFileInputChange = (e) => {
    onFileChange(e); // Update the file state
  };

  useEffect(() => {
    if (file) {
      onFileUpload(); // Upload the file after the state has been updated
    }
  }, [file]);

  const [filters, setFilters] = useState({
    status: "",
    userId: "",
    country: "",
    companyCode: "",
    countryCode: [],
    userType: "",
    validFrom: "",
    validTo: "",
    createdBy: "",
    createdDate: "",
    created_date_from: "",
    created_date_to: "",

    changedBy: "",
    updated_date_from: "",
    updated_date_to: "",
  });
  const fileInputRef = useRef(null);

  const handleUserFilterPopup = () => {
    console.log("entered");
    setIsUserModalOpen(true);
  };

  const handleCountryFilterPopup = () => {
    console.log("entered");
    setIsCountryModalOpen(true);
  };

  const closeCountryModal = () => {
    setIsCountryModalOpen(false);
  };

  // const closeUserModal = () => {
  //   setIsUserModalOpen(false);
  // };

  const handleFilterPopup = () => {
    console.log("entered filter");
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("set usertypes list data", value);
    setUserType(value);
    // If you want to update filters as well:
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const resetFilters = () => {
    setFilters({
      status: "",
      userId: "",
      companyCode: "",
      country: "",
      userType: "",
      validFrom: "",
      countryCode: [],
      validTo: "",
      createdBy: "",
      createdDate: "",
      created_date_from: "",
      created_date_to: "",
    });
    // Clear selected users
    setHasFiltersApplied(false);
    setUsersToShow([]);
    setTempSelectedcreatedby([]);
    setTempSelectedupdatedby([]);
    setSelectedCreatedBy([]);
    setSelectedUpdatedBy([]);
    setSelectedUsers({}); // Reset selected users (uncheck all checkboxes)
    setSearchTerm("");
    localStorage.removeItem("filterData");
    localStorage.removeItem("fetchUsersData");
    localStorage.removeItem("usersToShow");
  };

  const handleSettingsButtonClick = () => {
    setIsSettingsPopupOpen(true);
  };

  const existingButtons = AdminActionsConfig.screen1.map((button) => {
    let onClick;
    let title;

    if (button.icon === IoMdRefresh) {
      onClick = reload; // Reference the reload function, not invoking it
      title = "Refresh";
    } else if (button.icon === IoAddCircleOutline) {
      if (checkingPswdPolicy.length === 0) {
        onClick = passwordPolicy;
      }
      // else if(checkingOtpPolicy.length === 0){
      //   onClick = otpPolicy;
      // }
      else {
        onClick = () => handleNavigate("/14/admin/108/useradd");
      }
      title = "Add User";
    } else if (button.icon === IoSettingsOutline) {
      onClick = handleSettingsButtonClick;
      title = "Table Settings";
    } else if (button.icon === GiSettingsKnobs) {
      onClick = handleFilterPopup;
      // onClick = () => {
      //   setIsModalOpen(true);
      // }
      title = "Filter";
    } else if (button.icon === MdOutlineFileUpload) {
      // onClick = () => fileInputRef.current.click();
       onClick = () => handleNavigate("/14/admin/108/user-upload");

      title = "Upload File";
    } else if (button.icon === MdOutlineFileDownload) {
      onClick = handleDownload;
      title = "Download File";
    } else {
      onClick = button.onClick || (() => { }); // Provide a default no-op function if onClick is undefined
      title = button.title || ""; // Use existing title or default to an empty string
    }

    return {
      ...button,
      onClick, // Reference the function without invoking it
      title, // Add the title property to the button configuration
    };
  });

  // Extra buttons: they are always rendered, but disabled until at least one user is selected
  const extraButtons = [
    {
      icon: IoMdCheckmark, // Pass the component itself
      onClick: handleApproveClick,
      title: "Approve",
      disabled: selectedRows.length === 0,
    },
    {
      icon: IoMdClose,
      onClick: handleCancelClick,
      title: "Reject",
      disabled: selectedRows.length === 0,
    },
    {
      icon: IoMdCreate,
      onClick: handleEditClick,
      title: "Edit",
      disabled: selectedRows.length === 0,
    },
    {
      icon: PiCopy,
      onClick: handleAddcopyClick,
      title: "Copy",
      disabled: selectedRows.length === 0,
    },
   
    {
      icon: LuKeyRound,
      onClick: handleTriggerOTPClick,
      title: "Trigger OTP",
      disabled: selectedRows.length === 0,
    },
    {
      icon: MdOutlineLock, // Pass the component itself
      onClick: handleLockUserClick,
      title: "Lock/Unlock",
      disabled: selectedRows.length === 0,
    },
    {
      icon: MdOutlineHistory,
      onClick: handleAuditTrail,
      title: "Audit Trail",
      disabled: selectedRows.length === 0,
    },
    {
      icon: IoMdTrash,
      onClick: handleDeleteClick,
      title: "Delete",
      disabled: selectedRows.length === 0,
    },
    {
      // icon: VscVmActive,
      // icon: VscVmConnect,
      icon: userStatus === '6' ? VscVmActive : VscVmConnect,
      onClick: handleInactiveClick,
      title:  userStatus === '6' ? "Active" : "Inactive",
      disabled: selectedRows.length === 0,
    },
  ];

  // Pass in extra buttons with dynamic disabled state
  const actionButtons = existingButtons.concat(
    extraButtons.map((button) => ({
      ...button,
      disabled: selectedRows.length === 0, // Disable button if no users are selected
      onClick: selectedRows.length > 0 ? button.onClick : null, // Enable onClick only if users are selected
    }))
  );

  useEffect(() => {
    const savedFetchUsers = localStorage.getItem("fetchUsersData");
    if (savedFetchUsers) {
      setFilteredUsers(JSON.parse(savedFetchUsers));
    }
  }, []);

  const handleApplyFilter = async () => {
    try {
      setIsModalOpen(false);
      console.log("selected CreatedBy",selectedCreatedBy);
      // Prepare the filter payload with dynamic filters from state

      // localStorage.setItem('fetchUsersData', JSON.stringify(filteredUsers));
      // console.log()
      const filterPayload = {
        clientId: assignee.clientId,
        approved: filters.status,
        userId: usersToShow,
        country: countryToShow,
        companyCodeId: filters.companyCode,
        department: filters.department,
        userType: filters.userType,
        validFrom: filters.validFrom,
        validTo: filters.validTo,
        createdBy: selectedCreatedBy,
        changedBy: selectedUpdatedBy,
        creationDate: filters.createdDate,
      };
      setHasFiltersApplied(true);
      console.log("filters userid", usersToShow);
      console.log("Filter payload:", filterPayload);

      // Fetch data from the server using the filter payload
      const response = await UserService.saveapproved(
        `${process.env.REACT_APP_BASE_URL}/get-user-filter`,
        filterPayload
      );

      console.log("Response from server for filtered data:", response);

      const { data, error, message } = response.data;

      // Handle errors if any
      if (error) {
        console.error("Error fetching Users data:", message);
        setAlert({ severity: "error", message });
        return;
      }

      console.log("testing 1", response.data);
      // setUsers(response.data); // Set user preference data
      setFilteredUsers(response.data);
      // Store filtered users in session storage
      localStorage.setItem("filterData", JSON.stringify(filters));
      // localStorage.setItem("fetchUsersData", JSON.stringify(response.data));
      localStorage.setItem("usersToShow", JSON.stringify(usersToShow));
    } catch (error) {
      console.error("Error fetching Users data:", error);
      setAlert({
        severity: "error",
        message: `Error fetching Users data. Please check your connection: ${error.message}`,
      });
    }
  };

  const getSelectDisplayLabelForCountry = () => {
    const count = countryToShow.length;
    console.log("count", count);

    if (count === 1) {
      console.log("equal to one count");
      // Show the single selected user ID when only one is selected
      return countryToShow[0];
    } else if (count > 1) {
      console.log("more than 1 count");
      console.log(
        `more than 1 count display ${countryToShow[0]} +${count - 1}`
      );
      // Show the first user ID and the number of remaining users
      return `${countryToShow[0]} +${count - 1}`;
    } else {
      // Default text when no users are selected
      return "Select Country";
    }
  };

  const getSelectDisplayLabel = () => {
    const count = usersToShow.length;
    console.log("count", count);

    if (count === 1) {
      console.log("equal to one count");
      // Show the single selected user ID when only one is selected
      return usersToShow[0];
    } else if (count > 1) {
      console.log("more than 1 count");
      console.log(`more than 1 count display ${usersToShow[0]} +${count - 1}`);
      // Show the first user ID and the number of remaining users
      return `${usersToShow[0]} +${count - 1}`;
    } else {
      // Default text when no users are selected
      return "Select User";
    }
  };

  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === "User & SOD") {
      console.log("Navigating to /admin");
      navigate("/14/admin");
    } else if (crumb.title === "User") {
      console.log("Navigating to /User");
      navigate("/14/admin/108/userscreen");
    } else if (crumb.title === "Admin") {
      console.log("Navigating to /14/admin");
      navigate("/14/admin");
    }
    else if (crumb.title === "View") {
      console.log("View");
      // navigate("/14/admin");
    }
    else if (crumb.title === "Add") {
      console.log("Add");
      // navigate("/14/admin");
    }
    //  else if (crumb.path) {
    //   navigate(crumb.path); // Navigate to the path specified in the crumb
    // }
  };

  
const formatDate = (dateString, format) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const shortYear = String(year).slice(-2);

  const formatMap = {
      "DD/MM/YYYY": `${day}/${month}/${year}`,
      "MM/DD/YYYY": `${month}/${day}/${year}`,
      "DD/MM/YY": `${day}/${month}/${shortYear}`,
      "MM/DD/YY": `${month}/${day}/${shortYear}`,
      "DD.MM.YYYY": `${day}.${month}.${year}`,
      "DDMMYY": `${day}${month}${shortYear}`,
      "DD-MM-YYYY": `${day}-${month}-${year}`,
  };

  return formatMap[format] || dateString;
};
const dateFormat = userPreference?.dateFormat || "DD-MM-YYYY"; // Default format




  // Table columns
  const columns = [
    {
      Header: (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TbFlag3Filled style={{ color: "#ffff" }} />
        </div>
      ),
      accessor: "approved",
      visible: true,
      width: 100,
      Cell: ({ row }) => {
        const { approved } = row.original;
        const colorMap = {
          0: "gray",
          1: "#AF52DE",
          2: "yellow",
          3: "orange",
          4: "#29CB00",
          5: "red",
        };
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TbFlag3Filled style={{ color: colorMap[approved] || "black" }} />
          </div>
        );
      },
    },

    {
      Header: (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <MdOutlineLock style={{ color: "#ffff" }} />
        </div>
      ),
      accessor: "userStatus",
      visible: true,
      width: 200,
      Cell: ({ row }) => {
        const user = row.original;
        return user.userStatus == 0 ? (
          <MdOutlineLockOpen />
        ) : (
          <MdOutlineLock style={{ color: "red" }} />
        );
      },
    },

    // {
    //   Header: 'Status',
    //   accessor: 'status', // This is the new last seen/online status field
    //   visible: true,
    //   width: 200,
    //   Cell: ({ row }) => {
    //     const user = row.original;
    //     return user.status;
    //   }
    // },

    // Use the icon directly here
    // { Header: 'Client ID', accessor: 'clientId' , visible: true, width: 200 },
    { Header: "User ID", accessor: "userId", visible: true, width: 200, alignment: "left" },
    // { Header: 'User Last seen', accessor: 'lastSeen' , visible: true, width: 200 },
    {
      Header: "Employee No",
      accessor: "employeeNo",
      visible: true,
      width: 200, alignment: "left"
    },
    { Header: "Salute", accessor: "salute", visible: true, width: 200, alignment: "left" },
    { Header: 'Accounting system id', accessor: 'accountingSystemId', visible: true, width: 200, alignment: "left" },
    
    { Header: "Username", accessor: "username", visible: true, width: 200, alignment: "left" },
    { Header: "First Name", accessor: "firstName", visible: true, width: 200, alignment: "left" },
    {
      Header: "Middle Name",
      accessor: "middleName",
      visible: true,
      width: 200, alignment: "left"
    },
    { Header: "Last Name", accessor: "lastName", visible: true, width: 200, alignment: "left" },
    {
      Header: "Language",
      accessor: "UserPreference.language",
      visible: true,
      width: 200, alignment: "left"
    },
    {
      Header: "Time Format",
      accessor: "UserPreference.timeFormat",
      visible: true,
      width: 200,
    },
    // { Header: 'Time Format', accessor: 'UserPreference.timeFormat' , visible: true, width: 200, Cell: ({ row }) => { const user = row.original;  return user.UserPreference.timeFormat == 1 ? ('12 Hours' ) : ('24 Hours' );  } },
    // {
    //   Header: 'Time Format',
    //   accessor: 'UserPreference.timeFormat',
    //   visible: true,
    //   width: 200,
    //   Cell: ({ row }) => {
    //     const user = row.original;
    //     const timeFormat = user.UserPreference?.timeFormat; // Check if UserPreference exists
    //     return timeFormat == 1 ? '12 Hours' : timeFormat == 2 ? '24 Hours' : 'N/A';
    //   }
    // },

    {
      Header: "Time Zone",
      accessor: "UserPreference.timeZone",
      visible: true,
      width: 200,
    },
    // { Header: 'User Type', accessor: 'userType', visible: true, width: 200, Cell: ({ row }) => { const user = row.original; return user.userType == 1 ? 'User' : user.userType == 2 ? 'Super User' : user.userType == 3 ? 'Approvals' : user.userType == 4 ? 'Admin' : ' '; } },
    {
      Header: "User Type",
      accessor: "userType",
      visible: true,
      width: 200,
      Cell: ({ row }) => {
        const user = row.original;
        return user.userType == 1
          ? "User"
          : user.userType == 2
            ? "Super User"
            : user.userType == 3
              ? "Approvals"
              : user.userType == 4
                ? "Admin"
                : user.userType == 5
                  ? "Consultant"
                  : user.userType == 6
                    ? "Support"
                    : user.userType == 7
                      ? "System"
                      : user.userType == 8
                        ? "KICKSTART"
                        : "N/A";
      },
    },

    {
      Header: "Company Code",
      accessor: "companyCodeId",
      visible: true,
      width: 200, alignment: "left"
    },
    { Header: "Department", accessor: "department", visible: true, width: 200, alignment: "left" },
    // { Header: 'Valid From', accessor: 'validFrom' , visible: true, width: 200, canResize: true, Cell: ({ value }) => { if (!value) return ""; const date = new Date(value); return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;  } },
    { Header: "Valid From", accessor: "validFrom", visible: true, width: 200,  
      Cell: ({ value }) => formatDate(value, dateFormat),
   },
    { Header: "Valid To", accessor: "validTo", visible: true, width: 200,
      Cell: ({ value }) => formatDate(value, dateFormat),
     },

    // { Header: 'Valid To', accessor: 'validTo' , visible: true, width: 200, canResize: true, Cell: ({ value }) => { if (!value) return ""; const date = new Date(value); return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`; } },
    { Header: "Email ID", accessor: "email", visible: true, width: 200, alignment: "left" },
    {
      Header: "Address 1",
      accessor: "Address.addressLine1",
      visible: true,
      width: 200, alignment: "left"
    },
    {
      Header: "Address 2",
      accessor: "Address.addressLine2",
      visible: true,
      width: 200, alignment: "left"
    },
    {
      Header: "Address 3",
      accessor: "Address.addressLine3",
      visible: true,
      width: 200, alignment: "left"
    },
    {
      Header: "Country",
      accessor: "Address.Country.country_name",
      visible: true,
      width: 200, alignment: "left"
    },
    { Header: "Mobile No", accessor: "mobileNo", visible: true, width: 200, alignment: "left" },
    {
      Header: "Nationality",
      accessor: "nationality",
      visible: true,
      width: 200, alignment: "left"
    },
    {
      Header: "Passport No",
      accessor: "passportNo",
      visible: true,
      width: 200, alignment: "left"
    },
    {
      Header: "Driving License No",
      accessor: "drivingLicenseNo",
      visible: true,
      width: 200, alignment: "left"
    },
    { Header: "Local ID", accessor: "localId", visible: true, width: 200, alignment: "left" },
    // { Header: 'Joining Date', accessor: 'joiningDate' , visible: true, width: 200 , canResize: true, Cell: ({ value }) => { if (!value) return ""; const date = new Date(value); return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;  } },
    {
      Header: "Joining Date",
      accessor: "joiningDate",
      visible: true,
      width: 200,
      Cell: ({ value }) => formatDate(value, dateFormat),
    },
    {
      Header: "Resign Date",
      accessor: "resignationDate",
      visible: true,
      width: 200,
      Cell: ({ value }) => formatDate(value, dateFormat),
    },

    // { Header: 'Resign Date', accessor: 'resignationDate' , visible: true, width: 200 , canResize: true, Cell: ({ value }) => { if (!value) return ""; const date = new Date(value); return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;  } },
    {
      Header: "Amount Format",
      accessor: "UserPreference.amountFormat",
      visible: true,
      width: 200,
    },
    {
      Header: "Date Format",
      accessor: "UserPreference.dateFormat",
      visible: true,
      width: 200, alignment: "left",
    },
    // {
    //   Header: 'Date Format',
    //   accessor: 'UserPreference.dateFormat',
    //   visible: true,
    //   width: 200,
    //   Cell: ({ row }) => {
    //     const user = row.original;
    //     const dateFormat = user.UserPreference?.dateFormat; // Check if UserPreference exists
    //     return dateFormat == 1 ? 'DD/MM/YYYY' :
    //            dateFormat == 2 ? 'MM/DD/YYYY' :
    //            dateFormat == 3 ? 'DD/MM/YY' :
    //            dateFormat == 4 ? 'MM/DD/YY' :
    //            dateFormat == 5 ? 'DD.MM.YYYY' :
    //            dateFormat == 6 ? 'DDMMYY' :
    //            'N/A'; // Default when there's no date format
    //   }
    // },

    // { Header: 'Login Status', accessor: 'loginStatus', visible: true, width: 200, Cell: ({ row }) => { const user = row.original; return user.loginStatus == 0 ? 'Offline' : user.loginStatus == 1 ? 'Online'  : 'N/A'; } },
    {
      Header: "Login Status",
      accessor: "loginStatus",
      visible: true,
      width: 200,
      Cell: ({ row }) => {
        const user = row.original;
        let statusText = "N/A";
        let statusStyle = {}; // Default style

        if (user.loginStatus === 0) {
          statusText = "Offline";
          statusStyle = { fontWeight: 200, color: "grey" }; // Offline style
        } else if (user.loginStatus === 1) {
          statusText = "Online";
          statusStyle = { fontWeight: 600, color: "#29CB00" }; // Online style (green text)
        }

        return <span style={statusStyle}>{statusText}</span>;
      },
    },

    // {
    //   Header: "Last Login Date",
    //   accessor: "lastSeenDate",
    //   visible: true,
    //   width: 15,
    //   canResize: true,
    //   Cell: ({ value }) => {
    //     if (!value) return "";
    //     const date = new Date(value);
    //     return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
    //   },
    // },

    {
      Header: "Last Login Date",
      accessor: "lastSeenDate",
          visible: true,
          width: 15,
          canResize: true,
          Cell: ({ value }) => formatDate(value, dateFormat),
      },

    {
      Header: "Last Login Time",
      accessor: "lastSeenTime",
      visible: true,
      width: 200,
    },

    // { Header: 'Last Login Time', accessor: 'lastSeenTime' ,visible: true, width: 15,canResize: true, Cell: ({ value }) => {if (!value) return ""; const date = new Date(value);return date.toLocaleTimeString("en-GB", { hour: '2-digit',minute: '2-digit',second: '2-digit', hour12: true, }); }},
    { Header: "Created By", accessor: "createdBy", visible: true, width: 200, alignment: "left" },
 
    {
      Header: "Creation Date",
      accessor: "creationDate",
      visible: true,
      width: 15,
      canResize: true,
      Cell: ({ value }) => formatDate(value, dateFormat),
  },
    {
      Header: "Creation Time",
      accessor: "creationTime",
      visible: true,
      width: 15,
      canResize: true,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        return date.toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
        });
      },
    },
    { Header: "Update By", accessor: "changedBy", visible: true, width: 200, alignment: "left" },
    // {
    //   Header: "Update Date",
    //   accessor: "changedDate",
    //   visible: true,
    //   width: 15,
    //   canResize: true,
    //   Cell: ({ value }) => {
    //     if (!value) return "";
    //     const date = new Date(value);
    //     return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
    //   },
    // },

    {
      Header: "Update Date",
      accessor: "changedDate",
      visible: true,
      width: 15,
      canResize: true,
      Cell: ({ value }) => formatDate(value, dateFormat),
  },

    {
      Header: "Update Time",
      accessor: "changedTime",
      visible: true,
      width: 200,
    },
  ];
  const [columnSettings, setColumnSettings] = useState(columns);
  const [isSettingsPopupOpen, setIsSettingsPopupOpen] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false); // Manage "Select All" checkbox state
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [showExtraIcons, setShowExtraIcons] = useState(false);


  const modifiedColumns = columnSettings
    .filter((col) => col.visible)
    .map((col) => ({
      ...col,
      width: col.width, // This should hold the updated width
    }));
  const handleUpdateColumnSettings = (updatedSettings) => {
    setColumnSettings(updatedSettings);
  };

  // Refetch filtered data upon a filter change or reload

  const handleSubmitCountry = () => {
    const selectedCountries = Object.keys(selectedCountry).filter(
      (country_name) => selectedCountry[country_name]
    );
    console.log("selected country:", selectedCountry);
    console.log("selected :", selectedCountries);
    setCountryToShow(selectedCountries); // Set selected IDs to be shown in the form
    closeCountryModal(); // Close modal after selection
  };

  // const handleApplyCountry = () => {
  //   setSelectedCountries(tempSelectedcountries); // Apply temporary selection to actual selection state
  //   setFilters((prevFilters) => ({
  //     ...prevFilters,
  //     countryCode: tempSelectedcountries, // Set the filter
  //   }));
  // };

  
  const handleCountryReset = () => {
    setTempSelectedcountries([]); // Reset selection
    setSelectedCountry([]);
  };

  useEffect(() => {
    console.log("check users filtered", users);
    console.log("check users data", users);

    if (users && users) {
      const filtered = users.filter(
        (user) =>
          user.userId &&
          user.userId.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredUsers(filtered);
    }
  }, [searchQuery, users]);

  const handleSelectRow = (id) => {
    handleCheckboxChange(id);
  };

  const handleSelectAll = () => {
    handleSelectAllChange();
  };

  const handleSelectAllChange = () => {
    if (isAllSelected) {
      setSelectedRows([]); // Deselect all
    } else {
      const allIds = filteredUsers.map((item) => item.userId); // Select all IDs
      setSelectedRows(allIds);
    }
    setIsAllSelected(!isAllSelected); // Toggle select all state
  };

  const handleDoubleClick = (id) => {
    console.log("Check user id", id);
    console.log("Check iconId from params", iconId);
    console.log("Check permissions for view", rolesPermissions);

    // Check if permissionsId is 5 and iconId matches the iconId from params
    const hasPermission = rolesPermissions.some(
      (permission) => permission.permissionsId === 3 && permission.iconId === parseInt(iconId)
    );

    console.log("Check user permissions with iconId", hasPermission);

    if (hasPermission) {
      navigate(`/user-view-screen/${id}`, { state: { mode: "view" } });
      console.log("Navigated to user view screen.");
    } else {
      console.log(
        "No permission with permissionsId === 3 and matching iconId, navigation skipped."
      );
    }
  };


  //  if (loading)
  //   return (
  //     <div>
  //       <CustomeLoading />
  //     </div>
  //   );

  const statusOptions = [
    { value: "0", label: "Created", className: "text-gray-500" },
    { value: "1", label: "Changed", className: "text-gray-500" },
    { value: "2", label: "Waiting for approval", className: "text-orange-400" },
    { value: "3", label: "Modified", className: "text-yellow-500" },
    { value: "4", label: "Approved", className: "text-[#29CB00]" },
    { value: "5", label: "Rejected", className: "text-red-500" },
    { value: "6", label: "Inactive", className: "text-black" },
  ];

  

  return (
    <div>
      {/* <div>
    <h1>Icon ID: {iconId}</h1> 
  </div> */}
      {/* <NoActionLayout title={"Admin > User & SOD > User "} /> */}
      <Header
        title={"Admin > User & SOD > User"}
        onBreadcrumbClick={handleBreadcrumbClick}
        backRoute={"/14/admin"}
      />

      {/* <div>
  {getd()}
</div> */}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileInputChange}
      />

      <div>
        <div className="flex justify-between items-center -ml-1 pl-4">
          <div className="flex items-center">
            <SearchInput
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              placeholder="Search User Id"
            />

            <AdminActions
              key={refreshKey} // Force re-render by updating key
              icons={iconId}
              buttons={actionButtons}
              // extraButtons={extraButtons}
              selectedCount={selectedRows.length}
              selectUserIds={selectedRows}
              hasFiltersApplied={hasFiltersApplied}

            />
          </div>

          <div className="flex items-center mr-4">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              totalCount={totalCount}
            />
          </div>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
       
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
            // onClick={closeModal} // Closes modal when clicking outside
          >
          <Draggable handle=".modal-header">   
          {/* Backdrop */}
          <div className="fixed inset-0 flex items-center justify-center bg-opacity-50 z-50">
          {/* Modal Content */}
          <div className="relative bg-white rounded-lg p-6 w-10/12 max-w-3xl shadow-lg">
         <div className="modal-header flex justify-between items-center cursor-move p-4 bg-white-100 rounded-t-lg">
               <h2 className="text-customBlue font-semibold mb-5">
                Apply Filters
              </h2>

              {/* <div className={"p-2"}> */}
              <HoverButton
                onClick={resetFilters}
                title={"Reset Filter"}
              />
              {/* </div> */}

            </div>

            <div className="grid grid-cols-2 gap-4">
              {/* Form grid */}
              <div className="col-span-2 sm:col-span-1">
                {/* Status */}
                <div className="flex items-center mb-4">
                  <label className="text-customGray text-sm mr-2 w-[140px]">
                    Status:
                  </label>
                  <select
                   name="status"
                   value={filters.status}
                   onChange={handleChange}
                   className="text-customGray border border-gray-300 pl-1 rounded-md w-[165px] h-[40px] focus:outline-none hover:border-blue-400 "
                 >
                 <option value="" className=" text-blue-500">
                     Select Status
                   </option>
                   <option value="0" className=" text-gray-500">
                     Created
                   </option>
                   <option value="1" className=" text-[#AF52DE]">
                     Changed
                   </option>
                  
                   <option value="2 " className=" text-yellow-500">
                     Waiting for Approval
                   </option>
                   <option value="3" className=" text-orange-400">
                     Modified
                   </option>
                   <option value="4" className=" text-[#29CB00]">
                     Approved
                   </option>
                   <option value="5" className=" text-red-500">
                     Rejected
                   </option>
                   <option value="6" className=" text-black">
                     Inactive
                   </option>
                 </select>

                  {/* <FilterDropdown
                    name="status"
                    value={filters.status}
                    onChange={handleChange}
                    options={statusOptions}
                    placeholder="Select Status"
                    className="bg-white text-sm"
                  /> */}
                </div>


                {/* User ID - Searchable and Multi-Select */}
                <div className="flex items-center">
                  <label className="text-customGray text-sm mr-2 w-[140px]">
                    User ID:
                  </label>
                  {/* <div onClick={handleUserFilterPopup} className="w-[165px]"> */}

                  <button
                    onClick={handleUserFilterPopup} // Trigger modal or selection dropdown
                    className={`border border-gray-300 p-2 rounded-md w-[165px] h-[40px] text-customGray focus:outline-none hover:border-blue-400 text-left flex items-center justify-between border-gray-300 hover:border-blue-400'
              }`}
                  >
                    {/* <span className="truncate text-sm">
                      {(filters.userId).length > 0
                        ? filters.userId.join(", ") // Display selected users
                        : getSelectDisplayLabel() || "Select User"}
                    </span> */}
                    <span className="truncate text-sm">
  {Array.isArray(filters?.userId) && filters.userId.length > 0
    ? filters.userId.join(", ") // Display selected users
    : getSelectDisplayLabel() || "Select User"}
</span>



                    <RxDropdownMenu className="ml-2 text-lg" />
                  </button>
                </div>
              </div>

              <div className="col-span-2 flex justify-between items-center">
                {/* Company Code */}
                <div className="flex items-center">
                  <label className="text-customGray text-sm mr-2 w-[140px]">
                    Company Code:
                  </label>
                  <input
                    type="text"
                    name="companyCode"
                    value={filters.companyCode}
                    onChange={handleChange}
                    placeholder="Input"
                    className="text-customGray text-sm border border-gray-300 p-2 rounded-md w-[165px] h-[40px] focus:outline-none hover:border-blue-400"
                  />
                </div>

                <div className="flex items-center">
                  <label className="text-customGray text-sm mr-2 w-[140px]">
                    Country :
                  </label>
                  <button
                    className="text-customGray border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer"
                    onClick={handleCountryFilterPopup}
                  >

<span className="truncate text-sm">
  {Array.isArray(filters?.country) && filters.country.length > 0
    ? filters.country.join(", ") // Display selected users
    : getSelectDisplayLabelForCountry() || "Select Country"}
</span>


                    {/* <span className="truncate text-sm">
                      {filters.country.length > 0
                        ? filters.country.join(", ") // Display selected users
                        : getSelectDisplayLabelForCountry() || "Select Country"}
                    </span> */}

                    <RxDropdownMenu className="ml-2 text-lg text-sm" />
                  </button>
                </div>
                {/* {isCountryModalOpen && (
                  <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
                    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-xl z-50">
                      <div className="flex justify-between items-center mb-4">
                        <h2 className="text-customGray font-semibold">
                          Select Country
                        </h2>
                        <HoverButton
                          onClick={handleCountryReset}
                          title={"Reset"}
                        />
                      </div>

                 
                      <input
                        type="text"
                        className="border border-gray-300 p-2 rounded-md mb-4 w-full"
                        placeholder="Search Country"
                        value={countryCodeSearchQuery}
                        onChange={(e) => setCountryCodeSearchQuery(e.target.value)} // Filter BU Groups
                      />

                      <div className="space-y-2 max-h-80 overflow-y-auto">
                        {filteredCountryCodes.map((code) => (
                          <div key={code} className="flex items-center">
                            <input
                              type="checkbox"
                              checked={tempSelectedcountries.includes(code)}
                              onChange={() => handleCountrySelection(code)}
                            />
                            <label className="ml-2">{code}</label>
                          </div>
                        ))}
                      </div>

                      <div className="flex justify-end mt-4">
                        <HoverButton
                          title={"Cancel"}
                          className={"mr-2"}
                          onClick={() => closeCountryModal(false)}
                        />
                        <CustomButton
                          onClick={() => {
                            // handleApplyCountry();
                            handleSubmitCountry();
                            closeCountryModal(false);
                          }}
                          title={"Apply"}
                        />
                      </div>
                    </div>
                  </div>
                )} */}
              </div>

              {/* User Type */}
              <div className="flex items-center">
                <label className="text-customGray text-sm mr-2 w-[140px]">
                  User Type:
                </label>
                <select
                  name="userType"
                  value={filters.userType}
                  onChange={handleChange}
                  className="text-customGray text-sm border border-gray-300 p-2 rounded-md w-[165px] h-[40px] focus:outline-none hover:border-blue-400"
                >
                  <option className="text-sm" value="">Select user type</option>
                  {userTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.name}
                    </option>
                  ))}
                </select>

                {/* <FilterDropdown
                  name="status"
                  value={filters.userType}
                  onChange={handleChange}
                  options={statusOptions}
                  placeholder="Select Status"
                  className="bg-white text-sm"
                /> */}

              </div>

              <div className="col-span-2 flex justify-between items-center">
                {/* Valid From */}
                <div className="flex items-center">
                  <label className="text-customGray text-sm mr-2 w-[140px]">
                    Valid From:
                  </label>
                  <input
                    type="date"
                    name="validFrom"
                    value={filters.validFrom}
                    onChange={handleChange}
                    className="text-customGray text-sm border border-gray-300 p-2 rounded-md w-[165px] h-[40px] focus:outline-none hover:border-blue-400"
                  />     
                </div>

                {/* Valid To */}
                <div className="flex items-center">
                  <label className="text-customGray text-sm text-sm mr-2 w-[140px]">
                    Valid To:
                  </label>
                  <input
                    type="date"
                    name="validTo"
                    value={filters.validTo}
                    onChange={handleChange}
                    className="text-customGray text-sm border border-gray-300 p-2 rounded-md w-[165px] h-[40px] focus:outline-none hover:border-blue-400"
                  />
                </div>
              </div>

              {/* Created/Updated by */}
              <div className="flex items-center">
                {/* Dropdown for selecting 'Created By' or 'Updated By' */}
                <select
                  name="filterType"
                  value={filterType} // State to track the selected filter option
                  onChange={(e) => {
                    setFilterType(e.target.value); // Update the selected filter
                  }}
                  className="text-customGray text-sm border border-gray-300 p-2 rounded-md w-[132px] h-[40px] focus:outline-none hover:border-blue-400"
                >
                  <option value="1">Created By</option>
                  <option value="2">Updated By</option>
                </select>

                {/* Button to open the popup */}
                <button
                  className="text-customGray  text-sm border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer ml-4"
                  onClick={() => {
                    if (filterType == "1") {
                      setIsCreatedByPopupOpen(true);
                      setTempSelectedcreatedby(selectedCreatedBy);// Open the Created By popup
                    } else if (filterType == "2") {
                      setIsUpdatedByPopupOpen(true); // Open the Updated By popup
                      setTempSelectedupdatedby(selectedUpdatedBy); // Set the selected data correctly before opening the popup
                    }

                  }}
                  disabled={!filterType} // Disable button if no filter is selected
                >
                  <span className="truncate">
                    {filterType === "1"
                      ? selectedCreatedBy.length > 0
                        ? selectedCreatedBy.join(", ")
                        : "Created By"
                      : filterType === "2"
                        ? selectedUpdatedBy.length > 0
                          ? selectedUpdatedBy.join(", ")
                          : "Updated By"
                        : "Select Filter"}
                  </span>
                  <RxDropdownMenu className="ml-2 text-lg" />
                </button>
              </div>

              {isCreatedByPopupOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
                  <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-xl z-50">
                    <div className="flex justify-between items-center mb-4">
                      <h2 className="text-customBlue font-semibold">
                        Select Created By
                      </h2>
                      <HoverButton
                        onClick={handleCreatedbyReset}
                        title={"Reset"}
                      />
                    </div>
                    {/* Search and Filter */}
                    <input
                      type="text"
                      className="border border-gray-300 p-2 rounded-md mb-4 w-full"
                      placeholder="Search User Id"
                      value={createdbydatasearchQuery}
                      onChange={(e) => setcreatedbydatasearchQuery(e.target.value)}
                    />
                    <div className="space-y-2 max-h-80 overflow-y-auto">
                      {/* {filteredcreatedbydatas.map((created) => (
                        <div key={created.id} className="flex items-center">
                          <input
                            type="checkbox"
                            checked={tempSelectedcreatedby.includes(
                              created.created_by
                            )}
                            onChange={() =>
                              handleCreatedBySelection(created.created_by)
                            }
                          />
                          <label className="ml-2">{created.created_by}</label>
                        </div>
                      ))} */}
                       {[...new Set(users.map((created) => created.createdBy))].map((uniqueCreatedBy) => (
                           <div key={uniqueCreatedBy} className="flex items-center">
                             <input
                               type="checkbox"
                               checked={tempSelectedcreatedby.includes(uniqueCreatedBy)}
                               onChange={() => handleCreatedBySelection(uniqueCreatedBy)}
                             />
                             <label className="ml-2">{uniqueCreatedBy}</label>
                           </div>
                         ))}

                    </div>

                    {/* Actions */}
                    <div className="flex justify-end mt-4">
                      <HoverButton
                        title={"Cancel"}
                        className={"mr-2"}
                        onClick={() => setIsCreatedByPopupOpen(false)}
                      />
                      <CustomButton
                        onClick={() => {
                          setIsCreatedByPopupOpen(false);
                          handleApplyCreatedby();                          // applyCreatedByFilter(); // Apply the selected filter
                        }}
                        title={"Apply"}
                      />
                    </div>
                  </div>
                </div>
              )}

              {/* Updated By Popup */}
              {isUpdatedByPopupOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
                  <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-xl z-50">
                    <div className="flex justify-between items-center mb-4">
                      <h2 className="text-customBlue font-semibold">
                        Select Updated By
                      </h2>
                      <HoverButton
                         onClick={resetSelectedUpdatedBy}
                        title={"Reset"}
                      />
                    </div>
                    {/* Search and Filter */}
                    <input
                      type="text"
                      className="border border-gray-300 p-2 rounded-md mb-4 w-full"
                      placeholder="Search User Id"
                    value={updatedbysearchquery}
                    onChange={(e) => setupdatedbysearchquery(e.target.value)}
                    />
                    <div className="space-y-2 max-h-80 overflow-y-auto">
                      {/* {filteredBuGroup.map((bugroup) => (
          <div key={bugroup.id} className="flex items-center">
            <input
              type="checkbox"
              checked={selectedUpdatedBy.includes(bugroup.changed_by)}
              onChange={() => handleUpdatedBySelection(bugroup.changed_by)}
            />
            <label className="ml-2">{bugroup.changed_by}</label>
          </div>
        ))} */}
         {[...new Set(users.map((updated) => updated.changedBy))].map((uniqueUpdatedBy) => (
                           <div key={uniqueUpdatedBy} className="flex items-center">
                             <input
                               type="checkbox"
                               checked={tempSelectedupdatedby.includes(uniqueUpdatedBy)}
                               onChange={() => handleUpdateBySelection(uniqueUpdatedBy)}
                             />
                             <label className="ml-2">{uniqueUpdatedBy}</label>
                           </div>
                         ))}

                    </div>
                    {/* Actions */}
                    <div className="flex justify-end mt-4">
                      <HoverButton
                        title={"Cancel"}
                        className={"mr-2"}
                        onClick={() => setIsUpdatedByPopupOpen(false)}
                      />
                      <CustomButton
                        onClick={() => {
                          handleApplyUpdatedby();
                          setIsUpdatedByPopupOpen(false);
                          // applyUpdatedByFilter(); // Apply the selected filter
                        }}
                        title={"Apply"}
                      />
                    </div>
                  </div>
                </div>
              )}

              {filterType && (
                <div className="col-span-2 flex justify-between items-center">
                  <div className="flex items-center">
                    <label className="text-customGray mr-2 w-[140px]">
                      {filterType === "createdBy"
                        ? " Date From:"
                        : " Date From:"}
                    </label>
                    <input
                      type="date"
                      name="dateFrom"
                      value={
                        filterType === "1"
                          ? filters.created_date_from
                          : filters.updated_date_from
                      }
                      onChange={(e) => handleFilterDateFrom(e)}
                      className="text-customGray border border-gray-300 p-2 rounded-md w-[165px] h-[40px] focus:outline-none hover:border-blue-400"
                    />
                  </div>
                  <div className="flex items-center">
                    <label className="text-customGray mr-2 w-[140px]">
                      {filterType === "createdBy" ? " Date To:" : " Date To:"}
                    </label>
                    <input
                      type="date"
                      name="dateTo"
                      value={
                        filterType === "1"
                          ? filters.created_date_to
                          : filters.updated_date_to
                      }
                      onChange={(e) => handleFilterDateto(e)}
                      className="text-customGray border border-gray-300 p-2 rounded-md w-[165px] h-[40px] focus:outline-none hover:border-blue-400"
                    />
                  </div>
                </div>
              )}
            </div>

            {/* Created By */}
            {/* <div className="flex items-center mb-2">
              <label className="text-customGray mr-2 w-[140px]">Created By:</label>
              <input
                type="text"
                name="createdBy"
                value={filters.createdBy}
                onChange={handleChange}
                placeholder="Input"
                className="text-customGray border border-gray-300 p-2 rounded-md w-[165px] h-[40px] focus:outline-none hover:border-blue-400"
              />
            </div> */}

            {/* Created Date */}
            {/* <div className="flex items-center mb-2">
              <label className="text-customGray mr-2 w-[140px]">Created Date:</label>
              <input
                type="date"
                name="createdDate"
                value={filters.createdDate}
                onChange={handleChange}
                className="text-customGray border border-gray-300 p-2 rounded-md w-[165px] h-[40px] focus:outline-none hover:border-blue-400"
              />
            </div> */}

            {/* Apply Button */}
            {/* <div className="mt-6 text-right">
              <button
                onClick={closeModal}
                className="bg-white text-customBlue border-customTableBorder px-4 py-2"
              >
                Cancel
              </button>
              <button
                className="bg-customBlue text-white px-4 py-2 rounded-md hover:bg-blue-600"
                onClick={handleApplyFilter} // Call the fetch function on Apply
              >
                Apply
              </button>
            </div> */}

            {/* Buttons */}
                          <div className="mt-6 flex justify-end space-x-3">
                            <HoverButton
                              onClick={closeModal}
                              title={"Cancel"}
                            />
                            <CustomButton
                              title={"Apply"}
                              onClick={() => {
                                handleApplyFilter();
                              }}
            
                            />
                          </div>

          </div>
          </div>
      </Draggable>
      </div>

      )}

      {/* user modal */}
      {isUserModalOpen && (
       <div className="fixed inset-0 bg-black bg-opacity-50 z-50">
       <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-xl z-50">
         <div className="flex justify-between items-center mb-4">
             <h2 className="text-customBlue font-semibold">
                Select Users
              </h2>
              {/* <button className="text-red-500" onClick={closeUserModal}>
                &times;
              </button> */}
               <HoverButton
                onClick={handleBankAccountIdReset}
                title={"Reset"}
              />
            </div>

            {/* Search Input */}
            <input
              type="text"
              placeholder="Search Users..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="border border-gray-300 rounded-md p-2 mb-4 w-full"
            />

            {/* Scrollable Checkbox Grid */}
            <div className="space-y-2 max-h-80 overflow-y-auto">
              {users
                .filter(
                  (user) =>
                    user.userId &&
                    user.userId.toLowerCase().includes(searchTerm.toLowerCase())
                )
                .reduce((acc, user, index) => {
                  const columnIndex = Math.floor(index / 10);
                  acc[columnIndex] = acc[columnIndex] || [];
                  acc[columnIndex].push(user);
                  return acc;
                }, [])
                .map((column, columnIndex) => (
                  <div key={columnIndex} className="flex flex-col space-y-2">
                    {column.map((user) => (
                      <label
                        key={user.value}
                        className="flex items-center text-customGray"
                      >
                        <input
                          type="checkbox"
                          name={user.userId}
                          checked={selectedUsers[user.userId] || false}
                          onChange={handleCheckboxChangefilter}
                          className="mr-2"
                        />
                        {user.userId}
                      </label>
                    ))}
                  </div>
                ))}
            </div>

            {/* <div className="mt-8">
              <button
                onClick={handleSubmitUsers}
                className="bg-blue-500 text-white py-2 px-4 rounded"
              >
                Submit
              </button>
            </div> */}
 <div className="flex justify-end mt-4">
                                                 <HoverButton
                                                   title={"Cancel"}
                                                   className={"mr-2"}
                                                   onClick={closeUserModal}
                                                 />
                                                 <CustomButton
                                                   onClick={() => {
                                                     // setIsBankAccountIdPopupOpen(false);
                                                     handleSubmitUsers();
                                                   }}
                                                   title={"Apply"}
                                                 />
                                               </div>
            
          </div>
        </div>
      )}

      {isCountryModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-xl z-50">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-customBlue font-semibold">Select Country</h2>
              <HoverButton onClick={handleCountryReset} title={"Reset"} />
            </div>

            {/* Search Input */}
            <input
              type="text"
              className="border border-gray-300 p-2 rounded-md mb-4 w-full"
              placeholder="Search Country"
              value={countryCodeSearchQuery}
              onChange={(e) => setCountryCodeSearchQuery(e.target.value)} // Update search query
            />

            <div className="space-y-2 max-h-80 overflow-y-auto">
              {users
                .filter((user) => {
                  const countryName = user.Address?.Country?.country_name || "";
                  return (
                    countryName.toLowerCase().includes(countryCodeSearchQuery.toLowerCase())
                  );
                })
                .reduce((uniqueCountries, user) => {
                  const countryName = user.Address?.Country?.country_name;
                  if (
                    countryName &&
                    !uniqueCountries.some((u) => u.countryName === countryName)
                  ) {
                    uniqueCountries.push({ countryName, userId: user.userId });
                  }
                  return uniqueCountries;
                }, [])
                .reduce((acc, user, index) => {
                  const columnIndex = Math.floor(index / 10);
                  acc[columnIndex] = acc[columnIndex] || [];
                  acc[columnIndex].push(user);
                  return acc;
                }, [])
                .map((column, columnIndex) => (
                  <div key={columnIndex} className="flex flex-col space-y-2">
                    {column.map((user) => (
                      <label
                        key={user.countryName}
                        className="flex items-center text-customGray"
                      >
                        <input
                          type="checkbox"
                          name={user.countryName}
                          checked={selectedCountry[user.countryName] || false}
                          onChange={handleCheckboxChangefilter2}
                          className="mr-2"
                        />
                        {user.countryName}
                      </label>
                    ))}
                  </div>
                ))}
            </div>

            <div className="flex justify-end mt-4">
              <HoverButton
                title={"Cancel"}
                className={"mr-2"}
                onClick={() => closeCountryModal(false)}
              />
              <CustomButton
                onClick={() => {
                  handleSubmitCountry();
                  closeCountryModal(false);
                }}
                title={"Apply"}
              />
            </div>
          </div>

        </div>
      )}

      <div>
        {loading && (
          <div>
            <CustomeLoading />
          </div>
        )}

        <ReTable
          data={filteredUsers}
          columns={modifiedColumns} // Use modified columns based on visibility
          selectedRows={selectedRows}
          onSelectRow={handleSelectRow}
          onSelectAll={handleSelectAll}
          onRowDoubleClick={handleDoubleClick}
          rowKey="userId" // Pass userId as the key for row selection
        />
      </div>
      {isSettingsPopupOpen && (
        <ColumnSettingsPopup
          columns={columnSettings}
          onClose={() => setIsSettingsPopupOpen(false)}
          onUpdate={handleUpdateColumnSettings}
        />
      )}
       <ConfirmationDialog
              open={showConfirmation}
              title="Confirm Deletion"
              message="Are you sure you want to delete this selected User ID?"
              onConfirm={confirmDeletion}
              onCancel={cancelDeletion}
            />

        <ConfirmationDialog
              open={showConfirmationForLock}
              title="Confirm Locked Status"
              message="Are you sure you want to Lock/Unlock this selected User ID?"
              onConfirm={confirmLockStatus}
              onCancel={cancelLockStatus}
            />


      {/* <Usertables onSelectionChange={handleSelectionChange} filterUsers={users}  setUsers={setUsers} refreshTable={refreshTable} /> */}
    </div>
  );
}
