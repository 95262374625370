import React, { useEffect, useState } from "react";

import { BamButtonsConfigs } from "../../../Components/BamButtonsConfig";
import BamActions from "../../../Components/BamActions";
import { useNavigate, useParams } from "react-router-dom";
import { IoAddCircleOutline, IoSettingsOutline } from "react-icons/io5";
import {
  IoMdCheckmark,
  IoMdClose,
  IoMdCreate,
  IoMdTrash,
} from "react-icons/io";
import Header from "../../../../../../components/Header";
import { FaRegEdit } from "react-icons/fa";
import {
  MdOutlineFileDownload,
  MdOutlineFileUpload,
  MdOutlineHistory,
} from "react-icons/md";
import { PiCopy } from "react-icons/pi";
import { TbFlag3Filled } from "react-icons/tb";
import ReTable from "../../../../../../components/Table";
import BamServices from "../../../../../../rest-services/BamServices";
import { useToast } from "../../../../../../components/toast/toast";
import SearchInput from "../../../../../../components/SearchInput";
import { BsTags } from "react-icons/bs";
import CustomButton from "../../../../../../components/CustomButton";
import { IoMdRefresh } from "react-icons/io";
import { FiBox } from "react-icons/fi";
import SetUpServices from "../../../../../../rest-services/SetUpServices";
import ConfirmationDialog from "../../../../../../components/Deletionpopup";
import { LuCopyCheck } from "react-icons/lu";
import { GiSettingsKnobs } from "react-icons/gi";
import FilterDropdown from "../../../../../../components/FilterDropdown";
import HoverButton from "../../../../../../components/HoverButton";
import { RxDropdownMenu } from "react-icons/rx";
import BankBranchServices from "../../../../../../rest-services/BankBranchServices";
import Pagination from "../../../../../../components/PageNation";
import { VscVmActive } from "react-icons/vsc";

export default function BankAccountMaster() {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [isFilterPopupOpen, setIsFilterPopupOpen] = useState(false);
  const [showExtraIcons, setShowExtraIcons] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(15); // Default page size
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [hasFiltersApplied, setHasFiltersApplied] = useState(false);

  const { toast } = useToast();
  const { iconId } = useParams();

  useEffect(() => {
    fetchAssignee();
  }, []);
  const [assignee, setAssignee] = useState({
    clientId: "",
    id: "",
    userId: "",
    assigner: "",
  });
  useEffect(() => {
    if (assignee.clientId) {
      fetchBankAccount();
    }
  }, [assignee]);
  const handleNavigate = (path) => {
    navigate(path);
  };

  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          clientId: userDetail.clientId || "",
          id: userDetail.id || "",
          assigner: userDetail.username || "",
          userId: userDetail.userId || "",
        };
        setAssignee(assigneeData);
      } else {
      }
    } catch (error) { }
  };

  const handleBreadcrumbClick = (crumb) => {
    if (crumb.title === "BAM") {
      navigate("/bam");
    } else if (crumb.title === "Bank Master Data") {
      navigate("/3/BAM/26/account-master");
    } else if (crumb.title === "Account Master") {
      navigate("/3/BAM/26/account-master");
    } else if (crumb.path) {
      navigate(crumb.path);
    }
  };

  const downLoadData = async () => {
    try {
      const response = await BamServices.getExportData(
        assignee.clientId,
        filters
      );

      if (response) {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "bank_account.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        toast("success", "Downloaded Successfully");
      }
    } catch (error) {
      console.error("Error downloading data:", error);
      toast("error", "Failed to download data");
    }
  };



  const existingButtons = BamButtonsConfigs.screen5.map((button) => {
    let onClick;
    let title;

    if (button.icon === IoMdRefresh) {
      onClick = () => {
        fetchBankAccount(1);
        setCurrentPage(1);
      };
      title = "Refresh";
    } else if (button.icon === IoAddCircleOutline) {
      onClick = () => handleNavigate("/3/BAM/26/account-master-add/1");
      title = "Add Account";
    } else if (button.icon === IoSettingsOutline) {
      // onClick = handleSettingsButtonClick;
      title = "Table Settings";
    } else if (button.icon === GiSettingsKnobs) {
      onClick = () => {
        setIsFilterPopupOpen(true);
      };
      title = "Filter";
    } else if (button.icon === MdOutlineFileUpload) {
      // onClick = () => fileInputRef.current.click();
      title = "Upload File";
    } else if (button.icon === MdOutlineFileDownload) {
      onClick = () => downLoadData();
      title = "Download File";
    } else {
      onClick = button.onClick || (() => { }); // Provide a default no-op function if onClick is undefined
      title = button.title || ""; // Use existing title or default to an empty string
    }

    return {
      ...button,
      onClick, // Reference the function without invoking it
      title, // Add the title property to the button configuration
    };
  });


  const extraButtons = [
    {
      icon: IoMdCheckmark,
      title: "Approve",
      disabled: selectedRows.length === 0,
      onClick: () => handleApprove(selectedRows),
    },
    {
      icon: IoMdClose,
      title: "Reject",
      onClick: () => handleReject(selectedRows),
      disabled: selectedRows.length === 0,
    },
    {
      icon: IoMdCreate,
      onClick: () =>
        navigate(`/3/BAM/26/account-master-add/3/${selectedId}`, {
          state: { mode: "edit" },
        }),
      title: "Edit",
      disabled: selectedRows.length === 0,
    },
    {
      icon: FaRegEdit,
      onClick: () => handleClickUpdate(selectedId),
      title: "Bank Account Number Update",
      disabled: selectedRows.length === 0,
    },

    {
      icon: BsTags,
      onClick: () => {
        setModalOpen(true);
      },
      title: "Status Change",
      disabled: selectedRows.length === 0,
    },
    {
      icon: FiBox,
      onClick: () => {
        setCompanyModalOpen(true);
      },
      title: "Company Change",
      disabled: selectedRows.length === 0,
    },
    {
      icon: PiCopy,
      onClick: () => navigate(`/3/BAM/26/account-master-add/5/${selectedId}`),
      title: "Copy",
      disabled: selectedRows.length === 0,
    },
    {
      icon: LuCopyCheck,
      onClick: () => navigate(`/3/BAM/26/account-master-add/6/${selectedId}`),
      title: "Special Copy",
      disabled: selectedRows.length === 0,
    },
    {
      icon: MdOutlineHistory,
      onClick: () =>
        navigate(`/bam-audit-trail/${selectedId}/26`, {
          state: { mode: "edit" },
        }),
      title: "Audit Trail",
      disabled: selectedRows.length === 0,
    },
    { icon: IoMdTrash, onClick: () => handleDelete(), title: "Delete", disabled: selectedRows.length === 0, },
    {
      icon: VscVmActive,
      onClick: () => handleActiveInactive,
      title: "Active/Inactive",
      disabled: selectedRows.length === 0,
    },
  ];

  const actionButtons = existingButtons.concat(
    extraButtons.map((button) => ({
      ...button,
      disabled: selectedRows.length == 0,
      onClick: selectedRows.length > 0 ? button.onClick : null,
    }))
  );

  const handleShowAll = () => {
    setPageSize(totalCount); // Set page size to total count to display all records
    setCurrentPage(1); // Reset to the first page
    fetchBankAccount(1, searchQuery); // Fetch data with updated page size
  };

  const handleApprove = async (selectedRows) => {
    try {
      const matchingRow = tableData.find((row) => row.id === selectedRows);
      if (matchingRow && matchingRow.status === 3) {
        toast("warning", "Already Approved");
        return false;
      }
      const response = await BamServices.approveBankAccount(
        selectedRows,
        assignee.userId,
        assignee.assigner
      );
      if (response.status === 200) {
        toast("success", "Approved Successfully");
        fetchBankAccount(currentPage);
        setSelectedRows([]);
        setSelectedId([]);
        setShowExtraIcons(false);
      } else {
        toast("error", `${response.message}`);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleReject = async (selectedRows) => {
    try {
      const matchingRow = tableData.find((row) => row.id === selectedRows);
      if (matchingRow && matchingRow.status === 4) {
        toast("warning", "Already In Rejected Status");
        return false;
      }
      const response = await BamServices.rejectBankAccount(
        selectedRows,
        assignee.userId
      );
      if (response.status === 200) {
        toast("success", "Bank Account Rejected");
        fetchBankAccount(currentPage);
        setSelectedRows([]);
        setSelectedId([]);
        setShowExtraIcons(false);
      } else {
        toast("error", `${response.message}`);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };


  // //Navigate To Special Edit Page
  const handleClickUpdate = async (id) => {
    try {
      const matchingRow = tableData.find((row) => row.id === id);
      if (!matchingRow) {
        toast("error", "Row not found.");
        return;
      }

      const { accountNo, status } = matchingRow;


      if (accountNo) {
        console.log("Id is", id);

        handleNavigate(`/3/BAM/26/account-master-add/2/${id}`);
      }

      else {
        if (status === 3) {
          handleNavigate(`/3/BAM/26/account-master-add/2/${id}`);
        }

        else if (status === 4) {
          handleNavigate(`/3/BAM/26/account-master-add/2/${id}`);
        }
        else if (!accountNo) {
          toast("error", "Since the Request is not approved, Bank account no. update is not possible");
        } else {
          toast("error", "Since the Request is not approved, Bank account no. update is not possible");
        }
      }
    } catch (error) {
      console.error("Error:", error);
      toast("error", "An unexpected error occurred.");
    }
  };




  const [isModalOpen, setModalOpen] = useState(false);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const [isCompanyModalOpen, setCompanyModalOpen] = useState(false);

  const handleCompanyModalClose = () => {
    setCompanyModalOpen(false);
  };

  const handleDelete = async () => {
    if (selectedRows.length === 0) return;
    setShowConfirmation(true);
  };

  const cancelDeletion = () => {
    setShowConfirmation(false);
  };

  const confirmDeletion = async () => {
    setShowConfirmation(false);
    setLoading(true);
    try {
      const response = await BamServices.deleBankAccount(
        selectedId,
        assignee.clientId
      );
      if (response.status === 200) {
        toast("success", "Deleted Successfully");
        fetchBankAccount(currentPage);
        setSelectedRows([]);
        setSelectedId([]);
        setShowExtraIcons(false);
      } else {
        toast("error", `${response.message}`);
      }
      selectedId(null);
      fetchBankAccount(currentPage);
    } catch (error) {
      console.error("Error deleting Bank Account:", error);
    } finally {
      setLoading(false);
    }
  };


  const handleActiveInactive = async () => {
    try {
      const response = await BamServices.activeInactiveBankAccount(
        selectedId,
        assignee.userId
      );
      if (response.status === 200) {
        toast("success", response.message);
        fetchBankAccount(currentPage);
        setSelectedRows([]);
        setSelectedId([]);
        setShowExtraIcons(false);
      } else {
        toast("error", response.message);
      }
    } catch (error) { }
  };

  const columns = React.useMemo(() => [
    {
      Header: "Status",
      accessor: "status",
      width: 50, Cell: ({ value }) => {
        let color;
        switch (value) {

          case 0:
            color = "gray"; // Gray for status 0
            break;
          case 1:
            color = "#AF52DE"; // Purple for status 1
            break;
          case 2:
            color = "yellow"; // Yellow for status 2
            break;
          case 3:
            color = "orange"; // Orange for status 3
            break;
          case 4:
            color = "#29CB00"; // Green for status 4
            break;
          case 5:
            color = "red"; // Red for status 5
            break;
          case 6:
            color = "black"; // Balck for status 6
            break;
          default:
            color = "black"; // Default black flag
        }
        return <TbFlag3Filled style={{ color, textAlign: "left" }} />;
      },
    },
    {
      Header: "Request No",
      accessor: "reqNo",
      width: 150,
      aling: "left",
    },
    {
      Header: "Account Status",
      accessor: "accountStatus",
      width: 200,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Approve For",
      accessor: "approveFor",
      width: 200,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Company Code",
      accessor: "companyID",
      width: 200,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Bank Account ID",
      accessor: "bankAccountId",
      width: 200,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Bank Account No",
      accessor: "accountNo",
      width: 200,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Bank Account Description",
      accessor: "bankAccountDescription",
      width: 200,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "IBAN No",
      accessor: "iBanNo",
      width: 200,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Bank Account Currency",
      accessor: "currencyID",
      width: 200,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Bank Statement",
      accessor: "bankStatements[0].statementIdentifier",
      width: 200,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Bank Branch",
      accessor: "BankBranch.bankBranch",
      width: 200,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Branch Name",
      accessor: "BankBranch.branchName",
      width: 200,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Bank Id",
      accessor: "BankBranch.BankId",
      width: 200,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Bank Name",
      accessor: "BankBranch.BankName",
      width: 200,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Bank Bic",
      accessor: "BankBranch.identifier[0].bankBIC",
      width: 200,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "County Code",
      accessor: "BankBranch.identifier[0].country_Code",
      width: 200,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },

    {
      Header: "Country Code Type",
      accessor: "BankBranch.identifier[0].countryCodeType",
      width: 200,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },

    {
      Header: "MICR",
      accessor: "BankBranch.identifier[0].micr",
      width: 200,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "OverDraft"
      , accessor: "overDraft"
      , width: 200,
      Cell: ({ value }) => {
        switch (value) {
          case 1:
            return <div style={{ textAlign: "left" }}> Yes</div>;
          case 0:
            return <div style={{ textAlign: "left" }}> No</div>;

          default:
            return <div style={{ textAlign: "left" }}> No</div>;
        }
      },
    },
    {
      Header: "Account Type",
      accessor: "accountID",
      width: 200,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "ZBA Type",
      accessor: "zbaID",
      width: 200,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Notes",
      accessor: "notes",
      width: 200,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "LGE Code",
      accessor: "legalEntityID",
      width: 200,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Legal Entity Name",
      accessor: "",
      width: 200,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Bu Group",
      accessor: "buGroup",
      width: 200,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Bu Group Name",
      accessor: "buGroupName",
      width: 200,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Last Statement Date",
      accessor: "bankStatements[0].lastStatementDate",
      width: 200,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Last Statement Closing Balance",
      accessor: "bankStatements[0].lastStateClosingbalance",
      width: 200,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Last Statement NO",
      accessor: "bankStatements[0].lastStatementNo",
      width: 200,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "OD Limit",
      accessor: "bamOdDetails[0].odLimit",
      width: 200,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Interest Indicator",
      accessor: "bamOdDetails[0].intrestIndicator",
      width: 200,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "OD Date",
      accessor: "bamOdDetails[0].odDate",
      width: 200,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Minimum Balance",
      accessor: "bamControls[0].minimumBalance",
      width: 200,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Dormant",
      accessor: "bamControls[0].dormant",
      width: 200,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Main Bank Account",
      accessor: "bamControls[0].mainBankAccount",
      width: 200,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Created By",
      accessor: "created_by",
      width: 200,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Created Date",
      accessor: "creationDate",
      width: 200,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, "0"); // Adds leading zero if day < 10
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Adds leading zero if month < 10
        const year = date.getFullYear();
        return <div style={{ textAlign: "left" }}> {`${day}-${month}-${year}`}</div>;
      },
    },

    {
      Header: "Created Time",
      accessor: "creationTime",
      width: 200,
      Cell: ({ value }) => {
        if (!value) return ""; // Handle null or undefined values
        const date = new Date(value);
        const formattedTime = date.toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true, // Ensures 12-hour format with AM/PM
        });
        return <div style={{ textAlign: "left" }}>{formattedTime}</div>;
      },
    },

    {
      Header: "Updated By",
      accessor: "changed_by",
      width: 200,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Updated Date",
      accessor: "changedDate",
      width: 200,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, "0"); // Adds leading zero if day < 10
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Adds leading zero if month < 10
        const year = date.getFullYear();
        return <div style={{ textAlign: "left" }}> {`${day}-${month}-${year}`}</div>;
      },
    },
    {
      Header: "Updated Time",
      accessor: "changedTime",
      width: 200,
      Cell: ({ value }) => {
        if (!value) return ""; // Handle null or undefined values
        const date = new Date(value);
        const formattedTime = date.toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true, // Ensures 12-hour format with AM/PM
        });
        return <div style={{ textAlign: "left" }}>{formattedTime}</div>;
      },
    },
  ]);

  useEffect(() => {
    if (searchQuery.length === 0) {
      fetchBankAccount(currentPage);
    } else {
      fetchSearchedBankAccount(searchQuery);
    }
  }, [searchQuery]);

  const fetchSearchedBankAccount = async (searchQuery) => {
    setLoading(true);
    try {
      const response = await BamServices.searchBankAccount(searchQuery);
      if (response && response.data) {
        const totalCount = Number(response.totalCount) || 0;
        const pages = Math.ceil(totalCount / (pageSize || 1));
        setTableData(response.data.data);
        setTotalCount(totalCount);
        setTotalPages(pages);
      }
    } catch (error) {
      console.error("Error fetching Bank Account Data", error);
    } finally {
      setLoading(false);
    }
  };


  // const debouncedFetchStateProvinces = useCallback(
  //   debounce((page) => {
  //     fetchBankAccount(page);
  //   }, 500),
  //   []
  // );

  useEffect(() => {
    fetchBankAccount(currentPage);
    // debouncedFetchStateProvinces(currentPage);
    // return () => debouncedFetchStateProvinces.cancel();
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const fetchBankAccount = async (page, searchQuery) => {
    setLoading(true);
    if (!assignee.clientId) {
      return null;
    }
    try {
      const response = await BamServices.getDataForTable(
        assignee.clientId,
        pageSize,
        page,
        searchQuery
      );
      if (response && response.data) {
        const totalCount = Number(response.totalCount) || 0;
        const pages = Math.ceil(totalCount / (pageSize || 1));

        setTableData(response.data);
        setTotalCount(totalCount);
        setTotalPages(pages);
      }
    } catch (error) {
      console.error("Error fetching Bank Account Data", error);
    } finally {
      setLoading(false);
    }
  };

  const onSelectRow = (rowId) => {
    setSelectedRows((prevSelectedRows) => {
      const isSelected = prevSelectedRows.includes(rowId);
      const nextSelectedRows = isSelected
        ? prevSelectedRows.filter((id) => id !== rowId)
        : [...prevSelectedRows, rowId];
      setShowExtraIcons(nextSelectedRows.length === 1);
      if (nextSelectedRows.length === 1 && !isSelected) {
        setSelectedId(rowId);
      } else if (nextSelectedRows.length === 0) {
        setSelectedId(null);
      }
      return nextSelectedRows;
    });
  };

  const onSelectAll = () => {
    if (selectedRows.length === tableData.length) {
      setSelectedRows([]);
      setSelectedId(null);
      setShowExtraIcons(false);
    } else {
      setSelectedRows(tableData.map((row) => row.id));
    }
  };

  // Function to handle double-click on a row
  const handleDoubleClick = (id) => {
    navigate(`/3/BAM/26/account-master-add/4/${id}`, {
      state: { mode: "view" },
    });
  };

  const AssignNewStatusModal = ({ isOpen, onClose }) => {
    const [data, setData] = useState([]);
    const [status, setStatus] = useState("Open");
    const [options, setOptions] = useState({
      receiveMT940: false,
      ftout: false,
      ftin: false,
      cashAccounting: false,
      reconciliation: false,
      odCalculation: false,
      loanRepayment: false,
      lcRepayment: false,
    });
    const [validFrom, setValidFrom] = useState("");

    const handleOptionChange = (key) => {
      setOptions((prev) => ({ ...prev, [key]: !prev[key] }));
    };
    useEffect(() => {
      fetchStatus();
    }, []);

    const fetchStatus = async () => {
      try {
        const status = tableData.find((row) => row.id === selectedId);

        const response = await BamServices.getAccountStatus(
          assignee.clientId,
          status.accountStatus
        );
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching status:", error);
      }
    };
    const handleSubmit = async () => {
      const data = {
        client: assignee.clientId,
        accountId: selectedId,
        status: status,
        // receiveMt940: options.receiveMT940 ? 1 : 0,
        // ftOut: options.ftout ? 1 : 0,
        // ftIn: options.ftin ? 1 : 0,
        // cashAccounting: options.cashAccounting ? 1 : 0,
        // reconciliation: options.reconciliation ? 1 : 0,
        // odCalculation: options.odCalculation ? 1 : 0,
        // loanRepayments: options.loanRepayment ? 1 : 0,
        // lcRepayments: options.lcRepayment ? 1 : 0,
        validFrom,
        changedBy: assignee.userId,
      };
      const response = await BamServices.assignNewStatus(data);
      if (response.status === 200) {
        toast("success", "Status Changed  Successfully");
        fetchBankAccount(currentPage);
        onClose();
      } else {
        toast("error", "Status Change Failed");
      }
    };

    const handleStatusChange = (e) => {
      setStatus(e.target.value);

      const selectedStatus = data.find(
        (status) => status.id.toString() === e.target.value
      );
      if (selectedStatus) {
        setOptions({
          receiveMT940: selectedStatus.receiveMt940 === 1 ? true : false,
          ftout: selectedStatus.ftOut === 1 ? true : false,
          ftin: selectedStatus.ftIn === 1 ? true : false,
          cashAccounting: selectedStatus.cashAccounting === 1 ? true : false,
          reconciliation: selectedStatus.reconciliation === 1 ? true : false,
          odCalculation: selectedStatus.odCalculation === 1 ? true : false,
          loanRepayment: selectedStatus.loanRepayments === 1 ? true : false,
          lcRepayment: selectedStatus.lcRepayments === 1 ? true : false,
        });
      }
    };

    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex items-center justify-center z-50">
        <div className="ml-16 bg-white p-6 rounded-lg shadow-lg w-3/4 max-w-3xl h-half relative">
          <h2 className="text-lg font-semibold mb-4 text-blue-600 p-4">
            Assigning New Status
          </h2>

          <div className="display-flex mb-4 flex items-center p-4">
            <label className="mr-6">
              Status <span className="text-red-600">*</span>
            </label>
            <span className="ml-3">:</span>
            <select
              value={status}
              onChange={handleStatusChange}
              className=" ml-10 border-gray-300 border rounded px-2 py-1 w-1/2"
            >
              <option value="">Select Status</option>
              {data.length > 0 ? (
                data.map((status) => (
                  <option key={status.id} value={status.id}>
                    {status.status}
                  </option>
                ))
              ) : (
                <option value="">Loading...</option>
              )}
            </select>
          </div>

          <div className="mb-6 mt-6 p-4">
            <div className="grid grid-cols-4 gap-4">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={options.receiveMT940}
                  //onChange={() => handleOptionChange("receiveMT940")}
                  className="mr-2 border-2 border-blue-500 focus:ring-blue-500"
                />
                Receive MT940
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={options.ftout}
                  //onChange={() => handleOptionChange("ftout")}
                  className="mr-2"
                />
                FTOUT
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={options.ftin}
                  // onChange={() => handleOptionChange("ftin")}
                  className="mr-2"
                />
                FTIN
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={options.cashAccounting}
                  //  onChange={() => handleOptionChange("cashAccounting")}
                  className="mr-2"
                />
                Cash Accounting
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={options.reconciliation}
                  // onChange={() => handleOptionChange("reconciliation")}
                  className="mr-2"
                />
                Reconciliation
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={options.odCalculation}
                  //onChange={() => handleOptionChange("odCalculation")}
                  className="mr-2"
                />
                OD Calculation
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={options.loanRepayment}
                  //  onChange={() => handleOptionChange("loanRepayment")}
                  className="mr-2"
                />
                Loan Repayment
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={options.lcRepayment}
                  //onChange={() => handleOptionChange("lcRepayment")}
                  className="mr-2"
                />
                LC Repayment
              </label>
            </div>
          </div>

          <div className="display-flex mb-4 flex items-center p-4">
            <label className="mr-6">
              Valid From <span className="text-red-600">*</span>
            </label>
            <input
              value={validFrom}
              onChange={(e) => setValidFrom(e.target.value)}
              type="Date"
              className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
              required
            />
          </div>


          <div className="flex justify-end mt-4">
            <HoverButton
              title={"Close"}
              className={"mr-2"}
              onClick={onClose}
            />
            <CustomButton
              onClick={() => {
                handleSubmit();
              }}
              title={"Save"}
            />
          </div>
        </div>
      </div>
    );
  };

  const AssignNewCompanyCode = ({ isOpen, onClose, accountId }) => {
    const [company, setCompany] = useState([]);
    const [companyCode, setCompanyCode] = useState([]);
    const [buGroup, setBuGroup] = useState([]);
    const [legalEntity, setLegalEntity] = useState([]);
    const [errors, setErrors] = useState({});
    const [validFrom, setValidFrom] = useState("");
    useEffect(() => {
      if (isOpen) {
        fetchCompnayCode();
      }
    }, []);

    const fetchCompnayCode = async () => {
      try {
        const company = await BamServices.getCompnayCode(accountId);

        if (company.data.status === 200) {
          setCompany(company.data.data);
        }
      } catch (error) { }
    };

    const handleSubmit = async () => {
      const data = {
        client: assignee.clientId,
        accountId,
        companyCode,
        buGroup,
        legalEntity,
        validFrom,
        changedBy: assignee.userId,
      };
      if (!companyCode || !validFrom) return toast("error", "Please Fill All The Aequired Fields");
      const response = await BamServices.assignNewCompanyCode(data);

      if (response.status === 200) {
        toast("success", "Company Code Successfully");
        fetchBankAccount(currentPage);
        onClose();
      } else {
        toast("error", "Company Code Failed");
      }
    };

    const handleCompanyCodeChange = (e) => {
      setCompanyCode(e.target.value);

      const selectedCompany = company.find(
        (item) => item.companyCode === e.target.value
      );
      if (selectedCompany) {
        setBuGroup(selectedCompany.buGroupCode);
        setLegalEntity(selectedCompany.legalEntityCode);
      }
    };

    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg w-3/4 max-w-4xl relative">
          <h2 className="text-lg font-semibold mb-4 text-blue-600 p-4">
            Assigning New Company Code
          </h2>
          <button
            className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
            onClick={onClose}
            aria-label="Close Modal"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>

          {/* <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          > */}
          {/* Company Code */}
          <div className="flex mb-4 items-center p-4 gap-4">
            <label
              htmlFor="companyCode"
              className="whitespace-nowrap font-medium"
            >
              Company <span className="text-red-600">*</span>
            </label>
            <span>:</span>
            <select
              id="companyCode"
              value={companyCode}
              onChange={handleCompanyCodeChange}
              className="border p-2 rounded w-72 focus:outline-none hover:border-blue-400 border-gray-300"
            >
              <option value="">Select Company</option>

              {company.length > 0 &&
                company.map((company) => (
                  <option key={company.id} value={company.companyCode}>
                    {" "}
                    {company.companyCode}
                  </option>
                ))}
            </select>
          </div>

          {/* Legal Entity and Bu Group */}
          <div className="flex mb-4 items-center p-4 gap-8">
            <div className="flex items-center gap-4">
              <label
                htmlFor="legalEntity"
                className="whitespace-nowrap font-medium"
              >
                Legal Entity
              </label>
              <span>:</span>
              <input
                id="legalEntity"
                type="text"
                value={legalEntity}
                readOnly
                className="border p-2 rounded w-72 focus:outline-none hover:border-blue-400 border-gray-300"
              />
            </div>
            <div className="flex items-center gap-4">
              <label
                htmlFor="buGroup"
                className="whitespace-nowrap font-medium"
              >
                Bu Group
              </label>
              <span>:</span>
              <input
                value={buGroup}
                id="buGroup"
                type="text"
                readOnly
                className="border p-2 rounded w-72 focus:outline-none hover:border-blue-400 border-gray-300"
              />
            </div>
          </div>

          {/* Valid From */}
          <div className="flex mb-4 items-center p-4 gap-4">
            <label
              htmlFor="validFrom"
              className="whitespace-nowrap font-medium"
            >
              Valid From<span className="text-red-600">*</span>
            </label>
            <span>:</span>
            <input
              id="validFrom"
              type="date"
              value={validFrom}
              onChange={(e) => setValidFrom(e.target.value)}
              className="border p-2 rounded w-72 focus:outline-none hover:border-blue-400 border-gray-300"
              required
            />
          </div>

          {/* Submit Button */}
          <div className="flex justify-center items-center mt-6">
            <CustomButton
              title="Submit"
              className="h-10 w-40 bg-blue-500 text-white rounded-lg shadow hover:bg-blue-600 focus:outline-none"
              onClick={handleSubmit}
            />
          </div>
          {/* </form> */}
        </div>
      </div>
    );
  };

  const statusOptions = [
    { value: "0", label: "Created", className: "text-gray-500" },
    { value: "1", label: "Changed", className: "text-[#AF52DE]" },
    { value: "2", label: "Waiting For Approval", className: "text-yellow-500" },
    { value: "3", label: "Modified", className: "text-orange-400" },
    { value: "4", label: "Approved", className: "text-[#29CB00]" },
    { value: "5", label: "Rejected", className: "text-red-500" },
    { value: "6", label: "Inactive", className: "text-black" },
  ];

  const [filters, setFilters] = useState({
    status: "",
    accountStatus: "",
    accountNo: "",
    bankAccountId: "",
    bankBranch: "",
    bankId: "",
    bank: "",
    companyCode: "",
    buGroup: "",
    legalEntity: "",
    country: "",
    approveFor: "",
    updated_by: "",
    updated_date_from: "",
    updated_date_to: "",
    created_by: "",
    created_date_from: "",
    created_date_to: "",
  });

  useEffect(() => {
    if (isFilterPopupOpen) {
      fetchStatus();
    }
  }, [isFilterPopupOpen]);

  const handleFilterChange = (e) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      status: e.target.value,
    }));
  };
  const [status, setStatus] = useState("");
  const fetchStatus = async () => {
    try {
      const response = await SetUpServices.getAccountStatus(assignee.clientId);
      setStatus(response.data.data);
    } catch (error) {
      console.error("Error fetching status:", error);
    }
  };

  const handleApplyFilter = async () => {
    try {
      const allFiltersEmpty = Object.values(filters).every(
        (value) =>
          value === "" ||
          (Array.isArray(value) && value.length === 0) ||
          value === undefined
      );
      setHasFiltersApplied(true);
      if (allFiltersEmpty) {
        setIsFilterPopupOpen(false);
        return null;
      }


      const response = await BamServices.getFilterBankAccount(filters);

      if (response.status === 200) {
        setTableData(response.accountDetailsWithStatus);
        setIsFilterPopupOpen(false);
      }
    } catch (error) {
      console.error("Error fetching filtered companies:", error);
    }
  };

  //Ccreated And Updated By Filtes Sets
  const [selectedCreatedBy, setSelectedCreatedBy] = useState([]);
  const [selectedUpdatedBy, setSelectedUpdatedBy] = useState([]);
  const [tempSelectedupdatedby, setTempSelectedupdatedby] =
    useState(selectedUpdatedBy);
  const [tempSelectedcreatedby, setTempSelectedcreatedby] =
    useState(selectedCreatedBy);
  const [filterType, setFilterType] = useState("1");
  const [isUpdatedByPopupOpen, setIsUpdatedByPopupOpen] = useState(false);
  const [isCreatedByPopupOpen, setIsCreatedByPopupOpen] = useState(false);
  const [createdbydatasearchQuery, setcreatedbydatasearchQuery] = useState("");
  const [updatedbysearchquery, setupdatedbysearchquery] = useState("");

  const handleApplyCreatedby = () => {
    setSelectedCreatedBy(tempSelectedcreatedby);
    setFilters((prevFilters) => ({
      ...prevFilters,
      created_by: tempSelectedcreatedby,
    }));
    setIsCreatedByPopupOpen(false);
  };

  const handleCreatedbyReset = () => {
    setTempSelectedcreatedby([]); // Reset selection
  };
  const handleCreatedBySelection = (createdBy) => {
    setTempSelectedcreatedby((prev) => {
      return prev.includes(createdBy)
        ? prev.filter((item) => item !== createdBy)
        : [...prev, createdBy];
    });
  };
  const seenCreatedBy = new Set();
  const seenUpdatedBy = new Set();
  const handleUpdatedbyReset = () => {
    setTempSelectedupdatedby([]); // Reset selection
  };
  const handleApplyUpdatedby = () => {
    setSelectedUpdatedBy(tempSelectedupdatedby);
    setFilters((prevFilters) => ({
      ...prevFilters,
      updated_by: tempSelectedupdatedby,
    }));
    setIsUpdatedByPopupOpen(false);
  };
  const handleUpdateBySelection = (changedby) => {
    setTempSelectedupdatedby((prev) => {
      return prev.includes(changedby)
        ? prev.filter((item) => item !== changedby)
        : [...prev, changedby];
    });
  };

  //Bank Account ID  Filter
  const [selectedBankAccountId, setSelectedBankAccountId] = useState([]);
  const [isBankAccountIdPopupOpen, setIsBankAccountIdPopupOpen] =
    useState(false);
  const [tempSelectedBankAccountId, setTempSelectedBankAccountId] = useState(
    selectedBankAccountId
  );
  const handleBankAccountIdSelection = (accountId) => {
    setTempSelectedBankAccountId((prev) => {
      return prev.includes(accountId)
        ? prev.filter((item) => item !== accountId)
        : [...prev, accountId];
    });
  };
  const handleBankAccountIdReset = () => {
    setTempSelectedBankAccountId([]); // Reset selection
  };

  const handleApplyBankAccountId = () => {
    setSelectedBankAccountId(tempSelectedBankAccountId);
    setFilters((prevFilters) => ({
      ...prevFilters,
      bankAccountId: tempSelectedBankAccountId,
    }));
    setIsCreatedByPopupOpen(false);
  };

  //Bank Branch Filter
  const [selectedBankBranch, setSelectedBankBranch] = useState([]);
  const [isBankBranchPopupOpen, setIsBankBranchPopupOpen] = useState(false);
  const [tempSelectedBankBranch, setTempSelectedBankBranch] =
    useState(selectedBankBranch);

  const [branch, setBranch] = useState([]);
  useEffect(() => {
    fetchBranches();
  }, [isBankBranchPopupOpen]);
  const fetchBranches = async () => {
    try {
      const response = await BankBranchServices.getAllBankBranch(
        "/get-all-bankbranch"
      );
      if (response && response.data) {
        setBranch(response.data);
      }
    } catch (error) {
      console.error("Error fetching branches:", error);
    }
  };

  const handleBankBranchSelection = (branch) => {
    setTempSelectedBankBranch((prev) => {
      return prev.includes(branch)
        ? prev.filter((item) => item !== branch)
        : [...prev, branch];
    });
  };

  const handleApplyBankBranch = () => {
    setSelectedBankBranch(tempSelectedBankBranch);
    setFilters((prevFilters) => ({
      ...prevFilters,
      bankBranch: tempSelectedBankBranch,
    }));
    setIsCreatedByPopupOpen(false);
  };
  const handleBankBranchIdReset = () => {
    setTempSelectedBankBranch([]); // Reset selection
  };

  //Bank ID  Filter
  const [selectedBankId, setSelectedBankId] = useState([]);
  const [isBankIdPopupOpen, setIsBankIdPopupOpen] = useState(false);
  const [tempSelectedBankId, setTempSelectedBankId] = useState(selectedBankId);
  const handleBankIdSelection = (accountId) => {
    setTempSelectedBankId((prev) => {
      return prev.includes(accountId)
        ? prev.filter((item) => item !== accountId)
        : [...prev, accountId];
    });
  };
  const handleBankIdReset = () => {
    setTempSelectedBankId([]); // Reset selection
  };

  const handleApplyBankId = () => {
    setSelectedBankId(tempSelectedBankId);
    setFilters((prevFilters) => ({
      ...prevFilters,
      bankId: tempSelectedBankId,
    }));
    setIsBankIdPopupOpen(false);
  };
  //Bank  Filter
  const [selectedBank, setSelectedBank] = useState([]);
  const [isBankPopupOpen, setIsBankPopupOpen] = useState(false);
  const [tempSelectedBank, setTempSelectedBank] = useState(selectedBank);
  const handleBankSelection = (account) => {
    setTempSelectedBank((prev) => {
      return prev.includes(account)
        ? prev.filter((item) => item !== account)
        : [...prev, account];
    });
  };
  const handleBankReset = () => {
    setTempSelectedBank([]); // Reset selection
  };

  const handleApplyBank = () => {
    setSelectedBank(tempSelectedBank);
    setFilters((prevFilters) => ({
      ...prevFilters,
      bank: tempSelectedBank,
    }));
    setIsBankPopupOpen(false);
  };

  //2/master-data/9/company Code
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [isCompanyPopupOpen, setIsCompanyPopupOpen] = useState(false);
  const [tempSelectedCompany, setTempSelectedCompany] =
    useState(selectedCompany);
  const handleCompanySelection = (account) => {
    setTempSelectedCompany((prev) => {
      return prev.includes(account)
        ? prev.filter((item) => item !== account)
        : [...prev, account];
    });
  };
  const handleCompanyReset = () => {
    setTempSelectedCompany([]); // Reset selection
  };

  const handleApplyCompany = () => {
    setSelectedCompany(tempSelectedCompany);
    setFilters((prevFilters) => ({
      ...prevFilters,
      companyCode: tempSelectedCompany,
    }));
    setIsCompanyPopupOpen(false);
  };

  //2/master-data/8/bugroup

  const [selectedBugroup, setSelectedBugroup] = useState([]);
  const [isBugroupPopupOpen, setIsBugroupPopupOpen] = useState(false);
  const [tempSelectedBugroup, setTempSelectedBugroup] =
    useState(selectedBugroup);
  const handleBugroupSelection = (account) => {
    setTempSelectedBugroup((prev) => {
      return prev.includes(account)
        ? prev.filter((item) => item !== account)
        : [...prev, account];
    });
  };
  const handleBugroupReset = () => {
    setTempSelectedBugroup([]); // Reset selection
  };

  const handleApplyBugroup = () => {
    setSelectedBugroup(tempSelectedBugroup);
    setFilters((prevFilters) => ({
      ...prevFilters,
      buGroup: tempSelectedBugroup,
    }));
    setIsBugroupPopupOpen(false);
  };

  //LegalEntity

  const [selectedEntity, setSelectedEntity] = useState([]);
  const [isEntityPopupOpen, setIsEntityPopupOpen] = useState(false);
  const [tempSelectedEntity, setTempSelectedEntity] = useState(selectedEntity);
  const [legalEntity, setLegaleEntity] = useState([]);
  useEffect(() => {
    fetchLegalEntity();
  }, [isEntityPopupOpen]);
  const fetchLegalEntity = async () => {
    try {
      const response = await BamServices.getAllLegalEntity();
      if (response && response.data) {
        setLegaleEntity(response.data);
      }
    } catch (error) { }
  };
  const handleEntitySelection = (account) => {
    setTempSelectedEntity((prev) => {
      return prev.includes(account)
        ? prev.filter((item) => item !== account)
        : [...prev, account];
    });
  };
  const handleEntityReset = () => {
    setTempSelectedEntity([]); // Reset selection
  };

  const handleApplyEntity = () => {
    setSelectedEntity(tempSelectedEntity);
    setFilters((prevFilters) => ({
      ...prevFilters,
      legalEntity: tempSelectedEntity,
    }));
    setIsEntityPopupOpen(false);
  };

  //Country Code
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [isCountryPopupOpen, setIsCountryPopupOpen] = useState(false);
  const [tempSelectedCountry, setTempSelectedCountry] =
    useState(selectedCountry);
  const handleCountrySelection = (account) => {
    setTempSelectedCountry((prev) => {
      return prev.includes(account)
        ? prev.filter((item) => item !== account)
        : [...prev, account];
    });
  };
  const handleCountryReset = () => {
    setTempSelectedCountry([]); // Reset selection
  };

  const handleApplyCountry = () => {
    setSelectedCountry(tempSelectedCountry);
    setFilters((prevFilters) => ({
      ...prevFilters,
      country: tempSelectedCountry,
    }));
    setIsCountryPopupOpen(false);
  };


  const [selectedApproveFor, setSelectedApproveFor] = useState([]);
  const [isApproveForPopupOpen, setIsApproveForPopupOpen] = useState(false);
  const [tempSelectedApproveFor, setTempSelectedApproveFor] =
    useState(selectedBugroup);
  const handleApproveForSelection = (account) => {
    setTempSelectedApproveFor((prev) => {
      return prev.includes(account)
        ? prev.filter((item) => item !== account)
        : [...prev, account];
    });
  };
  const handleApproveForReset = () => {
    setTempSelectedApproveFor([]); // Reset selection
  };

  const handleApplyApproveFor = () => {
    setSelectedApproveFor(tempSelectedApproveFor);
    setFilters((prevFilters) => ({
      ...prevFilters,
      approveFor: tempSelectedApproveFor,
    }));
    setIsApproveForPopupOpen(false);
  };

  const handleFilterDateFrom = (e) => {
    if (filterType === "1") {
      setFilters((prevFilters) => ({
        ...prevFilters,
        created_date_from: e.target.value,
      }));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        updated_date_from: e.target.value,
      }));
    }
  };
  const handleFilterDateto = (e) => {
    if (filterType === "1") {
      setFilters((prevFilters) => ({
        ...prevFilters,
        created_date_to: e.target.value,
      }));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        updated_date_to: e.target.value,
      }));
    }
  };

  const [newRequest, setNewRequest] = useState(false);

  const handleApplyNewRequest = (isChecked) => {
    if (isChecked) {
      setNewRequest(true);
      setFilters((prevFilters) => ({
        ...prevFilters,
        accountStatus: "0",
      }));
    } else {
      setNewRequest(false);
      setFilters((prevFilters) => ({
        ...prevFilters,
        accountStatus: "",
      }));
    }
  };

  const [accountNo, setAccountNo] = useState(false);
  const handleApplyAccountNo = (isChecked) => {
    if (isChecked) {
      setAccountNo(true);
      setFilters((prevFilters) => ({
        ...prevFilters,
        accountNo: "1",
      }));
    } else {
      setAccountNo(false);
      setFilters((prevFilters) => ({
        ...prevFilters,
        accountNo: "",
      }));
    }
  };
  const handleResetFilters = () => {
    setFilters({
      status: "",
      accountStatus: "",
      accountNo: "",
      bankAccountId: [],
      bankBranch: "",
      bankId: "",
      bank: "",
      companyCode: "",
      buGroup: "",
      legalEntity: "",
      country: "",
      updated_by: "",
      updated_date_from: "",
      updated_date_to: "",
      created_by: "",
      created_date_from: "",
      created_date_to: "",
    });
    setHasFiltersApplied(false);
    setSelectedBankAccountId([]);
    setSelectedBankBranch([]);
    setSelectedBankId([]);
    setSelectedBank([]);
    setSelectedCompany([]);
    setSelectedBugroup([]);
    setSelectedEntity([]);
    setSelectedCountry([]);
    setSelectedCreatedBy([]);
    setSelectedUpdatedBy([]);
    setNewRequest(false);
    setAccountNo(false);
    fetchBankAccount();
  };
  const FilterPopUp = () => {
    const [dataSearchQuery, setDataSearchQuery] = useState("");
    const [bankIdSerchQuery, setBankIdSerchQuery] = useState("");
    const [bankSerchQuery, setBankSerchQuery] = useState("");
    const [companySerchQuery, setCompanySerchQuery] = useState("");
    const [bugroupSerchQuery, setBugroupSerchQuery] = useState("");
    const [legalEntitySerchQuery, setLegalEntitySerchQuery] = useState("");
    const [countrySerchQuery, setCountrySerchQuery] = useState("");
    const [approveForSerchQuery, setApproveForSerchQuery] = useState("");
    const manualBugroups = ["New Request", "Bank Account ID Update", "Status Change", "Company Code Change"];
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-3xl z-50">
          <div className="flex justify-between items-center mt-2">
            <h2 className="text-customBlue text-lg font-semibold mb-5 ">
              Apply Filters
            </h2>
            <div className={"p-2"}>
              <HoverButton
                onClick={handleResetFilters}
                title={"Reset Filter"}
              />
            </div>
          </div>

          {/* Grid Layout */}
          <div className="grid grid-cols-2 gap-4 p-2">
            {/* Left-Side Single Column Fields */}
            <div className="col-span-2 sm:col-span-1">
              <div className="flex items-center mb-2">
                <label className="text-customGray mr-2 w-[140px] text-sm">
                  Status:
                </label>
                <FilterDropdown
                  name="status"
                  value={filters.status}
                  onChange={handleFilterChange}
                  options={statusOptions}
                  placeholder="Select Status"
                  className="bg-white text-sm"
                />
              </div>
            </div>
            <div className="flex items-center mb-2">
              <label className="text-customGray mr-2 w-[140px] text-sm">
                Account Status:
              </label>
              <select
                onChange={(e) => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    accountStatus: e.target.value,
                  }));
                }}
                value={filters.accountStatus}
                className="text-customGray text-sm border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer"
              >
                <option value="">Select Status</option>
                {(status || []).map((statusItem) => (
                  <option key={statusItem.id} value={statusItem.id}>
                    {statusItem.status}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 p-2">
            {/* Left-Side Single Column Fields */}
            <div className="col-span-2 sm:col-span-1">
              <div className="flex items-center mb-2">
                <label className="text-customGray mr-2 w-[140px] text-sm">
                  Bank Acc ID:
                </label>
                <button
                  className="text-customGray border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer "
                  onClick={() => {
                    setIsBankAccountIdPopupOpen(true);
                  }}
                >
                  <span className="truncate text-sm">
                    {selectedBankAccountId.length > 0
                      ? selectedBankAccountId.join(", ")
                      : "Select Account ID"}
                  </span>
                  <RxDropdownMenu className="ml-2 text-lg" />
                </button>
                {isBankAccountIdPopupOpen && (
                  <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
                    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-xl z-50">
                      <div className="flex justify-between items-center mb-4">
                        <h2 className="text-customBlue font-semibold">
                          Select Bank Account ID
                        </h2>
                        <HoverButton
                          onClick={handleBankAccountIdReset}
                          title={"Reset"}
                        />
                      </div>
                      {/* Search and Filter */}
                      <input
                        type="text"
                        className="border border-gray-300 p-2 rounded-md mb-4 w-full"
                        placeholder="Search BankAccount ID"
                        value={dataSearchQuery}
                        onChange={(e) => setDataSearchQuery(e.target.value)}
                      />
                      <div className="space-y-2 max-h-80 overflow-y-auto">
                        {tableData
                          .filter((accountid) => {
                            if (accountid.bankAccountId === null) return false;

                            if (dataSearchQuery) {
                              return accountid.bankAccountId
                                .toLowerCase()
                                .includes(dataSearchQuery.toLowerCase());
                            }

                            return true;
                          })
                          .map((accountid) => (
                            <div
                              key={accountid.id}
                              className="flex items-center"
                            >
                              <input
                                type="checkbox"
                                checked={tempSelectedBankAccountId.includes(
                                  accountid.bankAccountId
                                )}
                                onChange={() =>
                                  handleBankAccountIdSelection(
                                    accountid.bankAccountId
                                  )
                                }
                              />
                              <label className="ml-2">
                                {accountid.bankAccountId}
                              </label>
                            </div>
                          ))}
                      </div>
                      <div className="flex justify-end mt-4">
                        <HoverButton
                          title={"Cancel"}
                          className={"mr-2"}
                          onClick={() => setIsBankAccountIdPopupOpen(false)}
                        />
                        <CustomButton
                          onClick={() => {
                            setIsBankAccountIdPopupOpen(false);
                            handleApplyBankAccountId();
                          }}
                          title={"Apply"}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="flex items-center mb-2">
              <label className="text-customGray mr-2 w-[140px] text-sm">
                Bank Branch:
              </label>
              <button
                className="text-customGray border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer "
                onClick={() => {
                  setIsBankBranchPopupOpen(true);
                }}
                disabled={!filterType} // Disable button if no filter is selected
              >
                <span className="truncate text-sm  ">
                  {selectedBankBranch.length > 0
                    ? selectedBankBranch
                      .map((branchID) => {
                        const matchingBranch = branch.find(
                          (b) => b.bankBranch === branchID
                        );
                        return matchingBranch
                          ? matchingBranch.bankBranch
                          : "Unknown Branch";
                      })
                      .join(", ")
                    : "Select Bank Branch"}
                </span>
                <RxDropdownMenu className="ml-2 text-lg" />
              </button>
              {isBankBranchPopupOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
                  <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-xl z-50">
                    <div className="flex justify-between items-center mb-4">
                      <h2 className="text-customBlue font-semibold">
                        Select Bank Branch{" "}
                      </h2>
                      <HoverButton
                        onClick={handleBankBranchIdReset}
                        title={"Reset"}
                      />
                    </div>
                    {/* Search and Filter */}
                    <input
                      type="text"
                      className="border border-gray-300 p-2 rounded-md mb-4 w-full"
                      placeholder="Search Bank Branch"
                      value={dataSearchQuery}
                      onChange={(e) => setDataSearchQuery(e.target.value)}
                    />
                    <div className="space-y-2 max-h-80 overflow-y-auto">
                      {(() => {
                        const seenBranchIds = new Set();

                        return tableData
                          .filter((branchId) => {
                            if (branchId.branchId === null) return false;
                            //console.log("Branch ID", tableData);
                            if (seenBranchIds.has(branchId.branchID)) {
                              return false;
                            }

                            seenBranchIds.add(branchId.branchID);

                            const branchIDString = branchId.branchID
                              ? branchId.branchID.toString()
                              : "";

                            // Filter based on the search query
                            if (dataSearchQuery) {
                              return branchIDString
                                .toLowerCase()
                                .includes(dataSearchQuery.toLowerCase());
                            }

                            return true;
                          })
                          .map((branchId) => {
                            const matchingBranch = branch.find(
                              (b) => b.bankBranch === branchId.branchID
                            );

                            return (
                              <div
                                key={branchId.id}
                                className="flex items-center"
                              >
                                <input
                                  type="checkbox"
                                  checked={tempSelectedBankBranch.includes(
                                    branchId.branchID
                                  )}
                                  onChange={() =>
                                    handleBankBranchSelection(branchId.branchID)
                                  }
                                />
                                <label className="ml-2">
                                  {matchingBranch?.bankBranch ||
                                    "Unknown Branch"}
                                </label>
                              </div>
                            );
                          });
                      })()}
                    </div>
                    <div className="flex justify-end mt-4">
                      <HoverButton
                        title={"Cancel"}
                        className={"mr-2"}
                        onClick={() => setIsBankBranchPopupOpen(false)}
                      />
                      <CustomButton
                        onClick={() => {
                          setIsBankBranchPopupOpen(false);
                          handleApplyBankBranch();
                        }}
                        title={"Apply"}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 p-2">
            {/* Left-Side Single Column Fields */}
            <div className="col-span-2 sm:col-span-1">
              <div className="flex items-center mb-2">
                <label className="text-customGray text-sm mr-2 w-[140px]">
                  Bank ID:
                </label>
                <button
                  className="text-customGray border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer "
                  onClick={() => {
                    setIsBankIdPopupOpen(true);
                  }}
                  disabled={!filterType}
                >
                  <span className="truncate text-sm ">
                    {selectedBankId.length > 0
                      ? selectedBankId.join(", ")
                      : "Select Bank ID"}
                  </span>
                  <RxDropdownMenu className="ml-2 text-lg" />
                </button>
                {isBankIdPopupOpen && (
                  <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
                    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-xl z-50">
                      <div className="flex justify-between items-center mb-4">
                        <h2 className="text-customBlue font-semibold">
                          Select Bank ID
                        </h2>
                        <HoverButton
                          onClick={handleBankIdReset}
                          title={"Reset"}
                        />
                      </div>

                      <input
                        type="text"
                        className="border border-gray-300 p-2 rounded-md mb-4 w-full"
                        placeholder="Search Bank ID"
                        value={bankIdSerchQuery}
                        onChange={(e) => setBankIdSerchQuery(e.target.value)}
                      />
                      <div className="space-y-2 max-h-80 overflow-y-auto">
                        {(() => {
                          const seenBankIds = new Set();
                          return tableData
                            .filter((account) => {
                              if (!account.bankID) return false;

                              if (seenBankIds.has(account.bankID)) {
                                return false;
                              }

                              seenBankIds.add(account.bankID);

                              if (bankIdSerchQuery) {
                                return account.bankID
                                  .toLowerCase()
                                  .includes(bankIdSerchQuery.toLowerCase());
                              }

                              return true;
                            })
                            .map((account) => (
                              <div
                                key={account.id}
                                className="flex items-center"
                              >
                                <input
                                  type="checkbox"
                                  checked={tempSelectedBankId.includes(
                                    account.bankID
                                  )}
                                  onChange={() =>
                                    handleBankIdSelection(account.bankID)
                                  }
                                />
                                <label className="ml-2">{account.bankID}</label>
                              </div>
                            ));
                        })()}
                      </div>
                      <div className="flex justify-end mt-4">
                        <HoverButton
                          title={"Cancel"}
                          className={"mr-2"}
                          onClick={() => setIsBankIdPopupOpen(false)}
                        />
                        <CustomButton
                          onClick={() => {
                            setIsBankIdPopupOpen(false);
                            handleApplyBankId();
                          }}
                          title={"Apply"}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="flex items-center mb-2">
              <label className="text-customGray  text-sm mr-2 w-[140px]">
                Bank :
              </label>
              <button
                className="text-customGray border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer "
                onClick={() => {
                  setIsBankPopupOpen(true);
                }}
                disabled={!filterType}
              >
                <span className="truncate text-sm ">
                  {selectedBank.length > 0
                    ? selectedBank.join(", ")
                    : "Select Bank"}
                </span>
                <RxDropdownMenu className="ml-2 text-lg" />
              </button>
              {isBankPopupOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
                  <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-xl z-50">
                    <div className="flex justify-between items-center mb-4">
                      <h2 className="text-customBlue font-semibold">
                        Select Bank
                      </h2>
                      <HoverButton onClick={handleBankReset} title={"Reset"} />
                    </div>

                    <input
                      type="text"
                      className="border border-gray-300 p-2 rounded-md mb-4 w-full"
                      placeholder="Search Bank"
                      value={bankSerchQuery}
                      onChange={(e) => setBankSerchQuery(e.target.value)}
                    />
                    <div className="space-y-2 max-h-80 overflow-y-auto">
                      {(() => {
                        const seenBanks = new Set();
                        return tableData
                          .filter((account) => {
                            if (!account.bankName) return false;

                            if (seenBanks.has(account.bankName)) {
                              return false;
                            }

                            seenBanks.add(account.bankName);

                            if (bankSerchQuery) {
                              return account.bankName
                                .toLowerCase()
                                .includes(bankSerchQuery.toLowerCase());
                            }

                            return true;
                          })
                          .map((account) => (
                            <div key={account.id} className="flex items-center">
                              <input
                                type="checkbox"
                                checked={tempSelectedBank.includes(
                                  account.bankName
                                )}
                                onChange={() =>
                                  handleBankSelection(account.bankName)
                                }
                              />
                              <label className="ml-2">{account.bankName}</label>
                            </div>
                          ));
                      })()}
                    </div>
                    <div className="flex justify-end mt-4">
                      <HoverButton
                        title={"Cancel"}
                        className={"mr-2"}
                        onClick={() => setIsBankPopupOpen(false)}
                      />
                      <CustomButton
                        onClick={() => {
                          setIsBankPopupOpen(false);
                          handleApplyBank();
                        }}
                        title={"Apply"}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 p-2">
            {/* Left-Side Single Column Fields */}
            <div className="col-span-2 sm:col-span-1">
              <div className="flex items-center mb-2">
                <label className="text-customGray text-sm mr-2 w-[140px]">
                  Company Code:
                </label>
                <button
                  className="text-customGray text-sm border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer "
                  onClick={() => {
                    setIsCompanyPopupOpen(true);
                  }}
                  disabled={!filterType}
                >
                  <span className="truncate text-sm ">
                    {selectedCompany.length > 0
                      ? selectedCompany.join(", ")
                      : "Select Company Code"}
                  </span>
                  <RxDropdownMenu className="ml-2 text-lg" />
                </button>
                {isCompanyPopupOpen && (
                  <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
                    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-xl z-50">
                      <div className="flex justify-between items-center mb-4">
                        <h2 className="text-customBlue font-semibold">
                          Select Company Code
                        </h2>
                        <HoverButton
                          onClick={handleCompanyReset}
                          title={"Reset"}
                        />
                      </div>

                      <input
                        type="text"
                        className="border border-gray-300 p-2 rounded-md mb-4 w-full"
                        placeholder="Search Company Code"
                        value={companySerchQuery}
                        onChange={(e) => setCompanySerchQuery(e.target.value)}
                      />
                      <div className="space-y-2 max-h-80 overflow-y-auto">
                        {(() => {
                          const seenCompanys = new Set();
                          return tableData
                            .filter((account) => {
                              if (!account.companyID) return false;

                              if (seenCompanys.has(account.companyID)) {
                                return false;
                              }

                              seenCompanys.add(account.companyID);

                              if (bankIdSerchQuery) {
                                return account.companyID
                                  .toLowerCase()
                                  .includes(bankIdSerchQuery.toLowerCase());
                              }

                              return true;
                            })
                            .map((account) => (
                              <div
                                key={account.id}
                                className="flex items-center"
                              >
                                <input
                                  type="checkbox"
                                  checked={tempSelectedCompany.includes(
                                    account.companyID
                                  )}
                                  onChange={() =>
                                    handleCompanySelection(account.companyID)
                                  }
                                />
                                <label className="ml-2">
                                  {account.companyID}
                                </label>
                              </div>
                            ));
                        })()}
                      </div>
                      <div className="flex justify-end mt-4">
                        <HoverButton
                          title={"Cancel"}
                          className={"mr-2"}
                          onClick={() => setIsCompanyPopupOpen(false)}
                        />
                        <CustomButton
                          onClick={() => {
                            setIsCompanyPopupOpen(false);
                            handleApplyCompany();
                          }}
                          title={"Apply"}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="flex items-center mb-2">
              <label className="text-customGray text-sm mr-2 w-[140px]">
                Bu Group:
              </label>
              <button
                className="text-customGray border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer "
                onClick={() => {
                  setIsBugroupPopupOpen(true);
                }}
                disabled={!filterType}
              >
                <span className="truncate text-sm ">
                  {selectedBugroup.length > 0
                    ? selectedBugroup.join(", ")
                    : "Select Bugroup"}
                </span>
                <RxDropdownMenu className="ml-2 text-lg" />
              </button>
              {isBugroupPopupOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
                  <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-xl z-50">
                    <div className="flex justify-between items-center mb-4">
                      <h2 className="text-customBlue font-semibold">
                        Select Bu Group
                      </h2>
                      <HoverButton
                        onClick={handleBugroupReset}
                        title={"Reset"}
                      />
                    </div>

                    <input
                      type="text"
                      className="border border-gray-300 p-2 rounded-md mb-4 w-full"
                      placeholder="Search Bu Group"
                      value={bugroupSerchQuery}
                      onChange={(e) => setBugroupSerchQuery(e.target.value)}
                    />
                    <div className="space-y-2 max-h-80 overflow-y-auto">
                      {(() => {
                        const seenBugroups = new Set();
                        return tableData
                          .filter((account) => {
                            if (!account.buGroup) return false;

                            if (seenBugroups.has(account.buGroup)) {
                              return false;
                            }

                            seenBugroups.add(account.buGroup);

                            if (bugroupSerchQuery) {
                              return account.buGroup
                                .toLowerCase()
                                .includes(bugroupSerchQuery.toLowerCase());
                            }

                            return true;
                          })
                          .map((account) => (
                            <div key={account.id} className="flex items-center">
                              <input
                                type="checkbox"
                                checked={tempSelectedCompany.includes(
                                  account.buGroup
                                )}
                                onChange={() =>
                                  handleBugroupSelection(account.buGroup)
                                }
                              />
                              <label className="ml-2">{account.buGroup}</label>
                            </div>
                          ));
                      })()}
                    </div>
                    <div className="flex justify-end mt-4">
                      <HoverButton
                        title={"Cancel"}
                        className={"mr-2"}
                        onClick={() => setIsBugroupPopupOpen(false)}
                      />
                      <CustomButton
                        onClick={() => {
                          setIsBugroupPopupOpen(false);
                          handleApplyBugroup();
                        }}
                        title={"Apply"}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 p-2">
            {/* Left-Side Single Column Fields */}
            <div className="col-span-2 sm:col-span-1">
              <div className="flex items-center mb-2">
                <label className="text-customGray mr-2 w-[140px] text-sm">
                  Legal Entity:
                </label>
                <button
                  className="text-customGray border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer "
                  onClick={() => {
                    setIsEntityPopupOpen(true);
                  }}
                  disabled={!filterType}
                >
                  <span className="truncate text-sm ">
                    {selectedEntity.length > 0
                      ? selectedEntity
                        .map((entityID) => {
                          const matchingEntity = legalEntity.find(
                            (e) => e.id === entityID
                          );
                          return matchingEntity
                            ? matchingEntity.LGE_Code
                            : "Unknown Entity";
                        })
                        .join(", ")
                      : "Select Legal Entity"}
                  </span>
                  <RxDropdownMenu className="ml-2 text-lg" />
                </button>
                {isEntityPopupOpen && (
                  <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
                    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-xl z-50">
                      <div className="flex justify-between items-center mb-4">
                        <h2 className="text-customBlue font-semibold ">
                          Select Legal Entity
                        </h2>
                        <HoverButton
                          onClick={handleEntityReset}
                          title={"Reset"}
                        />
                      </div>

                      <input
                        type="text"
                        className="border border-gray-300 p-2 rounded-md mb-4 w-full"
                        placeholder="Search Legal Entity"
                        value={legalEntitySerchQuery}
                        onChange={(e) =>
                          setLegalEntitySerchQuery(e.target.value)
                        }
                      />
                      <div className="space-y-2 max-h-80 overflow-y-auto">
                        {(() => {
                          const seenEntity = new Set();

                          return tableData
                            .filter((entity) => {
                              if (entity.legalEntityID === null) return false;

                              if (seenEntity.has(entity.legalEntityID)) {
                                return false;
                              }

                              seenEntity.add(entity.legalEntityID);

                              const branchIDString = entity.legalEntityID
                                ? entity.legalEntityID.toString()
                                : "";

                              if (legalEntitySerchQuery) {
                                return branchIDString
                                  .toLowerCase()
                                  .includes(
                                    legalEntitySerchQuery.toLowerCase()
                                  );
                              }

                              return true;
                            })
                            .map((entity) => {
                              const matchingEnity = legalEntity.find(
                                (e) => e.id === entity.legalEntityID
                              );

                              return (
                                <div
                                  key={entity.id}
                                  className="flex items-center"
                                >
                                  <input
                                    type="checkbox"
                                    checked={tempSelectedEntity.includes(
                                      entity.legalEntityID
                                    )}
                                    onChange={() =>
                                      handleEntitySelection(
                                        entity.legalEntityID
                                      )
                                    }
                                  />
                                  <label className="ml-2">
                                    {matchingEnity?.LGE_Code ||
                                      "Unknown Branch"}
                                  </label>
                                </div>
                              );
                            });
                        })()}
                      </div>
                      <div className="flex justify-end mt-4">
                        <HoverButton
                          title={"Cancel"}
                          className={"mr-2"}
                          onClick={() => setIsEntityPopupOpen(false)}
                        />
                        <CustomButton
                          onClick={() => {
                            setIsEntityPopupOpen(false);
                            handleApplyEntity();
                          }}
                          title={"Apply"}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="flex items-center mb-2">
              <label className="text-customGray text-sm mr-2 w-[140px]">
                Country:
              </label>
              <button
                className="text-customGray border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer "
                onClick={() => {
                  setIsCountryPopupOpen(true);
                }}
                disabled={!filterType}
              >
                <span className="truncate text-sm">
                  {selectedCountry.length > 0
                    ? selectedCountry.join(", ")
                    : "Select Country"}
                </span>
                <RxDropdownMenu className="ml-2 text-lg" />
              </button>
              {isCountryPopupOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
                  <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-xl z-50">
                    <div className="flex justify-between items-center mb-4">
                      <h2 className="text-customBlue font-semibold">
                        Select Country
                      </h2>
                      <HoverButton
                        onClick={handleCountryReset}
                        title={"Reset"}
                      />
                    </div>

                    <input
                      type="text"
                      className="border border-gray-300 p-2 rounded-md mb-4 w-full"
                      placeholder="Search BankAccount ID"
                      value={countrySerchQuery}
                      onChange={(e) => setCountrySerchQuery(e.target.value)}
                    />
                    <div className="space-y-2 max-h-80 overflow-y-auto">
                      {(() => {
                        const seenCountry = new Set();
                        return tableData
                          .filter((accountid) => {
                            if (
                              !accountid.bamAddress ||
                              accountid.bamAddress.length === 0
                            )
                              return false;

                            const country = accountid.bamAddress[0]?.country;
                            if (country === null || country === "")
                              return false;
                            if (seenCountry.has(country)) {
                              return false; // Skip duplicates
                            }

                            seenCountry.add(country);

                            if (dataSearchQuery) {
                              return country
                                .toLowerCase()
                                .includes(dataSearchQuery.toLowerCase());
                            }

                            return true;
                          })
                          .map((accountid) => {
                            const country = accountid.bamAddress[0]?.country;
                            return (
                              <div
                                key={accountid.id}
                                className="flex items-center"
                              >
                                <input
                                  type="checkbox"
                                  checked={tempSelectedCountry.includes(
                                    country
                                  )}
                                  onChange={() =>
                                    handleCountrySelection(country)
                                  }
                                />
                                <label className="ml-2">
                                  {country || "Unknown Country"}
                                </label>
                              </div>
                            );
                          });
                      })()}
                    </div>
                    <div className="flex justify-end mt-4">
                      <HoverButton
                        title={"Cancel"}
                        className={"mr-2"}
                        onClick={() => setIsCountryPopupOpen(false)}
                      />
                      <CustomButton
                        onClick={() => {
                          setIsCountryPopupOpen(false);
                          handleApplyCountry();
                        }}
                        title={"Apply"}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 p-2">
            <div className="col-span-2 sm:col-span-1">
              <div className="flex items-center mb-2">
                {/* Select Dropdown */}
                <select
                  name="filterType"
                  value={filterType} // State to track the selected filter option
                  onChange={(e) => {
                    setFilterType(e.target.value); // Update the selected filter
                  }}
                  className="text-customGray text-sm border border-gray-300 p-2 rounded-md w-[120px] h-[40px] focus:outline-none hover:border-blue-400"
                >
                  <option value="1">Created By</option>
                  <option value="2">Updated By</option>
                </select>

                {/* Filter Button */}
                <button
                  className="text-customGray text-sm border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer ml-6 "
                  onClick={() => {
                    if (filterType === "1") {
                      setIsCreatedByPopupOpen(true);
                      setTempSelectedcreatedby(selectedCreatedBy);
                    } else if (filterType === "2") {
                      setIsUpdatedByPopupOpen(true);
                      setTempSelectedupdatedby(selectedUpdatedBy);
                    }
                  }}
                  disabled={!filterType} // Disable button if no filter is selected
                >
                  <span className="truncate">
                    {filterType === "1"
                      ? selectedCreatedBy.length > 0
                        ? selectedCreatedBy.join(", ")
                        : "Created By"
                      : filterType === "2"
                        ? selectedUpdatedBy.length > 0
                          ? selectedUpdatedBy.join(", ")
                          : "Updated By"
                        : "Select Filter"}
                  </span>
                  <RxDropdownMenu className="ml-2 text-lg" />
                </button>
              </div>
            </div>

            {isCreatedByPopupOpen && (
              <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
                <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-xl z-50">
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="text-customGray font-semibold">
                      Select User Id
                    </h2>
                    <HoverButton
                      onClick={handleCreatedbyReset}
                      title={"Reset"}
                    />
                  </div>
                  {/* Search and Filter */}
                  <input
                    type="text"
                    className="border border-gray-300 p-2 rounded-md mb-4 w-full"
                    placeholder="Search Created By"
                    value={createdbydatasearchQuery}
                    onChange={(e) =>
                      setcreatedbydatasearchQuery(e.target.value)
                    }
                  />
                  <div className="space-y-2 max-h-80 overflow-y-auto">
                    {tableData.map((created) => {
                      if (seenCreatedBy.has(created.created_by)) {
                        return null;
                      }

                      seenCreatedBy.add(created.created_by);

                      return (
                        <div key={created.id} className="flex items-center">
                          <input
                            type="checkbox"
                            checked={tempSelectedcreatedby.includes(
                              created.created_by
                            )}
                            onChange={() =>
                              handleCreatedBySelection(created.created_by)
                            }
                          />
                          <label className="ml-2">{created.created_by}</label>
                        </div>
                      );
                    })}
                  </div>
                  {/* Actions */}
                  <div className="flex justify-end mt-4">
                    <HoverButton
                      title={"Cancel"}
                      className={"mr-2"}
                      onClick={() => setIsCreatedByPopupOpen(false)}
                    />
                    <CustomButton
                      onClick={() => {
                        setIsCreatedByPopupOpen(false);
                        handleApplyCreatedby();
                      }}
                      title={"Apply"}
                    />
                  </div>
                </div>
              </div>
            )}
            {isUpdatedByPopupOpen && (
              <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
                <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-xl z-50">
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="text-customGray font-semibold">
                      Select User Id
                    </h2>
                    <HoverButton
                      onClick={handleUpdatedbyReset}
                      title={"Reset"}
                    />
                  </div>
                  {/* Search and Filter */}
                  <input
                    type="text"
                    className="border border-gray-300 p-2 rounded-md mb-4 w-full"
                    placeholder="Search Updated By"
                    value={updatedbysearchquery}
                    onChange={(e) => setupdatedbysearchquery(e.target.value)}
                  />
                  <div className="space-y-2 max-h-80 overflow-y-auto">
                    {tableData.map((updated) => {
                      if (seenUpdatedBy.has(updated.changed_by)) {
                        return null;
                      }
                      if (updated.changed_by === null) {
                        return null;
                      }

                      seenUpdatedBy.add(updated.changed_by);

                      return (
                        <div key={updated.id} className="flex items-center">
                          <input
                            type="checkbox"
                            checked={tempSelectedupdatedby.includes(
                              updated.changed_by
                            )}
                            onChange={() =>
                              handleUpdateBySelection(updated.changed_by)
                            }
                          />
                          <label className="ml-2">{updated.changed_by}</label>
                        </div>
                      );
                    })}
                  </div>

                  <div className="flex justify-end mt-4">
                    <HoverButton
                      title={"Cancel"}
                      className={"mr-2"}
                      onClick={() => setIsUpdatedByPopupOpen(false)}
                    />
                    <CustomButton
                      onClick={() => {
                        handleApplyUpdatedby();
                        setIsUpdatedByPopupOpen(false);
                      }}
                      title={"Apply"}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="flex items-center mb-2">
              <label className="text-customGray text-sm mr-2 w-[140px]">
                Approve For:
              </label>
              <button
                className="text-customGray border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer "
                onClick={() => {
                  setIsApproveForPopupOpen(true);
                }}
                disabled={!filterType}
              >
                <span className="truncate text-sm ">
                  {selectedApproveFor.length > 0
                    ? selectedApproveFor.join(", ")
                    : "Select Approve For"}
                </span>
                <RxDropdownMenu className="ml-2 text-lg" />
              </button>
              {isApproveForPopupOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
                  <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-xl z-50">
                    <div className="flex justify-between items-center mb-4">
                      <h2 className="text-customBlue font-semibold">
                        Select Approve For
                      </h2>
                      <HoverButton
                        onClick={handleApproveForReset}
                        title={"Reset"}
                      />
                    </div>

                    <input
                      type="text"
                      className="border border-gray-300 p-2 rounded-md mb-4 w-full"
                      placeholder="Search Bu Group"
                      value={approveForSerchQuery}
                      onChange={(e) => setApproveForSerchQuery(e.target.value)}
                    />
                    <div className="space-y-2 max-h-80 overflow-y-auto">
                      {manualBugroups
                        .filter((approvefor) => {
                          if (approveForSerchQuery) {
                            return approvefor
                              .toLowerCase()
                              .includes(approveForSerchQuery.toLowerCase());
                          }
                          return true;
                        })
                        .map((approvefor, index) => (
                          <div key={index} className="flex items-center">
                            <input
                              type="checkbox"
                              checked={tempSelectedApproveFor.includes(approvefor)}
                              onChange={() => handleApproveForSelection(approvefor)}
                            />
                            <label className="ml-2">{approvefor}</label>
                          </div>
                        ))}
                    </div>
                    <div className="flex justify-end mt-4">
                      <HoverButton
                        title={"Cancel"}
                        className={"mr-2"}
                        onClick={() => setIsBugroupPopupOpen(false)}
                      />
                      <CustomButton
                        onClick={() => {
                          setIsApproveForPopupOpen(false);
                          handleApplyApproveFor();
                        }}
                        title={"Apply"}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="col-span-2 sm:col-span-1">
              <div className="flex items-center mb-2">
                {filterType && (
                  <div className="col-span-2 flex justify-between items-center">
                    <div className="flex items-center">
                      <label className="text-customGray text-sm mr-2 w-[140px] pl-3">
                        {filterType === "createdBy"
                          ? " Date From:"
                          : " Date From:"}
                      </label>
                      <input
                        type="date"
                        name="dateFrom"
                        value={
                          filterType === "1"
                            ? filters.created_date_from
                            : filters.updated_date_from
                        }
                        onChange={(e) => handleFilterDateFrom(e)}
                        className="text-customGray text-sm border border-gray-300 p-2 rounded-md w-[165px] h-[40px] focus:outline-none hover:border-blue-400 ml-2"
                      />
                    </div>
                    <div className="flex items-center">
                      <label className="text-customGray text-sm mr-2 w-[140px] pl-12">
                        {filterType === "createdBy" ? " Date To:" : " Date To:"}
                      </label>
                      <input
                        type="date"
                        name="dateTo"
                        value={
                          filterType === "1"
                            ? filters.created_date_to
                            : filters.updated_date_to
                        }
                        onChange={(e) => handleFilterDateto(e)}
                        className="text-customGray text-sm border border-gray-300 p-2 rounded-md w-[165px] h-[40px] focus:outline-none hover:border-blue-400 ml-12"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between mt-4">
            <div className="flex items-center">
              <label className="text-customGray text-sm mr-2 ml-4">
                New Request
              </label>
              <input
                type="checkbox"
                className="accent-customBlue"
                checked={newRequest}
                onChange={(e) => handleApplyNewRequest(e.target.checked)}
              />
            </div>
            <div className="flex items-center">
              <label className="text-customGray text-sm mr-2">Account No</label>
              <input
                type="checkbox"
                className="accent-customBlue"
                checked={accountNo}
                onChange={(e) => handleApplyAccountNo(e.target.checked)}
              />
            </div>
            <div className="flex items-center">
              <HoverButton
                title="Cancel"
                className="mr-2"
                onClick={() => setIsFilterPopupOpen(false)}
              />
              <CustomButton onClick={handleApplyFilter} title="Apply" />
            </div>
          </div>
        </div>
      </div >
    );
  };

  return (
    <div>
      <div>
        <Header
          title={"BAM > Bank Master Data > Account Master"}
          onBreadcrumbClick={handleBreadcrumbClick}
        />
      </div>
      <div className="flex justify-between items-center -ml-1 pl-4">
        <div className="flex items-center">
          <SearchInput
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            onChange={(e) => fetchBankAccount(searchQuery)}
            placeholder="Search by Account Id"
          />
          <BamActions
            icons={iconId}
            buttons={actionButtons}
            selectedCount={selectedRows.length}
            // extraButtons={extraButtons}
            isExtraButtonsVisible={showExtraIcons}
            selectIds={selectedRows}
            hasFiltersApplied={hasFiltersApplied}
          />
        </div>
        <div className="flex items-center mr-4">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            totalCount={totalCount}
            onShowAll={handleShowAll}
          />
        </div>
      </div>
      <ReTable
        data={tableData}
        columns={columns}
        onSelectRow={onSelectRow}
        selectedRows={selectedRows}
        onSelectAll={onSelectAll}
        onRowDoubleClick={handleDoubleClick}
      />

      <div>
        {isModalOpen && (
          <AssignNewStatusModal
            isOpen={isModalOpen}
            onClose={handleModalClose}
            accountId={selectedId}
          />
        )}
      </div>
      <div>
        <AssignNewCompanyCode
          isOpen={isCompanyModalOpen}
          onClose={handleCompanyModalClose}
          accountId={selectedId}
        />
      </div>
      <ConfirmationDialog
        open={showConfirmation}
        title="Confirm Deletion"
        message="Are you sure you want to delete the selected Bank Account?"
        onConfirm={confirmDeletion}
        onCancel={cancelDeletion}
      />

      <div>{isFilterPopupOpen && <FilterPopUp />}</div>
    </div>
  );
}
