import React, { useEffect, useRef, useState } from 'react'
import Header from '../../../../../../components/Header'
import { useNavigate } from 'react-router-dom';
import Footer from '../../../../../../components/footer';
import CustomeLoading from '../../../../../../components/CustomeLoading';
import { IoIosCodeDownload, IoIosDownload, IoIosList, IoMdCloudDownload, IoMdCodeDownload } from 'react-icons/io';
import { Checkbox } from '@mui/material';
import * as XLSX from "xlsx";
import { useToast } from '../../../../../../components/toast/toast';
import ConfirmationDialog from '../../../../../../components/Deletionpopup';
import UserService from '../../../../../../rest-services/UserServices';
export default function UserUpload() {
    const navigate = useNavigate();
    const { toast } = useToast();
const fileInputRef = useRef(null);
const [tableData, setTableData] = useState([]);
const [buttonText, setButtonText] = useState("Upload from desktop");
const [isUploaded, setIsUploaded] = useState(false);
const [displayOption, setDisplayOption] = useState(""); // Default value
const [uploadOption, setUploadOption] = useState(""); // Default value
const [isDisplayChecked, setIsDisplayChecked] = useState(false); // State for checkbox
const [isUploadChecked, setIsUploadChecked] = useState(false); // State for checkbox
const [isError, setIsError] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
     const [assignee, setAssignee] = useState({ id: "", username: "" });
  const [alert, setAlert] = useState(null);

 // Allowed format (Replace with actual validation if needed)
 const allowedFormat = ["SLNo","userId","username",	"salute","firstName","middleName","lastName","AccountingSystemId","email","mobileNo","whatsAppNo","officeNo","extension","passportNo","drivingLicenseNo","localId","nationality","employeeNo","companyCodeId","department","validFrom","validTo","resignationDate","joiningDate","DoorNo","FloorNo","BuildingNo","BuildingName","LandMark","StreetNo","StreetName","RoadNo","RoadName","Block","cityName","areaName","addressLine1","addressLine2","addressLine3","countryId","StateProvince","District","PostalCode","ZipCode","POBox","language","DateFormat","timeFormat","timeZone","amountFormat","thousandSeparator","decimalSeparator","acceleratorForThousands","acceleratorForMillions"]; 
 
 const cancelDeletion = () => {
  setShowConfirmation(false);
};

const confirmDeletion = async () => {
  console.log("Download the excel sheet");
  // Simulate the download by creating an anchor element
  const fileUrl = "/User_Upload.xlsx"; 
  console.log("file ur is",fileUrl);
  
  const anchor = document.createElement("a");
  anchor.href = fileUrl;
  anchor.download = "User_Upload.xlsx"; // Set the file name for download
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);





}

const handleRadioChange = (e) => {
    setDisplayOption(e.target.value);
  };

  const handleRadioChange2 = (e) => {
    setUploadOption(e.target.value);
  };


  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setIsDisplayChecked(isChecked);

    if (isChecked) {
      setDisplayOption("1"); // Automatically select "Errors Only" when checked
    } else {
      setDisplayOption(""); // Reset radio button selection if unchecked
    }
  };

  const  handleCheckboxChange2 = (e) => {
    const isChecked = e.target.checked;
    setIsUploadChecked(isChecked);
    if (isChecked) {
        setUploadOption("3"); // Automatically select "Errors Only" when checked
      } else {
        setUploadOption(""); // Reset radio button selection if unchecked
      }
  };



    const handleBreadcrumbClick = (crumb) => {
        console.log("Breadcrumb clicked:", crumb.title);
        if (crumb.title === 'Admin') {
          console.log("Navigating to /Admin");
          navigate('/14/admin');
        } else if (crumb.title === 'User & SOD') {
          console.log("Navigating to User & SOD");
          navigate('/14/admin');
        }
        else if (crumb.title === 'User') {
          console.log("Navigating to /User");
          navigate('/14/admin/108/userscreen');
        }
        else if (crumb.title === 'Add') {
          console.log("Navigating to /User");
          // navigate('/useradd');
        }
        else if (crumb.title === "View") {
          console.log("View");
          // navigate("/14/admin");
        }
      };

      const handleClick = (e) => {
        fileInputRef.current.click();
      };

      const excelDateToJSDate = (excelDate) => {
        const startDate = new Date(Date.UTC(1900, 0, 1)); // Excel base date is 1900-01-01
        const correctedDate = new Date(startDate.getTime() + (excelDate - 2) * 24 * 60 * 60 * 1000); // Adjust for leap year bug
        return correctedDate;
      };


const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0]; // Get first sheet
      const sheet = workbook.Sheets[sheetName];

      // Ensure we read only headers
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      console.log("Raw Sheet Data:", jsonData); // Debugging: Check the first row

      if (jsonData.length === 0) {
        setButtonText("File Format is Incorrect !");
        setIsUploaded(false);
        setIsError(true);
        return;
      }

      // Extract headers from the first row
      const uploadedHeaders = jsonData[0].map(header => header.trim().toLowerCase());
      const expectedHeaders = allowedFormat.map(header => header.trim().toLowerCase());

      console.log("Uploaded Headers:", uploadedHeaders);
      console.log("Expected Headers:", expectedHeaders);

      // Validate headers
      const isValidFormat = expectedHeaders.every(header => uploadedHeaders.includes(header));

      if (isValidFormat) {
        // setTableData(XLSX.utils.sheet_to_json(sheet)); // Convert to JSON properly
        const rows = XLSX.utils.sheet_to_json(sheet, { raw: true }).slice(2);

      // Convert Excel date serial numbers to readable dates
      const formattedRows = rows.map((row) => {
        const formattedRow = { ...row };

        // Convert date fields
        if (formattedRow.validFrom && !isNaN(formattedRow.validFrom)) {
          const date = excelDateToJSDate(formattedRow.validFrom);
          formattedRow.validFrom = date.toISOString().split("T")[0]; // Format: YYYY-MM-DD
        }
        if (formattedRow.validTo && !isNaN(formattedRow.validTo)) {
          const date = excelDateToJSDate(formattedRow.validTo);
          formattedRow.validTo = date.toISOString().split("T")[0];
        }
        if (formattedRow.joiningDate && !isNaN(formattedRow.joiningDate)) {
          const date = excelDateToJSDate(formattedRow.joiningDate);
          formattedRow.joiningDate = date.toISOString().split("T")[0];
        }
        if (formattedRow.resignationDate && !isNaN(formattedRow.resignationDate)) {
          const date = excelDateToJSDate(formattedRow.resignationDate);
          formattedRow.resignationDate = date.toISOString().split("T")[0];
        }
    
        return formattedRow;
      });
    

      console.log("Formatted Table Data:", formattedRows);

      setTableData(formattedRows);
        setButtonText("File Uploaded");
        setIsUploaded(true);
        setIsError(false);
      } else {
        setButtonText("File Format is Incorrect !");
        setIsUploaded(false);
        setIsError(true);
        setShowConfirmation(true);
      }

      setTimeout(() => setIsUploaded(false), 1000);
    };
    reader.readAsArrayBuffer(file);
};

 
  useEffect(() => {
      fetchAssignee();
    }, []);
      
  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          clientId: userDetail.clientId || "",
          assigner: userDetail.username || "",
          userId: userDetail.userId || "",

        };

        setAssignee(assigneeData);

        // Log the id and assigner values to the console
        console.log("Assignee ID:", assigneeData.id);
        console.log("Assigner:", assigneeData.assigner);
        console.log("Assigner clientId:", assigneeData.clientId);
        console.log("Assigner userId:", assigneeData.userId);

      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex for basic email validation
    return emailRegex.test(email);
  };

  const isRowValid = (row) => {
    const isSLNovalid = row.SLNo && !isNaN(row.SLNo) && String(row.SLNo).length <= 5;
    const isUserIdValid = row.userId && String(row.userId).length <= 12;
    const isUsernameValid = row.username && String(row.username).length <= 12;
    const isFirstNameValid = row.firstName && String(row.firstName).length <= 20;
    const isLastNameValid = row.lastName && String(row.lastName).length <= 20;
    const isMobileNoValid = row.mobileNo && !isNaN(row.mobileNo) && String(row.mobileNo).length <= 13;
    const isNationalityValid = row.nationality && String(row.nationality).length <= 20;
    const isValidFromValid = row.validFrom && String(row.validFrom).length <= 10;
    const isValidToValid = row.validTo && String(row.validTo).length <= 10;
    const isCountryIdValid = row.countryId && String(row.countryId).length <= 2;
    const isEmailValid = row.email && String(row.email).length <= 80 && validateEmail(row.email);
  
    return (
      isSLNovalid &&
      isUserIdValid &&
      isUsernameValid &&
      isFirstNameValid &&
      isLastNameValid &&
      isMobileNoValid &&
      isNationalityValid &&
      isValidFromValid &&
      isValidToValid &&
      isCountryIdValid &&
      isEmailValid
    );
  };

  const successfulRecords = tableData.filter((row) => isRowValid(row));
console.log("successfulRecords data",successfulRecords);
console.log("uploadOption data",uploadOption);

let dataToDisplay;
if (displayOption === "1" && uploadOption === "") {
  console.log("displayOption is 1");
  // dataToDisplay = filteredData; // Display filteredData if displayOption is "1"
} else if (displayOption === "2" && uploadOption === "") {
  console.log("displayOption is 2");
  dataToDisplay = tableData; // Display tableData if displayOption is "2"
} else if (uploadOption === "3") {
  console.log("displayOption is 3");
  dataToDisplay = successfulRecords; // Display successfulRecords if uploadOption is "3"
} else if (uploadOption === "4") {
  console.log("displayOption is 4");
  dataToDisplay = tableData; // Display filteredData if uploadOption is "4"
} else {
  dataToDisplay = tableData; // Default to tableData if no conditions are met
}
console.log("dataToDisplay data is",dataToDisplay);

const storeArrayDataForUsers= async () => {
    console.log("successfulRecords data is",dataToDisplay);
    try {
      const data = {
        clientId: assignee.clientId,
        dataToDisplay,
        createdBy: assignee.userId,
      }
      console.log("storeArrayDataForUsers dataToDisplay is",data);
      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/store-array-users-data`,
        data
      );
      console.log("Roles Response data", response);
  
      if (response.status === 200) {
        toast("success", "Uploaded Users successfully");
      }
      else if(response.status === 400){
        toast("error", "Invalid or empty array of users");
      }
      else if(response.status === 404){
        toast("error", "Missing required fields");
      }
      else if(response.status === 500){
        toast("error", "Duplicate user IDs found.");
        console.log("response duplicate user id data",response.duplicates)
      }
    } catch (error) {
      console.error("Error fetching array users data", error);
    }
  };


  const handleSubmit = async () => {
      console.log("table data length is",tableData.length);

    if(buttonText === 'Upload from desktop'){
        toast("error", "Please upload a file.");
        return;
    }
    else if (tableData.length === 0) { 
        // alert("Please upload a file before submitting."); // Validation alert
        toast("error", "Please upload the correct file containing the data.");
        // toast("error", "Please upload a file containing at least one record.");
        return;
      }

         // Check if either of the checkboxes is selected
    if (!isDisplayChecked && !isUploadChecked) {
        toast("error", "Please check at least one checkbox (Display or Upload).");
        return;
    }

    // if (!validateForm()) return;
    console.log("tableData send to the prop",tableData);
    console.log("displayOption selected", displayOption);
    console.log("uploadOption selected", uploadOption);

    if(uploadOption !== '' && displayOption === '' ){
      console.log("User arry upload data");
      storeArrayDataForUsers();
    }
    // if(uploadOption == ''){
        // navigate(`/14/admin/108/user-upload/display-screen`, {
        //     state: { tableData, displayOption, uploadOption } // Passing tableData via state
        // });
    // }
    else {
      if(uploadOption != ''){
        storeArrayDataForUsers();
      }

      navigate(`/14/admin/108/user-upload/display-screen`, {
            state: { tableData, displayOption, uploadOption } // Passing tableData via state
        });
    }
  }

  return (
    <div>
      <div>

        {/* <NoActionLayout title={"Admin > User & SOD > User > Add"} /> */}
        <Header title={`Admin > User & SOD > User > Upload `}
        onBreadcrumbClick={handleBreadcrumbClick}
        backRoute={"/14/admin/108/userscreen"}
        />
        <div className="p-8 pr-24">
  <div className="flex flex-col space-y-8 p-10 border rounded-md">    

    {/* File Upload Section */}
    {/* <div className="flex items-center">
      <label className="flex items-center">
        <span className="w-20 font-medium text-gray-600">File</span>
        <span className="ml-4">:</span>
        <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
                accept=".xlsx, .xls"
              />
      </label>
      <button
        type="button"
         onClick={() => handleClick()}
        className={`border border-gray-300 p-2 w-80 h-10 focus:outline-none hover:border-blue-400 ml-6 rounded-full text-center
              ${isUploaded ? "text-green-500 animate-pulse" : isError ? "text-red-500" : "text-gray-700"}
              ${buttonText === 'File Uploaded' ? "text-green-500" : ''}
               `}
      >
      {buttonText}
      </button>
    </div>

    <a href="/User_Upload.xlsx" download style={{ display: 'inline-flex', alignItems: 'center', textDecoration: 'none' }}>
  <span className='text-customGray'>Download Excel format</span>
  <IoMdCloudDownload style={{ marginLeft: '8px' }} />
</a> */}
<div className="flex items-center">
  <label className="flex items-center">
    <span className="w-20 font-medium text-gray-600">File</span>
    <span className="ml-4">:</span>
    <input
      type="file"
      ref={fileInputRef}
      style={{ display: "none" }}
      onChange={handleFileChange}
      accept=".xlsx, .xls"
    />
  </label>
  
  <button
    type="button"
    onClick={() => handleClick()}
    className={`border border-gray-300 p-2 w-80 h-10 focus:outline-none hover:border-blue-400 ml-6 rounded-full text-center
      ${isUploaded ? "text-green-500 animate-pulse" : isError ? "text-red-500" : "text-gray-700"}
      ${buttonText === 'File Uploaded' ? "text-green-500" : ''}`}
  >
    {buttonText}
  </button>
  
  <a
    href="/User_Upload.xlsx"
    download
    style={{ display: 'inline-flex', alignItems: 'center', textDecoration: 'none', marginLeft: '16px' }}
  >
    <span className="text-customGray">Download Excel format</span>
    <IoMdCloudDownload style={{ marginLeft: '8px' }} />
  </a>
</div>


    {/* Display Section */}
    <div className="flex flex-col space-y-2 py-3">
      <div className="flex items-center">
        <Checkbox
            checked={isDisplayChecked}
            onChange={handleCheckboxChange} // Handle checkbox state change
            className="form-checkbox text-blue-500 border-blue-500 rounded transform scale-75"
        />
        <span className="ml-3 font-medium text-gray-700">Display</span>
      </div>
      <div className="flex space-x-6 ml-4">
        <label className="flex items-center">
          <input type="radio" value="1" name="displayOption" className="mr-2 accent-customBlue" 
          checked={displayOption === "1"} // Checked if selected
          onChange={handleRadioChange} // Update state on change
          disabled={!isDisplayChecked} // Disable if checkbox is not checked
          />
          <span>Errors Only</span>
        </label>
        <label className="flex items-center">
          {/* <input type="radio" name="displayOption" className="mr-2 accent-customBlue" /> */}
          <input
        type="radio"
        name="displayOption"
        value="2" // Value to track
        className="mr-2 accent-customBlue"
        checked={displayOption === "2"} // Checked if selected
        onChange={handleRadioChange} // Update state on change
        disabled={!isDisplayChecked} // Disable if checkbox is not checked
      />
          <span>All</span>
        </label>
      </div>
    </div>

    {/* Upload Section */}
    <div className="flex flex-col space-y-2 py-3">
      <div className="flex items-center">
         <Checkbox
                      onChange={handleCheckboxChange2}
                      checked={isUploadChecked}
                      className="form-checkbox text-blue-500 border-blue-500 rounded transform scale-75"
                    />
        <span className="ml-3 font-medium text-gray-700">Upload</span>
      </div>
      <div className="flex space-x-6 ml-4">
        <label className="flex items-center">
          <input type="radio" value='3' name="uploadOption" className="mr-2 accent-customBlue"
            checked={uploadOption === "3"} // Checked if selected
            onChange={handleRadioChange2} // Update state on change
            disabled={!isUploadChecked} 
           />
          <span>Only Successful records</span>
        </label>
        <label className="flex items-center">
          <input type="radio" value='4' name="uploadOption" className="mr-2 accent-customBlue"
           checked={uploadOption === "4"} // Checked if selected
           onChange={handleRadioChange2} // Update state on change
           disabled={!isUploadChecked} 
           />
          <span>All records</span>
        </label>
      </div>
    </div>

  </div> 
</div>

<ConfirmationDialog
              open={showConfirmation}
              title="Upload File"
              message="Do you need to download the latest upload format?"
              onConfirm={confirmDeletion}
              onCancel={cancelDeletion}
            />


      <Footer>
              <button
                onClick={handleSubmit}
                className="bg-customBlue text-sm text-white py-1 px-2  rounded hover:bg-blue-600 transition duration-200 ease-in-out w-28  whitespace-nowrap"
              >
                Run
              </button>
            </Footer>

        </div>
    </div>
  )
}
