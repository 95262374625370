
import React, { useEffect, useState } from 'react'
import Tabs from '../../../../../components/Tabs';
import Footer from '../../../../../components/footer';
import ButtonGroup from '../../../../../components/FooterButton';
import Header from '../../../../../components/Header';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import LegalEntityServices from '../../../../../rest-services/LegalEntityServices';
import { useToast } from "../../../../../components/toast/toast";
import { RxDropdownMenu } from 'react-icons/rx';
import CurrencyServicess from '../../../../../rest-services/CurrencyServices';
import CountryServices from '../../../../../rest-services/CountryServices';
import StateProvinceServices from '../../../../../rest-services/StateProvinceServices';
import BankIdServices from '../../../../../rest-services/BankIdServices';
import BankServices from '../../../../../rest-services/BankServices';
import CustomButton from '../../../../../components/CustomButton';
import { TbFlag3Filled } from 'react-icons/tb';
import ReusableModal from '../../../../../components/PopupComponent';
import LabelWithButton from '../../../../../components/InputButtonField';
import LabelWithInput from '../../../../../components/InputFiledComponent';
import TableWrapper from '../../../../../components/ScrollBarComponent';

export default function BankIdAdd() {
  const [activeTab, setActiveTab] = useState("addbankiddetails");
  const navigate = useNavigate();
  const { bankidId } = useParams();
  const location = useLocation();
  const { mode } = location.state || {};
  const isViewMode = mode === 'view';
  const [errors, setErrors] = useState({}); // Validation errors
  const [assignee, setAssignee] = useState({ id: "", assigner: "" }); // State to store assignee
  const { toast } = useToast();
  const [alert, setAlert] = useState(null);
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const [bankID, setBankID] = useState("");
  const [bankName, setBankName] = useState("");
  const isEditMode = !!bankidId;

  const [bank, setBank] = useState("");
  const [banks, setBanks] = useState([]);
  const [countryCodeType, setCountryCodeType] = useState([]);
  const [minLength, setMinLength] = useState([]);
  const [maxLength, setMaxLength] = useState([]);

  const [countryCode, setCountryCode] = useState(""); // Country Code
  const [countryName, setCountryName] = useState("");
  const [district, setdistrict] = useState("");
  const [poBox, setpoBox] = useState("");
  const [state, setstate] = useState("");
  const [stateName, setStateName] = useState('');
  // const [countryCode, setcountryCode] = useState("");
  const [countryId, setCountryId] = useState('');
  const [areaBlock, setareaBlock] = useState("");
  const [roadNo, setroadNo] = useState("");
  const [roadName, setRoadName] = useState("");
  const [streetNo, setstreetNo] = useState("");
  const [buildingName, setbuildingName] = useState("");
  const [buildingNo, setbuildingNo] = useState("");
  const [doorNo, setdoorNo] = useState("");
  const [floorNo, setfloorNo] = useState("");
  const [addressLine1, setaddressLine1] = useState("");
  const [addressLine2, setaddressLine2] = useState("");
  const [addressLine3, setaddressLine3] = useState("");
  const [countries, setCountries] = useState([]);
  const [stateProvinces, setStateProvinces] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(countryCode || "");
  const [postalCode, setpostalCode] = useState("");
  const [searchQueryState, setSearchQueryState] = useState(""); // State for search query
  const [selectedState, setSelectedState] = useState(state || "");

  const [streetName, setstreetName] = useState("");
  const [areaName, setareaName] = useState("");
  // const [cityName, setcityName] = useState("");
  const [landMark, setlandMark] = useState("");
  const [zipCode, setzipCode] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBank, setSelectedBank] = useState("");
  const [isModalOpenForState, setIsModalOpenForState] = useState(false);
  const [isCountryModalOpen, setIsCountryModalOpen] = useState(false);
  const [isBankModalOpen, setIsBankModalOpen] = useState(false);
  const [isStateModalOpen, setIsStateModalOpen] = useState(false);
  const [filteredStateProvinces, setFilteredStateProvinces] = useState([]);
  const [isValidationTriggered, setIsValidationTriggered] = useState(false);
  const [isCopyMode, setIsCopyMode] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [isStateNameModalOpen, setIsStateNameModalOpen] = useState(false);
  const [isCountryNameModalOpen, setIsCountryNameModalOpen] = useState(false);
  const [phase, setPhase] = useState("");
  const [sector, setSector] = useState("");
  const [village, setVillage] = useState("");
  const [town, setTown] = useState("");
  const [city, setCity] = useState("");
  const [status, setStatus] = useState("");
  useEffect(() => {
    fetchcountry();
    fetchStateProvinces();
    fetchbank();
    fetchAssignee();
  }, []);
  useEffect(() => {
    if (location.state?.copiedData) {
      setIsCopyMode(true);
      const copiedData = location.state.copiedData;
      // Set address fields
      if (copiedData.addresses && copiedData.addresses.length > 0) {
        const address = copiedData.addresses[0]; // Use the first address
        setdoorNo(address.doorNo);
        setfloorNo(address.floorNo);
        setbuildingNo(address.buildingNo);
        setbuildingName(address.buildingName);
        setlandMark(address.landMark);
        setstreetNo(address.streetNo);
        setstreetName(address.streetName);
        setroadNo(address.roadName);
        setRoadName(address.roadName);
        setareaBlock(address.areaBlock);
        setareaName(address.areaName);
        setaddressLine1(address.addressLine1);
        setaddressLine2(address.addressLine2);
        setaddressLine3(address.addressLine3);
        setstate(address.state);
        setdistrict(address.district);
        setpostalCode(address.postalCode);
        setzipCode(address.zipCode);
        setpoBox(address.poBox);
        setPhase(address.phase);
        setSector(address.sector);
        setVillage(address.village);
        setTown(address.town);
        setCity(address.city);
        setBankID(copiedData.bankID || "");
        setBankName(copiedData.bankName || "");
        if (copiedData.bank) {
          const selectedBank = banks.find(
            (bank) => bank.bankCode === copiedData.bank
          );
  
          if (selectedBank) {
            setBank(selectedBank.bankCode); // Set the legal entity code
            setSelectedBank(selectedBank.bankCode); // Update UI-selected value
          } else {
            console.warn('Legal entity not found for code:', copiedData.legalEntityCode);
          }
        }
        // Map country
        const selectedCountryData = countries.find(
          (country) => country.country === address.countryCode
        );
        if (selectedCountryData) {
          setSelectedCountry(selectedCountryData.country);
          setCountryCode(selectedCountryData.country);
          setCountryName(selectedCountryData.countryName);
        } else {
          console.warn('Country not found for code:', address.countryCode);
        }

        // Map state
        const selectedStateData = stateProvinces.find(
          (state) => state.state === address.state
        );
        if (selectedStateData) {
          setSelectedState(selectedStateData.state);
          setStateName(selectedStateData.State_Name);
          setstate(selectedStateData.state);
        } else {
          console.warn('State not found for code:', address.state);
        }
      } else {
        console.warn('No addresses found in copiedData.');
      }
      if (copiedData.countrycode && copiedData.countrycode.length > 0) {
        const countryc = copiedData.countrycode[0]; // Use the first address
        setCountryCodeType(countryc.countryCodeType);
        setMinLength(countryc.minLength);
        setMaxLength(countryc.maxLength);

      }

      // Set other copied fields
   


    } else if (bankidId) {
      getDataForEdit();
    }
    fetchAssignee();
  }, [bankidId, countries, stateProvinces,banks, location.state]);

  // useEffect(() => {
  //   if (bankidId) {
  //     getDataForEdit();
  //   }
  // }, [  bankidId,countries,stateProvinces]);



  const getDataForEdit = async () => {
    try {
      const response = await BankIdServices.getBankIdById(bankidId);
      console.log("Response data:", response);
      const address = response.addresses[0];
      const countrycodetype = response.countrycode[0];
      const bank = banks.find(c => c.bankCode === response.bank);
      console.log("bank code from response:", response.bank);
      console.log("Available currencies:", banks);
      if (banks) {
        setBank(bank.bankCode);  // Set currency name for display
        setSelectedBank(bank.bankCode); // Set the selected currency based on currency name
      } else {
        console.error("bank not found for the provided bank code.");
      }
      // Existing data handling
      setBankID(response.bankID); // Adjust according to API response structure
      setBankName(response.bankName);
      setCountryCode(response.countryCode);  // Set country code
      setBank(response.bank);
      setStatus(response.status);  // Set country code
      setdoorNo(address.doorNo);
      setfloorNo(address.floorNo);
      setbuildingNo(address.buildingNo);
      setbuildingName(address.buildingName);
      setlandMark(address.landMark);
      setstreetNo(address.streetNo);
      setstreetName(address.streetName);
      setroadNo(address.roadName);
      setRoadName(address.roadName);
      setareaBlock(address.areaBlock);
      setareaName(address.areaName);
      setaddressLine1(address.addressLine1);
      setaddressLine2(address.addressLine2);
      setaddressLine3(address.addressLine3);
      setstate(address.state);
      setdistrict(address.district);
      setpostalCode(address.postalCode);
      setzipCode(address.zipCode);
      setpoBox(address.poBox);
      setPhase(address.phase);
      setSector(address.sector);
      setVillage(address.village);
      setTown(address.town);
      setCity(address.city);
      setCountryCodeType(countrycodetype.countryCodeType);
      setMaxLength(countrycodetype.maxLength);
      setMinLength(countrycodetype.minLength);


    
      // Country lookup based on country code from response
      const country = countries.find(
        (c) => c.country === address.countryCode
      );
      console.log("country code from response:", address.countryCode);
      console.log("Available countries:", countries);
      if (countries) {
        setCountryName(country.countryName);
        setCountryCode(country.country);
        // Set currency name for display
        setSelectedCountry(country.countryName); // Set the selected currency based on currency name
        // Set the currency code// Set the selected country based on countryName
      } else {
        console.error("Country not found for the provided country code.");
      }

      // for state
      const fetchedState = stateProvinces.find(c => c.state === address.state);
      console.log("State code from response:", address.state);
      console.log("Available states:", stateProvinces);
      console.log("the fetched console", fetchedState.State_Name
      );
      if (fetchedState) {
        setStateName(fetchedState.State_Name);  // Set state name for display
      } else {
        console.error("State not found for the provided state code:", response.state);
      }

      // Currency lookup based on currency code from response

    } catch (error) {
      console.error("Error fetching bankid data:", error);
      setAlert({
        severity: "error",
        message: "Error fetching bankid data.",
      });
    }
  };

  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.userId || "",
        };
        setAssignee(assigneeData);
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };


  const fetchcountry = async () => {
    try {
      const response = await CurrencyServicess.getAllCalendar(`${process.env.REACT_APP_BASE_URL}/get-country`);
      console.log("Fetched countries:", response); // Add this log
      setCountries(response.data.data); // Ensure this is correct
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };
  const fetchStateProvinces = async () => {
    try {
      const response = await StateProvinceServices.getAllStateprovince(`${process.env.REACT_APP_BASE_URL}/get-stateprovince`);
      console.log("Fetched StateProvinces:", response); // Add this log
      setStateProvinces(response.data.data); // Ensure this is correct
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };
  const fetchbank = async () => {
    try {
      const response = await BankServices.getAllBank(`${process.env.REACT_APP_BASE_URL}/get-bank`);
      console.log("Fetched bank:", response); // Add this log
      setBanks(response.data.data); // Ensure this is correct
    } catch (error) {
      console.error("Error fetching banks:", error);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      // Skip duplication check for edit mode
      if (!bankidId || isCopyMode) {
        const duplicateCheckResponse = await BankIdServices.checkDuplicateBankid(
          `${process.env.REACT_APP_BASE_URL}/getduplicatebankid`,
          { bankID: bankID }
        );

        if (duplicateCheckResponse.message === "bank id code already exists") {
          toast("error", "Bank Id already exists.");
          setErrors((prev) => ({
            ...prev,
            bankID: "bankid already exists.",
          }));
          return; // Stop further execution if duplicate exists
        }
      }
      const data = {
        id: isCopyMode ? null : bankidId, // Use 'id' for updates
        bankID,
        bankName,
        bank,
        created_by: assignee.assigner,
        changed_by: assignee.assigner,
        doorNo,
        floorNo,
        buildingNo,
        buildingName,
        landMark,
        streetNo,
        streetName,
        roadNo,
        roadName,
        areaBlock,
        areaName,
        addressLine1,
        addressLine2,
        addressLine3,
        countryCode,
        state,
        district,
        postalCode,
        zipCode,
        poBox,
        poBox,
        phase,
        sector,
        village,
        town,
        city,
        countryCodeType: typeof countryCodeType === 'string' ? countryCodeType : "",
        minLength,
        maxLength
      };


      let response;
      if (isCopyMode || !bankidId) {
        // Updating the existing currency
        response = await BankIdServices.addBankId(
          `${process.env.REACT_APP_BASE_URL}/create-bankid`, // Ensure this endpoint is for updates
          data
        );
      } else {
        // Creating a new currency
        response = await BankIdServices.addBankId(
          `${process.env.REACT_APP_BASE_URL}/update-bankid`,
          data
        );
      }

      if (response && !response.error) {
        toast("success", bankidId && !isCopyMode ? "bankid Updated successfully" : "bankid Added successfully");
        // Clear the form and navigate

        setBankID("");
        setBankName("");
        setBank("");
        setSelectedBank("");
        setCountryCodeType("");
        setMaxLength("");
        setMinLength("");
        setSelectedCountry("");
        setSelectedState("");
        setStateName("");
        setCountryCode("");
        setCountryName("");
        setdoorNo("");
        setfloorNo("");
        setbuildingNo("");
        setbuildingName("");
        setlandMark("");
        setstreetNo("");
        setstreetName("");
        setroadNo("");
        setRoadName("");
        setareaBlock("");
        setareaName("");
        setaddressLine1("");
        setaddressLine2("");
        setaddressLine3("");
        setstate("");
        setdistrict("");
        setpostalCode("");
        setpoBox("");
        setPhase("");
        setSector("");
        setVillage("");
        setTown("");
        setCity("");
        setzipCode("");
        setIsValidationTriggered(false);

        navigate("/2/master-data/9/bankidscreen");

        // Redirect after saving
      } else {

      }
    } catch (error) {
      console.error("Request error:", error);
      setAlert({
        severity: "error",
        message: `Error adding/updating bankid: ${error.message}`,
      });
    }
  };
  const handleSaveandContinue = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      // Skip duplication check for edit mode
      if (!bankidId || isCopyMode) {
        const duplicateCheckResponse = await BankIdServices.checkDuplicateBankid(
          `${process.env.REACT_APP_BASE_URL}/getduplicatebankid`,
          { bankID: bankID }
        );

        if (duplicateCheckResponse.message === "bank id code already exists") {
          toast("error", "Bank Id already exists.");
          setErrors((prev) => ({
            ...prev,
            bankID: "bankid already exists.",
          }));
          return; // Stop further execution if duplicate exists
        }
      }
      const data = {
        id: isCopyMode ? null : bankidId, // Use 'id' for updates
        bankID,
        bankName,
        bank,
        created_by: assignee.assigner,
        changed_by: assignee.assigner,
        doorNo,
        floorNo,
        buildingNo,
        buildingName,
        landMark,
        streetNo,
        streetName,
        roadNo,
        roadName,
        areaBlock,
        areaName,
        addressLine1,
        addressLine2,
        addressLine3,
        countryCode,
        state,
        district,
        postalCode,
        zipCode,
        poBox,
        phase,
        sector,
        village,
        town,
        city,
        countryCodeType: typeof countryCodeType === 'string' ? countryCodeType : "",
        minLength,
        maxLength
      };


      let response;
      if (isCopyMode || !bankidId) {
        // Updating the existing currency
        response = await BankIdServices.addBankId(
          `${process.env.REACT_APP_BASE_URL}/create-bankid`, // Ensure this endpoint is for updates
          data
        );
      } else {
        // Creating a new currency
        response = await BankIdServices.addBankId(
          `${process.env.REACT_APP_BASE_URL}/update-bankid`,
          data
        );
      }

      if (response && !response.error) {
        toast("success", bankidId && !isCopyMode ? "bankid Updated successfully" : "bankid Added successfully");
        // Clear the form and navigate

        setBankID("");
        setBankName("");
        setBank("");
        setSelectedBank("");
        setCountryCodeType("");
        setMaxLength("");
        setMinLength("");
        setSelectedCountry("");
        setSelectedState("");
        setStateName("");
        setCountryCode("");
        setCountryName("");
        setdoorNo("");
        setfloorNo("");
        setbuildingNo("");
        setbuildingName("");
        setlandMark("");
        setstreetNo("");
        setstreetName("");
        setroadNo("");
        setRoadName("");
        setareaBlock("");
        setareaName("");
        setaddressLine1("");
        setaddressLine2("");
        setaddressLine3("");
        setstate("");
        setdistrict("");
        setpostalCode("");
        setpoBox("");
        setzipCode("");
        setPhase("");
        setSector("");
        setVillage("");
        setTown("");
        setCity("");
        setIsValidationTriggered(false);


        // Redirect after saving
      } else {

      }
    } catch (error) {
      console.error("Request error:", error);
      setAlert({
        severity: "error",
        message: `Error adding/updating bankid: ${error.message}`,
      });
    }
  };
  const checkDuplicatebankid = async (code) => {
    try {
      const response = await BankIdServices.checkDuplicateBankid(
        `/getduplicatebankid`,
        { bankID: code }  // Sending roleName to backend
      );


      console.log("Response from server:", response);

      if (response.message === "bank id code already exists") {
        // Check if it's a duplicate
        console.log("Response from internal server:", response);
        setErrors({ bankID: " " });

        toast("error", "Bank ID already exists.");
      }
      console.log("Response from external server:", response);
    } catch (error) {
      console.error("Error checking duplicate bankID:", error);
      // Handle potential errors when making the API call
      setErrors((prev) => ({ ...prev, bankID: "Error checking role name" }));
    }
  };
  const handlebankidChange = (e) => {
    const code = e.target.value;
    setBankID(code);
    setUnsavedChanges(true);
    // Clear previous errors when typing
    setErrors(prev => ({ ...prev, bankID: "" }));


    if (code.trim() !== "") {
      checkDuplicatebankid(code);
    }
  };
  // Separate handler for country selection
  const handleCountryCheckBoxChange = (event) => {
    console.log(`this is the ${countries}`)

    const selectedName = event.target.value; // Get the selected country name
    setSelectedCountry(selectedName);
    setUnsavedChanges(true);
    const selectedCountryData = countries.find(country => country.country === selectedName);
    if (selectedCountryData) {
      setCountryCode(selectedCountryData.country); // Set the country code
      setCountryName(selectedCountryData.countryName); // Set the country name

      // Filter states by the selected country code
      const filteredStates = stateProvinces.filter(state => state.Country_Code === selectedCountryData.country);
      setFilteredStateProvinces(filteredStates); // Assuming you have state to hold the filtered states
    }
  };


  // Separate handler for bankid selection
  const handleBankCheckBoxChange = (event) => {
    banks.forEach((bank) => {
      console.log(bank);
    });
    const selectedName = event.target.value; // Get the selected currency name
    setSelectedBank(selectedName);
    setUnsavedChanges(true);
    const selectedbankData = banks.find(bank => bank.bankCode === selectedName);

    if (selectedbankData) {
      setBank(selectedbankData.bankCode); // Set the currency code
      // Set the currency name
    }
  };
  const handleCheckboxChangeForState = (event) => {

    const selectedCode = event.target.value;
    console.log("selected State code", selectedCode);
    setSelectedState(selectedCode);
    setstate(selectedCode);
    setUnsavedChanges(true);
    const selectedCountryData = stateProvinces.find(stateP => stateP.state === selectedCode);
    console.log('selected state country data', selectedCountryData);
    if (selectedCountryData) {
      console.log('check selected country data', selectedCountryData.State_Name);
      setStateName(selectedCountryData.State_Name);
    }
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleSearchChangeState = (e) => {
    setSearchQueryState(e.target.value);
  };


  const validateForm = () => {
    setIsValidationTriggered(true);

    const isAddressSectionFilled = addressLine1 || addressLine2 || addressLine3;
    const isBuildingSectionFilled = doorNo || floorNo || buildingNo || buildingName || landMark;
    const isStreetSectionFilled = streetNo || streetName || roadNo || roadName;
    const isAreaSectionFilled = areaBlock || areaName;
    const iscitySectionFilled =  city || town || village || sector ||  phase;

    const isBuildingStreetAreaSectionFilled = isBuildingSectionFilled || isStreetSectionFilled || isAreaSectionFilled || iscitySectionFilled;   
     const newErrors = {};
    if (!bankID) newErrors.bankID = "bankID is required.";
    if (!bankName) newErrors.bankName = "bankName is required.";
    if (!bank) newErrors.bank = "bank   is required.";
    if (!countryCode) newErrors.countryCode = "country Code   is required.";
    if (filteredStateProvinces.length > 0 && !state) {
      newErrors.state = "state is required.";
    }


    if (!isAddressSectionFilled && !isBuildingStreetAreaSectionFilled) {
      newErrors.addressSection = "Either Address Section or Building, Street, and Area Section must be entered";
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      toast("error", "Missing Required Fields");
    }
    return Object.keys(newErrors).length === 0;
  };
  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === "Company & Bank Data") {
      navigate("/2/master-data");
    } else if (crumb.title === "Bank ID") {
      navigate("/2/master-data/10/bankidscreen");
    } else if (crumb.title === "Primary Data") {
      navigate("/2/master-data");
    } else if (crumb.path) {
      navigate(crumb.path);
    }
  };
  const toggleModalForState = () => {
    setIsModalOpenForState(!isModalOpenForState);

  }

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const tabs = [
    { value: "addbankiddetails", label: "Basic Details" },
    { value: "bankidaddress", label: "Bank Address" },
    { value: "countrycodetype", label: "Country Code Type" },

  ];

  const errorss = {
    addbankiddetails: !bankID || !bankName || !bank,
    bankidaddress: !addressLine1 && !addressLine2 && !addressLine3 && !doorNo && !floorNo && !buildingNo && !buildingName && !landMark && !streetNo && !streetName && !roadNo && !roadName && !areaBlock && !areaName && !countryCode && !countryName && !phase  && !sector && !village && !town  && !city,

  };
  const nextToAddress = () => {
    setActiveTab("bankidaddress");

  }
  const nextToCountryCodetype = () => {
    setActiveTab("countrycodetype");

  }
  const getApprovedIcon = () => {
    let color = "black"; // Default color
    // Determine the color based on the approved status
    if (status === 4) {
      color = "#29CB00"; // Green
    } else if (status === 0) {
      color = "gray"; // Gray
    } else if (status === 1) {
      color = "#AF52DE"; 
    } else if (status === 2) {
      color = "yellow"; 
    } else if (status === 3) {
      color = "orange"; 
    }
    else if (status === 5) {
      color = "red"; // Red
    }

    return <TbFlag3Filled style={{ color, marginLeft: '8px' }} />; // Return the icon with the determined color
  };
  const handleReset = () => {
    setSearchQuery(''); // Reset search query
    setSelectedBank(""); // Reset selected bank ID
    setBank("");
  };
  const handleCountryReset = () => {
    setSearchQuery(''); // Reset search query
    setSelectedCountry(""); // Reset selected bank ID
    setCountryCode("");
    setCountryName("");
    setstate("");
    setStateName("")
  };
  const handleCountryNameReset = () => {
    setSearchQuery(''); // Reset search query
    setSelectedCountry(""); // Reset selected bank ID
    setCountryCode("");
    setCountryName("");
    setstate("");
    setStateName("")
  };
  const handleStateReset = () => {
    setSearchQueryState(''); // Reset search query
    setSelectedState(""); // Reset selected bank ID
    setstate("");
    setStateName("")
  };
  const handleStateNameReset = () => {
    setSearchQueryState(''); // Reset search query
    setSelectedState(""); // Reset selected bank ID
    setstate("");
    setStateName("")
  };
  
  const handleRefreshbank = async () => {
    try {
      await fetchbank();  // Fetch Branchs
    } catch (error) {
      console.error('Error reloading Branchs:', error);
    } finally {
    }
  };
  const handleRefreshcountry = async () => {
    try {
      await fetchcountry();  // Fetch Branchs
    } catch (error) {
     
    } finally {
    }
  };
  const handleRefreshstate = async () => {
    try {
      await fetchStateProvinces();  // Fetch Branchs
    } catch (error) {
      console.error('Error reloading Branchs:', error);
    } finally {
    }
  };
  return (
    <div>
      <Header
            title={`Primary Data > Company & Bank Data > Bank ID > ${
    isCopyMode 
      ? 'Add' 
      : bankidId 
        ? (isViewMode ? "View" : "Edit") 
        : "Add"
  }${bankidId && (isViewMode || !isCopyMode) ? ` > ${bankID}` : ''}`}

        onBreadcrumbClick={handleBreadcrumbClick}
        extraContent={bankidId && !isCopyMode ? getApprovedIcon() : null}
        hasUnsavedChanges={unsavedChanges}
        backRoute={"/2/master-data/10/bankidscreen"}
      />
      <Tabs tabs={tabs} activeTab={activeTab} onTabClick={handleTabClick} isValidationTriggered={isValidationTriggered} errors={errorss} />
      <div className="flex-grow">
        {activeTab === "addbankiddetails" && (
          <div className="m-6 border border-gray-200 p-5  rounded-lg">

          <div className="flex flex-col space-y-8 col-span-1 p-6">
          <LabelWithInput
        label="Bank ID "
        value={bankID}
        onChange={handlebankidChange}
        maxLength={10}
        error={errors.bankID}
        isRequired={true}
        isDisabled={((isEditMode && !isCopyMode) || isViewMode)}
        />

              <LabelWithInput
                label="Bank Name "
                value={bankName}
                onChange={(e) => {
                  setBankName(e.target.value);
                  setUnsavedChanges(true);
                  if (errors.bankName) {
                    setErrors(prevErrors => ({ ...prevErrors, bankName: "" }));
                  }
                }} maxLength={150}
                error={errors.bankName}
                isRequired={true}
                isDisabled={isViewMode}
              />

              <div className="flex items-center">
                <LabelWithButton
                  label="Bank"
                  isRequired={true}
                  isDisabled={isViewMode}
                  onClick={() => {
                    setIsCountryModalOpen(true);
                    setUnsavedChanges(true);
                  }
                  }
                  buttonText={selectedBank || 'Select Bank'}
                  error={errors.bank}
                />

          <ReusableModal
  title="Select Bank Id"
  isOpen={isCountryModalOpen}
  onClose={() => {
    setIsCountryModalOpen(false);
    setSearchQuery('');
  }}
  onSave={() => {
    setIsCountryModalOpen(false);
    setSearchQuery('');
  }}
  showReset={true}
  onReset={handleReset}
  onRefresh={handleRefreshbank}
  searchPlaceholder="Search bankid..."
  searchQuery={searchQuery}
  onSearchChange={handleSearchChange}
  isViewMode={isViewMode}
>
   {banks.length > 0 ? (
    banks
      .filter((bank) =>
    (bank.bankCode && bank.bankCode.trim() && bank.bankCode.toLowerCase().includes(searchQuery.toLowerCase())) 
  )
  .sort((a, b) => a.bankCode.localeCompare(b.bankCode)) // Sort in alphabetical order

                      .map((bank, index) => (

                        <div key={bank.id} className={`flex items-center ${index % 2 === 0 ? 'bg-sky-blue-200' : ''}`}>
                          <input
                            type="radio"
                            name="Bank Code"
                            value={bank.bankCode}
                            checked={selectedBank === bank.bankCode}
                            onChange={handleBankCheckBoxChange}
                            className="mr-2 accent-customBlue"
                            disabled={isViewMode}
                          />
                          <label>{bank.bankCode}</label>
                        </div>
                      ))
                  ) : (
                    <p>Loading countries...</p>
                  )}


                </ReusableModal>

              </div>




              {/* <CustomButton onClick={nextToAddress} title={"Next"}/> */}
            </div>
          </div>
        )}

        {activeTab === "bankidaddress" && (
          <div className="m-6 border border-gray-200 p-5  rounded-lg">
            <TableWrapper maxHeight="70vh">
              <div className="p-1">
                {/* Building Section */}
                <div className="mb-6 border border-gray-300 p-5 rounded-lg">
                  <h3 className="text-md font-medium text-customGray mb-4">Building</h3>

                  <div className="grid grid-cols-2 gap-4">
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Door No </label>
                      <span className="ml-4">:</span>
                      <input type="text"
                        value={doorNo}
                        maxLength={30}

                        onChange={(e) => {
                          setdoorNo(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        disabled={isViewMode}
                        className="border border-gray-300 p-1.5 rounded w-80 h-8  focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray font-small text-customGray text-sm" />
                    </div>
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Floor No </label>
                      <span className="ml-4">:</span>
                      <input type="text"
                        value={floorNo}
                        maxLength={30}

                        disabled={isViewMode}
                        onChange={(e) => {
                          setfloorNo(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        placeholder="4" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm font-small text-customGray text-sm " />
                    </div>
                  </div>


                  <div className="grid grid-cols-2 gap-4 mt-4">
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Building No </label>
                      <span className="ml-4">:</span>
                      <input type="text"
                        value={buildingNo}
                        maxLength={30}

                        disabled={isViewMode}
                        onChange={(e) => {
                          setbuildingNo(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        placeholder="2A" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm font-small text-customGray text-sm" />
                    </div>
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Building Name </label>
                      <span className="ml-4">:</span>
                      <input type="text"
                        value={buildingName}
                        maxLength={30}

                        disabled={isViewMode}
                        onChange={(e) => {
                          setbuildingName(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        placeholder="B name" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm font-small text-customGray text-sm" />
                    </div>
                  </div>

                  <div className="flex items-center mt-4">
                    <label className="w-48 font-small text-customGray text-sm">Landmark</label>
                    <span className="ml-4">:</span>
                    <input type="text"
                      value={landMark}
                      maxLength={30}

                      onChange={(e) => {
                        setlandMark(e.target.value)
                        setUnsavedChanges(true);
                      }}
                      disabled={isViewMode}
                      placeholder="" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm" />
                  </div>
                </div>

                {/* Street Section */}
                <div className="mb-6 border border-gray-300 p-5 rounded-lg">
                  <h3 className="text-md font-medium text-customGray  mb-4">Street</h3>

                  <div className="grid grid-cols-2 gap-4">
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Street No </label>
                      <span className="ml-4">:</span>
                      <input
                        value={streetNo}
                        maxLength={30}

                        onChange={(e) => {
                          setstreetNo(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        disabled={isViewMode}

                        type="text" placeholder="2/626" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray" />
                    </div>
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Street Name</label>
                      <span className="ml-4">:</span>
                      <input type="text" value={streetName} maxLength={30}
                        disabled={isViewMode} onChange={(e) => {
                          setstreetName(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray" />
                      {/* {errors.streetName && (
                    <p className="text-red-500 text-sm ml-4">{errors.streetName}</p>
                  )} */}
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-4 mt-4">
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Road No </label>
                      <span className="ml-4">:</span>
                      <input type="text"
                        value={roadNo}
                        maxLength={30}

                        onChange={(e) => {
                          setroadNo(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        disabled={isViewMode}
                        placeholder="2A" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray" />
                    </div>
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Road Name </label>
                      <span className="ml-4">:</span>
                      <input
                        type="text"
                        value={roadName}
                        maxLength={30}

                        onChange={(e) => {
                          setRoadName(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        placeholder="B name"
                        disabled={isViewMode}
                        className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray"
                      />
                    </div>
                  </div>
                </div>

                {/* Area Section */}
                <div className="mb-6 border border-gray-300 p-5 rounded-lg">
                  <h3 className="text-md font-medium text-customGray  mb-4">Area</h3>

                  <div className="grid grid-cols-2 gap-4">
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Block </label>
                      <span className="ml-4">:</span>
                      <input type="text"
                        value={areaBlock}
                        maxLength={30}

                        onChange={(e) => {
                          setareaBlock(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        placeholder="2/626" disabled={isViewMode} className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray" />
                    </div>
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Area Name </label>
                      <span className="ml-4">:</span>
                      <input type="text"
                        value={areaName}
                        maxLength={30}

                        onChange={(e) => {
                          setareaName(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        placeholder="4" disabled={isViewMode} className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray" />
                    </div>
                  </div>
                </div>
                
                {/* Display the error message for address section */}
                {errors.addressSection && (
                  <p className="text-red-500 text-sm mb-4">{errors.addressSection}</p>
                )}
                <div className="mb-6 border border-gray-300 p-5 rounded-lg">
                  <h3 className="text-md font-medium text-customGray mb-4">City</h3>

                  <div className="grid grid-cols-2 gap-4">
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Phase </label>
                      <span className="ml-4">:</span>
                      <input type="text"
                        value={phase}
                        maxLength={5}

                        onChange={(e) => {
                          setPhase(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        disabled={isViewMode}
                        className="border border-gray-300 p-1.5 rounded w-80 h-8  focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray font-small text-customGray text-sm" />
                    </div>
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Sector </label>
                      <span className="ml-4">:</span>
                      <input type="text"
                        value={sector}
                        maxLength={5}

                        disabled={isViewMode}
                        onChange={(e) => {
                          setSector(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        placeholder="4" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm font-small text-customGray text-sm " />
                    </div>
                  </div>


                  <div className="grid grid-cols-2 gap-4 mt-4">
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Village </label>
                      <span className="ml-4">:</span>
                      <input type="text"
                        value={village}
                        maxLength={5}

                        disabled={isViewMode}
                        onChange={(e) => {
                          setVillage(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        placeholder="2A" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm font-small text-customGray text-sm" />
                    </div>
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Town </label>
                      <span className="ml-4">:</span>
                      <input type="text"
                        value={town}
                        maxLength={50}

                        disabled={isViewMode}
                        onChange={(e) => {
                          setTown(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        placeholder="B name" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm font-small text-customGray text-sm" />
                    </div>
                  </div>

                  <div className="flex items-center mt-4">
                    <label className="w-48 font-small text-customGray text-sm">City</label>
                    <span className="ml-4">:</span>
                    <input type="text"
                      value={city}
                      maxLength={100}

                      onChange={(e) => {
                        setCity(e.target.value)
                        setUnsavedChanges(true);
                      }}
                      disabled={isViewMode}
                      placeholder="" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm" />
                  </div>
                </div>
                {/* Address Section */}
                <div className="mb-6 border border-gray-300 p-5 rounded-lg">
                  <h3 className="text-md font-medium text-customGray  mb-4">Address</h3>
                  <div className="grid grid-cols-1 gap-4">
                    <div className="flex items-center">
                      <label className="w-56 font-small text-customGray text-sm">Address 1 </label>
                      <span className="ml-4">:</span>
                      <input type="text" value={addressLine1} onChange={(e) => {
                        setaddressLine1(e.target.value)
                        setUnsavedChanges(true);
                      }}
                        placeholder="2/626" maxLength={30}
                        disabled={isViewMode} className="border border-gray-300 p-1.5 rounded w-full h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray"
                      />

                      {/* {errors.addressLine1 && (
                    <p className="text-red-500 text-sm ml-4">
                      {errors.addressLine1}
                    </p>
                  )} */}
                    </div>
                    {/* <div className="flex items-center">
                <label className="w-48 font-small text-customGray text-sm">Address 2 </label>
                <span className="ml-4">:</span>
                <input type="text"  value={addressLine2}  onChange={(e) => setaddressLine2(e.target.value)}
                placeholder="4" className="border border-gray-300 p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm" />
              </div> */}
                  </div>

                  <div className="grid grid-cols-1 gap-4 mt-4">
                    <div className="flex items-center">
                      <label className="w-56 font-small text-customGray text-sm">Address 2 </label>
                      <span className="ml-4">:</span>
                      <input type="text" value={addressLine2} onChange={(e) => {
                        setaddressLine2(e.target.value)
                        setUnsavedChanges(true);
                      }}
                        placeholder="4" maxLength={30}
                        disabled={isViewMode} className="border border-gray-300 p-1.5 rounded w-full h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray" />
                    </div>

                  </div>

                  <div className="grid grid-cols-1 gap-4 mt-4">
                    <div className="flex items-center">

                      <label className="w-56 font-small text-customGray text-sm">Address 3 </label>
                      <span className="ml-4">:</span>
                      <input type="text" value={addressLine3} onChange={(e) => {
                        setaddressLine3(e.target.value)
                        setUnsavedChanges(true);
                      }}
                        placeholder="2A" maxLength={30}
                        disabled={isViewMode} className="border border-gray-300 p-1.5 rounded w-full h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray" />
                    </div>

                  </div>
                </div>


                {/* Country Section */}
                <div className="mb-6 border border-gray-300 p-5 rounded-lg">
                  <h3 className="text-md font-medium text-customGray  mb-4">
                    Country
                  </h3>

                  <div className="grid grid-cols-2 gap-4">
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">
                        Country Code{" "}
                        <span className="text-red-500 ml-1">*</span>
                      </label>
                      <span className="ml-4">:</span>
                      <button
                        onClick={() => setIsCountryModalOpen(true)}
                        disabled={isViewMode}
                        className={`border border-gray-300 p-1 disabled:bg-[#FAFAFA] rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 text-left text-customGray flex items-center justify-between ${errors.countryCode
                            ? "border-red-500 hover:border-red-500"
                            : "border-gray-300 hover:border-blue-400"
                          }`}
                      >
                        <span className="font-small text-customGray text-sm">
                          {countryCode || "Select Country Code"}
                        </span>
                        <RxDropdownMenu className="ml-2" />
                      </button>

                      <ReusableModal
                        title="Select Country Code"
                        isOpen={isCountryModalOpen}
                        onClose={() => {
                          setIsCountryModalOpen(false);
                          setSearchQuery("");
                        }}
                        onSave={() => {
                          setIsCountryModalOpen(false);
                          setSearchQuery("");
                        }}
                        showReset={true}
                        onReset={handleCountryReset}
                        searchPlaceholder="Search country..."
                        searchQuery={searchQuery}
                        onRefresh={handleRefreshcountry}
                        onSearchChange={handleSearchChange}
                        isViewMode={isViewMode}
                      >
                        {countries.length > 0 ? (
                          countries
                            .filter((country) =>
                              country.country
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase())
                            )
                            .sort((a, b) => a.country.localeCompare(b.country)) // Sort in alphabetical order

                            .map((country) => (
                              <div
                                key={country.id}
                                className="flex items-center"
                              >
                                <input
                                  type="radio"
                                  name="country"
                                  value={country.country}
                                  checked={selectedCountry === country.country}
                                  onChange={handleCountryCheckBoxChange}
                                  className="mr-2 accent-customBlue"
                                  disabled={isViewMode}
                                />
                                <label>{country.country}</label>
                              </div>
                            ))
                        ) : (
                          <p>Loading countries...</p>
                        )}
                      </ReusableModal>
                    </div>
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">
                        Country Name{" "}
                        <span className="text-red-500 ml-1">*</span>
                      </label>
                      <span className="ml-4">:</span>
                      <button
                        onClick={() => setIsCountryNameModalOpen(true)}
                        disabled={isViewMode}
                        className={`border border-gray-300 p-1 disabled:bg-[#FAFAFA] rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 text-left text-customGray flex items-center justify-between ${errors.countryName
                            ? "border-red-500 hover:border-red-500"
                            : "border-gray-300 hover:border-blue-400"
                          }`}
                      >
                        <span className="font-small text-customGray text-sm">
                          {countryName || "Select Country Name"}
                        </span>
                        <RxDropdownMenu className="ml-2" />
                      </button>

                      <ReusableModal
                        title="Select Country Code"
                        isOpen={isCountryNameModalOpen}
                        onClose={() => {
                          setIsCountryNameModalOpen(false);
                          setSearchQuery("");
                        }}
                        onSave={() => {
                          setIsCountryNameModalOpen(false);
                          setSearchQuery("");
                        }}
                        showReset={true}
                        onReset={handleCountryNameReset}
                        searchPlaceholder="Search countryname..."
                        searchQuery={searchQuery}
                        onRefresh={handleRefreshcountry}

                        onSearchChange={handleSearchChange}
                        isViewMode={isViewMode}
                      >
                        {countries.length > 0 ? (
                          countries
                            .filter((country) =>
                              country.countryName
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase())
                            )
                            .sort((a, b) => a.countryName.localeCompare(b.countryName)) // Sort in alphabetical order

                            .map((country) => (
                              <div
                                key={country.id}
                                className="flex items-center"
                              >
                                <input
                                  type="radio"
                                  name="country"
                                  value={country.country}
                                  checked={selectedCountry === country.country}
                                  onChange={handleCountryCheckBoxChange}
                                  className="mr-2 accent-customBlue"
                                  disabled={isViewMode}
                                />
                                <label>{country.countryName}</label>
                              </div>
                            ))
                        ) : (
                          <p>Loading countries...</p>
                        )}
                      </ReusableModal>
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-4 mt-4">
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">
                        State / Province{" "}
                        <span className="text-red-500 ml-1">*</span>
                      </label>
                      <span className="ml-4">:</span>
                      <button
                        onClick={() => setIsStateModalOpen(true)}
                        disabled={isViewMode}
                        className={`border border-gray-300 p-1 disabled:bg-[#FAFAFA] rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 text-left flex items-center justify-between text-customGray ${errors.state
                            ? "border-red-500 hover:border-red-500"
                            : "border-gray-300 hover:border-blue-400"
                          }`}
                      >
                        <span className="font-small text-customGray text-sm">
                          {state || "Select State / Province"}
                        </span>
                        <RxDropdownMenu className="ml-2" />
                      </button>

                      <ReusableModal
                        title="Select State Code"
                        isOpen={isStateModalOpen}
                        onClose={() => {
                          setIsStateModalOpen(false);
                          setSearchQueryState("");
                        }}
                        onSave={() => {
                          setIsStateModalOpen(false);
                          setSearchQueryState("");
                        }}
                        showReset={true}
                        onReset={handleStateReset}
                        searchPlaceholder="Search state..."
                        searchQuery={searchQueryState}
                        onRefresh={handleRefreshstate}

                        onSearchChange={handleSearchChangeState}
                        isViewMode={isViewMode}
                      >
                        {filteredStateProvinces.length > 0 ? (
                          filteredStateProvinces
                            .filter(
                              (stateP) =>

                                stateP.state
                                  .toLowerCase()
                                  .includes(searchQueryState.toLowerCase())
                            )
                            .sort((a, b) => a.state.localeCompare(b.state)) // Sort in alphabetical order

                            .map((stateP, index) => (
                              <div
                                key={stateP.id}
                                className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""
                                  }`}
                              >
                                <input
                                  type="radio"
                                  name="country"
                                  value={stateP.state}
                                  checked={selectedState === stateP.state}
                                  onChange={handleCheckboxChangeForState}
                                  className="mr-2 accent-customBlue"
                                  disabled={isViewMode}
                                />
                                <label>{stateP.state}</label>
                              </div>
                            ))
                        ) : (
                          <p>Loading State...</p>
                        )}
                      </ReusableModal>
                    </div>

                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">
                        State / Province Name{" "}
                        <span className="text-red-500 ml-1">*</span>
                      </label>
                      <span className="ml-4">:</span>
                      <button
                        onClick={() => setIsStateNameModalOpen(true)}
                        disabled={isViewMode}
                        className={`border border-gray-300 p-1 disabled:bg-[#FAFAFA] rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 text-left flex items-center justify-between text-customGray ${errors.state
                            ? "border-red-500 hover:border-red-500"
                            : "border-gray-300 hover:border-blue-400"
                          }`}
                      >
                        <span className="font-small text-customGray text-sm">
                          {stateName || "Select State / Province Name"}
                        </span>
                        <RxDropdownMenu className="ml-2" />
                      </button>

                      <ReusableModal
                        title="Select State Code"
                        isOpen={isStateNameModalOpen}
                        onClose={() => {
                          setIsStateNameModalOpen(false);
                          setSearchQueryState("");
                        }}
                        onSave={() => {
                          setIsStateNameModalOpen(false);
                          setSearchQueryState("");
                        }}
                        showReset={true}
                        onReset={handleStateNameReset}
                        onRefresh={handleRefreshstate}

                        searchPlaceholder="Search statename..."
                        searchQuery={searchQueryState}
                        onSearchChange={handleSearchChangeState}
                        isViewMode={isViewMode}
                      >
                        {filteredStateProvinces.length > 0 ? (
                          filteredStateProvinces
                            .filter(
                              (stateP) =>
                                stateP.State_Name.toLowerCase().includes(
                                  searchQueryState.toLowerCase()
                                ) ||
                                stateP.state
                                  .toLowerCase()
                                  .includes(searchQueryState.toLowerCase())
                            )
                            .sort((a, b) => a.State_Name.localeCompare(b.State_Name)) // Sort in alphabetical order

                            .map((stateP, index) => (
                              <div
                                key={stateP.id}
                                className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""
                                  }`}
                              >
                                <input
                                  type="radio"
                                  name="country"
                                  value={stateP.state}
                                  checked={selectedState === stateP.state}
                                  onChange={handleCheckboxChangeForState}
                                  className="mr-2 accent-customBlue"
                                  disabled={isViewMode}
                                />
                                <label>{stateP.State_Name}</label>
                              </div>
                            ))
                        ) : (
                          <p>Loading State...</p>
                        )}
                      </ReusableModal>
                    </div>
                  </div>



                  {/* <div className="grid grid-cols-2 gap-4 mt-2">
            </div> */}

                  <div className="grid grid-cols-2 gap-4 mt-4">
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">District</label>
                      <span className="ml-4">:</span>
                      <input type="text"
                        value={district}
                        maxLength={30}

                        onChange={(e) => {
                          setdistrict(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        disabled={isViewMode}
                        placeholder="2A"
                        className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray" />
                    </div>
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Postal Code</label>
                      <span className="ml-4">:</span>
                      <input type="text"
                        value={postalCode}
                        maxLength={30}

                        onChange={(e) => {
                          setpostalCode(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        disabled={isViewMode}
                        placeholder="B name" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray" />
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-4 mt-4">
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Zip Code</label>
                      <span className="ml-4">:</span>
                      <input type="text"
                        value={zipCode}
                        maxLength={30}

                        onChange={(e) => {
                          setzipCode(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        disabled={isViewMode}
                        placeholder="2A" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray" />
                    </div>

                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">PO Box</label>
                      <span className="ml-4">:</span>
                      <input type="text"
                        value={poBox}
                        maxLength={30}

                        onChange={(e) => {
                          setpoBox(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        disabled={isViewMode}

                        placeholder="B name" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray" />
                    </div>

                  </div>

                </div>

                {/* <CustomButton onClick={nextToIdentifiers} title={"Next"} className={"mb-10"}/> */}
              </div>
            </TableWrapper>
          </div>

        )}

        {activeTab === "countrycodetype" && (
          <div className="m-6 border border-gray-200 p-5  rounded-lg">

            <div className='flex flex-col space-y-8 col-span-1 p-6'>
              <div className="grid grid-cols-2 gap-8">
                <div className="flex items-center">
                  <LabelWithInput
                    label="Country Code Type"
                    value={countryCodeType}
                    onChange={(e) => {
                      setCountryCodeType(e.target.value);
                      setUnsavedChanges(true);
                    }} maxLength={30}
                    isDisabled={isViewMode}
                  />
                </div>

                <div className="flex items-center">
                <label className="flex items-center">
    <span className="text-sm font-small text-customGray">Min</span>
    <span className="ml-4">:</span>
    <input
      type="number"
      value={minLength}
      onChange={(e) => {
        setMinLength(e.target.value);
        setUnsavedChanges(true);
      }}
      className="border border-gray-300 p-1.5 rounded w-20 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 mr-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 text-sm font-small text-customGray"
      disabled={isViewMode}
       // Restrict maximum value to 25
    />
  </label>

  <label className="flex items-center">
  <span className="text-sm font-small text-customGray">Max</span>
  <span className="ml-4">:</span>
  <input
    type="number"
    value={maxLength}
    onChange={(e) => {
      setMaxLength(e.target.value);
      setUnsavedChanges(true);
    }}
    className="border border-gray-300 p-1.5 rounded w-20 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 text-sm font-small text-customGray"
    disabled={isViewMode}
    // Restrict maximum value to 25
  />
</label>
                </div>
              </div>
            </div>
          </div>
        )}

      </div>
      <Footer>
        {/* Footer */}
        {(!isViewMode &&
          <ButtonGroup
            onSave={handleSubmit}

            onSaveContinue={handleSaveandContinue}
            hasId={!!bankidId}
          />
        )}
      </Footer>
    </div>
  )
}
