import React, { useState, useEffect } from 'react';
import Header from '../../../../components/Header';
import { useNavigate, useParams } from 'react-router-dom';
import { Checkbox, MenuItem, Select } from '@mui/material';
import Footer from '../../../../components/footer';
import axios from 'axios';
import ModuleService from '../../../../rest-services/ModuleServices';
import { useToast } from '../../../../components/toast/toast';
import TableWrapper from '../../../../components/ScrollBarComponent';
import { IoMdCreate } from 'react-icons/io';
import UserService from '../../../../rest-services/UserServices';

export default function ModuleAssignment() {
    const navigate = useNavigate();
     const { iconId } = useParams();
    const [selectedValue, setSelectedValue] = useState('');
    const [groupedData, setGroupedData] = useState({});
    const [filteredData, setFilteredData] = useState({});
    const [userData, setUserData] = useState([]);
    const { toast } = useToast();
    const [checkedStates, setCheckedStates] = useState({});
    const [pendingCheckedStates, setPendingCheckedStates] = useState({}); 
    const [userTypes, setUserTypes] = useState({});
  const [alert, setAlert] = useState(null);
  const [editView, setEditView] = useState([]);

    const [assignee, setAssignee] = useState({ id: "", username: "" });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rolesPermissions, setRolesPermissions] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

    
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Function to handle closing the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {

      fetchAssignee();
      fetchData();
  },[]);



  useEffect(() => {
    console.log("Grouped data updated:", groupedData);
  }, [groupedData]); // This effect runs whenever 'groupedData' is updated

  

    const fetchAssignee = async () => {
        try {
          const userDetailString = localStorage.getItem("userDetail");
    console.log("Fetch assignee");
    
          if (userDetailString) {
            const userDetail = JSON.parse(userDetailString);
            const assigneeData = {
              id: userDetail.id || "",
              assigner: userDetail.username || "",
              clientId: userDetail.clientId || "",
              assignerId: userDetail.userId || "",
            };
            console.log("assigneeData is",assigneeData);
    
            setAssignee(assigneeData);
            
            // Log the id and assigner values to the console
            console.log("Assignee ID:", assigneeData.id);
            console.log("Assigner:", assigneeData.assigner);
            console.log("Assigner userId:", assigneeData.assignerId);
            console.log("Assigner clientId:", assigneeData.clientId);
            
          } else {
            setAlert({
              severity: "error",
              message: "No user detail found in session storage.",
            });
          }
        } catch (error) {
          setAlert({
            severity: "error",
            message: `Error fetching user details: ${error.message}`,
          });
        }
      };

        useEffect(() => {
            if (assignee.assignerId) {
              console.log("Assigner ID has changed:", assignee.assignerId);
              fetchUserPermissionDetails();
            }
          }, [assignee.assignerId]);


      const fetchUserPermissionDetails = async () => {
        try {
          const data = { userId: assignee.assignerId };
          console.log("Request Data:", data);
    
          const response = await UserService.saveContactDetails(
            `${process.env.REACT_APP_BASE_URL}/get-users-permissions-by-userId`,
            data
          );
          console.log("user permissions details",response);
          const responseData = response.data;
          setRolesPermissions(responseData.rolesPermissionsTables || []); // Save permission details
          console.log("Set Permission details for userscreen", responseData.rolesPermissionsTables);
    
    
        } catch (error) {
          setError(error.message); // Set error message
        } finally {
          setLoading(false); // Reset loading state
        }
      };
    
      console.log("Check user permissions", rolesPermissions);

      const hasPermissionForEdit = rolesPermissions.some(
        (permission) => permission.permissionsId === 2 && permission.iconId === parseInt(iconId)
      );
      const hasPermissionForSave = rolesPermissions.some(
        (permission) => permission.permissionsId === 23 && permission.iconId === parseInt(iconId)
      );
      console.log("Check user permissions to user view list", hasPermissionForEdit);

      

        const fetchData = async () => {
            try {
                const purchaseResponse = await axios.get(`${process.env.REACT_APP_BASE_URL}/purchase-details`);
                const grouped = {};
                purchaseResponse.data.forEach(item => {
                    const mainMenuId = item.mainMenuId;
                    const subMenuDetails = {
                        id: item.subMenu.id,
                        name: item.subMenu.name,
                        purchased: item.purchased,
                        assigned: item.assigned,
                        available: item.available,
                    };
                    if (!grouped[mainMenuId]) {
                        grouped[mainMenuId] = {
                            mainMenuName: item.mainMenu.name,
                            subMenus: [],
                        };
                    }
                    grouped[mainMenuId].subMenus.push(subMenuDetails);
                });
                console.log("Set grouped data",grouped);
                setGroupedData(grouped);
    
                const response = await ModuleService.approvedUser();


                // console.log("assignees data",assignee);
                // const data = { clientId: assignee.clientId };
                // console.log("Request Data for users:", data);
                
                // const response = await ModuleService.sendData(`${process.env.REACT_APP_BASE_URL}/user/get-user`,
                //   data
                // );    

                setUserData(response.data.data);

                // Fetch user types
                const userTypeResponse = await ModuleService.userAllType();
                const userTypeMap = {};
                userTypeResponse.data.data.forEach(type => {
                    userTypeMap[type.id] = type.name;
                });
                setUserTypes(userTypeMap);
    
                const assignmentResponse = await axios.get(`${process.env.REACT_APP_BASE_URL}/get-module-assignment`);
                const initialCheckedStates = {};
                assignmentResponse.data.forEach(assignment => {
                    initialCheckedStates[`${assignment.userId}-${assignment.subMenuId}`] = {
                        isChecked: assignment.isChecked,
                        id: assignment.id,
                    };
                });
                setCheckedStates(initialCheckedStates);
                setPendingCheckedStates(initialCheckedStates); // Initialize pending state as well

                 // Return grouped for immediate use
                return grouped;
                
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        

    const handleMenuChange = (event) => {
        const selectedMenuId = event.target.value;
        setSelectedValue(selectedMenuId);
    console.log("SElected mainmenu id",selectedMenuId);
    console.log("grouped data is ",groupedData[selectedMenuId]);

        if (selectedMenuId && groupedData[selectedMenuId]) {
            // Show only the selected menu's submenus
            setFilteredData({
                [selectedMenuId]: groupedData[selectedMenuId],
            });
        } else {
            // Clear filtered data to show no submenus
            setFilteredData({});
        }
        console.log("Filtered data selected main menu id is ",filteredData);
    };    

    const savePurchasedDetails = async () => {
        try {
          // Prepare the data to be sent
          const data = editView.map((item) => ({
            id:  parseInt(item.id, 10),
            purchased: parseInt(item.purchased, 10), // Convert assigned value to integer
          }));
      
          console.log("save request data is:",data);
          // Make the API call
          const response = await UserService.saveAllUserDetails(
            `${process.env.REACT_APP_BASE_URL}/edit-module-assignment-save`,
            data
          );
      console.log("response for purchased details",response);
          // Handle success response
          if (response.status === 200) {
            toast("success", "Updated Purchase Details successfully");
            
            const updatedGroupedData = await fetchData(); // Fetch updated data and get grouped directly
            
                const selectedMenuId = response.mainMenuId;
                console.log("response mainMenuId is",response.mainMenuId);
                setSelectedValue(selectedMenuId);

                console.log("Set grouped data after saving",updatedGroupedData);

                
                console.log("response selected mainMenuId is",updatedGroupedData[selectedMenuId]);
            
                if (selectedMenuId && updatedGroupedData[selectedMenuId]) {
                    // Show only the selected menu's submenus
                    setFilteredData({
                        [selectedMenuId]: updatedGroupedData[selectedMenuId],
                    });
                } else {
                    // Clear filtered data to show no submenus
                    setFilteredData({});
                }
            await fetchData();

                console.log("Response groupedData[selectedMenuId] data is ",updatedGroupedData[selectedMenuId]);
                
           


            closeModal(); // Close the modal after successful save
          } else {
            console.error("Failed to save details:", response);
          }
        } catch (error) {
          // Handle error
          console.error("Error saving details:", error);
        }
      };


    useEffect(() => {
            if (selectedValue) {
              console.log("selected menu id",selectedValue);
              fetchSubmodulesForEditView();
            }
          }, [selectedValue]);

const fetchSubmodulesForEditView = async() =>{
    try {
        console.log("selected menu id for  view edit",selectedValue);

        const data = { mainMenuId: selectedValue };

        const response = await UserService.saveContactDetails(
            `${process.env.REACT_APP_BASE_URL}/purchase-details-submenuId`,
          data
        );
        console.log("Roles Response data",response);
        setEditView(response);
    } catch (error) {
  console.error("Error fetching usertype purchase data", error);
        
    }
}
    // const handleCheckboxChange = (userId, subMenuId, isChecked) => {
    //     const key = `${userId}-${subMenuId}`;
    //     setPendingCheckedStates(prevStates => ({
    //         ...prevStates,
    //         [key]: { ...prevStates[key], isChecked }
    //     }));
    // };

    const handleCheckboxChange = (userId, subMenuId, isChecked) => {
        // Simply update the pending state without validation for now
        setPendingCheckedStates(prevStates => ({
          ...prevStates,
          [`${userId}-${subMenuId}`]: { ...prevStates[`${userId}-${subMenuId}`], isChecked }
        }));
    };
      
    // const onSubmit = async () => {
    //     console.log("check pendingCheckedStates",pendingCheckedStates);
    //     // Check if at least one user has the checkbox checked for subMenuId: 30
    //     const isSubMenu30Valid = Object.entries(pendingCheckedStates).some(
    //         ([key, value]) => value.isChecked && key.endsWith('-30')
    //     );
    //     console.log("check isSubMenu30Valid",isSubMenu30Valid);

    //     if (!isSubMenu30Valid) {            
    //         toast("error", "At least one user must have access to licenses");
    //         return; 
    //     }

    //     const updatePromises = Object.entries(pendingCheckedStates).map(async ([key, value]) => {
    //         const [userId, subMenuId] = key.split('-');
    //         const isChecked = value.isChecked;
    //         const assignment = checkedStates[key];
    
    //         if (assignment && assignment.id) {
    //             // Update existing assignment
    //             console.log("Update module assignment requst id",assignment.id);
    //             console.log("Update module assignment isChecked",isChecked);

    //             return axios.put(`${process.env.REACT_APP_BASE_URL}/update-module-assignment/${assignment.id}`, {
    //                 isChecked: isChecked
    //             });
    //         } else {
    //             // Create new assignment
    //             console.log("Create new assignment");
    //             console.log("Create module assignment userId",userId);
    //             console.log("Create module assignment subMenuId",subMenuId);
    //             console.log("Create module assignment isChecked",isChecked);

    //             return axios.post(`${process.env.REACT_APP_BASE_URL}/post-module-assignment`, {
    //                 userId: userId,
    //                 subMenuId: subMenuId,
    //                 isChecked: isChecked
    //             });
    //         }
            
    //     });
    
    //     try {
    //         fetchData();
    //         await Promise.all(updatePromises);
    //         toast("success", "Updated successfully");
    //         setCheckedStates(pendingCheckedStates); // Update checked states after successful submission
    //     } catch (error) {
    //         console.error("Error updating assignments:", error);
    //         toast("error", "Failed to update");
    //     }
    // };
    
    const onSubmit = async () => {
        console.log("check pendingCheckedStates", pendingCheckedStates);
    
        // Check if at least one user has the checkbox checked for subMenuId: 30
        const isSubMenu30Valid = Object.entries(pendingCheckedStates).some(
            ([key, value]) => value.isChecked && key.endsWith('-30')
        );
        console.log("check isSubMenu30Valid", isSubMenu30Valid);
    
        if (!isSubMenu30Valid) {
            toast("error", "At least one user must have access to licenses");
            return;
        }
    
        const assignments = Object.entries(pendingCheckedStates).map(([key, value]) => {
            const [userId, subMenuId] = key.split('-');
            const isChecked = value.isChecked;
            const assignment = checkedStates[key];
    
            return {
                id: assignment?.id || null, // Include id if updating
                userId,
                subMenuId,
                isChecked
            };
        });
    
        try {
            console.log("Sending assignments:", assignments);
    
            await axios.post(`${process.env.REACT_APP_BASE_URL}/module-assignment`, { assignments });
    
            fetchData(); // Refresh data
            toast("success", "Updated successfully");
            setCheckedStates(pendingCheckedStates); // Update checked states after successful submission
        } catch (error) {
            console.error("Error updating assignments:", error);
            toast("error", "Failed to update");
        }
    };


    const handleBreadcrumbClick = (crumb) => {
        if (crumb.title === 'Admin') {
            navigate('/14/admin');
        } else if (crumb.title === 'Licenses') {
            navigate('/14/admin');
        } else if (crumb.title === 'Module Assignments') {
            navigate('/14/admin/module-assignment/114');
        } else if (crumb.path) {
            navigate(crumb.path);
        }
    };

    
    return (
        <div>
            <Header 
                title={"Admin > Licenses > Module Assignments"}
                onBreadcrumbClick={handleBreadcrumbClick}
            />
            
            {/* <div>
                {filteredData}
            </div> */}
            <div className="p-2 overflow-x-auto" >
                {/* <table className="min-w-[300px] border-collapse border border-customBlue">
                    <thead>
                        <tr className="bg-customBlue text-white text-center">
                            <th rowSpan="2" className="p-2 border-t border-white font-normal" style={{ width: '150px' }}>Module Name</th>
                            <th rowSpan="2" className="p-2 border-t border-white font-normal" style={{ width: '200px' }}>:</th>         
                            <th rowSpan="2" className="p-2 border-t border-white font-normal" style={{ width: '200px' }}>
                            <Select
    labelId="dropdown-label"
    value={selectedValue}
    onChange={handleMenuChange}
    displayEmpty
    className="min-w-[180px] h-[36px] bg-white border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-300 focus:border-blue-500 transition duration-200 ease-in-out"
>
    <MenuItem value="" className="font-normal">Select</MenuItem>
    {Object.entries(groupedData).map(([menuId, menu]) => (
        <MenuItem key={menuId} value={menuId}>
            {menu.mainMenuName}
        </MenuItem>
    ))}
</Select>

                </th>                            
                        </tr>
                       
                        <tr className="bg-customBlue text-white text-center" >
                            {Object.values(filteredData).flatMap(mainMenu => 
                                mainMenu.subMenus.map((subMenu, subIndex) => (
                                    <th key={subIndex} className="border border-gray-300 font-normal" style={{ width: '200px' }}>
                                        {subMenu.name}
                                    </th>
                                ))
                            )}
                        </tr>
                    </thead>

                    <tbody>
                        <tr className="bg-yellow-50 text-center">
                            <td colSpan="3" className="p-2 border border-gray-300">
                            <div className="flex items-center justify-center">
                                <span className='mr-2'>
                                Purchased
                                </span>
                                <IoMdCreate
                                                    onClick={hasPermissionForEdit ? openModal : null} // Only trigger modal if permission exists
                                                    title={!hasPermissionForEdit ? "You don't have permission" : ""} // Show tooltip if no permission
                                                    className={` ${hasPermissionForEdit ? "cursor-pointer text-black" : "text-gray-400"}`} // Disable style if no permission
                                                  />
                                </div>

                                </td>
                            {Object.values(filteredData).flatMap(mainMenu => 
                                mainMenu.subMenus.map(subMenu => (
                                    <td key={subMenu.name} className="p-2 border border-gray-300">{subMenu.purchased}</td>
                                ))
                            )}
                        </tr>
                        <tr className="bg-yellow-50 text-center">
                            <td colSpan="3" className="p-2 border border-gray-300">Assigned</td>
                            {Object.values(filteredData).flatMap(mainMenu => 
                                mainMenu.subMenus.map(subMenu => (
                                    <td key={subMenu.name} className="p-2 border border-gray-300">{subMenu.assigned}</td>
                                ))
                            )}
                        </tr>
                        <tr className="bg-yellow-50 text-center">
                            <td colSpan="3" className="p-2 border border-gray-300">Available</td>
                            {Object.values(filteredData).flatMap(mainMenu => 
                                mainMenu.subMenus.map(subMenu => (
                                    <td key={subMenu.name} className="p-2 border border-gray-300">{subMenu.available}</td>
                                ))
                            )}
                        </tr>

                        <tr className="bg-white text-center">
                            <td className="p-2 border border-gray-300 text-customBlue font-semibold">User ID</td>
                            <td className="p-2 border border-gray-300 text-customBlue font-semibold">Username</td>
                            <td className="p-2 border border-gray-300 text-customBlue font-semibold">User Type</td>
                            {Object.values(filteredData).flatMap(mainMenu => 
                                mainMenu.subMenus.map(subMenu => (
                                    <td key={subMenu.name} className="p-2 border border-gray-300"></td>
                                ))
                            )}
                        </tr>
                        {userData.map(user => (
                            <tr key={user.id} className="bg-blue-50 text-center">
                                <td className="p-2 border border-gray-300">{user.userId}</td>
                                <td className="p-2 border border-gray-300">{user.username}</td>
                                <td className="border border-gray-300">{userTypes[user.userType] || "Unknown"}</td>
                                {Object.values(filteredData).flatMap(mainMenu => 
                                    mainMenu.subMenus.map(subMenu => (
                                        <td key={`${user.id}-${subMenu.id}`} className="border border-gray-300">
                                            <Checkbox 
                                                checked={pendingCheckedStates[`${user.id}-${subMenu.id}`]?.isChecked || false}
                                                onChange={(event) => handleCheckboxChange(user.id, subMenu.id, event.target.checked)}
                                                sx={{color: 'green', '&.Mui-checked': {color: '#29CB00;'},}}
                                            />
                                        </td>
                                    ))
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table> */}

{/* <div className="overflow-x-auto"> */}
  <table className="min-w-[300px] border-collapse border border-customBlue">
    <thead>
      <tr className="bg-customBlue text-white text-center">
        <th rowSpan="2" className="p-2 border-t border-white font-normal" style={{ width: '150px' }}>Module Name</th>
        <th rowSpan="2" className="p-2 border-t border-white font-normal" style={{ width: '200px' }}>:</th>
        <th rowSpan="2" className="p-2 border-t border-white font-normal" style={{ width: '200px' }}>
          <Select
            labelId="dropdown-label"
            value={selectedValue}
            onChange={handleMenuChange}
            displayEmpty
            className="min-w-[180px] h-[36px] bg-white border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-300 focus:border-blue-500 transition duration-200 ease-in-out"
          >
            <MenuItem value="" className="font-normal">Select</MenuItem>
            {Object.entries(groupedData).map(([menuId, menu]) => (
              <MenuItem key={menuId} value={menuId}>
                {menu.mainMenuName}
              </MenuItem>
            ))}
          </Select>
        </th>
      </tr>
      <tr className="bg-customBlue text-white text-center">
        {Object.values(filteredData).flatMap(mainMenu =>
          mainMenu.subMenus.map((subMenu, subIndex) => (
            <th key={subIndex} className="border border-gray-300 font-normal" style={{ width: '200px' }}>
              {subMenu.name}
            </th>
          ))
        )}
      </tr>
    </thead>

    <tbody>
      {/* Static Rows */}
      <tr className="bg-yellow-50 text-center">
        <td colSpan="3" className="p-2 border border-gray-300">
          <div className="flex items-center justify-center">
            <span className="mr-2">Purchased</span>
            <IoMdCreate
              onClick={hasPermissionForEdit ? openModal : null}
              title={!hasPermissionForEdit ? "You don't have permission" : ""}
              className={`${hasPermissionForEdit ? "cursor-pointer text-black" : "text-gray-400"}`}
            />
          </div>
        </td>
        {Object.values(filteredData).flatMap(mainMenu =>
          mainMenu.subMenus.map(subMenu => (
            <td key={subMenu.name} className="p-2 border border-gray-300">{subMenu.purchased}</td>
          ))
        )}
      </tr>
      <tr className="bg-yellow-50 text-center">
        <td colSpan="3" className="p-2 border border-gray-300">Assigned</td>
        {Object.values(filteredData).flatMap(mainMenu =>
          mainMenu.subMenus.map(subMenu => (
            <td key={subMenu.name} className="p-2 border border-gray-300">{subMenu.assigned}</td>
          ))
        )}
      </tr>
      <tr className="bg-yellow-50 text-center">
        <td colSpan="3" className="p-2 border border-gray-300">Available</td>
        {Object.values(filteredData).flatMap(mainMenu =>
          mainMenu.subMenus.map(subMenu => (
            <td key={subMenu.name} className="p-2 border border-gray-300">{subMenu.available}</td>
          ))
        )}
      </tr>
      <tr className="bg-white text-center">
                            <td className="p-2 border border-gray-300 text-customBlue font-semibold">User ID</td>
                            <td className="p-2 border border-gray-300 text-customBlue font-semibold">Username</td>
                            <td className="p-2 border border-gray-300 text-customBlue font-semibold">User Type</td>
                            {Object.values(filteredData).flatMap(mainMenu => 
                                mainMenu.subMenus.map(subMenu => (
                                    <td key={subMenu.name} className="p-2 border border-gray-300"></td>
                                ))
                            )}
                        </tr>
      {/* Scrollable User Rows */}
      <tr>
        <td colSpan="100%">
        <TableWrapper maxHeight="55vh">
          <div className="overflow-x-auto" >
            <table className="min-w-full table-auto">
              <tbody>
                {userData.map(user => (
                  <tr key={user.id} className="bg-blue-50 text-center">
                    <td className="p-2 border border-gray-300" style={{ width: '150px' }}>{user.userId}</td>
                    <td className="p-2 border border-gray-300" style={{ width: '200px' }}>{user.username}</td>
                    <td className="border border-gray-300" style={{ width: '200px' }}>
                      {userTypes[user.userType] || "Unknown"}
                    </td>
                    {Object.values(filteredData).flatMap(mainMenu =>
                      mainMenu.subMenus.map(subMenu => (
                        <td key={`${user.id}-${subMenu.id}`} className="border border-gray-300" style={{ width: '200px' }}>
                          <Checkbox
                            checked={pendingCheckedStates[`${user.id}-${subMenu.id}`]?.isChecked || false}
                            onChange={(event) => handleCheckboxChange(user.id, subMenu.id, event.target.checked)}
                            sx={{ color: 'green', '&.Mui-checked': { color: '#29CB00;' } }}
                          />
                        </td>
                      ))
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          </TableWrapper>
        </td>
      </tr>
    </tbody>
  </table>
{/* </div> */}


                {/* Modal */}
                {isModalOpen && (
                        <>
                        {/* Backdrop */}
                        <div
                            className="fixed inset-0 bg-black bg-opacity-50 z-40"
                            onClick={closeModal} // Closes modal when clicking outside
                        />

                        {/* Modal Content */}
                        <div
                            className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-3xl z-50"
                            onClick={(e) => e.stopPropagation()} // Prevents close on modal content click
                        >
                            <div className="flex justify-between items-center mt-2">
                            <h2 className="text-customBlue text-lg font-semibold mb-5">Edit Purchase Details</h2>
                            </div>

                            {/* Table inside the modal */}
                            <div className="overflow-x-auto">
                            <table
                className="table-fixed min-w-full bg-white border border-customBlue"
                style={{
                    borderCollapse: "collapse", // Add this style to collapse borders
                }}
                >
                <thead
                    className={`bg-customBlue text-center text-sm font-small border border-customBlue sticky top-0 z-10`}
                >
                    <tr className="z-20">
                    <th
                        className="text-center text-white font-medium border-r border-customTableBorder whitespace-nowrap bg-customBlue sticky top-0 z-10 h-[30px]"
                        style={{
                        width: "30px",
                        minWidth: "30px",
                        maxWidth: "30px",
                        }}
                    >
                        Sub Modules
                    </th>
                    <th
                        className="text-center text-white font-medium border-r border-customTableBorder whitespace-nowrap bg-customBlue sticky top-0 z-10 h-[30px]"
                        style={{
                        width: "30px",
                        minWidth: "30px",
                        maxWidth: "30px",
                        }}
                    >
                        Purchase
                    </th>
                    </tr>
                </thead>
                <tbody>
                    {editView.map((row, index) => (
                    <tr
                        key={index}
                        className={index === editView.length - 1 ? "border-b-2" : ""}
                    >
                        <td className="text-center px-4 py-2 border-b">{row.subMenu.name}</td>
                        <td className="text-center px-4 py-2 border-b">
                        <input
                            type="text"
                            value={row.purchased}
                            onChange={(e) => {
                            const updatedView = editView.map((item, idx) =>
                                idx === index ? { ...item, purchased: e.target.value } : item
                            );
                            setEditView(updatedView);
                            }}
                            className="border border-gray-300 rounded-md p-1 text-center w-full"
                        />
                        </td>
                    </tr>
                    ))}
                </tbody>
                </table>

                            </div>

                            {/* Apply Button */}
                            <div className="mt-6 text-right">
                            <button
                                onClick={closeModal}
                                className="bg-white text-customBlue border-customTableBorder px-4 py-2"
                            >
                                Cancel
                            </button>
                            <button
                                className="bg-customBlue text-white px-4 py-2 rounded-md hover:bg-blue-600"
                                onClick={savePurchasedDetails} // Call the fetch function on Save
                            >
                                Save
                            </button>
                            </div>
                        </div>
                        </>
                    )}

            </div>        

            <Footer>
            <button
    onClick={hasPermissionForSave ? onSubmit : null} // Only allow onClick if permission exists
    disabled={!hasPermissionForSave} // Disable button if no permission
    title={!hasPermissionForSave ? "You don't have permission" : ""} // Show tooltip if no permission
    className={`bg-customBlue text-sm text-white py-1 px-2 rounded transition duration-200 ease-in-out w-16 ml-7 ${
      hasPermissionForSave ? "hover:bg-blue-600" : "bg-gray-400"
    }`} // Change style dynamically
  >
    Submit
  </button>
  {/* <button
              onClick={onSubmit}
              className="bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ml-7"
            >
              Submit
            </button> */}


          </Footer>
        </div>
    );
}
