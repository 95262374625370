import React, { useEffect, useState } from "react";
import Tabs from "../../../../../components/Tabs";
import Footer from "../../../../../components/footer";
import ButtonGroup from "../../../../../components/FooterButton";
import Header from "../../../../../components/Header";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LegalEntityServices from "../../../../../rest-services/LegalEntityServices";
import { useToast } from "../../../../../components/toast/toast";
import { RxDropdownMenu } from "react-icons/rx";
import CurrencyServicess from "../../../../../rest-services/CurrencyServices";
import CountryServices from "../../../../../rest-services/CountryServices";
import StateProvinceServices from "../../../../../rest-services/StateProvinceServices";
import CompanyServices from "../../../../../rest-services/CompanyServices";
import { Checkbox } from "@mui/material";
import CustomButton from "../../../../../components/CustomButton";
import BuGroupServices from "../../../../../rest-services/BuGroupServices";
import { TbFlag3Filled } from "react-icons/tb";
import ReusableModal from "../../../../../components/PopupComponent";
import LabelWithInput from "../../../../../components/InputFiledComponent";
import LabelWithButton from "../../../../../components/InputButtonField";
import TableWrapper from "../../../../../components/ScrollBarComponent";
import { set } from "lodash";

export default function CompanyAdd() {
  const [activeTab, setActiveTab] = useState("addcompany");
  const navigate = useNavigate();
  const { companyId } = useParams();
  const location = useLocation();
  const { mode } = location.state || {};
  const isViewMode = mode === "view";
  const [errors, setErrors] = useState({}); // Validation errors
  const [assignee, setAssignee] = useState({ id: "", assigner: "" }); // State to store assignee
  const { toast } = useToast();
  const [alert, setAlert] = useState(null);
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const [companyCode, setCompanyCode] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyShortName, setCompanyShortName] = useState("");
  const [legalEntities, setLegalEntities] = useState([]);
  const [buGroups, setBugroups] = useState([]);
  const [sourceSystemCurrencyCode, setSourceSystemCurrencyCode] = useState("");
  const [chartOfAccountingCode, setChartOfAccountingCode] = useState("");
  const [accountingSystem, setAccountingSystem] = useState("");
  const [accountingPeriodCode, setAccountingPeroidCode] = useState("");
  const [contactPersonName, setContactPersonName] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  const [faxNo, setFaxNo] = useState("");
  const [status, setStatus] = useState("");
  const [lgeCode, setLgeCode] = useState("");
  const [lgeDescription, setLgeDescription] = useState("");
  const [buGroupCode, setBuGroupCode] = useState("");
  const [bugroupName, setBuGroupName] = useState("");
  const isEditMode = !!companyId;

  const [currencyCode, setCurrencyCode] = useState("");
  const [currencyName, setCurrencyName] = useState("");
  const [currencies, setCurrencies] = useState([]);
  const [countryCode, setCountryCode] = useState(""); // Country Code
  const [countryName, setCountryName] = useState("");
  const [district, setdistrict] = useState("");
  const [poBox, setpoBox] = useState("");
  const [state, setstate] = useState("");
  const [stateName, setStateName] = useState("");
  // const [countryCode, setcountryCode] = useState("");
  const [countryId, setCountryId] = useState("");
  const [areaBlock, setareaBlock] = useState("");
  const [roadNo, setroadNo] = useState("");
  const [roadName, setRoadName] = useState("");
  const [streetNo, setstreetNo] = useState("");
  const [buildingName, setbuildingName] = useState("");
  const [buildingNo, setbuildingNo] = useState("");
  const [doorNo, setdoorNo] = useState("");
  const [floorNo, setfloorNo] = useState("");
  const [addressLine1, setaddressLine1] = useState("");
  const [addressLine2, setaddressLine2] = useState("");
  const [addressLine3, setaddressLine3] = useState("");
  const [countries, setCountries] = useState([]);
  const [stateProvinces, setStateProvinces] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(countryCode || "");
  const [postalCode, setpostalCode] = useState("");
  const [searchQueryState, setSearchQueryState] = useState(""); // State for search query
  const [selectedState, setSelectedState] = useState(state || "");
  const [selectedBuGroup, setSelectedBugroup] = useState(state || "");
  const [selectedBuGroupName, setSelectedBugroupName] = useState(state || "");

  const [streetName, setstreetName] = useState("");
  const [areaName, setareaName] = useState("");
  // const [cityName, setcityName] = useState("");
  const [landMark, setlandMark] = useState("");
  const [zipCode, setzipCode] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [selectedCurrencyName, setSelectedCurrencyName] = useState("");
  const [isModalOpenForState, setIsModalOpenForState] = useState(false);
  const [isCountryModalOpen, setIsCountryModalOpen] = useState(false);
  const [isCurrencyModalOpen, setIsCurrencyModalOpen] = useState(false);
  const [isCurrencyNameModalOpen, setIsCurrencyNameModalOpen] = useState(false);
  const [isStateModalOpen, setIsStateModalOpen] = useState(false);
  const [filteredStateProvinces, setFilteredStateProvinces] = useState([]);
  const [isLgeModalOpen, setIsLgeModalOpen] = useState(false);
  const [isLgeNameModalOpen, setIsLgeNameModalOpen] = useState(false);
  const [isBuGroupModalOpen, setIsBuGroupModalOpen] = useState(false);
  const [isBuGroupNameModalOpen, setIsBuGroupNameModalOpen] = useState(false);
  const [selectedLge, setSelectedLge] = useState(false);
  const [selectedLgeName, setSelectedLgeName] = useState(false);
  const [isAddressChecked, setIsAddressChecked] = useState(false);
  const [isValidationTriggered, setIsValidationTriggered] = useState(false);
  const [isCopyMode, setIsCopyMode] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [isStateNameModalOpen, setIsStateNameModalOpen] = useState(false);
  const [isCountryNameModalOpen, setIsCountryNameModalOpen] = useState(false);
  const [phase, setPhase] = useState("");
  const [sector, setSector] = useState("");
  const [village, setVillage] = useState("");
  const [town, setTown] = useState("");
  const [city, setCity] = useState("");
  useEffect(() => {
    fetchcountry();
    fetchcurrency();
    fetchStateProvinces();
    fetchLegalEntity();
    fetchBuGroups();
    fetchAssignee();
  }, []);

  useEffect(() => {
    if (location.state?.copiedData) {
      setIsCopyMode(true);
      const copiedData = location.state.copiedData;
      // Set address fields
      if (copiedData.addresses && copiedData.addresses.length > 0) {
        const address = copiedData.addresses[0]; // Use the first address
        setdoorNo(address.doorNo);
        setfloorNo(address.floorNo);
        setbuildingNo(address.buildingNo);
        setbuildingName(address.buildingName);
        setlandMark(address.landMark);
        setstreetNo(address.streetNo);
        setstreetName(address.streetName);
        setroadNo(address.roadName);
        setRoadName(address.roadName);
        setareaBlock(address.areaBlock);
        setareaName(address.areaName);
        setaddressLine1(address.addressLine1);
        setaddressLine2(address.addressLine2);
        setaddressLine3(address.addressLine3);
        setstate(address.state);
        setdistrict(address.district);
        setpostalCode(address.postalCode);
        setzipCode(address.zipCode);
        setpoBox(address.poBox);
        setPhase(address.phase);
        setSector(address.sector);
        setVillage(address.village);
        setTown(address.town);
        setCity(address.city);
        // Map country
        const selectedCountryData = countries.find(
          (country) => country.country === address.countryCode
        );
        if (selectedCountryData) {
          setSelectedCountry(selectedCountryData.country);
          setCountryCode(selectedCountryData.country);
          setCountryName(selectedCountryData.countryName);
        } else {
          console.warn('Country not found for code:', address.countryCode);
        }

        // Map state
        const selectedStateData = stateProvinces.find(
          (state) => state.state === address.state
        );
        if (selectedStateData) {
          setSelectedState(selectedStateData.state);
          setStateName(selectedStateData.State_Name);
          setstate(selectedStateData.state);
        } else {
          console.warn('State not found for code:', address.state);
        }
      } else {
        console.warn('No addresses found in copiedData.');
      }
      if (copiedData.account && copiedData.account.length > 0) {
        const address = copiedData.account[0]; // Use the first address
        setSourceSystemCurrencyCode(address.sourceSystemCurrencyCode);
        setChartOfAccountingCode(address.chartOfAccountingCode);
        setAccountingPeroidCode(address.accountingPeriodCode);
        setAccountingSystem(address.accountingSystem);

      }
      if (copiedData.contact && copiedData.contact.length > 0) {
        const address = copiedData.contact[0]; // Use the first address
        setContactPersonName(address.contactPersonName);
        setMobileNo(address.mobileNo);
        setEmail(address.email);
        setTelephone(address.telephone);
        setFaxNo(address.faxNo);


      }
      // Set other copied fields
      setCompanyCode(copiedData.companyCode || "");
      setCompanyName(copiedData.companyName || "");
      setCompanyShortName(copiedData.companyShortName || "")
      if (copiedData.legalEntityCode) {
        const selectedLegalEntity = legalEntities.find(
          (entity) => entity.LGE_Code === copiedData.legalEntityCode
        );

        if (selectedLegalEntity) {
          setLgeCode(selectedLegalEntity.LGE_Code); // Set the legal entity code
          setLgeDescription(selectedLegalEntity.LGE_Description); // Set the legal entity description
          setSelectedLge(selectedLegalEntity.LGE_Code); // Update UI-selected value
          setSelectedLgeName(selectedLegalEntity.LGE_Description);
        } else {
          console.warn('Legal entity not found for code:', copiedData.legalEntityCode);
        }
      }
      if (copiedData.buGroupCode) {
        const selectedBugroup = buGroups.find(
          (bugroup) => bugroup.BU_Group === copiedData.buGroupCode
        );

        if (selectedBugroup) {
          setBuGroupCode(selectedBugroup.BU_Group); // Set the legal entity code
          setBuGroupName(selectedBugroup.BU_Group_Description); // Set the legal entity description
          setSelectedBugroup(selectedBugroup.BU_Group); // Update UI-selected value
          setSelectedBugroupName(selectedBugroup.BU_Group_Description);
        } else {
          console.warn('bugroupnot found for code:', copiedData.buGroupCode);
        }
      }
      if (copiedData.currency) {
        const selectedCurrency = currencies.find(
          (cur) => cur.currencyCode === copiedData.currency
        );

        if (selectedCurrency) {
          setCurrencyCode(selectedCurrency.currencyCode); // Set the legal entity code
          setCurrencyName(selectedCurrency.Currency_Name); // Set the legal entity description
          setSelectedCurrency(selectedCurrency.currencyCode); // Update UI-selected value
          setSelectedCurrencyName(selectedCurrency.Currency_Name);
        } else {
          console.warn('bugroupnot found for code:', copiedData.currencyCode);
        }
      }
    }
    else if (companyId) {
      getDataForEdit();
    }
    fetchAssignee();
  }, [companyId, currencies, countries, stateProvinces, legalEntities, buGroups, location.state]);
  // useEffect(() => {
  //   if (companyId) {
  //     getDataForEdit();
  //   }
  // }, [companyId, currencies, countries, stateProvinces,legalEntities,buGroups]);

  // const handleCheckboxChange = (event) => {
  //   const selectedName = event.target.value; // Change from value to country name
  //   setSelectedCountry(selectedName);

  //   // Find the selected country data by country name instead of code
  //   const selectedCountryData = countries.find(country => country.countryName === selectedName);
  //   if (selectedCountryData) {
  //     setCountryCode(selectedCountryData.country); // Set the country code based on the selected country name
  //     setCountryName(selectedCountryData.countryName);
  //   }
  // };


  const getDataForEdit = async () => {
    try {
      const response = await CompanyServices.getCompanyById(companyId);
      console.log("Response data:", response);
      const address = response.addresses[0];
      const account = response.account[0];
      const contact = response.contact[0];

      // Existing data handling
      setCompanyCode(response.companyCode);
      setCompanyName(response.companyName);
      setCompanyShortName(response.companyShortName);
      setBuGroupCode(response.buGroupCode);
      setLgeCode(response.legalEntityCode); // Adjust according to API response structure
      setCountryCode(address.countryCode); // Set country code
      setStatus(response.status);
      setCurrencyCode(response.currency); // Set currency code
      setdoorNo(address.doorNo);
      setfloorNo(address.floorNo);
      setbuildingNo(address.buildingNo);
      setbuildingName(address.buildingName);
      setlandMark(address.landMark);
      setstreetNo(address.streetNo);
      setstreetName(address.streetName);
      setroadNo(address.roadName);
      setRoadName(address.roadName);
      setareaBlock(address.areaBlock);
      setareaName(address.areaName);
      setaddressLine1(address.addressLine1);
      setaddressLine2(address.addressLine2);
      setaddressLine3(address.addressLine3);
      setstate(address.state);
      setdistrict(address.district);
      setpostalCode(address.postalCode);
      setzipCode(address.zipCode);
      setpoBox(address.poBox);
      setPhase(address.phase);
      setSector(address.sector);
      setVillage(address.village);
      setTown(address.town);
      setCity(address.city);
      setSourceSystemCurrencyCode(account.sourceSystemCurrencyCode);
      setChartOfAccountingCode(account.chartOfAccountingCode);
      setAccountingSystem(account.accountingSystem);
      setAccountingPeroidCode(account.accountingPeriodCode);
      setContactPersonName(contact.contactPersonName);
      setMobileNo(contact.mobileNo);
      setEmail(contact.email);
      setTelephone(contact.telephone);
      setFaxNo(contact.faxNo);
      if (response.legalEntityCode) {
        const selectedLegalEntity = legalEntities.find(
          (entity) => entity.LGE_Code === response.legalEntityCode
        );

        if (selectedLegalEntity) {
          setLgeCode(selectedLegalEntity.LGE_Code); // Set the legal entity code
          setLgeDescription(selectedLegalEntity.LGE_Description); // Set the legal entity description
          setSelectedLge(selectedLegalEntity.LGE_Code); // Update UI-selected value
          setSelectedLgeName(selectedLegalEntity.LGE_Description);
        } else {
          console.warn('Legal entity not found for code:', response.legalEntityCode);
        }
      }
      const bu = buGroups.find(
        (c) => c.BU_Group === response.buGroupCode
      );
      console.log("bu code from response:", response.buGroupCode);
      console.log("Available bu:", buGroups);
      if (bu) {
        setBuGroupName(bu.BU_Group_Description); // Set currency name for display
        setSelectedBugroup(bu.BU_Group); // Set the selected currency based on currency name
        setBuGroupCode(bu.BU_Group); // Set the currency code// Set the selected country based on countryName
setSelectedBugroupName(bu.BU_Group_Description);      } else {
        console.error("bu not found for the provided bu code.");
      }
      const currency = currencies.find(
        (c) => c.currencyCode === response.currency
      );
      console.log("Currency code from response:", response.Currency);
      console.log("Available currencies:", currencies);
      if (currencies) {
        setCurrencyName(currency.Currency_Name); // Set currency name for display
        setSelectedCurrency(currency.currencyCode); // Set the selected currency based on currency name
        setCurrencyCode(currency.currencyCode); // Set the currency code// Set the selected country based on countryName
        setSelectedCurrencyName(currency.Currency_Name);  
      } else {
        console.error("Country not found for the provided country code.");
      }

      // Country lookup based on country code from response
      const country = countries.find(
        (c) => c.country === address.countryCode
      );
      console.log("country code from response:", address.countryCode);
      console.log("Available countries:", countries);
      if (countries) {
        setCountryName(country.countryName); // Set currency name for display
        setSelectedCountry(country.countryName); // Set the selected currency based on currency name
        // Set the currency code// Set the selected country based on countryName
      } else {
        console.error("Country not found for the provided country code.");
      }

      // for state
      const fetchedState = stateProvinces.find(
        (c) => c.state === address.state
      );
      console.log("State code from response:", address.state);
      console.log("Available states:", stateProvinces);
      console.log("the fetched console", fetchedState.State_Name);
      if (fetchedState) {
        setStateName(fetchedState.State_Name); // Set state name for display
      } else {
        console.error(
          "State not found for the provided state code:",
          response.state
        );
      }

      // Currency lookup based on currency code from response

    }
    catch (error) {
      console.error("Error fetching legal entity data:", error);
      setAlert({
        severity: "error",
        message: "Error fetching legal entity data.",
      });
    }

  };

  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.userId || "",
        };
        setAssignee(assigneeData);
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

  const fetchcountry = async () => {
    try {
      const response = await CurrencyServicess.getAllCalendar(
        `${process.env.REACT_APP_BASE_URL}/get-country`
      );
      console.log("Fetched countries:", response); // Add this log
      setCountries(response.data.data); // Ensure this is correct
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };
  const fetchStateProvinces = async () => {
    try {
      const response = await StateProvinceServices.getAllStateprovince(
        `${process.env.REACT_APP_BASE_URL}/get-stateprovince`
      );
      console.log("Fetched StateProvinces:", response); // Add this log
      setStateProvinces(response.data.data); // Ensure this is correct
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };
  const fetchcurrency = async () => {
    try {
      const response = await CurrencyServicess.getAllCalendar(
        `${process.env.REACT_APP_BASE_URL}/get-currency`
      );
      console.log("Fetched Currencies:", response); // Add this log
      setCurrencies(response.data.data); // Ensure this is correct
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };
  const fetchLegalEntity = async () => {
    try {
      const response = await LegalEntityServices.getAllLegalEntity(
        `${process.env.REACT_APP_BASE_URL}/get-all-legal-entity`
      );
      console.log("Fetched Legal Entity:", response); // Add this log
      setLegalEntities(response.data);
      console.log("helooooo", legalEntities)// Ensure this is correct
    } catch (error) {
      console.error("Error fetching LGE:", error);
    }
  };
  const fetchBuGroups = async () => {
    try {
      const response = await BuGroupServices.getAllBuGroup(
        `${process.env.REACT_APP_BASE_URL}/get-bugroup`
      );
      console.log("Fetched BuGROUPS:", response); // Add this log
      setBugroups(response.data.data);
      console.log("helooooo", buGroups);// Ensure this is correct
    } catch (error) {
      console.error("Error fetching BuGroups:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      // Skip duplication check for edit mode
      if (!companyId || isCopyMode) {
        const duplicateCheckResponse = await CompanyServices.checkDuplicateCompanycode(
          `${process.env.REACT_APP_BASE_URL}/getduplicatecompany`,
          { companyCode: companyCode }
        );

        if (duplicateCheckResponse.message === "company code already exists") {
          toast("error", "Company Code already exists.");
          setErrors((prev) => ({
            ...prev,
            companyCode: "Company Code already exists.",
          }));
          return; // Stop further execution if duplicate exists
        }
      }
      const data = {
        id: isCopyMode ? null : companyId, // Use 'id' for updates
        legalEntityCode: lgeCode,
        buGroupCode,
        companyCode,
        companyName,
        companyShortName,
        currency: currencyCode,
        created_by: assignee.assigner,
        changed_by: assignee.assigner,
        doorNo,
        floorNo,
        buildingNo,
        buildingName,
        landMark,
        streetNo,
        streetName,
        roadNo,
        roadName,
        areaBlock,
        areaName,
        addressLine1,
        addressLine2,
        addressLine3,
        countryCode,
        state,
        district,
        postalCode,
        zipCode,
        poBox,
        phase,
        sector,
        village,
        town,
        city,
        sourceSystemCurrencyCode,
        chartOfAccountingCode,
        accountingSystem,
        accountingPeriodCode,
        contactPersonName,
        mobileNo,
        email,
        telephone,
        faxNo,
      };
      console.log("helooooo", data);

      let response;
      if (isCopyMode || !companyId) {
        // Updating the existing currency
        response = await CompanyServices.addCompany(
          `/create-company`, // Ensure this endpoint is for updates
          data
        );
      } else {
        // Creating a new currency
        response = await CompanyServices.addCompany(
          `/update-company`,
          data
        );
      }

      if (response && !response.error) {
        toast(
          "success",
          companyId && !isCopyMode
            ? "Company Updated successfully"
            : "Company Added successfully"
        );
        // Clear the form and navigate
        setCompanyCode("");
        setCompanyName("");
        setCompanyShortName("");
        setLgeCode("");
        setLgeDescription("");
        setSelectedLge("");
        setBuGroupCode("");
        setBuGroupName("");
        setSelectedBugroup("");
        setCurrencyCode("");
        setCurrencyName("");
        setSelectedCurrency("");
        setSelectedCountry("");
        setSelectedState("");
        setSourceSystemCurrencyCode("");
        setChartOfAccountingCode("");
        setAccountingSystem("");
        setAccountingPeroidCode("");
        setContactPersonName("");
        setMobileNo("");
        setEmail("");
        setTelephone("");
        setFaxNo("");
        setdoorNo("");
        setfloorNo("");
        setbuildingNo("");
        setbuildingName("");
        setlandMark("");
        setstreetNo("");
        setstreetName("");
        setroadNo("");
        setRoadName("");
        setareaBlock("");
        setareaName("");
        setaddressLine1("");
        setaddressLine2("");
        setaddressLine3("");
        setstate("");
        setdistrict("");
        setpostalCode("");
        setpoBox("");
        setzipCode("");
        setPhase("");
        setSector("");
        setVillage("");
        setTown("");
        setCity("");
        setStateName("");
        setCountryCode("");
        setCountryName("");
        setIsValidationTriggered(false);

        navigate("/2/master-data/9/company");

        // Redirect after saving
      } else {
        // toast("error", "Error adding/updating Company");
      }
    } catch (error) {
      console.error("Request error:", error);
      setAlert({
        severity: "error",
        message: `Error adding/updating Company: ${error.message}`,
      });
    }
  };
  const handleSaveandContinue = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      // Skip duplication check for edit mode
      if (!companyId || isCopyMode) {
        const duplicateCheckResponse = await CompanyServices.checkDuplicateCompanycode(
          `${process.env.REACT_APP_BASE_URL}/getduplicatecompany`,
          { companyCode: companyCode }
        );

        if (duplicateCheckResponse.message === "company code already exists") {
          toast("error", "Company Code already exists.");
          setErrors((prev) => ({
            ...prev,
            companyCode: "Company Code already exists.",
          }));
          return; // Stop further execution if duplicate exists
        }
      }
      const data = {
        id: isCopyMode ? null : companyId, // Use 'id' for updates
        legalEntityCode: lgeCode,
        buGroupCode,
        companyCode,
        companyName,
        companyShortName,
        currency: currencyCode,
        created_by: assignee.assigner,
        changed_by: assignee.assigner,
        doorNo,
        floorNo,
        buildingNo,
        buildingName,
        landMark,
        streetNo,
        streetName,
        roadNo,
        roadName,
        areaBlock,
        areaName,
        addressLine1,
        addressLine2,
        addressLine3,
        countryCode,
        state,
        district,
        postalCode,
        zipCode,
        poBox,
        phase,
        sector,
        village,
        town,
        city,
        sourceSystemCurrencyCode,
        chartOfAccountingCode,
        accountingSystem,
        accountingPeriodCode,
        contactPersonName,
        mobileNo,
        email,
        telephone,
        faxNo,
      };
      console.log("helooooo", data);

      let response;
      if (isCopyMode || !companyId) {
        // Updating the existing currency
        response = await CompanyServices.addCompany(
          `/create-company`, // Ensure this endpoint is for updates
          data
        );
      } else {
        // Creating a new currency
        response = await CompanyServices.addCompany(
          `/update-company`,
          data
        );
      }

      if (response && !response.error) {
        toast(
          "success",
          companyId && !isCopyMode
            ? "Company Updated successfully"
            : "Company Added successfully"
        );
        // Clear the form and navigate
        setCompanyCode("");
        setCompanyName("");
        setCompanyShortName("");
        setLgeCode("");
        setLgeDescription("");
        setSelectedLge("");
        setBuGroupCode("");
        setBuGroupName("");
        setSelectedBugroup("");
        setCurrencyCode("");
        setCurrencyName("");
        setSelectedCurrency("");
        setSelectedCountry("");
        setSelectedState("");
        setSourceSystemCurrencyCode("");
        setChartOfAccountingCode("");
        setAccountingSystem("");
        setAccountingPeroidCode("");
        setContactPersonName("");
        setMobileNo("");
        setEmail("");
        setTelephone("");
        setFaxNo("");
        setdoorNo("");
        setfloorNo("");
        setbuildingNo("");
        setbuildingName("");
        setlandMark("");
        setstreetNo("");
        setstreetName("");
        setroadNo("");
        setRoadName("");
        setareaBlock("");
        setareaName("");
        setaddressLine1("");
        setaddressLine2("");
        setaddressLine3("");
        setstate("");
        setdistrict("");
        setpostalCode("");
        setpoBox("");
        setzipCode("");
        setPhase("");
        setSector("");
        setVillage("");
        setTown("");
        setCity("");
        setStateName("");
        setCountryCode("");
        setCountryName("");
        setIsAddressChecked(false);
        setIsValidationTriggered(false);
        // Redirect after saving
      } else {
        // toast("error", "Error adding/updating Company");
      }
    } catch (error) {
      console.error("Request error:", error);
      setAlert({
        severity: "error",
        message: `Error adding/updating Company: ${error.message}`,
      });
    }
  };
  const checkDuplicateCompanyCode = async (code) => {
    try {
      const response = await CompanyServices.checkDuplicateCompanycode(
        `/getduplicatecompany`,
        { companyCode: code }  // Sending roleName to backend
      );

      console.log("Response from server:", response);

      if (response.message === "company code already exists") {
        // Check if it's a duplicate
        console.log("Response from internal server:", response);
        setErrors({ companyCode: " " });

        toast("error", "Company Code already exists.");
      }
      console.log("Response from external server:", response);
    } catch (error) {
      console.error("Error checking duplicate Lgecode:", error);
      // Handle potential errors when making the API call
      setErrors((prev) => ({ ...prev, companyCode: "Error checking role name" }));
    }
  };
  const handleCompanyCodeChange = (e) => {
    const code = e.target.value;
    setUnsavedChanges(true);
    setCompanyCode(code);

    // Clear previous errors when typing
    setErrors(prev => ({ ...prev, companyCode: "" }));


    if (code.trim() !== "") {
      checkDuplicateCompanyCode(code);
    }
  };
  // Separate handler for country selection
  const handleCountryCheckBoxChange = (event) => {
    console.log(`this is the Legal Entity ${countries}`);

    const selectedName = event.target.value; // Get the selected country name
    setSelectedCountry(selectedName);
    setUnsavedChanges(true);
    const selectedCountryData = countries.find(
      (country) => country.country === selectedName
    );
    if (selectedCountryData) {
      setCountryCode(selectedCountryData.country); // Set the country code
      setCountryName(selectedCountryData.countryName); // Set the country name

      // Filter states by the selected country code
      const filteredStates = stateProvinces.filter(
        (state) => state.Country_Code === selectedCountryData.country
      );
      setFilteredStateProvinces(filteredStates); // Assuming you have state to hold the filtered states
    }
  };


  // Separate handler for Legal Entity selection
  const handleCurrencyCheckBoxChange = (event) => {
    const selectedValue = event.target.value; // Value from the checkbox (currency code or name)
  
    // Find the selected currency object
    const selectedCurrencyData = currencies.find(
      (currency) => currency.currencyCode === selectedValue || currency.Currency_Name === selectedValue
    );
  
    if (selectedCurrencyData) {
      if (selectedValue === selectedCurrencyData.currencyCode) {
        // Handle selection for currency code
        setSelectedCurrency(selectedCurrencyData.currencyCode);
        setCurrencyCode(selectedCurrencyData.currencyCode);
        setCurrencyName(selectedCurrencyData.Currency_Name);
        setSelectedCurrencyName(selectedCurrencyData.Currency_Name);
      } else if (selectedValue === selectedCurrencyData.Currency_Name) {
        // Handle selection for currency name
        setSelectedCurrencyName(selectedCurrencyData.Currency_Name);
        setCurrencyName(selectedCurrencyData.Currency_Name);
        setCurrencyCode(selectedCurrencyData.currencyCode);
        setSelectedCurrency(selectedCurrencyData.currencyCode);
      }
      setUnsavedChanges(true); // Mark unsaved changes
    } else {
      console.error("Selected currency not found in the list.");
    }
  };
  const handleLgeCheckBoxChange = (event) => {

    const selectedValue = event.target.value; // Value from the checkbox (currency code or name)
  
    // Find the selected currency object
    const selectedlge = legalEntities.find(
      (lge) => lge.LGE_Code === selectedValue || lge.LGE_Description === selectedValue
    );
  
    if (selectedlge) {
      if (selectedValue === selectedlge.LGE_Code) {
        // Handle selection for currency code
        setSelectedLge(selectedlge.LGE_Code);
        setLgeCode(selectedlge.LGE_Code);
        setLgeDescription(selectedlge.LGE_Description);
        setSelectedLgeName(selectedlge.LGE_Description);
      } else if (selectedValue === selectedlge.LGE_Description) {
        // Handle selection for currency name
        setSelectedLge(selectedlge.LGE_Code);
        setLgeCode(selectedlge.LGE_Code);
        setLgeDescription(selectedlge.LGE_Description);
        setSelectedLgeName(selectedlge.LGE_Description);
      }
      setUnsavedChanges(true); // Mark unsaved changes
    } else {
      console.error("Selected currency not found in the list.");
    }
  };
  const handleBuGroupCheckBoxChange = (event) => {
    const selectedValue = event.target.value; // Value from the checkbox (currency code or name)
  
    // Find the selected currency object
    const selectedbu = buGroups.find(
      (bu) => bu.BU_Group === selectedValue || bu.BU_Group_Description === selectedValue
    );
  
    if (selectedbu) {
      if (selectedValue === selectedbu.BU_Group) {
        // Handle selection for currency code
        setSelectedBugroup(selectedbu.BU_Group);
        setBuGroupCode(selectedbu.BU_Group);
        setBuGroupName(selectedbu.BU_Group_Description);
        setSelectedBugroupName(selectedbu.BU_Group_Description);
      } else if (selectedValue === selectedbu.BU_Group_Description) {
        // Handle selection for currency name
        setSelectedBugroup(selectedbu.BU_Group);
        setBuGroupCode(selectedbu.BU_Group);
        setBuGroupName(selectedbu.BU_Group_Description);
        setSelectedBugroupName(selectedbu.BU_Group_Description);
      }
      setUnsavedChanges(true); // Mark unsaved changes
    } else {
      console.error("Selected currency not found in the list.");
    }
  };
  const handleCheckboxChangeForState = (event) => {
    const selectedCode = event.target.value;
    console.log("selected State code", selectedCode);
    setSelectedState(selectedCode);
    setstate(selectedCode);
    setUnsavedChanges(true);

    const selectedCountryData = stateProvinces.find(
      (stateP) => stateP.state === selectedCode
    );
    console.log("selected state country data", selectedCountryData);
    if (selectedCountryData) {
      console.log(
        "check selected country data",
        selectedCountryData.State_Name
      );
      setStateName(selectedCountryData.State_Name);
    }
  };
  const handleCheckBoxForAddress = (event) => {
    if (event.target.checked) {
      setUnsavedChanges(true);
      console.log("Selected lgeCode:", lgeCode); // Check if lgeCode is being set properly

      // Find the legal entity by its LGE_Code
      const targetLge = legalEntities.find((lge) => lge.LGE_Code === lgeCode);

      console.log("Matching legal entity:", targetLge);

      if (targetLge) {
        // Ensure you are accessing the addresses array
        if (targetLge.addresses && targetLge.addresses.length > 0) {

          const address = targetLge.addresses[0]; // Access the first address

          console.log("Address found:", address); // Log the address object

          // Set the address fields
          setdoorNo(address.doorNo || "");
          setfloorNo(address.floorNo || "");
          setbuildingNo(address.buildingNo || "");
          setbuildingName(address.buildingName || "");
          setlandMark(address.landMark || "");
          setstreetNo(address.streetNo || "");
          setstreetName(address.streetName || "");
          setroadNo(address.roadNo || "");
          setRoadName(address.roadName || "");
          setareaBlock(address.areaBlock || "");
          setareaName(address.areaName || "");
          setaddressLine1(address.addressLine1 || "");
          setaddressLine2(address.addressLine2 || "");
          setaddressLine3(address.addressLine3 || "");
          setCountryCode(address.countryCode || "");
          setstate(address.state || "");
          setdistrict(address.district || "");
          setpostalCode(address.postalCode || "");
          setzipCode(address.zipCode || "");
          setpoBox(address.poBox || "");

          // Fetch and set country details
          const selectedCountryData = countries.find(
            (country) => country.country === address.countryCode
          );

          if (selectedCountryData) {
            setCountryCode(selectedCountryData.country);
            setCountryName(selectedCountryData.countryName);

            // Filter states by the selected country code
            const filteredStates = stateProvinces.filter(
              (state) => state.Country_Code === selectedCountryData.country
            );
            setFilteredStateProvinces(filteredStates);
          }

          // Fetch and set state details
          const selectedStateData = stateProvinces.find(
            (state) => state.state === address.state
          );

          if (selectedStateData) {
            setStateName(selectedStateData.State_Name);
          }

        } else {
          console.error("No address available for the selected legal entity.");
        }
      } else {
        console.error("No matching legal entity found for the provided lgeCode");
      }
    } else {
      // Clear address fields if unchecked
      setdoorNo("");
      setfloorNo("");
      setbuildingNo("");
      setbuildingName("");
      setlandMark("");
      setstreetNo("");
      setstreetName("");
      setCountryCode("");
      setCountryName("");
      setStateName("");
      setRoadName("");
      setareaBlock("");
      setareaName("");
      setaddressLine1("");
      setaddressLine2("");
      setaddressLine3("");
      setCountryCode("");
      setstate("");
      setdistrict("");
      setpostalCode("");
      setzipCode("");
      setpoBox("");


    }
  };




  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleSearchChangeState = (e) => {
    setSearchQueryState(e.target.value);
  };

  const validateForm = () => {
    setIsValidationTriggered(true);

    let newErrors = {};
    const isAddressSectionFilled = addressLine1 || addressLine2 || addressLine3;
    const isBuildingSectionFilled = doorNo || floorNo || buildingNo || buildingName || landMark;
    const isStreetSectionFilled = streetNo || streetName || roadNo || roadName;
    const isAreaSectionFilled = areaBlock || areaName;
    const iscitySectionFilled =  city || town || village || sector ||  phase;
    const isBuildingStreetAreaSectionFilled = isBuildingSectionFilled || isStreetSectionFilled || isAreaSectionFilled || iscitySectionFilled;
    // Validate Address and Building/Street/Area
    if (!isAddressSectionFilled && !isBuildingStreetAreaSectionFilled) {
      newErrors.addressSection = "Either Address Section or Building, Street, and Area Section must be entered";
    }

    // Validate Other Fields
    if (!companyCode) newErrors.companyCode = "Company Code is required.";
    if (!companyName) newErrors.companyName = "Company Name is required.";
    if (!companyShortName) newErrors.companyShortName = "Company Short Name is required.";
    if (!bugroupName) newErrors.bugroupName = "Bugroup Name is required.";
    if (!lgeDescription) newErrors.lgeDescription = "LGE Description is required.";
    if (!currencyName) newErrors.currencyName = "Currency Code is required.";
    if (filteredStateProvinces.length > 0 && !state) {
      newErrors.state = "State is required.";
    }
    if (!countryCode) newErrors.countryCode = "Country Code is required.";

    // Set Errors and Show Toast if There are Missing Fields
    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      toast("error", "Missing Required Fields");
    }

    return Object.keys(newErrors).length === 0;
  };
  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === "Company & Bank Data") {
      navigate("/2/master-data");
    } else if (crumb.title === "Company") {
      navigate("/2/master-data/9/company");
    } else if (crumb.title === "Primary Data") {
      navigate("/2/master-data");
    } else if (crumb.path) {
      navigate(crumb.path);
    }
  };
  const toggleModalForState = () => {
    setIsModalOpenForState(!isModalOpenForState);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const tabs = [
    { value: "addcompany", label: "Basic Details" },
    { value: "companyaddress", label: "Company Address" },
    { value: "account", label: "Account" },
    { value: "contact", label: "Contact" },
  ];
  const errorss = {
    addcompany: !companyCode || !companyName || !companyShortName || !lgeDescription || !bugroupName || !currencyName,
    companyaddress: !addressLine1 && !addressLine2 && !addressLine3 && !doorNo && !floorNo && !buildingNo && !buildingName && !landMark && !streetNo && !streetName && !roadNo && !roadName && !areaBlock && !areaName && !countryCode && !countryName && !state && !stateName && !city && !town && !village && !sector && !phase,

  };
  const nextToAddress = () => {
    setActiveTab("companyaddress")

  }
  const nextToAccount = () => {
    setActiveTab("account")

  }
  const nextToContact = () => {
    setActiveTab("contact")

  }


 
  const handlelgeReset = () => {
    setSearchQuery(''); // Reset search query
    setSelectedLge(""); // Reset selected bank ID
    setLgeDescription("");
    setLgeCode("");
  };
  const handlebugroupReset = () => {
    setSearchQuery(''); // Reset search query
    setSelectedBugroup(""); // Reset selected bank ID
    setBuGroupName("");
    setBuGroupCode("");
  };
  const handlecurrencyReset = () => {
    setSearchQuery(''); // Reset search query
    setSelectedCurrency(""); // Reset selected bank ID
    setCurrencyName("");
    setCurrencyCode("");
  };
  const handleCountryReset = () => {
    setSearchQuery(''); // Reset search query
    setSelectedCountry(""); // Reset selected bank ID
    setCountryCode("");
    setCountryName("");
    setstate("");
    setStateName("")
  };
  const handleCountryNameReset = () => {
    setSearchQuery(''); // Reset search query
    setSelectedCountry(""); // Reset selected bank ID
    setCountryCode("");
    setCountryName("");
    setstate("");
    setStateName("")
  };
  const handleStateReset = () => {
    setSearchQueryState(''); // Reset search query
    setSelectedState(""); // Reset selected bank ID
    setstate("");
    setStateName("")
  };
  const handleStateNameReset = () => {
    setSearchQueryState(''); // Reset search query
    setSelectedState(""); // Reset selected bank ID
    setstate("");
    setStateName("")
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    return emailRegex.test(email);
  };
  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
setUnsavedChanges(true);
    // Validate email
    if (!isValidEmail(value)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
  };
  const getApprovedIcon = () => {
    let color = "black"; // Default color
    // Determine the color based on the approved status
    if (status === 4) {
      color = "#29CB00"; // Green
    } else if (status === 0) {
      color = "gray"; // Gray
    } else if (status === 1) {
      color = "#AF52DE"; 
    } else if (status === 2) {
      color = "yellow"; 
    } else if (status === 3) {
      color = "orange"; 
    }
    else if (status === 5) {
      color = "red"; // Red
    }

    return <TbFlag3Filled style={{ color, marginLeft: '8px' }} />; // Return the icon with the determined color
  };
  const handleRefreshbugroup = async () => {
    try {
      await fetchBuGroups();  // Fetch Branchs
    } catch (error) {
      console.error('Error reloading Branchs:', error);
    } finally {
    }
  };
  const handleRefreshlge = async () => {
    try {
      await fetchLegalEntity();  // Fetch Branchs
    } catch (error) {
      console.error('Error reloading Branchs:', error);
    } finally {
    }
  };
  const handleRefreshcurrency = async () => {
    try {
      await fetchcurrency();  // Fetch Branchs
    } catch (error) {
      console.error('Error reloading Branchs:', error);
    } finally {
    }
  };
  const handleRefreshcountry = async () => {
    try {
      await fetchcountry();  // Fetch Branchs
    } catch (error) {
     
    } finally {
    }
  };
  const handleRefreshstate = async () => {
    try {
      await fetchStateProvinces();  // Fetch Branchs
    } catch (error) {
      console.error('Error reloading Branchs:', error);
    } finally {
    }
  };
  return (
    <div>
      <Header
        title={`Primary Data > Company & Bank Data > Company > ${
    isCopyMode 
      ? 'Add' 
      : companyId 
        ? (isViewMode ? "View" : "Edit") 
        : "Add"
  }${companyId && (isViewMode || !isCopyMode) ? ` > ${companyCode}` : ''}`}
        onBreadcrumbClick={handleBreadcrumbClick}
        extraContent={companyId && !isCopyMode ? getApprovedIcon() : null}
        hasUnsavedChanges={unsavedChanges}
        backRoute={"/2/master-data/9/company"}
      />
      <Tabs tabs={tabs} activeTab={activeTab} onTabClick={handleTabClick} isValidationTriggered={isValidationTriggered} errors={errorss} />
      <div className="flex-grow">
        {activeTab === "addcompany" && (
          <div className="m-6 border border-gray-200 p-5  rounded-lg">

          <div className="flex flex-col space-y-8 col-span-1 p-6">
            <div className=" space-y-8">
              {/* First row: Single field (LGE) */}
              <div className="grid grid-cols-2 gap-8">
              <div className="flex items-center">
              <LabelWithInput
        label="Company Code "
        value={companyCode}
        onChange={handleCompanyCodeChange}
        maxLength={10}
        error={errors.companyCode}
        isRequired={true}
        isDisabled={((isEditMode && !isCopyMode) || isViewMode)}
        />
              </div>
              {selectedLge && !isViewMode && (
            <div className="flex items-center">
            <input 
                type="checkbox"

                        checked={isAddressChecked} // Control the checkbox with state
                        onChange={(event) => {
                          setIsAddressChecked(event.target.checked); // Update the checkbox state
                          handleCheckBoxForAddress(event); // Call your existing function
                        }}
                        className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded accent-customBlue"
                      />

                      <p className="p-4 text-sm font-small text-customGray">
                        Copy the Legal Entity Address
                      </p>
                    </div>
                  )}
                </div>
                {/* Second row: Two fields (LGE Code and LGE Description) */}
                <div className="grid grid-cols-2 gap-8">
                  <div className="flex items-center">
                    <LabelWithInput
                      label="Company Name"
                      value={companyName}
                      onChange={(e) => {
                        setCompanyName(e.target.value);
                        setUnsavedChanges(true);
                        if (errors.companyName) {
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            companyName: "",
                          }));
                        }
                      }}
                      maxLength={150}
                      error={errors.companyName}
                      isRequired={true}
                      isDisabled={isViewMode}
                    />
                  </div>

                  <div className="flex items-center">
                    <LabelWithInput
                      label="Company Short Name"
                      value={companyShortName}
                      onChange={(e) => {
                        setCompanyShortName(e.target.value);
                        setUnsavedChanges(true);
                        if (errors.companyShortName) {
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            companyShortName: "",
                          }));
                        }
                      }}
                      maxLength={15}
                      error={errors.companyShortName}
                      isRequired={true}
                      isDisabled={isViewMode}
                    />
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-8">
                <div className="flex items-center">
                  <LabelWithButton
                    label="Legal Entity Code"
                    isRequired={true}
                    isDisabled={isViewMode}
                    onClick={() => { setIsLgeModalOpen(true); }
                    }
                    buttonText={selectedLge || 'Select LGE Code'}
                    error={errors.lgeCode}
                  />

                  <ReusableModal
                    title="Select Lge Code"
                    isOpen={isLgeModalOpen}
                    onClose={() => {
                      setIsLgeModalOpen(false);
                      setSearchQuery('');
                    }}
                    onSave={() => {
                      setIsLgeModalOpen(false);
                      setSearchQuery('');
                    }}
                    searchPlaceholder="Search lge..."
                    searchQuery={searchQuery}
                    showReset={true}
                    onReset={handlelgeReset}
                    onSearchChange={handleSearchChange}
                    isViewMode={isViewMode}
                    onRefresh={handleRefreshlge}
                  >
                    {legalEntities && legalEntities.length > 0 ? (
                      legalEntities
                        .filter(
                          (lge) =>
                            lge.status === 4 &&
                            (lge.LGE_Code &&
                              lge.LGE_Code.trim() &&
                              lge.LGE_Code
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase()))

                        )
                        .sort((a, b) => a.LGE_Code.localeCompare(b.LGE_Code)) // Sort in alphabetical order

                        .map((lge, index) => (
                          <div
                            key={lge.id}
                            className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""
                              }`}
                          >
                            <input
                              type="radio"
                              name="Lge Code"
                              value={lge.LGE_Code}
                              checked={
                                selectedLge === lge.LGE_Code
                              }
                              onChange={handleLgeCheckBoxChange}
                              className="mr-2 accent-customBlue"
                              disabled={isViewMode}
                            />
                            <label>{lge.LGE_Code}</label>
                          </div>
                        ))

                    ) : (
                      <p>Loading lge...</p>
                    )}
                  </ReusableModal>
                </div>
                <div className="flex items-center">
                  <LabelWithButton
                    label="Legal Entity Name"
                    isRequired={true}
                    isDisabled={isViewMode}
                    onClick={() => { setIsLgeNameModalOpen(true); }
                    }
                    buttonText={selectedLgeName || 'Select LGE Name'}
                    error={errors.lgeDescription}
                  />

                  <ReusableModal
                    title="Select Lge Name"
                    isOpen={isLgeNameModalOpen}
                    onClose={() => {
                      setIsLgeNameModalOpen(false);
                      setSearchQuery('');
                    }}
                    onSave={() => {
                      setIsLgeNameModalOpen(false);
                      setSearchQuery('');
                    }}
                    searchPlaceholder="Search lge..."
                    searchQuery={searchQuery}
                    showReset={true}
                    onReset={handlelgeReset}
                    onSearchChange={handleSearchChange}
                    isViewMode={isViewMode}
                    onRefresh={handleRefreshlge}
                  >
                    {legalEntities && legalEntities.length > 0 ? (
                      legalEntities
                        .filter(
                          (lge) =>
                            lge.status === 4 &&
                            (lge.LGE_Description &&
                              lge.LGE_Description.trim() &&
                              lge.LGE_Description
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase()))

                        )
                        .sort((a, b) => a.LGE_Description.localeCompare(b.LGE_Description)) // Sort in alphabetical order

                        .map((lge, index) => (
                          <div
                            key={lge.id}
                            className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""
                              }`}
                          >
                            <input
                              type="radio"
                              name="Lge Name"
                              value={lge.LGE_Description}
                              checked={
                                selectedLgeName === lge.LGE_Description
                              }
                              onChange={handleLgeCheckBoxChange}
                              className="mr-2 accent-customBlue"
                              disabled={isViewMode}
                            />
                            <label>{lge.LGE_Description}</label>
                          </div>
                        ))

                    ) : (
                      <p>Loading lge...</p>
                    )}
                  </ReusableModal>
                </div>

                
              </div>
              <div className="grid grid-cols-2 gap-8">
                <div className="flex items-center">
                  <LabelWithButton
                    label="Bu Group Code"
                    isRequired={true}
                    isDisabled={isViewMode}
                    onClick={() => { setIsBuGroupModalOpen(true); }
                    }
                    buttonText={selectedBuGroup || 'Select Bugroup Code'}
                    error={errors.buGroupCode}
                  />


                  <ReusableModal
                    title="Select Bugroup Code"
                    isOpen={isBuGroupModalOpen}
                    onClose={() => {
                      setIsBuGroupModalOpen(false);
                      setSearchQuery('');
                    }}
                    onSave={() => {
                      setIsBuGroupModalOpen(false);
                      setSearchQuery('');
                    }}
                    searchPlaceholder="Search bugroup..."
                    searchQuery={searchQuery}
                    showReset={true}
                    onReset={handlebugroupReset}
                    onSearchChange={handleSearchChange}
                    isViewMode={isViewMode}
                    onRefresh={handleRefreshbugroup}
                  >
                    {buGroups.length > 0 ? (
                      buGroups
                        .filter(
                          (bugroup) =>
                            bugroup.status === 4 &&
                            (bugroup.BU_Group &&
                              bugroup.BU_Group.trim() &&
                              bugroup.BU_Group
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase()))

                        )
                        .sort((a, b) => a.BU_Group.localeCompare(b.BU_Group)) // Sort in alphabetical order

                        .map((bugroup, index) => (
                          <div
                            key={bugroup.id}

                          >
                            <input
                              type="radio"
                              name="Bugroup Code"
                              value={bugroup.BU_Group}
                              checked={
                                selectedBuGroup === bugroup.BU_Group
                              }
                              onChange={handleBuGroupCheckBoxChange}
                              className="mr-2 accent-customBlue"
                              disabled={isViewMode}
                            />
                            <label>{bugroup.BU_Group}</label>
                          </div>
                        ))
                    ) : (
                      <p>Loading Bugroups...</p>
                    )}
                  </ReusableModal>
                </div>

                <div className="flex items-center">
                  <LabelWithButton
                    label="Bu Group Names"
                    isRequired={true}
                    isDisabled={isViewMode}
                    onClick={() => { setIsBuGroupNameModalOpen(true); }
                    }
                    buttonText={selectedBuGroupName || 'Select Bugroup Names'}
                    error={errors.buGroupName}
                  />


                  <ReusableModal
                    title="Select Bugroup Name"
                    isOpen={isBuGroupNameModalOpen}
                    onClose={() => {
                      setIsBuGroupNameModalOpen(false);
                      setSearchQuery('');
                    }}
                    onSave={() => {
                      setIsBuGroupNameModalOpen(false);
                      setSearchQuery('');
                    }}
                    searchPlaceholder="Search bugroup..."
                    searchQuery={searchQuery}
                    showReset={true}
                    onReset={handlebugroupReset}
                    onSearchChange={handleSearchChange}
                    isViewMode={isViewMode}
                    onRefresh={handleRefreshbugroup}
                  >
                    {buGroups.length > 0 ? (
                      buGroups
                        .filter(
                          (bugroup) =>
                            bugroup.status === 4 &&
                            (bugroup.BU_Group_Description &&
                              bugroup.BU_Group_Description.trim() &&
                              bugroup.BU_Group_Description
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase()))

                        )
                        .sort((a, b) => a.BU_Group_Description.localeCompare(b.BU_Group_Description)) // Sort in alphabetical order

                        .map((bugroup, index) => (
                          <div
                            key={bugroup.id}

                          >
                            <input
                              type="radio"
                              name="Bugroup Code"
                              value={bugroup.BU_Group_Description}
                              checked={
                                selectedBuGroupName === bugroup.BU_Group_Description
                              }
                              onChange={handleBuGroupCheckBoxChange}
                              className="mr-2 accent-customBlue"
                              disabled={isViewMode}
                            />
                            <label>{bugroup.BU_Group_Description}</label>
                          </div>
                        ))
                    ) : (
                      <p>Loading Bugroups...</p>
                    )}
                  </ReusableModal>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-8">
                <div className="flex items-center">
                  <LabelWithButton
                    label="Currency Code"
                    isRequired={true}
                    isDisabled={isViewMode}
                    onClick={() => { setIsCurrencyModalOpen(true); }
                    }
                    buttonText={selectedCurrency || 'Select Currency Code'}
                    error={errors.currencyCode}
                  />

                
          <ReusableModal
  title="Select Currency"
  isOpen={isCurrencyModalOpen}
  onClose={() => {
    setIsCurrencyModalOpen(false);
    setSearchQuery('');
  }}
  onSave={() => {
    setIsCurrencyModalOpen(false);
    setSearchQuery('');
  }}
  searchPlaceholder="Search Currency..."
  searchQuery={searchQuery}
  showReset={true}
  onReset={handlecurrencyReset}
  onRefresh={handleRefreshcurrency}

  onSearchChange={handleSearchChange}
  isViewMode={isViewMode}
>
  {currencies.length > 0 ? (
                            currencies
                              .filter(
                                (currency) =>
                                  (currency.currencyCode &&
                                    currency.currencyCode.trim() &&
                                    currency.currencyCode
                                      .toLowerCase()
                                      .includes(searchQuery.toLowerCase())) 
                                 
                              )
                              .sort((a, b) => a.currencyCode.localeCompare(b.currencyCode)) // Sort in alphabetical order

                        .map((currency, index) => (
                          <div
                            key={currency.id}
                            className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""
                              }`}
                          >
                            <input
                              type="radio"
                              name="Currency"
                              value={currency.currencyCode}
                              checked={
                                selectedCurrency ===
                                currency.currencyCode
                              }
                              onChange={handleCurrencyCheckBoxChange}
                              className="mr-2 accent-customBlue"
                              disabled={isViewMode}
                            />
                            <label>{currency.currencyCode}</label>
                          </div>
                        ))

                    ) : (
                      <p>Loading Currencies...</p>
                    )}
                  </ReusableModal>
                </div>

                <div className="flex items-center">
                  <LabelWithButton
                    label="Currency Name"
                    isRequired={true}
                    isDisabled={isViewMode}
                    onClick={() => { setIsCurrencyNameModalOpen(true); }
                    }
                    buttonText={selectedCurrencyName || 'Select Currency Name'}
                    error={errors.currencyName}
                  />

                
          <ReusableModal
  title="Select Currency Name"
  isOpen={isCurrencyNameModalOpen}
  onClose={() => {
    setIsCurrencyNameModalOpen(false);
    setSearchQuery('');
  }}
  onSave={() => {
    setIsCurrencyNameModalOpen(false);
    setSearchQuery('');
  }}
  searchPlaceholder="Search Currency..."
  searchQuery={searchQuery}
  showReset={true}
  onReset={handlecurrencyReset}
  onRefresh={handleRefreshcurrency}

  onSearchChange={handleSearchChange}
  isViewMode={isViewMode}
>
  {currencies.length > 0 ? (
                            currencies
                              .filter(
                                (currency) =>
                                  (currency.Currency_Name &&
                                    currency.Currency_Name.trim() &&
                                    currency.Currency_Name
                                      .toLowerCase()
                                      .includes(searchQuery.toLowerCase())) 
                                 
                              )
                              .sort((a, b) => a.Currency_Name.localeCompare(b.Currency_Name)) // Sort in alphabetical order

                        .map((currency, index) => (
                          <div
                            key={currency.id}
                            className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""
                              }`}
                          >
                            <input
                              type="radio"
                              name="Currency"
                              value={currency.Currency_Name}
                              checked={
                                selectedCurrencyName ===
                                currency.Currency_Name
                              }
                              onChange={handleCurrencyCheckBoxChange}
                              className="mr-2 accent-customBlue"
                              disabled={isViewMode}
                            />
                            <label>{currency.Currency_Name}</label>
                          </div>
                        ))

                    ) : (
                      <p>Loading Currencies...</p>
                    )}
                  </ReusableModal>
                </div>
                {/* {(!isViewMode && (
  <button
          onClick={handleSubmit}
          className="mt-4 bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16"
        >
          Save
        </button>
))} */}
              </div>

              {/* <CustomButton onClick={nextToAddress} title={"Next"}/> */}
            </div>
          </div>
        )}

        {activeTab === "companyaddress" && (
          <div className="m-6 border border-gray-200 p-5  rounded-lg">
            <TableWrapper
              maxHeight="70vh">
              <div className="p-1">
                {/* Building Section */}
                <div className="mb-6 border border-gray-300 p-5 rounded-lg">
                  <h3 className="text-md font-medium text-customGray mb-4">Building</h3>

                  <div className="grid grid-cols-2 gap-4">
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Door No </label>
                      <span className="ml-4">:</span>
                      <input type="text"
                        value={doorNo}
                        maxLength={30}

                        onChange={(e) => {
                          setdoorNo(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        disabled={isViewMode}
                        className="border border-gray-300 p-1.5 rounded w-80 h-8  focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray font-small text-customGray text-sm" />
                    </div>
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Floor No </label>
                      <span className="ml-4">:</span>
                      <input type="text"
                        value={floorNo}
                        maxLength={30}

                        disabled={isViewMode}
                        onChange={(e) => {
                          setfloorNo(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        placeholder="4" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm font-small text-customGray text-sm " />
                    </div>
                  </div>


                  <div className="grid grid-cols-2 gap-4 mt-4">
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Building No </label>
                      <span className="ml-4">:</span>
                      <input type="text"
                        value={buildingNo}
                        maxLength={30}

                        disabled={isViewMode}
                        onChange={(e) => {
                          setbuildingNo(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        placeholder="2A" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm font-small text-customGray text-sm" />
                    </div>
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Building Name </label>
                      <span className="ml-4">:</span>
                      <input type="text"
                        value={buildingName}
                        maxLength={30}

                        disabled={isViewMode}
                        onChange={(e) => {
                          setbuildingName(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        placeholder="B name" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm font-small text-customGray text-sm" />
                    </div>
                  </div>

                  <div className="flex items-center mt-4">
                    <label className="w-48 font-small text-customGray text-sm">Landmark</label>
                    <span className="ml-4">:</span>
                    <input type="text"
                      value={landMark}
                      maxLength={30}

                      onChange={(e) => {
                        setlandMark(e.target.value)
                        setUnsavedChanges(true);
                      }}
                      disabled={isViewMode}
                      placeholder="" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm" />
                  </div>
                </div>

                {/* Street Section */}
                <div className="mb-6 border border-gray-300 p-5 rounded-lg">
                  <h3 className="text-md font-medium text-customGray  mb-4">Street</h3>

                  <div className="grid grid-cols-2 gap-4">
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Street No </label>
                      <span className="ml-4">:</span>
                      <input
                        value={streetNo}
                        maxLength={30}

                        onChange={(e) => {
                          setstreetNo(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        disabled={isViewMode}

                        type="text" placeholder="2/626" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray" />
                    </div>
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Street Name</label>
                      <span className="ml-4">:</span>
                      <input type="text" value={streetName} maxLength={30}
                        disabled={isViewMode} onChange={(e) => {
                          setstreetName(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray" />
                      {/* {errors.streetName && (
                    <p className="text-red-500 text-sm ml-4">{errors.streetName}</p>
                  )} */}
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-4 mt-4">
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Road No </label>
                      <span className="ml-4">:</span>
                      <input type="text"
                        value={roadNo}
                        maxLength={30}

                        onChange={(e) => {
                          setroadNo(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        disabled={isViewMode}
                        placeholder="2A" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray" />
                    </div>
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Road Name </label>
                      <span className="ml-4">:</span>
                      <input
                        type="text"
                        value={roadName}
                        maxLength={30}

                        onChange={(e) => {
                          setRoadName(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        placeholder="B name"
                        disabled={isViewMode}
                        className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray"
                      />
                    </div>
                  </div>
                </div>

                {/* Area Section */}
                <div className="mb-6 border border-gray-300 p-5 rounded-lg">
                  <h3 className="text-md font-medium text-customGray  mb-4">Area</h3>

                  <div className="grid grid-cols-2 gap-4">
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Block </label>
                      <span className="ml-4">:</span>
                      <input type="text"
                        value={areaBlock}
                        maxLength={30}

                        onChange={(e) => {
                          setareaBlock(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        placeholder="2/626" disabled={isViewMode} className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray" />
                    </div>
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Area Name </label>
                      <span className="ml-4">:</span>
                      <input type="text"
                        value={areaName}
                        maxLength={30}

                        onChange={(e) => {
                          setareaName(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        placeholder="4" disabled={isViewMode} className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray" />
                    </div>
                  </div>
                </div>
                <div className="mb-6 border border-gray-300 p-5 rounded-lg">
                  <h3 className="text-md font-medium text-customGray mb-4">City</h3>

                  <div className="grid grid-cols-2 gap-4">
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Phase </label>
                      <span className="ml-4">:</span>
                      <input type="text"
                        value={phase}
                        maxLength={5}

                        onChange={(e) => {
                          setPhase(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        disabled={isViewMode}
                        className="border border-gray-300 p-1.5 rounded w-80 h-8  focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray font-small text-customGray text-sm" />
                    </div>
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Sector </label>
                      <span className="ml-4">:</span>
                      <input type="text"
                        value={sector}
                        maxLength={5}

                        disabled={isViewMode}
                        onChange={(e) => {
                          setSector(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        placeholder="4" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm font-small text-customGray text-sm " />
                    </div>
                  </div>


                  <div className="grid grid-cols-2 gap-4 mt-4">
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Village </label>
                      <span className="ml-4">:</span>
                      <input type="text"
                        value={village}
                        maxLength={5}

                        disabled={isViewMode}
                        onChange={(e) => {
                          setVillage(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        placeholder="2A" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm font-small text-customGray text-sm" />
                    </div>
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Town </label>
                      <span className="ml-4">:</span>
                      <input type="text"
                        value={town}
                        maxLength={50}

                        disabled={isViewMode}
                        onChange={(e) => {
                          setTown(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        placeholder="B name" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm font-small text-customGray text-sm" />
                    </div>
                  </div>

                  <div className="flex items-center mt-4">
                    <label className="w-48 font-small text-customGray text-sm">City</label>
                    <span className="ml-4">:</span>
                    <input type="text"
                      value={city}
                      maxLength={100}

                      onChange={(e) => {
                        setCity(e.target.value)
                        setUnsavedChanges(true);
                      }}
                      disabled={isViewMode}
                      placeholder="" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm" />
                  </div>
                </div>
                {/* Display the error message for address section */}
                {errors.addressSection && (
                  <p className="text-red-500 text-sm mb-4">{errors.addressSection}</p>
                )}

                {/* Address Section */}
                <div className="mb-6 border border-gray-300 p-5 rounded-lg">
                  <h3 className="text-md font-medium text-customGray  mb-4">Address</h3>
                  <div className="grid grid-cols-1 gap-4">
                    <div className="flex items-center">
                      <label className="w-56 font-small text-customGray text-sm">Address 1 </label>
                      <span className="ml-4">:</span>
                      <input type="text" value={addressLine1} onChange={(e) => {
                        setaddressLine1(e.target.value)
                        setUnsavedChanges(true);
                      }}
                        placeholder="2/626" maxLength={30}
                        disabled={isViewMode} className="border border-gray-300 p-1.5 rounded w-full h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray"
                      />

                      {/* {errors.addressLine1 && (
                    <p className="text-red-500 text-sm ml-4">
                      {errors.addressLine1}
                    </p>
                  )} */}
                    </div>
                    {/* <div className="flex items-center">
                <label className="w-48 font-small text-customGray text-sm">Address 2 </label>
                <span className="ml-4">:</span>
                <input type="text"  value={addressLine2}  onChange={(e) => setaddressLine2(e.target.value)}
                placeholder="4" className="border border-gray-300 p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm" />
              </div> */}
                  </div>

                  <div className="grid grid-cols-1 gap-4 mt-4">
                    <div className="flex items-center">
                      <label className="w-56 font-small text-customGray text-sm">Address 2 </label>
                      <span className="ml-4">:</span>
                      <input type="text" value={addressLine2} onChange={(e) => {
                        setaddressLine2(e.target.value)
                        setUnsavedChanges(true);
                      }}
                        placeholder="4" maxLength={30}
                        disabled={isViewMode} className="border border-gray-300 p-1.5 rounded w-full h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray" />
                    </div>

                  </div>

                  <div className="grid grid-cols-1 gap-4 mt-4">
                    <div className="flex items-center">

                      <label className="w-56 font-small text-customGray text-sm">Address 3 </label>
                      <span className="ml-4">:</span>
                      <input type="text" value={addressLine3} onChange={(e) => {
                        setaddressLine3(e.target.value)
                        setUnsavedChanges(true);
                      }}
                        placeholder="2A" maxLength={30}
                        disabled={isViewMode} className="border border-gray-300 p-1.5 rounded w-full h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray" />
                    </div>

                  </div>
                </div>


                {/* Country Section */}
                <div className="mb-6 border border-gray-300 p-5 rounded-lg">
                  <h3 className="text-md font-medium text-customGray  mb-4">
                    Country
                  </h3>

                  <div className="grid grid-cols-2 gap-4">
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">
                        Country Code{" "}
                        <span className="text-red-500 ml-1">*</span>
                      </label>
                      <span className="ml-4">:</span>
                      <button
                        onClick={() => setIsCountryModalOpen(true)}
                        disabled={isViewMode}
                        className={`border border-gray-300 p-1 disabled:bg-[#FAFAFA] rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 text-left text-customGray flex items-center justify-between ${errors.countryCode
                            ? "border-red-500 hover:border-red-500"
                            : "border-gray-300 hover:border-blue-400"
                          }`}
                      >
                        <span className="font-small text-customGray text-sm">
                          {countryCode || "Select Country Code"}
                        </span>
                        <RxDropdownMenu className="ml-2" />
                      </button>

                      <ReusableModal
                        title="Select Country Code"
                        isOpen={isCountryModalOpen}
                        onClose={() => {
                          setIsCountryModalOpen(false);
                          setSearchQuery("");
                        }}
                        onSave={() => {
                          setIsCountryModalOpen(false);
                          setSearchQuery("");
                        }}
                        showReset={true}
                        onReset={handleCountryReset}
                        searchPlaceholder="Search country..."
                        searchQuery={searchQuery}
                        onSearchChange={handleSearchChange}
                        isViewMode={isViewMode}
                      >
                        {countries.length > 0 ? (
                          countries
                            .filter((country) =>
                              country.country
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase())
                            )
                            .sort((a, b) => a.country.localeCompare(b.country)) // Sort in alphabetical order

                            .map((country) => (
                              <div
                                key={country.id}
                                className="flex items-center"
                              >
                                <input
                                  type="radio"
                                  name="country"
                                  value={country.country}
                                  checked={selectedCountry === country.country}
                                  onRefresh={handleRefreshcountry}

                                  onChange={handleCountryCheckBoxChange}
                                  className="mr-2 accent-customBlue"
                                  disabled={isViewMode}
                                />
                                <label>{country.country}</label>
                              </div>
                            ))
                        ) : (
                          <p>Loading countries...</p>
                        )}
                      </ReusableModal>
                    </div>
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">
                        Country Name{" "}
                        <span className="text-red-500 ml-1">*</span>
                      </label>
                      <span className="ml-4">:</span>
                      <button
                        onClick={() => setIsCountryNameModalOpen(true)}
                        disabled={isViewMode}
                        className={`border border-gray-300 p-1 disabled:bg-[#FAFAFA] rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 text-left text-customGray flex items-center justify-between ${errors.countryName
                            ? "border-red-500 hover:border-red-500"
                            : "border-gray-300 hover:border-blue-400"
                          }`}
                      >
                        <span className="font-small text-customGray text-sm">
                          {countryName || "Select Country Name"}
                        </span>
                        <RxDropdownMenu className="ml-2" />
                      </button>

                      <ReusableModal
                        title="Select Country Code"
                        isOpen={isCountryNameModalOpen}
                        onClose={() => {
                          setIsCountryNameModalOpen(false);
                          setSearchQuery("");
                        }}
                        onSave={() => {
                          setIsCountryNameModalOpen(false);
                          setSearchQuery("");
                        }}
                        showReset={true}
                        onReset={handleCountryNameReset}
                        searchPlaceholder="Search countryname..."
                        searchQuery={searchQuery}
                        onRefresh={handleRefreshcountry}

                        onSearchChange={handleSearchChange}
                        isViewMode={isViewMode}
                      >
                        {countries.length > 0 ? (
                          countries
                            .filter((country) =>
                              country.countryName
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase())
                            )
                            .sort((a, b) => a.countryName.localeCompare(b.countryName)) // Sort in alphabetical order

                            .map((country) => (
                              <div
                                key={country.id}
                                className="flex items-center"
                              >
                                <input
                                  type="radio"
                                  name="country"
                                  value={country.country}
                                  checked={selectedCountry === country.country}
                                  onChange={handleCountryCheckBoxChange}
                                  className="mr-2 accent-customBlue"
                                  disabled={isViewMode}
                                />
                                <label>{country.countryName}</label>
                              </div>
                            ))
                        ) : (
                          <p>Loading countries...</p>
                        )}
                      </ReusableModal>
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-4 mt-4">
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">
                        State / Province{" "}
                        <span className="text-red-500 ml-1">*</span>
                      </label>
                      <span className="ml-4">:</span>
                      <button
                        onClick={() => setIsStateModalOpen(true)}
                        disabled={isViewMode}
                        className={`border border-gray-300 p-1 disabled:bg-[#FAFAFA] rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 text-left flex items-center justify-between text-customGray ${errors.state
                            ? "border-red-500 hover:border-red-500"
                            : "border-gray-300 hover:border-blue-400"
                          }`}
                      >
                        <span className="font-small text-customGray text-sm">
                          {state || "Select State / Province"}
                        </span>
                        <RxDropdownMenu className="ml-2" />
                      </button>

                      <ReusableModal
                        title="Select State Code"
                        isOpen={isStateModalOpen}
                        onClose={() => {
                          setIsStateModalOpen(false);
                          setSearchQueryState("");
                        }}
                        onSave={() => {
                          setIsStateModalOpen(false);
                          setSearchQueryState("");
                        }}
                        showReset={true}
                        onReset={handleStateReset}
                        searchPlaceholder="Search state..."
                        searchQuery={searchQueryState}
                        onRefresh={handleRefreshstate}

                        onSearchChange={handleSearchChangeState}
                        isViewMode={isViewMode}
                      >
                        {filteredStateProvinces.length > 0 ? (
                          filteredStateProvinces
                            .filter(
                              (stateP) =>

                                stateP.state
                                  .toLowerCase()
                                  .includes(searchQueryState.toLowerCase())
                            )
                            .sort((a, b) => a.state.localeCompare(b.state)) // Sort in alphabetical order

                            .map((stateP, index) => (
                              <div
                                key={stateP.id}
                                className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""
                                  }`}
                              >
                                <input
                                  type="radio"
                                  name="country"
                                  value={stateP.state}
                                  checked={selectedState === stateP.state}
                                  onChange={handleCheckboxChangeForState}
                                  className="mr-2 accent-customBlue"
                                  disabled={isViewMode}
                                />
                                <label>{stateP.state}</label>
                              </div>
                            ))
                        ) : (
                          <p>Loading State...</p>
                        )}
                      </ReusableModal>
                    </div>

                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">
                        State / Province Name{" "}
                        <span className="text-red-500 ml-1">*</span>
                      </label>
                      <span className="ml-4">:</span>
                      <button
                        onClick={() => setIsStateNameModalOpen(true)}
                        disabled={isViewMode}
                        className={`border border-gray-300 p-1 disabled:bg-[#FAFAFA] rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 text-left flex items-center justify-between text-customGray ${errors.state
                            ? "border-red-500 hover:border-red-500"
                            : "border-gray-300 hover:border-blue-400"
                          }`}
                      >
                        <span className="font-small text-customGray text-sm">
                          {stateName || "Select State / Province Name"}
                        </span>
                        <RxDropdownMenu className="ml-2" />
                      </button>

                      <ReusableModal
                        title="Select State Code"
                        isOpen={isStateNameModalOpen}
                        onClose={() => {
                          setIsStateNameModalOpen(false);
                          setSearchQueryState("");
                        }}
                        onSave={() => {
                          setIsStateNameModalOpen(false);
                          setSearchQueryState("");
                        }}
                        showReset={true}
                        onReset={handleStateNameReset}
                        searchPlaceholder="Search statename..."
                        searchQuery={searchQueryState}
                        onRefresh={handleRefreshstate}

                        onSearchChange={handleSearchChangeState}
                        isViewMode={isViewMode}
                      >
                        {filteredStateProvinces.length > 0 ? (
                          filteredStateProvinces
                            .filter(
                              (stateP) =>
                                stateP.State_Name.toLowerCase().includes(
                                  searchQueryState.toLowerCase()
                                ) ||
                                stateP.state
                                  .toLowerCase()
                                  .includes(searchQueryState.toLowerCase())
                            )
                            .sort((a, b) => a.State_Name.localeCompare(b.State_Name)) // Sort in alphabetical order

                            .map((stateP, index) => (
                              <div
                                key={stateP.id}
                                className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""
                                  }`}
                              >
                                <input
                                  type="radio"
                                  name="country"
                                  value={stateP.state}
                                  checked={selectedState === stateP.state}
                                  onChange={handleCheckboxChangeForState}
                                  className="mr-2 accent-customBlue"
                                  disabled={isViewMode}
                                />
                                <label>{stateP.State_Name}</label>
                              </div>
                            ))
                        ) : (
                          <p>Loading State...</p>
                        )}
                      </ReusableModal>
                    </div>
                  </div>



                  {/* <div className="grid grid-cols-2 gap-4 mt-2">
            </div> */}

                  <div className="grid grid-cols-2 gap-4 mt-4">
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">District</label>
                      <span className="ml-4">:</span>
                      <input type="text"
                        value={district}
                        maxLength={30}

                        onChange={(e) => {
                          setdistrict(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        disabled={isViewMode}
                        placeholder="2A"
                        className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray" />
                    </div>
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Postal Code</label>
                      <span className="ml-4">:</span>
                      <input type="text"
                        value={postalCode}
                        maxLength={30}

                        onChange={(e) => {
                          setpostalCode(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        disabled={isViewMode}
                        placeholder="B name" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray" />
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-4 mt-4">
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Zip Code</label>
                      <span className="ml-4">:</span>
                      <input type="text"
                        value={zipCode}
                        maxLength={30}

                        onChange={(e) => {
                          setzipCode(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        disabled={isViewMode}
                        placeholder="2A" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray" />
                    </div>

                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">PO Box</label>
                      <span className="ml-4">:</span>
                      <input type="text"
                        value={poBox}
                        maxLength={30}

                        onChange={(e) => {
                          setpoBox(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        disabled={isViewMode}

                        placeholder="B name" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray" />
                    </div>

                  </div>

                </div>

                {/* <CustomButton onClick={nextToIdentifiers} title={"Next"} className={"mb-10"}/> */}
              </div>
            </TableWrapper>
          </div>
        )}
        {activeTab === "account" && (
          <div className="m-6 border border-gray-200 p-5  rounded-lg">

            <div className="flex flex-col space-y-8 col-span-1 p-6">
              <div className="grid grid-cols-2 gap-8">
                <div className="flex items-center">
                  <LabelWithInput
                    label="Source System Currency Code"
                    value={sourceSystemCurrencyCode}
                    onChange={(e) => {
                      setSourceSystemCurrencyCode(e.target.value);
                      setUnsavedChanges(true);
                    }}
                    isDisabled={isViewMode}
                  />
                </div>

                <div className="flex items-center">
                  <LabelWithInput
                    label="Chart Of Accounting Code"
                    value={chartOfAccountingCode}
                    onChange={(e) => {
                      setChartOfAccountingCode(e.target.value);
                      setUnsavedChanges(true);
                    }}
                    isDisabled={isViewMode}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-8">
                <div className="flex items-center">
                  <LabelWithInput
                    label="Accounting System"
                    value={accountingSystem}
                    onChange={(e) => {
                      setAccountingSystem(e.target.value);
                      setUnsavedChanges(true);
                    }}
                    isDisabled={isViewMode}
                  />
                </div>

                <div className="flex items-center">
                  <LabelWithInput
                    label="Accounting Period code"
                    value={accountingPeriodCode}
                    onChange={(e) => {
                      setAccountingPeroidCode(e.target.value);
                      setUnsavedChanges(true);
                    }}
                    isDisabled={isViewMode}
                  />
                </div>
              </div>
              {/* <CustomButton onClick={nextToContact} title={"Next"}/> */}
            </div>
          </div>
        )}
        {activeTab === "contact" && (
          <div className="m-6 border border-gray-200 p-5  rounded-lg">

            <div className="flex flex-col space-y-8 col-span-1 p-6">
              <LabelWithInput
                label="Contact Person Number"
                value={contactPersonName}
                maxLength={50}
                onChange={(e) => {
                  setContactPersonName(e.target.value);
                  setUnsavedChanges(true);
                }}
                isDisabled={isViewMode}
              />
              <LabelWithInput
                label="Mobile No"
                value={mobileNo}
                maxLength={13}
                onChange={(e) => {
                  setMobileNo(e.target.value);
                  setUnsavedChanges(true);
                }}
                isDisabled={isViewMode}
              />
              <div className="flex items-center gap-4">
              <LabelWithInput
                label="Email"
                value={email}
                maxLength={80}
                onChange={handleEmailChange}
                isDisabled={isViewMode}
              />
 {emailError && (
        <span className="text-red-500 text-sm mt-1">{emailError}</span>
      )}
              </div>              <LabelWithInput
                label="Telephone"
                value={telephone}
                maxLength={13}
                onChange={(e) => {
                  setTelephone(e.target.value);
                  setUnsavedChanges(true);
                }}
                isDisabled={isViewMode}
              />
              
              <LabelWithInput
                label="Fax No"
                value={faxNo}
                maxLength={13}
                onChange={(e) => {
                  setFaxNo(e.target.value);
                  setUnsavedChanges(true);
                }}
                isDisabled={isViewMode}
              />
            </div>
          </div>
        )}
      </div>
      <Footer>
        {/* Footer */}
        {!isViewMode && (
          <ButtonGroup
            onSave={handleSubmit}
            onSaveContinue={handleSaveandContinue}
            hasId={!!companyId}
          />
        )}
      </Footer>
    </div>
  );
}
