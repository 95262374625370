import React, { useEffect, useState } from "react";
import Header from "../../../../../../components/Header";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import UserService from "../../../../../../rest-services/UserServices";
import {
  IoMdCheckmark,
  IoMdClose,
  IoMdCreate,
  IoMdRefresh,
  IoMdTrash,
} from "react-icons/io";
import {
  IoAddCircleOutline,
  IoChevronDown,
  IoChevronForward,
  IoSettingsOutline,
} from "react-icons/io5";
import { LuCopyCheck, LuKeyRound } from "react-icons/lu";
import {
  MdOutlineFileDownload,
  MdOutlineFileUpload,
  MdOutlineHistory,
  MdOutlineLock,
  MdOutlineSaveAs,
} from "react-icons/md";
import Footer from "../../../../../../components/footer";
import ButtonGroup from "../../../../../../components/FooterButton";
import { useToast } from "../../../../../../components/toast/toast";
import { RxDropdownMenu } from "react-icons/rx";
import { GiSettingsKnobs } from "react-icons/gi";
import { BiSave } from "react-icons/bi";
import { VscVmActive } from "react-icons/vsc";
import LabelWithButton from "../../../../../../components/InputButtonField";
import ReusableModal from "../../../../../../components/PopupComponent";
import TableWrapper from "../../../../../../components/ScrollBarComponent";
import { Checkbox } from "@mui/material";
import { TbFlag3Filled } from "react-icons/tb";
import { PiCopy, PiNotEquals } from "react-icons/pi";
import { FaRegEdit } from "react-icons/fa";
import { BsTags, BsUbuntu } from "react-icons/bs";
import { FiBox } from "react-icons/fi";

export default function RolesAndPermissionAdd() {
  const navigate = useNavigate();
  const { UserId } = useParams();
  const location = useLocation();
  const { mode } = location.state || {}; // Get the mode from the state
  const isViewMode = mode === "view";
  const isEditMode = mode === "edit";
  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState(null);
  // const[roles,setRoles] = useState([]);
  const { toast } = useToast();
  const [selectedUserTypeName, setSelectedUserTypeName] = useState("");
  const [selectedUserTypeId, setSelectedUserTypeId] = useState("");
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const [userTypes, setUserTypes] = useState([]);
  const [userType, setUserType] = useState("");
  const [status, setStatus] = useState("");
  const [selectedUserId, setSelectedUserId] = useState("");
  const [users, setUsers] = useState([]);
  const [assignee, setAssignee] = useState({ id: "", username: "" });
  const [searchTerm, setSearchTerm] = useState("");
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedPermissions, setSelectedPermissions] = useState({});
  const [filteredIcons, setFilteredIcons] = useState([]);
  const [Icons, setIcons] = useState([]);

  const [allExpanded, setAllExpanded] = useState(false);
  const [isModalOpenForModule, setIsModalOpenForModule] = useState(false);
  const [isModalOpenForSubModule, setIsModalOpenForSubModule] = useState(false);
  const [isModalOpenForIconModule, setIsModalOpenForIconModule] =
    useState(false);

  const [searchQueryState, setSearchQueryState] = useState(""); // State for search query
  const [searchQuerySubmodule, setSearchQuerySubmodule] = useState(""); // State for search query
  const [searchQueryIconmodule, setSearchQueryIconmodule] = useState(""); // State for search query

  const [expandedMainMenu, setExpandedMainMenu] = useState(null);
  const [selectedSubMenu, setSelectedSubMenu] = useState(null);
  const [expandedSubMenu, setExpandedSubMenu] = useState({});
  const [expandedIcon, setExpandedIcon] = useState([]);
  const [selectedIcons, setSelectedIcons] = useState({});
  const [permissionIcons, setPermissionIcons] = useState([]);

  const [mainMenu, setMainMenu] = useState("");
  const [subMenu, setSubMenu] = useState("");
  const [checkboxState, setCheckboxState] = useState({});

  useEffect(() => {
    fetchUserTypes();
    fetchAssignee();
  }, []);

  useEffect(() => {
    if (assignee.clientId) {
      fetchUsers();
      fetchIconsData();
      fetchGetPermissionsData();
    }
  }, [assignee]);

  useEffect(() => {
    if (selectedRole) {
      console.log("selected role", selectedRole);
      getDataForSelectedRole();
    }
  }, [selectedRole]);


  useEffect(() => {
    if (selectedUserTypeId) {
      fetchallRoles();
    }
  }, [selectedUserTypeId]);

  useEffect(() => {
    if (UserId) {
      getDataForEdit();
    }
  }, [UserId]);

  const getDataForEdit = async () => {
    try {
      const data = { userId: UserId };
      console.log("Request Data:", data);

      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-users-permissions-by-userId`,
        data
      );

      console.log(
        "Response from server fetching roles & Permissions:",
        response
      );
      const responseData = response.data;
      console.log("set roles & permission details", responseData);
      // setRoles(responseData);
      if (responseData) {
        setUserId(responseData.userId);
        setUserType(responseData.User.userType);
        setStatus(responseData.status);
        if (responseData.roleId) {
          setSelectedRole(responseData.roleId);
          console.log("Set roleId to state:", responseData.roleId);
        } else {
          console.warn("roleId is undefined in the responseData");
        }
        console.log("set roles Id", responseData.role_id);
      }

      if (responseData.error) {
        console.error("Error fetching roles data:", responseData.message);
        setAlert({ severity: "error", message: responseData.message });
        return;
      }
    } catch (error) {
      console.error("Error fetching roles data", error);
      setAlert({
        severity: "error",
        message: `Error fetching roles data, kindly check the connection ${error.message}`,
      });
    }
  };

  const getDataForSelectedRole = async () => {
    try {
      const data = { roleId: selectedRole };
      console.log("Request Data:", data);

      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-role-by-roleId`,
        data
      );

      console.log("Response from server fetching roles:", response);
      const responseData = response.data;
      console.log("set roles details", responseData);
      // setRoles(responseData);
      if (responseData) {
        console.log("set roles details name", responseData.name);
        console.log("set roles details desc", responseData.description);

        const initialIcons = {};
        const initialPermissions = {};

        responseData.rolesPermissionsTables.forEach((rolePermission) => {
          const { iconId, permissionsId } = rolePermission;

          initialIcons[iconId] = true;
          if (!initialPermissions[iconId]) initialPermissions[iconId] = {};
          initialPermissions[iconId][permissionsId] = true;
        });

        setSelectedIcons(initialIcons);
        setSelectedPermissions(initialPermissions);
      }

      if (responseData.error) {
        console.error("Error fetching roles data:", responseData.message);
        setAlert({ severity: "error", message: responseData.message });
        return;
      }
    } catch (error) {
      console.error("Error fetching roles data", error);
      setAlert({
        severity: "error",
        message: `Error fetching roles data, kindly check the connection ${error.message}`,
      });
    }
  };

  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");

      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.username || "",
          assignerId: userDetail.userId || "",
          clientId: userDetail.clientId || "",
        };

        setAssignee(assigneeData);

        // Log the id and assigner values to the console
        console.log("Assignee ID:", assigneeData.id);
        console.log("Assigner:", assigneeData.assigner);
        console.log("Assigner userId:", assigneeData.assignerId);
        console.log("Assigner clientId:", assigneeData.clientId);
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

  const fetchIconsData = async () => {
    try {
      const response = await UserService.getUsers(
        `${process.env.REACT_APP_BASE_URL}/get-icons-data`
      );
      console.log("Response from server fetching Icons data:", response);
      const responseData = response.data.data;
      setIcons(responseData);

      console.log("double check users", response.data.data);
      if (responseData.error) {
        console.error("Error fetching Users data:", responseData.message);
        setAlert({ severity: "error", message: responseData.message });
        return;
      }
    } catch (error) {
      console.error("Error fetching Users data", error);
      setAlert({
        severity: "error",
        message: `Error fetching Users data, kindly check the connection ${error.message}`,
      });
    }
  };

  const fetchGetPermissionsData = async () => {
    try {
      const response = await UserService.getUsers(
        `${process.env.REACT_APP_BASE_URL}/get-permissions`
      );
      console.log("Response from server fetching permissions data:", response);
      const responseData = response.data.data;
      setPermissionIcons(responseData);

      console.log("double check users permissons", responseData);
      if (responseData.error) {
        console.error("Error fetching Users data:", responseData.message);
        setAlert({ severity: "error", message: responseData.message });
        return;
      }
    } catch (error) {
      console.error("Error fetching Users data", error);
      setAlert({
        severity: "error",
        message: `Error fetching Users data, kindly check the connection ${error.message}`,
      });
    }
  };

  const fetchUsers = async () => {
    try {
      console.log("assignees data", assignee);
      const data = { clientId: assignee.clientId };
      console.log("Request Data for users:", data);

      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/user/get-user/approved`,
        data
      );
      console.log("Response from server fetching users:", response);
      const responseData = response.data;
      console.log("add last seen and users data", responseData);
      setUsers(responseData);

      console.log("double check users", response.data);
      if (responseData.error) {
        console.error("Error fetching Users data:", responseData.message);
        setAlert({ severity: "error", message: responseData.message });
        return;
      }
    } catch (error) {
      console.error("Error fetching Users data", error);
      setAlert({
        severity: "error",
        message: `Error fetching Users data, kindly check the connection ${error.message}`,
      });
    }
  };

  const fetchallRoles = async () => {
    try {
      // const data = { };
      // console.log("Request selectedUserTypeName Data:", data);

      console.log("assignees data 2", assignee);
      const data = { clientId: assignee.clientId, userTypeId: selectedUserTypeId };
      console.log("Request Data for users 2:", data);

      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-roles-approved`,
        data
      );
      console.log("response data for language", response.data);
      setRoles(response.data);
    } catch (error) {
      console.error("Error fetching Language data", error);
    }
  };

  const handleUserFilterPopup = () => {
    console.log("entered");
    setIsUserModalOpen(true);
  };

  const closeUserModal = () => {
    setIsUserModalOpen(false);
  };

  const fetchUserTypes = async () => {
    try {
      const response = await UserService.getUsers(
        `${process.env.REACT_APP_BASE_URL}/get-alluser-type`
      );
      setUserTypes(response.data.data);
      console.log("Set user types data", response.data.data);
    } catch (error) {
      console.error("Error fetching user types:", error);
    }
  };

  // const selectedUserTypeName = userTypes.find(type => type.id === userType)?.name || "";
  // const selectedUserTypeName = userTypes?.find((type) => Number(type.id) === Number(userType))?.name || "";

  useEffect(() => {
    const selectedType = userTypes?.find((type) => Number(type.id) === Number(userType)) || {};
    const selectedTypeId = selectedType.id || "";
    const selectedTypeName = selectedType.name || "";

    setSelectedUserTypeId(selectedTypeId);
    setSelectedUserTypeName(selectedTypeName);
    console.log("Selected user type ID is", selectedTypeId);
    console.log("Selected user type name is", selectedTypeName);
  }, [userType, userTypes]);


  const handleCheckboxChangeForUser = (event) => {
    const selectedUserId = event.target.value; // Get the selected user's ID
    console.log("selected user id data", selectedUserId);
    setSelectedUserId(selectedUserId); // Update the state to reflect the selected user

    const selectedUserData = users.find(
      (user) => user.userId === selectedUserId
    );
    if (selectedUserData) {
      // setUserName(selectedUserData.userId);
      setUserId(selectedUserData.userId);
      setUserType(selectedUserData.userType);
    }
  };

  const handlePermissionChange = (iconId, permissionId) => {
    setSelectedPermissions((prev) => ({
      ...prev,
      [iconId]: {
        ...prev[iconId],
        [permissionId]: !prev[iconId]?.[permissionId] || false,
      },
    }));
  };

  const toggleIcon = (iconId) => {
    setExpandedIcon((prev) =>
      prev.includes(iconId)
        ? prev.filter((id) => id !== iconId)
        : [...prev, iconId]
    );
  };

  const toggleAllMenus = () => {
    setAllExpanded(!allExpanded);
  };

  const handleSearchChangeUserID = (e) => {
    setSearchTerm(e.target.value);

  }

  const toggleModalForModule = () => {
    setIsModalOpenForModule(!isModalOpenForModule);
  };
  const handleSearchChangeState = (e) => {
    setSearchQueryState(e.target.value);
  };

  const handleSearchChangeSubmodule = (e) => {
    setSearchQuerySubmodule(e.target.value);
  };

  const handleSearchChangeIconmodule = (e) => {
    setSearchQueryIconmodule(e.target.value);
  };

  const toggleModalForSubModule = () => {
    setIsModalOpenForSubModule(!isModalOpenForSubModule);
  };

  const toggleModalForIconModule = () => {
    setIsModalOpenForIconModule(!isModalOpenForIconModule);
  };

  const toggleMainMenu = (mainMenuId) => {
    setExpandedMainMenu((prev) => (prev === mainMenuId ? null : mainMenuId));
  };

  const toggleSubMenu = (mainMenuId, subMenuId) => {
    setSelectedSubMenu(subMenuId);
    setExpandedSubMenu((prev) => ({
      ...prev,
      [mainMenuId]: prev[mainMenuId] === subMenuId ? null : subMenuId,
    }));
  };

  const handleIconChange = (iconId) => {
    setSelectedIcons((prev) => ({
      ...prev,
      [iconId]: !prev[iconId],
    }));
  };

  const handleRoleChange = (e) => {
    const newRole = e.target.value;
    setSelectedRole(newRole);
  };

  const validateFields = () => {
    const newErrors = {};
    if (!userId) newErrors.userId = " ";
    if (!selectedRole) newErrors.selectedRole = " ";

    setErrors(newErrors);

    // Show toast only if there are errors
    if (Object.keys(newErrors).length > 0) {
      toast("error", "Missing required fields.");
      return false;
    }

    return true;
  };

  function isPermissionDisabled(iconId, permissionId) {
    const disabledPermissionsByUserType = {
      1: {
        21: [1, 2, 4, 8, 11, 12, 13, 17, 18], // Permissions disabled for icon 21, userType 1
        22: [1, 2, 4, 8, 10, 11, 12, 13, 17, 18], // Permissions disabled for icon 22, userType 1
        23: [1, 2, 4, 8, 11, 12, 13, 17, 18],
        24: [1, 2, 4, 8, 11, 12, 13, 17, 18],
        25: [1, 2, 4, 8, 11, 12, 13, 17, 18],
        44: [17],
        45: [1, 2, 4, 13, 17],
        108: [1, 2, 4, 8, 10, 11, 12, 13, 17, 18],
        109: [1, 2, 4, 8, 10, 11, 12, 13, 17, 18],
        110: [17],
        111: [1, 2, 4, 13, 10, 17, 18],
        112: [1, 2, 4, 8, 10, 11, 12, 13, 17, 18],
        113: [1, 2, 4, 8, 10, 11, 12, 13, 17, 18],
        114: [1, 2, 4, 8, 10, 11, 12, 13, 17, 18],
        115: [1, 2, 4, 8, 10, 11, 12, 13, 17, 18]

      },
      3: {
        1: [1, 2, 4, 8, 11, 12, 13, 17, 18], // Permissions disabled for icon 1, userType 3
        2: [1, 2, 4, 8, 11, 12, 13, 17, 18],
        3: [1, 2, 4, 8, 11, 12, 13, 17, 18],
        4: [1, 2, 4, 8, 11, 12, 13, 17, 18],
        5: [1, 2, 4, 8, 11, 12, 13, 17, 18],
        6: [1, 2, 4, 8, 11, 12, 13, 17, 18],
        7: [1, 2, 4, 8, 9, 11, 12, 13, 17, 18],
        8: [1, 2, 4, 8, 9, 11, 12, 13, 17, 18],
        9: [1, 2, 4, 8, 9, 11, 12, 13, 17, 18],
        10: [1, 2, 4, 8, 9, 11, 12, 13, 17, 18],
        11: [1, 2, 4, 8, 9, 11, 12, 13, 17, 18],
        21: [1, 2, 4, 8, 11, 12, 13, 17, 18],
        22: [1, 2, 4, 8, 10, 11, 12, 13, 17, 18],
        23: [1, 2, 4, 8, 11, 12, 13, 17, 18],
        24: [1, 2, 4, 8, 11, 12, 13, 17, 18],
        25: [1, 2, 4, 8, 11, 12, 13, 17, 18],
        26: [1, 2, 4, 8, 9, 11, 12, 13, 17, 18],
        44: [17],
        45: [1, 2, 4, 13, 17],
        46: [1, 2, 4, 8, 9, 11, 12, 13, 17, 18],
        47: [17],
        48: [1, 2, 10, 17],
        49: [3, 17, 18],
        50: [3, 17, 18],
        108: [1, 2, 4, 8, 10, 11, 12, 13, 17, 18],
        109: [1, 2, 4, 8, 10, 11, 12, 13, 17, 18],
        110: [17],
        111: [1, 2, 4, 13, 10, 17, 18],
        112: [1, 2, 4, 8, 10, 11, 12, 13, 17, 18],
        113: [1, 2, 4, 8, 10, 11, 12, 13, 17, 18],
        114: [1, 2, 4, 8, 10, 11, 12, 13, 17, 18],
        115: [1, 2, 4, 8, 10, 11, 12, 13, 17, 18]
      },
      4: {
        1: [1, 2, 4, 8, 11, 12, 13, 17, 18], // Permissions disabled for icon 1, userType 3
        2: [1, 2, 4, 8, 11, 12, 13, 17, 18],
        3: [1, 2, 4, 8, 11, 12, 13, 17, 18],
        4: [1, 2, 4, 8, 11, 12, 13, 17, 18],
        5: [1, 2, 4, 8, 11, 12, 13, 17, 18],
        6: [1, 2, 4, 8, 11, 12, 13, 17, 18],
        7: [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18],
        8: [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18],
        9: [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18],
        10: [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18],
        11: [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18],
        21: [1, 2, 4, 8, 11, 12, 13, 17, 18],
        22: [1, 2, 4, 8, 10, 11, 12, 13, 17, 18],
        23: [1, 2, 4, 8, 11, 12, 13, 17, 18],
        24: [1, 2, 4, 8, 11, 12, 13, 17, 18],
        25: [1, 2, 4, 8, 11, 12, 13, 17, 18],
        26: [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18],
        44: [17],
        45: [1, 2, 4, 13, 17],
        46: [1, 2, 4, 8, 9, 11, 12, 13, 17, 18],
        47: [17],
        48: [1, 2, 17],
        49: [3, 17, 18],
        50: [3, 17, 18]
      },
      8: {
        1: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
        2: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
        3: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
        4: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
        5: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
        6: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
        7: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
        8: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
        9: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
        10: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
        11: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
        21: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
        22: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
        23: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
        24: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
        25: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
        26: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
        44: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
        45: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
        46: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
        47: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
        48: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
        49: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
        50: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
        109: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
        110: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
        111: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]
        // Similarly for other icons
      },
    };

    // Disable all permissions for icons 1 to 50 if userType is 8
    if (userType === 8 && iconId >= 1 && iconId <= 50) {
      return true;
    }

    // Check if there are any disabled permissions for the given userType and iconId
    if (disabledPermissionsByUserType[userType]?.[iconId]) {
      return disabledPermissionsByUserType[userType][iconId].includes(permissionId);
    }

    // Default to not disabled
    return false;
  }


  function isPermissionDisplayed(iconId, permissionId) {
    const hiddenPermissions = {
      1: [4, 5, 6, 7, 8, 9, 11, 12, 13, 15],
      2: [4, 5, 6, 7, 8, 9, 11, 12, 13, 15],
      3: [4, 5, 6, 7, 8, 9, 11, 12, 13, 15],
      4: [4, 5, 6, 7, 8, 9, 11, 12, 13, 15],
      5: [4, 5, 6, 7, 8, 9, 11, 12, 13, 15],
      6: [4, 5, 6, 7, 8, 9, 11, 12, 13, 15],
      7: [8, 12],
      8: [8, 12],
      9: [8, 12],
      10: [8, 12],
      11: [8, 12],
      21: [4, 5, 6, 7, 8, 9, 11, 12, 18],
      22: [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 18],
      23: [4, 5, 6, 7, 8, 9, 11, 12],
      24: [4, 5, 6, 7, 8, 9, 11, 12],
      25: [4, 5, 6, 7, 8, 9, 11, 12],
      26: [8, 12],
      44: [1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 18],
      45: [4, 5, 6, 7, 8, 9, 11, 12, 18],
      46: [2, 4, 5, 6, 7, 8, 11, 12],
      47: [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 18],
      48: [4, 5, 6, 7, 8, 9, 11, 12, 13, 18],
      49: [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
      50: [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
      109: [8, 9, 12],
      110: [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15, 16, 18],
      111: [8, 9, 12],
      112: [8, 9, 12],
      113: [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 18],
      114: [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 18],
      115: [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 18],
    };

    return !hiddenPermissions[iconId]?.includes(permissionId);
  }

  function toggleAllPermissions(iconId) {
    if (userType === 8 && iconId >= 1 && iconId <= 50) {
      // Prevent toggling if "ALL" is disabled for userType 8
      return;
    }

    // Filter permissions: enabled and displayed only
    const displayedEnabledPermissions = permissionIcons.filter(
      (permission) =>
        !isPermissionDisabled(iconId, permission.id) &&
        isPermissionDisplayed(iconId, permission.id) // Custom function for visibility
    );

    const areAllSelected = displayedEnabledPermissions.every(
      (permission) => selectedPermissions[iconId]?.[permission.id]
    );

    // Toggle all enabled and displayed permissions
    const newSelectedPermissions = { ...selectedPermissions };
    displayedEnabledPermissions.forEach((permission) => {
      if (!newSelectedPermissions[iconId]) newSelectedPermissions[iconId] = {};
      newSelectedPermissions[iconId][permission.id] = !areAllSelected;
    });

    setSelectedPermissions(newSelectedPermissions);

    // Update "ALL" checkbox state
    setCheckboxState((prevState) => ({
      ...prevState,
      [iconId]: !areAllSelected,
    }));
  }

  const handleSubmit = async () => {
    if (!validateFields()) return;
    if (UserId) {
      console.log("its comes on user id console");
      try {
        // const permissionsData = [];
        // Object.keys(selectedIcons).forEach((iconId) => {
        //   if (selectedIcons[iconId]) {
        //     Object.keys(selectedPermissions[iconId] || {}).forEach(
        //       (permissionId) => {
        //         if (selectedPermissions[iconId][permissionId]) {
        //           permissionsData.push({
        //             role_id: selectedRole,
        //             icon_id: parseInt(iconId),
        //             permission_id: parseInt(permissionId),
        //             user_id: UserId, // Use the new roleId from the role submission
        //             updatedby: assignee.assignerId,
        //             clientId: assignee.clientId,
        //           });
        //         }
        //       }
        //     );
        //   }
        // });
        // console.log("update permission array", permissionsData);

        const data = {
          selectedRole, UserId, clientId: assignee.clientId, createdBy: assignee.assignerId,
        };
        console.log("update permission request data", data);

        const permissionsResponse = await UserService.saveAllRolesDetails(
          `${process.env.REACT_APP_BASE_URL}/update-user-permissions`,
          data
        );
        console.log("console permissions Response", permissionsResponse);
        if (permissionsResponse.status === 200) {
          // alert('Permissions and role saved successfully');
          toast("success", "User Permissions Updated Successfully");

          navigate("/14/admin/112/roles-and-permission");
        } else {
          // alert('Failed to save permissions');
          toast("error", "Failed to save permissions");
        }
      } catch (error) {
        console.error("Error submitting permissions and role details:", error);
        // alert('Error submitting permissions and role details');
        toast("error", "Error submitting permissions and role details");
      }
    } else {
      try {
        // First, submit the role details
        console.log("selected role also needed", selectedRole);
        console.log("selected user Id also needed", userId);
        console.log("selected user Type also needed", userType);

        // Prepare the selected permissions to include the new roleId

        // const permissionsData = [];
        // Object.keys(selectedIcons).forEach((iconId) => {
        //   if (selectedIcons[iconId]) {
        //     Object.keys(selectedPermissions[iconId] || {}).forEach(
        //       (permissionId) => {
        //         if (selectedPermissions[iconId][permissionId]) {
        //           permissionsData.push({
        //             role_id: selectedRole,
        //             icon_id: parseInt(iconId),
        //             permission_id: parseInt(permissionId),
        //             user_id: userId, // Use the new roleId from the role submission
        //             createdBy: assignee.assignerId,
        //             clientId: assignee.clientId,
        //           });
        //         }
        //       }
        //     );
        //   }
        // });
        const data = {
          selectedRole, userId, clientId: assignee.clientId, createdBy: assignee.assignerId,
        };

        console.log("now stored in permissions data", data);

        const permissionsResponse = await UserService.saveAllRolesDetails(
          `${process.env.REACT_APP_BASE_URL}/insert-user-permissions`,
          data
        );

        if (permissionsResponse.status === 200) {
          // alert('Permissions and role saved successfully');
          toast("success", "User Permissions Saved Successfully");

          navigate("/14/admin/112/roles-and-permission");
        } else {
          // alert('Failed to save permissions');
          toast("error", "Failed to save permissions");
        }
      } catch (error) {
        console.error("Error submitting permissions and role details:", error);
        // alert('Error submitting permissions and role details');
        toast("error", "Error submitting permissions and role details");
      }
    }
  };

  const handleSaveContinueDetails = async () => {
    if (!validateFields()) return;
    if (UserId) {
      console.log("its comes on user id console");
      try {
        // const permissionsData = [];
        // Object.keys(selectedIcons).forEach((iconId) => {
        //   if (selectedIcons[iconId]) {
        //     Object.keys(selectedPermissions[iconId] || {}).forEach(
        //       (permissionId) => {
        //         if (selectedPermissions[iconId][permissionId]) {
        //           permissionsData.push({
        //             role_id: selectedRole,
        //             icon_id: parseInt(iconId),
        //             permission_id: parseInt(permissionId),
        //             user_id: UserId, // Use the new roleId from the role submission
        //             updatedby: assignee.assignerId,
        //             clientId: assignee.clientId,
        //           });
        //         }
        //       }
        //     );
        //   }
        // });
        // console.log("update permission array", permissionsData);

        const data = {
          selectedRole, UserId, clientId: assignee.clientId, createdBy: assignee.assignerId,
        };

        const permissionsResponse = await UserService.saveAllRolesDetails(
          `${process.env.REACT_APP_BASE_URL}/update-user-permissions`,
          data
        );
        console.log("console permissions Response", permissionsResponse);
        if (permissionsResponse.status === 200) {
          // alert('Permissions and role saved successfully');
          toast("success", "User Permissions Updated Successfully");

          // navigate("/14/admin/112/roles-and-permission");
        } else {
          // alert('Failed to save permissions');
          toast("error", "Failed to save permissions");
        }
      } catch (error) {
        console.error("Error submitting permissions and role details:", error);
        // alert('Error submitting permissions and role details');
        toast("error", "Error submitting permissions and role details");
      }
    } else {
      try {
        // First, submit the role details
        console.log("selected role also needed", selectedRole);
        console.log("selected user Id also needed", userId);
        console.log("selected user Type also needed", userType);

        // Prepare the selected permissions to include the new roleId

        // const permissionsData = [];
        // Object.keys(selectedIcons).forEach((iconId) => {
        //   if (selectedIcons[iconId]) {
        //     Object.keys(selectedPermissions[iconId] || {}).forEach(
        //       (permissionId) => {
        //         if (selectedPermissions[iconId][permissionId]) {
        //           permissionsData.push({
        //             role_id: selectedRole,
        //             icon_id: parseInt(iconId),
        //             permission_id: parseInt(permissionId),
        //             user_id: userId, // Use the new roleId from the role submission
        //             createdBy: assignee.assignerId,
        //             clientId: assignee.clientId,
        //           });
        //         }
        //       }
        //     );
        //   }
        // });

        //   const selectedPermissions = Object.keys(permissions)
        //       .filter(permission => permissions[permission])
        //       .map(permission => ({
        //           permission_id: permissionValues[permission],
        //           icon_id: 108, // Manually setting icon_id to 1
        //           role_id: selectedRole,
        //         user_id: userId, // Use the new roleId from the role submission
        //       }));

        const data = {
          selectedRole, userId, clientId: assignee.clientId, createdBy: assignee.assignerId,
        };
        console.log("now stored in permissions data", data);

        const permissionsResponse = await UserService.saveAllRolesDetails(
          `${process.env.REACT_APP_BASE_URL}/insert-user-permissions`,
          data
        );

        if (permissionsResponse.status === 200) {
          // alert('Permissions and role saved successfully');
          toast("success", "User Permissions Saved Successfully");
          setSelectedUserTypeName("");
          setSelectedUserTypeId("");
          setUserId("");
          setUserType("");
          setSelectedUserId("");
          setSearchTerm("");
          setSelectedRole("");
          setAllExpanded(false);
          setSearchQueryState("");
          await fetchUsers();

          setErrors({});

          navigate("/14/admin/112/roles-and-permission/add");
        } else {
          // alert('Failed to save permissions');
          toast("error", "Failed to save permissions");
        }
      } catch (error) {
        console.error("Error submitting permissions and role details:", error);
        // alert('Error submitting permissions and role details');
        toast("error", "Error submitting permissions and role details");
      }
    }
  };


  const iconMapping = {
      "Add": <IoAddCircleOutline size={18} />,  // Adjust size as needed
      "Edit": <IoMdCreate size={18} />,
      "View": <svg className="ml-2 text-customGray" xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.3em" viewBox="0 0 24 24"><path fill="currentColor" d="M6 23H3q-.825 0-1.412-.587T1 21v-3h2v3h3zm12 0v-2h3v-3h2v3q0 .825-.587 1.413T21 23zm-6-4.5q-3 0-5.437-1.775T3 12q1.125-2.95 3.563-4.725T12 5.5t5.438 1.775T21 12q-1.125 2.95-3.562 4.725T12 18.5m0-3q1.45 0 2.475-1.025T15.5 12t-1.025-2.475T12 8.5T9.525 9.525T8.5 12t1.025 2.475T12 15.5m0-2q-.625 0-1.062-.437T10.5 12t.438-1.062T12 10.5t1.063.438T13.5 12t-.437 1.063T12 13.5M1 6V3q0-.825.588-1.412T3 1h3v2H3v3zm20 0V3h-3V1h3q.825 0 1.413.588T23 3v3z"></path></svg>,
      "Copy": <PiCopy size={18} />,
      "Audit Trail": <MdOutlineHistory size={18} />,
      "Approve": <IoMdCheckmark size={18} />,
      "Reject": <IoMdClose size={18} />,
      "Trigger OTP": <LuKeyRound size={18} />,
      "Upload": <MdOutlineFileUpload size={18} />,
      "Download": <MdOutlineFileDownload size={18} />,
      "Inactive": <VscVmActive size={18} />,
      "Lock": <MdOutlineLock size={18} />,
      "Delete": <IoMdTrash size={18} />,
      "Filter": <GiSettingsKnobs size={18} />,
      "Settings": <IoSettingsOutline size={18} />,
      "Refresh": <IoMdRefresh size={18} />,
      "Save": <BiSave size={18} />,
      "Save & Continue": <MdOutlineSaveAs size={18} />,
      "Bank Account Number Update": <FaRegEdit size={18} />,
      "Status Change": <BsTags size={18} />,
      "Company Change": <FiBox size={18} />,
      "Special Copy": <LuCopyCheck size={18} />,
      "Submit": <BiSave size={18} />,
      "Inconsistence User Types": <PiNotEquals size={18} />,
      "Assign Role": <BsUbuntu size={18} />,
      // Add other icons with size adjustments
    };

  useEffect(() => {
    const filtereddata = Icons.filter((mainMenuItem) => {
      const matchesMainMenu = mainMenu ? mainMenuItem.name === mainMenu : true;

      if (matchesMainMenu) {
        const filteredSubMenus = mainMenuItem.subMenus
          .filter((subMenuItem) => {
            const matchesSubMenu = subMenu
              ? subMenuItem.name === subMenu
              : true;

            if (matchesSubMenu) {
              const matchingIcons = subMenuItem.icons.filter((icon) =>
                icon.name.toLowerCase().includes(searchQueryState.toLowerCase())
              );

              return (
                matchingIcons.length > 0 ||
                subMenuItem.name
                  .toLowerCase()
                  .includes(searchQueryState.toLowerCase())
              );
            }
            return false;
          })
          .map((subMenuItem) => ({
            ...subMenuItem,
            icons: subMenuItem.icons.filter((icon) =>
              icon.name.toLowerCase().includes(searchQueryState.toLowerCase())
            ),
          }));

        if (filteredSubMenus.length > 0) {
          return {
            ...mainMenuItem,
            subMenus: filteredSubMenus,
          };
        }
      }

      return null;
    }).filter(Boolean);

    // Update the state only if the filtered data changes
    setFilteredIcons(filtereddata);
  }, [Icons, mainMenu, subMenu, searchQueryState]);

  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === "User & SOD") {
      navigate("/14/admin");
    } else if (crumb.title === "Admin") {
      navigate("/14/admin");
    } else if (crumb.title === "Permissions") {
      navigate("/14/admin/112/roles-and-permission");
    }
    // else if (crumb.path) {
    //   navigate(crumb.path); // Navigate to the specified path in the breadcrumb
    // }
  };

  const getApprovedIcon = () => {
    let color = "black"; // Default color
    // Determine the color based on the approved status
    if (status == 4) {
      color = "#29CB00"; // Green
    } else if (status == 0) {
      color = "gray"; // Gray
    } else if (status == 1) {
      color = "#AF52DE"; //Purple
    } else if (status === 2) {
      color = "yellow"; // Yellow
    } else if (status == 3) {
      color = "orange"; // Orange
    } else if (status == 5) {
      color = "red"; // Red
    }

    return <TbFlag3Filled style={{ color, marginLeft: '8px' }} />; // Return the icon with the determined color
  };


  return (
    <div>
      <Header
        title={`Admin > User & SOD > Permissions > ${UserId ? (isViewMode ? "View" : "Edit") : "Add"
          } > ${UserId ? UserId : ''}
        `}
        onBreadcrumbClick={handleBreadcrumbClick}
        extraContent={isViewMode || isEditMode ? getApprovedIcon() : ''}
      />
      <div className="px-5 py-5 mb-5">
        <div className="max-w-8xl">
          <div className="py-1 px-3 grid grid-cols-[1fr_1fr_1fr_auto] gap-x-4 items-center shadow-sm mb-6">

            <LabelWithButton
              label="User ID"
              labelwidth='w-32'
              isDisabled={isViewMode || isEditMode}
              isRequired={true}
              onClick={() => { setIsUserModalOpen(true); }
              }
              buttonText={userId || 'Select User ID'}
              marginLeft="ml-2"   // Set custom margin-left
              width='w-full'
            />
            <ReusableModal
              title="Select User ID"
              isOpen={isUserModalOpen}
              onClose={() => {
                setIsUserModalOpen(false);
                setSearchTerm('');
              }}
              onSave={() => {
                setIsUserModalOpen(false);
                setSearchTerm('');
              }}
              onReset={() => {
                setSelectedUserId("");
                setUserId("");
                setSelectedUserTypeName("");
                setSelectedUserTypeId("");
                setSelectedRole("");
                setRoles([]);
                setUserType("");

              }}
              showReset={true} // Only show Reset button in this modal
              searchPlaceholder="Search User ID..."
              searchQuery={searchTerm}
              onSearchChange={handleSearchChangeUserID}
              isViewMode={isViewMode}
            >
              {users.length > 0 ? (
                // <div className="grid grid-cols-3 gap-4">
                //   {
                users
                  .filter(
                    (user) =>
                      user.userId
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    // || user.username.toLowerCase().includes(searchTerm.toLowerCase())
                  )
                  .map((user, index) => (
                    <div
                      key={user.userId}
                      className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""
                        }`}
                    >
                      <input
                        type="radio"
                        name="user Id"
                        value={user.userId}
                        checked={selectedUserId === user.userId} // Check if the user is selected
                        onChange={handleCheckboxChangeForUser}
                        className="mr-2 text-blue-200"
                      />
                      <label>{user.userId}</label>
                    </div>
                  ))
                //     }
                // </div>
              ) : (
                <p>No Users Found</p>
              )}

            </ReusableModal>

            <label className="flex items-center">
              <span className="w-32 text-sm font-medium text-customGray">
                User Type
              </span>
              <span className="ml-2">:</span>
              <input
                type="text"
                value={selectedUserTypeName}
                className="border border-gray-300 p-1.5 rounded w-full h-8 text-customGray focus:outline-none ml-4"
                disabled
              />
            </label>

            <label className="flex items-center border-r-2 pr-4">
              <span className="w-32 text-sm font-medium text-customGray">
                Roles
              </span>
              <span className="ml-2">:</span>
              {/* <input
                type="text"
                value={selectedUserTypeName}
                className="border border-gray-300 p-1.5 rounded w-72 h-8 text-customGray focus:outline-none ml-4"
                disabled
              /> */}
              <select
                value={selectedRole}
                disabled={isViewMode}
                onChange={handleRoleChange}
                className={`border border-gray-300 p-1 rounded w-full h-8 ${isViewMode ? "text-gray-500 bg-gray-100" : "text-customGray"
                  } focus:outline-none hover:border-blue-400 ml-4 ${errors.selectedRole
                    ? "border-red-500 hover:border-red-500"
                    : "border-gray-300 hover:border-blue-400"
                  }`}
              >
                <option value="">Select Role</option>
                {roles.map((type) => (
                  <option key={type.roleId} value={type.roleId}>
                    {type.roleId}
                  </option>
                ))}
              </select>

              {errors.selectedRole && (
                <p className="text-red-500 text-sm ml-4">
                  {errors.selectedRole}
                </p>
              )}
            </label>

            <div className="flex justify-end">
              <button
                className="flex items-center justify-center bg-white text-sm border border-gray-300 text-customGray py-1.5 px-4 rounded hover:bg-gray-100 transition duration-200"
                onClick={toggleAllMenus} // Add this onClick to trigger the toggle
              >
                {allExpanded ? (
                  <IoChevronForward className="w-5 h-5 text-gray-500" />
                ) : (
                  <IoChevronDown className="w-5 h-5 text-gray-500" />
                )}
              </button>
            </div>
          </div>

          <div className="border border-gray-200 rounded-lg ">
            <TableWrapper maxHeight="70vh">

              {/* Permission Data started */}
              <div className="bg-white rounded-lg shadow-sm">
                {filteredIcons.map((mainMenu) => (
                  <div key={mainMenu.id} className="mb-4">
                    {/* Main Menu Level */}
                    <div
                      className="bg-blue-50 px-2 py-1.5 flex items-center justify-start cursor-pointer rounded space-x-2"
                      onClick={() => toggleMainMenu(mainMenu.id)}
                    >
                      {allExpanded || expandedMainMenu === mainMenu.id ? (
                        <IoChevronDown className="w-5 h-5 text-gray-500" />
                      ) : (
                        <IoChevronForward className="w-5 h-5 text-gray-500" />
                      )}
                      <span className="text-md font-medium text-customGray">{mainMenu.name}</span>
                    </div>

                    {/* Submenus */}
                    {(allExpanded || expandedMainMenu === mainMenu.id || searchTerm) &&
                      mainMenu.subMenus.map((subMenu) => (
                        <div key={subMenu.id} className="ml-4 mt-2">
                          {/* Submenu Level */}
                          <div
                            className="bg-blue-50 px-2 py-1.5 flex items-center justify-start cursor-pointer rounded space-x-2"
                            onClick={() => toggleSubMenu(mainMenu.id, subMenu.id)} // Handle submenu toggle
                          >
                            {allExpanded || expandedSubMenu[mainMenu.id] === subMenu.id ? (
                              <IoChevronDown className="w-5 h-5 text-gray-500" />
                            ) : (
                              <IoChevronForward className="w-5 h-5 text-gray-500" />
                            )}
                            <span className="text-md font-medium text-customGray">{subMenu.name}</span>
                          </div>

                          {/* Icons and expanded submenu content */}
                          {(allExpanded || expandedSubMenu[mainMenu.id] === subMenu.id || searchTerm) && (
                            <div className="ml-8 mt-2">
                              {/* Icons Level */}
                              {subMenu.icons.map((icon) => (
                                <div key={icon.id} className="flex flex-col mt-2">
                                  <div
                                    className="bg-blue-50 px-2 flex items-center justify-start cursor-pointer rounded space-x-2"
                                    onClick={() => toggleIcon(icon.id)} // Toggle icon
                                  >
                                    {allExpanded || expandedIcon.includes(icon.id) ? (
                                      <IoChevronDown className="w-5 h-5 text-gray-500" />
                                    ) : (
                                      <IoChevronForward className="w-5 h-5 text-gray-500" />
                                    )}
                                    <span className="text-md font-medium text-customGray">
                                      {icon.name}
                                    </span>
                                    {/* <input
                        type="checkbox"
                        className="h-4 w-4 text-blue-600 border-gray-300 rounded accent-Green"
                        checked={selectedIcons[icon.id] || false}
                        onClick={(e) => e.stopPropagation()}
                        disabled={isViewMode }
                        onChange={() => handleIconChange(icon.id)}
                      /> */}
                                    <Checkbox
                                      checked={selectedIcons[icon.id] || false}
                                      onClick={(e) => e.stopPropagation()}
                                      disabled
                                      onChange={() => handleIconChange(icon.id)}
                                      sx={{
                                        transform: 'scale(1.0)', // Reduces the size further
                                        color: 'customGray',
                                        '& .MuiSvgIcon-root': {
                                          backgroundColor: (isViewMode || !selectedIcons[icon.id] || (userType === 8 && icon.id >= 1 && icon.id <= 50))
                                            ? '#F2F4F9' // Disabled: gray background
                                            : 'white', // Enabled: white background
                                          borderRadius: '4px', // Round the corners slightly
                                          fontSize: 18, // Adjust the size of the checkbox
                                        },
                                        '&.Mui-checked': {
                                          color: (isViewMode || !selectedIcons[icon.id] || (userType === 8 && icon.id >= 1 && icon.id <= 50))
                                            ? 'rgba(41, 203, 0, 0.5)' // Dimmed green for disabled checked state
                                            : 'rgba(41, 203, 0, 0.5)', // Bright green when checked and enabled
                                        },
                                        '&.Mui-disabled .MuiSvgIcon-root': {
                                          backgroundColor: '#F2F4F9', // Explicitly set disabled bg color
                                        },
                                      }}
                                    />



                                    {/* "All" Checkbox */}
                                    <div className={`flex items-center space-x-2 px-2 ml-5 `} >

                                      <div className={`${isViewMode || !selectedIcons[icon.id] ? 'cursor-default' : 'cursor-pointer'} `}
                                        onClick={(e) => e.stopPropagation()} // Prevent accordion toggle
                                      >

                                        <span className="text-md font-medium text-customGray">
                                          ALL
                                        </span>
                                        <Checkbox
                                          checked={
                                            checkboxState[icon.id] !== undefined
                                              ? checkboxState[icon.id] // Use explicit checkbox state if available
                                              : Object.keys(selectedPermissions[icon.id] || {}).length ===
                                              permissionIcons.filter(
                                                (permission) => !isPermissionDisabled(icon.id, permission.id) &&
                                                  isPermissionDisplayed(icon.id, permission.id) // Filter only visible permissions
                                              ).length
                                          }
                                          onChange={() => {
                                            toggleAllPermissions(icon.id);
                                          }}
                                          disabled
                                          sx={{
                                            transform: 'scale(1.0)', // Reduces the size further
                                            color: 'customGray',
                                            '& .MuiSvgIcon-root': {
                                              backgroundColor: (isViewMode || !selectedIcons[icon.id] || (userType === 8 && icon.id >= 1 && icon.id <= 50))
                                                ? '#F2F4F9' // Disabled: gray background
                                                : 'white', // Enabled: white background
                                              borderRadius: '4px', // Round the corners slightly
                                              fontSize: 18, // Adjust the size of the checkbox
                                            },
                                            '&.Mui-checked': {
                                              color: (isViewMode || !selectedIcons[icon.id] || (userType === 8 && icon.id >= 1 && icon.id <= 50))
                                                ? 'rgba(41, 203, 0, 0.5)' // Dimmed green for disabled checked state
                                                : 'rgba(41, 203, 0, 0.5)', // Bright green when checked and enabled
                                            },
                                            '&.Mui-disabled .MuiSvgIcon-root': {
                                              backgroundColor: '#F2F4F9', // Explicitly set disabled bg color
                                            },
                                          }}
                                        />

                                      </div>

                                    </div>

                                  </div>

                                  {/* Expanded content for icons */}
                                  {allExpanded || expandedIcon.includes(icon.id) ? (
                                    <div className="px-4 py-2 grid grid-cols-4 gap-4 items-center bg-white rounded">
                                      {permissionIcons.map((permission) => {
                                        const isDisabled =
                                          userType == 1 &&
                                          (
                                            (icon.id === 21 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                            (icon.id === 22 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                            (icon.id === 23 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                            (icon.id === 24 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                            (icon.id === 25 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||

                                            (icon.id === 44 && [17].includes(permission.id)) ||
                                            (icon.id === 45 && [1, 2, 4, 13, 17].includes(permission.id)) ||

                                            (icon.id === 108 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                            (icon.id === 109 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                            (icon.id === 110 && permission.id === 17) ||
                                            (icon.id === 111 && [1, 2, 4, 13, 10, 17, 18].includes(permission.id)) ||
                                            (icon.id === 112 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                            (icon.id === 113 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                            (icon.id === 114 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                            (icon.id === 115 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id))

                                          ) ||
                                          (userType == 3 &&
                                            (
                                              // Country
                                              (icon.id === 1 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                              // State provice
                                              (icon.id === 2 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                              // Calender
                                              (icon.id === 3 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                              // Currency
                                              (icon.id === 4 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                              // Bank
                                              (icon.id === 5 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                              // Language
                                              (icon.id === 6 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                              // Legal entity
                                              (icon.id === 7 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                              // Bu group
                                              (icon.id === 8 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                              // Company
                                              (icon.id === 9 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                              // Bank Id
                                              (icon.id === 10 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                              // Branch
                                              (icon.id === 11 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                              // Account status
                                              (icon.id === 21 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                              // Account no logic
                                              (icon.id === 22 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                              // ZBA type
                                              (icon.id === 23 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                              // DB area
                                              (icon.id === 24 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                              // Account types
                                              (icon.id === 25 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                              // Bank account master
                                              (icon.id === 26 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                              // Statement controls
                                              (icon.id === 44 && [17].includes(permission.id)) ||
                                              // Statement format
                                              (icon.id === 45 && [1, 2, 4, 13, 17].includes(permission.id)) ||
                                              // Statement identifier
                                              (icon.id === 46 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                              // Mt940 uploads
                                              (icon.id === 47 && [17].includes(permission.id)) ||
                                              // BTC
                                              (icon.id === 48 && [1, 2, 10, 17].includes(permission.id)) ||
                                              // Statement analyser
                                              (icon.id === 49 && [3, 17, 18].includes(permission.id)) ||
                                              // Statement analyser 2
                                              (icon.id === 50 && [3, 17, 18].includes(permission.id)) ||

                                              // User Icon
                                              (icon.id === 108 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                              // Key data Icon
                                              (icon.id === 109 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                              // Maker and checker Icons
                                              (icon.id === 110 && permission.id === 17) ||
                                              // Roles Icon
                                              (icon.id === 111 && [1, 2, 4, 13, 10, 17, 18].includes(permission.id)) ||
                                              // Permission Icon
                                              (icon.id === 112 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id)) ||

                                              (icon.id === 113 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                              (icon.id === 114 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                              (icon.id === 115 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id)))

                                          ) ||
                                          (userType == 4 &&
                                            (
                                              // Country
                                              (icon.id === 1 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                              // State provice
                                              (icon.id === 2 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                              // Calender
                                              (icon.id === 3 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                              // Currency
                                              (icon.id === 4 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                              // Bank
                                              (icon.id === 5 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                              // Language
                                              (icon.id === 6 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                              // Legal entity
                                              (icon.id === 7 && [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                              // Bu group
                                              (icon.id === 8 && [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                              // Company
                                              (icon.id === 9 && [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                              // Bank Id
                                              (icon.id === 10 && [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                              // Branch
                                              (icon.id === 11 && [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                              // Account status
                                              (icon.id === 21 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                              // Account no logic
                                              (icon.id === 22 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                              // ZBA type
                                              (icon.id === 23 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                              // DB area
                                              (icon.id === 24 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                              // Account types
                                              (icon.id === 25 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                              // Bank account master
                                              (icon.id === 26 && [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                              // Statement controls
                                              (icon.id === 44 && [17].includes(permission.id)) ||
                                              // Statement format
                                              (icon.id === 45 && [1, 2, 4, 13, 17].includes(permission.id)) ||
                                              // Statement identifier
                                              (icon.id === 46 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                              // Mt940 uploads
                                              (icon.id === 47 && [17].includes(permission.id)) ||
                                              // BTC
                                              (icon.id === 48 && [1, 2, 17].includes(permission.id)) ||
                                              // Statement analyser
                                              (icon.id === 49 && [3, 17, 18].includes(permission.id)) ||
                                              // Statement analyser 2
                                              (icon.id === 50 && [3, 17, 18].includes(permission.id))

                                            )
                                          ) ||
                                          (userType == 8 &&
                                            (
                                              // Country
                                              (icon.id === 1 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                              // State provice
                                              (icon.id === 2 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                              // Calender
                                              (icon.id === 3 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                              // Currency
                                              (icon.id === 4 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                              // Bank
                                              (icon.id === 5 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                              // Language
                                              (icon.id === 6 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                              // Legal entity
                                              (icon.id === 7 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                              // Bu group
                                              (icon.id === 8 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                              // Company
                                              (icon.id === 9 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                              // Bank Id
                                              (icon.id === 10 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                              // Branch
                                              (icon.id === 11 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                              // Account status
                                              (icon.id === 21 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                              // Account no logic
                                              (icon.id === 22 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                              // ZBA type
                                              (icon.id === 23 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                              // DB area
                                              (icon.id === 24 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                              // Account types
                                              (icon.id === 25 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                              // Bank account master
                                              (icon.id === 26 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                              // Statement controls
                                              (icon.id === 44 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                              // Statement format
                                              (icon.id === 45 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                              // Statement identifier
                                              (icon.id === 46 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                              // Mt940 uploads
                                              (icon.id === 47 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                              // BTC
                                              (icon.id === 48 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                              // Statement analyser
                                              (icon.id === 49 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                              // Statement analyser 2
                                              (icon.id === 50 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||

                                              // Key data Icon
                                              (icon.id === 109 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                              // Maker and checker Icons
                                              (icon.id === 110 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                              // Roles Icon
                                              (icon.id === 111 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id))


                                            )
                                          )
                                          ;

                                        const isChecked = !isDisabled && (selectedPermissions[icon.id]?.[permission.id] || false);

                                        const handleCheckboxChange = () => {
                                          if (!isDisabled) {
                                            handlePermissionChange(icon.id, permission.id, !isChecked);
                                          }
                                        };

                                        return (
                                          // Below the Action Icons are Not Needed, Permission table Id
                                          (icon.id === 1 && [4, 5, 6, 7, 8, 9, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                            (icon.id === 2 && [4, 5, 6, 7, 8, 9, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                            (icon.id === 3 && [4, 5, 6, 7, 8, 9, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                            (icon.id === 4 && [4, 5, 6, 7, 8, 9, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                            (icon.id === 5 && [4, 5, 6, 7, 8, 9, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                            (icon.id === 6 && [4, 5, 6, 7, 8, 9, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                            (icon.id === 7 && [8, 12, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                            (icon.id === 8 && [8, 12, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                            (icon.id === 9 && [8, 12, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                            (icon.id === 10 && [8, 12, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                            (icon.id === 11 && [8, 12, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                            (icon.id === 21 && [4, 5, 6, 7, 8, 9, 11, 12, 17, 18, 19, 20, 21, 22, 24, 25].includes(permission.id)) ||
                                            (icon.id === 22 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 24, 25].includes(permission.id)) ||
                                            (icon.id === 23 && [4, 5, 6, 7, 8, 9, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                            (icon.id === 24 && [4, 5, 6, 7, 8, 9, 11, 12, 13, 15, 16, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                            (icon.id === 25 && [4, 5, 6, 7, 8, 9, 11, 12, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                            (icon.id === 26 && [8, 12, 23, 24, 25].includes(permission.id)) ||

                                            (icon.id === 44 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 24, 25].includes(permission.id)) ||
                                            (icon.id === 45 && [4, 5, 6, 7, 8, 9, 11, 12, 17, 18, 19, 20, 21, 22, 24, 25].includes(permission.id)) ||
                                            (icon.id === 46 && [2, 4, 5, 6, 7, 8, 11, 12, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                            (icon.id === 47 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 24, 25].includes(permission.id)) ||
                                            (icon.id === 48 && [4, 5, 6, 7, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 24, 25].includes(permission.id)) ||
                                            (icon.id === 49 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                            (icon.id === 50 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||

                                            (icon.id === 108 && [19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                            (icon.id === 109 && [8, 9, 12, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                            (icon.id === 110 && [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 24, 25].includes(permission.id)) ||
                                            (icon.id === 111 && [8, 9, 12, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                            (icon.id === 112 && [2, 8, 9, 12, 19, 20, 21, 22, 23].includes(permission.id)) ||
                                            (icon.id === 113 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 24, 25].includes(permission.id)) ||
                                            (icon.id === 114 && [1, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 24, 25].includes(permission.id)) ||
                                            (icon.id === 115 && [1, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 24, 25].includes(permission.id))
                                            ? null : (
                                            <div key={`${icon.id}-${permission.id}`} className="flex items-center space-x-2">
                                              <Checkbox
                                                disabled
                                                checked={isChecked}
                                                onChange={handleCheckboxChange}
                                                sx={{
                                                  transform: 'scale(1.0)', // Reduces the size further
                                                  color: 'customGray', '& .MuiSvgIcon-root': {
                                                    backgroundColor: 'white', // Background color for the checkbox
                                                    borderRadius: '4px', // Optional: round the corners slightly
                                                    fontSize: 18, // Adjust the size of the checkbox
                                                    // border: '1px solid #ccc', // Custom border
                                                  }, '&.Mui-checked': {
                                                    color: (isViewMode || !selectedIcons[icon.id])
                                                      ? 'rgba(41, 203, 0, 0.5)' // Dimmed green for disabled checked state
                                                      : 'rgba(41, 203, 0, 0.5)', // Bright green when checked and enabled
                                                  },
                                                  '&.Mui-disabled .MuiSvgIcon-root': {
                                                    backgroundColor: '#F2F4F9', // Explicitly set disabled bg color
                                                  },
                                                }}
                                              />
                                              <span className="text-md text-customGray">{permission.name}</span>
                                              <span className="ml-2 text-customGray">{iconMapping[permission.name] || null}</span>
                                            </div>
                                          )
                                        );
                                      })}
                                    </div>
                                  ) : null}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                ))}
              </div>

            </TableWrapper>

          </div>
        </div>
      </div>


      <div>
        {/* Render other components */}

      </div>


      <Footer>
        {(!isViewMode && (

          <ButtonGroup
            onSave={handleSubmit}
            onSaveContinue={handleSaveContinueDetails} hasId={!!UserId} 
          />
        ))}

      </Footer>

    </div>
  )
}
