import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Header from '../../../../../../components/Header';
import ReTable from '../../../../../../components/Table';
import ColumnSettingsPopup from '../../../../Masterdata/Components/ColumnSettingPopup';
import AdminActions from '../../../Components/AdminActions';
import { AdminActionsConfig } from '../../../Components/ActionConfig';
import { MdOutlineFileDownload } from 'react-icons/md';
// import * as XLSX from "xlsx"; // Import the xlsx library
import XLSX from "xlsx-js-style";
import UserService from '../../../../../../rest-services/UserServices';

export default function UserUploadTableScreen() {
    const location = useLocation();
    const { iconId } = useParams();
    const navigate = useNavigate();
    const { tableData, displayOption, uploadOption  } = location.state || {}; // Retrieve tableData from state
      const [isSettingsPopupOpen, setIsSettingsPopupOpen] = useState(false);
      const [selectedRows, setSelectedRows] = useState([]); // Track selected rows by IDs
     const [unsavedChanges, setUnsavedChanges] = useState(true);
     const [assignee, setAssignee] = useState({ id: "", username: "" });
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [processedData, setProcessedData] = useState([]);

    console.log("table data is",tableData);
    
    const handleSelectRow = (id) => {
        handleCheckboxChange(id);
      };

      const handleDoubleClick = (id) => {
        console.log("check roles id", id);
      };
     

const [userPreference, setUserPreference] = useState("");


useEffect(() => {
  fetchAssignee();
}, []);

const fetchAssignee = async () => {
try {
  const userDetailString = localStorage.getItem("userDetail");

  if (userDetailString) {
    const userDetail = JSON.parse(userDetailString);
    const assigneeData = {
      id: userDetail.id || "",
      assigner: userDetail.username || "",
      clientId: userDetail.clientId || "",
      assignerId: userDetail.userId || "",
    };
    console.log("assigneeData is", assigneeData);

    setAssignee(assigneeData);

    // Log the id and assigner values to the console
    console.log("Assignee ID:", assigneeData.id);
    console.log("Assigner:", assigneeData.assigner);
    console.log("Assigner userId:", assigneeData.assignerId);
    console.log("Assigner clientId:", assigneeData.clientId);
  } else {
    setAlert({
      severity: "error",
      message: "No user detail found in session storage.",
    });
  }
} catch (error) {
  setAlert({
    severity: "error",
    message: `Error fetching user details: ${error.message}`,
  });
}
};

useEffect(() => {
  if (assignee.id) {
    fetchUserPreferenceDetails();
  }
}, [assignee.id]);

const fetchUserPreferenceDetails = async () => {
try {
  const data = { userId: assignee.assignerId }; // Assuming assignee.id is the user ID
  console.log("Requestfor user pref Data:", data);

  setLoading(true); // Set loading state
  const response = await UserService.saveContactDetails(
    `${process.env.REACT_APP_BASE_URL}/get-edit-user-preference-details-by-Id`,
    data
  );
  console.log("user prefference details", response.data);
  setUserPreference(response.data); // Set user preference data
} catch (error) {
  setError(error.message); // Set error message
} finally {
  setLoading(false); // Reset loading state
}
};

const formatDate = (dateString, format) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const shortYear = String(year).slice(-2);

  const formatMap = {
      "DD/MM/YYYY": `${day}/${month}/${year}`,
      "MM/DD/YYYY": `${month}/${day}/${year}`,
      "DD/MM/YY": `${day}/${month}/${shortYear}`,
      "MM/DD/YY": `${month}/${day}/${shortYear}`,
      "DD.MM.YYYY": `${day}.${month}.${year}`,
      "DDMMYY": `${day}${month}${shortYear}`,
      "DD-MM-YYYY": `${day}-${month}-${year}`,
  };

  return formatMap[format] || dateString;
};
const dateFormat = userPreference?.dateFormat || "DD-MM-YYYY"; // Default format


    const handleCheckboxChange = (id) => {
        console.log('checked user id', id);
        const updatedSelectedRows = selectedRows.includes(id)
          ? selectedRows.filter((rowId) => rowId !== id) // Deselect if already selected
          : [...selectedRows, id]; // Add to selection if not selected
          
          setSelectedRows(updatedSelectedRows);
          console.log('check box:', updatedSelectedRows);
    
      };
 
     

      const handleDownload = () => {
        if (processedData.length === 0) {
          setAlert({
            severity: "warning",
            message: "No data available to export!",
          });
          return;
        }
      
        const extractText = (cellValue) => {
          if (Array.isArray(cellValue)) {
            return cellValue.join(", "); // Convert array to string
          } else if (typeof cellValue === "string") {
            return cellValue;
          } else if (React.isValidElement(cellValue)) {
            return cellValue.props.children;
          }
          return cellValue !== null && cellValue !== undefined ? cellValue.toString() : "";
        };
      
        const formattedUsers = processedData.map((user) => ({
          "SL No": extractText(user.SLNo),
          "User ID": extractText(user.userId),
          "Employee No": extractText(user.employeeNo),
          "Salute": extractText(user.salute),
          "AccountingSystemId": extractText(user.AccountingSystemId),
          "Username": extractText(user.username),
          "First Name": extractText(user.firstName),
          "Middle Name": extractText(user.middleName),
          "Last Name": extractText(user.lastName),
          "Language": extractText(user.language || ""),
          "Time Format": extractText(user.timeFormat || ""),
          "Time Zone": extractText(user.timeZone || ""),
          "Company Code": extractText(user.companyCodeId),
          "Department": extractText(user.department),
          "Valid From": extractText(user.validFrom || ''),
          "Valid To": extractText(user.validTo || ''),
          "Email ID": extractText(user.email),
          "Mobile No": extractText(user.mobileNo),
          "whatsApp No": extractText(user.whatsAppNo),
          "Nationality": extractText(user.nationality),
          "passportNo": extractText(user.passportNo),
          "Driving License No": extractText(user.drivingLicenseNo),
          "Local ID": extractText(user.localId),
          "officeNo": extractText(user.officeNo),
          "extension": extractText(user.extension),
          "Joining Date": extractText(user.joiningDate),
          "Resign Date": extractText(user.resignationDate),
          "Door No": extractText(user.DoorNo),
          "Floor No": extractText(user.FloorNo),
          "Building No": extractText(user.BuildingNo),
          "Building Name": extractText(user.BuildingName),
          "LandMark": extractText(user.LandMark),
          "Street No": extractText(user.StreetNo),
          "Street Name": extractText(user.StreetName),
          "Road No": extractText(user.RoadNo),
          "Road Name": extractText(user.RoadName),
          "Block": extractText(user.Block),
          "cityName": extractText(user.cityName),
          "areaName": extractText(user.areaName),
          "Address 1": extractText(user.addressLine1 || ""),
          "Address 2": extractText(user.addressLine2 || ""),
          "Address 3": extractText(user.addressLine3 || ""),
          "Country": extractText(user.countryId || ""),
          "State/Province": extractText(user.StateProvince),
          "District": extractText(user.District),
          "PostalCode": extractText(user.PostalCode),
          "ZipCode": extractText(user.ZipCode),
          "POBox": extractText(user.POBox),
          "Date Format": extractText(user.DateFormat || ""),
          "Amount Format": extractText(user.amountFormat || ""),
          "thousandSeparator": extractText(user.thousandSeparator),
          "decimalSeparator": extractText(user.decimalSeparator),
          "acceleratorForThousands": extractText(user.acceleratorForThousands),
          "acceleratorForLakhs": extractText(user.acceleratorForLakhs),
          "acceleratorForMillions": extractText(user.acceleratorForMillions),
        }));
      

  // Add second and third row
  const secondRow = {
    "SL No": "R", "User ID": "R","Username": "R",  "Salute": "", "First Name": "R", "Middle Name": "", "Last Name": "R",  "AccountingSystemId": "", "Email ID": "R",
    "Mobile No": "R", "whatsApp No": "",  "officeNo": "", "extension": "", "passportNo": "", "Driving License No": "", "Local ID": "","Nationality": "R", "Employee No": "R",
    "Company Code": "","Department": "", "Valid From": "R", "Valid To": "R",  "Joining Date": "", "Resign Date": "",
    "Door No": "", "Floor No": "", "Building No": "", "Building Name": "", "LandMark": "", "Street No": "", 
    "Street Name": "", "Road No": "", "Road Name": "", "Block": "", "cityName": "", "areaName": "", "Address 1": "", "Address 2": "", "Address 3": "", 
    "Country": "R", "State/Province": "Conditional", "District": "", "PostalCode": "", "ZipCode": "", "POBox": "", "Language": "", "Time Format": "", "Time Zone": "",   "Date Format": "", "Amount Format": "", 
    "thousandSeparator": "", "decimalSeparator": "", "acceleratorForThousands": "", "acceleratorForLakhs":"","acceleratorForMillions": ""
  };

  const thirdRow = {
    "SL No": "5", "User ID": "12","Username": "12","Salute": "",  "First Name": "20", "Middle Name": "20", "Last Name": "20","AccountingSystemId": "12",  "Email ID": "80",
    "Mobile No": "13", "whatsApp No": "13", "officeNo": "13", "extension": "10","passportNo": "10", "Driving License No": "20", "Local ID": "20", "Nationality": "20",  "Employee No": "12", 
     "Company Code": "10", "Department": "10", "Valid From": "10", "Valid To": "10", "Joining Date": "10", "Resign Date": "19", 
     "Door No": "", "Floor No": "", "Building No": "", "Building Name": "", "LandMark": "", "Street No": "", 
    "Street Name": "", "Road No": "", "Road Name": "", "Block": "", "cityName": "", "areaName": "", "Address 1": "50", "Address 2": "50", "Address 3": "50", 
    "Country": "2", "State/Province": "3", "District": "", "PostalCode": "", "ZipCode": "", "Language": "2", "Time Format": "8", "Time Zone": "","POBox": "",  "Date Format": "", "Amount Format": "19", 
   "thousandSeparator": "1", "decimalSeparator": "1", "acceleratorForThousands": "1", "acceleratorForLakhs":"1","acceleratorForMillions": "1"
  };

    // Insert second and third row at the beginning of the formatted data
    const rowsToDownload = [secondRow, thirdRow, ...formattedUsers];

        const worksheet = XLSX.utils.json_to_sheet(rowsToDownload);
      console.log("rowsToDownload data is",rowsToDownload);

        // Define border style
  const border = {
    top: { style: "thin", color: { rgb: "000000" } },
    right: { style: "thin", color: { rgb: "000000" } },
    bottom: { style: "thin", color: { rgb: "000000" } },
    left: { style: "thin", color: { rgb: "000000" } },
  };

      // Apply borders to the first three rows
      rowsToDownload.slice(0, 3).forEach((row, rowIdx) => {
        Object.keys(row).forEach((col, colIdx) => {
          const cellAddress = XLSX.utils.encode_cell({ r: rowIdx, c: colIdx });
          if (worksheet[cellAddress]) {
            worksheet[cellAddress].s = { border };
          }
        });
      });

      
       // Additional cell styling for validation errors
        Object.keys(rowsToDownload[0]).forEach((col, colIdx) => {
          rowsToDownload.forEach((row, rowIdx) => {
            const cellAddress = XLSX.utils.encode_cell({ r: rowIdx + 1, c: colIdx });
            const cellValue = row[col];
      
            if (
              typeof cellValue === "string" && (
                cellValue.includes("Shouldn't be blank") 
                // ||
                // cellValue.includes("Mismatched Data Type") ||
                // cellValue.includes("Max. allowed character")
              )
            ) {
              worksheet[cellAddress] = {
                v: cellValue,
                s: { font: { color: { rgb: "FF0000" } } },
              };
            } else if (Array.isArray(row[col])) {
              worksheet[cellAddress] = {
                v: extractText(row[col]),
                s: { font: { color: { rgb: "FF0000" } } },  // Apply red color for array values
              };
            }
            else {
              worksheet[cellAddress] = { v: cellValue };
            }
          });
        });
      
        // AutoFit column width
        worksheet["!cols"] = Object.keys(rowsToDownload[0]).map((col) => {
          const maxLength = Math.max(...rowsToDownload.map((row) => extractText(row[col]).length), col.length);
          return { wch: maxLength + 2 }; // Add extra space
        });


        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Users");
        XLSX.writeFile(workbook, "Users_Uploading_Data.xlsx");
      };


  
  
      
    const columns = [   
        
        { Header: 'SL No', accessor: 'SLNo', visible: true, width: 200, alignment: "left" },
        { Header: 'User ID', accessor: 'userId', visible: true, width: 200, alignment: "left" },
        { Header: 'Employee No', accessor: 'employeeNo', visible: true, width: 200, alignment: "left" },
        { Header: 'Salute', accessor: 'salute', visible: true, width: 200, alignment: "left" },
        { Header: 'AccountingSystemId', accessor: 'AccountingSystemId', visible: true, width: 200, alignment: "left" },
        { Header: 'Username', accessor: 'username', visible: true, width: 200, alignment: "left" },
        { Header: 'First Name', accessor: 'firstName', visible: true, width: 200, alignment: "left" },
        { Header: 'Middle Name', accessor: 'middleName', visible: true, width: 200, alignment: "left" },
        { Header: 'last Name', accessor: 'lastName', visible: true, width: 200, alignment: "left" },
        { Header: 'language', accessor: 'language', visible: true, width: 200, alignment: "left" },
        { Header: 'Time Format', accessor: 'timeFormat', visible: true, width: 200, alignment: "left" },
        { Header: 'Time Zone', accessor: 'timeZone', visible: true, width: 200, alignment: "left" },
        { Header: 'companyCodeId', accessor: 'companyCodeId', visible: true, width: 200, alignment: "left" },
        { Header: 'department', accessor: 'department', visible: true, width: 200, alignment: "left" },
        { 
          Header: 'Valid From', 
          accessor: 'validFrom', 
          visible: true, 
          width: 200, 
          alignment: "left", 
          Cell: ({ value }) => 
            value ? (
              isNaN(new Date(value).getTime()) ? (
                <span style={{ color: "red" }}>Shouldn't be blank</span>
              ) : (
                formatDate(value, dateFormat) // Use the formatDate function for valid dates
              )
            ) : (
              <span style={{ color: "red" }}>Shouldn't be blank</span>
            )
        },
        
        { 
          Header: 'Valid To', 
          accessor: 'validTo', 
          visible: true, 
          width: 200, 
          alignment: "left", 
          Cell: ({ value }) => 
            value ? (
              isNaN(new Date(value).getTime()) ? (
                <span style={{ color: "red" }}>Shouldn't be blank</span>
              ) : (
                formatDate(value, dateFormat) // Use the formatDate function for valid dates
              )
            ) : (
              <span style={{ color: "red" }}>Shouldn't be blank</span>
            )
        },
        // { Header: 'valid To', accessor: 'validTo', visible: true, width: 200, alignment: "left", Cell: ({ value }) => formatDate(value, dateFormat), },
        { Header: 'Email ID', accessor: 'email', visible: true, width: 200, alignment: "left" },      
        { Header: 'mobileNo', accessor: 'mobileNo', visible: true, width: 200, alignment: "left" },
        { Header: 'whatsAppNo', accessor: 'whatsAppNo', visible: true, width: 200, alignment: "left" },
        { Header: 'nationality', accessor: 'nationality', visible: true, width: 200, alignment: "left" },
        { Header: 'passportNo', accessor: 'passportNo', visible: true, width: 200, alignment: "left" },
        { Header: 'drivingLicenseNo', accessor: 'drivingLicenseNo', visible: true, width: 200, alignment: "left" },
        { Header: 'localId', accessor: 'localId', visible: true, width: 200, alignment: "left" },
        { Header: 'officeNo', accessor: 'officeNo', visible: true, width: 200, alignment: "left" },
        { Header: 'extension', accessor: 'extension', visible: true, width: 200, alignment: "left" },
        // { Header: 'joiningDate', accessor: 'joiningDate', visible: true, width: 200, alignment: "left" },
        { 
          Header: 'joiningDate', 
          accessor: 'joiningDate', 
          visible: true, 
          width: 200, 
          alignment: "left", 
          Cell: ({ value }) => 
            value ? (
              isNaN(new Date(value).getTime()) ? (
                <span> </span>
              ) : (
                formatDate(value, dateFormat) // Use the formatDate function for valid dates
              )
            ) : (
              <span> </span>
            )
        },

        { 
          Header: 'resignationDate', 
          accessor: 'resignationDate', 
          visible: true, 
          width: 200, 
          alignment: "left", 
          Cell: ({ value }) => 
            value ? (
              isNaN(new Date(value).getTime()) ? (
                <span> </span>
              ) : (
                formatDate(value, dateFormat) // Use the formatDate function for valid dates
              )
            ) : (
              <span> </span>
            )
        },

        // { Header: 'resignationDate', accessor: 'resignationDate', visible: true, width: 200, alignment: "left" },
        { Header: 'Door No', accessor: 'DoorNo', visible: true, width: 200, alignment: "left" },
        { Header: 'Floor No', accessor: 'FloorNo', visible: true, width: 200, alignment: "left" },
        { Header: 'Building No', accessor: 'BuildingNo', visible: true, width: 200, alignment: "left" },
        { Header: 'Building Name', accessor: 'BuildingName', visible: true, width: 200, alignment: "left" },
        { Header: 'LandMark', accessor: 'LandMark', visible: true, width: 200, alignment: "left" },
        { Header: 'Street No', accessor: 'StreetNo', visible: true, width: 200, alignment: "left" },
        { Header: 'Street Name', accessor: 'StreetName', visible: true, width: 200, alignment: "left" },
        { Header: 'Road No', accessor: 'RoadNo', visible: true, width: 200, alignment: "left" },
        { Header: 'Road Name', accessor: 'RoadName', visible: true, width: 200, alignment: "left" },
        { Header: 'Block', accessor: 'Block', visible: true, width: 200, alignment: "left" },
        // { Header: 'Area Name', accessor: 'Area Name', visible: true, width: 200, alignment: "left" },
        // { Header: 'streetName', accessor: 'streetName', visible: true, width: 200, alignment: "left" },
        { Header: 'cityName', accessor: 'cityName', visible: true, width: 200, alignment: "left" },
        { Header: 'areaName', accessor: 'areaName', visible: true, width: 200, alignment: "left" },
        // { Header: 'landmarkName', accessor: 'landmarkName', visible: true, width: 200, alignment: "left" },
        { Header: 'addressLine1', accessor: 'addressLine1', visible: true, width: 200, alignment: "left" },
        { Header: 'addressLine2', accessor: 'addressLine2', visible: true, width: 200, alignment: "left" },
        { Header: 'addressLine3', accessor: 'addressLine3', visible: true, width: 200, alignment: "left" },
        { Header: 'countryId', accessor: 'countryId', visible: true, width: 200, alignment: "left" },
        { Header: 'State/Province', accessor: 'StateProvince', visible: true, width: 200, alignment: "left" },
        { Header: 'District', accessor: 'District', visible: true, width: 200, alignment: "left" },
        { Header: 'Postal Code', accessor: 'PostalCode', visible: true, width: 200, alignment: "left" },
        { Header: 'Zip Code', accessor: 'ZipCode', visible: true, width: 200, alignment: "left" },
        { Header: 'PO Box', accessor: 'POBox', visible: true, width: 200, alignment: "left" },
        { Header: 'DateFormat', accessor: 'DateFormat', visible: true, width: 200, alignment: "left" },
        { Header: 'amountFormat', accessor: 'amountFormat', visible: true, width: 200, alignment: "left" },
        { Header: 'thousandSeparator', accessor: 'thousandSeparator', visible: true, width: 200, alignment: "left" },
        { Header: 'decimalSeparator', accessor: 'decimalSeparator', visible: true, width: 200, alignment: "left" },
        { Header: 'acceleratorForThousands', accessor: 'acceleratorForThousands', visible: true, width: 200, alignment: "left" },
        { Header: 'acceleratorForLakhs', accessor: 'acceleratorForLakhs', visible: true, width: 200, alignment: "left" },
        { Header: 'acceleratorForMillions', accessor: 'acceleratorForMillions', visible: true, width: 200, alignment: "left" },
      
      ];

        const existingButtons = AdminActionsConfig.screen7.map((button) => {
          let onClick;
          let title;
      
      
          if (button.icon === MdOutlineFileDownload) {
            onClick = handleDownload;
            title = "Download File";
          }
      
          else {
            onClick = button.onClick || (() => { }); // Provide a default no-op function if onClick is undefined
            title = button.title || ""; // Use existing title or default to an empty string
          }
      
          return {
            ...button,
            onClick, // Reference the function without invoking it
            title,   // Add the title property to the button configuration
          };
        });
      
 const extraButtons = [

  ];

      const actionButtons = existingButtons.concat(
        extraButtons.map((button) => ({
          ...button,
          disabled: selectedRows.length === 0, // Disable button if no users are selected
          onClick: selectedRows.length > 0 ? button.onClick : null, // Enable onClick only if users are selected
        }))
      );

  const [columnSettings, setColumnSettings] = useState(columns);

  const handleUpdateColumnSettings = (updatedSettings) => {
    setColumnSettings(updatedSettings);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex for basic email validation
    return emailRegex.test(email);
  };


// Function to check if a row is invalid
const isRowInvalid = (row) => {
  const isSLNoInvalid = !row.SLNo || isNaN(row.SLNo) || String(row.SLNo).length > 5;
  const isUserIdInvalid = !row.userId || String(row.userId).length > 12;
  const isUsernameInvalid = !row.username || String(row.username).length > 12;
  const isFirstNameInvalid = !row.firstName || String(row.firstName).length > 20;
  const isLastNameInvalid = !row.lastName || String(row.lastName).length > 20;
  const isMobileNoInvalid = !row.mobileNo|| isNaN(row.mobileNo) || String(row.mobileNo).length > 13;
  const isNationalityInvalid = !row.nationality|| String(row.nationality).length > 20;
  const isValidFromInvalid = !row.validFrom || String(row.validFrom).length > 10;
  const isValidToInvalid = !row.validTo || String(row.validTo).length > 10;
  const isCountryIdInvalid = !row.countryId || String(row.countryId).length > 2;
  const isEmailInvalid = !row.email || String(row.email).length > 80 || !validateEmail(row.email);

  return (
    isSLNoInvalid ||
    isUserIdInvalid ||
    isUsernameInvalid ||
    isFirstNameInvalid ||
    isLastNameInvalid ||
    isMobileNoInvalid ||
    isNationalityInvalid ||
    isValidFromInvalid ||
    isValidToInvalid ||
    isCountryIdInvalid ||
    isEmailInvalid
  );
};


// Function to check if a row is valid
const isRowValid = (row) => {
  const isSLNovalid = row.SLNo && !isNaN(row.SLNo) && String(row.SLNo).length <= 5;
  const isUserIdValid = row.userId && String(row.userId).length <= 12;
  const isUsernameValid = row.username && String(row.username).length <= 12;
  const isFirstNameValid = row.firstName && String(row.firstName).length <= 20;
  const isLastNameValid = row.lastName && String(row.lastName).length <= 20;
  const isMobileNoValid = row.mobileNo && !isNaN(row.mobileNo) && String(row.mobileNo).length <= 13;
  const isNationalityValid = row.nationality && String(row.nationality).length <= 20;
  const isValidFromValid = row.validFrom && String(row.validFrom).length <= 10;
  const isValidToValid = row.validTo && String(row.validTo).length <= 10;
  const isCountryIdValid = row.countryId && String(row.countryId).length <= 2;
  const isEmailValid = row.email && String(row.email).length <= 80 && validateEmail(row.email);

  return (
    isSLNovalid &&
    isUserIdValid &&
    isUsernameValid &&
    isFirstNameValid &&
    isLastNameValid &&
    isMobileNoValid &&
    isNationalityValid &&
    isValidFromValid &&
    isValidToValid &&
    isCountryIdValid &&
    isEmailValid
  );
};

// Preprocess table data to include only rows where at least one field is invalid
const filteredData = tableData.filter((row) => isRowInvalid(row));
console.log("filteredData is ",filteredData);
// Preprocess table data to include only rows where all fields are valid
const successfulRecords = tableData.filter((row) => isRowValid(row));
console.log("successfulRecords data",successfulRecords);
// const dataToDisplay = uploadOption === "3" ? successfulRecords : filteredData;
  
  // Decide which data to display based on displayOption and uploadOption
  let dataToDisplay;
  if (displayOption === "1") {
    dataToDisplay = filteredData; // Display filteredData if displayOption is "1"
  } else if (displayOption === "2") {
    dataToDisplay = tableData; // Display tableData if displayOption is "2"
  } else if (uploadOption === "3") {
    dataToDisplay = successfulRecords; // Display successfulRecords if uploadOption is "3"
  } else if (uploadOption === "4") {
    dataToDisplay = tableData; // Display filteredData if uploadOption is "4"
  } else {
    dataToDisplay = tableData; // Default to tableData if no conditions are met
  }

console.log("dataToDisplay data is",dataToDisplay);

  // useEffect(() => {
  //   if (uploadOption !== '') {
  //     console.log("selected uploadOption", uploadOption);
  //     storeArrayDataForUsers();
  //   }
  // }, [uploadOption]);

// const storeArrayDataForUsers= async () => {
//   try {
//     console.log("storeArrayDataForUsers dataToDisplay is",dataToDisplay);
//     const response = await UserService.saveContactDetails(
//       `${process.env.REACT_APP_BASE_URL}/store-array-users-data`,
//       dataToDisplay
//     );
//     console.log("Roles Response data", response);

//   } catch (error) {
//     console.error("Error fetching array users data", error);
//   }
// };

async function checkDuplicateUserIdsFromDB(userIds) {
  try {
    const data = { userIds };
    console.log("UserIds duplicated DATA IS",data);
    
    const response = await UserService.saveContactDetails(
      `${process.env.REACT_APP_BASE_URL}/get-Users-duplicates`,
      data
    );

    console.log("API Response for response details:", response);
    console.log("API Response for duplicates details:", response.duplicates);
    return response.duplicates; // Return an array of duplicate userIds
  } catch (error) {
    console.error("Error checking duplicate userIds from DB:", error);
    return [];
  }
}

const processData = async (data) => {
  // Extract all userIds from dataToDisplay
  const userIds = data.map(row => row.userId).filter(Boolean);
  const duplicateUserIdsFromDB = await checkDuplicateUserIdsFromDB(userIds);


   // Preprocess table data to add conditional rendering for userId and username
   return data.map((row, _, allRows) => {
    // const rowInvalid = isRowInvalid(row);
    // Define the max allowed characters for each column (this can come from your column definitions)
  
      // Check for duplicate userId in dataToDisplay
  const isDuplicateUserId = allRows.filter(r => r.userId == row.userId).length > 1;
console.log("Duplicate user id is ",isDuplicateUserId);

 // Check for duplicate userId in the database
 const isDuplicateInDB = duplicateUserIdsFromDB.includes(row.userId);

 // Check for errors (red text) and apply highlight based on that
 const hasError = [
  row.SLNo, 
  row.userId, 
  row.username, 
  row.firstName, 
  row.middleName,
  row.lastName,
  row.AccountingSystemId,
  row.mobileNo, 
  row.whatsAppNo,
  row.officeNo,
  row.extension,
  row.passportNo,
  row.drivingLicenseNo,
  row.localId,
  row.nationality,
  row.employeeNo,
  row.companyCodeId,
  row.department,
  row.validFrom,
  row.validTo,
  row.resignationDate,
  row.joiningDate,
  row.addressLine1,
  row.addressLine2,
  row.addressLine3,
  row.countryId,
  row.StateProvince,
  row.language,
  row.DateFormat,
  row.timeFormat,
  row.amountFormat,
  row.thousandSeparator,
  row.decimalSeparator,
  row.email
].some(field => {
  console.log("Filed data",field);
  console.log("row.middleName data",row.middleName);

  if (!row.SLNo || field === row.SLNo) {
    // SLNo error conditions
    if (!field) return true; // Shouldn't be blank
    if (isNaN(field)) return true; // Mismatched Data Type
    if (String(field).length > 5) return true; // Max. allowed character is 5
  }
  else if (!row.userId || field === row.userId) {
    // userId error conditions
    if (!field) return true; // Shouldn't be blank
    if (String(field).length > 12) return true; // Max. allowed character is 12
    if (!isDuplicateUserId || !isDuplicateInDB) return true; // isDuplicateUserId 
  }
  else if (!row.username || field === row.username) {
    // username error conditions
    if (!field) return true; // Shouldn't be blank
    if (String(field).length > 12) return true; // Max. allowed character is 12
  }
  else if (!row.firstName || field === row.firstName) {
    // firstName error conditions
    if (!field) return true; // Shouldn't be blank
    if (String(field).length > 20) return true; // Max. allowed character is 20
  }


  else if (field === row.middleName) {
    // middleName error conditions
    if (String(field).length > 20) return true; // Max. allowed character is 20
    else if( row.middleName === ''){
      return false;
    }
  }
  else if (!row.lastName || field === row.lastName) {
    // lastName error conditions
    if (!field) return true; // Shouldn't be blank
    if (String(field).length > 20) return true; // Max. allowed character is 20
  }
  else if (field === row.AccountingSystemId) {
    // AccountingSystemId error conditions
    if (String(field).length > 12) return true; // Max. allowed character is 12
    else if( row.AccountingSystemId === ''){
      return false;
    }
  }
  else if (!row.mobileNo || field === row.mobileNo) {
    // mobileNo error conditions
    if (!field) return true; // Shouldn't be blank
    if (isNaN(field)) return true; // Mismatched Data Type
    if (String(field).length > 13) return true; // Max. allowed character is 13
  }
  else if (field === row.whatsAppNo) {
    // whatsAppNo error conditions
    if (isNaN(field)) return true; // Mismatched Data Type
    if (String(field).length > 13) return true; // Max. allowed character is 13
    else if( row.whatsAppNo === ''){
      return false;
    }
  }
  else if (field === row.officeNo) {
    // officeNo error conditions
    if (isNaN(field)) return true; // Mismatched Data Type
    if (String(field).length > 13) return true; // Max. allowed character is 13
    else if( row.officeNo === ''){
      return false;
    }
  }
  else if (field === row.extension) {
    // extension error conditions
    if (isNaN(field)) return true; // Mismatched Data Type
    if (String(field).length > 13) return true; // Max. allowed character is 13
    else if( row.extension === ''){
      return false;
    }
  }
  else if (field === row.passportNo) {
    // passportNo error conditions
    if (String(field).length > 13) return true; // Max. allowed character is 13
    else if( row.passportNo === ''){
      return false;
    }
  }
  else if (field === row.drivingLicenseNo) {
    // drivingLicenseNo error conditions
    if (String(field).length > 13) return true; // Max. allowed character is 13
    else if( row.drivingLicenseNo === ''){
      return false;
    }
  }
  else if (field === row.localId) {
    // localId error conditions
    if (isNaN(field)) return true; // Mismatched Data Type
    if (String(field).length > 13) return true; // Max. allowed character is 13
    else if( row.localId === ''){
      return false;
    }
  }
  else if (!row.nationality || field === row.nationality) {
    // nationality error conditions
    if (!field) return true; // Shouldn't be blank
    if (String(field).length > 20) return true; // Max. allowed character is 20
    
  }
  else if (field === row.employeeNo) {
    // employeeNo error conditions
    if (String(field).length > 12) return true; // Max. allowed character is 12
    else if( row.employeeNo === ''){
      return false;
    }
  }
  else if (field === row.companyCodeId) {
    // companyCodeId error conditions
    if (String(field).length > 10) return true; // Max. allowed character is 10
    else if( row.companyCodeId === ''){
      return false;
    }
  }
  else if (field === row.department) {
    // department error conditions
    if (String(field).length > 6) return true; // Max. allowed character is 6
    else if( row.department === ''){
      return false;
    }
  }
  else if (!row.validFrom || field === row.validFrom) {
    // validFrom error conditions
    console.log("valid from field",field);
    if (!field) return true; // Shouldn't be blank
    if (String(field).length > 10) return true; // Max. allowed character is 10
  }
  else if (!row.validTo || field === row.validTo) {
    // validTo error conditions
    if (!field) return true; // Shouldn't be blank
    if (String(field).length > 10) return true; // Max. allowed character is 10
  }
  else if (field === row.resignationDate) {
    // resignationDate error conditions
    if (String(field).length > 10) return true; // Max. allowed character is 10
    else if( row.resignationDate === ''){
      return false;
    }
  }
  else if (field === row.joiningDate) {
    // joiningDate error conditions
    if (String(field).length > 10) return true; // Max. allowed character is 10
    else if( row.joiningDate === ''){
      return false;
    }
  }
  else if (field === row.addressLine1) {
    // addressLine1 error conditions
    if (String(field).length > 50) return true; // Max. allowed character is 50
    else if( row.addressLine1 === ''){
      return false;
    }
  }
  else if (field === row.addressLine2) {
    // addressLine2 error conditions
    if (String(field).length > 50) return true; // Max. allowed character is 50
    else if( row.addressLine2 === ''){
      return false;
    }
  }
  else if (field === row.addressLine3) {
    // addressLine3 error conditions
    if (String(field).length > 50) return true; // Max. allowed character is 50
    else if( row.addressLine3 === ''){
      return false;
    }
  }
  else if (!row.countryId || field === row.countryId) {
    // countryId error conditions
    if (!field) return true; // Shouldn't be blank
    if (String(field).length > 2) return true; // Max. allowed character is 2
  }
  else if (field === row.StateProvince) {
    // StateProvince error conditions
    if (String(field).length > 3) return true; // Max. allowed character is 3
    else if( row.StateProvince === ''){
      return false;
    }
  }
  else if (field === row.language) {
    // language error conditions
    if (String(field).length > 2) return true; // Max. allowed character is 2
    else if( row.language === ''){
      return false;
    }
  }
  else if (field === row.DateFormat) {
    // DateFormat error conditions
    if (String(field).length > 10) return true; // Max. allowed character is 10
    else if( row.DateFormat === ''){
      return false;
    }
  }
  else if (field === row.timeFormat) {
    // timeFormat error conditions
    if (String(field).length > 8) return true; // Max. allowed character is 8
    else if( row.timeFormat === ''){
      return false;
    }
  }
  else if (field === row.amountFormat) {
    // amountFormat error conditions
    if (String(field).length > 19) return true; // Max. allowed character is 19
    else if( row.amountFormat === ''){
      return false;
    }
  }
  else if (field === row.thousandSeparator) {
    // thousandSeparator error conditions
    if (String(field).length > 1) return true; // Max. allowed character is 1
    if (![",", "."].includes(field)) return true; // Only ',' or '.' allowed
    if (field === row.decimalSeparator) return true; // Thousand and decimal separators cannot be the same
    else if( row.thousandSeparator === ''){
      return false;
    }
  }
  else if (field === row.decimalSeparator) {
    // decimalSeparator error conditions
    if (String(field).length > 1) return true; // Max. allowed character is 1
    if (![",", "."].includes(field)) return true; // Only ',' or '.' allowed
    if (field === row.thousandSeparator) return true; // Thousand and decimal separators cannot be the same
    else if( row.decimalSeparator === ''){
      return false;
    }
  }
  else if (!row.email || field === row.email) {
    // Email error condition
    if (!field) return true; // Shouldn't be blank
    if (field && !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(field)) {
      return true; // Invalid email format
    }
    if (String(field).length > 80) return true; // Max. allowed character is 80
  }
  return false; // No error for this field
});


const hasNotError = [
  row.SLNo, 
  row.userId, 
  row.username, 
  row.firstName, 
  row.middleName,
  row.lastName,
  row.AccountingSystemId,
  row.mobileNo, 
  row.whatsAppNo,
  row.officeNo,
  row.extension,
  row.passportNo,
  row.drivingLicenseNo,
  row.localId,
  row.nationality,
  row.employeeNo,
  row.companyCodeId,
  row.department,
  row.validFrom,
  row.validTo,
  row.resignationDate,
  row.joiningDate,
  row.addressLine1,
  row.addressLine2,
  row.addressLine3,
  row.countryId,
  row.StateProvince,
  row.language,
  row.DateFormat,
  row.timeFormat,
  row.amountFormat,
  row.thousandSeparator,
  row.decimalSeparator,
  row.email
].every(field => {
  if (row.SLNo && field === row.SLNo) {
    // SLNo valid conditions
    if (!field) return false; // Should not be blank
    if (isNaN(field)) return false; // Should be a valid number
    if (String(field).length > 5) return false; // Max. allowed character is 5
  }
  else if (row.userId && field === row.userId) {
    // userId valid conditions
    if (!field) return false; // Should not be blank
    if (String(field).length > 12) return false; // Max. allowed character is 12
    if (isDuplicateUserId || isDuplicateInDB) return false; // isDuplicateUserId 
  }
  else if (row.username && field === row.username) {
    // username valid conditions
    if (!field) return false; // Should not be blank
    if (String(field).length > 12) return false; // Max. allowed character is 12
  }
  else if (row.firstName && field === row.firstName) {
    // firstName valid conditions
    if (!field) return false; // Should not be blank
    if (String(field).length > 20) return false; // Max. allowed character is 20
  }
  else if (field === row.middleName) {
    // middleName valid conditions
    if (String(field).length > 20) return false; // Max. allowed character is 20
    if (row.middleName === '') return true; // Can be empty
  }
  else if (row.lastName && field === row.lastName) {
    // lastName valid conditions
    if (!field) return false; // Should not be blank
    if (String(field).length > 20) return false; // Max. allowed character is 20
  }
  else if (field === row.AccountingSystemId) {
    // AccountingSystemId valid conditions
    if (String(field).length > 12) return false; // Max. allowed character is 12
    if (row.AccountingSystemId === '') return true; // Can be empty
  }
  else if (row.mobileNo && field === row.mobileNo) {
    // mobileNo valid conditions
    if (!field) return false; // Should not be blank
    if (isNaN(field)) return false; // Should be a valid number
    if (String(field).length > 13) return false; // Max. allowed character is 13
  }
  else if (field === row.whatsAppNo) {
    // whatsAppNo valid conditions
    if (isNaN(field)) return false; // Should be a valid number
    if (String(field).length > 13) return false; // Max. allowed character is 13
    if (row.whatsAppNo === '') return true; // Can be empty
  }
  else if (field === row.officeNo) {
    // officeNo valid conditions
    if (isNaN(field)) return false; // Should be a valid number
    if (String(field).length > 13) return false; // Max. allowed character is 13
    if (row.officeNo === '') return true; // Can be empty
  }
  else if (field === row.extension) {
    // extension error conditions
    if (isNaN(field)) return false; // Mismatched Data Type
    if (String(field).length > 13) return false; // Max. allowed character is 13
    else if( row.extension === ''){
      return true;
    }
  }
  else if (field === row.passportNo) {
    // passportNo error conditions
    if (String(field).length > 13) return false; // Max. allowed character is 13
    else if( row.passportNo === ''){
      return true;
    }
  }
  else if (field === row.drivingLicenseNo) {
    // drivingLicenseNo error conditions
    if (String(field).length > 13) return false; // Max. allowed character is 13
    else if( row.drivingLicenseNo === ''){
      return true;
    }
  }
  else if (field === row.localId) {
    // localId error conditions
    if (isNaN(field)) return false; // Mismatched Data Type
    if (String(field).length > 13) return false; // Max. allowed character is 13
    else if( row.localId === ''){
      return true;
    }
  }
  else if (!row.nationality || field === row.nationality) {
    // nationality valid conditions
    if (!field) return false; // Should not be blank
    if (String(field).length > 20) return false; // Max. allowed character is 20
  }
  else if (field === row.employeeNo) {
    // employeeNo error conditions
    if (String(field).length > 12) return false; // Max. allowed character is 12
    else if( row.employeeNo === ''){
      return true;
    }
  }
  else if (field === row.companyCodeId) {
    // companyCodeId error conditions
    if (String(field).length > 10) return false; // Max. allowed character is 10
    else if( row.companyCodeId === ''){
      return true;
    }
  }
  else if (field === row.department) {
    // department error conditions
    if (String(field).length > 6) return false; // Max. allowed character is 6
    else if( row.department === ''){
      return true;
    }
  }
  else if (!row.validFrom || field === row.validFrom) {
    // validFrom error conditions
    console.log("valid from field",field);
    if (!field) return false; // Shouldn't be blank
    if (String(field).length > 10) return false; // Max. allowed character is 10
  }
  else if (!row.validTo || field === row.validTo) {
    // validTo error conditions
    if (!field) return false; // Shouldn't be blank
    if (String(field).length > 10) return false; // Max. allowed character is 10
  }

  else if (field === row.resignationDate) {
    // resignationDate error conditions
    if (String(field).length > 10) return false; // Max. allowed character is 10
    else if( row.resignationDate === ''){
      return true;
    }
  }
  else if (field === row.joiningDate) {
    // joiningDate error conditions
    if (String(field).length > 10) return false; // Max. allowed character is 10
    else if( row.joiningDate === ''){
      return true;
    }
  }
  else if (field === row.addressLine1) {
    // addressLine1 error conditions
    if (String(field).length > 50) return false; // Max. allowed character is 50
    else if( row.addressLine1 === ''){
      return true;
    }
  }
  else if (field === row.addressLine2) {
    // addressLine2 error conditions
    if (String(field).length > 50) return false; // Max. allowed character is 50
    else if( row.addressLine2 === ''){
      return true;
    }
  }
  else if (field === row.addressLine3) {
    // addressLine3 error conditions
    if (String(field).length > 50) return false; // Max. allowed character is 50
    else if( row.addressLine3 === ''){
      return true;
    }
  }
  else if (!row.countryId || field === row.countryId) {
    // countryId error conditions
    if (!field) return false; // Shouldn't be blank
    if (String(field).length > 2) return false; // Max. allowed character is 2
  }
  else if (field === row.StateProvince) {
    // StateProvince error conditions
    if (String(field).length > 3) return false; // Max. allowed character is 3
    else if( row.StateProvince === ''){
      return true;
    }
  }
  else if (field === row.language) {
    // language error conditions
    if (String(field).length > 2) return false; // Max. allowed character is 2
    else if( row.language === ''){
      return true;
    }
  }
  else if (field === row.DateFormat) {
    // DateFormat error conditions
    if (String(field).length > 10) return false; // Max. allowed character is 10
    else if( row.DateFormat === ''){
      return true;
    }
  }
  else if (field === row.timeFormat) {
    // timeFormat error conditions
    if (String(field).length > 8) return false; // Max. allowed character is 8
    else if( row.timeFormat === ''){
      return true;
    }
  }
  else if (field === row.amountFormat) {
    // amountFormat error conditions
    if (String(field).length > 19) return false; // Max. allowed character is 19
    else if( row.amountFormat === ''){
      return true;
    }
  }
  else if (field === row.thousandSeparator) {
    // thousandSeparator error conditions
    if (String(field).length > 1) return false; // Max. allowed character is 1
    if (![",", "."].includes(field)) return false; // Only ',' or '.' allowed
    if (field === row.decimalSeparator) return false; // Thousand and decimal separators cannot be the same
    else if( row.thousandSeparator === ''){
      return true;
    }
  }
  else if (field === row.decimalSeparator) {
    // decimalSeparator error conditions
    if (String(field).length > 1) return false; // Max. allowed character is 1
    if (![",", "."].includes(field)) return false; // Only ',' or '.' allowed
    if (field === row.thousandSeparator) return false; // Thousand and decimal separators cannot be the same
    else if( row.decimalSeparator === ''){
      return true;
    }
  }

  else if (row.email && field === row.email) {
    // Email valid conditions
    if (!field) return false; // Should not be blank
    if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(field)) return false; // Invalid email format
    if (String(field).length > 80) return false; // Max. allowed character is 80
  }
  // Add other fields following the same pattern...

  return true; // Field passes all valid conditions
});

console.log("error list data is", hasError);
console.log("Not error list data is ", hasNotError);

 

    return {
      ...row,
      successfulrecords: hasNotError, // Mark as successful if no error
      // rowStyle: rowInvalid ? {} : hasError ? { backgroundColor: "red" } : { backgroundColor: "green" }, // Highlight in red if there's an error
      SLNo: row.SLNo ? (
          isNaN(row.SLNo) ? (
            <span style={{ color: "red"}}>
              {row.SLNo} (Mismatched Data Type)
            </span>
          ) : String(row.SLNo).length > 5 ? (
            <span style={{ color: "red"}}>
              {row.SLNo} (Max. allowed character is 5)
            </span>
          ) : (
            row.SLNo // Valid SLNo
          )
        ) : (
          <span style={{ color: "red"}}>Shouldn't be blank</span>
        ),
        
  
        userId: row.userId ? (
          String(row.userId).length > 12 ? (
            <span style={{ color: "red"}}>
              {row.userId} (Max. allowed character is 12)
            </span>
          ) : isDuplicateUserId || isDuplicateInDB ? (
            <span style={{ color: "red" }}>
              {row.userId} (User ID Duplicate exists)
            </span>
          ) : (
              String(row.userId) // Ensure userId is treated as a string
          )
        ) : (
          <span style={{ color: "red"}}>
            Shouldn't be blank
          </span>
        ),
  
        username: row.username ? (
          String(row.username).length > 12 ? (
            <span style={{ color: "red"}}>
              {row.username} (Max. allowed character is 12)
            </span>
          ) : (
              String(row.username) // Ensure userId is treated as a string
          )
        ) : (
          <span style={{ color: "red"}}>
            Shouldn't be blank
          </span>
        ),
  
        firstName: row.firstName ? (
          String(row.firstName).length > 20 ? (
            <span style={{ color: "red"}}>
              {row.firstName} (Max. allowed character is 20)
            </span>
          ) : (
              String(row.firstName) // Ensure userId is treated as a string
          )
        ) : (
          <span style={{ color: "red"}}>
            Shouldn't be blank
          </span>
        ),
  
        middleName: row.middleName ? (
          String(row.middleName).length > 20 ? (
            <span style={{ color: "red"}}>
              {row.middleName} (Max. allowed character is 20)
            </span>
          ) : (
              String(row.middleName) // Ensure userId is treated as a string
          )
        ) : (
          <span>
           
          </span>
        ),
  
        AccountingSystemId: row.AccountingSystemId ? (
          String(row.AccountingSystemId).length > 12 ? (
            <span style={{ color: "red"}}>
              {row.AccountingSystemId} (Max. allowed character is 12)
            </span>
          ) : (
              String(row.AccountingSystemId) // Ensure userId is treated as a string
          )
        ) : (
          <span>
           
          </span>
        ),
  
        lastName: row.lastName ? (
          String(row.lastName).length > 20 ? (
            <span style={{ color: "red"}}>
              {row.lastName} (Max. allowed character is 20)
            </span>
          ) : (
              String(row.lastName) // Ensure userId is treated as a string
          )
        ) : (
          <span style={{ color: "red"}}>
            Shouldn't be blank
          </span>
        ),
  
        mobileNo: row.mobileNo ? (
          isNaN(row.mobileNo) ? (
            <span style={{ color: "red"}}>
              {row.mobileNo} (Mismatched Data Type)
            </span>
          ) : String(row.mobileNo).length > 13 ? (
            <span style={{ color: "red"}}>
              {row.mobileNo} (Max. allowed character is 13)
            </span>
          ) : (
            row.mobileNo // Valid SLNo
          )
        ) : (
          <span style={{ color: "red"}}>Shouldn't be blank</span>
        ),
  
        whatsAppNo: row.whatsAppNo ? (
          isNaN(row.whatsAppNo) ? (
            <span style={{ color: "red"}}>
              {row.whatsAppNo} (Mismatched Data Type)
            </span>
          ) : String(row.whatsAppNo).length > 13 ? (
            <span style={{ color: "red"}}>
              {row.whatsAppNo} (Max. allowed character is 13)
            </span>
          ) : (
            row.whatsAppNo // Valid SLNo
          )
        ) : (
          <span> </span>
        ),
  
        officeNo: row.officeNo ? (
          isNaN(row.officeNo) ? (
            <span style={{ color: "red"}}>
              {row.officeNo} (Mismatched Data Type)
            </span>
          ) : String(row.officeNo).length > 13 ? (
            <span style={{ color: "red"}}>
              {row.officeNo} (Max. allowed character is 13)
            </span>
          ) : (
            row.officeNo // Valid SLNo
          )
        ) : (
          <span> </span>
        ),
        extension: row.extension ? (
          isNaN(row.extension) ? (
            <span style={{ color: "red"}}>
              {row.extension} (Mismatched Data Type)
            </span>
          ) : String(row.extension).length > 13 ? (
            <span style={{ color: "red"}}>
              {row.extension} (Max. allowed character is 13)
            </span>
          ) : (
            row.extension // Valid SLNo
          )
        ) : (
          <span> </span>
        ),
  
        passportNo: row.passportNo ? (
          String(row.passportNo).length > 13 ? (
            <span style={{ color: "red"}}>
              {row.passportNo} (Max. allowed character is 13)
            </span>
          ) : (
              String(row.passportNo) // Ensure userId is treated as a string
          )
        ) : (
          <span>
           
          </span>
        ),
  
        drivingLicenseNo: row.drivingLicenseNo ? (
          String(row.drivingLicenseNo).length > 13 ? (
            <span style={{ color: "red"}}>
              {row.drivingLicenseNo} (Max. allowed character is 13)
            </span>
          ) : (
              String(row.drivingLicenseNo) // Ensure userId is treated as a string
          )
        ) : (
          <span>
           
          </span>
        ),
  
        localId: row.localId ? (
          isNaN(row.localId) ? (
            <span style={{ color: "red"}}>
              {row.localId} (Mismatched Data Type)
            </span>
          ) : String(row.localId).length > 13 ? (
            <span style={{ color: "red"}}>
              {row.localId} (Max. allowed character is 13)
            </span>
          ) : (
            row.localId // Valid SLNo
          )
        ) : (
          <span> </span>
        ),
  
        nationality: row.nationality ? (
          String(row.nationality).length > 20 ? (
            <span style={{ color: "red"}}>
              {row.nationality} (Max. allowed character is 20)
            </span>
          ) : (
              String(row.nationality) // Ensure userId is treated as a string
          )
        ) : (
          <span style={{ color: "red"}}>
            Shouldn't be blank
          </span>
        ),
  
        employeeNo: row.employeeNo ? (
          String(row.employeeNo).length > 12 ? (
            <span style={{ color: "red"}}>
              {row.employeeNo} (Max. allowed character is 12)
            </span>
          ) : (
              String(row.employeeNo) // Ensure userId is treated as a string
          )
        ) : (
          <span>
         
          </span>
        ),
  
        companyCodeId: row.companyCodeId ? (
          String(row.companyCodeId).length > 10 ? (
            <span style={{ color: "red"}}>
              {row.companyCodeId} (Max. allowed character is 10)
            </span>
          ) : (
              String(row.companyCodeId) // Ensure userId is treated as a string
          )
        ) : (
          <span>
          
          </span>
        ),
        department: row.department ? (
          String(row.department).length > 6 ? (
            <span style={{ color: "red"}}>
              {row.department} (Max. allowed character is 6)
            </span>
          ) : (
              String(row.department) // Ensure userId is treated as a string
          )
        ) : (
          <span>
           
          </span>
        ),
        validFrom: row.validFrom ? (
          String(row.validFrom).length > 10 ? (
            <span style={{ color: "red"}}>
              {row.validFrom} (Max. allowed character is 10)
            </span>
          ) : (
              String(row.validFrom) // Ensure userId is treated as a string
          )
        ) : (
          <span style={{ color: "red"}}>
            Shouldn't be blank
          </span>
        ),
  
        validTo: row.validTo ? (
          String(row.validTo).length > 10 ? (
            <span style={{ color: "red"}}>
              {row.validTo} (Max. allowed character is 10)
            </span>
          ) : (
              String(row.validTo) // Ensure userId is treated as a string
          )
        ) : (
          <span style={{ color: "red"}}>
            Shouldn't be blank
          </span>
        ),
     
        resignationDate: row.resignationDate ? (
          String(row.resignationDate).length > 10 ? (
            <span style={{ color: "red"}}>
              {row.resignationDate} (Max. allowed character is 10)
            </span>
          ) : (
              String(row.resignationDate) // Ensure userId is treated as a string
          )
        ) : (
          <span>
          
          </span>
        ),
  
        joiningDate: row.joiningDate ? (
          String(row.joiningDate).length > 10 ? (
            <span style={{ color: "red"}}>
              {row.joiningDate} (Max. allowed character is 10)
            </span>
          ) : (
              String(row.joiningDate) // Ensure userId is treated as a string
          )
        ) : (
          <span>
          
          </span>
        ),
  
        addressLine1: row.addressLine1 ? (
          String(row.addressLine1).length > 50 ? (
            <span style={{ color: "red"}}>
              {row.addressLine1} (Max. allowed character is 50)
            </span>
          ) : (
              String(row.addressLine1) // Ensure userId is treated as a string
          )
        ) : (
          <span>
           
          </span>
        ),
  
        addressLine2: row.addressLine2 ? (
          String(row.addressLine2).length > 50 ? (
            <span style={{ color: "red"}}>
              {row.addressLine2} (Max. allowed character is 50)
            </span>
          ) : (
              String(row.addressLine2) // Ensure userId is treated as a string
          )
        ) : (
          <span>
           
          </span>
        ),
  
        addressLine3: row.addressLine3 ? (
          String(row.addressLine3).length > 50 ? (
            <span style={{ color: "red"}}>
              {row.addressLine3} (Max. allowed character is 50)
            </span>
          ) : (
              String(row.addressLine3) // Ensure userId is treated as a string
          )
        ) : (
          <span>
           
          </span>
        ),
  
        countryId: row.countryId ? (
          String(row.countryId).length > 2 ? (
            <span style={{ color: "red"}}>
              {row.countryId} (Max. allowed character is 2)
            </span>
          ) : (
              String(row.countryId) // Ensure userId is treated as a string
          )
        ) : (
          <span style={{ color: "red"}}>
            Shouldn't be blank
          </span>
        ),
      
        StateProvince: row.StateProvince ? (
          String(row.StateProvince).length > 3 ? (
            <span style={{ color: "red"}}>
              {row.StateProvince} (Max. allowed character is 3)
            </span>
          ) : (
              String(row.StateProvince) // Ensure userId is treated as a string
          )
        ) : (
          <span>
           
          </span>
        ),
  
        language: row.language ? (
          String(row.language).length > 3 ? (
            <span style={{ color: "red"}}>
              {row.language} (Max. allowed character is 3)
            </span>
          ) : (
              String(row.language) // Ensure userId is treated as a string
          )
        ) : (
          <span>
           
          </span>
        ),
        
        DateFormat: row.DateFormat ? (
          String(row.DateFormat).length > 10 ? (
            <span style={{ color: "red"}}>
              {row.DateFormat} (Max. allowed character is 10)
            </span>
          ) : (
              String(row.DateFormat) // Ensure userId is treated as a string
          )
        ) : (
          <span>
           
          </span>
        ),
  
        timeFormat: row.timeFormat ? (
          String(row.timeFormat).length > 8 ? (
            <span style={{ color: "red"}}>
              {row.timeFormat} (Max. allowed character is 8)
            </span>
          ) : (
              String(row.timeFormat) // Ensure userId is treated as a string
          )
        ) : (
          <span>
           
          </span>
        ),
  
        amountFormat: row.amountFormat ? (
          String(row.amountFormat).length > 19 ? (
            <span style={{ color: "red"}}>
              {row.amountFormat} (Max. allowed character is 19)
            </span>
          ) : (
              String(row.amountFormat) // Ensure userId is treated as a string
          )
        ) : (
          <span>
           
          </span>
        ),
  
        thousandSeparator: row.thousandSeparator ? (
          String(row.thousandSeparator).length > 1 ? (
              <span style={{ color: "red"}}>
              {row.thousandSeparator} (Max. allowed character is 1)
              </span>
          ) : [",", "."].includes(row.thousandSeparator) ? (
              row.thousandSeparator === row.decimalSeparator ? (
              <span style={{ color: "red"}}>
                  Thousand and decimal separators cannot be the same.
              </span>
              ) : (
              row.thousandSeparator // Valid case
              )
          ) : (
              <span style={{ color: "red"}}>
              {row.thousandSeparator} (Only ',' or '.' allowed)
              </span>
          )
          ) : (
          <span>
             
          </span>
          ),
  
          decimalSeparator: row.decimalSeparator ? (
          String(row.decimalSeparator).length > 1 ? (
              <span style={{ color: "red"}}>
              {row.decimalSeparator} (Max. allowed character is 1)
              </span>
          ) : [",", "."].includes(row.decimalSeparator) ? (
              row.decimalSeparator === row.thousandSeparator ? (
              <span style={{ color: "red"}}>
                  Thousand and decimal separators cannot be the same.
              </span>
              ) : (
              row.decimalSeparator // Valid case
              )
          ) : (
              <span style={{ color: "red"}}>
              {row.decimalSeparator} (Only ',' or '.' allowed)
              </span>
          )
          ) : (
          <span>
            
          </span>
          ),
  
  
  
        email: row.email ? (
          String(row.email).length > 80 ? (
            <span style={{ color: "red"}}>
              {row.email} (Max. allowed character is 80)
            </span>
          ) : !validateEmail(row.email) ? (
            <span style={{ color: "red"}}>
              {row.email} (Invalid email format)
            </span>
          ) : (
            row.email // If valid, display the email normally
          )
        ) : (
          <span style={{ color: "red"}}>
            Shouldn't be blank
          </span>
        ),
        
    
      };

   
  });
}

useEffect(() => {
  const fetchData = async () => {
    setLoading(true);
    const processed = await processData(dataToDisplay);
    setProcessedData(processed);
    setLoading(false);
  };

  fetchData();
}, []);

    const modifiedColumns = columnSettings
    .filter((col) => col.visible)
    .map((col) => ({
      ...col,
      width: col.width, // This should hold the updated width
    }));

//     const handleDoubleClick = (id) => {
//         console.log("check roles id", id);
//       };

//         const handleSelectAll = () => {
//     handleSelectAllChange();
//   };

//   const handleSelectAllChange = () => {
   
//   };

const handleBreadcrumbClick = (crumb) => {
  console.log("Breadcrumb clicked:", crumb.title);
  if (crumb.title === 'Admin') {
    console.log("Navigating to /Admin");
    navigate('/14/admin');
  } else if (crumb.title === 'User & SOD') {
    console.log("Navigating to User & SOD");
    navigate('/14/admin');
  }
  else if (crumb.title === 'User') {
    console.log("Navigating to /User");
    navigate('/14/admin/108/userscreen');
  }
  else if (crumb.title === 'Upload') {
    // console.log("Navigating to /User");
    navigate('/14/admin/108/user-upload');
  }
  else if (crumb.title === "View") {
    console.log("View");
    // navigate("/14/admin");
  }
};


  return (
    <div>
      {/* <h2>User Upload Display option{displayOption} </h2>
      <h2>User Upload upload option{uploadOption} </h2> */}
   
        <Header title={`Admin > User & SOD > User > Upload > Display`}
        onBreadcrumbClick={handleBreadcrumbClick}
        hasUnsavedChanges={unsavedChanges}
        backRoute={"/14/admin/108/user-upload"}
        />

<AdminActions
              icons={iconId}
              buttons={actionButtons}
              // extraButtons={extraButtons}
              selectedCount={selectedRows.length}
              selectUserIds={selectedRows} 
              // hasFiltersApplied={hasFiltersApplied}
              />

{isSettingsPopupOpen && (
        <ColumnSettingsPopup
          columns={columnSettings}
          onClose={() => setIsSettingsPopupOpen(false)}
          onUpdate={handleUpdateColumnSettings}
        />
      )}
        <ReTable
          data={processedData}
          columns={modifiedColumns} // Use modified columns based on visibility
          selectedRows={[]}
          onSelectRow={() => {}}
          onSelectAll={() => {}}
          showCheckbox={false}
          // rowKey="userId" 
          onRowDoubleClick={handleDoubleClick}
        />

    </div>
  );
}
