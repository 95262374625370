import RestService from "./RestServices"
const statmentBasePath = "/statement"
const managedStatementBasePath = "/managed-statements"
const BankStatementServices = {

    getStatementControls: (pageSize, page, clientId) => {
        const limit = pageSize || 10
        const offset = page && pageSize ? (page - 1) * pageSize : 0
        return RestService.GetAllData(`${statmentBasePath}/get-all-statements?limit=${limit}&offset=${offset}&clientId=${clientId}`)
    },
    getManagedStatementControls: (pageSize, page, clientId) => {
        const limit = pageSize || 10
        const offset = page && pageSize ? (page - 1) * pageSize : 0
        return RestService.GetAllData(`${managedStatementBasePath}/get-managed-statements?limit=${limit}&offset=${offset}&clientId=${clientId}`)
    },
    updateStatementControls: (data, updatededBy) => {
        return RestService.UpdateData(`${statmentBasePath}/update-statement?updatededBy=${updatededBy}`, data)
    },

    getmanagedStatementById: (id, clientId) => {
        return RestService.GetAllData(`${managedStatementBasePath}/get-manage-byId?accountId=${id}&clientId=${clientId}`);
    },
    updateManagedStatementControls: (data) => {
        return RestService.UpdateData(`${managedStatementBasePath}/update-manage-satement`, data)
    },

}
export default BankStatementServices;