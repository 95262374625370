// // Tabs.js
// import React from "react";

// const Tabs = ({ tabs, activeTab, onTabClick }) => {
//   const tabStyle = (tab) => ({
//     backgroundColor: activeTab === tab ? "white" : "transparent",
//     color: activeTab === tab ? "#616161" : "#616161",
//     padding: "2px 10px",
//     cursor: "pointer",
//     borderRadius: "5px 5px 0px 0px",
//     border: "none",
//   });

//   return (
//     <div className="ml-2 pl-8 pt-2 flex flex-row space-x-6 bg-sky-100 whitespace-nowrap ">
//       {tabs.map((tab) => (
//         <h6
//           key={tab.value}
//           className="font-small"
//           style={tabStyle(tab.value)}
//           onClick={() => onTabClick(tab.value)}
//         >
//           {tab.label}
//         </h6>
//       ))}
//     </div>
//   );
// };

// export default Tabs;
import React from "react";

const Tabs = ({
  tabs,
  activeTab,
  onTabClick,
  errors = {},
  isValidationTriggered,
}) => {
  const tabStyle = (tab) => {
    const isActiveTab = activeTab === tab;
    const tabHasError = isValidationTriggered && errors[tab]; // Dynamically highlight errors
    return {
      backgroundColor: isActiveTab ? "white" : "transparent",
      color: tabHasError ? "red" : isActiveTab ? "#0B60B0" : "#5D5D5D",
      padding: "2px 10px",
      cursor: "pointer",
      borderRadius: "5px 5px 0px 0px",
      borderBottom: "none",
      border: tabHasError ? "2px solid red" : "none",
    };
  };

  return (
    <div className="ml-2 pl-8 pt-2 flex flex-row space-x-6 bg-sky-100 whitespace-nowrap">
      {tabs.map((tab) => (
        <h6
          key={tab.value}
          className="font-small text-sm"
          style={tabStyle(tab.value)}
          onClick={() => onTabClick(tab.value)}
        >
          {tab.label}
        </h6>
      ))}
    </div>
  );
};


export default Tabs;
