import { update } from "lodash";
import RestService from "./RestServices";
const basePath = "/bam";

const BamServices = {
  getAccountType: (id) => {
    return RestService.GetAllData(`/get-account-types?clientId=${id}`);
  },
  getAllBranchWithAllStatus: async () => {
    return RestService.GetAllData(`${basePath}/get-all-branches-with-allstatus`);
  },
  updateAccountType: (data) => {
    return RestService.UpdateData(`/update-account-types`, data);
  },
  getAllZBATypes: (id) => {
    return RestService.GetAllData(`/get-zba-types?clientId=${id}`);
  },
  updateZBA: (data) => {
    return RestService.UpdateData(`/update-zba-types`, data);
  },
  getAllCurrency: () => {
    return RestService.GetAllData("/get-currency");
  },
  getAllLegalEntity: () => {
    return RestService.CreateData("/get-all-legal-entity");
  },
  getAssociatedCompany: (entitCode) => {
    return RestService.GetAllData(
      `${basePath}/get-associate-company?id=${entitCode}`
    );
  },

  addBankAccountMaster: (data) => {
    return RestService.CreateData(`${basePath}/create-bank-account`, data);
  },
  getZBAById: (id, clientId) => {
    return RestService.GetAllData(
      `/get-zba-by-id?id=${id}&clientId=${clientId}`
    );
  },

  getAccountTypesById: (id, clientId) => {
    return RestService.GetAllData(
      `/get-account-types-by-id?id=${id}&clientId=${clientId}`
    );
  },
  getDataForTable: (clientId, pageSize, page, searchQuery) => {
    const data = {
      clientId: clientId || "",
      limit: pageSize || 10,
      searchQuery: searchQuery || "",
      offset: page && pageSize ? (page - 1) * pageSize : 0,
    };

    return RestService.CreateData(`${basePath}/get-bank-account`, data);
  },

  getBankForAudit: (id) => {
    return RestService.GetAllData(`${basePath}/get-account-for-audit?id=${id}`);
  },
  approveBankAccount: (id, changedBy, username) => {
    const data = { id: id, changedBy, username };
    return RestService.CreateData(`${basePath}/approve-bank-account`, data);
  },
  activeInactiveBankAccount: (id, changedBy) => {
    const data = { id: id, changedBy };
    return RestService.CreateData(
      `${basePath}/activeInactive-bank-account`,
      data
    );
  },
  rejectBankAccount: (id, changedBy) => {
    const data = { id: id, changedBy };
    return RestService.CreateData(
      `${basePath}/reject-bank-account`, data
    );
  },
  updateBankId: (data) => {
    return RestService.UpdateData(`${basePath}/update-bank-account-id`, data);
  },
  getBankAccountById: (id, clientId) => {
    return RestService.GetAllData(
      `${basePath}/get-bank-account-by-id?accountId=${id}&clientId=${clientId}`
    );
  },
  searchBankAccount: (data) => {
    return RestService.GetAllData(
      `${basePath}/search-by-account?accountId=${data}`
    );
  },
  editBankAccount: (data) => {
    return RestService.UpdateData(`${basePath}/edit-bank-account-id`, data);
  },
  getAuditTrail: (accountId, iconId) => {
    return RestService.GetAllData(
      `${basePath}/get-bank-audit-trail?accountId=${accountId}&iconId=${iconId}`
    );
  },
  getValidity: (accountId) => {
    return RestService.GetAllData(
      `${basePath}/get-validity?accountId=${accountId}`
    );
  },
  getCompnayCode: (accountId) => {
    return RestService.GetAllData(
      `${basePath}/get-company-code?id=${accountId}`
    );
  },
  assignNewCompanyCode: (data) => {
    return RestService.UpdateData(`${basePath}/assign-new-company-code`, data);
  },

  getAccountStatus: (clientId, status) => {
    return RestService.GetAllData(
      `${basePath}/get-account-status?clientId=${clientId}&status=${status}`
    );
  },

  assignNewStatus: (data) => {
    return RestService.UpdateData(`${basePath}/assign-new-status`, data);
  },

  deleBankAccount: (id, clientId) => {
    return RestService.DeleteDataWithoutState(
      `${basePath}/delete-bank-account?clientId=${clientId}&id=${id}`
    );
  },
  mainBankAccount: () => {
    return RestService.GetAllData(
      `${basePath}/get-main-account`
    );
  },
  getFilterBankAccount: (filters) => {
    return RestService.CreateData(`${basePath}/get-filtered-account`, filters);
  },
  getExportData: (clientId, filters) => {
    return RestService.ExportData(
      `${basePath}/get-export-account?clientId=${clientId}`,
      filters
    );
  },

  checkAccountTypeExist: (data, clientId) => {
    return RestService.GetAllData(`${basePath}/check-account-types?query=${data}&clientId=${clientId}`,);
  },
  checkZBATypeExist: (data, clientId) => {
    return RestService.GetAllData(`${basePath}/check-zba-types?query=${data}&clientId=${clientId}`,);
  },

  checkAcountExist: (data) => {
    return RestService.GetAllData(`${basePath}/get-is-bankaccount-no?query=${data}`,);
  },
};
export default BamServices;
