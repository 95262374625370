import React from 'react';

const LabelWithInput = ({
  label,
  value,
  onChange,
  maxLength,
  placeholder,
  error,
  isRequired = false,
  isDisabled = false,
  inputType = 'text',
  isreadonly = false
}) => {
  return (
    <label className="flex items-center">
      <span className="w-48 font-small text-sm text-customGray">
        {label}
        {isRequired && <span className="text-red-500 ml-1">*</span>}
      </span>
      <span className="ml-4">:</span>
      <input
        type={inputType}
        value={value}
        onChange={onChange}
        maxLength={maxLength}
        placeholder={placeholder}
        readOnly={isreadonly}
        className={`border ${
          error ? 'border-red-500' : 'border-gray-300'
        } p-1.5  disabled:bg-[#FAFAFA] rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-sm font-sm text-customGray`}
        required={isRequired}
        disabled={isDisabled}
      />
    </label>
  );
};

export default LabelWithInput;
