import React, { useEffect, useState } from "react";
import StatementServices from "../../../../../rest-services/StatementIdentiServices";
import BankStatementActions from "../../props/ActionButtons";
import { bankStatementActionsConfig } from "../../props/ActionButtonConfig";
import { IoMdRefresh } from "react-icons/io";
import Pagination from "../../../../../components/PageNation";
import { MdClose, MdOutlineFileDownload, MdOutlineMotionPhotosAuto } from "react-icons/md";
import ReTable from "../../../../../components/Table";
import Header from "../../../../../components/Header";
import SearchInput from "../../../../../components/SearchInput";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../../../../components/toast/toast";
import CustomeLoading from "../../../../../components/CustomeLoading";
import { BsUbuntu } from "react-icons/bs";
import HoverButton from "../../../../../components/HoverButton";
import CustomButton from "../../../../../components/CustomButton";
import CustomDropdown from "../../../../../components/CustomDropdown";
import BamServices from "../../../../../rest-services/BamServices";

function StatementIdentifier() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(15);
  const navigate = useNavigate()
  const { toast } = useToast()
  const [manualAssing, setManualAssing] = useState(false)
  const [showExtraIcons, setShowExtraIcons] = useState(false);
  const [searchQuery, setSearchQuery] = useState('')

  useEffect(() => {
    fetchAssignee();
  }, []);
  const [assignee, setAssignee] = useState({
    clientId: "",
    id: "",
    userId: "",
    assigner: "",
  });
  const fetchData = async (page) => {
    try {
      const response = await StatementServices.getAllStatement(pageSize, page);

      let { identifier, totalCount } = response.data.data;

      if (!identifier) {
        identifier = [];
      } else if (!Array.isArray(identifier)) {
        identifier = [identifier];
      }

      setData(identifier);
      setTotalCount(totalCount);
      setTotalPages(Math.ceil(totalCount / 20));
    } catch (error) {
      console.error("Error fetching statement identifier data:", error);
    }
  };
  const handleShowAll = () => {
    setPageSize(totalCount); // Set page size to total count to display all records
    setCurrentPage(1); // Reset to the first page
    fetchData(1); // Fetch data with updated page size
  };

  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          clientId: userDetail.clientId || "",
          id: userDetail.id || "",
          assigner: userDetail.username || "",
          userId: userDetail.userId || "",
        };
        setAssignee(assigneeData);
      } else {
      }
    } catch (error) { }
  };

  const downloadExcel = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/identifier/export-data`,
        {
          method: "GET",
        }
      );

      if (!response.ok) {
        throw new Error("Error downloading file");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "statement_identifier.xlsx");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };
  const autoAssign = async () => {
    try {
      if (!assignee.clientId) return;
      setIsLoading(true);
      const response = await StatementServices.autoAssign(assignee.clientId);
      if (response.status === 200) {
        fetchData(currentPage)
        setIsLoading(false);
        toast("success", "Auto Assign Successfully");
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }
  useEffect(() => {
    if (searchQuery.length > 0) {
      handleSearch(searchQuery)
    }

  }, [searchQuery])
  const handleSearch = async (searchQuery) => {
    try {
      setIsLoading(true)
      const response = await StatementServices.searchStatement(searchQuery, pageSize, currentPage);
      if (response.status === 200) {

        setData(response.data.statement);
        setIsLoading(false);
        setTotalCount(totalCount);
        setTotalPages(Math.ceil(totalCount / 20));
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }

  const buttons = bankStatementActionsConfig.screen5.map((button) => {
    let onClick;
    let title;
    if (button.icon === IoMdRefresh) {
      onClick = () => fetchData(currentPage);
      title = "fetch";
    } else if (button.icon === MdOutlineFileDownload) {
      onClick = () => downloadExcel();
      title = "download";
    }
    else if (button.icon === MdOutlineMotionPhotosAuto) {
      onClick = () => autoAssign();
      title = "Auto Assign";
    }

    return {
      ...button,
      onClick,
      title,
    };
  });

  const extraButtons = [
    {
      icon: BsUbuntu,
      title: "Manual Assing",
      onClick: () => setManualAssing(true),
    },
  ];

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const columns = React.useMemo(() => [
    {
      Header: "Statement Type",
      accessor: "statement_type",
      width: 200,
    },
    {
      Header: "Statement Identifier",
      accessor: "statement_identifier",

      width: 200,
    },
    {
      Header: "Corporate BIC",
      accessor: "corporate_bic",

      width: 200,
    },
    { Header: "Bank BIC", accessor: "bank_bic", width: 200 },
    {
      Header: "Active",
      accessor: "status",
      width: 200,
      Cell: ({ value }) => (
        <input type="checkbox" checked={value === 1} readOnly />
      ),
    },

    {
      Header: "Bank account Id",
      accessor: "bank_account_id",
      width: 200,
    },
    {
      Header: " Company Code",
      accessor: "companyCode",
      width: 200,
    },
    {
      Header: "Bank",
      accessor: "bank",
      width: 200,
    },
    {
      Header: "Bank Branch",
      accessor: "bankBranch",
      width: 200,
    },
    {
      Header: "Bank Account No",
      accessor: "bankAccountNo",
      width: 200,
    },
    {
      Header: "IBAN",
      accessor: "iban",
      width: 200,
    },
    {
      Header: "Bank Statement Updated",
      accessor: "bankStatementUpdate",
      Cell: ({ value }) => (
        <input type="checkbox" checked={value === 1} readOnly />
      ),
      width: 200,
    },
    {
      Header: "Manual Updated",
      accessor: "manualUpdate",
      Cell: ({ value }) => (
        <input type="checkbox" checked={value === 1} readOnly />
      ),
      width: 200,
    },
    { Header: "Created By", accessor: "createdBy", width: 200 },
    {
      Header: "Created Date",
      accessor: "creationDate",
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, "0"); // Adds leading zero if day < 10
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Adds leading zero if month < 10
        const year = date.getFullYear();
        return <div style={{ textAlign: "left" }}> {`${day}-${month}-${year}`}</div>;
      },
    },
    {
      Header: "Created Time",
      accessor: "creationTime",
      Cell: ({ value }) => {
        if (!value) return ""; // Handle null or undefined values
        const date = new Date(value);
        const formattedTime = date.toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true, // Ensures 12-hour format with AM/PM
        });
        return <div style={{ textAlign: "left" }}>{formattedTime}</div>;
      },
    },
    {
      Header: "Updated By",
      accessor: "changedBy",

      width: 15,
      canResize: true,
    },
    {
      Header: "Updated Date",
      accessor: "changedDate",
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, "0"); // Adds leading zero if day < 10
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Adds leading zero if month < 10
        const year = date.getFullYear();
        return <div style={{ textAlign: "left" }}> {`${day}-${month}-${year}`}</div>;
      },
    },
    {
      Header: "Updated Time",
      accessor: "changedTime",
      Cell: ({ value }) => {
        if (!value) return ""; // Handle null or undefined values
        const date = new Date(value);
        const formattedTime = date.toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true, // Ensures 12-hour format with AM/PM
        });
        return <div style={{ textAlign: "left" }}>{formattedTime}</div>;
      },
    },
  ]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSelectRow = (id) => {
    handleCheckboxChange(id);
  };

  const handleSelectAll = () => {
    handleSelectAllChange();
  };
  const handleSelectAllChange = () => {
    if (isAllSelected) {
      setSelectedRows([]);
    } else {
      const allIds = data.map((item) => item.id);
      setSelectedRows(allIds);
    }
    setIsAllSelected(!isAllSelected);
  };

  const handleCheckboxChange = (id) => {
    const updatedSelectedRows = selectedRows.includes(id)
      ? selectedRows.filter((rowId) => rowId !== id)
      : [...selectedRows, id];

    setSelectedRows(updatedSelectedRows);
    setShowExtraIcons(updatedSelectedRows.length === 1);
  };

  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === "Statement Identifier") {
      navigate("/statement-identifire");
    } else if (crumb.title === "Bank Statements") {
      navigate("/bank-statements");
    } else if (crumb.title === "MT940") {
      navigate("/bank-statements");
    } else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };

  const ManualAssingModel = ({ isOpen, onclose, statmentId, }) => {
    const [mainAccount, setMainAccount] = useState([]);
    const [openDropdown, setOpenDropdown] = useState(null);
    const [selectedMainAccount, setSelectedMainAccount] = useState(null);
    useEffect(() => {
      if (!isOpen) return;
      fetchMainBankAccount();
    }, [isOpen]);
    const fetchMainBankAccount = async () => {
      try {
        const response = await BamServices.mainBankAccount();

        if (response.status === 200) {
          setMainAccount(response.data.bankAccounts);
        }
      } catch (error) {
        console.error("Error fetching main bank account:", error);
      }
    };
    const handleToggle = (dropdownId) => {
      setOpenDropdown((prev) => (prev === dropdownId ? null : dropdownId)); // Toggle dropdown
    };
    const mainBankAccountOptions = Array.isArray(mainAccount)
      ? mainAccount.map((bank) => ({
        value: bank.bankAccountId,
        label: bank.bankAccountId,
      }))
      : [];
    const handleMainAccountChange = async () => {
      try {
        setIsLoading(true)
        const data = {
          id: statmentId,
          bankAccountId: selectedMainAccount
        }
        const response = await StatementServices.manualAssign(data);

        if (response.status === 200) {
          toast("success", response.message);
          onclose();
          fetchData(currentPage);
          setIsLoading(false)

        }
      } catch (error) {
        console.error("Error fetching main bank account:", error);
        setIsLoading(false)
      }
    }
    return (
      <div>
        <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
          <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-3xl z-50">
            <div className="flex justify-between items-center mt-2">
              <h2 className="text-customBlue text-lg font-semibold mb-5 ">
                Manual Assing
              </h2>
              <div className={"p-2 cursor-pointer"}>
                <MdClose onClick={onclose} />
              </div>
            </div>
            <div className=" flex  align-center">
              <div className="grid grid-cols-2 gap-4 p-2">
                {/* Left-Side Single Column Fields */}
                <div className="col-span-2 sm:col-span-1">
                  <div className="flex items-center mb-2">
                    <label className="text-customGray mr-2 w-[140px] text-lg whitespace-nowrap">
                      Select Bank Account :
                    </label>
                    <CustomDropdown
                      value={
                        mainBankAccountOptions.find((option) => option.value === selectedMainAccount) || null
                      }
                      options={mainBankAccountOptions}
                      isOpen={openDropdown === "mainAccount"}
                      onChange={(value) => setSelectedMainAccount(value)}
                      onToggle={() => handleToggle("mainAccount")}
                      className="ml-[15%] border-gray-300"
                      placeholder="Select BankAccount Id" />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-end mt-4">
              <HoverButton
                title={"Cancel"}
                className={"mr-2"}
                onClick={onclose}
              />
              <CustomButton
                onClick={() => {
                  handleMainAccountChange()
                }}
                title={"Done"}
              />
            </div>

          </div>
        </div>
      </div >
    )
  }
  return (
    <div>
      <Header title="Bank Statements > MT940 > Statement Identifier" onBreadcrumbClick={handleBreadcrumbClick} />
      <div >
        <div className="flex justify-between items-center -ml-1 pl-4">
          <div className="flex items-center">
            <SearchInput
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              placeholder="Search by Identifiers"
            />

            <BankStatementActions buttons={buttons} extraButtons={extraButtons} isExtraButtonsVisible={showExtraIcons} />
          </div>
          <div className="flex items-center mr-4">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              totalCount={totalCount}
              onShowAll={handleShowAll}
            />
          </div>

        </div>
        <div>
          <ReTable
            data={data}
            columns={columns}
            selectedRows={selectedRows}
            onSelectRow={handleSelectRow}
            onSelectAll={handleSelectAll}
          //onRowDoubleClick={handleDoubleClick}
          />
        </div>
        <div>{manualAssing && <ManualAssingModel isOpen={manualAssing} onclose={() => setManualAssing(false)} statmentId={selectedRows[0]} />}</div>
        <div>{isLoading && <CustomeLoading />}</div>
      </div>
    </div>
  );
}

export default StatementIdentifier;
