import { useState } from "react";
import { IoIosList } from "react-icons/io";
import Header from "../../../../components/Header";
import Footer from "../../../../components/footer";
import CustomButton from "../../../../components/CustomButton";
import HoverButton from "../../../../components/HoverButton";

export default function AddTransferToBs() {
    const [errors, setErrors] = useState({});
    return (
        <div>
            <Header title="Bank Statements > MT940 > Transfer to BS" />
            <div className="p-8 pr-24">
                <div className="flex flex-col space-y-8 col-span-1 p-10 border  rounded-md">
                    <label className="flex items-center">
                        <span className="w-48 font-small text-gray-500">Process Name</span>
                        <span className="ml-4">:</span>
                        <input
                            type="text"
                            // onChange={(e) => setProcessName(e.target.value)}
                            className={`border ${errors.ntMainHead ? "border-red-500" : "border-gray-300"
                                } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
                            required
                        />
                        <button>
                            <IoIosList className="ml-6 border  h-6 w-6  hover hover:border-customBlue" />
                        </button>
                    </label>
                    <div className="flex flex-row space-x-8 col-span-1">
                        <label className="flex items-center">
                            <span className="w-48 font-small text-gray-500">Statment Type</span>
                            <span className="ml-4">:</span>
                            <input
                                type="text"
                                // onChange={(e) => setProcessName(e.target.value)}
                                className={`border ${errors.ntMainHead ? "border-red-500" : "border-gray-300"
                                    } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
                                required
                            />

                        </label>
                        <label className="flex items-center">
                            <span className="w-48 font-small text-gray-500">Statment Format</span>
                            <span className="ml-4">:</span>
                            <input
                                type="text"
                                // onChange={(e) => setProcessName(e.target.value)}
                                className={`border ${errors.ntMainHead ? "border-red-500" : "border-gray-300"
                                    } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
                                required
                            />
                        </label>
                    </div>
                </div>
                {/* Second Block */}
                <div className="flex flex-col space-y-8 col-span-1 p-10 border  rounded-md mt-2">
                    <div className="flex flex-row  space-x-[35%] row-span-2 ">
                        <label className="flex items-center">
                            <span className="w-48 font-small font-bold  text-gray-500">Company</span>

                        </label>
                        <label className="flex items-center">
                            <span className="w-48 font-small font-bold text-gray-500">Bank</span>

                        </label>
                    </div>
                    <div className="flex flex-row  space-x-[5%] row-span-2 ">
                        <label className="flex items-center">
                            <span className="w-48 font-small text-gray-500">Company Code</span>
                            <span className="ml-4">:</span>
                            <input
                                type="text"
                                // onChange={(e) => setProcessName(e.target.value)}
                                className={`border ${errors.ntMainHead ? "border-red-500" : "border-gray-300"
                                    } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
                                required
                            />
                        </label>
                        <label className="flex items-center">
                            <span className="w-48 font-small text-gray-500">Bank</span>
                            <span className="ml-4">:</span>
                            <input
                                type="text"
                                // onChange={(e) => setProcessName(e.target.value)}
                                className={`border ${errors.ntMainHead ? "border-red-500" : "border-gray-300"
                                    } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
                                required
                            />
                        </label>
                    </div>
                    <div className="flex flex-row  space-x-[5%] row-span-2 ">
                        <label className="flex items-center">
                            <span className="w-48 font-small text-gray-500">Legal Entity</span>
                            <span className="ml-4">:</span>
                            <input
                                type="text"
                                // onChange={(e) => setProcessName(e.target.value)}
                                className={`border ${errors.ntMainHead ? "border-red-500" : "border-gray-300"
                                    } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
                                required
                            />
                        </label>
                        <label className="flex items-center">
                            <span className="w-48 font-small text-gray-500">Bank ID</span>
                            <span className="ml-4">:</span>
                            <input
                                type="text"
                                // onChange={(e) => setProcessName(e.target.value)}
                                className={`border ${errors.ntMainHead ? "border-red-500" : "border-gray-300"
                                    } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
                                required
                            />
                        </label>
                    </div>
                    <div className="flex flex-row  space-x-[5%] row-span-2 ">
                        <label className="flex items-center">
                            <span className="w-48 font-small text-gray-500">Bu Group</span>
                            <span className="ml-4">:</span>
                            <input
                                type="text"
                                // onChange={(e) => setProcessName(e.target.value)}
                                className={`border ${errors.ntMainHead ? "border-red-500" : "border-gray-300"
                                    } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
                                required
                            />
                        </label>
                        <label className="flex items-center">
                            <span className="w-48 font-small text-gray-500">Bank Account ID</span>
                            <span className="ml-4">:</span>
                            <input
                                type="text"
                                // onChange={(e) => setProcessName(e.target.value)}
                                className={`border ${errors.ntMainHead ? "border-red-500" : "border-gray-300"
                                    } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
                                required
                            />
                        </label>
                    </div>
                </div>
                {/*Third Block*/}
                <div className="border rounded-md border-solid border-gray-300 w-full  p-10  mt-2">
                    <div className="mb-4">
                        <label className="flex items-center mb-2">
                            <span className="w-24 font-medium text-gray-700 ">Year/Month</span>
                            <span className="text-red-500">*</span>
                        </label>
                        <div className="grid grid-cols-2 gap-4">
                            <div className="flex items-center">
                                <label className="w-32 text-gray-700">Month/Year</label>
                                <span className="ml-[16%]">:</span>
                                <input
                                    type="date"
                                    // onChange={(e) => setProcessName(e.target.value)}
                                    className={`border ${errors.ntMainHead ? "border-red-500" : "border-gray-300"
                                        } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-9 text-gray-500`}
                                    required
                                />

                            </div>
                            <div className="flex items-center">

                                <input
                                    type="date"
                                    // onChange={(e) => setProcessName(e.target.value)}
                                    className={`border ${errors.ntMainHead ? "border-red-500" : "border-gray-300"
                                        } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-gray-500`}
                                    required
                                />
                            </div>
                        </div>
                    </div>

                    {/* Or Divider */}
                    <div className="flex justify-center my-4 text-gray-500">Or</div>

                    {/* Date Section */}
                    <div>
                        <div className="grid grid-cols-2 gap-4">
                            <div className="flex items-center">
                                <label className="w-32 text-gray-700">Date</label>
                                <span className="ml-[16%]">:</span>
                                <input
                                    type="date"
                                    // onChange={(e) => setProcessName(e.target.value)}
                                    className={`border ${errors.ntMainHead ? "border-red-500" : "border-gray-300"
                                        } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-9 text-gray-500`}
                                    required
                                />
                            </div>
                            <div className="flex items-center">

                                <input
                                    type="date"
                                    // onChange={(e) => setProcessName(e.target.value)}
                                    className={`border ${errors.ntMainHead ? "border-red-500" : "border-gray-300"
                                        } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-gray-500`}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer>
                <div>
                    <CustomButton
                        onClick={() => { }}
                        title={"Save"}
                        className="mr-2"
                    />
                    <HoverButton
                        title={"Save & Continue"}
                        className={"mr-2"}
                        onClick={() => { }}
                    />
                    <HoverButton
                        title={"Run >"}
                        className={"mr-2"}
                        onClick={() => { }}
                    />
                </div>
            </Footer>

        </div>
    );
}


