// import React, { useState } from "react";
// import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
// import { useNavigate } from "react-router-dom";
// import Card from "../../../components/Card";
// import { GlobeIcon, MapIcon, LanguageIcon,SourceCodeIcon } from "../../../components/SVG/FigmaIcons";
// import { LuCalendarDays, LuUpload } from "react-icons/lu";
// import { HiOutlineCurrencyDollar } from "react-icons/hi";
// import { CiBank } from "react-icons/ci";
// import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
// import { MdOutlineGroups,MdCurrencyYen, MdOutlinePentagon } from "react-icons/md";
// import { FaExchangeAlt } from "react-icons/fa";
// import { FaUserClock } from "react-icons/fa";
// import { RiAccountBoxLine } from "react-icons/ri";
// import { PiFolderUser } from "react-icons/pi";
// import { ImProfile } from "react-icons/im";
// import { Fa2, Fa42Group, FaBitcoin, FaBookTanakh, FaBuilding, FaBuildingCircleCheck, FaCalendar, FaEdgeLegacy, FaGlobe, FaKipSign, FaLanguage, FaMoneyBillWave, FaMoneyCheckDollar, FaPiggyBank, FaSackXmark, FaSortUp, FaStackExchange, FaStapler, FaUpload, FaWebflow } from "react-icons/fa6";
// import { FaCodeBranch, FaDatabase, FaFileArchive, FaMask, FaMehBlank, FaRegTimesCircle, FaStackpath } from "react-icons/fa";

// export default function MasterDataComponent  ()  {
//   const [SetupOpen, setSetupOpen] = useState(true);
//   const [globalMasterDataOpen, setGlobalMasterDataOpen] = useState(true);
//   const [CompanyOpen, SetCompanyOpen ] = useState(true);
//   const [bankMasterData, SetBankMasterDataOpen] = useState(true);
//   const [marketDataOpen, SetmarketDataOpen] = useState(true);
//   const [AccountsOpen, SetAccountsOpen] = useState(true);
//   const navigate = useNavigate();
//   const handleCardClick = (route) => {
//     navigate(route);
//   };
//   // const togglesetupOpen = () => {
//   //   setSetupOpen(!SetupOpen);
//   // };

//   const toggleGlobalmasterData = () => {
//     setGlobalMasterDataOpen(!globalMasterDataOpen);
//   };
//   const ToggleCompany = () => {
//     SetCompanyOpen(!CompanyOpen);
//   };
//   const ToggleBankMasterData = () => {
//     SetBankMasterDataOpen(!bankMasterData);
//   };
//   const ToggleMarketData = () => {
//     SetmarketDataOpen(!marketDataOpen);
//   };
//   const ToggleAccounts = () => {
//     SetAccountsOpen(!AccountsOpen);
//   };

//   return (
//     <>
//     <div>
//       <div className=" text-primary-foreground p-5 ml-2 shadow-sm">
//         <h1 className="text-xl font-semibold text-customBlue">Primary Data</h1>
//       </div>
//       <section>
//         {/* <div className="mt-2 ml-2 ">
//           <div className="bg-muted  rounded-lg mb-12">
//             <div
//               className="flex justify-between cursor-pointer border bg-sky-50 w-full  px-4 py-1"
//               onClick={togglesetupOpen}
//             >
//               <h2 className="text-left text-md font-medium  text-muted-foreground text-muted-foreground">
//                 SetUp
//               </h2>
//               <span className="p-1">
//                 {SetupOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
//               </span>
//             </div>
//             <div
//               className={`overflow-hidden transition-opacity duration-900 ${
//                 SetupOpen ? "opacity-100 max-h-96" : "opacity-0 max-h-0"
//               }`}
//             >
//               <div className="my-2 -mt-4">
//                 {SetupOpen && (
//                   <div className="mt-4 flex flex-wrap gap-6  mt-1  p-3">
//                   <Card
//                     className="mr-4"
//                       title="Account Status"
//                       icon={<FaStackpath />}
//                       onClick={() => handleCardClick("/userscreen")}
//                     />
//                     <Card
//                       title="Account Number Logic"
//                       icon={<FaWebflow />}
//                       onClick={() => handleCardClick("/statement-formatt")}
//                     />
//                     <Card
//                       title=" ZBA Types"
//                       icon={<FaKipSign />}
//                       onClick={() => handleCardClick("/statement-formatt")}
//                     />
//                     <Card
//                       title="DB Area"
//                       icon={<FaDatabase />}
//                       onClick={() => handleCardClick("/statement-formatt")}
//                     />
//                      <Card
//                       title="Account Types"
//                       icon={<FaBuilding />}
//                       onClick={() => handleCardClick("/statement-formatt")}
//                     />
//                   </div>
//                 )}
//               </div>
//             </div>
//           </div>
//         </div> */}
//         <div className="mt-2 ml-2">
//           <div className="bg-muted  rounded-lg mb-16">
//             <div
//               className="flex justify-between cursor-pointer border bg-sky-50 w-full  px-4 py-1"
//               onClick={toggleGlobalmasterData}
//             >
//               <h2 className="text-left text-md font-medium  text-muted-foreground text-muted-foreground">
//                 Global Master Data
//               </h2>
//               <span className="p-1">
//                 {globalMasterDataOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
//               </span>
//             </div>
//             <div
//               className={`overflow-hidden transition-opacity duration-900 ${
//                 globalMasterDataOpen ? "opacity-100 max-h-96" : "opacity-0 max-h-0"
//               }`}
//             >
//               <div className="my-2 -mt-4">
//                 {globalMasterDataOpen && (
//                   <div className="mt-4 flex flex-wrap gap-6  mt-1  p-3">
//                     <Card
//                       title="Country"
//                       icon={<GlobeIcon />}
//                       onClick={() => handleCardClick("/country")}
//                     />
//                     <Card
//                       title="State/province"
//                       icon={<MapIcon />}
//                       onClick={() => handleCardClick("/state-province")}
//                     />
//                     <Card
//                       title="Calendar"
//                       icon={<LuCalendarDays />}
//                       onClick={() => handleCardClick("/calendar")}
//                     />
//                     <Card
//                       title="Currency"
//                       icon={<HiOutlineCurrencyDollar />}
//                       onClick={() => handleCardClick("/currency")}
//                     />
//                     <Card
//                       title="Bank"
//                       icon={<CiBank />}
//                       onClick={() => handleCardClick("/bank")}
//                     />
//                     <Card
//                       title="Language"
//                       icon={<LanguageIcon />}
//                       onClick={() => handleCardClick("/language")}
//                     />

//                   </div>
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className=" -mt-16">
//           {" "}
//           {/* Added margin top to separate sections */}
//           <div className="bg-muted  rounded-lg mb-4 ml-2">
//             <div
//               className="flex justify-between cursor-pointer border bg-sky-50 w-full  px-4 py-1"
//               onClick={ToggleCompany}
//             >
//               <h2 className=" text-left text-md font-medium  text-muted-foreground">
//                 Company
//               </h2>
//               <span className="p-1">
//                 {CompanyOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
//               </span>
//             </div>
//             <div
//               className={`overflow-hidden transition-opacity duration-900 ${
//                 CompanyOpen ? "opacity-100 max-h-96" : "opacity-0 max-h-0"
//               }`}
//             >
//               <div className="my-2 -mt-4">
//                 {CompanyOpen && (
//                   <div className="mt-4 flex flex-wrap gap-6  mt-1  p-3">
//                     <Card
//                       title="Legal Entity"
//                       icon={<HiOutlineBuildingOffice2 />}
//                       onClick={() => handleCardClick("/negative-balances")}
//                     />
//                      <Card
//                       title="BU Group"
//                       icon={<MdOutlineGroups/>}
//                       onClick={() => handleCardClick("/negative-balances")}
//                     />
//                      <Card
//                       title="Company"
//                       icon={<HiOutlineBuildingOffice2/>}
//                       onClick={() => handleCardClick("/negative-balances")}
//                     />
//                        <Card
//                       title="Bank ID"
//                       icon={<CiBank />}
//                       onClick={() => handleCardClick("/negative-balances")}
//                     />

//                      <Card
//                       title="Branch "
//                       icon={<FaCodeBranch />}
//                       onClick={() => handleCardClick("/dormant")}
//                     />
//                   </div>
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>
//         {/* <div className=" -mt-4">
//           {" "} */}
//           {/* Added margin top to separate sections */}
//           {/* <div className="bg-muted  rounded-lg mb-4 ml-2">
//             <div
//               className="flex justify-between cursor-pointer border bg-sky-50 w-full  px-4 py-1"
//               onClick={ToggleBankMasterData}
//             >
//               <h2 className=" text-left text-md font-medium  text-muted-foreground">
//                 Bank Master Data
//               </h2>
//               <span className="p-1">
//                 {bankMasterData ? <IoIosArrowUp /> : <IoIosArrowDown />}
//               </span>
//             </div> */}
//             {/* <div
//               className={`overflow-hidden transition-opacity duration-900 ${
//                 bankMasterData ? "opacity-100 max-h-96" : "opacity-0 max-h-0"
//               }`}
//             >
//               <div className="my-2 -mt-4">
//                 {bankMasterData && (
//                   <div className="mt-4 flex flex-wrap gap-6  mt-1  p-3">

//                      <Card
//                       title="Bank Account Master"
//                       icon={<ImProfile />}
//                       onClick={() => handleCardClick("/dormant")}
//                     />

//                   </div>
//                 )}
//               </div>
//             </div>
//           </div>
//         </div> */}
//         <div className=" -mt-4">
//           {" "}
//           {/* Added margin top to separate sections */}
//           <div className="bg-muted  rounded-lg mb-4 ml-2">
//             <div
//               className="flex justify-between cursor-pointer border bg-sky-50 w-full  px-4 py-1"
//               onClick={ToggleMarketData}
//             >
//               <h2 className=" text-left text-md font-medium  text-muted-foreground">
//                 Market Data
//               </h2>
//               <span className="p-1">
//                 {marketDataOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
//               </span>
//             </div>
//             <div
//               className={`overflow-hidden transition-opacity duration-900 ${
//                 marketDataOpen ? "opacity-100 max-h-96" : "opacity-0 max-h-0"
//               }`}
//             >
//               <div className="my-2 -mt-4">
//                 {marketDataOpen && (
//                   <div className="mt-4 flex flex-wrap gap-6  mt-1  p-3">
//                     <Card
//                       title="Exchange Rate Type"
//                       icon={<FaExchangeAlt />}
//                       onClick={() => handleCardClick("/negative-balances")}
//                     />

//                      <Card
//                       title="Source"
//                       icon={<SourceCodeIcon/>}
//                       onClick={() => handleCardClick("/dormant")}
//                     />
//                      <Card
//                       title="Currency Rate"
//                       icon={<MdCurrencyYen />}
//                       onClick={() => handleCardClick("/dormant")}
//                     />
//                      <Card
//                       title="Uploads"
//                       icon={<LuUpload/>}
//                       onClick={() => handleCardClick("/dormant")}
//                     />

//                   </div>
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className=" -mt-4">
//           {" "}
//           {/* Added margin top to separate sections */}
//           <div className="bg-muted  rounded-lg mb-4 ml-2">
//             <div
//               className="flex justify-between cursor-pointer border bg-sky-50 w-full  px-4 py-1"
//               onClick={ToggleAccounts}
//             >
//               <h2 className=" text-left text-md font-medium  text-muted-foreground">
//                 Accounts
//               </h2>
//               <span className="p-1">
//                 {AccountsOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
//               </span>
//             </div>
//             <div
//               className={`overflow-hidden transition-opacity duration-900 ${
//                 AccountsOpen ? "opacity-100 max-h-96" : "opacity-0 max-h-0"
//               }`}
//             >
//               <div className="my-2 -mt-4">
//                 {AccountsOpen && (
//                   <div className="mt-4 flex flex-wrap gap-6  mt-1  p-3">

//                      <Card
//                       title="COA"
//                       icon={<MdOutlinePentagon/>}
//                       onClick={() => handleCardClick("/dormant")}
//                     />
//                      <Card
//                       title="Account Master"
//                       icon={<RiAccountBoxLine/>}
//                       onClick={() => handleCardClick("/dormant")}
//                     />
//                      <Card
//                       title="Accounting Code"
//                       icon={<PiFolderUser/>}
//                       onClick={() => handleCardClick("/dormant")}
//                     />
//                    <Card
//                       title="Accounting Period"
//                       icon={<FaUserClock/>}
//                       onClick={() => handleCardClick("/dormant")}
//                     />
//                     <Card
//                       title="Profit Cost"
//                       icon={<CiBank/>}
//                       onClick={() => handleCardClick("/negative-balances")}
//                     />
//                   </div>
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </div>

//   </>
// );
// }

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  GlobeIcon,
  MapIcon,
  LanguageIcon,
  SourceCodeIcon,
} from "../../../components/SVG/FigmaIcons";
import { LuCalendarDays, LuUpload } from "react-icons/lu";
import { HiOutlineCurrencyDollar } from "react-icons/hi";
import { CiBank } from "react-icons/ci";
import Dashboard from "../../../components/Dashboard";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import {
  MdCurrencyYen,
  MdOutlineGroups,
  MdOutlinePentagon,
} from "react-icons/md";
import { FaCodeBranch, FaUserClock } from "react-icons/fa6";
import { PiFolderUser } from "react-icons/pi";
import { RiAccountBoxLine } from "react-icons/ri";
import { FaExchangeAlt } from "react-icons/fa";
import MainHeader from "../../../components/MainHeader";
import { LiaUserClockSolid } from "react-icons/lia";
import { IoIosGitMerge } from "react-icons/io";
import { TbArrowsRightLeft } from "react-icons/tb";

export default function MasterDataComponent() {
  const [subMenus, setSubMenus] = useState([]);
  const [moduleAssignments, setModuleAssignments] = useState([]);
  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch user ID from session storage
    const fetchUserId = () => {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        setUserId(userDetail.id || null);
      }
    };

    fetchUserId();

    // Fetch sub-menu data
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/get-sub-menu`)
      .then((response) => {
        const filteredMenus = response.data.data.filter(
          (menu) => menu.mainMenuId === 2
        );
        setSubMenus(filteredMenus);
      })
      .catch((error) => console.error("Error fetching sub-menus:", error));

    // Fetch module assignment data
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/get-module-assignment`)
      .then((response) => {
        // Filter module assignments by userId
        const filteredAssignments = response.data.filter(
          (assignment) =>
            assignment.userId === String(userId) &&
            assignment.isChecked === true
        );
        setModuleAssignments(filteredAssignments);
      })
      .catch((error) =>
        console.error("Error fetching module assignments:", error)
      );
  }, [userId]);

  const handleCardClick = (route) => {
    navigate(route);
  };

  // Filter subMenus based on moduleAssignments
  const filteredSections = subMenus.filter((menu) => {
    return moduleAssignments.some(
      (assignment) => assignment.subMenuId === menu.id && assignment.isChecked
    );
  });

  // Define an order for submenus to control their display order
  const submenuOrder = [
    "Global Master Data",
    "Company & Bank Master Data",
    "Market data",
    "Accounts",
  ];

  // Sort the filteredSections by the defined submenuOrder
  const orderedSections = filteredSections.sort((a, b) => {
    return submenuOrder.indexOf(a.name) - submenuOrder.indexOf(b.name);
  });

  const sections = filteredSections.map((menu) => {
    let cards;

    if (menu.name === "Global Data") {
      cards = [
        {
          title: "Country",
          icon: <GlobeIcon />,
          onClick: () => handleCardClick("/2/master-data/1/country"),
        },
        {
          title: "State/Province",
          icon: <MapIcon />,
          onClick: () => handleCardClick("/2/master-data/2/state-province"),
        },
        {
          title: "Calendar",
          icon: <LuCalendarDays />,
          onClick: () => handleCardClick("/2/master-data/3/calendar"),
        },
        {
          title: "Currency",
          icon: <HiOutlineCurrencyDollar />,
          onClick: () => handleCardClick("/2/master-data/4/currency"),
        },
        {
          title: "Bank",
          icon: <CiBank />,
          onClick: () => handleCardClick("/2/master-data/5/bank"),
        },
        {
          title: "Language",
          icon: <LanguageIcon />,
          onClick: () => handleCardClick("/2/master-data/6/language"),
        },
      ];
    } else if (menu.name === "Company & Bank Data") {
      cards = [
        {
          title: "Legal Entity",
          icon: <HiOutlineBuildingOffice2 />,
          onClick: () => handleCardClick("/2/master-data/7/legalentity"),
        },
        {
          title: "BU Group ",
          icon: <MdOutlineGroups />,
          onClick: () => handleCardClick("/2/master-data/8/bugroup"),
        },
        {
          title: "Company",
          icon: <HiOutlineBuildingOffice2 />,
          onClick: () => handleCardClick("/2/master-data/9/company"),
        },
        {
          title: "Bank ID",
          icon: <CiBank />,
          onClick: () => handleCardClick("/2/master-data/10/bankidscreen"),
        },
        {
          title: "Bank Branch",
          icon: <IoIosGitMerge />,
          onClick: () => handleCardClick("/2/master-data/11/bankbranch"),
        },
      ];
    } else if (menu.name === "Market data") {
      cards = [
        {
          title: "MD Source",
          icon: <TbArrowsRightLeft />,
          onClick: () => handleCardClick("/2/master-data/12/md-source"),
        },
        {
          title: "Source",
          icon: <SourceCodeIcon />,
          onClick: () => handleCardClick("/2/master-data/13/source-code"),
        },
        {
          title: "Currency Rate",
          icon: <MdCurrencyYen />,
          onClick: () => handleCardClick("/2/master-data/14/currency-rate"),
        },
        {
          title: "Uploads",
          icon: <LuUpload />,
          onClick: () => handleCardClick("/2/master-data/15/market-data-uploads-add"),
        },
      ];
    } else if (menu.name === "Accounts") {
      cards = [
        {
          title: "COA",
          icon: <MdOutlinePentagon />,
          onClick: () => handleCardClick("/negative-balances"),
        },
        {
          title: "Account Master",
          icon: <RiAccountBoxLine />,
          onClick: () => handleCardClick("/negative-balances"),
        },
        {
          title: "Accounting Code",
          icon: <PiFolderUser />,
          onClick: () => handleCardClick("/negative-balances"),
        },
        {
          title: "Accounting Period",
          icon: <LiaUserClockSolid />,
          onClick: () => handleCardClick("/negative-balances"),
        },
        {
          title: "Profit Cost",
          icon: <CiBank />,
          onClick: () => handleCardClick("/negative-balances"),
        },
      ];
    } else {
      // Optionally handle other cases or default cards
      cards = [];
    }

    return {
      title: menu.name,
      subMenuId: menu.id,
      cards: cards,
    };
  });

  return (
    <>
      <div>
        <MainHeader title={"Primary Data"} />
        <Dashboard sections={sections} /> {/* Use the Dashboard component */}
      </div>
    </>
  );
}
