import React, { useEffect, useState } from 'react'
import Tabs from '../../../../../components/Tabs';
import Footer from '../../../../../components/footer';
import ButtonGroup from '../../../../../components/FooterButton';
import Header from '../../../../../components/Header';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import LegalEntityServices from '../../../../../rest-services/LegalEntityServices';
import { useToast } from "../../../../../components/toast/toast";
import { RxDropdownMenu } from 'react-icons/rx';
import CurrencyServicess from '../../../../../rest-services/CurrencyServices';
import CountryServices from '../../../../../rest-services/CountryServices';
import StateProvinceServices from '../../../../../rest-services/StateProvinceServices';
import { Checkbox } from "@mui/material";
import BankIdServices from '../../../../../rest-services/BankIdServices';
import BankBranchServices from '../../../../../rest-services/BankBranchServices';
import CustomButton from '../../../../../components/CustomButton';
import { TbFlag3Filled } from 'react-icons/tb';
import ReusableModal from '../../../../../components/PopupComponent';
import TableWrapper from '../../../../../components/ScrollBarComponent';

export default function BankBranchAdd() {
  const [activeTab, setActiveTab] = useState("addbankbranch");
  const navigate = useNavigate();
  const { bankbranchId } = useParams();
  const location = useLocation();
  const { mode } = location.state || {};
  const isViewMode = mode === 'view';
  const [errors, setErrors] = useState({}); // Validation errors
  const [assignee, setAssignee] = useState({ id: "", assigner: "" ,clientId:"" }); // State to store assignee
  const { toast } = useToast();
  const [alert, setAlert] = useState(null);
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const [bankBranch, setBankBranch] = useState("");
  const [branchName, setBranchName] = useState("");
  const [BankName, setBankName] = useState("");

  const [BankId, setBankId] = useState("");
  const [Bank, setBank] = useState("");
  const [bankidies, setbankIdies] = useState([]);
  const [countryCode, setCountryCode] = useState(""); // Country Code
  const [countryName, setCountryName] = useState("");
  const [district, setdistrict] = useState("");
  const [poBox, setpoBox] = useState("");
  const [state, setstate] = useState("");
  const [stateName, setStateName] = useState('');
  // const [countryCode, setcountryCode] = useState("");
  const [countryId, setCountryId] = useState('');
  const [areaBlock, setareaBlock] = useState("");
  const [roadNo, setroadNo] = useState("");
  const [roadName, setRoadName] = useState("");
  const [streetNo, setstreetNo] = useState("");
  const [buildingName, setbuildingName] = useState("");
  const [buildingNo, setbuildingNo] = useState("");
  const [doorNo, setdoorNo] = useState("");
  const [floorNo, setfloorNo] = useState("");
  const [addressLine1, setaddressLine1] = useState("");
  const [addressLine2, setaddressLine2] = useState("");
  const [addressLine3, setaddressLine3] = useState("");
  const [countries, setCountries] = useState([]);
  const [stateProvinces, setStateProvinces] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(countryCode || "");
  const [postalCode, setpostalCode] = useState("");
  const [bankBIC, setsetBankBIC] = useState("");
  const [country_Code, setCountry_Code] = useState("");
  const [countryCodeType, setCountryCodeType] = useState("");
  const [customerCode, setCustomerCode] = useState("");
  const [micr, setmicr] = useState("");
  const [bankCode, setbankCode] = useState("");
  const [purpose, setPurpose] = useState("");
  const [spocName, setSpocName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [alterNativeMobileNo, setAlterNativeMobileNo] = useState("");
  const [bankAccountMinLength, setBankAccountMinLength] = useState("");
  const [bankAccountMaxLength, setBankAccountMaxLength] = useState("");
  const [IBAN_required, setIbanRequired] = useState("");
  const [BranchCode, setBranchCode] = useState("");

  const [status, setStatus] = useState("");
  const [searchQueryState, setSearchQueryState] = useState(""); // State for search query
  const [selectedState, setSelectedState] = useState(state || "");

  const [streetName, setstreetName] = useState("");
  const [areaName, setareaName] = useState("");
  // const [cityName, setcityName] = useState("");
  const [landMark, setlandMark] = useState("");
  const [zipCode, setzipCode] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBankId, setSelectedBankId] = useState("");
  const [isModalOpenForState, setIsModalOpenForState] = useState(false);
  const [isCountryModalOpen, setIsCountryModalOpen] = useState(false);
  const [isCountryNameModalOpen, setIsCountryNameModalOpen] = useState(false);
  const [isBankidModalOpen, setIsBankidModalOpen] = useState(false);
  const [isStateModalOpen, setIsStateModalOpen] = useState(false);
  const [isStateNameModalOpen, setIsStateNameModalOpen] = useState(false);
  const [filteredStateProvinces, setFilteredStateProvinces] = useState([]);
  const [isAddressChecked, setIsAddressChecked] = useState(false);
  const [spocs, setSpocs] = useState([]);
  const [addingNewSpoc, setAddingNewSpoc] = useState(false);
  const [isValidationTriggered, setIsValidationTriggered] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isCopyMode, setIsCopyMode] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const isEditMode = !!bankbranchId;
  const [phase, setPhase] = useState("");
  const [sector, setSector] = useState("");
  const [village, setVillage] = useState("");
  const [town, setTown] = useState("");
  const [city, setCity] = useState("");

  useEffect(() => {
    fetchcountry();
    fetchbankid();
    fetchStateProvinces();
    fetchAssignee();
  }, []);
  useEffect(() => {
    if (location.state?.copiedData) {
      setIsCopyMode(true);
      const copiedData = location.state.copiedData;
      // Set address fields
      if (copiedData.addresses && copiedData.addresses.length > 0) {
        const address = copiedData.addresses[0]; // Use the first address
        setdoorNo(address.doorNo);
        setfloorNo(address.floorNo);
        setbuildingNo(address.buildingNo);
        setbuildingName(address.buildingName);
        setlandMark(address.landMark);
        setstreetNo(address.streetNo);
        setstreetName(address.streetName);
        setroadNo(address.roadName);
        setRoadName(address.roadName);
        setareaBlock(address.areaBlock);
        setareaName(address.areaName);
        setaddressLine1(address.addressLine1);
        setaddressLine2(address.addressLine2);
        setaddressLine3(address.addressLine3);
        setstate(address.state);
        setdistrict(address.district);
        setpostalCode(address.postalCode);
        setzipCode(address.zipCode);
        setpoBox(address.poBox);
        setPhase(address.phase);
        setSector(address.sector);
        setVillage(address.village);
        setTown(address.town);
        setCity(address.city);
        if (copiedData.BankId) {
          const selectedBankid = bankidies.find(
            (bank) => bank.bankID === copiedData.BankId
          );
        console.log("Matching BankId:", selectedBankid);
          if (selectedBankid) {
            setBankId(selectedBankid.bankID); // Set the legal entity code
            setBankName(selectedBankid.bankName); // Set the legal entity code
            setBank(selectedBankid.bank); // Set the legal entity code
            
            // Safely access countryCodeType
            const countryCodeType = selectedBankid.countrycode[0].countryCodeType;
            if (countryCodeType) {
              setCountryCodeType(countryCodeType); // Set the country code type
            } else {
              console.warn("Country code type not found in selectedBankid:", selectedBankid);
            }
        
            setSelectedBankId(selectedBankid.bankID); // Update UI-selected value
          } else {
            console.warn("Bank ID not found for:", copiedData.BankId);
          }
        }
        
        // Map country
        const selectedCountryData = countries.find(
          (country) => country.country === address.countryCode
        );
        if (selectedCountryData) {
          setSelectedCountry(selectedCountryData.country);
          setCountryCode(selectedCountryData.country);
          setCountryName(selectedCountryData.countryName);
        } else {
          console.warn('Country not found for code:', address.countryCode);
        }

        // Map state
        const selectedStateData = stateProvinces.find(
          (state) => state.state === address.state
        );
        if (selectedStateData) {
          setSelectedState(selectedStateData.state);
          setStateName(selectedStateData.State_Name);
          setstate(selectedStateData.state);
        } else {
          console.warn('State not found for code:', address.state);
        }
      } else {
        console.warn('No addresses found in copiedData.');
      }
      if (copiedData.identifier && copiedData.identifier.length > 0) {
        const identity = copiedData.identifier[0]; // Use the first address
        setsetBankBIC(identity.bankBIC);
        setCountryCodeType(identity.countryCodeType);
        setCustomerCode(identity.customerCode);
        setmicr(identity.micr);
        setbankCode(identity.bankCode);
        setBranchCode(identity.BranchCode);


      }

      if (copiedData.specificchecks && copiedData.specificchecks.length > 0) {
        const specific = copiedData.specificchecks[0]; // Use the first address
        setBankAccountMinLength(specific.bankAccountMinLength);
        setBankAccountMaxLength(specific.bankAccountMaxLength);
        setIbanRequired(specific.IBAN_required);
      }
      if (copiedData.branchspoc && copiedData.branchspoc.length > 0) {
        const branchspoc = copiedData.branchspoc || []; const spocsData = branchspoc.map(spoc => ({
          purpose: spoc.purpose,
          spocName: spoc.spocName,
          emailId: spoc.emailId,
          mobileNo: spoc.mobileNo,
          alterNativeMobileNo: spoc.alterNativeMobileNo,
        }));
        setSpocs(spocsData);
      }

      // Set other copied fields
      setBankBranch(copiedData.bankBranch || "");
      setBranchName(copiedData.branchName || "");



    } else if (bankbranchId) {
      getDataForEdit();
    }
    fetchAssignee();
  }, [bankidies,bankbranchId, countries, stateProvinces, location.state]);
  // useEffect(() => {
  //   if (bankbranchId) {
  //     getDataForEdit();
  //   }
  // }, [  bankbranchId,bankidies,countries,stateProvinces]);

 



  const getDataForEdit = async () => {
    try {
      console.log("test is test");
      const response = await BankBranchServices.getBankBranchById(bankbranchId);
      // const response = await axios.get(`http://localhost:5000/api/v1/get-bankbranch/${bankbranchId}`);


      const address = response.addresses[0];
      const identifier = response.identifier[0];
      const branchspoc = response.branchspoc || []; // Ensure it's an array, even if empty
      const specificc = response.specificchecks[0];

      console.log("Branch Name  eeeeeeee:", response.branchName); // Add for each key as needed
      console.log("branchhhhhhhh");
      // Existing data handling
      setBankBranch(response.bankBranch); // Adjust according to API response structure
      setBranchName(response.branchName || "");
      // setBankId(response.BankId);    
      const bankid = bankidies.find(c => c.bankID === response.BankId);
      console.log("bankid from response:", response.BankId);
      console.log("Available bankidies:", bankidies);
      
      if (bankid) {
        setSelectedBankId(bankid.bankID);
        setBankId(bankid.bankID);
        setBankName(bankid.bankName);
        setBank(bankid.bank);
      
        // Safely access the countryCodeType
        const countryCodeType = bankid.countrycode?.[0]?.countryCodeType;
        if (countryCodeType) {
          setCountryCodeType(countryCodeType);
        } else {
          console.error("Country code type not found for the selected bank.");
        }
      } else {
        console.error("Bank ID not found for the provided response.");
      }
      setCountryCode(response.countryCode);
      setStatus(response.status);  // Set country code
      setdoorNo(address.doorNo);
      setfloorNo(address.floorNo);
      setbuildingNo(address.buildingNo);
      setbuildingName(address.buildingName);
      setlandMark(address.landMark);
      setstreetNo(address.streetNo);
      setstreetName(address.streetName);
      setroadNo(address.roadName);
      setRoadName(address.roadName);
      setareaBlock(address.areaBlock);
      setareaName(address.areaName);
      setaddressLine1(address.addressLine1);
      setaddressLine2(address.addressLine2);
      setaddressLine3(address.addressLine3);
      setstate(address.state);
      setdistrict(address.district);
      setpostalCode(address.postalCode);
      setpoBox(address.poBox);
      setPhase(address.phase);
      setSector(address.sector);
      setVillage(address.village);
      setTown(address.town);
      setCity(address.city);
      setzipCode(address.zipCode);
      setsetBankBIC(identifier.bankBIC);
      setCountry_Code(identifier.country_Code);
      setCustomerCode(identifier.customerCode);
      setmicr(identifier.micr);
      setbankCode(identifier.bankCode);
      setBranchCode(identifier.BranchCode);
      const spocsData = branchspoc.map(spoc => ({
        purpose: spoc.purpose,
        spocName: spoc.spocName,
        emailId: spoc.emailId,
        mobileNo: spoc.mobileNo,
        alterNativeMobileNo: spoc.alterNativeMobileNo,
      }));
      setSpocs(spocsData);

      setBankAccountMinLength(specificc.bankAccountMinLength || 0); // Provide default value if needed
      setBankAccountMaxLength(specificc.bankAccountMaxLength || 0);
      setIbanRequired(specificc.IBAN_required); // Convert to boolean explicitly



     

      // Country lookup based on country code from response
      const country = countries.find(
        (c) => c.country === address.countryCode
      );
      console.log("country code from response:", address.countryCode);
      console.log("Available countries:", countries);
      if (countries) {
        setCountryCode(country.country);
        setCountryName(country.countryName); // Set currency name for display
        setSelectedCountry(country.countryName); // Set the selected currency based on currency name
        // Set the currencycoun code// Set the selected country based on countryName
      } else {
        console.error("Country not found for the provided country code.");
      }

      // for state
      const fetchedState = stateProvinces.find(c => c.state === address.state);
      console.log("State code from response:", address.state);
      console.log("Available states:", stateProvinces);
      console.log("the fetched console", fetchedState.State_Name
      );
      if (fetchedState) {
        setStateName(fetchedState.State_Name);  // Set state name for display
      } else {
        console.error("State not found for the provided state code:", response.state);
      }

      // Currency lookup based on currency code from response

    } catch (error) {
      console.error("Error fetching Bank Branch data:", error);
      setAlert({
        severity: "error",
        message: "Error fetching Bank Branch data.",
      });
    }
  };

  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.userId || "",
          clientId: userDetail.clientId || "",
        };
        setAssignee(assigneeData);
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };


  const fetchcountry = async () => {
    try {
      const response = await CurrencyServicess.getAllCalendar(`${process.env.REACT_APP_BASE_URL}/get-country`);
      console.log("Fetched countries:", response); // Add this log
      setCountries(response.data.data); // Ensure this is correct
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };
  const fetchStateProvinces = async () => {
    try {
      const response = await StateProvinceServices.getAllStateprovince(`${process.env.REACT_APP_BASE_URL}/get-stateprovince`);
      console.log("Fetched StateProvinces:", response); // Add this log
      setStateProvinces(response.data.data); // Ensure this is correct
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };
  const fetchbankid = async () => {
    try {
      const response = await BankIdServices.getAllBankId(`/get-all-bankid`);
      console.log("Fetched bankidies:", response); // Add this log
      setbankIdies(response.data.data || response.data); // Ensure this is correct
    } catch (error) {
      console.error("Error fetching bankidies:", error);
    }
  };
  const handleIbanRequiredChange = () => {
    setIbanRequired(IBAN_required === 1 ? 0 : 1);
    setUnsavedChanges(true);// Toggle between 1 and 0
  };
  const handleSubmit = async (e) => {
    setIsValidationTriggered(true);
    e.preventDefault();

    // Validate the form
    if (!validateForm()) return;
    try {
      // Skip duplication check for edit mode
      if (!bankbranchId || isCopyMode) {
        const duplicateCheckResponse = await BankBranchServices.checkDuplicatebankBranch(
          `${process.env.REACT_APP_BASE_URL}/getduplicatebankbranch`,
          { bankBranch: bankBranch }
        );

        if (duplicateCheckResponse.message === "bank branch code already exists") {
          toast("error", "Branch already exists.");
          setErrors((prev) => ({
            ...prev,
            bankBranch: "bank branch already exists.",
          }));
          return; // Stop further execution if duplicate exists
        }
      }
      // Ensure spocs is an array and map over it safely
      const spocData = (spocs || []).map(spoc => ({
        purpose: spoc.purpose,
        spocName: spoc.spocName,
        emailId: spoc.emailId,
        mobileNo: spoc.mobileNo,
        alterNativeMobileNo: spoc.alterNativeMobileNo,
      }));

      const parseIntSafe = (value) => {
        return value !== "" ? parseInt(value) : null; // Change to `0` if that's your default instead of `null`
      };

      const data = {
        id: isCopyMode ? null : bankbranchId, // Use 'id' for updates
        bankBranch,
        branchName,
        BankId,
        BankName,
        Bank,
        created_by: assignee.assigner,
        changed_by: assignee.assigner,
        doorNo,
        floorNo,
        buildingNo,
        buildingName,
        landMark,
        streetNo,
        streetName,
        roadNo,
        roadName,
        areaBlock,
        areaName,
        addressLine1,
        addressLine2,
        addressLine3,
        countryCode,
        state,
        district,
        postalCode,
        zipCode,
        poBox,
        phase,
        sector,
        village,
        town,
        city,
        bankBIC,
        country_Code,
        customerCode,
        micr,
        bankCode,
        BranchCode,
        spocs: spocData,
        bankAccountMinLength: parseIntSafe(bankAccountMinLength),
        bankAccountMaxLength: parseIntSafe(bankAccountMaxLength),
        IBAN_required,
      };


      let response;
      if (isCopyMode || !bankbranchId) {
        // Create a new bank branch
        response = await BankBranchServices.addBankBranch(
          `${process.env.REACT_APP_BASE_URL}/create-bankbranch`,
          data
        );
      } else {
        // Update existing bank branch
        response = await BankBranchServices.addBankBranch(
          `${process.env.REACT_APP_BASE_URL}/update-bankbranch`,
          data
        );
      }

      if (response && !response.error) {
        toast("success", bankbranchId && !isCopyMode ? "Bank Branch Updated successfully" : "Bank Branch Added successfully");

        // Clear the form fields
        setBankBranch("");
        setBranchName("");
        setBankId("");
        setBankName("");
        setBank("");
        setStateName("");
        setCountryCode("");
        setdoorNo("");
        setfloorNo("");
        setbuildingNo("");
        setbuildingName("");
        setlandMark("");
        setstreetNo("");
        setstreetName("");
        setroadNo("");
        setRoadName("");
        setareaBlock("");
        setareaName("");
        setaddressLine1("");
        setaddressLine2("");
        setaddressLine3("");
        setstate("");
        setdistrict("");
        setpostalCode("");
        setpoBox("");
        setPhase("");
        setSector("");
        setVillage("");
        setTown("");
        setCity("");
        setzipCode("");
        setCountryName("");
        setsetBankBIC("");
        setCountry_Code("");
        setCountryCodeType("");
        setCustomerCode("");
        setmicr("");
        setbankCode("");
        setBranchCode("");
        setSpocs([{
          purpose: "",
          spocName: "",
          emailId: "",
          mobileNo: "",
          alterNativeMobileNo: "",
          checked: false,
          emailError: "",
        }]);
        setBankAccountMinLength("");
        setBankAccountMaxLength("");
        setIbanRequired("");
        setCountryCode("");
        setCountryName("");
        setSelectedCountry("");
        setSelectedBankId("");
        setIsValidationTriggered(false);
        setIsAddressChecked(false);
        // Navigate if not saving and continuing

        navigate("/2/master-data/11/bankbranch");

      } else {
        // toast("error", "Required Fields are Missing.");
      }
    } catch (error) {
      console.error("Request error:", error);
      setAlert({
        severity: "error",
        message: `Error adding/updating Bank Branch: ${error.message}`,
      });
    }
  };
  const handleSaveandContinue = async (e) => {
    setIsValidationTriggered(true);
    e.preventDefault();

    // Validate the form
    if (!validateForm()) return;
    try {
      // Skip duplication check for edit mode
      if (!bankbranchId || isCopyMode) {
        const duplicateCheckResponse = await BankBranchServices.checkDuplicatebankBranch(
          `${process.env.REACT_APP_BASE_URL}/getduplicatebankbranch`,
          { bankBranch: bankBranch }
        );

        if (duplicateCheckResponse.message === "bank branch code already exists") {
          toast("error", "Branch already exists.");
          setErrors((prev) => ({
            ...prev,
            bankBranch: "bank branch already exists.",
          }));
          return; // Stop further execution if duplicate exists
        }
      }
      // Ensure spocs is an array and map over it safely
      const spocData = (spocs || []).map(spoc => ({
        purpose: spoc.purpose,
        spocName: spoc.spocName,
        emailId: spoc.emailId,
        mobileNo: spoc.mobileNo,
        alterNativeMobileNo: spoc.alterNativeMobileNo,
      }));

      const parseIntSafe = (value) => {
        return value !== "" ? parseInt(value) : null; // Change to `0` if that's your default instead of `null`
      };

      const data = {
        id: isCopyMode ? null : bankbranchId, // Use 'id' for updates
        bankBranch,
        branchName,
        BankId,
        BankName,
        Bank,
        created_by: assignee.assigner,
        changed_by: assignee.assigner,
        doorNo,
        floorNo,
        buildingNo,
        buildingName,
        landMark,
        streetNo,
        streetName,
        roadNo,
        roadName,
        areaBlock,
        areaName,
        addressLine1,
        addressLine2,
        addressLine3,
        countryCode,
        state,
        district,
        postalCode,
        zipCode,
        poBox,
        phase,
        sector,
        village,
        town,
        city,
        bankBIC,
        country_Code,
        customerCode,
        micr,
        bankCode,
        BranchCode,
        spocs: spocData,
        bankAccountMinLength: parseIntSafe(bankAccountMinLength),
        bankAccountMaxLength: parseIntSafe(bankAccountMaxLength),
        IBAN_required,
      };


      let response;
      if (isCopyMode || !bankbranchId) {
        // Create a new bank branch
        response = await BankBranchServices.addBankBranch(
          `${process.env.REACT_APP_BASE_URL}/create-bankbranch`,
          data
        );
      } else {
        // Update existing bank branch
        response = await BankBranchServices.addBankBranch(
          `${process.env.REACT_APP_BASE_URL}/update-bankbranch`,
          data
        );
      }

      if (response && !response.error) {
        toast("success", bankbranchId && !isCopyMode ? "Bank Branch Updated successfully" : "Bank Branch Added successfully");

        // Clear the form fields
        setBankBranch("");
        setBranchName("");
        setBankId("");
        setBankName("");
        setBank("");
        setStateName("");
        setCountryCode("");
        setdoorNo("");
        setfloorNo("");
        setbuildingNo("");
        setbuildingName("");
        setlandMark("");
        setstreetNo("");
        setstreetName("");
        setroadNo("");
        setRoadName("");
        setareaBlock("");
        setareaName("");
        setaddressLine1("");
        setaddressLine2("");
        setaddressLine3("");
        setstate("");
        setdistrict("");
        setpostalCode("");
        setpoBox("");
        setPhase("");
        setSector("");
        setVillage("");
        setTown("");
        setCity("");
        setzipCode("");
        setCountryName("");
        setsetBankBIC("");
        setCountry_Code("");
        setCountryCodeType("");
        setCustomerCode("");
        setmicr("");
        setbankCode("");
        setBranchCode("");
        setSpocs([{
          purpose: "",
          spocName: "",
          emailId: "",
          mobileNo: "",
          alterNativeMobileNo: "",
          checked: false,
          emailError: "",
        }]);
        setBankAccountMinLength("");
        setBankAccountMaxLength("");
        setIbanRequired("");
        setCountryCode("");
        setCountryName("");
        setSelectedCountry("");
        setSelectedBankId("");
        setIsValidationTriggered(false);
        setIsAddressChecked(false);
        // Navigate if not saving and continuing


      } else {
        // toast("error", "Required Fields are Missing.");
      }
    } catch (error) {
      console.error("Request error:", error);
      setAlert({
        severity: "error",
        message: `Error adding/updating Bank Branch: ${error.message}`,
      });
    }
  };


  const checkDuplicatebankbranch = async (code) => {
    try {
      const response = await BankBranchServices.checkDuplicatebankBranch(
        `/getduplicatebankbranch`,
        { bankBranch: code }  // Sending roleName to backend
      );

      console.log("Response from server:", response);

      if (response.message === "bank branch code already exists") {
        // Check if it's a duplicate
        console.log("Response from internal server:", response);
        setErrors({ bankBranch: " " });

        toast("error", "Branch already exists.");
      }
      console.log("Response from external server:", response);
    } catch (error) {
      console.error("Error checking duplicate bankBranch:", error);
      // Handle potential errors when making the API call
      setErrors((prev) => ({ ...prev, bankBranch: "Error checking role name" }));
    }
  };
  const handlebankbranchChange = (e) => {
    const code = e.target.value;
    setUnsavedChanges(true);
    setBankBranch(code);

    // Clear previous errors when typing
    setErrors(prev => ({ ...prev, bankBranch: "" }));


    if (code.trim() !== "") {
      checkDuplicatebankbranch(code);
    }
  };
  // Separate handler for country selection
  const handleCountryCheckBoxChange = (event) => {
    console.log(`this is the Bank Branch ${countries}`)

    const selectedName = event.target.value;
    setUnsavedChanges(true); // Get the selected country name
    setSelectedCountry(selectedName);

    const selectedCountryData = countries.find(country => country.country === selectedName);
    if (selectedCountryData) {
      setCountryCode(selectedCountryData.country); // Set the country code
      setCountryName(selectedCountryData.countryName); // Set the country name

      // Filter states by the selected country code
      const filteredStates = stateProvinces.filter(state => state.Country_Code === selectedCountryData.country);
      setFilteredStateProvinces(filteredStates); // Assuming you have state to hold the filtered states
    }
  };

  const handleRemoveSpoc = () => {
    const updatedSpocs = spocs.filter((spoc, index) => !spoc.checked);
    setSpocs(updatedSpocs);
  };
  // Separate handler for Bank Branch selection
  const handleBankidCheckBoxChange = (event) => {
    const selectedName = event.target.value;
    setUnsavedChanges(true); // Flag unsaved changes
    setSelectedBankId(selectedName); // Update the selected bank ID

    // Find the selected bank ID data
    const selectedBankidData = bankidies.find(bankid => bankid.bankID === selectedName);

    if (selectedBankidData) {
      setBankId(selectedBankidData.bankID); // Set the bank ID
      setBankName(selectedBankidData.bankName); // Set the bank name
      setBank(selectedBankidData.bank); // Set additional bank data

      // Safely get the countryCodeType
      const countryCodeType =
        selectedBankidData.countrycode?.[0]?.countryCodeType || "DefaultCountryCodeType";

      setCountryCodeType(countryCodeType); // Set the country code type
    }
  };
  const handleCheckboxChangeForState = (event) => {

    const selectedCode = event.target.value;
    setUnsavedChanges(true);
    console.log("selected State code", selectedCode);
    setSelectedState(selectedCode);
    setstate(selectedCode);

    const selectedCountryData = stateProvinces.find(stateP => stateP.state === selectedCode);
    console.log('selected state country data', selectedCountryData);
    if (selectedCountryData) {
      console.log('check selected country data', selectedCountryData.State_Name);
      setStateName(selectedCountryData.State_Name);
    }
  };
  const handleCheckBoxForAddress = (event) => {
    if (event.target.checked) {
      setUnsavedChanges(true);
      console.log("Selected bank:", BankId); // Check if lgeCode is being set properly

      // Find the Bank Branch by its LGE_Code
      const targetbank = bankidies.find((bank) => bank.bankID === BankId);

      console.log("Matching BankId:", targetbank);

      if (targetbank) {
        // Ensure you are accessing the addresses array
        if (targetbank.addresses && targetbank.addresses.length > 0) {
          const address = targetbank.addresses[0]; // Access the first address

          console.log("Address found:", address); // Log the address object

          // Set the address fields
          setdoorNo(address.doorNo || "");
          setfloorNo(address.floorNo || "");
          setbuildingNo(address.buildingNo || "");
          setbuildingName(address.buildingName || "");
          setlandMark(address.landMark || "");
          setstreetNo(address.streetNo || "");
          setstreetName(address.streetName || "");
          setroadNo(address.roadNo || "");
          setRoadName(address.roadName || "");
          setareaBlock(address.areaBlock || "");
          setareaName(address.areaName || "");
          setaddressLine1(address.addressLine1 || "");
          setaddressLine2(address.addressLine2 || "");
          setaddressLine3(address.addressLine3 || "");
          setCountryCode(address.countryCode || "");
          setstate(address.state || "");
          setdistrict(address.district || "");
          setpostalCode(address.postalCode || "");
          setzipCode(address.zipCode || "");
          setpoBox(address.poBox || "");

          // Fetch and set country details
          const selectedCountryData = countries.find(
            (country) => country.country === address.countryCode
          );

          if (selectedCountryData) {
            setCountryCode(selectedCountryData.country);
            setCountryName(selectedCountryData.countryName);

            // Filter states by the selected country code
            const filteredStates = stateProvinces.filter(
              (state) => state.Country_Code === selectedCountryData.country
            );
            setFilteredStateProvinces(filteredStates);
          }

          // Fetch and set state details
          const selectedStateData = stateProvinces.find(
            (state) => state.state === address.state
          );

          if (selectedStateData) {
            setStateName(selectedStateData.State_Name);
          }

        } else {
          console.error("No address available for the selected Bank Branch.");
        }
      } else {
        console.error("No matching Bank Branch found for the provided lgeCode");
      }
    } else {
      // Clear address fields if unchecked
      setdoorNo("");
      setfloorNo("");
      setbuildingNo("");
      setbuildingName("");
      setlandMark("");
      setstreetNo("");
      setstreetName("");
      setCountryCode("");
      setStateName("");
      setRoadName("");
      setareaBlock("");
      setareaName("");
      setaddressLine1("");
      setaddressLine2("");
      setaddressLine3("");
      setCountryCode("");
      setstate("");
      setdistrict("");
      setpostalCode("");
      setzipCode("");
      setpoBox("");


    }
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleSearchChangeState = (e) => {
    setSearchQueryState(e.target.value);
  };


  const validateForm = () => {
    const isAddressSectionFilled = addressLine1 || addressLine2 || addressLine3;
    const isBuildingSectionFilled = doorNo || floorNo || buildingNo || buildingName || landMark;
    const isStreetSectionFilled = streetNo || streetName || roadNo || roadName;
    const isAreaSectionFilled = areaBlock || areaName;
    const iscitySectionFilled =  city || town || village || sector ||  phase;
    const isBuildingStreetAreaSectionFilled = isBuildingSectionFilled || isStreetSectionFilled || isAreaSectionFilled || iscitySectionFilled;
    const newErrors = {};
    if (!bankBranch) newErrors.bankBranch = "bankBranch is required.";
    if (!branchName) newErrors.branchName = "branchName is required.";
    if (!BankId) newErrors.BankId = "BankId   is required.";
    if (!BankName) newErrors.BankName = " BankName is required.";
    if (!Bank) newErrors.Bank = " Bank is required.";
    if (!countryCode) newErrors.countryCode = " countryCode is required.";
    if (filteredStateProvinces.length > 0 && !state) {
      newErrors.state = "state is required.";
    }

    if (!isAddressSectionFilled && !isBuildingStreetAreaSectionFilled) {
      newErrors.addressSection = "Either Address Section or Building, Street, and Area Section must be entered";
    }


    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      toast("error", "Missing Required Fields");
    }
    return Object.keys(newErrors).length === 0;
  };
  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === "Company & Bank Data") {
      navigate("/2/master-data");
    } else if (crumb.title === "Branch") {
      navigate("/2/master-data/11/bankbranch");
    } else if (crumb.title === "Primary Data") {
      navigate("/2/master-data");
    } else if (crumb.path) {
      navigate(crumb.path);
    }
  };
  const toggleModalForState = () => {
    setIsModalOpenForState(!isModalOpenForState);

  }

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const tabs = [
    { value: "addbankbranch", label: "Bank Branch" },
    { value: "branchaddress", label: "Branch address" },
    { value: "identifiers", label: "Identifiers" },
    { value: "banchspoc", label: "Branch Spoc" },
    { value: "bankspecificchecks", label: "Bank Specific Checks" },



  ];
  const handleReset = () => {
    setSearchQuery(''); // Reset search query
    setSelectedBankId(""); // Reset selected bank ID
    setBankName("");
    setBank("")
  };
  const errorss = {
    addbankbranch: !bankBranch || !branchName || !BankId, // Replace with your validation logic
    branchaddress: !addressLine1 && !addressLine2 && !addressLine3 && !doorNo && !floorNo && !buildingNo && !buildingName && !landMark && !streetNo && !streetName && !roadNo && !roadName && !areaBlock && !areaName && !countryCode && !countryName && !phase  && !sector && !village && !town  && !city && !state && !stateName ,

  };
  const handleAddNewSpoc = () => {
    const newSpoc = {
      purpose: "",
      spocName: "",
      emailId: "",
      mobileNo: "",
      alterNativeMobileNo: "",
      checked: false,
      emailError: "" // Initialize emailError
    };
    setSpocs([...spocs, newSpoc]);
    setAddingNewSpoc(true);
  };

  // Function to handle saving the SPOC
  const handleSaveSpoc = () => {
    setAddingNewSpoc(false);
  };

  // Function to handle input changes
  const handleSpocChange = (index, field, value) => {
    setUnsavedChanges(true);
    setSpocs(prevSpocs => {
      const updatedSpocs = [...prevSpocs];
      updatedSpocs[index] = { ...updatedSpocs[index], [field]: value };
      return updatedSpocs;
    });
  };
  const nextToAddress = () => {
    setActiveTab("branchaddress")

  }
  const nextToIdentifiers = () => {
    setActiveTab("identifiers")

  }
  const nextToBranchSpoc = () => {
    setActiveTab("banchspoc")

  }
  const nextToSpecific = () => {
    setActiveTab("bankspecificchecks")

  }
  const getApprovedIcon = () => {
    let color = "black"; // Default color
    // Determine the color based on the approved status
    if (status === 4) {
      color = "#29CB00"; // Green
    } else if (status === 0) {
      color = "gray"; // Gray
    } else if (status === 1) {
      color = "#AF52DE"; 
    } else if (status === 2) {
      color = "yellow"; 
    } else if (status === 3) {
      color = "orange"; 
    }
    else if (status === 5) {
      color = "red"; // Red
    }

    return <TbFlag3Filled style={{ color, marginLeft: '8px' }} />; // Return the icon with the determined color
  };
  const handleCountryReset = () => {
    setSearchQuery(''); // Reset search query
    setSelectedCountry(""); // Reset selected bank ID
    setCountryCode("");
    setCountryName("");
    setstate("");
    setStateName("")
  };
  const handleCountryNameReset = () => {
    setSearchQuery(''); // Reset search query
    setSelectedCountry(""); // Reset selected bank ID
    setCountryCode("");
    setCountryName("");
    setstate("");
    setStateName("")
  };
  const handleStateReset = () => {
    setSearchQueryState(''); // Reset search query
    setSelectedState(""); // Reset selected bank ID
    setstate("");
    setStateName("")
  };
  const handleStateNameReset = () => {
    setSearchQueryState(''); // Reset search query
    setSelectedState(""); // Reset selected bank ID
    setstate("");
    setStateName("")
  };
  const handleRefreshbank = async () => {
    try {
      await fetchbankid();  // Fetch Branchs
    } catch (error) {
      console.error('Error reloading Branchs:', error);
    } finally {
    }
  };
  const handleRefreshcountry = async () => {
    try {
      await fetchcountry();  // Fetch Branchs
    } catch (error) {
     
    } finally {
    }
  };
  const handleRefreshstate = async () => {
    try {
      await fetchStateProvinces();  // Fetch Branchs
    } catch (error) {
      console.error('Error reloading Branchs:', error);
    } finally {
    }
  };
  return (
    <div>
    <Header
  title={`Primary Data > Company & Bank Data > Branch > ${
    isCopyMode 
      ? 'Add' 
      : bankbranchId 
        ? (isViewMode ? "View" : "Edit") 
        : "Add"
  }${bankbranchId && (isViewMode || !isCopyMode) ? ` > ${bankBranch}` : ''}`}
  onBreadcrumbClick={handleBreadcrumbClick}
  extraContent={bankbranchId && !isCopyMode ? getApprovedIcon() : null}
  hasUnsavedChanges={unsavedChanges}
  backRoute={"/2/master-data/11/bankbranch"}
/>
            <Tabs tabs={tabs} activeTab={activeTab} onTabClick={handleTabClick} errors={errorss} isValidationTriggered={isValidationTriggered}/>
            <div className="flex-grow">
        {activeTab === "addbankbranch" && (
          <div className="m-6 border border-gray-200 p-5  rounded-lg">

            <div className="flex flex-col space-y-8 col-span-1 p-6">
            <div className="grid grid-cols-2 gap-2">
              <label className="flex items-center">
                <span className="w-48 text-sm font-small text-customGray">Bank Branch<span className="text-red-500 ml-1">*</span></span>
                <span className="ml-4">:</span>
                <input
                  type="text"
                  value={bankBranch}
                  maxLength={10}

                  onChange={handlebankbranchChange}
                  className={`border text-customGray  ${errors.bankBranch ? "border-red-500" : "border-gray-300"} p-1.5 rounded w-80 h-8 focus:outline-none text-sm font-small text-customGray hover:border-blue-400 ml-8 `}
                  required
                  disabled={((isEditMode && !isCopyMode) || isViewMode)}
                />
              </label>

              <label className="flex items-center ml-6">
                <span className="w-48 text-sm font-small text-customGray">Branch Name<span className="text-red-500 ml-1">*</span></span>
                <span className="ml-4">:</span>
                <input
                  type="text"
                  value={branchName}
                  maxLength={150}

                  onChange={(e) => {
                    setBranchName(e.target.value);
                    setUnsavedChanges(true);
                    if (errors.branchName) {
                      setErrors(prevErrors => ({ ...prevErrors, branchName: "" }));
                    }
                  }}
                  className={`border text-customGray ${errors.branchName ? "border-red-500" : "border-gray-300"} p-1.5 rounded w-80 h-8 focus:outline-none text-sm font-small text-customGray hover:border-blue-400 ml-8 `}
                  required
                  disabled={isViewMode}
                />
              </label>
              </div>
              <div className="grid grid-cols-2 gap-2">
              <div className="flex items-center">
                <label className="w-48 text-sm font-small text-customGray" disabled={isViewMode}>Bank ID <span className="text-red-500 ml-1">*</span></label>
                <span className="ml-4">:</span>
                <button
                  onClick={() => setIsBankidModalOpen(true)}
                  disabled={isViewMode}

                  className={`border border-gray-300 p-1 disabled:bg-[#FAFAFA] rounded w-80 h-8 focus:outline-none text-sm font-small text-customGray hover:border-blue-400 ml-8 text-left flex items-center justify-between  ${errors.BankId ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
                >
                  <span className='text-customGray'>{selectedBankId || 'Select Bank ID'}</span>
                  <RxDropdownMenu className="ml-2" />
                </button>

                <ReusableModal
                  title="Select Bank id "
                  isOpen={isBankidModalOpen}
                  onClose={() => {
                    setIsBankidModalOpen(false);
                    setSearchQuery('');
                  }}
                  onSave={() => {
                    setIsBankidModalOpen(false);
                    setSearchQuery('');
                  }}
                  searchPlaceholder="Search bankid..."
                  searchQuery={searchQuery}
                  showReset={true}
                  onReset={handleReset}
                  onSearchChange={handleSearchChange}
                  isViewMode={isViewMode}
                >
                  {Array.isArray(bankidies) && bankidies.length > 0 ? (
                    bankidies
                      .filter((bank) =>
                        bank.status === 4 &&
                        bank.bankID &&
                        bank.bankID.trim() &&
                        bank.bankID.toLowerCase().includes(searchQuery.toLowerCase())
                      )
                      .sort((a, b) => a.bankID.localeCompare(b.bankID)) // Sort in alphabetical order
                      .map((bankid, index) => (
                        <div key={bankid.id} className={`flex items-center ${index % 2 === 0 ? 'bg-sky-blue-200' : ''}`}>
                          <input
                            type="radio"
                            name="Country Name"
                            value={bankid.bankID}
                            checked={selectedBankId === bankid.bankID}
                            onChange={handleBankidCheckBoxChange}
                            className="mr-2 accent-customBlue"
                            disabled={isViewMode}
                          />
                          <label>{bankid.bankID}</label>
                        </div>
                      ))
                  ) : (
                    <p>Loading bank id...</p>
                  )}
                </ReusableModal>

              </div>

              <div className="flex items-center ml-6">
                <label className="w-48 text-sm font-small text-customGray">Bank Name</label>
                <span className="ml-4">:</span>
                <input
                  type="text"
                  value={BankName}
                  readOnly
                  disabled={isViewMode}
                  className="border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none text-sm font-small text-customGray ml-8 text-customGray"
                />



              </div>
              </div>
              <div className="grid grid-cols-2 gap-2">
              <div className="flex items-center">
                <label className="w-48 text-sm font-small text-customGray">Bank</label>
                <span className="ml-4">:</span>
                <input
                  type="text"
                  value={Bank}
                  disabled={isViewMode}
                  readOnly
                  className="border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none text-sm font-small text-customGray ml-8 text-customGray"
                />



              </div>
              {selectedBankId && !isViewMode && (
                <div className="flex ml-6">
                  <input
                    type='checkbox'
                    checked={isAddressChecked} // Control the checkbox with state
                    className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded accent-customBlue"

                    onChange={(event) => {
                      setIsAddressChecked(event.target.checked); // Update the checkbox state
                      handleCheckBoxForAddress(event); // Call your existing function
                    }}
                  />


                  <p className="p-4 text-sm font-small text-customGray">
                    Copy the Bank Id Address
                  </p>

                </div>
              )}
              {/* <CustomButton onClick={nextToAddress} title={"Next"}/> */}
              {/* {(!isViewMode && (
  <button
          onClick={handleSubmit}
          className="mt-4 bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16"
        >
          Save
        </button>
))} */}
              {/* <CustomButton onClick={nextToAddress} title={"Next"} /> */}
            </div>
            </div>
          </div>

        )}

        {activeTab === "branchaddress" && (
          <div className="m-6 border border-gray-200 p-5  rounded-lg">
            <TableWrapper maxHeight="70vh">
              <div className="p-1">
                {/* Building Section */}
                <div className="mb-6 border border-gray-300 p-5 rounded-lg">
                  <h3 className="text-md font-medium text-customGray mb-4">Building</h3>

                  <div className="grid grid-cols-2 gap-4">
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Door No </label>
                      <span className="ml-4">:</span>
                      <input type="text"
                        value={doorNo}
                        maxLength={5}

                        onChange={(e) => {
                          setdoorNo(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        disabled={isViewMode}
                        className="border border-gray-300 p-1.5 rounded w-80 h-8  focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray font-small text-customGray text-sm" />
                    </div>
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Floor No </label>
                      <span className="ml-4">:</span>
                      <input type="text"
                        value={floorNo}
                        maxLength={5}

                        disabled={isViewMode}
                        onChange={(e) => {
                          setfloorNo(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        placeholder="4" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm font-small text-customGray text-sm " />
                    </div>
                  </div>


                  <div className="grid grid-cols-2 gap-4 mt-4">
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Building No </label>
                      <span className="ml-4">:</span>
                      <input type="text"
                        value={buildingNo}
                        maxLength={5}

                        disabled={isViewMode}
                        onChange={(e) => {
                          setbuildingNo(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        placeholder="2A" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm font-small text-customGray text-sm" />
                    </div>
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Building Name </label>
                      <span className="ml-4">:</span>
                      <input type="text"
                        value={buildingName}
                        maxLength={50}

                        disabled={isViewMode}
                        onChange={(e) => {
                          setbuildingName(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        placeholder="B name" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm font-small text-customGray text-sm" />
                    </div>
                  </div>

                  <div className="flex items-center mt-4">
                    <label className="w-48 font-small text-customGray text-sm">Landmark</label>
                    <span className="ml-4">:</span>
                    <input type="text"
                      value={landMark}
                      maxLength={100}

                      onChange={(e) => {
                        setlandMark(e.target.value)
                        setUnsavedChanges(true);
                      }}
                      disabled={isViewMode}
                      placeholder="" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm" />
                  </div>
                </div>

                {/* Street Section */}
                <div className="mb-6 border border-gray-300 p-5 rounded-lg">
                  <h3 className="text-md font-medium text-customGray  mb-4">Street</h3>

                  <div className="grid grid-cols-2 gap-4">
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Street No </label>
                      <span className="ml-4">:</span>
                      <input
                        value={streetNo}
                        maxLength={5}

                        onChange={(e) => {
                          setstreetNo(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        disabled={isViewMode}

                        type="text" placeholder="2/626" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray" />
                    </div>
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Street Name</label>
                      <span className="ml-4">:</span>
                      <input type="text" value={streetName} maxLength={30}
                        disabled={isViewMode} onChange={(e) => {
                          setstreetName(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray" />
                      {/* {errors.streetName && (
                    <p className="text-red-500 text-sm ml-4">{errors.streetName}</p>
                  )} */}
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-4 mt-4">
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Road No </label>
                      <span className="ml-4">:</span>
                      <input type="text"
                        value={roadNo}
                        maxLength={5}

                        onChange={(e) => {
                          setroadNo(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        disabled={isViewMode}
                        placeholder="2A" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray" />
                    </div>
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Road Name </label>
                      <span className="ml-4">:</span>
                      <input
                        type="text"
                        value={roadName}
                        maxLength={30}

                        onChange={(e) => {
                          setRoadName(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        placeholder="B name"
                        disabled={isViewMode}
                        className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray"
                      />
                    </div>
                  </div>
                </div>

                {/* Area Section */}
                <div className="mb-6 border border-gray-300 p-5 rounded-lg">
                  <h3 className="text-md font-medium text-customGray  mb-4">Area</h3>

                  <div className="grid grid-cols-2 gap-4">
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Block </label>
                      <span className="ml-4">:</span>
                      <input type="text"
                        value={areaBlock}
                        maxLength={5}

                        onChange={(e) => {
                          setareaBlock(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        placeholder="2/626" disabled={isViewMode} className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray" />
                    </div>
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Area Name </label>
                      <span className="ml-4">:</span>
                      <input type="text"
                        value={areaName}
                        maxLength={30}

                        onChange={(e) => {
                          setareaName(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        placeholder="4" disabled={isViewMode} className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray" />
                    </div>
                  </div>
                </div>
                {/* Display the error message for address section */}
              
                <div className="mb-6 border border-gray-300 p-5 rounded-lg">
                  <h3 className="text-md font-medium text-customGray mb-4">City</h3>

                  <div className="grid grid-cols-2 gap-4">
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Phase </label>
                      <span className="ml-4">:</span>
                      <input type="text"
                        value={phase}
                        maxLength={5}

                        onChange={(e) => {
                          setPhase(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        disabled={isViewMode}
                        className="border border-gray-300 p-1.5 rounded w-80 h-8  focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray font-small text-customGray text-sm" />
                    </div>
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Sector </label>
                      <span className="ml-4">:</span>
                      <input type="text"
                        value={sector}
                        maxLength={5}

                        disabled={isViewMode}
                        onChange={(e) => {
                          setSector(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        placeholder="4" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm font-small text-customGray text-sm " />
                    </div>
                  </div>


                  <div className="grid grid-cols-2 gap-4 mt-4">
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Village </label>
                      <span className="ml-4">:</span>
                      <input type="text"
                        value={village}
                        maxLength={5}

                        disabled={isViewMode}
                        onChange={(e) => {
                          setVillage(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        placeholder="2A" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm font-small text-customGray text-sm" />
                    </div>
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Town </label>
                      <span className="ml-4">:</span>
                      <input type="text"
                        value={town}
                        maxLength={50}

                        disabled={isViewMode}
                        onChange={(e) => {
                          setTown(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        placeholder="B name" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm font-small text-customGray text-sm" />
                    </div>
                  </div>

                  <div className="flex items-center mt-4">
                    <label className="w-48 font-small text-customGray text-sm">City</label>
                    <span className="ml-4">:</span>
                    <input type="text"
                      value={city}
                      maxLength={100}

                      onChange={(e) => {
                        setCity(e.target.value)
                        setUnsavedChanges(true);
                      }}
                      disabled={isViewMode}
                      placeholder="" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm" />
                  </div>
                </div>
                {errors.addressSection && (
                  <p className="text-red-500 text-sm mb-4">{errors.addressSection}</p>
                )}
                {/* Address Section */}
                <div className="mb-6 border border-gray-300 p-5 rounded-lg">
                  <h3 className="text-md font-medium text-customGray  mb-4">Address</h3>
                  <div className="grid grid-cols-1 gap-4">
                    <div className="flex items-center">
                      <label className="w-56 font-small text-customGray text-sm">Address 1 </label>
                      <span className="ml-4">:</span>
                      <input type="text" value={addressLine1} onChange={(e) => {
                        setaddressLine1(e.target.value)
                        setUnsavedChanges(true);
                      }}
                        placeholder="2/626" maxLength={100}
                        disabled={isViewMode} className="border border-gray-300 p-1.5 rounded w-full h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray"
                      />

                      {/* {errors.addressLine1 && (
                    <p className="text-red-500 text-sm ml-4">
                      {errors.addressLine1}
                    </p>
                  )} */}
                    </div>
                    {/* <div className="flex items-center">
                <label className="w-48 font-small text-customGray text-sm">Address 2 </label>
                <span className="ml-4">:</span>
                <input type="text"  value={addressLine2}  onChange={(e) => setaddressLine2(e.target.value)}
                placeholder="4" className="border border-gray-300 p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm" />
              </div> */}
                  </div>

                  <div className="grid grid-cols-1 gap-4 mt-4">
                    <div className="flex items-center">
                      <label className="w-56 font-small text-customGray text-sm">Address 2 </label>
                      <span className="ml-4">:</span>
                      <input type="text" value={addressLine2} onChange={(e) => {
                        setaddressLine2(e.target.value)
                        setUnsavedChanges(true);
                      }}
                        placeholder="4" maxLength={100}
                        disabled={isViewMode} className="border border-gray-300 p-1.5 rounded w-full h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray" />
                    </div>

                  </div>

                  <div className="grid grid-cols-1 gap-4 mt-4">
                    <div className="flex items-center">

                      <label className="w-56 font-small text-customGray text-sm">Address 3 </label>
                      <span className="ml-4">:</span>
                      <input type="text" value={addressLine3} onChange={(e) => {
                        setaddressLine3(e.target.value)
                        setUnsavedChanges(true);
                      }}
                        placeholder="2A" maxLength={100}
                        disabled={isViewMode} className="border border-gray-300 p-1.5 rounded w-full h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray" />
                    </div>

                  </div>
                </div>


                {/* Country Section */}
                <div className="mb-6 border border-gray-300 p-5 rounded-lg">
                  <h3 className="text-md font-medium text-customGray  mb-4">
                    Country
                  </h3>

                  <div className="grid grid-cols-2 gap-4">
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">
                        Country Code{" "}
                        <span className="text-red-500 ml-1">*</span>
                      </label>
                      <span className="ml-4">:</span>
                      <button
                        onClick={() => setIsCountryModalOpen(true)}
                        disabled={isViewMode}
                        className={`border border-gray-300 p-1 disabled:bg-[#FAFAFA] rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 text-left text-customGray flex items-center justify-between ${errors.countryCode
                            ? "border-red-500 hover:border-red-500"
                            : "border-gray-300 hover:border-blue-400"
                          }`}
                      >
                        <span className="font-small text-customGray text-sm">
                          {countryCode || "Select Country Code"}
                        </span>
                        <RxDropdownMenu className="ml-2" />
                      </button>

                      <ReusableModal
                        title="Select Country Code"
                        isOpen={isCountryModalOpen}
                        onClose={() => {
                          setIsCountryModalOpen(false);
                          setSearchQuery("");
                        }}
                        onSave={() => {
                          setIsCountryModalOpen(false);
                          setSearchQuery("");
                        }}
                        showReset={true}
                        onReset={handleCountryReset}
                        searchPlaceholder="Search country..."
                        searchQuery={searchQuery}
                        onRefresh={handleRefreshcountry}
                        onSearchChange={handleSearchChange}
                        isViewMode={isViewMode}
                      >
                        {countries.length > 0 ? (
                          countries
                            .filter((country) =>
                              country.country
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase())
                            )
                            .sort((a, b) => a.country.localeCompare(b.country)) // Sort in alphabetical order

                            .map((country) => (
                              <div
                                key={country.id}
                                className="flex items-center"
                              >
                                <input
                                  type="radio"
                                  name="country"
                                  value={country.country}
                                  checked={selectedCountry === country.country}
                                  onChange={handleCountryCheckBoxChange}
                                  className="mr-2 accent-customBlue"
                                  disabled={isViewMode}
                                />
                                <label>{country.country}</label>
                              </div>
                            ))
                        ) : (
                          <p>Loading countries...</p>
                        )}
                      </ReusableModal>
                    </div>
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">
                        Country Name{" "}
                        <span className="text-red-500 ml-1">*</span>
                      </label>
                      <span className="ml-4">:</span>
                      <button
                        onClick={() => setIsCountryNameModalOpen(true)}
                        disabled={isViewMode}
                        className={`border border-gray-300 p-1 disabled:bg-[#FAFAFA] rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 text-left text-customGray flex items-center justify-between ${errors.countryName
                            ? "border-red-500 hover:border-red-500"
                            : "border-gray-300 hover:border-blue-400"
                          }`}
                      >
                        <span className="font-small text-customGray text-sm">
                          {countryName || "Select Country Name"}
                        </span>
                        <RxDropdownMenu className="ml-2" />
                      </button>

                      <ReusableModal
                        title="Select Country Code"
                        isOpen={isCountryNameModalOpen}
                        onClose={() => {
                          setIsCountryNameModalOpen(false);
                          setSearchQuery("");
                        }}
                        onSave={() => {
                          setIsCountryNameModalOpen(false);
                          setSearchQuery("");
                        }}
                        showReset={true}
                        onReset={handleCountryNameReset}
                        searchPlaceholder="Search countryname..."
                        searchQuery={searchQuery}
                        onRefresh={handleRefreshcountry}
                        onSearchChange={handleSearchChange}
                        isViewMode={isViewMode}
                      >
                        {countries.length > 0 ? (
                          countries
                            .filter((country) =>
                              country.countryName
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase())
                            )
                            .sort((a, b) => a.countryName.localeCompare(b.countryName)) // Sort in alphabetical order

                            .map((country) => (
                              <div
                                key={country.id}
                                className="flex items-center"
                              >
                                <input
                                  type="radio"
                                  name="country"
                                  value={country.country}
                                  checked={selectedCountry === country.country}
                                  onChange={handleCountryCheckBoxChange}
                                  className="mr-2 accent-customBlue"
                                  disabled={isViewMode}
                                />
                                <label>{country.countryName}</label>
                              </div>
                            ))
                        ) : (
                          <p>Loading countries...</p>
                        )}
                      </ReusableModal>
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-4 mt-4">
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">
                        State / Province{" "}
                        <span className="text-red-500 ml-1">*</span>
                      </label>
                      <span className="ml-4">:</span>
                      <button
                        onClick={() => setIsStateModalOpen(true)}
                        disabled={isViewMode}
                        className={`border border-gray-300 p-1 disabled:bg-[#FAFAFA] rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 text-left flex items-center justify-between text-customGray ${errors.state
                            ? "border-red-500 hover:border-red-500"
                            : "border-gray-300 hover:border-blue-400"
                          }`}
                      >
                        <span className="font-small text-customGray text-sm">
                          {state || "Select State / Province"}
                        </span>
                        <RxDropdownMenu className="ml-2" />
                      </button>

                      <ReusableModal
                        title="Select State Code"
                        isOpen={isStateModalOpen}
                        onClose={() => {
                          setIsStateModalOpen(false);
                          setSearchQueryState("");
                        }}
                        onSave={() => {
                          setIsStateModalOpen(false);
                          setSearchQueryState("");
                        }}
                        showReset={true}
                        onReset={handleStateReset}
                        searchPlaceholder="Search state..."
                        searchQuery={searchQueryState}
                        onRefresh={handleRefreshstate}
                        onSearchChange={handleSearchChangeState}
                        isViewMode={isViewMode}
                      >
                        {filteredStateProvinces.length > 0 ? (
                          filteredStateProvinces
                            .filter(
                              (stateP) =>

                                stateP.state
                                  .toLowerCase()
                                  .includes(searchQueryState.toLowerCase())
                            )
                            .sort((a, b) => a.state.localeCompare(b.state)) // Sort in alphabetical order

                            .map((stateP, index) => (
                              <div
                                key={stateP.id}
                                className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""
                                  }`}
                              >
                                <input
                                  type="radio"
                                  name="country"
                                  value={stateP.state}
                                  checked={selectedState === stateP.state}
                                  onChange={handleCheckboxChangeForState}
                                  className="mr-2 accent-customBlue"
                                  disabled={isViewMode}
                                />
                                <label>{stateP.state}</label>
                              </div>
                            ))
                        ) : (
                          <p>Loading State...</p>
                        )}
                      </ReusableModal>
                    </div>

                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">
                        State / Province Name{" "}
                        <span className="text-red-500 ml-1">*</span>
                      </label>
                      <span className="ml-4">:</span>
                      <button
                        onClick={() => setIsStateNameModalOpen(true)}
                        disabled={isViewMode}
                        className={`border border-gray-300 p-1 disabled:bg-[#FAFAFA] rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 text-left flex items-center justify-between text-customGray ${errors.state
                            ? "border-red-500 hover:border-red-500"
                            : "border-gray-300 hover:border-blue-400"
                          }`}
                      >
                        <span className="font-small text-customGray text-sm">
                          {stateName || "Select State / Province Name"}
                        </span>
                        <RxDropdownMenu className="ml-2" />
                      </button>

                      <ReusableModal
                        title="Select State Code"
                        isOpen={isStateNameModalOpen}
                        onClose={() => {
                          setIsStateNameModalOpen(false);
                          setSearchQueryState("");
                        }}
                        onSave={() => {
                          setIsStateNameModalOpen(false);
                          setSearchQueryState("");
                        }}
                        showReset={true}
                        onReset={handleStateNameReset}
                        searchPlaceholder="Search statename..."
                        searchQuery={searchQueryState}
                        onRefresh={handleRefreshstate}
                        onSearchChange={handleSearchChangeState}
                        isViewMode={isViewMode}
                      >
                        {filteredStateProvinces.length > 0 ? (
                          filteredStateProvinces
                            .filter(
                              (stateP) =>
                                stateP.State_Name.toLowerCase().includes(
                                  searchQueryState.toLowerCase()
                                ) ||
                                stateP.state
                                  .toLowerCase()
                                  .includes(searchQueryState.toLowerCase())
                            )
                            .sort((a, b) => a.State_Name.localeCompare(b.State_Name)) // Sort in alphabetical order

                            .map((stateP, index) => (
                              <div
                                key={stateP.id}
                                className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""
                                  }`}
                              >
                                <input
                                  type="radio"
                                  name="country"
                                  value={stateP.state}
                                  checked={selectedState === stateP.state}
                                  onChange={handleCheckboxChangeForState}
                                  className="mr-2 accent-customBlue"
                                  disabled={isViewMode}
                                />
                                <label>{stateP.State_Name}</label>
                              </div>
                            ))
                        ) : (
                          <p>Loading State...</p>
                        )}
                      </ReusableModal>
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-4 mt-4">
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">District</label>
                      <span className="ml-4">:</span>
                      <input type="text"
                        value={district}
                        maxLength={30}

                        onChange={(e) => {
                          setdistrict(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        disabled={isViewMode}
                        placeholder="2A"
                        className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray" />
                    </div>
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Postal Code</label>
                      <span className="ml-4">:</span>
                      <input type="text"
                        value={postalCode}
                        maxLength={10}

                        onChange={(e) => {
                          setpostalCode(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        disabled={isViewMode}
                        placeholder="B name" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray" />
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-4 mt-4">
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">Zip Code</label>
                      <span className="ml-4">:</span>
                      <input type="text"
                        value={zipCode}
                        maxLength={10}

                        onChange={(e) => {
                          setzipCode(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        disabled={isViewMode}
                        placeholder="2A" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray" />
                    </div>

                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">PO Box</label>
                      <span className="ml-4">:</span>
                      <input type="text"
                        value={poBox}
                        maxLength={10}

                        onChange={(e) => {
                          setpoBox(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        disabled={isViewMode}

                        placeholder="B name" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray" />
                    </div>

                  </div>

                </div>

                {/* <CustomButton onClick={nextToIdentifiers} title={"Next"} className={"mb-10"}/> */}
              </div>
            </TableWrapper>
          </div>
        )}
        {activeTab === "identifiers" && (
          <div className="m-6 border border-gray-200 p-5  rounded-lg">

            <div className="flex flex-col space-y-8 col-span-1 p-6">
              <div className=" space-y-8">
                {/* First row: Single field (LGE) */}
                <div className="flex items-center">
                  <label className="flex items-center w-full">
                    <span className="w-48 text-sm font-small text-customGray">Bank BIC</span>
                    <span className="ml-4">:</span>
                    <input
                      type="text"
                      value={bankBIC}
                      maxLength={11}

                      onChange={(e) => {
                        const value = e.target.value;
                        setUnsavedChanges(true);
                        // Allow typing but validate length
                        if (value.length <= 11) {
                          setsetBankBIC(value); // Update state
                        }
                      }}
                      onBlur={() => {
                        // Show error if length is not 8 or 11
                        if (bankBIC.length !== 8 && bankBIC.length !== 11) {
                          setErrorMessage("Bank BIC must be exactly 8 or 11 characters.");
                        } else {
                          setErrorMessage(""); // Clear error if valid
                        }
                      }}
                      className={`border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none text-sm text-sm font-small text-customGray hover:border-blue-400 ml-4 ${errorMessage ? "border-red-500" : ""
                        }`}
                      placeholder="Enter Bank BIC"
                      disabled={isViewMode}
                    />
                  </label>
                  {errorMessage && (
                    <p className="text-red-500 text-sm mt-1">{errorMessage}</p>
                  )}
                </div>

                {/* Second row: Two fields (LGE Code and LGE Description) */}
                <div className="grid grid-cols-2 gap-8">
                  <div className="flex items-center">
                    <label className="flex items-center w-full">
                      <span className="w-48 text-sm font-small text-customGray">
                        Bank Country Code
                      </span>
                      <span className="ml-4">:</span>
                      <input
                        type="text"
                        maxLength={12}

                        value={country_Code}
                        onChange={(e) => {
                          setCountry_Code(e.target.value);
                          setUnsavedChanges(true);
                        }}
                        className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none text-sm text-sm font-small text-customGray hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 text-customGray"

                        disabled={isViewMode}
                      />
                    </label>
                  </div>

                  <div className="flex items-center">
                    <label className="flex items-center w-full">
                      <span className="w-48 text-sm font-small text-customGray">
                        Country Code Type
                      </span>
                      <span className="ml-4">:</span>
                      <input
                        type="text"
                        maxLength={12}

                        value={countryCodeType}
                      
                        className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none text-sm text-sm font-small text-customGray hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 text-customGray"

                        disabled={true}
                      />
                    </label>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-8">
                  <div className="flex items-center">
                    <label className="flex items-center w-full">
                      <span className="w-48 text-sm font-small text-customGray">
                        Customer Code
                      </span>
                      <span className="ml-4">:</span>
                      <input
                        type="text"
                        maxLength={12}

                        value={customerCode}
                        onChange={(e) => {
                          setCustomerCode(e.target.value);
                          setUnsavedChanges(true);
                        }}
                        className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none text-sm text-sm font-small text-customGray hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 text-customGray"

                        disabled={isViewMode}
                      />
                    </label>
                  </div>

                  <div className="flex items-center">
                    <label className="flex items-center w-full">
                      <span className="w-48 text-sm font-small text-customGray">
                        MICR
                      </span>
                      <span className="ml-4">:</span>
                      <input
                        type="text"
                        maxLength={12}

                        value={micr}
                        onChange={(e) => {
                          setmicr(e.target.value);
                          setUnsavedChanges(true);
                        }}
                        className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none text-sm text-sm font-small text-customGray hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 text-customGray"

                        disabled={isViewMode}
                      />
                    </label>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-8">
                  <div className="flex items-center">
                    <label className="flex items-center w-full">
                      <span className="w-48 text-sm font-small text-customGray">
                        Bank Code
                      </span>
                      <span className="ml-4">:</span>
                      <input
                        type="text"
                        maxLength={12}

                        value={bankCode}
                        onChange={(e) => {
                          setbankCode(e.target.value);
                          setUnsavedChanges(true);
                        }}
                        className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none text-sm text-sm font-small text-customGray hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 text-customGray"

                        disabled={isViewMode}
                      />
                    </label>
                  </div>

                  <div className="flex items-center">
                    <label className="flex items-center w-full">
                      <span className="w-48 text-sm font-small text-customGray">
                        Branch Code
                      </span>
                      <span className="ml-4">:</span>
                      <input
                        type="text"
                        maxLength={12}

                        value={BranchCode}
                        onChange={(e) => {
                          setBranchCode(e.target.value);
                          setUnsavedChanges(true);
                        }}
                        className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none text-sm text-sm font-small text-customGray hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 text-customGray"

                        disabled={isViewMode}
                      />
                    </label>
                  </div>
                </div>
              </div>
              {/* <CustomButton onClick={nextToBranchSpoc} title={"Next"}/> */}
            </div>
          </div>
        )}
        {activeTab === "banchspoc" && (
          <div className="m-6 border border-gray-200 p-5 rounded-lg">
            <div className="flex flex-col space-y-8 col-span-1 p-6">
              <table className="table-fixed min-w- bg-white border border-customBlue" style={{ maxWidth: "1000px" }}>
                <thead className="bg-customBlue text-primary-foreground text-center text-sm font-small text-white">
                  <tr>
                    <th className="text-center text-white text-sm font-small border-r border-customTableBorder relative whitespace-nowrap bg-customBlue">
                      <input type="checkbox" className='accent-customBlue' />
                    </th>
                    <th>Purpose</th>
                    <th>Spoc Name</th>
                    <th>Email ID</th>
                    <th>Mobile No</th>
                    <th>Alternative Mobile No</th>
                  </tr>
                </thead>
                <tbody>
                  {spocs.map((spoc, index) => (
                    <tr key={index}>
                      <td className="text-center border-r border-customTableBorder">
                        <input
                          type="checkbox"
                          checked={spoc.checked}
                          disabled={isViewMode}
                          onChange={() => handleSpocChange(index, "checked", !spoc.checked)}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          disabled={isViewMode}
                          maxLength={25}
                          value={spoc.purpose}
                          onChange={(e) => handleSpocChange(index, "purpose", e.target.value)}
                          className="p-2 bg-gray-100 border border-gray-300 rounded-lg w-48 h-8 text-sm font-small text-customGray"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          disabled={isViewMode}
                          maxLength={50}
                          value={spoc.spocName}
                          onChange={(e) => handleSpocChange(index, "spocName", e.target.value)}
                          className="p-2 bg-gray-100 border border-gray-300 rounded-lg w-48 h-8 text-sm font-small text-customGray"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={spoc.emailId}
                          disabled={isViewMode}
                          maxLength={80}
                          onChange={(e) => {
                            const email = e.target.value;
                            let errorMessage = "";
                            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
                            if (email.length > 80) {
                              errorMessage = "Email must be 80 characters or less.";
                            } else if (email && !emailRegex.test(email)) {
                              errorMessage = "Enter Valid Email";
                            }
                            handleSpocChange(index, "emailId", email);
                            handleSpocChange(index, "emailError", errorMessage);
                          }}
                          className="p-2 bg-gray-100 border border-gray-300 rounded-lg w-48 h-8 text-sm font-small text-customGray"
                        />
                        {spoc.emailError && <p className="text-red-500 text-sm mt-1">{spoc.emailError}</p>}
                      </td>
                      <td>
                        <input
                          type="text"
                          value={spoc.mobileNo}
                          disabled={isViewMode}
                          maxLength={13}
                          onChange={(e) => handleSpocChange(index, "mobileNo", e.target.value)}
                          className="p-2 bg-gray-100 border border-gray-300 rounded-lg w-48 h-8 text-sm font-small text-customGray"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={spoc.alterNativeMobileNo}
                          disabled={isViewMode}
                          maxLength={13}
                          onChange={(e) => handleSpocChange(index, "alterNativeMobileNo", e.target.value)}
                          className="p-2 bg-gray-100 border border-gray-300 rounded-lg w-48 h-8 text-sm font-small text-customGray"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="flex space-x-4">
                {!addingNewSpoc && !isViewMode && (
                  <button onClick={handleAddNewSpoc} className="text-customBlue">
                    Add New
                  </button>
                )}
                {addingNewSpoc && !isViewMode && (
                  <button onClick={handleSaveSpoc} className="text-customBlue">
                    Save
                  </button>
                )}
                {spocs.some((spoc) => spoc.checked) && !isViewMode && (
                  <button
                    onClick={handleRemoveSpoc}
                    className="text-customBlue"
                  >
                    Remove
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
        {activeTab === "bankspecificchecks" && (
          <div className="m-6 border border-gray-200 p-5  rounded-lg">

            <div className="flex flex-col space-y-8 col-span-1 p-6">
              <div className="grid grid-cols-2">
                <div className="flex items-center">
                  <label className="flex items-center w-full">
                    <span className="text-sm font-small text-customGray whitespace-nowrap">
                      Bank Account Number Length
                    </span>
                    <span className="ml-4">:</span>
                  </label>
                  <label className="flex items-center">
                    <span className="text-sm font-small text-customGray ml-8">Min</span>
                    <span className="ml-4">:</span>
                    <input
                      type="number"
                      value={bankAccountMinLength}
                      
                      onChange={(e) => {
        const value = Math.min(Math.max(0, parseInt(e.target.value)), 24); // Ensure value is between 0 and 25
        setBankAccountMinLength(value);
        setUnsavedChanges(true);
      }}
                      className="border border-gray-300 p-1.5 rounded w-20 h-8 text-customGray focus:outline-none text-sm text-sm font-small text-customGray hover:border-blue-400 ml-4 mr-4"
                      disabled={isViewMode}
                       min="0"
      max="25"
                    />
                  </label>

                  <label className="flex items-center">
                    <span className="text-sm font-small text-customGray ml-8">Max</span>
                    <span className="ml-4">:</span>
                    <input
                      type="number"

                      value={bankAccountMaxLength !== null && bankAccountMaxLength !== undefined ? bankAccountMaxLength : ""}
                      placeholder="25"
    onChange={(e) => {
      const value = Math.min(Math.max(0, parseInt(e.target.value) ), 25); // Ensure value is between 0 and 25
      setBankAccountMaxLength(value);
      setUnsavedChanges(true);
    }}
                      className="border border-gray-300 p-1.5 rounded w-20 h-8 text-customGray focus:outline-none text-sm text-sm font-small text-customGray hover:border-blue-400 ml-4 mr-4"
                      disabled={isViewMode}
                      min="0"
                      max="25" 
                    />
                  </label>
                </div>

                {/* IBAN Required Checkbox */}
                <div className="flex items-center mt-4 col-span-2">
                  <label className="flex items-center w-full">
                    <span className="text-sm font-small text-customGray whitespace-nowrap">IBAN Required</span>
                    <input
                      type='checkbox'
                      checked={IBAN_required === 1}
                      onChange={handleIbanRequiredChange}
                      className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded accent-customBlue"
                      disabled={isViewMode}
                    />
                  </label>
                </div>

              </div>
            </div>
          </div>
        )}
      </div>
      <Footer>
        {/* Footer */}
        {(!isViewMode &&
          <ButtonGroup
            onSave={handleSubmit}
            onSaveContinue={handleSaveandContinue} hasId={!!bankbranchId}
          />
        )}
      </Footer>
    </div>
  )
}

