import React from "react";
import { BiRefresh } from "react-icons/bi";
import CustomButton from "./CustomButton";
import styled from "styled-components";
import Draggable from "react-draggable"; // Import Draggable

const TableWrapper = styled.div`
  overflow-y: auto;
  max-height: 300px;

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #8aa6c1;
    border-radius: 6px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #6b8db2;
  }

  ::-webkit-scrollbar-track {
    background-color: #f0f4f8;
    border-radius: 6px;
  }

  scrollbar-width: thin;
  scrollbar-color: #8aa6c1 #f0f4f8;
`;

const ReusableModal = ({
  title = "",
  isOpen,
  onClose,
  onSave,
  onReset,
  onRefresh,
  showReset = false,
  children = [],
  searchPlaceholder = "Search...",
  searchQuery,
  onSearchChange,
  isViewMode,
}) => {
  if (!isOpen) return null;

  const rows = [];
  const columnsPerRow = 4;
  for (let i = 0; i < children.length; i += columnsPerRow) {
    rows.push(children.slice(i, i + columnsPerRow));
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <Draggable handle=".modal-header">
        <div className="bg-white p-0 w-[800px] h-[400px] flex flex-col rounded-2xl overflow-hidden absolute">
          {/* Header */}
          <div className="modal-header flex justify-between items-center p-4 shadow bg-white sticky top-0 z-10 cursor-move">
            <div className="flex items-center">
              <h2 className="text-lg text-customBlue">{title}</h2>
              <button
                onClick={onRefresh}
                className="ml-4 text-customBlue hover:text-blue-600"
                title="Refresh"
              >
                <BiRefresh size={20} />
              </button>
            </div>
            <input
              type="text"
              placeholder={searchPlaceholder}
              value={searchQuery}
              onChange={onSearchChange}
              disabled={isViewMode}
              className="border border-gray-300 p-1 rounded w-60 ml-60 placeholder-customGray placeholder-text-sm hover:border-customBlue text-customGray focus:outline-none font-small text-sm"
            />
            <button
              onClick={onClose}
              className="text-customBlue text-2xl mr-4"
            >
              &times;
            </button>
          </div>

          {/* Body */}
          <TableWrapper className="flex-1 flex-col items-center justify-center">
            {children.length > 0 ? (
              rows.map((row, rowIndex) => {
                const isEvenRow = (rowIndex + 1) % 2 === 0;

                return (
                  <div
                    key={rowIndex}
                    className={`grid grid-cols-4 gap-4 p-2 ${
                      isEvenRow ? "bg-sky-50" : ""
                    }`}
                  >
                    {row.map((child, columnIndex) => (
                      <div
                        key={columnIndex}
                        className="rounded  text-customGray text-sm font-small"
                        style={{
                wordWrap: "break-word",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
                      >
                        {child}
                      </div>
                    ))}
                  </div>
                );
              })
            ) : (
              <div className="flex items-center justify-center h-full text-customGray text-sm font-small">
                No data available
              </div>
            )}
          </TableWrapper>

          {/* Footer */}
          <div className="flex justify-end p-2 bg-white sticky bottom-0 border-t">
            {!isViewMode && (
              <CustomButton
                onClick={onSave}
                title="Select"
                className="mr-4"
                showSecondaryButton={showReset}
                secondaryOnClick={onReset}
                secondaryTitle="Reset"
                secondaryClassName="mr-5"
              />
            )}
          </div>
        </div>
      </Draggable>
    </div>
  );
};

export default ReusableModal;
