import React, { useState, useEffect } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import HoverButton from "./HoverButton";
import CustomButton from "./CustomButton";

const Header = ({
  title = "",
  onBreadcrumbClick,
  extraContent,
  hasUnsavedChanges,
  backRoute, // New prop for dynamic back navigation
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [nextPath, setNextPath] = useState(null);
  const [clientId, setClientId] = useState(null);

  useEffect(() => {
    const fetchAssignee = () => {
      try {
        const userDetailString = localStorage.getItem("userDetail");
        if (userDetailString) {
          const userDetail = JSON.parse(userDetailString);
          setClientId(userDetail.clientId || null); // Set clientId from session storage
        } else {
          console.error("No user detail found in session storage.");
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchAssignee();
  }, []); // Fetch clientId on component mount

  const breadcrumbs = title ? title.split(" > ") : [];

  const getBreadcrumbPath = (breadcrumb) => {
    const routes = {
      "Primary Data": "/2/master-data",
      "Company & Bank Data": "/2/master-data",
      "Bank ID": "/2/master-data/10/bankidscreen",
      "Branch": "/2/master-data/11/bankbranch",
      "Bu Group": "/2/master-data/8/bugroup",
      "Company": "/2/master-data/9/company",
      "Legal Entity": "/2/master-data/7/legalentity",
      "Global Data": "/2/master-data",
      "Bank": "/2/master-data/5/bank",
      "Calendar": "/2/master-data/3/calendar",
      "Country": "/2/master-data/1/country",
      "Currency": "/2/master-data/4/currency",
      "Language": "/2/master-data/6/language",
      "State/Province": "/2/master-data/2/state-province",
      "User": "/14/admin/108/userscreen",
      "User & SOD": "/14/admin",
      "Upload": "/14/admin/108/user-upload",
      "Admin": "/14/admin",
      "Licenses": "/14/admin",
      "Maker & Checker": "/14/admin/110/maker-and-checker",
      "Roles": "/14/admin/111/roles-and-permission/roles",
      "Permissions": "/14/admin/112/roles-and-permission",
      "Password Policy": "/14/admin/passwordscreen/113",
      "User Types": "/14/admin/user-type-screen/115",
      "Module Assignments": "/14/admin/module-assignment/114",
      "Statment Analyser": "/add-statement-analyser",
      "MT940": "/bank-statements",
      "Bank Statements": "/bank-statements",
      "Bank Statements": "/add-statement-analyser-bs",
      "Manage Statement Controlls": "/manage-statement-controlls",     
       "Market Data": "/2/master-data",
      "MD Source": "/2/master-data/12/md-source",
      "Source Code": "/2/master-data/13/source-code",
      "Currency table":"/2/master-data/14/currency-rate",
      "Uploads":"/2/master-data/15/market-data-uploads-add",

    };
    return routes[breadcrumb] || location.pathname; // Fallback to current path if no match
  };

  const handleBreadcrumbClick = (breadcrumb) => {
    const crumb = {
      title: breadcrumb,
      path: getBreadcrumbPath(breadcrumb),
    };

    if (hasUnsavedChanges) {
      setNextPath(crumb.path);
      setIsModalOpen(true);
    } else {
      onBreadcrumbClick(crumb);
      const currentMode = location.state?.mode || "edit"; // Default to "edit" if no mode is present
      navigate(crumb.path, { state: { mode: currentMode } });
    }
  };

  const handleNavigateBack = () => {
    if (hasUnsavedChanges) {
      setNextPath(backRoute || -1); // Use backRoute if available
      setIsModalOpen(true);
    } else {
      backRoute ? navigate(backRoute) : navigate(-1);
    }
  };

  const confirmNavigation = () => {
    setIsModalOpen(false);
    if (nextPath === -1) {
      navigate(-1);
    } else if (nextPath) {
      navigate(nextPath);
    }
  };

  const cancelNavigation = () => {
    setIsModalOpen(false);
    setNextPath(null);
  };

  return (
    <>
      <header className="bg-white text-primary-foreground p-4 flex items-center space-x-2 shadow-md shadow-blue-100 mb-2 ml-2">
        {/* Back Arrow with dynamic route */}
        <FaArrowLeft
          style={{ color: "#0B5299" }}
          onClick={handleNavigateBack}
          className="cursor-pointer text-customBlue mr-4"
        />
        <div className="flex space-x-2 items-center flex-grow">
          {breadcrumbs.map((breadcrumb, index) => {
            const isLast = index === breadcrumbs.length - 1;

            return (
              <span key={breadcrumb} className="flex items-center">
                <span
                  className={`text-xl font-small cursor-pointer-center transition-all duration-200 hover:text-customBlue hover:text-lg ${isLast ? "text-customBlue" : "text-[#0B5299]"
                    }`}
                  onClick={() => handleBreadcrumbClick(breadcrumb)}
                >
                  {breadcrumb}
                </span>
                {!isLast && <span className="mx-2 text-gray-500">{">"}</span>}
              </span>
            );
          })}
          {/* Render any extra content after breadcrumbs */}
          {extraContent && <span>{extraContent}</span>}
        </div>
        {/* Render Client ID at the far end */}
        {clientId && (
          <div className="ml-4 text-md font-small text-customBlue">
            Client ID : {clientId}
          </div>
        )}
      </header>

      {/* Confirmation Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded shadow-md w-96 text-center">
            <h2 className="text-md font-small mb-4 text-customBlue">
              Unsaved Changes
            </h2>
            <p className="mb-6 text-customGray">
              You haven't saved your changes, Do you want to continue?
            </p>
            <div className="flex justify-center space-x-4">
              <CustomButton title={"Yes"} onClick={confirmNavigation} />
              <HoverButton
                className="w-16"
                title={"No"}
                onClick={cancelNavigation}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
