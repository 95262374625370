import React, { useEffect, useState } from "react";
import { IoMdCheckmark, IoMdClose, IoMdCreate, IoMdTrash } from "react-icons/io";
import { VscVmActive } from "react-icons/vsc";
import UserService from "../../../../rest-services/UserServices";
import { BsTags, BsUbuntu } from "react-icons/bs";
import { LuCopyCheck, LuKeyRound } from "react-icons/lu";
import { MdOutlineHistory, MdOutlineLock } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { FiBox } from "react-icons/fi";
import { PiCopy } from "react-icons/pi";
import { GiSettingsKnobs } from "react-icons/gi";

const BAMActions = ({
  buttons = [],
  extraButtons = [],
  // isExtraButtonsVisible = false,
  selectedCount,
  selectIds, 
  icons,
  hasFiltersApplied

}) => {

  const [rolesPermissions, setRolesPermissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [assignee, setAssignee] = useState({ id: "", username: "" });
  const[alert,setAlert] = useState("");
  const [error, setError] = useState(null);
  const [areAllButtonsDisabled, setAreAllButtonsDisabled] = useState(false);
  const [accountDetails, SetaccountDetails] = useState([]);
  const [accountDetailsCompanyId, SetaccountDetailsCompanyId] = useState([]);
  const [accountDetailsBankAccountID, SetaccountDetailsBankAccountID] = useState([]);
  const [accountDetailsBAMStatus, SetaccountDetailsBAMStatus] = useState([]);
    const [MakerCheker, setMakerChecker] = useState("");
  

  const iconId = parseInt(icons, 10);

  
  useEffect(() => {
    fetchAssignee();
  fetchIconsData();
}, []);

//   useEffect(() => {
//     if (selectIds && selectIds.length > 0) {
//       console.log("fetched aray users", selectIds);
//   fetchUserPreferenceDetails(icons);
// }
// }, [selectIds, icons]);


  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.username || "",
          assignerId: userDetail.userId || "",
          clientId: userDetail.clientId || "",
        };

        setAssignee(assigneeData);
        // Log the id and assigner values to the console
        console.log("Assignee ID:", assigneeData.id);
        console.log("Assigner:", assigneeData.assigner);
        console.log("Assigner userId:", assigneeData.assignerId);
        console.log("Assigner clientId:", assigneeData.clientId);
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

    useEffect(() => {
      if (assignee.assignerId) {
        fetchUserPermissionDetails();
      }
    }, [assignee.assignerId]);


     useEffect(() => {
        if (selectIds && selectIds.length > 0) {
          console.log("fetched aray details", selectIds);
          fetchArrayAccountDetails(selectIds);
        }
      }, [selectIds, icons]);

    const fetchArrayAccountDetails = async (ids) => {
      try {
        // Prepare the request data
        // const requestData = ids.map((id) => ({ id })); // Transforming to the desired format
        console.log("Request Data from account detail ids actions:", ids); // Debugging the request data
  
        const response = await UserService.saveContactDetails(
          `${process.env.REACT_APP_BASE_URL}/bam/get-array-account-details`,
          { ids } // Send the correctly formatted request data
        );
  
        console.log("API Response from admin AccountDetail actions data:", response.data);
        const companyIDs = response.data.map((item) => item.companyID || ""); // Default to an empty string if null/undefined
    const bankAccountIDs = response.data.map((item) => item.bankAccountId || ""); // Default to an empty string if null/undefined
    const BAMstatus = response.data.map((item) => item.status || 0 ); // Default to an empty string if null/undefined


        console.log("Extracted companyIDs from response:", companyIDs);
        console.log("Extracted bankAccountIDs from response:", bankAccountIDs);
        console.log("Extracted BAMstatus from response:", BAMstatus);


        SetaccountDetails(response.data);
        SetaccountDetailsCompanyId(companyIDs);
        SetaccountDetailsBankAccountID(bankAccountIDs);
        SetaccountDetailsBAMStatus(BAMstatus);
        // Handle the API response as needed, e.g., update state or notify user
      } catch (error) {
        console.error("Error fetching user details:", error);
        // Optionally handle the error state
      }
    };


const fetchUserPermissionDetails = async () => {
setLoading(true);
try {
const data = { userId: assignee.assignerId };
console.log("Request Data:", data);

const response = await UserService.saveContactDetails(
  `${process.env.REACT_APP_BASE_URL}/get-approved-users-permissions-by-userId`,
  data
);

const responseData = response.data.data;
setRolesPermissions(responseData.rolesPermissionsTables || []); // Save permission details
console.log("Set roles & permission details", responseData);
} catch (error) {
setError(error.message); // Set error message
} finally {
setLoading(false); // Reset loading state
}
};

const fetchIconsData = async () => {
  try {

    const response = await UserService.getUsers(`${process.env.REACT_APP_BASE_URL}/get-icons-data-maker-checker-checked`);
    console.log("Response from server fetching BAM M&C:", response);
    const responseData = response.data.data;
    setMakerChecker(responseData);


    console.log("double check users", response.data.data);
    if (responseData.error) {
      console.error("Error fetching Users data:", responseData.message);
      setAlert({ severity: "error", message: responseData.message });
      return;
    }
  } catch (error) {
    console.error("Error fetching Users data", error);
    setAlert({ severity: "error", message: `Error fetching Users data, kindly check the connection ${error.message}` });
  }
};

   const getIconColor = (icon,disabled) => {
      if (disabled) return "text-gray-300"; // Gray color for disabled state
  
      switch (icon) {
        case IoMdCheckmark:
          return "text-green-500";
        case IoMdClose:
          return "text-red-500";
        case IoMdTrash:
          return "text-red-500";
        case VscVmActive:
          return "text-green-500";
           case GiSettingsKnobs: // Update filter icon color based on state
                  return hasFiltersApplied ? 'text-orange-600' : 'text-sky-900';
        default:
          return "text-sky-900";
      }
    };

  // const getIconSize = (disabled) => {
  //   return disabled ? "text-3xl" : "text-2xl"; // Reduced size for disabled state
  // };

//   const getButtonTitle = (button) => {
//     if(
//       isApproveButtonDisabled(button) ||
//       isDeleteButtonDisabled(button) ||
//       isChangesAccNoUpdate(button) ||
//       isChangesAccMaster(button) ||
//       isCheckCompapnyCodeId(button)||
//       isCheckStatusChange(button) 
//       // isTriggerOTPButtonDisabled(button)
//       )
//       {
//         // return MakerCheker === 1 ? "Maker & Checker is Active" : "Action Disabled";
// console.log("maker and checker data is ",MakerCheker);

//       // Check if Maker & Checker is active for id 27
//         // Check if Maker & Checker is active on BAM NEW REQUEST
//       const isBAMMakerCheckerEnabled = (MakerCheker || []).some(
//         (mChecker) => mChecker.id === 27 && mChecker.makeAndChecker === 1
//       );
      
//       const isBAMMakerCheckerEnabled2 = (MakerCheker || []).some(
//         (mChecker) => mChecker.id === 28 && mChecker.makeAndChecker === 1
//       );

//       const  isBAMMakerCheckerEnabled3 = (MakerCheker || []).some(
//         (mChecker) => mChecker.id === 133 && mChecker.makeAndChecker === 1
//       );
//       // (4) Account Company code change 
//       const  isBAMMakerCheckerEnabled4 = (MakerCheker || []).some(
//         (mChecker) => mChecker.id === 29 && mChecker.makeAndChecker === 1
//       );
      
//       if (isBAMMakerCheckerEnabled || isBAMMakerCheckerEnabled2 || isBAMMakerCheckerEnabled3 || isBAMMakerCheckerEnabled4) {
//          if (button.icon === IoMdCheckmark || button.icon === IoMdClose) {
//             return "Maker & Checker is Active";
//         }
//         else{
//           return "Action Disabled";
//         }
//         // return isActive ? "Maker & Checker is Active on New Request" : "Action Disabled";
//       }
//       else{
//         return "Action Disabled";
//       }

//       }
//     else if (
//       shouldDisableDueToPermission(button)       
//     ) {
//       return "You Don't have Permission";
//     }
//     return button.title;
//   };


const getButtonTitle = (button) => {
  const isMakerCheckerActive = (MakerCheker || []).some(
    (mChecker) => [27, 28, 133, 29].includes(mChecker.id) && mChecker.makeAndChecker === 1
  );

  if (
    isApproveButtonDisabled(button) ||
    isDeleteButtonDisabled(button) ||
    isChangesAccNoUpdate(button) ||
    isChangesAccMaster(button) ||
    isCheckCompapnyCodeId(button) ||
    isCheckStatusChange(button)
  ) {
    console.log("Maker and Checker data is", MakerCheker);

    if (isMakerCheckerActive) {
      console.log("Maker & Checker for BAM initial approval is active.");

      const isValidAccountStatus = (accountDetails || []).some(
        (acct) =>
          (acct.changed_by !== null && assignee.assignerId === acct.changed_by ) ||
          assignee.assignerId === acct.created_by
      );

      console.log("isValidAccountStatus is", isValidAccountStatus);

      if (isValidAccountStatus) {
        console.log("maker & checker isValidAccountStatus is true");
        return "Maker & Checker is Active";
      } else {
        console.log("maker & checker isValidAccountStatus is false");
        return button.title;
      }
    }

    return "Action Disabled"; // If MakerChecker is not active, explicitly return "Action Disabled".
  } else if (shouldDisableDueToPermission(button)) {
    return "You Don't have Permission";
  }

  return button.title; // Return the button title only if none of the above conditions are met.
};




  
  const shouldDisableDueToSelectedCount = (button) => {
    console.log("selectedCount bam",selectedCount);
    if (selectedCount > 1) {
      // Check for IoMdCheckmark and IoMdClose icons
      if (button.icon === IoMdCheckmark || button.icon === IoMdClose) {
        if (iconId == 26) {    
          return (accountDetails || []).some((acct) =>  [4,5,6].includes(acct.status) );
        }
      }
  
      // Disable for other icons if selectedCount > 1
      return (
        button.icon === MdOutlineHistory ||
        button.icon === IoMdCreate ||
        button.icon === BsUbuntu ||
        button.icon === FaRegEdit ||
        button.icon === BsTags ||
        button.icon === FiBox ||
        button.icon === LuCopyCheck ||
        button.icon === IoMdTrash ||
        button.icon === PiCopy ||
        button.icon === VscVmActive ||
        button.icon === LuKeyRound ||
        button.icon === MdOutlineLock
      );
    }
  
    return false;
  };

   useEffect(() => {
    // Function to check if permissions array is empty or not
    const areAllButtonsDisabled = rolesPermissions.length >= 0;
    // const isConsultant = '';
    setAreAllButtonsDisabled(areAllButtonsDisabled);
    console.log("All Buttons bam Disabled", areAllButtonsDisabled);
  
  }, [rolesPermissions]);

  const shouldDisableDueToPermission = (button) => {
    const permissionCheck = (permissionsId) =>
      rolesPermissions.some(
        (permission) =>
          permission.permissionsId === permissionsId &&
          permission.iconId === iconId
      );

    console.log("checking roles and ", rolesPermissions);
    return (
      (areAllButtonsDisabled &&
        // Add Button
        button.icon?.name === "IoAddCircleOutline" &&
        !permissionCheck(1)) ||
      // Edit Button
      (
        button.icon?.name === "IoMdCreate" && !permissionCheck(2)) ||
      // Copy
      (button.icon?.name === "PiCopy" && !permissionCheck(4)) ||
      // Audit Trail
      (button.icon?.name === "MdOutlineHistory" && !permissionCheck(5)) ||
      // Approve
      (button.icon?.name === "IoMdCheckmark" && !permissionCheck(6)) ||
      // Reject
      (button.icon?.name === "IoMdClose" && !permissionCheck(7)) ||
      // Trigger OTP
      (button.icon?.name === "LuKeyRound" && !permissionCheck(8)) ||
      // Upload
      (button.icon?.name === "MdOutlineFileUpload" && !permissionCheck(9)) ||
      // Download
      (button.icon?.name === "MdOutlineFileDownload" && !permissionCheck(10)) ||
      // Inactive
      (button.icon?.name === "VscVmActive" && !permissionCheck(11)) ||
      // Lock
      (button.icon?.name === "MdOutlineLock" && !permissionCheck(12)) ||
      // Delete
      (button.icon?.name === "IoMdTrash" && !permissionCheck(13)) ||
      // Settings
      (button.icon?.name === "IoSettingsOutline" && !permissionCheck(14)) ||
      //   // Filter
      (button.icon?.name === "GiSettingsKnobs" && !permissionCheck(15)) ||
      // Refresh
      (button.icon?.name === "IoMdRefresh" && !permissionCheck(16)) ||

   
      
       // Bank Account Number Update
       (button.icon?.name === "FaRegEdit" && !permissionCheck(19)) ||
       // Status Change
       (button.icon?.name === "BsTags" && !permissionCheck(20)) ||
       // Company Change
       (button.icon?.name === "FiBox" && !permissionCheck(21)) ||
      //  Special Copy
       (
        button.icon?.name === "LuCopyCheck" && !permissionCheck(22)) 
      //  ||
       
       // "Inconsistence User Types
      //  (button.icon?.name === "PiNotEquals" && !permissionCheck(24)) ||
      //   // Assign Role
      //   (button.icon?.name === "BsUbuntu" && !permissionCheck(25))

    );
  };

  
    const isDeleteButtonDisabled = (button) => {
      if (button.icon === IoMdTrash) {
        if (iconId == 26) {
          const isaccountDetailsBankID = (accountDetails|| []).some((acct) => acct.bankAccountId == null );
          if(!isaccountDetailsBankID){

            return (accountDetails || []).some((acct) =>  [0,1,2,3,4,5,6].includes(acct.status) );
          }

        }

      }
      return false;
    };


    const isApproveButtonDisabled = (button) => {
      if (button.icon === IoMdCheckmark || button.icon === IoMdClose) {
               
          if (iconId === 26) {

            // (1) Maker And checker for BAM - New Request
             // Ensure MakerCheker array contains an entry with id === 27 and maker_and_checker === 1
            const isBAMMakerCheckerEnabled = (MakerCheker || []).some(
              (mChecker) => mChecker.id === 27 && mChecker.makeAndChecker === 1
            );

            // (2) Account number Update
            const isBAMMakerCheckerEnabled2 = (MakerCheker || []).some(
              (mChecker) => mChecker.id === 28 && mChecker.makeAndChecker === 1
            );
            // (3) Account status change 
            const  isBAMMakerCheckerEnabled3 = (MakerCheker || []).some(
              (mChecker) => mChecker.id === 133 && mChecker.makeAndChecker === 1
            );
            // (4) Account Company code change 
            const  isBAMMakerCheckerEnabled4 = (MakerCheker || []).some(
              (mChecker) => mChecker.id === 29 && mChecker.makeAndChecker === 1
            );

            const isaccountDetailStatus = (accountDetails|| []).some((acct) => acct.status == 0);
            const isaccountDetailsBankID = (accountDetails|| []).some((acct) => acct.bankAccountId == null );

            const isaccountDetailStatus2 = (accountDetails|| []).some((acct) => acct.status == 3);
            const isaccountDetailsAcctNo2 = (accountDetails|| []).some((acct) => acct.accountNo != null );
            const isaccountDetailsApproveFor2  = (accountDetails|| []).some((acct) => acct.approveFor === 'Bank Account ID Update' );
            const isaccountDetailsApproveFor3 = (accountDetails|| []).some((acct) => acct.approveFor === 'Status Change' );
            const isaccountDetailsApproveFor4 = (accountDetails|| []).some((acct) => acct.approveFor === 'Company Code Change' );
            

            console.log("isaccountDetails is",isaccountDetailStatus);
            console.log("isaccountDetailsBankID is",isaccountDetailsBankID);
            // (1) Maker And checker for BAM - New Request
            if (isaccountDetailStatus && isaccountDetailsBankID) {
              console.log("isaccountDetailStatus And isaccountDetailsBankID is granted");
              

               if (isBAMMakerCheckerEnabled) {

                console.log("Is that maker and checker for BAM intial approve is 1");

                return (accountDetails || []).some((acct) => 
                // acct.status === 0 ||
                acct.status === 1 ||
                acct.status === 2 ||
                acct.status === 3 ||
                acct.status === 4 ||
                acct.status === 5 ||
                acct.status === 6 ||
                (acct.changed_by !== null &&
                  assignee.assigner === acct.changed_by) ||
                assignee.assigner === acct.created_by
               );
              }
              else{
                console.log("Is that maker and checker for BAM intial approve is 0");
              }

            }
             // (2) Account number Update
            else if(isaccountDetailStatus2 && isaccountDetailsAcctNo2 && isaccountDetailsApproveFor2 ){
              if (isBAMMakerCheckerEnabled2) {
                console.log("Is that maker and checker for BAM Account number Update is 1");

                return (accountDetails || []).some((acct) => 
                acct.status === 0 ||
                acct.status === 1 ||
                acct.status === 2 ||
                // acct.status === 3 ||
                acct.status === 4 ||
                acct.status === 5 ||
                acct.status === 6 ||
                (acct.changed_by !== null &&
                  assignee.assigner === acct.changed_by) ||
                assignee.assigner === acct.created_by
               );
              }
              else{
                console.log("Is that maker and checker for BAM Account number Update is 0");
              }
            }
             // (3) Status Change
            else if(isaccountDetailStatus2 && isaccountDetailsApproveFor3){
              if(isBAMMakerCheckerEnabled3){

              console.log("Is that maker and checker for BAM Status Change is 1");

                return (accountDetails || []).some((acct) => 
                acct.status === 0 ||
                acct.status === 1 ||
                acct.status === 2 ||
                // acct.status === 3 ||
                acct.status === 4 ||
                acct.status === 5 ||
                acct.status === 6 ||
                (acct.changed_by !== null &&
                  assignee.assigner === acct.changed_by) ||
                assignee.assigner === acct.created_by
               );
              }
              else{
                console.log("Is that maker and checker for BAM Status Change is 0");
              }
            }
             // (4) Company Code Change
            else if(isaccountDetailStatus2 && isaccountDetailsApproveFor4) {
              if(isBAMMakerCheckerEnabled4){

              console.log("Is that maker and checker for BAM Company Code Change is 1");

                return (accountDetails || []).some((acct) => 
                acct.status === 0 ||
                acct.status === 1 ||
                acct.status === 2 ||
                // acct.status === 3 ||
                acct.status === 4 ||
                acct.status === 5 ||
                acct.status === 6 ||
                (acct.changed_by !== null &&
                  assignee.assigner === acct.changed_by) ||
                assignee.assigner === acct.created_by
               );
              }
              else{
                console.log("Is that maker and checker for BAM Company Code Change is 0");
              }
            }

            else{
              return (accountDetails || []).some((acct) =>  [4,5,6].includes(acct.status) );
            }

  
          }
      }
      return false;
    };

    // const isApproveButtonDisabled = (button) => {
    //   if ([IoMdCheckmark, IoMdClose, IoMdTrash].includes(button.icon)) {
    //     console.log("Checking approve button conditions...");
    
    //     if (iconId === 26) {
    //       const hasEmptyBankAccount = accountDetails.every(acct => acct.bankAccountId === "");
    //   const hasBAMStatusZero = accountDetails.every(acct => acct.status === 0);
  
  
    //   if (hasEmptyBankAccount && hasBAMStatusZero)
    //     {
    //         console.log("It is accountDetailsBankAccountID and accountDetailsBAMStatus");
    //       }
    //       return (accountDetails || []).some((acct) => [3, 4, 5].includes(acct.status));
    //     }
    //   }
    //   return false;
    // };

    const isChangesAccMaster = (button) => {
      if(button.icon === BsTags || button.icon === FiBox)
        if (iconId == 26) {
          return (accountDetails || []).some((acct) =>  [0,1,2,3,5,6].includes(acct.status) );

        }
        return false;
      };

      const isChangesAccNoUpdate = (button) => {
        if(button.icon === FaRegEdit)
          if (iconId == 26) {

            const isaccountDetailStatus2 = (accountDetails|| []).some((acct) => acct.status == 3);
            const isaccountDetailsAcctNo2 = (accountDetails|| []).some((acct) => acct.accountNo != null );


            if(isaccountDetailStatus2 && isaccountDetailsAcctNo2 ){
            return (accountDetails || []).some((acct) =>  [0,1,2,3,5,6].includes(acct.status) );

            }
            else{
              return (accountDetails || []).some((acct) =>  [0,1,2,3,5,6].includes(acct.status) );
            }
  
          }
          return false;
        };


      const isCheckCompapnyCodeId   = (button) => {
        if (button.icon === FiBox) {
          // Check if the iconId is 26 and if accountDetailsCompanyId contains an empty or whitespace-only value
          if (iconId === 26) {
            return (accountDetailsCompanyId || []).some(
              (acct) => acct.trim() === "" // Check if any entry is empty or whitespace-only
            );
          }
        }
        return false; // Return false if conditions are not met
      };
      
      const isCheckStatusChange  = (button) => {
        if (button.icon === BsTags) {
          // Check if the iconId is 26 and if accountDetailsCompanyId contains an empty or whitespace-only value
          if (iconId === 26) {
            return (accountDetailsBankAccountID || []).some(
              (acct) => acct.trim() === "" // Check if any entry is empty or whitespace-only
            );
          }
        }
        return false; // Return false if conditions are not met
      };



  return (
    <div className="px-4">
      <div className="flex justify-between items-center">
        {/* Existing buttons aligned to the start */}

        <div className="flex items-center space-x-2">
          {buttons.map((button, index) => {
            const shouldDisableDueToApproval =
            isApproveButtonDisabled(button) ||
            isDeleteButtonDisabled(button) ||
            isChangesAccNoUpdate(button)||
            isChangesAccMaster(button)||
            isCheckCompapnyCodeId(button)||
            isCheckStatusChange(button)
            ;


              const shouldDisable =
              shouldDisableDueToSelectedCount(button);


            return (
            <button
              key={index}
              className={`p-1 rounded-lg ${button.disabled ||
                shouldDisable ||
                shouldDisableDueToApproval ||
                shouldDisableDueToPermission(button)
                ? ""
                : "bg-secondary text-secondary-foreground"
              }`}
              onClick={
                !shouldDisable &&
                  !shouldDisableDueToApproval &&
                  !shouldDisableDueToPermission(button)
                  ? button.onClick
                  : null
              }
              disabled={
                shouldDisable ||
                shouldDisableDueToApproval ||
                shouldDisableDueToPermission(button)
              }
              title={getButtonTitle(button)}
            >
              <button.icon
                className={`text-3xl border border-blue-100 rounded-lg cursor-pointer hover:border-customBlue hover:shadow-md p-1 ${button.rotate ? "rotate-90" : ""
                } ${getIconColor(
                  button.icon, button.disabled ||
                  shouldDisable ||
                  shouldDisableDueToApproval ||
                  shouldDisableDueToPermission(button)
                )}`}
              />
            </button>
             );
})}
        </div>


        {/* <div className="flex items-center space-x-2">
          {buttons.map((button, index) => (
            <button
              key={index}
              className="text-secondary-foreground p-1 rounded-lg"
              onClick={button.onClick}
              title={button.title}
            >
              <button.icon
                className={`border border-blue-100 rounded-lg cursor-pointer hover:border-customBlue hover:shadow-md p-1 ${button.rotate ? "rotate-90" : ""
                  } ${getIconColor(button.icon, true)} ${getIconSize(true)}`}
              />
            </button>
          ))}
        </div> */}

        {/* Extra buttons always visible but in light grey */}
        <div className="flex items-center space-x-2">
          {extraButtons.map((button, index) => (
            <button
              key={index}
              className={`p-1 rounded-lg 
                ${button.disabled
                  ? "bg-gray-200 cursor-not-allowed"
                  : "bg-secondary text-secondary-foreground"
                }`}
              onClick={!button.disabled ? button.onClick : undefined}
              disabled={button.disabled}
              title={button.title}
            >
              <button.icon
                className={`text-3xl border border-dark-gray shadow-md cursor-pointer p-1 hover:border-customBlue ${getIconColor(
                    button.icon,
                  button.disabled
                )}`}
              />
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BAMActions;
