import React, { useEffect, useState } from "react";
import axios from "axios";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { RiMenuSearchLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { BsCheckSquare } from "react-icons/bs";
import { LiaUserLockSolid } from "react-icons/lia";
import { TbLockCheck } from "react-icons/tb";
import { MdOutlineAssignmentReturn } from "react-icons/md";
import { TbUserHeart } from "react-icons/tb";
import { FaClipboardUser } from "react-icons/fa6";
import { MdOutlineBookmarkAdded, dOutlineGroups } from "react-icons/md";
import { MdOutlineGroups, MdCurrencyYen, MdOutlinePentagon } from "react-icons/md";
import { TbArrowsUpDown } from "react-icons/tb";
import { FaListCheck } from "react-icons/fa6";
import { GoWorkflow } from "react-icons/go";
import { LuRedoDot } from "react-icons/lu";
import { IoPersonAddOutline } from "react-icons/io5";
import { FaRegAddressCard } from "react-icons/fa6";

import { FcApproval, FcBusinessman, FcCollaboration, FcDecision, FcKey, FcMakeDecision, FcNews, FcPaid, FcReading, FcReadingEbook, FcSafe, FcShipped, FcSurvey, FcTimeline } from "react-icons/fc";

import Card from "../../../components/Card";
import MainHeader from "../../../components/MainHeader";
import Dashboard from "../../../components/Dashboard";
import { FaRegUser } from "react-icons/fa";
export default function AdminScreen() {
  const [subMenus, setSubMenus] = useState([]);
  const [moduleAssignments, setModuleAssignments] = useState([]);
  const [userId, setUserId] = useState(null);
  const [usermasterOpen, setUsermasterOpen] = useState(true);
  const [passwordPolicyOpen, setPasswordPolicyOpen] = useState(true);
  const [LicensesOpen, SetLicensesOpen] = useState(true);
  const [WorkFlowOpen, SetWorkFlowOpen] = useState(true);
  const navigate = useNavigate();
  const handleCardClick = (route) => {
    navigate(route);
  };
  const toggleUserMaster = () => {
    setUsermasterOpen(!usermasterOpen);
  };

  const togglePasswordPolicy = () => {
    setPasswordPolicyOpen(!passwordPolicyOpen);
  };
  const ToggleLicenses = () => {
    SetLicensesOpen(!LicensesOpen);
  };
  const ToggleWorkFlow = () => {
    SetWorkFlowOpen(!WorkFlowOpen);
  };

  useEffect(() => {

    // Fetch user ID from session storage
    const fetchUserId = () => {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        setUserId(userDetail.id || null);
      }
    };

    fetchUserId();

    // Fetch sub-menu data
    axios.get(`${process.env.REACT_APP_BASE_URL}/get-sub-menu`)
      .then((response) => {
        const filteredMenus = response.data.data.filter(menu => menu.mainMenuId === 14);
        setSubMenus(filteredMenus);
      })
      .catch((error) => console.error("Error fetching sub-menus:", error));

    // Fetch module assignment data
    axios.get(`${process.env.REACT_APP_BASE_URL}/get-module-assignment`)
      .then((response) => {
        // Filter module assignments by userId
        const filteredAssignments = response.data.filter(
          (assignment) =>
            assignment.userId === String(userId) && assignment.isChecked === true
        );
        setModuleAssignments(filteredAssignments);
      })
      .catch((error) => console.error("Error fetching module assignments:", error));
  }, [userId]);

  // Filter subMenus based on moduleAssignments
  const filteredSections = subMenus.filter((menu) => {
    return moduleAssignments.some(
      (assignment) => assignment.subMenuId === menu.id && assignment.isChecked
    );
  });

  // Define an order for submenus to control their display order
  const submenuOrder = ["User & SOD", "Password Policy", "Licenses", "Workflow"];

  // Sort the filteredSections by the defined submenuOrder
  const orderedSections = filteredSections.sort((a, b) => {
    return submenuOrder.indexOf(a.name) - submenuOrder.indexOf(b.name);
  });

  const sections = orderedSections.map((menu) => {
    let cards;

    if (menu.name === "User & SOD") {
      cards = [
        { title: "User", icon: <IoPersonAddOutline />, onClick: () => handleCardClick("/14/admin/108/userscreen") },
        { title: "Maker & Checker", icon: <BsCheckSquare />, onClick: () => handleCardClick("/14/admin/110/maker-and-checker") },
        { title: "Roles", icon: <FaRegUser />, onClick: () => handleCardClick("/14/admin/111/roles-and-permission/roles") },
        { title: "Permissions", icon: <LiaUserLockSolid />, onClick: () => handleCardClick("/14/admin/112/roles-and-permission") },
        { title: "Key Data", icon: <RiMenuSearchLine />, onClick: () => handleCardClick("/") },
      ];
    }
    else if (menu.name === "Password Policy") {
      cards = [
        { title: "Password Policy", icon: <TbLockCheck />, onClick: () => handleCardClick("/14/admin/passwordscreen/113") },
      ];
    }
    else if (menu.name === "Licenses") {

      cards = [
        { title: "User Type", icon: <TbUserHeart />, onClick: () => handleCardClick("/14/admin/user-type-screen/115") },
        { title: "Module Assignment", icon: <MdOutlineAssignmentReturn />, onClick: () => handleCardClick("/14/admin/module-assignment/114") },
      ];
    } else if (menu.name === "Workflow") {
      cards = [
        { title: "WF ID", icon: <FaRegAddressCard />, onClick: () => handleCardClick("/") },
        { title: "Approve Type", icon: <MdOutlineBookmarkAdded />, onClick: () => handleCardClick("/") },
        { title: "Approval Group", icon: <MdOutlineGroups />, onClick: () => handleCardClick("/") },
        { title: "Approval Sequence", icon: <TbArrowsUpDown />, onClick: () => handleCardClick("/") },
        { title: "Approval Status", icon: <FaListCheck />, onClick: () => handleCardClick("/") },
        { title: "WorkFlow", icon: <GoWorkflow />, onClick: () => handleCardClick("/") },
        { title: "Approval Tracker", icon: <LuRedoDot />, onClick: () => handleCardClick("/") },
      ];
    } else {
      // Optionally handle other cases or default cards
      cards = [];
    }

    return {
      title: menu.name,
      subMenuId: menu.id,
      cards: cards,
    };
  });

  // const sections = [
  //   {
  //     title: "User Master",
  //     cards: [
  //       { title: "User", icon: <IoPersonAddOutline />, onClick: () => handleCardClick("/14/admin/108/userscreen") },
  //       { title: "Key Data", icon: <RiMenuSearchLine />, onClick: () => handleCardClick("/statement-formatt") },
  //       { title: "Maker & Checker", icon: <BsCheckSquare />, onClick: () => handleCardClick("/14/admin/110/maker-and-checker") },
  //       { title: "Roles & Permission", icon: <LiaUserLockSolid />, onClick: () => handleCardClick("/14/admin/112/roles-and-permission") },
  //     ],
  //   },
  //   {
  //     title: "password Policy",
  //     cards: [
  //      {title:"Password Policy",icon:<TbLockCheck />,onClick:() => handleCardClick("/passwordscreen")},

  //     ],
  //   },
  //   {
  //     title: "Licenses",
  //     cards: [
  //       {title:"Module Assignment",icon:<MdOutlineAssignmentReturn />,onClick:() => handleCardClick("/module-assignment")},
  //       {title:"User Type",icon:<TbUserHeart/>,onClick:() => handleCardClick("/user-type-screen")},


  //     ],
  //   },
  //   {
  //     title: "WorkFLow",
  //     cards: [
  //       {title:"WF ID",icon:<FaRegAddressCard/>,onClick:() => handleCardClick("/negative-balances")},
  //       {title:"Approve Type",icon:<MdOutlineBookmarkAdded/>,onClick:() => handleCardClick("/dormant")},
  //       {title:"Approval Group",icon:<MdOutlineGroups/>,onClick:() => handleCardClick("/dormant")},
  //       {title:"Approval Sequence",icon:<TbArrowsUpDown/>,onClick:() => handleCardClick("/dormant")},
  //       {title:"Approval Status",icon:<FaListCheck/>,onClick:() => handleCardClick("/dormant")},
  //       {title:"WorkFlow",icon:<GoWorkflow/>,onClick:() => handleCardClick("/dormant")},
  //       {title:"Approval Tracker",icon:<LuRedoDot/>,onClick:() => handleCardClick("/dormant")},


  //     ],
  //   },
  //   // Add more sections as needed
  // ];

  return (
    <>
      <div>
        <MainHeader title={"Admin"} />
        <Dashboard sections={sections} />

        {/* <section>
  <div className="mt-2 ml-2">
    <div className="bg-muted rounded-lg mb-8">
      <div
        className={`flex justify-between cursor-pointer border bg-sky-50 w-full px-4 py-1 ${usermasterOpen ? "mb-2" : ""}`}
        onClick={toggleUserMaster}
      >
        <h2 className="text-left text-md font-medium text-muted-foreground">
          User & SOD
        </h2>
        <span className="p-1">
          {usermasterOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </span>
      </div>
      <div
        className={`overflow-hidden transition-opacity duration-900 ${usermasterOpen ? "opacity-100 max-h-96" : "opacity-0 max-h-0"}`}
      >
        <div className="my-2 -mt-4">
          {usermasterOpen && (
            <div className="mt-2 flex flex-wrap gap-6  mt-1  p-3">           
             <Card
            
                title="User"
                icon={<IoPersonAddOutline/>}
                onClick={() => handleCardClick("/userscreen")}
              />
              <Card

              
                title="Key Data"
                icon={<RiMenuSearchLine />}
                onClick={() => handleCardClick("/statement-formatt")}
              />
              <Card
                title="Maker & Checker"
                icon={<BsCheckSquare />}
                onClick={() => handleCardClick("/statement-formatt")}
              />
              <Card
                title="Roles & Permission"
                icon={<LiaUserLockSolid />}
                onClick={() => handleCardClick("/statement-formatt")}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
  
        <div className="-mt-8 ml-2">
          <div className="bg-muted  rounded-lg mb-12">
            <div
              className="flex justify-between cursor-pointer border bg-sky-50 w-full  px-4 py-1"
              onClick={togglePasswordPolicy}
            >
              <h2 className="text-left text-md font-medium  text-muted-foreground text-muted-foreground">
                password Policy
              </h2>
              <span className="p-1">
                {passwordPolicyOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </span>
            </div>
            <div
              className={`overflow-hidden transition-opacity duration-900 ${
                passwordPolicyOpen ? "opacity-100 max-h-96" : "opacity-0 max-h-0"
              }`}
            >
              <div className="my-2 -mt-4">
                {passwordPolicyOpen && (
                  <div className="mt-4 flex flex-wrap gap-6  mt-1  p-3">           
                  <Card
                      title="Password Policy"
                      icon={<TbLockCheck />}
                      onClick={() => handleCardClick("/passwordscreen")}
                    />
                   
                  
                
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className=" -mt-12">
          {" "}
          <div className="bg-muted  rounded-lg mb-4 ml-2">
            <div
              className="flex justify-between cursor-pointer border bg-sky-50 w-full  px-4 py-1"
              onClick={ToggleLicenses}
            >
              <h2 className=" text-left text-md font-medium  text-muted-foreground">
                Licenses
              </h2>
              <span className="p-1">
                {LicensesOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </span>
            </div>
            <div
              className={`overflow-hidden transition-opacity duration-900 ${
                LicensesOpen ? "opacity-100 max-h-96" : "opacity-0 max-h-0"
              }`}
            >
              <div className="my-2 -mt-2">
                {LicensesOpen && (
                  <div className="mt-2 flex flex-wrap gap-6  mt-1  p-3">           
                  <Card
                      title="Module Assignment"
                      icon={<MdOutlineAssignmentReturn />}
                      onClick={() => handleCardClick("/negative-balances")}
                    />
                     <Card
                      title="User Type"
                      icon={<TbUserHeart />}
                      onClick={() => handleCardClick("/negative-balances")}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className=" -mt-4">
          {" "}
          <div className="bg-muted  rounded-lg mb-4 ml-2">
            <div
              className="flex justify-between cursor-pointer border bg-sky-50 w-full  px-4 py-1"
              onClick={ToggleWorkFlow}
            >
              <h2 className=" text-left text-md font-medium  text-muted-foreground">
                WorkFLow
              </h2>
              <span className="p-1">
                {WorkFlowOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </span>
            </div>
            <div
              className={`overflow-hidden transition-opacity duration-900 ${
                WorkFlowOpen ? "opacity-100 max-h-96" : "opacity-0 max-h-0"
              }`}
            >
              <div className="my-2 -mt-2">
                {WorkFlowOpen && (
                  <div className="mt-2 flex flex-wrap gap-6  mt-1  p-3">           
                  <Card
                      title="WF ID"
                      icon={<FaRegAddressCard />}
                      onClick={() => handleCardClick("/negative-balances")}
                    />
                   
                     <Card
                      title="Approve Type"
                      icon={<MdOutlineBookmarkAdded />}
                      onClick={() => handleCardClick("/dormant")}
                    />
                     <Card
                      title="Approval Group"
                      icon={<MdOutlineGroups />}
                      onClick={() => handleCardClick("/dormant")}
                    />
                     <Card
                      title="Approval Sequence"
                      icon={<TbArrowsUpDown />}
                      onClick={() => handleCardClick("/dormant")}
                    />
                     <Card
                      title="Approval Status"
                      icon={<FaListCheck />}
                      onClick={() => handleCardClick("/dormant")}
                    />
                     <Card
                      title="WorkFlow"
                      icon={<GoWorkflow />}
                      onClick={() => handleCardClick("/dormant")}
                    />
                     <Card
                      title="Approval Tracker"
                      icon={<LuRedoDot />}
                      onClick={() => handleCardClick("/dormant")}
                    />
                    
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section> */}
      </div>


    </>
  );
}



