import { useEffect, useState } from "react";
import CustomButton from "../../../../components/CustomButton";
import Footer from "../../../../components/footer";
import Header from "../../../../components/Header";
import BankStatementServices from "../../../../rest-services/BankStatementServices";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useToast } from "../../../../components/toast/toast";

export default function AddManageStatementControlls() {
    const [errors, setErrors] = useState({});
    const [assignee, setAssignee] = useState({
        clientId: "",
        id: "",
        assigner: "",
        userId: "",
    });
    //Mt940
    const [statmentType, setStatmentType] = useState("")
    const [statementIdentifier, setStatementIdentifier] = useState("")
    //Branch
    const [bankId, setBankId] = useState("")
    const [bankIdName, setBankIdName] = useState("")
    const [branch, setBranch] = useState("")
    const [branchName, setBranchName] = useState("")
    //accountdetails
    const [bankaccountId, setBankAccountId] = useState("")
    const [bankaccountDes, setBankAccountDes] = useState("")
    const [bankAccountNo, setBankAccountNo] = useState("")
    const [currency, setCurrency] = useState("")
    const [ibanNo, setIbanNo] = useState("")
    const [companyCode, setCompanyCode] = useState("")
    const [companyName, setComapanyName] = useState("")
    //controls
    const [lastStatementDate, setLastStatementDate] = useState("")
    const [balance, setBalance] = useState("")
    const [lastStatementNo, setLastStatementNo] = useState("")

    const location = useLocation();
    const { mode } = location.state || {};
    const { id } = useParams();
    useEffect(() => {
        fetchAssignee()
    }, []);

    useEffect(() => {
        fetchDateForFields()
    }, [assignee.clientId])
    const navigate = useNavigate()
    const { toast } = useToast();
    const handleBreadcrumbClick = (crumb) => {
        console.log("Breadcrumb clicked:", crumb.title);
        if (crumb.title === "Manage Statement Controlls") {
            navigate("/manage-statement-controlls");
        } else if (crumb.title === "MT940") {
            navigate("/bank-statements");
        }
        else if (crumb.title === "BankStatements") {
            navigate("/bank-statements");
        }
        else if (crumb.path) {
            navigate(crumb.path); // Navigate to the path specified in the crumb
        }
    };
    const fetchAssignee = async () => {
        try {
            const userDetailString = localStorage.getItem("userDetail");
            if (userDetailString) {
                const userDetail = JSON.parse(userDetailString);
                const assigneeData = {
                    clientId: userDetail.clientId || "",
                    id: userDetail.id || "",
                    assigner: userDetail.username || "",
                    userId: userDetail.userId || "",
                };
                setAssignee(assigneeData);
            } else {
            }
        } catch (error) { console.log(error) }
    };

    const fetchDateForFields = async () => {
        try {
            if (!assignee.clientId) return;

            const response = await BankStatementServices.getmanagedStatementById(id, assignee.clientId);
            if (response.status === 200) {
                const data = response.data.data;
                // Extract and assign values to state variables
                const manageStatementControl = data.manageStatementControl || {};
                const bankBranch = data.bankBranch || {};
                const accountDetails = data.accountDetails || {};
                const company = data.company || {};

                setStatmentType(manageStatementControl.statmentType);
                setStatementIdentifier(manageStatementControl.statementIdentifier);
                setBankId(bankBranch.bankId);
                setBankIdName(bankBranch.bankIdName);
                setBranch(bankBranch.bankBranch);
                setBranchName(bankBranch.bankbranchName);
                setBankAccountId(manageStatementControl.bankAccountId);
                setBankAccountDes(accountDetails.accountDesc);
                setBankAccountNo(accountDetails.accountNumber); // Assuming this field exists
                setCurrency(accountDetails.currency);
                setIbanNo(accountDetails.ibanNo); // Assuming this field exists
                setCompanyCode(company.companyCode);
                setComapanyName(company.companyName);
                setLastStatementDate(manageStatementControl.lastStamentdate);
                setBalance(manageStatementControl.balance);
                setLastStatementNo(manageStatementControl.lastStamentNo);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const updateControls = async () => {
        try {
            if (!validateForm()) return;
            const data = {
                id: id,
                clientId: assignee.clientId,
                lastStamentdate: lastStatementDate,
                balance: balance,
                lastStamentNo: lastStatementNo,
                changedBy: assignee.userId
            }
            const response = await BankStatementServices.updateManagedStatementControls(data);
            if (response.status === 200) {
                toast("success", response.message);
            } else {
                toast("error", response.message);
            }
        } catch (error) {
            console.log(error)
        }
    }
    const validateForm = () => {
        const newErrors = {};
        if (!lastStatementDate) newErrors.lastStatementDate = "lastStatementDate is required.";
        if (!lastStatementNo) newErrors.lastStatementNo = "lastStatementNo is required.";
        if (!balance) newErrors.balance = "balance is required.";


        setErrors(newErrors);
        if (Object.keys(newErrors).length > 0) {
            toast("error", "Missing Required Fields");
        }
        return Object.keys(newErrors).length === 0;
    };
    return (
        <div>
            <Header title="Bank Statements > MT940 > Manage Statement Controlls > Edit" onBreadcrumbClick={handleBreadcrumbClick} />
            <div className="p-8 pr-24">
                {/* MT940 */}
                <div className="flex flex-col space-y-8 col-span-1 p-7 border  rounded-md">
                    <label className="flex items-center">
                        <span className="w-48 font-small text-gray-500 font-bold">MT940</span>
                    </label>
                    <div className="flex flex-row space-x-8 col-span-1">
                        <label className="flex items-center">
                            <span className="w-48 font-small text-gray-500">Statment Type</span>
                            <span className="ml-4">:</span>
                            <input
                                type="text"
                                value={statmentType}
                                readOnly
                                className={`border ${errors.ntMainHead ? "border-red-500" : "border-gray-300"
                                    } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-gray-500`}
                                required
                            />

                        </label>
                        <label className="flex items-center">
                            <span className="w-48 font-small text-gray-500">Statment Format</span>
                            <span className="ml-4">:</span>
                            <input
                                type="text"
                                value={statementIdentifier}
                                readOnly
                                className={`border ${errors.ntMainHead ? "border-red-500" : "border-gray-300"
                                    } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-gray-500`}
                                required
                            />
                        </label>
                    </div>

                </div>
                {/* Bank */}
                <div className="flex flex-col space-y-8 col-span-1 p-7 border  rounded-md mt-2">
                    <label className="flex items-center">
                        <span className="w-48 font-small text-gray-500 font-bold">Bank</span>
                    </label>
                    <div className="flex flex-row space-x-8 col-span-1">
                        <label className="flex items-center">
                            <span className="w-48 font-small text-gray-500">Bank ID</span>
                            <span className="ml-4">:</span>
                            <input
                                type="text"
                                readOnly
                                value={bankId}
                                className={`border ${errors.ntMainHead ? "border-red-500" : "border-gray-300"
                                    } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-gray-500`}
                                required
                            />

                        </label>
                        <label className="flex items-center">
                            <span className="w-48 font-small text-gray-500">Bank ID Name</span>
                            <span className="ml-4">:</span>
                            <input
                                type="text"
                                value={bankIdName}
                                readOnly
                                className={`border ${errors.ntMainHead ? "border-red-500" : "border-gray-300"
                                    } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-gray-500`}
                                required
                            />
                        </label>
                    </div>
                    <div className="flex flex-row space-x-8 col-span-1">
                        <label className="flex items-center">
                            <span className="w-48 font-small text-gray-500">Bank Branch</span>
                            <span className="ml-4">:</span>
                            <input
                                type="text"

                                value={branch}
                                readOnly
                                className={`border ${errors.ntMainHead ? "border-red-500" : "border-gray-300"
                                    } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-gray-500`}
                                required
                            />

                        </label>
                        <label className="flex items-center">
                            <span className="w-48 font-small text-gray-500">Bank Branch Name</span>
                            <span className="ml-4">:</span>
                            <input
                                type="text"
                                value={branchName}
                                readOnly
                                className={`border ${errors.ntMainHead ? "border-red-500" : "border-gray-300"
                                    } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-gray-500`}
                                required
                            />
                        </label>
                    </div>

                </div>
                {/* Account Details */}
                <div className="flex flex-col space-y-8 col-span-1 p-7 border  rounded-md mt-2">
                    <label className="flex items-center">
                        <span className="w-48 font-small text-gray-500 font-bold">Account Details</span>
                    </label>
                    <div className="flex flex-row space-x-8 col-span-1">
                        <label className="flex items-center">
                            <span className="w-48 font-small text-gray-500">Bank Account ID</span>
                            <span className="ml-4">:</span>
                            <input
                                type="text"
                                value={bankaccountId}
                                readOnly
                                className={`border ${errors.ntMainHead ? "border-red-500" : "border-gray-300"
                                    } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-gray-500`}
                                required
                            />

                        </label>
                        <label className="flex items-center">
                            <span className="w-48 font-small text-gray-500">Account Description</span>
                            <span className="ml-4">:</span>
                            <input
                                type="text"
                                value={bankaccountDes}
                                readOnly
                                className={`border ${errors.ntMainHead ? "border-red-500" : "border-gray-300"
                                    } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-gray-500`}
                                required
                            />
                        </label>
                    </div>
                    <div className="flex flex-row space-x-8 col-span-1">
                        <label className="flex items-center">
                            <span className="w-48 font-small text-gray-500">Account No</span>
                            <span className="ml-4">:</span>
                            <input
                                type="text"
                                value={bankAccountNo}
                                readOnly
                                className={`border ${errors.ntMainHead ? "border-red-500" : "border-gray-300"
                                    } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-gray-500`}
                                required
                            />

                        </label>
                        <label className="flex items-center">
                            <span className="w-48 font-small text-gray-500">Currency</span>
                            <span className="ml-4">:</span>
                            <input
                                type="text"
                                value={currency}
                                readOnly
                                className={`border ${errors.ntMainHead ? "border-red-500" : "border-gray-300"
                                    } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-gray-500`}
                                required
                            />
                        </label>
                    </div>
                    <div className="">
                        <label className="flex items-center">
                            <span className="w-48 font-small text-gray-500">IBAN</span>
                            <span className="ml-4">:</span>
                            <input
                                type="text"
                                value={ibanNo}
                                readOnly
                                className={`border ${errors.ntMainHead ? "border-red-500" : "border-gray-300"
                                    } p-1.5 rounded w-[73.5%] h-8 focus:outline-none hover:border-blue-400 ml-8 text-gray-500`}
                                required
                            />

                        </label>
                    </div>
                    <div className="flex flex-row space-x-8 col-span-1">
                        <label className="flex items-center">
                            <span className="w-48 font-small text-gray-500">Company Code</span>
                            <span className="ml-4">:</span>
                            <input
                                type="text"
                                value={companyCode}
                                readOnly
                                className={`border ${errors.ntMainHead ? "border-red-500" : "border-gray-300"
                                    } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-gray-500`}
                                required
                            />

                        </label>
                        <label className="flex items-center">
                            <span className="w-48 font-small text-gray-500">Company Name</span>
                            <span className="ml-4">:</span>
                            <input
                                type="text"
                                value={companyName}
                                readOnly
                                className={`border ${errors.ntMainHead ? "border-red-500" : "border-gray-300"
                                    } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-gray-500`}
                                required
                            />
                        </label>
                    </div>
                </div>
                <div className="flex flex-col space-y-8 col-span-1 p-7 border  rounded-md mt-2">
                    <label className="flex items-center">
                        <span className="w-48 font-small text-gray-500 font-bold">Controls</span>
                    </label>
                    <div className="flex flex-row space-x-8 col-span-1">
                        <label className="flex items-center">
                            <span className="w-48 font-small text-gray-500">Last Statement Date</span>
                            <span className="ml-4">:</span>
                            <input
                                type="date"
                                value={lastStatementDate}
                                onChange={(e) => setLastStatementDate(e.target.value)}
                                className={`border ${errors.lastStatementDate ? "border-red-500" : "border-gray-300"
                                    } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-gray-500`}
                                required
                            />
                        </label>

                    </div>
                    <div className="flex flex-row space-x-8 col-span-1">
                        <label className="flex items-center">
                            <span className="w-48 font-small text-gray-500">Balance</span>
                            <span className="ml-4">:</span>
                            <input
                                type="text"
                                value={balance}
                                onChange={(e) => setBalance(e.target.value)}
                                className={`border ${errors.lastStatementNo ? "border-red-500" : "border-gray-300"
                                    } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-gray-500`}
                                required
                            />
                        </label>
                    </div>
                    <div className="flex flex-row space-x-8 col-span-1">
                        <label className="flex items-center">
                            <span className="w-48 font-small text-gray-500">Last Statement No</span>
                            <span className="ml-4">:</span>
                            <input
                                type="text"
                                value={lastStatementNo}
                                onChange={(e) => setLastStatementNo(e.target.value)}
                                className={`border ${errors.balance ? "border-red-500" : "border-gray-300"
                                    } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-gray-500`}
                                required
                            />
                        </label>
                    </div>
                </div>
            </div>
            <div className="mt-4">
                <Footer>
                    <div>
                        <CustomButton
                            onClick={() => { updateControls() }}
                            title={"Save"}
                            className="mr-2"
                        />
                        {/* <HoverButton
                            title={"Save & Continue"}
                            className={"mr-2"}
                            onClick={() => { }}
                        /> */}
                        {/* <HoverButton
                        title={"Run >"}
                        className={"mr-2"}
                        onClick={() => { }}
                    /> */}
                    </div>
                </Footer>
            </div>
        </div>
    )
}