import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../../../../../../components/Header";
import Tabs from "../../../../../../components/Tabs";
import BankBranchServices from "../../../../../../rest-services/BankBranchServices";
import BamServices from "../../../../../../rest-services/BamServices";
import Footer from "../../../../../../components/footer";
import ReTable from "../../../../../../components/Table";
import { useToast } from "../../../../../../components/toast/toast";
import { TbFlag3Filled } from "react-icons/tb";
import CustomDropdown from "../../../../../../components/CustomDropdown";
import ReusableModal from "../../../../../../components/PopupComponent";
import { RxDropdownMenu } from "react-icons/rx";
import { use } from "react";
import { set } from "lodash";
import LabelWithButton from "../../../../../../components/InputButtonField";
import StatementFormatServices from "../../../../../../rest-services/StatementFormatServices";
import TableWrapper from "../../../../../../components/ScrollBarComponent";
import UserService from "../../../../../../rest-services/UserServices";

export default function BankAccountMasterAdd() {
  const { iconId } = useParams();
  const navigate = useNavigate();
  const { actionId, accountIds } = useParams();
  const [rolesPermissions, setRolesPermissions] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [assignee, setAssignee] = useState({
    clientId: "",
    id: "",
    assigner: "",
    userId: "",
  });

  const tabs = [
    { value: "bankaccount", label: "Bank Account" },
    { value: "bankaddress", label: "Bank Address" },
    { value: "identifiers", label: "Identifiers" },
    { value: "bankstatements", label: "Bank Statements" },
    { value: "validity", label: "Validity" },
    { value: "odcc", label: "OD/CC" },
    { value: "controls", label: "Controls" },
    { value: "textfields", label: "Text Fields" },
  ];
  // State to track the active tab
  const [activeTab, setActiveTab] = useState("bankaccount");

  // Function to handle tab clicks
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    if (assignee.userId) {
      fetchUserPermissionDetails();
    }
  }, [assignee.userId]);

  const hasPermissionForSave = rolesPermissions.some(
    (permission) => permission.permissionsId === 17 && permission.iconId === parseInt(iconId)
  );

  const hasPermissionForSaveANDContinue = rolesPermissions.some(
    (permission) => permission.permissionsId === 18 && permission.iconId === parseInt(iconId)
  );


  const fetchUserPermissionDetails = async () => {
    try {
      const data = { userId: assignee.userId };
      console.log("Request Data:", data);

      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-users-permissions-by-userId`,
        data
      );
      console.log("user permissions details", response);
      const responseData = response.data;
      setRolesPermissions(responseData.rolesPermissionsTables || []); // Save permission details
      console.log("Set Permission details for bam screen", responseData.rolesPermissionsTables);


    } catch (error) {
      setError(error.message); // Set error message
    } finally {
      setLoading(false); // Reset loading state
    }
  };


  const [isValidationTriggered, setIsValidationTriggered] = useState(false);

  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === "BAM") {
      navigate("/bam");
    } else if (crumb.title === "Bank Master Data") {
      navigate("/3/BAM/26/account-master");
    } else if (crumb.title === "Account Master") {
      navigate("/3/BAM/26/account-master");
    } else if (crumb.title === "Add") {
      navigate("/account-master-add");
    } else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };

  //Bank Account
  const isAcNoDisabled = actionId === "1";
  const isAccontDetaislsDisabled = actionId === "2";
  const isCopyMode = actionId === "5";
  const isSpecialCopyMode = actionId === "6";

  const location = useLocation();
  const { mode } = location.state || {};

  const isViewMode = mode === "view";
  const isEditMode = mode === "edit";

  const [unsavedChanges, setUnsavedChanges] = useState(false);

  //DropDown Values
  const [branch, setBranch] = useState([]);
  const [accountType, setAccountType] = useState([]);
  const [zbaType, setZbaType] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [legalEntity, setLegaleEntity] = useState([]);
  const [company, setCompany] = useState([]);
  const [mainAccount, setMainAccount] = useState([]);
  const [statementType, setStatementType] = useState([]);

  //To save the or assing value for Account details form
  const [reqNo, setReqNo] = useState("");
  const [status, setStatus] = useState();
  const [accountStatus, setAccountStatus] = useState("");
  const [accountStatusFor, setAccountStatusFor] = useState("New Request");
  const [branchID, setBranchID] = useState("");
  const [bankID, setBankID] = useState("");
  const [branchName, setBranchName] = useState("");
  const [bankIDName, setBankIDName] = useState("");
  const [bankName, setBankName] = useState("");
  const [bank, setBank] = useState("");
  const [currencyid, setCurrencyid] = useState("");
  const [accountId, setAccountId] = useState("");
  const [zbaid, setZbaid] = useState("");
  const [overDraft, setOverDraft] = useState(0);
  const [notes, setNotes] = useState("");

  //For an Account NO
  const [accountNo, setAccountNo] = useState("");
  const [iBanNo, setIbanNo] = useState("");
  const [bankAccountId, setBankAccountId] = useState("");
  const [bankAccountDesc, setBankAccountDesc] = useState("");
  const [accountTextField, setAccountTextField] = useState("");

  //For an Entity
  const [legalEntityID, setLegalEntityID] = useState("");
  const [legalEntityName, setLegalEntityName] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [buGroup, setBuGroup] = useState("");
  const [buGroupName, setBuGroupName] = useState("");

  //To save the or assing value for Address details form
  const [doorNo, setDoorNo] = useState("");
  const [floorNo, setFloorNo] = useState("");
  const [buildingNo, setBuildingNo] = useState("");
  const [buildingName, setBuildingName] = useState("");
  const [landMark, setLandMark] = useState("");
  const [streetNo, setStreetNo] = useState("");
  const [roadNo, setRoadNo] = useState("");
  const [roadName, setRoadName] = useState("");
  const [streetName, setStreetName] = useState("");
  const [areaBlock, setAreaBlock] = useState("");
  const [areaName, setAreaName] = useState("");
  const [country, setCountry] = useState("");
  const [addressLine1, setaddressLine1] = useState("");
  const [addressLine2, setaddressLine2] = useState("");
  const [addressLine3, setaddressLine3] = useState("");
  const [countriesName, setCountriesName] = useState("");
  const [stateProvinces, setStateProvinces] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [postalCode, setpostalCode] = useState("");
  const [district, setdistrict] = useState("");
  const [poBox, setpoBox] = useState("");
  const [state, setState] = useState("");
  const [stateName, setStateName] = useState("");

  //Identfiers
  const [bankBic, setBankBic] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [countryCodeType, setCountryCodeType] = useState("");
  const [micr, setMicr] = useState("");
  const [customerCode, setCustomerCode] = useState("");
  const [branchCode, setBranchCode] = useState("");
  const [bankCode, setBankCode] = useState("");

  //Bank Statements
  const [statementId, setStatementId] = useState("");
  const [statementIdentifier, setStatementIdentifier] = useState("");
  const [lastStatementDate, setLastStatementDate] = useState("");
  const [lastStateClosingbalance, setLastStateClosingBalance] = useState("");
  const [lastStatementNo, setLastStatementNo] = useState("");

  //OD/CC
  const [odLimit, setOdLimit] = useState("");
  const [intrestIndicator, setIntrestIndicator] = useState("");
  const [odDate, setOdDate] = useState("");

  //Controls
  const [minimumBalance, setMinimumBalance] = useState("");
  const [dormant, setDormant] = useState("");
  const [isZbaType, setIsZbaType] = useState(0);
  const [zbaTypeControlls, setZbaTypeControlls] = useState("");
  const [mainBankAccount, setMainBankAccount] = useState("");

  //Text Fields
  const [textFields1, setTextFields1] = useState("");
  const [textFields2, setTextFields2] = useState("");
  const [textFields3, setTextFields3] = useState("");
  const [textFields4, setTextFields4] = useState("");
  const [textFields5, setTextFields5] = useState("");

  //New Pop up Things
  const [searchQuery, setSearchQuery] = useState("");
  const [isBranchModalOpen, setIsBranchModalOpen] = useState(false);
  const [isCurrencyModalOpen, setIsCurrencyModalOpen] = useState(false);
  const [isAccountTypeModalOpen, setIsAccountTypeModalOpen] = useState(false);
  const [isZbaTypeModalOpen, setIsZbaTypeModalOpen] = useState(false);
  const [isLegalEntityModalOpen, setIsLegalEntityModalOpen] = useState(false);
  const [isLegalEntityNameModalOpen, setIsLegalEntityNameModalOpen] = useState(false);
  const [isCompanyModalOpen, setIsCompanyModalOpen] = useState(false);
  const [isCompanyNameModalOpen, setIsCompanyNameModalOpen] = useState(false);

  const [isMainBankModalOpen, setIsMainBankModalOpen] = useState(false);
  //Validity Data
  const [validityId, setValidityId] = useState("");
  const isoToDateInput = (isoDate) => {
    return isoDate ? isoDate.split("T")[0] : ""; // Extracts the YYYY-MM-DD part
  };

  // Validitiy
  const [selectedRows, setSelectedRows] = useState([]);

  //Assingner
  // const [assignee, setAssignee] = useState({
  //   clientId: "",
  //   id: "",
  //   assigner: "",
  //   userId: "",
  // });

  //Error Thorwing
  const [errors, setErrors] = useState({});

  //Account No Validation
  const [accountMax, setAccountMax] = useState("");
  const [accountMin, setAccountMin] = useState("");
  const [isIbanRequired, setIsIbanRequired] = useState(0);

  useEffect(() => {
    fetchAssignee();
  }, []);
  useEffect(() => {
    fetchBranches();
    fetcAccountType();
    fetchZbaType();
    fetchCurrencies();
    fetchLegalEntity();
    getStatementType();
  }, [assignee.clientId]);

  useEffect(() => {
    if (accountIds) {
      handleFetchDataForFilled();
      if (actionId !== "5" && actionId !== "6") {
        getValidity(accountIds);
      }

    }
  }, [accountIds, assignee]);

  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          clientId: userDetail.clientId || "",
          id: userDetail.id || "",
          assigner: userDetail.username || "",
          userId: userDetail.userId || "",
        };
        setAssignee(assigneeData);
      } else {
      }
    } catch (error) { }
  };

  const errorss = {
    bankaccount: !accountStatus || !branchID || !currencyid || !legalEntityID,
    odcc: !odDate || !odLimit || !intrestIndicator,
  };

  const getValidity = async (accountIds) => {
    try {
      const response = await BamServices.getValidity(accountIds);

      setValidityId(response.data.data);
    } catch (error) { }
  };

  const checkLimits = () => {
    if (!accountNo) return;
    let isValid = true;
    if ((!accountMin && !accountMax) || (accountMin === 0 && accountMax === 0)) {
      return isValid;
    }
    if (accountNo.length > accountMax) {
      toast(
        "error",
        `Account number exceeds the maximum value of ${accountMax}`
      );
      isValid = false;
    }

    if (accountNo.length < accountMin) {
      toast(
        "error",
        `Account number is below the minimum value of ${accountMin}`
      );
      isValid = false;
    }

    return isValid;
  };
  const checkifAccountNoExists = async () => {
    try {
      if (!isAccontDetaislsDisabled) return;
      const response = await BamServices.checkAcountExist(accountNo);
      if (response && response.data) {
        if (response.data.data === true) {
          toast("warning", "Account Number Already Exists");
        }
      }
    } catch (error) {

    }
  };
  const handleFetchDataForFilled = async () => {
    try {
      if (!assignee.clientId) {
        return;
      }
      const response = await BamServices.getBankAccountById(
        accountIds,
        assignee.clientId
      );
      if (response && response.data) {

        const account = response.data.data
        if (isCopyMode || actionId === "6") {
          setStatus(0);
          setAccountStatus("Requested");
        } else {
          setStatus(account.accountDetails.status);
          setAccountStatus(account.accountDetails.accountStatus);
        }
        setAccountId(account.accountDetails.accountID);
        setReqNo(account.accountDetails.reqNo);
        setBranchID(account.branchData.bankBranch);
        setBankID(account.branchData.bankID);
        // Branch Data
        setBranchName(account.branchData.branchName);
        setBankIDName(account.branchData.bankIDName);
        setBankName(account.branchData.bankName);
        setBank(account.branchData.bank);
        //Currency Data
        setCurrencyid(account.accountDetails.currencyID);
        setZbaid(account.accountDetails.zbaID);
        setOverDraft(account.accountDetails.overDraft);
        setNotes(account.accountDetails.notes);
        setLegalEntityID(account.accountDetails.legalEntityID);
        setLegalEntityName(account.accountDetails.legalEntityName);
        setCompanyId(account.accountDetails.companyID);
        setCompanyName(account.accountDetails.companyName);
        setBuGroup(account.accountDetails.buGroup);
        setBuGroupName(account.accountDetails.buGroupName);
        if (actionId !== "5" && actionId !== "6") {
          setAccountNo(account.accountDetails.accountNo);
          setIbanNo(account.accountDetails.iBanNo);
          setBankAccountId(account.accountDetails.bankAccountId);
          setBankAccountDesc(account.accountDetails.bankAccountDescription);
          setAccountTextField(account.accountDetails.accountTextField);
        }
        //Address
        setDoorNo(account.branchData.addresses[0].doorNo || "");
        setFloorNo(account.branchData.addresses[0].floorNo || "");
        setBuildingNo(account.branchData.addresses[0].buildingNo || "");
        setBuildingName(account.branchData.addresses[0].buildingName || "");
        setLandMark(account.branchData.addresses[0].landMark || "");
        setStreetNo(account.branchData.addresses[0].streetNo || "");
        setRoadNo(account.branchData.addresses[0].roadNo || "");
        setRoadName(account.branchData.addresses[0].roadName || "");
        setStreetName(account.branchData.addresses[0].streetName || "");
        setAreaBlock(account.branchData.addresses[0].areaBlock || "");
        setAreaName(account.branchData.addresses[0].areaName || "");
        setaddressLine1(account.branchData.addresses[0].addressLine1 || "");
        setaddressLine2(account.branchData.addresses[0].addressLine2 || "");
        setaddressLine3(account.branchData.addresses[0].addressLine3 || "");
        setCountry(account.branchData.addresses[0].countryCode || "");
        setCountriesName(account.branchData.addresses[0].countriesName || "");
        setStateName(account.branchData.addresses[0].stateProvince || "");
        setStateProvinces(account.branchData.addresses[0].state || "");
        setZipCode(account.branchData.addresses[0].zipCode || "");
        setpostalCode(account.branchData.addresses[0].postalCode || "");
        setdistrict(account.branchData.addresses[0].district || "");
        setpoBox(account.branchData.addresses[0].poBox || "");
        setState(account.branchData.addresses[0].state || "");

        //Indedifiers
        setBankBic(account.branchData.identifier[0].bankBIC || "");
        setCountryCode(account.branchData.identifier[0].country_Code || "");
        setCountryCodeType(account.branchData.identifier[0].countryCodeType || "");
        setMicr(account.branchData.identifier[0].micr || "");
        setCustomerCode(account.branchData.identifier[0].customerCode || "");
        setBranchCode(account.branchData.identifier[0].BranchCode || "")
        setBankCode(account.branchData.identifier[0].bankCode || "")
        //Branch Validate
        setAccountMax(account.branchData.specificchecks
        [0].bankAccountMaxLength);
        setAccountMin(account.branchData.specificchecks
        [0].bankAccountMinLength);
        setIsIbanRequired(account.branchData.specificchecks
        [0].IBAN_required);

        //Bank Statements
        setStatementId(account.bankStatements[0].statementId || "");
        setStatementIdentifier(account.bankStatements[0].statementIdentifier || "");
        setLastStatementDate(
          isoToDateInput(account.bankStatements[0].lastStatementDate) || ""
        );
        setLastStateClosingBalance(account.bankStatements[0].lastStateClosingbalance || "");
        setLastStatementNo(account.bankStatements[0].lastStatementNo || "");

        //Controlls
        setMinimumBalance(account.bamControls[0].minimumBalance || "");
        setDormant(account.bamControls[0].dormant || "");
        setZbaTypeControlls(account.bamControls[0].zbaTypeControlls || "");
        setMainBankAccount(account.bamControls[0].mainBankAccount || "");
        //ODCC
        setOdLimit(account.bamOdDetails
        [0].odLimit || "");
        setIntrestIndicator(account.bamOdDetails
        [0].intrestIndicator || "");
        setOdDate(isoToDateInput(account.bamOdDetails
        [0].odDate) || "");
        //TextFields
        setTextFields1(account.bamTextField[0].textFields1 || "");
        setTextFields2(account.bamTextField[0].textFields2 || "");
        setTextFields3(account.bamTextField[0].textFields3 || "");
        setTextFields4(account.bamTextField[0].textFields4 || "");
        setTextFields5(account.bamTextField[0].textFields5 || "");
      }
    } catch (error) { }
  };

  const fetchBranches = async () => {
    try {
      const response = await BankBranchServices.getAllBankBranch(
        "/get-all-bankbranch"
      );
      if (response && response.data) {
        setBranch(response.data);
        if (isAcNoDisabled) {
          setAccountStatus("Requested");
        }
      }
    } catch (error) {
      console.error("Error fetching branches:", error);
    }
  };

  const fetcAccountType = async () => {
    try {
      const response = await BamServices.getAccountType(assignee.clientId);
      if (response && response.data) {
        setAccountType(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching Account Type:", error);
    }
  };

  const getStatementType = async () => {
    try {
      const response = await StatementFormatServices.getAllStatementFormat(assignee.clientId);
      if (response && response.data) {
        setStatementType(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching Statement Type:", error);
    }
  }

  const fetchZbaType = async () => {
    try {
      if (!assignee.clientId) {
        return;
      }
      const response = await BamServices.getAllZBATypes(assignee.clientId);
      if (response && response.data) {
        setZbaType(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCurrencies = async () => {
    try {
      const response = await BamServices.getAllCurrency();
      if (response && response.data) {
        setCurrency(response.data.data);
      }
    } catch (error) { }
  };

  const fetchLegalEntity = async () => {
    try {
      const response = await BamServices.getAllLegalEntity();
      console.log(response);
      if (response) {
        setLegaleEntity(response);
      }
    } catch (error) { }
  };

  const handleBranchChange = async (value) => {
    console.log("value", value);
    setBranchID(value);
    setUnsavedChanges(true);
    fetchMainBankAccount();
    const selectedBranch = branch.find(
      (item) => item.id.toString() === value.toString()
    );
    setStatus(0);
    setBranchName(selectedBranch.branchName);
    setBankName(selectedBranch.BankName);
    setBank(selectedBranch.Bank);
    setBankID(selectedBranch.BankId);
    if (selectedBranch.addresses.length > 0) {
      const address = selectedBranch.addresses[0];
      setDoorNo(address.doorNo || "");
      setFloorNo(address.floorNo || "");
      setBuildingNo(address.buildingNo || "");
      setBuildingName(address.buildingName || "");
      setLandMark(address.landMark || "");
      setStreetNo(address.streetNo || "");
      setRoadNo(address.roadNo || "");
      setRoadName(address.roadName || "");
      setStreetName(address.streetName || "");
      setAreaBlock(address.areaBlock || "");
      setAreaName(address.areaName || "");
      setStateProvinces(address.state || "");
      setCountry(address.countryCode || "");
      setZipCode(address.zipCode || "");
      setaddressLine1(address.addressLine1 || "");
      setaddressLine2(address.addressLine2 || "");
      setaddressLine3(address.addressLine3 || "");
      setdistrict(address.district || "");
      setpostalCode(address.postalCode || "");
      setZipCode(address.zipCode || "");
      setpoBox(address.poBox || "");
    }
    if (selectedBranch.identifier.length > 0) {
      const identifier = selectedBranch.identifier[0]
      setBankBic(identifier.bankBIC || "");
      setCountryCode(identifier.country_Code || "");
      setCountryCodeType(identifier.countryCodeType || "");
      setMicr(identifier.micr || "");
      setCustomerCode(identifier.customerCode || "");
      setBranchCode(identifier.BranchCode || "");
      setBankCode(identifier.bankCode || "");

    }
    if (selectedBranch.specificchecks.length > 0) {
      setIsIbanRequired(selectedBranch.specificchecks[0].IBAN_required || "");
      setAccountMin(
        selectedBranch.specificchecks[0].bankAccountMinLength || ""
      );
      setAccountMax(
        selectedBranch.specificchecks[0].bankAccountMaxLength || ""
      );
    }
  };

  const handleLegalEntityChange = async (value) => {
    setLegalEntityID(value);
    setUnsavedChanges(true);
    const selectedEntity = legalEntity.find(
      (item) => item.id.toString() === value.toString()
    );
    setLegalEntityName(selectedEntity.LGE_Description);
    setCompanyId("");
    setCompanyName("");
    setBuGroup("");
    setBuGroupName("");
    const response = await BamServices.getAssociatedCompany(
      selectedEntity.LGE_Code
    );
    if (response.status === 200) {
      setCompany(
        Array.isArray(response.data) ? response.data : [response.data]
      );
    } else {
      toast("error", "No Company found for this Legal Entity");
      setCompany([]);

    }
  };

  const handleLegalEntityNameChange = async (value) => {
    setLegalEntityName(value);
    setUnsavedChanges(true);
    const selectedEntity = legalEntity.find(
      (item) => item.LGE_Description.toString() === value.toString()
    );
    setLegalEntityID(selectedEntity.id);
    setCompanyId("");
    setCompanyName("");
    setBuGroup("");
    setBuGroupName("");
    // const response = await BamServices.getAssociatedCompany(
    //   selectedEntity.LGE_Code
    // );
    // if (response.status === 200) {
    //   setCompany(
    //     Array.isArray(response.data) ? response.data : [response.data]
    //   );
    // } else {
    //   toast("error", "No Company found for this Legal Entity");
    //   setCompany([]);

    // }
  };

  useEffect(() => {
    fetchBankAccountCompany(legalEntityID);
  }, [legalEntityID]);

  const fetchBankAccountCompany = async (entityCode) => {
    try {
      if (!legalEntityID) return
      const selectedEntity = legalEntity.find((item) => item.id === entityCode);
      const response = await BamServices.getAssociatedCompany(
        selectedEntity.LGE_Code
      );
      if (response.status === 200) {
        setCompany(
          Array.isArray(response.data) ? response.data : [response.data]
        );
      } else {
        toast("error", "No Company found for this Legal Entity");
        setCompany([]);

      }
    } catch (error) {
      console.error("Error fetching Company:", error);
    }
  };

  const handleCompanyChange = async (event) => {
    setCompanyId(event.target.value);
    setUnsavedChanges(true);
    const selectedCompany = company.find(
      (item) => item.companyCode.toString() === event.target.value
    );
    setCompanyName(selectedCompany.companyShortName);
    setBuGroup(selectedCompany.buGroupCode);
    setBuGroupName(selectedCompany.bu_Description);
  };
  const handleCompanyNameChange = async (event) => {
    setCompanyName(event.target.value);
    setUnsavedChanges(true);
    const selectedCompany = company.find(
      (item) => item.companyName.toString() === event.target.value
    );
    setCompanyId(selectedCompany.companyCode);
    setBuGroup(selectedCompany.buGroupCode);
    setBuGroupName(selectedCompany.bu_Description);
  };
  const columns = React.useMemo(() => [
    {
      Header: "SNo",
      width: 50,
      Cell: ({ row }) => row.index + 1,
    },
    {
      Header: "Bank Account Code",
      accessor: "bankAccountCode",
      width: 150,
    },
    {
      Header: "Company Code",
      accessor: "companyCode",
      width: 150,
    },
    {
      Header: "Status",
      accessor: "status",
      width: 200,
    },
    {
      Header: "Valid From",
      accessor: "validFrom",
      width: 200,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, "0"); // Adds leading zero if day < 10
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Adds leading zero if month < 10
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      },
    },
    {
      Header: "Valid To",
      accessor: "validTo",
      width: 200,
      Cell: ({ value }) => {
        if (!value) return "";
        if (typeof value === "string" && isNaN(Date.parse(value))) {
          return value;
        }
        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, "0"); // Adds leading zero if day < 10
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Adds leading zero if month < 10
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      },
    },
    {
      Header: "Updated By",
      accessor: "updatedBy",
      width: 200,
    },
    {
      Header: "Updated Date",
      accessor: "updatedDate",
      width: 200,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, "0"); // Adds leading zero if day < 10
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Adds leading zero if month < 10
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      },
    },
    {
      Header: "Updated Time",
      accessor: "updateTime",
      width: 200,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        return date.toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
        });
      },
    },
  ]);


  // Function to handle row selection
  const onSelectRow = (rowId) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(rowId)
        ? prevSelectedRows.filter((id) => id !== rowId)
        : [...prevSelectedRows, rowId]
    );
  };

  // Function to handle double-click on a row
  const onRowDoubleClick = (rowId) => {
    console.log("Double clicked row with id:", rowId);
  };

  const { toast } = useToast();
  const validateForm = () => {
    const newErrors = {};
    if (!accountStatus) newErrors.accountStatus = "AccountStatus is required.";
    if (!branchID) newErrors.branchID = "branchID is required.";
    if (!currencyid) newErrors.currencyid = "currencyid is required.";
    if (!legalEntityID) newErrors.legalEntityID = "legalEntityID is required.";
    if (overDraft !== 0) {
      if (!odLimit) newErrors.odLimit = "OD Limit is required.";
      if (!odDate) newErrors.odDate = "OD Date is required.";
      if (!intrestIndicator)
        newErrors.intrestIndicator = "Interest Indicator is required.";
      setIsValidationTriggered(true);
    }

    if (!branchID) {
      setIsValidationTriggered(true);
    }



    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      toast("error", "Missing Required Fields");
    }
    return Object.keys(newErrors).length === 0;
  };

  const validateUpdateForm = () => {
    const newErrors = {};
    if (!companyId) newErrors.companyId = "CompanyId is required.";
    if (isIbanRequired === 1) {
      if (!iBanNo) {
        newErrors.iBanNo = "IBAN Number is required.";
      }
    }
    if (!bankAccountDesc) {
      newErrors.bankAccountDesc = "Bank Account Description is required.";
    }
    const value = checkLimits()

    if (!value) {
      newErrors.accountNo = "Account Number Length is not valid.";
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      toast("error", "Missing Required Fields");
    }
    return Object.keys(newErrors).length === 0;
  };
  const resetForm = () => {
    try {
      // Resetting general account details
      setStatus(0);
      setAccountStatus("");
      setAccountId("");
      setReqNo("");
      setBranchID("");
      setBankID("");
      setBranchName("");
      setBankIDName("");
      setBankName("");
      setBank("");
      setCurrencyid("");
      setZbaid("");
      setOverDraft("");
      setNotes("");
      setLegalEntityID("");
      setLegalEntityName("");
      setCompanyId("");
      setCompanyName("");
      setBuGroup("");
      setBuGroupName("");
      setAccountNo("");
      setIbanNo("");
      setBankAccountId("");
      setBankAccountDesc("");
      setAccountTextField("")

      // Resetting bank statements
      setStatementId("");
      setStatementIdentifier("");
      setLastStatementDate("");
      setLastStateClosingBalance("");
      setLastStatementNo("");

      // Resetting controls
      setMinimumBalance("");
      setDormant("");
      setZbaTypeControlls("");
      setMainBankAccount("");

      // Resetting address fields
      setDoorNo("");
      setFloorNo("");
      setBuildingNo("");
      setBuildingName("");
      setLandMark("");
      setStreetNo("");
      setRoadNo("");
      setRoadName("");
      setStreetName("");
      setAreaBlock("");
      setAreaName("");
      setCountry("");
      setaddressLine1("");
      setaddressLine2("");
      setaddressLine3("");
      setCountriesName("");
      setStateProvinces("");
      setZipCode("");
      setpostalCode("");
      setdistrict("");
      setpoBox("");

      setStateName("");

      // Resetting identifiers
      setBankBic("");
      setCountryCode("");
      setCountryCodeType("");
      setMicr("");

      // Resetting ODCC fields
      setOdLimit("");
      setIntrestIndicator("");
      setOdDate("");

      // Resetting text fields
      setTextFields1("");
      setTextFields2("");
      setTextFields3("");
      setTextFields4("");
      setTextFields5("");
    } catch (error) {
      console.error("Error resetting form:", error);
    }
  };

  const handleSave = async (e, isContinue) => {
    try {
      e.preventDefault();

      if (!validateForm()) return;
      if (actionId === "6" && !validateUpdateForm()) return;

      const payload = {
        odLimit,
        intrestIndicator,
        odDate,
        // Controls
        minimumBalance,
        dormant,
        isZbaType,
        zbaTypeControlls,
        mainBankAccount,
        // Text Fields
        textFields1,
        textFields2,
        textFields3,
        textFields4,
        textFields5,
        // Identifiers
        // bankBic,
        // countryCode,
        // countryCodeType,
        // micr,
        // Bank Statements
        statementId,
        statementIdentifier,
        lastStatementDate,
        lastStateClosingbalance,
        lastStatementNo,
        // Address Information
        // doorNo,
        // floorNo,
        // buildingNo,
        // buildingName,
        // landMark,
        // streetNo,
        // roadNo,
        // roadName,
        // streetName,
        // areaBlock,
        // areaName,
        // country,
        // addressLine1,
        // addressLine2,
        // addressLine3,
        // countriesName,
        // stateProvinces,
        // zipCode,
        // postalCode,
        // district,
        // poBox,
        // state,
        // stateName,
        // Additional Information
        clientId: assignee.clientId,
        status: 0,
        accountStatus: accountStatus === "Requested" ? 0 : 1,
        accountStatusFor,
        branchID,
        // bankID,
        // branchName,
        // bankIDName: bankName,
        // bankName,
        // bank,
        currencyid,
        accountId,
        zbaid,
        overDraft,
        notes,
        createdBy: assignee.userId,
        createdDate: new Date().toISOString(),

        // Legal Entity Information
        legalEntityID,
        legalEntityName,
        companyId,
        companyName,
        buGroup,
        buGroupName,
        // accountMin,
        // accountMax,
        // isBanRequired: isIbanRequired,
        actionId,

        ...(actionId === "6" && {
          accountNo: accountNo,
          ibanNo: iBanNo,
          bankAccountDes: bankAccountDesc,
          accountTextField: accountTextField,
        }),
      };

      const response = await BamServices.addBankAccountMaster(payload);
      if (response.status === 200) {
        toast("success", "Data Saved Successfully");
        if (!isContinue) {
          navigate("/3/BAM/26/account-master");
        } else {
          resetForm();
        }
      }
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const handleBankAccoutIdUpdate = async () => {
    try {
      if (!validateUpdateForm()) return;
      const data = {
        id: accountIds,
        accountNo: accountNo,
        ibanNo: iBanNo,
        bankAccountDes: bankAccountDesc,
        accountTextField: accountTextField,
        companyId: companyId,
        companyName: companyName,
        buGroup: buGroup,
        buGroupName: buGroupName,
        changedBy: assignee.userId,
      };
      const response = await BamServices.updateBankId(data);
      if (response.status === 200) {
        toast("success", "Data Updated Successfully");
        navigate("/3/BAM/26/account-master");
      }
    } catch (error) { }
  };

  const handleSaveAndEdit = async () => {
    try {
      const data = {
        id: accountIds,
        accountId,
        zbaid,
        overDraft,
        notes,
        odLimit,
        intrestIndicator,
        odDate,
        minimumBalance,
        dormant,
        mainBankAccount,
        textFields1,
        textFields2,
        textFields3,
        textFields4,
        textFields5,
        changedBy: assignee.userId,
      };
      const reponse = await BamServices.editBankAccount(data);
      if (reponse.status === 200) {
        toast("success", "Data Edited Successfully");
        navigate("/3/BAM/26/account-master");
      }
    } catch (error) { }
  };

  const handleSaveClick = (actionId, e) => {
    if (actionId === "1") {
      handleSave(e);
    } else if (actionId === "2") {
      handleBankAccoutIdUpdate();
    } else if (actionId === "3") {
      handleSaveAndEdit();
    } else if (actionId === "5") {
      handleSave(e);
    } else if (actionId === "6") {
      handleSave(e);
    }
  };

  useEffect(() => {
    fetchMainBankAccount();
  }, [assignee]);
  const fetchMainBankAccount = async () => {
    try {
      const response = await BamServices.mainBankAccount();
      if (response.status === 200) {
        setMainAccount(response.data.bankAccounts);
      }
    } catch (error) {
      console.error("Error fetching main bank account:", error);
    }
  };

  const getApprovedIcon = () => {
    let color = "black"; // Default color
    if (status === 4) {
      color = "#29CB00"; // Green
    } else if (status === 0) {
      color = "gray"; // Gray
    } else if (status === 1) {
      color = "#AF52DE";
    } else if (status === 2) {
      color = "yellow";
    } else if (status === 3) {
      color = "orange";
    }
    else if (status === 5) {
      color = "red"; // Red
    }

    return <TbFlag3Filled style={{ color, marginLeft: '8px' }} />; // Return the icon with the determined color
  };
  const [openDropdown, setOpenDropdown] = useState(null);
  const handleToggle = (dropdownId) => {
    setOpenDropdown((prev) => (prev === dropdownId ? null : dropdownId)); // Toggle dropdown
  };

  const statementTypeOptions = Array.isArray(statementType)
    ? statementType.map((type) => ({
      value: type.id,
      label: type.statement_type,
    }))
    : [];

  const mainBankAccountOptions = Array.isArray(mainAccount)
    ? mainAccount.map((bank) => ({
      value: bank.bankAccountId,
      label: bank.bankAccountId,
    }))
    : [];
  const overDraftOptions = [
    { value: 0, label: "No" },
    { value: 1, label: "Yes" },
  ];

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const [isStatementTypeModalOpen, setIsStatementTypeModalOpen] =
    useState(false);

  const handleResetBranchSelection = () => {
    setBranchID("");
    setBankID("");
    setBranchName("");
    setBankIDName("");
    setBankName("");
    setBank("");
  };

  return (
    <div>
      <div>
        <Header
          title={`BAM > Bank Master Data > Account Master >  ${actionId === "1"
            ? "Add"
            : actionId === "2"
              ? "ID Update"
              : actionId === "5"
                ? "Copy"
                : actionId === "6"
                  ? "Special Copy"
                  : isEditMode
                    ? "Edit"
                    : "View"
            } > ${bankAccountId ? bankAccountId : ""}`}
          onBreadcrumbClick={handleBreadcrumbClick}
          extraContent={actionId ? getApprovedIcon() : null}
          hasUnsavedChanges={unsavedChanges}
        />
      </div>
      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        errors={errorss}
        onTabClick={handleTabClick}
        isValidationTriggered={isValidationTriggered}
      />
      {/*Bank Account*/}
      {activeTab === "bankaccount" && (

        <div className="m-6 border border-gray-200 p-5  rounded-lg">
          <TableWrapper maxHeight="70vh">
            <div className=" mb-10">
              {/** Account Details */}
              <div className="mb-6 mx-8 border border-gray-300 p-5 rounded-lg">
                <h3 className="text-lg font-semibold text-gray-500 mb-4">
                  Account details
                </h3>
                <div className="grid grid-cols-2 gap-4">
                  <div className="flex items-center">
                    <label className="w-48 text-sm font-medium text-gray-500">
                      New Req No{" "}
                    </label>
                    <span className="ml-4">:</span>
                    <input
                      type="text"
                      value={reqNo}
                      // onClick={() => {
                      //   generateReqNo();
                      //   if (errors.reqNo) {
                      //     setErrors((prevErrors) => ({
                      //       ...prevErrors,
                      //       reqNo: "",
                      //     }));
                      //   }
                      // }}
                      readOnly={isViewMode || isEditMode}
                      disabled={isAccontDetaislsDisabled}
                      className={`border ${errors.reqNo ? "border-red-500" : "border-gray-300"
                        } p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`}
                    />
                  </div>
                  <div className="flex items-center">
                    <label className="w-48 text-sm font-medium text-gray-500">
                      Account Status{" "}
                    </label>
                    <span className="ml-4">:</span>
                    <input
                      type="text"
                      value={accountStatus === 0 ? "Requested" : accountStatus}
                      onChange={() => {
                        if (errors.accountStatus) {
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            accountStatus: "",
                          }));
                          setUnsavedChanges(true);
                        }
                      }}
                      readOnly={isViewMode || isEditMode}
                      disabled={isAccontDetaislsDisabled}
                      placeholder=""
                      className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"

                    />
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4 mt-4">
                  <div className="flex items-center">
                    <label className="w-48 text-sm font-medium text-gray-500">
                      Bank Branch<span className="text-red-500">*</span>
                    </label>
                    <span className="ml-4">:</span>
                    <button
                      onClick={() => setIsBranchModalOpen(true)}
                      disabled={
                        isAccontDetaislsDisabled || isViewMode || isEditMode
                      }
                      className={`border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-4 text-left flex items-center justify-between text-customGray ${errors.branchID
                        ? "border-red-500 hover:border-red-500"
                        : "border-gray-300 hover:border-blue-400"
                        }`}
                    >
                      <span className="font-small text-customGray text-sm">
                        {isViewMode || isEditMode || isCopyMode || isSpecialCopyMode ? branchID : (branch.find((branch) => branch.id == branchID)
                          ?.bankBranch || "Select Bank Branch")}
                      </span>
                      <RxDropdownMenu className="ml-2" />
                    </button>

                    <ReusableModal
                      title="Select Bank Branch"
                      isOpen={isBranchModalOpen}
                      onRefresh={fetchBranches}
                      onClose={() => {
                        setIsBranchModalOpen(false);
                        setSearchQuery("");
                      }}
                      onSave={() => {
                        setIsBranchModalOpen(false);
                        setSearchQuery("");
                      }}
                      searchPlaceholder="Search Branch..."
                      searchQuery={searchQuery}
                      onSearchChange={handleSearchChange}
                      isViewMode={isViewMode}
                      showReset={true}
                      onReset={() => {
                        handleResetBranchSelection();
                      }}
                    >
                      {branch && branch.length > 0 ? (
                        branch
                          .filter((branch, index, self) =>
                            // Keep only the first occurrence of branchName
                            index === self.findIndex((b) => b.branchName === branch.branchName)
                          )
                          .filter((branch) =>
                            branch.branchName
                              ?.toString()
                              .toLowerCase()
                              .includes(searchQuery.toLowerCase())
                          ).sort((a, b) =>
                            a.branchName.localeCompare(b.branchName, undefined, { sensitivity: 'base' })
                          )
                          .map((branch, index) => (
                            <div
                              key={branch.id}
                              className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""}`}
                            >
                              <input
                                type="radio"
                                name="BranchID"
                                value={branch.id}
                                checked={branchID == branch.id}
                                onChange={(e) => {
                                  handleBranchChange(e.target.value);
                                  if (errors.branchID) {
                                    setErrors((prevErrors) => ({
                                      ...prevErrors,
                                      branchID: "",
                                    }));
                                  }
                                  setUnsavedChanges(true);
                                }}
                                className="mr-2 accent-customBlue"
                              />
                              <label>{branch.bankBranch || "N/A"}</label>
                            </div>
                          ))
                      ) : (
                        <p>{branch ? "No Branch Found" : "Loading Branch..."}</p>
                      )}

                    </ReusableModal>
                  </div>
                  <div className="flex items-center">
                    <label className="w-48 text-sm font-medium text-gray-500">
                      Bank ID <span className="text-red-500">*</span>
                    </label>
                    <span className="ml-4">:</span>
                    <input
                      type="text"
                      placeholder="B name"
                      value={bankID}
                      readOnly={isViewMode || isEditMode}
                      disabled={isAccontDetaislsDisabled}
                      className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4 mt-4">
                  <div className="flex items-center">
                    <label className="w-48 text-sm font-medium text-gray-500">
                      Branch Name <span className="text-red-500">*</span>
                    </label>
                    <span className="ml-4">:</span>
                    <input
                      type="text"
                      placeholder=""
                      value={branchName}
                      readOnly={isViewMode || isEditMode}
                      disabled={isAccontDetaislsDisabled}
                      className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                    />
                  </div>
                  <div className="flex items-center">
                    <label className="w-48 text-sm font-medium text-gray-500">
                      Bank ID Name <span className="text-red-500">*</span>
                    </label>
                    <span className="ml-4">:</span>
                    <input
                      value={bankName}
                      readOnly={isViewMode || isEditMode}
                      type="text"
                      placeholder="B name"
                      disabled={isAccontDetaislsDisabled}
                      className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4 mt-4">
                  <div className="flex items-center">
                    <label className="w-48 text-sm font-medium text-gray-500">
                      Currency <span className="text-red-500">*</span>
                    </label>
                    <span className="ml-4">:</span>

                    <button
                      onClick={() => setIsCurrencyModalOpen(true)}
                      disabled={
                        isAccontDetaislsDisabled || isViewMode || isEditMode
                      }
                      className={`border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-4 text-left flex items-center justify-between text-customGray ${errors.currencyid
                        ? "border-red-500 hover:border-red-500"
                        : "border-gray-300 hover:border-blue-400"
                        }`}
                    >
                      <span className="font-small text-customGray text-sm">
                        {currencyid || "Select Currency Code"}
                      </span>
                      <RxDropdownMenu className="ml-2" />
                    </button>

                    <ReusableModal
                      title="Select Currency Code"
                      onRefresh={fetchCurrencies}
                      isOpen={isCurrencyModalOpen}
                      onClose={() => {
                        setIsCurrencyModalOpen(false);
                        setSearchQuery("");
                      }}
                      onSave={() => {
                        setIsCurrencyModalOpen(false);
                        setSearchQuery("");
                      }}
                      searchPlaceholder="Search  Currency..."
                      searchQuery={searchQuery}
                      onSearchChange={handleSearchChange}
                      isViewMode={isViewMode}
                      onReset={() => {
                        setCurrencyid("");
                      }}
                      showReset={true}
                    >
                      {currency && currency.length > 0 ? (
                        currency
                          .filter((currency) =>
                            currency.currencyCode
                              ?.toString()
                              .toLowerCase()
                              .includes(searchQuery.toLowerCase())
                          ).sort((a, b) =>
                            a.currencyCode.localeCompare(b.currencyCode, undefined, { sensitivity: 'base' })
                          )
                          .map((currency, index) => (
                            <div
                              key={currency.currencyCode}
                              className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""
                                }`}
                            >
                              <input
                                type="radio"
                                name="BranchID"
                                value={currency.currencyCode}
                                checked={currencyid == currency.currencyCode}
                                onChange={(e) => {
                                  setCurrencyid(e.target.value);
                                  if (errors.currencyid) {
                                    setErrors((prevErrors) => ({
                                      ...prevErrors,
                                      currencyid: "",
                                    }));
                                  }
                                  setUnsavedChanges(true);
                                }}
                                className="mr-2 accent-customBlue"
                              />
                              <label>{currency.currencyCode || "N/A"}</label>
                            </div>
                          ))
                      ) : (
                        <p>{branch ? "No Branch Found" : "Loading Branch..."}</p>
                      )}
                    </ReusableModal>
                  </div>
                  <div className="flex items-center">
                    <label className="w-48 text-sm font-medium text-gray-500">
                      Bank <span className="text-red-500">*</span>
                    </label>
                    <span className="ml-4">:</span>
                    <input
                      disabled={isAccontDetaislsDisabled}
                      type="text"
                      value={bank}
                      readOnly={isViewMode || isEditMode}
                      placeholder="B name"
                      className="border border-gray-300 p-1.5 rounded w-80 h-9 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4 mt-4">
                  <div className="flex items-center">
                    <label className="w-48 text-sm font-medium text-gray-500">
                      Account Type{" "}
                    </label>
                    <span className="ml-4">:</span>
                    <button
                      onClick={() => setIsAccountTypeModalOpen(true)}
                      disabled={isAccontDetaislsDisabled || isViewMode}
                      className={`border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-4 text-left flex items-center justify-between text-customGray ${"border-gray-300 hover:border-blue-400"}`}
                    >
                      <span className="font-small text-customGray text-sm">
                        {accountId || "Select Account Type"}
                      </span>
                      <RxDropdownMenu className="ml-2" />
                    </button>

                    <ReusableModal
                      title="Select Account Type"
                      onRefresh={fetcAccountType}
                      isOpen={isAccountTypeModalOpen}
                      onClose={() => {
                        setIsAccountTypeModalOpen(false);
                        setSearchQuery("");
                      }}
                      onSave={() => {
                        setIsAccountTypeModalOpen(false);
                        setSearchQuery("");
                      }}
                      onReset={() => {
                        setAccountId("");
                      }}
                      showReset={true}
                      searchPlaceholder="Search Account Type..."
                      searchQuery={searchQuery}
                      onSearchChange={handleSearchChange}
                      isViewMode={isViewMode}
                    >
                      {accountType && accountType.length > 0 ? (
                        accountType
                          .filter((account, index, self) =>
                            // Keep only the first occurrence of Account_Type
                            index === self.findIndex((a) => a.Account_Type === account.Account_Type)
                          )
                          .filter((account) =>
                            account.Account_Type
                              ?.toString()
                              .toLowerCase()
                              .includes(searchQuery.toLowerCase())
                          ).sort((a, b) =>
                            a.Account_Type.localeCompare(b.Account_Type, undefined, { sensitivity: 'base' })
                          )
                          .map((account, index) => (
                            <div
                              key={account.Account_Type}
                              className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""}`}
                            >
                              <input
                                type="radio"
                                name="AccountType"
                                value={account.Account_Type}
                                checked={accountId === account.Account_Type}
                                onChange={(e) => {
                                  setAccountId(e.target.value);
                                  setUnsavedChanges(true);
                                }}
                                className="mr-2 accent-customBlue"
                              />
                              <label>{account.Account_Type || "N/A"}</label>
                            </div>
                          ))
                      ) : (
                        <p>{accountType ? "No Account Type Found" : "Loading Account Types..."}</p>
                      )}

                    </ReusableModal>
                  </div>
                  <div className="flex items-center">
                    <label className="w-48 text-sm font-medium text-gray-500">
                      Bank Name <span className="text-red-500">*</span>
                    </label>
                    <span className="ml-4">:</span>
                    <input
                      disabled={isAccontDetaislsDisabled}
                      value={bankName}
                      readOnly={isViewMode || isEditMode}
                      type="text"
                      placeholder="B name"
                      className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4 mt-4">
                  <div className="flex items-center">
                    <label className="w-48 text-sm font-medium text-gray-500">
                      ZBA Type{" "}
                    </label>
                    <span className="ml-4">:</span>
                    <button
                      onClick={() => setIsZbaTypeModalOpen(true)}
                      disabled={isAccontDetaislsDisabled || isViewMode}
                      className={`border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-4 text-left flex items-center justify-between text-customGray ${"border-gray-300 hover:border-blue-400"}`}
                    >
                      <span className="font-small text-customGray text-sm">
                        {zbaid || "Select Zba Type"}
                      </span>
                      <RxDropdownMenu className="ml-2" />
                    </button>

                    <ReusableModal
                      title="Select ZBA Type"
                      onRefresh={fetchZbaType}
                      isOpen={isZbaTypeModalOpen}
                      onClose={() => {
                        setIsZbaTypeModalOpen(false);
                        setSearchQuery("");
                      }}
                      onSave={() => {
                        setIsZbaTypeModalOpen(false);
                        setSearchQuery("");
                      }}
                      onReset={() => {
                        setZbaid("");
                      }}
                      showReset={true}
                      searchPlaceholder="Search ZBA Type..."
                      searchQuery={searchQuery}
                      onSearchChange={handleSearchChange}
                      isViewMode={isViewMode}
                    >
                      {zbaType && zbaType.length > 0 ? (
                        zbaType
                          .filter((zba, index, self) =>
                            // Keep only the first occurrence of ZBA_ID
                            index === self.findIndex((z) => z.ZBA_ID === zba.ZBA_ID)
                          )
                          .filter((zba) =>
                            zba.ZBA_ID
                              ?.toString()
                              .toLowerCase()
                              .includes(searchQuery.toLowerCase())
                          ).sort((a, b) =>
                            a.ZBA_ID.localeCompare(b.ZBA_ID, undefined, { sensitivity: 'base' })
                          )
                          .map((zba, index) => (
                            <div
                              key={zba.ZBA_ID}
                              className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""}`}
                            >
                              <input
                                type="radio"
                                name="AccountType"
                                value={zba.ZBA_ID}
                                checked={zbaid == zba.ZBA_ID}
                                onChange={(e) => {
                                  setZbaid(e.target.value); // Update state with selected ZBA_ID
                                  setUnsavedChanges(true); // Mark changes as unsaved
                                }}
                                className="mr-2 accent-customBlue"
                              />
                              <label>{zba.ZBA_ID || "N/A"}</label>
                            </div>
                          ))
                      ) : (
                        <p>{"No Data Found"}</p>
                      )}

                    </ReusableModal>
                  </div>
                  <div className="flex items-center">
                    <label className="w-48 text-sm font-medium text-gray-500">
                      Over Draft{" "}
                    </label>
                    <span className="ml-4">:</span>
                    <CustomDropdown
                      value={
                        overDraftOptions.find(
                          (option) => option.value === overDraft
                        ) || null
                      }
                      options={overDraftOptions}
                      onChange={(value) => setOverDraft(value)}
                      isOpen={openDropdown === "overDraft"}
                      onToggle={() => handleToggle("overDraft")}
                      isDisabled={isAccontDetaislsDisabled || isViewMode}
                      className="ml-4 border-gray-300"
                      placeholder="Select Overdraft"
                    />
                  </div>
                </div>

                <div className="flex items-center mt-4">
                  <label className="w-52 2xl:w-48 text-sm font-medium text-gray-500">
                    Notes
                  </label>
                  <span className="ml-4">:</span>
                  <input
                    disabled={isAccontDetaislsDisabled}
                    type="text"
                    value={notes}
                    readOnly={isViewMode}
                    maxLength={150}
                    onChange={(e) => {
                      setNotes(e.target.value);
                      setUnsavedChanges(true);
                    }}
                    className="border border-gray-300 p-1.5 rounded 2xl:w-[72%] w-full h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 "
                  />
                </div>
              </div>
              {/** Account No*/}
              <div className="mb-6 border mx-8 border-gray-300 p-5 rounded-lg">
                <h3 className="text-lg font-semibold text-gray-500 mb-4">
                  Account No
                </h3>
                <div className="grid grid-cols-2 gap-4">
                  <div className="flex items-center">
                    <label className="w-48 text-sm font-medium text-gray-500">
                      Bank Account ID
                    </label>
                    <span className="ml-4">:</span>
                    <input
                      type="text"
                      maxLength={30}
                      value={bankAccountId}
                      readOnly={
                        isAccontDetaislsDisabled ||
                        isViewMode ||
                        isEditMode ||
                        isCopyMode
                      }
                      // onChange={(e) => setBankAccountId(e.target.value)}
                      className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                      disabled={isAcNoDisabled}
                    />
                  </div>
                  <div className="flex items-center">
                    <label className="w-48 text-sm font-medium text-gray-500">
                      Bank Account ID Description{" "}
                      <span className="text-red-500">*</span>
                    </label>
                    <span className="ml-4">:</span>
                    <input
                      type="text"
                      maxLength={30}
                      readOnly={isViewMode || isEditMode || isCopyMode}
                      value={bankAccountDesc}
                      onChange={(e) => {
                        setBankAccountDesc(e.target.value);
                        setUnsavedChanges(true);
                        if (errors.bankAccountDesc) {
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            accountNo: "",
                          }));
                        }
                      }}
                      placeholder=""
                      className={`border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-4 text-left flex items-center justify-between text-customGray ${errors.bankAccountDesc
                        ? "border-red-500 hover:border-red-500"
                        : "border-gray-300 hover:border-blue-400"
                        }`} disabled={isAcNoDisabled}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4 mt-4">
                  <div className="flex items-center">
                    <label className="w-48 text-sm font-medium text-gray-500">
                      Account No <span className="text-red-500">*</span>
                    </label>
                    <span className="ml-4">:</span>
                    <input
                      type="text"
                      value={accountNo}
                      maxLength={25}
                      onBlur={() => {
                        checkLimits();
                        checkifAccountNoExists();
                      }
                      }
                      readOnly={isViewMode || isEditMode || isCopyMode || bankAccountId}
                      onChange={(e) => {
                        setAccountNo(e.target.value);
                        setUnsavedChanges(true);
                        if (errors.accountNo) {
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            accountNo: "",
                          }));
                        }
                      }}
                      placeholder=""
                      className={`border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-4 text-left flex items-center justify-between text-customGray ${errors.accountNo
                        ? "border-red-500 hover:border-red-500"
                        : "border-gray-300 hover:border-blue-400"
                        }`}
                      disabled={isAcNoDisabled}
                    />
                  </div>
                  <div className="flex items-center">
                    <label className="w-48 text-sm font-medium text-gray-500">
                      Text Field{" "}
                      <span className="text-red-500">*</span>
                    </label>
                    <span className="ml-4">:</span>
                    <input
                      type="text"
                      maxLength={30}
                      readOnly={isViewMode || isEditMode || isCopyMode}
                      value={accountTextField}
                      onChange={(e) => {
                        setAccountTextField(e.target.value);
                        setUnsavedChanges(true);
                      }}
                      placeholder=""
                      className={`border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-4 text-left flex items-center justify-between text-customGray border-gray-300 hover:border-blue-400
                        `} disabled={isAcNoDisabled}
                    />
                  </div>
                </div>
                <div className="flex items-center mt-4">
                  <label className="w-52 2xl:w-48 text-sm font-medium text-gray-500">
                    IBAN No <span className="text-red-500">*</span>
                  </label>
                  <span className="ml-4">:</span>
                  <input
                    type="text"
                    autocomplete="off"
                    value={iBanNo}
                    readOnly={isViewMode || isEditMode || isCopyMode}
                    onChange={(e) => {
                      setIbanNo(e.target.value);
                      setUnsavedChanges(true);
                      if (errors.iBanNo) {
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          iBanNo: "",
                        }));
                      }
                    }}
                    maxLength={50}
                    className={`border ${errors.iBanNo ? "border-red-500" : "border-gray-300"
                      } p-1.5 rounded 2xl:w-[72%] w-full h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4`}
                    disabled={isAcNoDisabled}
                  />
                </div>
                {errors.iBanNo && (
                  <span className="text-red-500 text-sm ml-4">{errors.iBanNo}</span>
                )}
              </div>
              {/* For An Entity */}
              <div className="mb-6 border border-gray-300 p-5 rounded-lg mx-8">
                <h3 className="text-lg font-semibold text-gray-500 mb-4">
                  For an Entity
                </h3>

                <div className="grid grid-cols-2 gap-4 mb-4">
                  <div className="flex items-center">
                    <label className="w-48 text-sm font-medium text-gray-500">
                      LGE Code <span className="text-red-500">*</span>
                    </label>
                    <span className="ml-4">:</span>

                    <button
                      onClick={() => setIsLegalEntityModalOpen(true)}
                      disabled={isAccontDetaislsDisabled || isViewMode}
                      className={`border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-4 text-left flex items-center justify-between text-customGray ${errors.legalEntityID
                        ? "border-red-500 hover:border-red-500"
                        : "border-gray-300 hover:border-blue-400"
                        }`}
                    >
                      <span className="font-small text-customGray text-sm">
                        {legalEntity.find(
                          (option) => option.id.toString() == legalEntityID
                        )?.LGE_Code || "Select Legal Entity"}
                      </span>
                      <RxDropdownMenu className="ml-2" />
                    </button>

                    <ReusableModal
                      title="Select Legal Entity"
                      onRefresh={fetchLegalEntity}
                      isOpen={isLegalEntityModalOpen}
                      onClose={() => {
                        setIsLegalEntityModalOpen(false);
                        setSearchQuery("");
                      }}
                      onSave={() => {
                        setIsLegalEntityModalOpen(false);
                        setSearchQuery("");
                      }}
                      onReset={() => {
                        setLegalEntityID("");
                        setLegalEntityName("");
                      }}
                      showReset={true}
                      searchPlaceholder="Search Legal Entity..."
                      searchQuery={searchQuery}
                      onSearchChange={handleSearchChange}
                      isViewMode={isViewMode}
                    >
                      {legalEntity && legalEntity.length > 0 ? (
                        legalEntity
                          .filter((entity) =>
                            entity.LGE_Code?.toString()
                              .toLowerCase()
                              .includes(searchQuery.toLowerCase())
                          ).sort((a, b) =>
                            a.LGE_Code.localeCompare(b.LGE_Code, undefined, { sensitivity: 'base' })
                          )
                          .map((entity, index) => (
                            <div
                              key={entity.id}
                              className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""
                                }`}
                            >
                              <input
                                type="radio"
                                name="legalEntityID"
                                value={entity.id}
                                checked={legalEntityID == entity.id}
                                onChange={(e) => {
                                  handleLegalEntityChange(e.target.value);
                                  setUnsavedChanges(true);
                                  if (errors.legalEntityID) {
                                    setErrors((prevErrors) => ({
                                      ...prevErrors,
                                      branchID: "",
                                    }));
                                  }
                                }}
                                className="mr-2 accent-customBlue"
                              />
                              <label>{entity.LGE_Code || "N/A"}</label>{" "}
                            </div>
                          ))
                      ) : (
                        <p>{"No Data Found"}</p>
                      )}
                    </ReusableModal>
                  </div>
                  <div className="flex items-center">
                    <label className="w-48 text-sm font-medium text-gray-500">
                      Legal Entity Name{" "}
                    </label>
                    <span className="ml-4">:</span>
                    <button
                      onClick={() => setIsLegalEntityNameModalOpen(true)}
                      disabled={
                        isAccontDetaislsDisabled || isViewMode || isEditMode
                      }
                      className={`border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-4 text-left flex items-center justify-between text-customGray border-gray-300 hover:border-blue-400`
                      }
                    >
                      <span className="font-small text-customGray text-sm">
                        {legalEntityName || "Select LE Name"}
                      </span>
                      <RxDropdownMenu className="ml-2" />
                    </button>

                    <ReusableModal
                      title="Select LE Name"
                      onRefresh={fetchLegalEntity}
                      isOpen={isLegalEntityNameModalOpen}
                      onClose={() => {
                        setIsLegalEntityNameModalOpen(false);
                        setSearchQuery("");
                      }}
                      onSave={() => {
                        setIsLegalEntityNameModalOpen(false);
                        setSearchQuery("");
                      }}
                      searchPlaceholder="Search  LE Name..."
                      searchQuery={searchQuery}
                      onSearchChange={handleSearchChange}
                      isViewMode={isViewMode}
                      onReset={() => {
                        setLegalEntityName("");
                      }}
                      showReset={true}
                    >
                      {legalEntity && legalEntity.length > 0 ? (
                        legalEntity
                          .filter((leName) =>
                            leName.LGE_Description
                              ?.toString()
                              .toLowerCase()
                              .includes(searchQuery.toLowerCase())
                          )
                          .sort((a, b) =>
                            a.LGE_Description.localeCompare(b.LGE_Description, undefined, { sensitivity: 'base' })
                          ) // Sorts alphabetically, case-insensitive
                          .map((leName, index) => (
                            <div
                              key={leName.LGE_Description}
                              className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""
                                }`}
                            >
                              <input
                                type="radio"
                                name="LE Name"
                                value={leName.LGE_Description}
                                checked={legalEntityName == leName.LGE_Description}
                                onChange={(e) => {
                                  setLegalEntityName(e.target.value);
                                  handleLegalEntityNameChange(e.target.value);
                                  setUnsavedChanges(true);
                                }}
                                className="mr-2 accent-customBlue"
                              />
                              <label>{leName.LGE_Description || "N/A"}</label>
                            </div>
                          ))
                      ) : (
                        <p>{legalEntity ? "No Legal Entity Found" : "Loading Entity..."}</p>
                      )}

                    </ReusableModal>
                    {/* <input
                      maxLength={30}
                      disabled={isAccontDetaislsDisabled}
                      value={legalEntityName}
                      readOnly={isViewMode || isEditMode}
                      type="text"
                      placeholder="B name"
                      className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                    /> */}
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="flex items-center">
                    <label className="w-48 text-sm font-medium text-gray-500">
                      Company Code
                    </label>
                    <span className="ml-4">:</span>
                    <button
                      onClick={() => setIsCompanyModalOpen(true)}
                      disabled={
                        isViewMode ||
                        isEditMode ||
                        (isAccontDetaislsDisabled && companyId !== null && companyId !== "")
                      } className={`border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-4 text-left flex items-center justify-between text-customGray ${errors.companyId
                        ? "border-red-500 hover:border-red-500"
                        : "border-gray-300 hover:border-blue-400"
                        }`}
                    >
                      <span className="font-small text-customGray text-sm">
                        {companyId || "Select Company Code"}
                      </span>
                      <RxDropdownMenu className="ml-2" />
                    </button>
                    <ReusableModal
                      title="Select Company Code"
                      onRefresh={fetchBankAccountCompany(legalEntityID)}
                      isOpen={isCompanyModalOpen}

                      onClose={() => {
                        setIsCompanyModalOpen(false);
                        setSearchQuery("");
                      }}
                      onSave={() => {
                        setIsCompanyModalOpen(false);
                        setSearchQuery("");
                      }}
                      onReset={() => {
                        setCompanyId("");
                        setCompanyName("");
                        setBuGroup("");
                        setBuGroupName("");
                      }}
                      showReset={true}
                      searchPlaceholder="Search Company..."
                      searchQuery={searchQuery}
                      onSearchChange={handleSearchChange}
                      isViewMode={isViewMode}
                    >
                      {company && company.length > 0 ? (
                        company
                          .filter((company) =>
                            company.companyCode
                              ?.toString()
                              .toLowerCase()
                              .includes(searchQuery.toLowerCase())
                          ).sort((a, b) =>
                            a.companyCode.localeCompare(b.companyCode, undefined, { sensitivity: 'base' })
                          )
                          .map((company, index) => (
                            <div
                              key={company.companyCode}
                              className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""
                                }`}
                            >
                              <input
                                type="radio"
                                name="Company Code"
                                value={company.companyCode}
                                checked={companyId == company.companyCode}
                                onChange={(e) => {
                                  handleCompanyChange(e);
                                  setUnsavedChanges(true);
                                  if (errors.companyId) {
                                    setErrors((prevErrors) => ({
                                      ...prevErrors,
                                      companyId: "",
                                    }));
                                  }
                                }}
                                className="mr-2 accent-customBlue"
                              />
                              <label>{company.companyCode || "N/A"}</label>{" "}
                            </div>
                          ))
                      ) : (
                        <p>{"No Data Found"}</p>
                      )}
                    </ReusableModal>
                  </div>
                  <div className="flex items-center">
                    <label className="w-48 text-sm font-medium text-gray-500">
                      Company Name{" "}
                    </label>
                    <span className="ml-4">:</span>
                    <button
                      onClick={() => setIsCompanyNameModalOpen(true)}
                      disabled={
                        isAccontDetaislsDisabled || isViewMode || isEditMode
                      }
                      className={`border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-4 text-left flex items-center justify-between text-customGray border-gray-300 hover:border-blue-400`
                      }
                    >
                      <span className="font-small text-customGray text-sm">
                        {companyName || "Select Company Name"}
                      </span>
                      <RxDropdownMenu className="ml-2" />
                    </button>

                    <ReusableModal
                      title="Select Company Name"
                      onRefresh={fetchBankAccountCompany(legalEntityID)}
                      isOpen={isCompanyNameModalOpen}
                      onClose={() => {
                        setIsCompanyNameModalOpen(false);
                        setSearchQuery("");
                      }}
                      onSave={() => {
                        setIsCompanyNameModalOpen(false);
                        setSearchQuery("");
                      }}
                      searchPlaceholder="Search Company..."
                      searchQuery={searchQuery}
                      onSearchChange={handleSearchChange}
                      isViewMode={isViewMode}
                      onReset={() => {
                        setCompanyName("");
                      }}
                      showReset={true}
                    >
                      {company && company.length > 0 ? (
                        company
                          .filter((comp) =>
                            comp.companyName
                              ?.toString()
                              .toLowerCase()
                              .includes(searchQuery.toLowerCase())
                          )
                          .sort((a, b) =>
                            a.companyName.localeCompare(b.companyName, undefined, { sensitivity: 'base' })
                          ) // Case-insensitive alphabetical sorting
                          .map((comp, index) => (
                            <div
                              key={comp.companyName}
                              className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""}`}
                            >
                              <input
                                type="radio"
                                name="Company Name"
                                value={comp.companyName}
                                checked={companyName === comp.companyName}
                                onChange={(e) => {
                                  setCompanyName(e.target.value);
                                  handleCompanyNameChange(e);
                                  setUnsavedChanges(true);
                                }}
                                className="mr-2 accent-customBlue"
                              />
                              <label>{comp.companyName || "N/A"}</label>
                            </div>
                          ))
                      ) : (
                        <p>{company ? "No Company Found" : "Loading Company..."}</p>
                      )}


                    </ReusableModal>
                    {/* <input
                      maxLength={30}
                      value={companyName}
                      disabled={isAccontDetaislsDisabled}
                      readOnly={isViewMode || isEditMode}
                      type="text"
                      placeholder=""
                      className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                    /> */}
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4 mt-4">
                  <div className="flex items-center">
                    <label className="w-48 text-sm font-medium text-gray-500">
                      BU Group{" "}
                    </label>
                    <span className="ml-4">:</span>
                    <input
                      maxLength={30}
                      value={buGroup}
                      disabled={isAccontDetaislsDisabled}
                      readOnly={isViewMode || isEditMode}
                      type="text"
                      placeholder=""
                      className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                    />
                  </div>
                  <div className="flex items-center">
                    <label className="w-48 text-sm font-medium text-gray-500">
                      BU Group Name{" "}
                    </label>
                    <span className="ml-4">:</span>
                    <input
                      maxLength={30}
                      value={buGroupName}
                      disabled={isAccontDetaislsDisabled}
                      readOnly={isViewMode || isEditMode}
                      type="text"
                      placeholder="B name"
                      className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                    />
                  </div>
                </div>
              </div>
            </div>

          </TableWrapper>
        </div>

      )}
      {/*Bank Address*/}
      {activeTab === "bankaddress" && (
        <div className="m-6 border border-gray-200 p-5  rounded-lg">
          <TableWrapper maxHeight="70vh">


            <div className=" mb-10">
              <div className="mb-6 border border-gray-300  mx-8 rounded-lg p-5">
                <h3 className="text-lg font-semibold text-gray-500 mb-4">
                  Building
                </h3>

                <div className="grid grid-cols-2 gap-4">
                  <div className="flex items-center">
                    <label className="w-48 text-sm font-medium text-gray-500">
                      Door No{" "}
                    </label>
                    <span className="ml-4">:</span>
                    <input
                      maxLength={30}
                      type="text"
                      value={doorNo}
                      readOnly={isViewMode || isEditMode}
                      className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                    />
                  </div>
                  <div className="flex items-center">
                    <label className="w-48 text-sm font-medium text-gray-500">
                      Floor No{" "}
                    </label>
                    <span className="ml-4">:</span>
                    <input
                      maxLength={30}
                      value={floorNo}
                      readOnly={isViewMode || isEditMode}
                      type="text"
                      placeholder=""
                      className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                    />
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4 mt-4">
                  <div className="flex items-center">
                    <label className="w-48 text-sm font-medium text-gray-500">
                      Building No{" "}
                    </label>
                    <span className="ml-4">:</span>
                    <input
                      maxLength={30}
                      value={buildingNo}
                      readOnly={isViewMode || isEditMode}
                      type="text"
                      placeholder=""
                      className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                    />
                  </div>
                  <div className="flex items-center">
                    <label className="w-48 text-sm font-medium text-gray-500">
                      Building Name{" "}
                    </label>
                    <span className="ml-4">:</span>
                    <input
                      maxLength={30}
                      value={buildingName}
                      readOnly={isViewMode || isEditMode}
                      type="text"
                      placeholder=""
                      className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                    />
                  </div>
                  <div className="flex items-center">
                    <label className="w-48 text-sm font-medium text-gray-500">
                      Landmark{" "}
                    </label>
                    <span className="ml-4">:</span>
                    <input
                      maxLength={30}
                      value={landMark}
                      readOnly={isViewMode || isEditMode}
                      type="text"
                      placeholder=""
                      className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                    />
                  </div>
                </div>
              </div>
              {/** */}
              <div className="mb-6 border border-gray-300 p-5 mx-8 rounded-lg">
                <h3 className="text-lg font-semibold text-gray-500 mb-4">Street</h3>

                <div className="grid grid-cols-2 gap-4">
                  <div className="flex items-center">
                    <label className="w-48 text-sm font-medium text-gray-500">
                      Street No{" "}
                    </label>
                    <span className="ml-4">:</span>
                    <input
                      maxLength={30}
                      value={streetNo}
                      readOnly={isViewMode || isEditMode}
                      type="text"
                      className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                    />
                  </div>
                  <div className="flex items-center">
                    <label className="w-48 text-sm font-medium text-gray-500">
                      Street Name{" "}
                    </label>
                    <span className="ml-4">:</span>
                    <input
                      maxLength={30}
                      value={streetName}
                      readOnly={isViewMode || isEditMode}
                      type="text"
                      placeholder=""
                      className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                    />
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4 mt-4">
                  <div className="flex items-center">
                    <label className="w-48 text-sm font-medium text-gray-500">
                      Road No{" "}
                    </label>
                    <span className="ml-4">:</span>
                    <input
                      maxLength={30}
                      value={roadNo}
                      readOnly={isViewMode || isEditMode}
                      type="text"
                      placeholder=""
                      className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                    />
                  </div>
                  <div className="flex items-center">
                    <label className="w-48 text-sm font-medium text-gray-500">
                      Road Name{" "}
                    </label>
                    <span className="ml-4">:</span>
                    <input
                      maxLength={30}
                      value={roadName}
                      readOnly={isViewMode || isEditMode}
                      type="text"
                      placeholder=""
                      className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                    />
                  </div>
                </div>
              </div>
              <div className="mb-6 border border-gray-300 p-5 mx-8 rounded-lg">
                <h3 className="text-lg font-semibold text-gray-500 mb-4">Area</h3>

                <div className="grid grid-cols-2 gap-4">
                  <div className="flex items-center">
                    <label className="w-48 text-sm font-medium text-gray-500">
                      Block{" "}
                    </label>
                    <span className="ml-4">:</span>
                    <input
                      maxLength={30}
                      value={areaBlock}
                      readOnly={isViewMode || isEditMode}
                      type="text"
                      className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                    />
                  </div>
                  <div className="flex items-center">
                    <label className="w-48 text-sm font-medium text-gray-500">
                      Area Name{" "}
                    </label>
                    <span className="ml-4">:</span>
                    <input
                      maxLength={30}
                      value={areaName}
                      readOnly={isViewMode || isEditMode}
                      type="text"
                      placeholder=""
                      className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                    />
                  </div>
                </div>
              </div>
              <div className="mb-6 border border-gray-300 p-5 mx-8 rounded-lg">
                <h3 className="text-lg font-semibold text-gray-500 mb-4">
                  Address
                </h3>

                <div className="grid grid-cols-1 gap-4">
                  <div className="flex items-center">
                    <label className="w-48 text-sm font-medium text-gray-500">
                      Address 1{" "}
                    </label>
                    <span className="ml-4">:</span>
                    <input
                      maxLength={30}
                      value={addressLine1}
                      type="text"
                      readOnly={isViewMode || isEditMode}
                      className="border border-gray-300 p-1.5 rounded 2xl:w-[72%] w-full h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1 gap-4 mt-4">
                  <div className="flex items-center">
                    <label className="w-48 text-sm font-medium text-gray-500">
                      Address 2{" "}
                    </label>
                    <span className="ml-4">:</span>
                    <input
                      maxLength={30}
                      value={addressLine2}
                      readOnly={isViewMode || isEditMode}
                      type="text"
                      placeholder=""
                      className="border border-gray-300 p-1.5 rounded 2xl:w-[72%] w-full h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1 gap-4 mt-4">
                  <div className="flex items-center">
                    <label className="w-48 text-sm font-medium text-gray-500">
                      Address 3{" "}
                    </label>
                    <span className="ml-4">:</span>
                    <input
                      maxLength={30}
                      value={addressLine3}
                      readOnly={isViewMode || isEditMode}
                      type="text"
                      placeholder=""
                      className="border border-gray-300 p-1.5 rounded 2xl:w-[72%] w-full h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                    />
                  </div>
                </div>
              </div>

              <div className="mb-6 border border-gray-300 p-5 mx-8 rounded-lg">
                <h3 className="text-lg font-semibold text-gray-500 mb-4">
                  Country
                </h3>
                <div className="grid grid-cols-2 gap-4">
                  <div className="flex items-center">
                    <label className="w-48 text-sm font-medium text-gray-500">
                      Country Code <span className="text-red-500">*</span>
                    </label>
                    <span className="ml-4">:</span>
                    <input
                      maxLength={30}
                      value={country}
                      readOnly={isViewMode || isEditMode}
                      type="text"
                      placeholder=""
                      className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                    />
                    {/* <select
                  placeholder=""
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                /> */}
                  </div>
                  <div className="flex items-center">
                    <label className="w-48 text-sm font-medium text-gray-500">
                      Country Name{" "}
                    </label>
                    <span className="ml-4">:</span>
                    <input
                      maxLength={30}
                      value={countriesName}
                      readOnly={isViewMode || isEditMode}
                      type="text"
                      placeholder=""
                      className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                    />
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4 mt-4">
                  <div className="flex items-center">
                    <label className="w-48 text-sm font-medium text-gray-500">
                      State / Province<span className="text-red-500">*</span>{" "}
                    </label>
                    <span className="ml-4">:</span>
                    <input
                      maxLength={30}
                      value={stateProvinces}
                      readOnly={isViewMode || isEditMode}
                      type="text"
                      placeholder=""
                      className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                    />
                    {/* <select
                  placeholder=""
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                /> */}
                  </div>
                  <div className="flex items-center">
                    <label className="w-48 text-sm font-medium text-gray-500">
                      State / Province Name{" "}
                    </label>
                    <span className="ml-4">:</span>
                    <input
                      maxLength={30}
                      value={stateName}
                      readOnly={isViewMode || isEditMode}
                      type="text"
                      placeholder=""
                      className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4 mt-4">
                  <div className="flex items-center">
                    <label className="w-48 text-sm font-medium text-gray-500">
                      District{" "}
                    </label>
                    <span className="ml-4">:</span>
                    <input
                      maxLength={30}
                      value={district}
                      readOnly={isViewMode || isEditMode}
                      type="text"
                      placeholder=""
                      className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                    />
                  </div>
                  <div className="flex items-center">
                    <label className="w-48 text-sm font-medium text-gray-500">
                      Postal Code{" "}
                    </label>
                    <span className="ml-4">:</span>
                    <input
                      maxLength={30}
                      value={postalCode}
                      readOnly={isViewMode || isEditMode}
                      type="text"
                      placeholder=""
                      className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4 mt-4">
                  <div className="flex items-center">
                    <label className="w-48 text-sm font-medium text-gray-500">
                      Zip Code{" "}
                    </label>
                    <span className="ml-4">:</span>
                    <input
                      maxLength={30}
                      value={zipCode}
                      readOnly={isViewMode || isEditMode}
                      type="text"
                      placeholder=""
                      className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                    />
                  </div>
                  <div className="flex items-center">
                    <label className="w-48 text-sm font-medium text-gray-500">
                      PO Box{" "}
                    </label>
                    <span className="ml-4">:</span>
                    <input
                      maxLength={30}
                      value={poBox}
                      readOnly={isViewMode || isEditMode}
                      type="text"
                      placeholder=""
                      className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                    />
                  </div>
                </div>
              </div>
            </div>
          </TableWrapper>
        </div>
      )}
      {/*Identifiers*/}

      {activeTab === "identifiers" && (
        <div className="m-6 border border-gray-200 p-5  rounded-lg">
          <div className="flex flex-col space-y-8 col-span-1 p-6">
            <div className=" space-y-8">
              {/* First row: Single field (LGE) */}
              <div className="flex items-center">
                <label className="flex items-center w-full">
                  <span className="w-48 text-sm font-small text-customGray">
                    Bank BIC
                  </span>
                  <span className="ml-4">:</span>
                  <input
                    value={bankBic}

                    type="text"
                    maxLength={30}
                    readOnly
                    className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                    required
                  />
                </label>

              </div>

              {/* Second row: Two fields (LGE Code and LGE Description) */}
              <div className="grid grid-cols-2 gap-8">
                <div className="flex items-center">
                  <label className="flex items-center w-full">
                    <span className="w-48 text-sm font-small text-customGray">
                      Bank Country Code
                    </span>
                    <span className="ml-4">:</span>
                    <input
                      value={countryCode}

                      type="text"
                      maxLength={30}
                      readOnly
                      className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                      required
                    />
                  </label>
                </div>

                <div className="flex items-center">
                  <label className="flex items-center w-full">
                    <span className="w-48 text-sm font-small text-customGray">
                      Country Code Type
                    </span>
                    <span className="ml-4">:</span>
                    <input
                      value={countryCodeType}
                      readOnly
                      type="text"
                      maxLength={30}

                      className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                      required
                    />
                  </label>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-8">
                <div className="flex items-center">
                  <label className="flex items-center w-full">
                    <span className="w-48 text-sm font-small text-customGray">
                      Customer Code
                    </span>
                    <span className="ml-4">:</span>
                    <input
                      type="text"
                      maxLength={12}
                      value={customerCode}

                      readOnly
                      className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                      disabled={isViewMode}
                    />
                  </label>
                </div>

                <div className="flex items-center">
                  <label className="flex items-center w-full">
                    <span className="w-48 text-sm font-small text-customGray">
                      MICR
                    </span>
                    <span className="ml-4">:</span>
                    <input
                      value={micr}
                      type="text"
                      maxLength={30}
                      readOnly
                      className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                      required
                    />
                  </label>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-8">
                <div className="flex items-center">
                  <label className="flex items-center w-full">
                    <span className="w-48 text-sm font-small text-customGray">
                      Bank Code
                    </span>
                    <span className="ml-4">:</span>
                    <input
                      type="text"
                      maxLength={12}
                      value={bankCode}
                      readOnly
                      className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                      disabled={isViewMode}
                    />
                  </label>
                </div>

                <div className="flex items-center">
                  <label className="flex items-center w-full">
                    <span className="w-48 text-sm font-small text-customGray">
                      Branch Code
                    </span>
                    <span className="ml-4">:</span>
                    <input
                      type="text"
                      maxLength={12}
                      value={branchCode}
                      readOnly={true}
                      className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                      disabled={isViewMode}
                    />
                  </label>
                </div>
              </div>
            </div>
            {/* <CustomButton onClick={nextToBranchSpoc} title={"Next"}/> */}
          </div>
        </div>
      )}

      {/*Bank Statements*/}
      {activeTab === "bankstatements" && (
        <div className="m-6 border border-gray-200 p-5  rounded-lg">

          <div className="p-4 mb-10">
            <div className="flex flex-col gap-y-4 mt-4 ml-10">
              {/* <label className="flex items-center"> */}
              {/* <span className="w-60 text-sm font-medium text-gray-500">
              Statement Type<span className="text-red-500">*</span>
            </span>
            <span className="ml-3">:</span>

            <div>

              <button
                onClick={() => setIsStatementTypeModalOpen(true)}
                disabled={isAccontDetaislsDisabled || isViewMode}
                className={`border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-4 text-left flex items-center justify-between text-customGray ${"border-gray-300 hover:border-blue-400"}`}
              >
                <span className="font-small text-customGray text-sm">
                  {statementTypeOptions.find(
                    (option) => option.value === statementId
                  )?.label || "Select Statement Type"}
                </span>
                <RxDropdownMenu className="ml-2" />
              </button> */}
              <LabelWithButton
                label="Statement Type"
                isDisabled={isViewMode}
                onClick={() => {
                  setIsStatementTypeModalOpen(true);
                }}
                buttonText={
                  statementTypeOptions.find(
                    (option) => option.value.toString() === statementId
                  )?.label || "Select Statement Type"
                }
                error={errors.companyCodeId}
                width="w-80"
                marginLeft="ml-4"
                spanMargin="ml-16"
              />

              <ReusableModal
                title="Select Statement Type"
                isOpen={isStatementTypeModalOpen}
                onClose={() => {
                  setIsStatementTypeModalOpen(false);

                  setSearchQuery("");
                }}
                onSave={() => {
                  setIsStatementTypeModalOpen(false);

                  setSearchQuery("");
                }}
                searchPlaceholder="Search Statement Type..."
                searchQuery={searchQuery}
                onSearchChange={handleSearchChange}
                isViewMode={isViewMode}
                onReset={() => {
                  setStatementId("");
                }}
                showReset={true}
              >
                {statementTypeOptions && statementTypeOptions.length > 0 ? (
                  statementTypeOptions
                    .filter((statement) =>
                      statement.label
                        ?.toLowerCase()
                        .includes(searchQuery.toLowerCase())
                    ).sort((a, b) =>
                      a.label.localeCompare(b.label, undefined, { sensitivity: 'base' })
                    )
                    .map((statement, index) => (
                      <div
                        key={statement.value}
                        className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""
                          }`}
                      >
                        <input
                          type="radio"
                          name="StatementType"
                          value={statement.value}
                          checked={statementId === statement.value.toString()}
                          onChange={(e) => {
                            setStatementId(e.target.value);
                            setUnsavedChanges(true);
                          }}
                          className="mr-2 accent-customBlue"
                        />
                        <label>{statement.label || "N/A"}</label>
                      </div>
                    ))
                ) : (
                  <p>{"No Statement Type Found"}</p>
                )}
              </ReusableModal>

              {/* </label> */}
              <label className="flex items-center">
                <span className="w-60 text-sm font-medium text-gray-500">
                  Statement identifier
                </span>
                <span className="ml-3">:</span>
                <input
                  autoComplete="off"
                  onChange={(e) => {
                    setStatementIdentifier(e.target.value);
                    setUnsavedChanges(true);
                  }}
                  value={statementIdentifier}
                  readOnly={isViewMode || isEditMode}
                  type="text"
                  maxLength={30}
                  className="border p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                  required
                />
              </label>
              <label className="flex items-center">
                <span className="w-60 text-sm font-medium text-gray-500">
                  Last Statement date
                </span>
                <span className="ml-3">:</span>
                <input
                  onChange={(e) => {
                    setLastStatementDate(e.target.value);
                    setUnsavedChanges(true);
                  }}
                  value={lastStatementDate}
                  readOnly={isViewMode || isEditMode}
                  type="date"
                  lang="en-GB"
                  className="border p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                  required
                  maxLength={30}
                />
              </label>
              <label className="flex items-center">
                <span className="w-60 text-sm font-medium text-gray-500">
                  Last statement Closing balance
                </span>
                <span className="ml-3">:</span>
                <input
                  onChange={(e) => {
                    setLastStateClosingBalance(e.target.value);
                    setUnsavedChanges(true);
                  }}
                  value={lastStateClosingbalance}
                  type="text"
                  maxLength={30}
                  readOnly={isViewMode || isEditMode}
                  className="border p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                  required
                />
              </label>
              <label className="flex items-center">
                <span className="w-60 text-sm font-medium text-gray-500">
                  Last Statement No
                </span>
                <span className="ml-3">:</span>
                <input
                  onChange={(e) => {
                    setLastStatementNo(e.target.value);
                    setUnsavedChanges(true);
                  }}
                  value={lastStatementNo}
                  type="text"
                  maxLength={30}
                  className="border p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                  required
                  readOnly={isViewMode || isEditMode}
                />
              </label>
            </div>
          </div>
        </div>
      )}
      {/*Validity*/}
      {activeTab === "validity" && (
        <div className="m-6 border border-gray-200 p-5  rounded-lg">
          <TableWrapper maxHeight="70vh">
            <div className="mt-10">
              <ReTable
                data={validityId}
                columns={columns}
                onSelectRow={onSelectRow}
                selectedRows={selectedRows}
                // onSelectAll={onSelectAll}
                showCheckbox={false}
                onRowDoubleClick={onRowDoubleClick}
              />
            </div>
          </TableWrapper>
        </div>
      )}
      {/*OD/CC*/}
      {activeTab === "odcc" && (
        <div className="m-6 border border-gray-200 p-5  rounded-lg">

          <div className="p-4 mb-10">
            <div className="flex flex-col gap-y-4 mt-4 ml-10">
              <label className="flex items-center">
                <span className="w-48 text-sm font-medium text-gray-500">
                  OD limit
                </span>
                <span className="ml-3">:</span>
                <input
                  value={odLimit}
                  maxLength={30}
                  onChange={(e) => {
                    setOdLimit(e.target.value);
                    setUnsavedChanges(true);
                    if (errors.odLimit) {
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        odLimit: "",
                      }));
                    }
                  }}
                  type="text"
                  className={`border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-4 ${errors.odLimit
                    ? "border-red-500 hover:border-red-500"
                    : "border-gray-300 hover:border-blue-400"
                    }`}
                  required
                  readOnly={isViewMode}
                />
              </label>
              <label className="flex items-center">
                <span className="w-48 text-sm font-medium text-gray-500">
                  Intrest Indicator
                </span>
                <span className="ml-3">:</span>
                <input
                  value={intrestIndicator}
                  onChange={(e) => {
                    setIntrestIndicator(e.target.value);
                    setUnsavedChanges(true);
                    if (errors.intrestIndicator) {
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        intrestIndicator: "",
                      }));
                    }
                  }}
                  maxLength={30}
                  type="text"
                  className={`border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-4 ${errors.intrestIndicator
                    ? "border-red-500 hover:border-red-500"
                    : "border-gray-300 hover:border-blue-400"
                    }`}
                  required
                  readOnly={isViewMode}
                />
              </label>
              <label className="flex items-center">
                <span className="w-48 text-sm font-medium text-gray-500">
                  OD Date
                </span>
                <span className="ml-3">:</span>
                <input
                  value={odDate}
                  onChange={(e) => {
                    setOdDate(e.target.value);
                    setUnsavedChanges(true);
                    if (errors.odDate) {
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        odDate: "",
                      }));
                    }
                  }}
                  type="Date"
                  className={`border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-4 ${errors.odDate
                    ? "border-red-500 hover:border-red-500"
                    : "border-gray-300 hover:border-blue-400"
                    }`}
                  required
                  readOnly={isViewMode}
                  maxLength={30}
                />
              </label>
            </div>
          </div>
        </div>
      )}
      {/*Controls*/}
      {activeTab === "controls" && (
        <div className="m-6 border border-gray-200 p-5  rounded-lg">

          <div className="p-4 mb-10">
            <div className="flex flex-col gap-y-4 mt-4 ml-10">
              <label className="flex items-center">
                <span className="w-48 text-sm font-medium text-gray-500">
                  Minimum Balance
                </span>
                <span className="ml-3">:</span>
                <input
                  value={minimumBalance}
                  maxLength={30}
                  onChange={(e) => {
                    setMinimumBalance(e.target.value);
                    setUnsavedChanges(true);
                  }}
                  type="text"
                  className="border p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                  required
                  readOnly={isViewMode}
                />
              </label>
              <label className="flex items-center">
                <span className="w-48 text-sm font-medium text-gray-500">
                  Dormant
                </span>
                <span className="ml-3 ">:</span>
                <input
                  value={dormant}
                  maxLength={30}
                  onChange={(e) => {
                    setDormant(e.target.value);
                    setUnsavedChanges(true);
                  }}
                  type="number"
                  className="border p-1.5 rounded w-14 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                  required
                  readOnly={isViewMode}
                />
                <span className="ml-6 text-sm font-medium text-gray-500">
                  Months
                </span>
              </label>
              {/* <label className="flex items-center mt-8 mb-4">
              <input
                type="checkbox"
                checked={isZbaType === 1}
                onChange={(e) => setIsZbaType(e.target.checked ? 1 : 0)}
                className="form-checkbox text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500"
              />
              <span className="ml-4 text-sm font-semibold text-gray-600">
                Zero Balance Account
              </span>
            </label> */}
              <label className="flex items-center">
                <span className="w-48 text-sm font-medium text-gray-500">
                  ZBA Type
                </span>
                <span className="ml-3">:</span>
                <input
                  value={zbaid}
                  maxLength={30}
                  //onChange={(e) => setDormant(e.target.value)}
                  type="text"
                  className="border p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                  required
                  readOnly={true}
                  disabled={isAccontDetaislsDisabled || isViewMode}
                />
              </label>

              <LabelWithButton
                label="Main Bank Account"
                isDisabled={isAccontDetaislsDisabled || isViewMode}
                onClick={() => {
                  setIsMainBankModalOpen(true);
                }}
                buttonText={mainBankAccount || "Select Main Bank"}
                error={errors.companyCodeId}
                width="w-80"
                marginLeft="ml-4"
                spanMargin="ml-3"
              />

              {/* <span className="w-48 text-sm font-medium text-gray-500">
              Main Bank Account
            </span>
            <span className="ml-3">:</span>

            <button
              onClick={() => {
                setIsMainBankModalOpen(true);
              }}
              disabled={isAccontDetaislsDisabled || isViewMode}
              className={`border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-4 text-left flex items-center justify-between text-customGray ${"border-gray-300 hover:border-blue-400"}`}
            >
              <span className="font-small text-customGray text-sm">
                {mainBankAccount || "Select Main Bank"}
              </span>
              <RxDropdownMenu className="ml-2" />
            </button> */}

              <ReusableModal
                title="Select Main Bank Account"
                isOpen={isMainBankModalOpen}
                onRefresh={fetchMainBankAccount}
                onClose={() => {
                  setIsMainBankModalOpen(false);
                }}
                onSave={() => {
                  setIsMainBankModalOpen(false);
                }}
                searchPlaceholder="Search Main Bank Account..."
                searchQuery={searchQuery}
                onSearchChange={handleSearchChange}
                isViewMode={isViewMode}
                onReset={() => {
                  setMainAccount("");
                }}
                showReset={true}
              >
                {mainBankAccountOptions && mainBankAccountOptions.length > 0 ? (
                  mainBankAccountOptions
                    .filter((option) =>
                      option.label
                        ?.toLowerCase()
                        .includes(searchQuery.toLowerCase())
                    ).sort((a, b) =>
                      a.label.localeCompare(b.label, undefined, { sensitivity: 'base' })
                    )
                    .map((option, index) => (
                      <div
                        key={option.value}
                        className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""
                          }`}
                      >
                        <input
                          type="radio"
                          name="MainBankAccount"
                          value={option.value}
                          checked={mainBankAccount === option.value}
                          onChange={(e) => {
                            setMainBankAccount(e.target.value);
                            setUnsavedChanges(true);
                          }}
                          className="mr-2 accent-customBlue"
                        />
                        <label>{option.label || "N/A"}</label>
                      </div>
                    ))
                ) : (
                  <p>{"No Main Bank Account Found"}</p>
                )}
              </ReusableModal>
            </div>
          </div>
        </div>
      )}
      {/*Textfields Contents*/}
      {activeTab === "textfields" && (
        <div className="m-6 border border-gray-200 p-5 rounded-lg">
          <div className="p-4 mb-10">
            <div className="flex flex-col gap-y-4 mt-4 ml-10">
              <label className="flex items-center">
                <span className="w-24 text-sm font-medium text-gray-500">
                  Text 1
                </span>
                <span className="ml-3">:</span>
                <input
                  value={textFields1}
                  type="text"
                  maxLength={30}
                  onChange={(e) => {
                    setTextFields1(e.target.value);
                    setUnsavedChanges(true);
                  }}
                  className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                  required
                  readOnly={isViewMode}
                />
              </label>
              <label className="flex items-center">
                <span className="w-24 text-sm font-medium text-gray-500">
                  Text 2
                </span>
                <span className="ml-3">:</span>
                <input
                  value={textFields2}
                  type="text"
                  maxLength={30}
                  onChange={(e) => {
                    setTextFields2(e.target.value);
                    setUnsavedChanges(true);
                  }}
                  className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                  required
                  readOnly={isViewMode}
                />
              </label>
              <label className="flex items-center">
                <span className="w-24 text-sm font-medium text-gray-500">
                  Text 3
                </span>
                <span className="ml-3">:</span>
                <input
                  value={textFields3}
                  type="text"
                  maxLength={30}
                  onChange={(e) => {
                    setTextFields3(e.target.value);
                    setUnsavedChanges(true);
                  }}
                  className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                  required
                  readOnly={isViewMode}
                />
              </label>
              <label className="flex items-center">
                <span className="w-24 text-sm font-medium text-gray-500">
                  Text 4
                </span>
                <span className="ml-3">:</span>
                <input
                  value={textFields4}
                  type="text"
                  maxLength={30}
                  onChange={(e) => {
                    setTextFields4(e.target.value);
                    setUnsavedChanges(true);
                  }}
                  className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                  required
                  readOnly={isViewMode}
                />
              </label>
              <label className="flex items-center">
                <span className="w-24 text-sm font-medium text-gray-500">
                  Text 5
                </span>
                <span className="ml-3">:</span>
                <input
                  value={textFields5}
                  type="text"
                  maxLength={30}
                  onChange={(e) => {
                    setTextFields5(e.target.value);
                    setUnsavedChanges(true);
                  }}
                  className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                  required
                  readOnly={isViewMode}
                />
              </label>
            </div>
          </div>
        </div>
      )}
      <div className="ml-2"></div>
      {!isViewMode && (
        <Footer>
          {/* <button
            onClick={(e) => handleSaveClick(actionId, e)}
            className="bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ml-7"
          >
            Save
          </button> */}
          <button
            onClick={hasPermissionForSave ? (e) => handleSaveClick(actionId, e) : null} // Only allow onClick if permission exists
            disabled={!hasPermissionForSave} // Disable button if no permission
            title={!hasPermissionForSave ? "You don't have permission" : ""} // Show tooltip if no permission
            className={`bg-customBlue text-sm text-white py-1 px-2 rounded transition duration-200 ease-in-out w-16 ml-7 ${hasPermissionForSave ? "hover:bg-blue-600" : "bg-gray-400"
              }`} // Change style dynamically
          >
            Save
          </button>



          {!(isEditMode || isCopyMode || isAccontDetaislsDisabled) && (
            <button
              onClick={hasPermissionForSaveANDContinue ? (e) => handleSave(e, true) : null}
              disabled={!hasPermissionForSaveANDContinue} // Disable button if no permission
              title={!hasPermissionForSaveANDContinue ? "You don't have permission" : ""} // Show tooltip if no permission
              className={`text-sm border 
                ${hasPermissionForSaveANDContinue ? "hover:bg-blue-600 bg-white hover:bg-customBlue border-customBlue text-customBlue hover:text-white " : "bg-gray-400 text-white"
                }

               
                py-1 px-2 rounded 
                transition duration-200 ease-in-out ml-4 w-30`}
            >
              Save & Continue
            </button>
          )}
        </Footer>
      )}
    </div>
  );
}
