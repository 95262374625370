import RestService from "./RestServices";

const BuGroupServices = {
  getAllBuGroup: async (path) => {
    try {
      const response = await RestService.GetAllData(path);
      return response;
    } catch (error) {
      console.error("Error fetching Calendar data:", error);
      return { error: true, message: "Error fetching Calendar data" };
    }
  },
  getBuGroupById: (clientId, buGroupId) => {
    return RestService.GetAllData(`/get-bugroup-id/${buGroupId}?clientId=${clientId}`);
  },
  
 deleteBuGroup: (id, clientId) => {
  // Append clientId as a query parameter to the URL
  return RestService.DeleteclientData(`/delete-bugroup?clientId=${clientId}&id=${id}`,);
},
  addBuGroup: async (path, data) => {
    try {
      console.log("Sending data:", data);
      const response = await RestService.CreateData(path, data);
      console.log("Response from Server:", response);
      return response;
    } catch (error) {
      console.error("Error adding Calendar:", error);
      return { error: true, message: "Error adding Calendar" };
    }
  },
  getFilterBugroup: async (path, data) => {
    try {
      console.log("Sending data:", data);
      const response = await RestService.CreateData(path, data);
      console.log("Response from Server:", response);
      return response;
    } catch (error) {
      console.error("Error getting filter bugroups:", error);
      return { error: true, message: "Error getting filter bugroups" };
    }
  },
  checkDuplicateBugroupcode: async (path, roleData) => {
    try {
      const response = await RestService.CreateData(path, roleData);
      console.log("Response from server:", response);

      return response;
    } catch (error) {
      console.error("Error fetching lge code:", error);
      return { error: true, message: "Error fetching bugroup code" };
    }
  },
  getAllbugroupwithPage: (pageSize, offset, searchQuery = "", clientId) => {
    const searchParam = searchQuery ? `&searchQuery=${encodeURIComponent(searchQuery)}` : "";
    const clientParam = clientId ? `&clientId=${encodeURIComponent(clientId)}` : "";
    return RestService.GetAllData(
      `/getallbugroupbypage?limit=${pageSize}&offset=${offset}${searchParam}${clientParam}`
    );
  },
  saveapproved: (path, data) => {
    try {
      console.log("Sending data:", data);
      const response = RestService.CreateData(path, data);
      console.log("Response from server:", response);
      return response;
    } catch (error) {
      console.error("Error updating users:", error);
      return { error: true, message: "Error updating users" };
    }
  },
  getAudittrail: (path, data) => {
    try {
      const response = RestService.CreateData(path, data);
      return response;
    } catch (error) {
      console.error("Error getting audittrail:", error);
      return { error: true, message: "Error getting audittrail" };
    }
  },
};

export default BuGroupServices;
