import React, { useEffect, useMemo, useRef, useState } from "react";
import UserService from "../../../../rest-services/UserServices"; // Assuming you have UserService for making API requests
import ReTable from "../../../../components/Table";
import Footer from "../../../../components/footer";
import { useToast } from "../../../../components/toast/toast";
import { Radio } from "@mui/material";
import CustomeLoading from "../../../../components/CustomeLoading";
import TableWrapper from "../../../../components/ScrollBarComponent";
import { IoMdCreate } from "react-icons/io";
import { useParams } from "react-router-dom";

const UserTable = () => {
  const { toast } = useToast();
  const { iconId } = useParams();
  const [users, setUsers] = useState([]);
  const [userTypes, setUserTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedUserTypes, setSelectedUserTypes] = useState({});
  const [purchaseData, setPurchaseData] = useState([]);
  const [editView, setEditView] = useState([]);
  const [assignee, setAssignee] = useState({ id: "", username: "" });
  const [alert, setAlert] = useState(null);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [assignedData, setAssignedData] = useState({});
  const [availableData, setAvailableData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rolesPermissions, setRolesPermissions] = useState([]);
  const [error, setError] = useState(null);
  const [selectedPurchaseDetails, setSelectedPurchaseDetails] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState([]);
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [purchaseDetails, setPurchaseDetails] = useState([]);

  useEffect(() => {
    if (assignee.clientId && rolesPermissions.length > 0) {
      fetchUsers();
    }
  }, [assignee.clientId, rolesPermissions]);



  useEffect(() => {
    if (assignee.assignerId) {
      console.log("Assigner ID has changed:", assignee.assignerId);
      fetchUserPermissionDetails();
    }
  }, [assignee.assignerId]);


  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");

      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.username || "",
          clientId: userDetail.clientId || "",
          assignerId: userDetail.userId || "",
        };
        console.log("assigneeData is", assigneeData);

        setAssignee(assigneeData);

        // Log the id and assigner values to the console
        console.log("Assignee ID:", assigneeData.id);
        console.log("Assigner:", assigneeData.assigner);
        console.log("Assigner userId:", assigneeData.assignerId);
        console.log("Assigner clientId:", assigneeData.clientId);
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

  // Fetch user types using fetchUserTypes method
  const fetchUserTypes = async () => {
    try {
      const response = await UserService.getUsers(
        `${process.env.REACT_APP_BASE_URL}/get-user-type`
      );
      const sortedUserTypes = response.data.data.sort((a, b) => a.id - b.id); // Sort user types by id in ascending order
      console.log("set user types", sortedUserTypes);
      setUserTypes(sortedUserTypes); // Update userTypes state with sorted data
    } catch (error) {
      console.error("Error fetching user types:", error);
    }
  };

  const fetchUserPermissionDetails = async () => {
    try {
      const data = { userId: assignee.assignerId };
      console.log("Request Data:", data);

      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-users-permissions-by-userId`,
        data
      );
      console.log("user permissions details", response);
      const responseData = response.data;
      setRolesPermissions(responseData.rolesPermissionsTables || []); // Save permission details
      console.log("Set Permission details for userscreen", responseData.rolesPermissionsTables);


    } catch (error) {
      setError(error.message); // Set error message
    } finally {
      setLoading(false); // Reset loading state
    }
  };


  // Fetch users using fetchUsers method
  const fetchUsers = async () => {
    try {
      console.log("assignees data", assignee);
      const data = { clientId: assignee.clientId };
      console.log("Request Data for users:", data);

      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/user/get-user/NotKickstart`,
        data
      );
      console.log("Response from server fetching users:", response);

      const responseData = response.data;
      console.log("add last seen and users data", responseData);

      setUsers(responseData); // Assuming `data.data` contains users

      // Set initial selected user types based on fetched users
      const initialSelectedUserTypes = {};
      responseData.forEach((user) => {
        initialSelectedUserTypes[user.id] = user.userType;
        console.log(
          "foreach initialSelectedUserTypes",
          initialSelectedUserTypes
        );
      });
      setSelectedUserTypes(initialSelectedUserTypes);
      console.log("Initial selected user types:", initialSelectedUserTypes);
    } catch (error) {
      console.error("Error fetching Users data", error);
    }
  };

  console.log("Check user permissions", rolesPermissions);

  const hasPermissionForEdit = rolesPermissions.some(
    (permission) => permission.permissionsId === 2 && permission.iconId === parseInt(iconId)
  );
  const hasPermissionForSave = rolesPermissions.some(
    (permission) => permission.permissionsId === 23 && permission.iconId === parseInt(iconId)
  );
  console.log("Check user permissions to user view list", hasPermissionForEdit);



  const fetchUserTypesPurchasedForEdit = async () => {
    try {
      const response = await UserService.getPurchaseUsertypes();
      console.log("fetch user type", response.data.data);
      setEditView(response.data.data);
    } catch (error) {
      console.error("Error fetching usertype purchase data", error);

    }
  };

  // Fetch user purchase details types
  const fetchPurchaseUsertypes = async () => {
    setLoading(true); // Start loading
    try {
      const response = await UserService.getPurchaseUsertypes();
      // Sort purchaseData by userType in ascending order
      const sortedPurchaseTypesData = response.data.data.sort(
        (a, b) => a.userType - b.userType
      );
      console.log("sort purchase", sortedPurchaseTypesData);
      setPurchaseData(sortedPurchaseTypesData); // Assuming `data.data` contains users
      const initialAssigned = {};
      const initialAvailable = {};
      sortedPurchaseTypesData.forEach((user) => {
        initialAssigned[user.id] = user.assigned || 0;
        initialAvailable[user.id] = user.purchased - (user.assigned || 0);
      });
      setAssignedData(initialAssigned);
      setAvailableData(initialAvailable);
    } catch (error) {
      console.error("Error fetching purchase data", error);
    }
    finally {
      setLoading(false); // Stop loading
    }
  };

  // Fetch both users and user types on component mount
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchUserTypes();
      await fetchAssignee();
      // await fetchUsers();
      await fetchPurchaseUsertypes();
      setLoading(false);
    };

    fetchData();
  }, []);

  // Handle local user type selection only, without API call
  // const handleUserTypeSelect = (id, userTypeId) => {
  //   console.log("user id is", id);
  //   console.log("user Type id is", userTypeId);
  //   setSelectedUserTypes((prev) => ({ ...prev, [id]: userTypeId }));
  // };

  // const calculateAssignedAndAvailable = () => {
  //   const updatedData = purchaseData.map((item) => {
  //     const assignedCount = Object.values(selectedUserTypes).filter(
  //       (userTypeId) => userTypeId === item.userType
  //     ).length;

  //     return {
  //       ...item,
  //       assigned: assignedCount,
  //       available: item.purchased - assignedCount,
  //     };
  //   });

  //   setPurchaseData(updatedData);
  // };

  const handleUserTypeSelect = (id, userTypeId) => {
    console.log("user id is", id);
    console.log("user Type id is", userTypeId);
    setSelectedUserTypes((prev) => ({ ...prev, [id]: userTypeId }));
    console.log(" purchaseData log", purchaseData);
    const updatedPurchaseData = purchaseData.map((item) => {
      if (item.id.toString() === userTypeId.toString()) {

        const newAssigned = item.assigned + 1;
        const newAvailable = item.purchased - newAssigned;
        console.log("new assigned and available", newAssigned, newAvailable);

        return {
          ...item,
          assigned: newAssigned,
          available: newAvailable,
        };
      }
      return item;
    });
    setPurchaseData(updatedPurchaseData);
  };

  // const handleUserTypeSelect = (id, userTypeId) => {
  //   setSelectedUserTypes((prev) => {
  //     const updated = { ...prev, [id]: userTypeId };

  //     // Recalculate Assigned and Available data dynamically
  //     const updatedAssigned = calculateAssignedAndAvailable(users, updated);
  //     const updatedAvailable = calculateAssignedAndAvailable(users, updated);

  //     setAssignedData(updatedAssigned);
  //     setAvailableData(updatedAvailable);

  //     return updated; // Return updated selectedUserTypes
  //   });
  // };


  const openModal = () => {
    setIsModalOpen(true);
  };

  // Function to handle closing the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleRowDoubleClick = (id) => {
    console.log(`Row with ID ${id} was double clicked.`);
  };

  useEffect(() => {
    fetchUserTypesPurchasedForEdit();
  }, []);
  // useEffect to handle immediate data refresh after updates
  useEffect(() => {
    if (updateTrigger) {
      // Re-fetch purchase data and users to reflect changes
      fetchPurchaseUsertypes();
      fetchUsers();
      setUpdateTrigger(false); // Reset the trigger
    }
  }, [updateTrigger]);

  // Submit all changes
  const onSubmit = async () => {
    try {
      console.log("selectedUserTypes", selectedUserTypes);

      const updates = Object.keys(selectedUserTypes).map((userId) => ({
        userId,
        userTypeId: selectedUserTypes[userId],
      }));
      console.log("check usertype", updates);

      let purchaseDetails = [];
      if (selectedPurchaseDetails && selectedPurchaseDetails.length > 0) {
        purchaseDetails = selectedPurchaseDetails.map((item) => {
          const matchedItem = purchaseData.find((data) => data.userType === item.userType);
          const purchased = parseInt(item.purchased, 10);
          const assigned = matchedItem ? parseInt(matchedItem.assigned, 10) : 0;
          return {
            userTypeId: item.userType, // Ensure this matches backend expectations
            purchased,
            assigned,
            available: purchased - assigned, // Calculate available as purchased - assigned
          };
        });
      }

      console.log("Pur ", purchaseDetails);
      // Basic frontend validation

      // Check if any `available` value is negative
      const hasNegativeAvailable = purchaseDetails.some((detail) => detail.available < 0);

      // if (hasNegativeAvailable) {
      //   toast("error", "Available value cannot be negative for any User Type.");
      //   return;
      // }

      // Check if purchased is less than assigned
      const isValid = purchaseDetails.every((detail) => detail.purchased >= detail.assigned);

      // if (!isValid) {
      //   toast(
      //     "error",
      //     "Purchased value cannot be less than assigned value for any User Type."
      //   );
      //   return;
      // }


      const payload = { updates, purchaseDetails };


      const response = await UserService.batchUpdateUserTypes(payload);
      if (response.status === 200) {
        // Update local user and purchase data after successful submission
        await fetchUsers();
        await fetchPurchaseUsertypes();
        console.log("User types updated successfully for selected users.");
        toast("success", "User Types updated successfully");
      }
      else if (response.status === 700) {
        await fetchUsers();
        await fetchPurchaseUsertypes();
        toast("error", response.message);
      }
      else if (response.status === 600) {
        await fetchUsers();
        await fetchPurchaseUsertypes();
        toast("error", response.message);
      }
    } catch (error) {
      console.error("Error updating user types:", error);
    }
  };

  const savePurchasedDetails = async () => {
    try {
      // Prepare the data to be sent
      const data = editView.map((item) => ({
        userType: parseInt(item.UserType.id, 10),
        purchased: parseInt(item.purchased, 10), // Convert assigned value to integer
      }));

      console.log("save request data is:", data);
      // Make the API call
      // const response = await UserService.saveAllUserDetails(
      //   `${process.env.REACT_APP_BASE_URL}/edit-user-type-save`,
      //   data
      // );
      console.log("save request data is:", data);
      closeModal();
      setSelectedPurchaseDetails(data);

      // Handle success response
      // if (response.status === 200) {
      //   toast("success", "Updated Purchase Details successfully");
      //   await fetchPurchaseUsertypes();
      //   closeModal(); // Close the modal after successful save
      // } else {
      //   console.error("Failed to save details:", response);
      // }
    } catch (error) {
      // Handle error
      console.error("Error saving details:", error);
    }
  };

  // Update purchaseDetails dynamically when selectedUserTypes changes
  useEffect(() => {
    const updatedPurchaseDetails = users.map((user) => {
      const selectedType = selectedUserTypes[user.id];
      const matchedItem = purchaseData.find((data) => data.userType === selectedType);
      console.log("Matched item is", matchedItem);
      const purchased = matchedItem ? parseInt(matchedItem.purchased, 10) : 0;
      const assigned = matchedItem ? parseInt(matchedItem.assigned, 10) : 0;
      return {
        userId: user.id,
        userTypeId: selectedType,
        purchased,
        assigned,
        available: purchased - assigned,
      };
    });
    console.log("updatedPurchaseDetails", updatedPurchaseDetails);
    setPurchaseDetails(updatedPurchaseDetails);
  }, [selectedUserTypes, users, purchaseData]);


  // Handle immediate reflection of changes in available values
  //  const handleInputChange = (userId, field, value) => {
  //   setPurchaseDetails((prev) =>
  //     prev.map((item) =>
  //       item.userId === userId ? { ...item, [field]: value, available: item.purchased - item.assigned } : item
  //     )
  //   );
  // };


  const columns = React.useMemo(() => {
    console.log("selectedUserTypes is", selectedUserTypes);
    console.log("userTypes is", userTypes);
    const userTypeColumns = userTypes.map((type) => ({
      Header: type.name,
      accessor: `userType_${type.id}`,
      Cell: ({ row }) => (
        <Radio
          type="radio"
          name={`usertype-${row.original.id}`}
          checked={selectedUserTypes[row.original.id] == type.id}
          onChange={() => handleUserTypeSelect(row.original.id, type.id)}
          style={{
            color:
              selectedUserTypes[row.original.id] == type.id
                ? "#29CB00"
                : "#5D5D5D",
            transform: "scale(0.7)",
          }}
        />
      ),
    }));

    return [
      { Header: "User ID", accessor: "userId" },
      { Header: "User Name", accessor: "username" },
      {
        Header: "Not Assigned",
        accessor: "not_assigned",
        Cell: ({ row }) => {
          // Check if userType is 0 (Not Assigned)
          const isNotAssigned = selectedUserTypes[row.original.id] == 0;

          return (
            <Radio
              type="radio"
              name={`usertype-${row.original.id}`}
              checked={isNotAssigned}
              onChange={() => handleUserTypeSelect(row.original.id, 0)} // Set userType to 0
              style={{
                color: isNotAssigned ? "red" : "#5D5D5D",
                transform: "scale(0.7)",
              }}
            />
          );
        },
      },
      ...userTypeColumns,
    ];
  }, [userTypes, selectedUserTypes]);


  // First table: Remove last 3 columns
  // const visibleColumns = purchaseData.slice(0, purchaseData.length - 3);
  useEffect(() => {
    const updatedColumns = selectedPurchaseDetails?.length
      ? purchaseData
        .map((item) => {
          const matchedPurchase = selectedPurchaseDetails.find(
            (p) => p.userType === item.userType
          );
          const purchased = matchedPurchase?.purchased || item.purchased;
          const assigned = matchedPurchase?.assigned || item.assigned || 0;
          return {
            ...item,
            purchased,
            assigned,
            available: purchased - assigned,
          };
        })
        .slice(0, -3) // Exclude last 3 elements
      : purchaseData.slice(0, -3);

    setVisibleColumns(updatedColumns);
  }, [purchaseData, selectedPurchaseDetails]); // Recalculate when data changes

  console.log("Visible table details", visibleColumns);

  // Second table: Show only last 3 columns
  // const hiddenColumns = purchaseData.slice(-3);
  useEffect(() => {
    const updatedHiddenColumns = selectedPurchaseDetails?.length
      ? purchaseData
        .map((item) => {
          const matchedPurchase = selectedPurchaseDetails.find(
            (p) => p.userType === item.userType
          );
          console.log("Matched Purchase", matchedPurchase);
          const purchased = matchedPurchase?.purchased || item.purchased;
          const assigned = matchedPurchase?.assigned || item.assigned || 0;
          return {
            ...item,
            purchased,
            assigned,
            available: purchased - assigned,
          };
        })
        .slice(-3) // Only the last 3 elements
      : purchaseData.slice(-3);

    setHiddenColumns(updatedHiddenColumns);
  }, [purchaseData, selectedPurchaseDetails]); // Recalculate when data changes


  const firstThreeColumns = columns.slice(0, columns.length - 7); // First 2 columns

  // const lessThreeColumns = columns.slice(0, columns.length - 3);
  const lessThreeColumns = columns.slice(3, 7); // Next 4 columns (index 2 to 5)
  const lastThreeColumns = columns.slice(-3);

  const scrollContainerRef = useRef();

  const handleScroll = (event) => {
    const scrollTop = event.target.scrollTop;
    const children = scrollContainerRef.current.children;
    for (const child of children) {
      child.scrollTop = scrollTop;
    }
  };

  return (
    <div> {loading ? (
      // <p>Loading...</p>
      <div><CustomeLoading /></div>
    ) : (
      <div>

        <div className="flex pr-5 m-2 mb-5">

          <div className="flex flex-col" style={{ width: "100%" }}>

            <div
              className="flex mb-5" style={{ width: "100%", }}
            >

              {/* First Table */}
              <table className="table-fixed bg-white border border-customBlue" style={{ flex: "0 0 40%", maxWidth: "40%", borderCollapse: 'collapse' }}>
                <thead className="bg-customBlue text-primary-foreground text-left text-sm font-medium ">
                  <tr>
                    <th
                      className="text-center text-white font-medium border-r border-grey-400 whitespace-nowrap bg-customBlue h-[30px]"
                    ></th>


                  </tr>
                </thead>
                <tbody className="text-sm">
                  <tr style={{ backgroundColor: "#FFFDF0" }}>

                    <td className="text-center border-b border-r text-customGray whitespace-nowrap px-16 h-[30px]">
                      <div className="flex items-center justify-center">
                        <span className="mr-2">Purchased</span>
                        {/* <IoMdCreate onClick={openModal} className="cursor-pointer" /> */}
                        <IoMdCreate
                          onClick={hasPermissionForEdit ? openModal : null} // Only trigger modal if permission exists
                          title={!hasPermissionForEdit ? "You don't have permission" : ""} // Show tooltip if no permission
                          className={` ${hasPermissionForEdit ? "cursor-pointer text-black" : "text-gray-400"}`} // Disable style if no permission
                        />


                      </div>
                    </td>

                    {/* Modal */}
                    {isModalOpen && (
                      <>
                        {/* Backdrop */}
                        <div
                          className="fixed inset-0 bg-black bg-opacity-50 z-40"
                          onClick={closeModal} // Closes modal when clicking outside
                        />

                        {/* Modal Content */}
                        <div
                          className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-3xl z-50"
                          onClick={(e) => e.stopPropagation()} // Prevents close on modal content click
                        >
                          <div className="flex justify-between items-center mt-2">
                            <h2 className="text-customBlue text-lg font-semibold mb-5">Edit Purchase Details</h2>
                          </div>

                          {/* Table inside the modal */}
                          <div className="overflow-x-auto">
                            <table
                              className="table-fixed min-w-full bg-white border border-customBlue"
                              style={{
                                borderCollapse: "collapse", // Add this style to collapse borders
                              }}
                            >
                              <thead
                                className={`bg-customBlue text-center text-sm font-small border border-customBlue sticky top-0 z-10`}
                              >
                                <tr className="z-20">
                                  <th
                                    className="text-center text-white font-medium border-r border-customTableBorder whitespace-nowrap bg-customBlue sticky top-0 z-10 h-[30px]"
                                    style={{
                                      width: "30px",
                                      minWidth: "30px",
                                      maxWidth: "30px",
                                    }}
                                  >
                                    User Types
                                  </th>
                                  <th
                                    className="text-center text-white font-medium border-r border-customTableBorder whitespace-nowrap bg-customBlue sticky top-0 z-10 h-[30px]"
                                    style={{
                                      width: "30px",
                                      minWidth: "30px",
                                      maxWidth: "30px",
                                    }}
                                  >
                                    Purchase
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {editView.map((row, index) => (
                                  <tr
                                    key={index}
                                    className={index === editView.length - 1 ? "border-b-2" : ""}
                                  >
                                    <td className="text-center px-4 py-2 border-b">{row.UserType.name}</td>
                                    {/* <td className="text-center px-4 py-2 border-b">{row.assigned}</td> */}
                                    <td className="text-center px-4 py-2 border-b">
                                      <input
                                        type="text"
                                        value={row.purchased}
                                        onChange={(e) => {
                                          const updatedView = editView.map((item, idx) =>
                                            idx === index ? { ...item, purchased: e.target.value } : item
                                          );
                                          setEditView(updatedView);
                                        }}
                                        className="border border-gray-300 rounded-md p-1 text-center w-full"
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>

                          </div>

                          {/* Apply Button */}
                          <div className="mt-6 text-right">
                            <button
                              onClick={closeModal}
                              className="bg-white text-customBlue border-customTableBorder px-4 py-2"
                            >
                              Cancel
                            </button>
                            <button
                              className="bg-customBlue text-white px-4 py-2 rounded-md hover:bg-blue-600"
                              onClick={savePurchasedDetails} // Call the fetch function on Save
                            >
                              Select
                            </button>
                          </div>
                        </div>
                      </>
                    )}


                  </tr>
                  <tr style={{ backgroundColor: "#FFFDF0" }}>
                    <td className="text-center border-b border-r text-customGray whitespace-nowrap px-16 h-[30px]">
                      Assigned
                    </td>


                  </tr>
                  <tr style={{ backgroundColor: "#FFFDF0" }}>
                    <td className="text-center border-r text-customGray whitespace-nowrap px-16 h-[30px]">
                      Available
                    </td>


                  </tr>
                </tbody>
              </table>

              {/* Middle */}
              {/* <table className="table-fixed bg-white border border-customBlue ml-3" style={{ flex: "0 0 35%", maxWidth: "35%", borderCollapse: 'collapse' }}>
                <thead className="bg-customBlue  text-center text-sm font-small border border-customBlue sticky top-0 z-10">
                  <tr className="z-20">
 
 
 
                    {visibleColumns.map((user, index) => {
                      const isLastColumn = index === visibleColumns.length - 1;
                      return (
                        <th
                          key={user.id}
                          className={`text-center text-white font-medium sticky top-0 z-10 h-[30px] ${isLastColumn ? '' : 'border-r'
                            } whitespace-nowrap bg-customBlue`}
                          style={{
                            width: "30px",
                            minWidth: "30px",
                            maxWidth: "30px",
                          }}
                        >
                          {user.userType === 1
                            ? "User"
                            : user.userType === 2
                              ? "Super User"
                              : user.userType === 3
                                ? "Approver"
                                : user.userType === 4
                                  ? "Admin"
                                  : user.userType === 5
                                    ? "Consultant"
                                    : user.userType === 6
                                      ? "Support"
                                      : user.userType === 7
                                        ? "System"
                                        : "Unknown"}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody className="text-sm">
                  <tr style={{ backgroundColor: "#FFFDF0" }}>
 
 
 
                    {visibleColumns.map((user, index) => {
                      const isLastColumn = index === visibleColumns.length - 1;
                      return (
                        <td
                          key={user.id}
                          className={`text-center text-customGray whitespace-nowrap h-[30px] ${isLastColumn ? '' : 'border-r'
                            } border-b `}
                        >
                          {user.purchased}
                        </td>
                      );
                    })}
 
                  </tr>
                  <tr style={{ backgroundColor: "#FFFDF0" }}>
 
 
                    {visibleColumns.map((user, index) => {
                      const isLastColumn = index === visibleColumns.length - 1;
                      return (
                        <td
                          key={user.id}
                          className={`text-center text-customGray whitespace-nowrap h-[30px] ${isLastColumn ? '' : 'border-r'
                            } border-b`}
                        >
                          {assignedData[user.id] || user.assigned}
                        </td>
                      );
                    })}
                  </tr>
                  <tr style={{ backgroundColor: "#FFFDF0" }}>
 
 
                    {visibleColumns.map((user, index) => {
                      const isLastColumn = index === visibleColumns.length - 1;
                      return (
                        <td
                          key={user.id}
                          className={`text-center text-customGray whitespace-nowrap h-[30px]
                    ${isLastColumn ? 'border-b border-customBlue ' : 'border-r'}
                     `}
                        >
                          {availableData[user.id] !== undefined
                            ? availableData[user.id]
                            : user.available}
                        </td>
                      );
                    })}
                  </tr>
                </tbody>
              </table> */}

              <table
                className="table-fixed bg-white border border-customBlue ml-3"
                style={{
                  flex: "0 0 35%",
                  maxWidth: "35%",
                  borderCollapse: 'collapse',
                }}
              >
                <thead className="bg-customBlue text-center text-sm font-small border border-customBlue sticky top-0 z-10">
                  <tr className="z-20">
                    {visibleColumns.map((user, index) => {
                      const isLastColumn = index === visibleColumns.length - 1;
                      return (
                        <th
                          key={user.id}
                          className={`text-center text-white font-medium sticky top-0 z-10 h-[30px] ${isLastColumn ? '' : 'border-r'
                            } whitespace-nowrap bg-customBlue`}
                          style={{
                            width: "30px",
                            minWidth: "30px",
                            maxWidth: "30px",
                          }}
                        >
                          {user.userType === 1
                            ? "User"
                            : user.userType === 2
                              ? "Super User"
                              : user.userType === 3
                                ? "Approver"
                                : user.userType === 4
                                  ? "Admin"
                                  : user.userType === 5
                                    ? "Consultant"
                                    : user.userType === 6
                                      ? "Support"
                                      : user.userType === 7
                                        ? "System"
                                        : "Unknown"}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody className="text-sm">
                  <tr style={{ backgroundColor: "#FFFDF0" }}>
                    {visibleColumns.map((user, index) => {
                      const isLastColumn = index === visibleColumns.length - 1;
                      return (
                        <td
                          key={user.id}
                          className={`text-center text-customGray whitespace-nowrap h-[30px] ${isLastColumn ? '' : 'border-r'
                            } border-b`}
                        >
                          {user.purchased}
                        </td>
                      );
                    })}
                  </tr>
                  <tr style={{ backgroundColor: "#FFFDF0" }}>
                    {visibleColumns.map((user, index) => {
                      const isLastColumn = index === visibleColumns.length - 1;
                      return (
                        <td
                          key={user.id}
                          className={`text-center text-customGray whitespace-nowrap h-[30px] ${isLastColumn ? '' : 'border-r'
                            } border-b`}
                        >
                          {user.assigned}
                        </td>
                      );
                    })}
                  </tr>
                  <tr style={{ backgroundColor: "#FFFDF0" }}>
                    {visibleColumns.map((user, index) => {
                      const isLastColumn = index === visibleColumns.length - 1;
                      return (
                        <td
                          key={user.id}
                          className={`text-center text-customGray whitespace-nowrap h-[30px] ${isLastColumn ? 'border-b border-customBlue' : 'border-r'
                            }`}
                        >
                          {user.available}
                        </td>
                      );
                    })}
                  </tr>
                </tbody>
              </table>

              {/* Third Table */}
              <table className="table-fixed bg-white border border-customBlue ml-3" style={{ flex: "0 0 25%", maxWidth: "25%" }}>
                <thead className="bg-customBlue text-primary-foreground text-left text-sm font-medium">
                  <tr className="z-20">
                    {hiddenColumns.map((user, index) => {
                      const isLastColumn = index === hiddenColumns.length - 1;
                      return (
                        <th
                          key={user.id}
                          className={`text-center text-white font-medium sticky top-0 z-10 h-[30px] ${isLastColumn ? '' : 'border-r'
                            } whitespace-nowrap bg-customBlue`}
                          style={{
                            width: "30px",
                            minWidth: "30px",
                            maxWidth: "30px",
                          }}

                        >
                          {user.userType === 1
                            ? "User"
                            : user.userType === 2
                              ? "Super User"
                              : user.userType === 3
                                ? "Approver"
                                : user.userType === 4
                                  ? "Admin"
                                  : user.userType === 5
                                    ? "Consultant"
                                    : user.userType === 6
                                      ? "Support"
                                      : user.userType === 7
                                        ? "System"
                                        : "Unknown"}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody className="text-sm">
                  <tr style={{ backgroundColor: "#FFFDF0" }}>

                    {hiddenColumns.map((user, index) => {
                      const isLastColumn = index === hiddenColumns.length - 1;
                      return (
                        <td
                          key={user.id}
                          className={`text-center text-customGray whitespace-nowrap h-[30px] ${isLastColumn ? '' : 'border-r'
                            } border-b `}
                        >
                          {user.purchased}
                        </td>
                      );
                    })}
                  </tr>
                  <tr style={{ backgroundColor: "#FFFDF0" }}>
                    {hiddenColumns.map((user, index) => {
                      const isLastColumn = index === hiddenColumns.length - 1;
                      return (
                        <td
                          key={user.id}
                          className={`text-center text-customGray whitespace-nowrap h-[30px] ${isLastColumn ? '' : 'border-r'
                            } border-b `}
                        >
                          {user.assigned}
                        </td>
                      );
                    })}
                  </tr>
                  <tr style={{ backgroundColor: "#FFFDF0" }}>
                    {hiddenColumns.map((user, index) => {
                      const isLastColumn = index === hiddenColumns.length - 1;
                      return (
                        <td
                          key={user.id}
                          className={`text-center text-customGray whitespace-nowrap h-[30px]  ${isLastColumn ? 'border-b border-customBlue ' : 'border-r'}`}
                        >
                          {user.available}
                        </td>
                      );
                    })}
                  </tr>
                </tbody>
              </table>

            </div>
          </div>

        </div>


        {/* <div className="flex mb-5" style={{ width: "100%", }} > */}
        {/* <div className="border border-gray-200 rounded-lg mb-5"> */}
        <TableWrapper maxHeight="70vh">
          <div
            className="flex" style={{ width: "100%" }}
            ref={scrollContainerRef}
            onScroll={handleScroll}
          >
            <div style={{ flex: "0 0 40%", maxWidth: "40%" }}>
              <ReTable
                data={users}
                columns={firstThreeColumns}
                selectedRows={selectedRows}
                showCheckbox={false}
                showHeaderCheckbox={false}
                onRowDoubleClick={handleRowDoubleClick}
                className={""}
                disableScroll={true} // Pass this prop
              />
            </div>

            <div style={{ flex: "0 0 35%", maxWidth: "35%" }}>
              <ReTable
                data={users}
                columns={lessThreeColumns}
                selectedRows={selectedRows}
                showCheckbox={false}
                showHeaderCheckbox={false}
                onRowDoubleClick={handleRowDoubleClick}
                className={""}
                disableScroll={true} // Pass this prop
              />
            </div>

            <div style={{ flex: "0 0 25%", maxWidth: "25%" }}>
              <ReTable
                data={users}
                columns={lastThreeColumns}
                selectedRows={selectedRows}
                showCheckbox={false}
                showHeaderCheckbox={false}
                onRowDoubleClick={handleRowDoubleClick}
                className={""}
                disableScroll={true} // Pass this prop
              />
            </div>
          </div>
        </TableWrapper>
        {/* </div> */}
        {/* </div> */}

      </div>

    )}
      <Footer>
        {/* <button
          onClick={onSubmit}
          className="bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ml-7"
        >
          Submit
        </button> */}
        <button
          onClick={hasPermissionForSave ? onSubmit : null} // Only allow onClick if permission exists
          disabled={!hasPermissionForSave} // Disable button if no permission
          title={!hasPermissionForSave ? "You don't have permission" : ""} // Show tooltip if no permission
          className={`bg-customBlue text-sm text-white py-1 px-2 rounded transition duration-200 ease-in-out w-16 ml-7 ${hasPermissionForSave ? "hover:bg-blue-600" : "bg-gray-400"
            }`} // Change style dynamically
        >
          Submit
        </button>

      </Footer>
    </div>
  );
};

export default UserTable;