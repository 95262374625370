import React, { useEffect, useState } from 'react';
import UserService from '../rest-services/UserServices';

export default function CustomButton({ 
  onClick, 
  title, 
  icons,
  className = "",
  secondaryOnClick,
  secondaryTitle,
  secondaryClassName = "",
  showSecondaryButton = false, // A flag to show or hide the secondary button
}) {

  const [rolesPermissions, setRolesPermissions] = useState([]);
    const [assignee, setAssignee] = useState({ id: "", username: "" });
    const iconId = parseInt(icons, 10);
    
    console.log("ICOn id is",iconId);
    console.log("ICOns id is",icons);

  console.log("Check user permissions", rolesPermissions);


    const hasPermissionForSave = iconId === 108 || iconId === 113  &&  rolesPermissions.some(
      (permission) => permission.permissionsId === 17 && permission.iconId === iconId
    );


    console.log("Check user permissions to user save 17", hasPermissionForSave);
    

    useEffect(() => {
      fetchAssignee();
    }, []);

const fetchAssignee = async () => {
  try {
    const userDetailString = localStorage.getItem("userDetail");
    if (userDetailString) {
      const userDetail = JSON.parse(userDetailString);
      const assigneeData = {
        clientId: userDetail.clientId || "",
        id: userDetail.id || "",
        assigner: userDetail.username || "",
        userId: userDetail.userId || "",
      };
      setAssignee(assigneeData);
    } else {
    }
  } catch (error) { }
};

    useEffect(() => {
      if (assignee.userId) {
        fetchUserPermissionDetails();
      }
    }, [assignee.userId]);

  const fetchUserPermissionDetails = async () => {
    try {
      const data = { userId: assignee.userId };
      console.log("Request Data:", data);

      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-users-permissions-by-userId`,
        data
      );
      console.log("user permissions details", response);
      const responseData = response.data;
      setRolesPermissions(responseData.rolesPermissionsTables || []); // Save permission details
      console.log("Set Permission details for bam screen", responseData.rolesPermissionsTables);


    } catch (error) {
      console.error(error.message); // Set error message
    } 
  };



  // If the secondary button is shown, wrap both buttons in a `div`
  if (showSecondaryButton) {
    return (
      <div className="flex flex-row justify-end p-2 sticky bottom-0">
        {/* Secondary Button */}
        <button
          onClick={secondaryOnClick}
          className={`w-16 bg-white text-sm border border-customBlue text-customBlue py-1 px-2 rounded hover:bg-customBlue hover:text-white transition duration-200 ease-in-out ${secondaryClassName}`}
        >
          {secondaryTitle}
        </button>

        {/* Primary Button */}
        <button
          onClick={onClick}
          className={`w-16 bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-[#0B5299] transition duration-200 ease-in-out ${className}`}
        >
          {title}
        </button>
      </div>
    );
  }

  // If the secondary button is NOT shown, only render the primary button
  return (
    // <button
    //   onClick={onClick}
    //   className={`w-16 bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-[#0B5299] transition duration-200 ease-in-out ${className}`}
    // >
    //   {title}
    // </button>

<button
      onClick={iconId === 108 || iconId === 113 ? (hasPermissionForSave ? onClick : null) : onClick }
      disabled={iconId === 108 || iconId === 113 ? !hasPermissionForSave : '' } // Disable button if permission exists
      title={iconId === 108 || iconId === 113 ? (!hasPermissionForSave ? "You don't have permission" : "") : ''} // Tooltip
      className={`w-16 text-sm text-white py-1 px-2 rounded transition duration-200 ease-in-out 
        ${className} 
        ${iconId === 108 || iconId === 113 ?  (!hasPermissionForSave ? "bg-gray-400" : "bg-customBlue hover:bg-[#0B5299]") : 'bg-customBlue hover:bg-[#0B5299]' }`}
    >
      {title}
    </button>

  );
}
