import React, { useState, useEffect } from "react";
import NoActionLayout from "../../../../../components/NoActionLayout";
import UserService from "../../../../../rest-services/UserServices";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CountryServices from "../../../../../rest-services/CountryServices";
import { useToast } from "../../../../../components/toast/toast";
import Header from "../../../../../components/Header";
import Tabs from "../../../../../components/Tabs";
import ButtonGroup from "../../../../../components/FooterButton";
import Footer from "../../../../../components/footer";
import CustomButton from "../../../../../components/CustomButton";
import LanguageServices from "../../../../../rest-services/LanguagesServices";
import CustomDropdown from "../../../../../components/CustomDropdown";
import TableWrapper from "../../../../../components/ScrollBarComponent";
import LabelWithButton from "../../../../../components/InputButtonField";
import ReusableModal from "../../../../../components/PopupComponent";
import { RxDropdownMenu } from "react-icons/rx";

const AddCountry = () => {
  const { countryId } = useParams();
  const [country, setCountry] = useState("");
  const [countryName, setCountryName] = useState("");
  const [amountFormat, setAmountFormat] = useState("");
  const [language, setlanguage] = useState("");
  const [languages, setlanguages] = useState([]);

  const [dateFormat, setDateFormat] = useState("DD-MM-YYYY");
  const [thousandSeparator, setThousandSeparator] = useState("");
  const [decimalSeparator, setDecimalSeparator] = useState("");
  const [acceleratorForThousands, setAcceleratorForThousands] = useState("");
  const [acceleratorForLakhs, setAcceleratorForLakhs] = useState("");
  const [acceleratorForMillions, setAcceleratorForMillions] = useState("");
  const [timeFormat, setTimeFormat] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [assignee, setAssignee] = useState({ id: "", assigner: "" });
  const [alert, setAlert] = useState(null);
  const [activeTab, setActiveTab] = useState("addCountry");
  const navigate = useNavigate();
  const { toast } = useToast();
  const [errors, setErrors] = useState({
    thousand: false,
    decimal: false,
    same: false,
    timeFormat: false,
  });
  const [suffixForThousands, setSuffixForThousands] = useState("");
  const [suffixForLakhs, setSuffixForLakhs] = useState("");
  const [suffixForMillions, setSuffixForMillions] = useState("");
  const [errorThousands, setErrorThousands] = useState(false);
  const [errorLakhs, setErrorLakhs] = useState(false);
  const [errorMillions, setErrorMillions] = useState(false);
  const location = useLocation();
  const { mode } = location.state || {}; // Get the mode from the state
  const isViewMode = mode === "view";
  const [receiveSystemMail, setreceiveSystemMail] = useState(0);
  const [downloadData, setdownloadData] = useState(0);
  const [changesInCashflow, setchangesInCashflow] = useState(0);
  const [changeVarient, setchangeVarient] = useState(0);
  const [deleteAllRecords, setdeleteAllRecords] = useState(0);
  const [changeAccountingCode, setchangeAccountingCode] = useState(0);
  const [updateCountry, setupdateCountry] = useState(0);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [selectedlanguage, setSelectedLanguae] = useState("");
  const [islanguageModalOpen, setIslanguageModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const isEditMode = !!countryId;
  const [isValidationTriggered, setIsValidationTriggered] = useState(false);

  useEffect(() => {
    fetchLanguages();
  }, []);
  useEffect(() => {
    if (countryId) {
      fetchCountryData(countryId);
    }

    fetchAssignee();
  }, [countryId, languages]);

  const fetchLanguages = async () => {
    try {
      const response = await LanguageServices.getAllLanguages(
        `${process.env.REACT_APP_BASE_URL}/get-language`
      );
      if (response && response.data) {
        console.log("response data for language", response.data.data);
        setlanguages(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching Language data", error);
    }
  };

  const validateForm = () => {
    setIsValidationTriggered(true);

    const newErrors = {};
    if (!country) newErrors.country = "Country is required.";
    if (!countryName) newErrors.countryName = "Country Name is required.";
    // Add other field validations as needed

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      toast("error", "Missing Required Fields");
    }

    return Object.keys(newErrors).length === 0;
  };

  const fetchCountryData = async (id) => {
    try {
      const response = await CountryServices.getCountries(id);
      console.log(response.data); // Check this carefully
      const responsedata = response.data

      setCountry(responsedata.country);
      setCountryName(responsedata.countryName);
      setAmountFormat(responsedata.amountFormat);
      setlanguage(responsedata.language);
      setDateFormat(responsedata.dateFormat);
      setThousandSeparator(responsedata.thousandSeparator);
      setDecimalSeparator(responsedata.decimalSeparator);
      setAcceleratorForThousands(responsedata.acceleratorForThousands);
      setAcceleratorForLakhs(responsedata.acceleratorForLakhs);
      setAcceleratorForMillions(responsedata.acceleratorForMillions);
      setTimeFormat(responsedata.timeFormat);
      setTimeZone(responsedata.timeZone);
      setreceiveSystemMail(responsedata.receiveSystemMail);
      setdownloadData(responsedata.downloadData);
      setchangesInCashflow(responsedata.changesInCashflow);
      setchangeVarient(responsedata.changeVarient);
      setdeleteAllRecords(responsedata.deleteAllRecords);
      setchangeAccountingCode(responsedata.changeAccountingCode);
      setupdateCountry(responsedata.updateCountry);

      const lang = languages.find(c => c.LE === responsedata.language);

      if (lang) {
        setlanguage(lang.LE);  // Set currency name for display
        setSelectedLanguae(lang.LE); // Set the selected currency based on currency name
      } else {
        console.error("Country not found for the provided country code.");
      }
    } catch (error) {
      console.error("Error fetching legal entity data:", error);
      setAlert({
        severity: "error",
        message: "Error fetching legal entity data.",
      });
    }
  };

  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        setAssignee({
          id: userDetail.id || "",
          assigner: userDetail.userId || "",
        });
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

  const handleSave = async () => {
    if (!validateForm()) return;
    try {
      // Skip duplication check for edit mode
      if (!countryId) {
        const duplicateCheckResponse = await CountryServices.checkDuplicateCountrycode(
          `${process.env.REACT_APP_BASE_URL}/getduplicatecountry`,
          { country: country }
        );

        if (duplicateCheckResponse.message === "country code already exists") {
          toast("error", "Country already exists.");
          setErrors((prev) => ({
            ...prev,
            country: "country code already exists.",
          }));
          return; // Stop further execution if duplicate exists
        }
      }
      const data = {
        countryId,
        country,
        countryName,
        amountFormat,
        language,
        dateFormat,
        thousandSeparator,
        decimalSeparator,
        acceleratorForThousands,
        acceleratorForLakhs,
        acceleratorForMillions,
        timeFormat,
        timeZone,
        receiveSystemMail,
        downloadData,
        changesInCashflow,
        changeVarient,
        deleteAllRecords,
        changeAccountingCode,
        updateCountry,
        changed_by: assignee.assigner || null, // Ensure changed_by is set
        createdBy: assignee.assigner || null
      };

      let response;
      if (countryId) {
        // Updating the existing country
        response = await UserService.saveCountry(
          `${process.env.REACT_APP_BASE_URL}/countries`, // Ensure this endpoint is for updates
          data
        );
      } else {
        // Creating a new country
        response = await UserService.saveCountry(
          `${process.env.REACT_APP_BASE_URL}/countries`,
          data
        );
      }

      if (response && !response.error) {
        toast(
          "success",
          countryId
            ? "Country Updated successfully"
            : "Country Added successfully"
        );
        // Clear the form and navigate
        setCountry("");
        setCountryName("");
        setSelectedLanguae("");

        navigate("/2/master-data/1/country"); // Redirect after saving
      } else {
        toast("error", "Error adding/updating Country");
      }
    } catch (error) {
      console.error("Request error:", error);
      setAlert({
        severity: "error",
        message: `Error adding/updating country: ${error.message}`,
      });
    }
  };

  const handleSaveAndContinue = async () => {
    if (!validateForm()) return;
    try {
      // Skip duplication check for edit mode
      if (!countryId) {
        const duplicateCheckResponse = await CountryServices.checkDuplicateCountrycode(
          `${process.env.REACT_APP_BASE_URL}/getduplicatecountry`,
          { country: country }
        );

        if (duplicateCheckResponse.message === "country code already exists") {
          toast("error", "Country already exists.");
          setErrors((prev) => ({
            ...prev,
            country: "country code already exists.",
          }));
          return; // Stop further execution if duplicate exists
        }
      }
      const data = {
        countryId,
        country,
        countryName,
        amountFormat,
        language,
        dateFormat,
        thousandSeparator,
        decimalSeparator,
        acceleratorForThousands,
        acceleratorForLakhs,
        acceleratorForMillions,
        timeFormat,
        timeZone,
        receiveSystemMail,
        downloadData,
        changesInCashflow,
        changeVarient,
        deleteAllRecords,
        changeAccountingCode,
        updateCountry,
        changed_by: assignee.assigner || null, // Ensure changed_by is set
        createdBy: assignee.assigner || null
      };

      let response;
      if (countryId) {
        // Updating the existing country
        response = await UserService.saveCountry(
          `${process.env.REACT_APP_BASE_URL}/countries`, // Ensure this endpoint is for updates
          data
        );
      } else {
        // Creating a new country
        response = await UserService.saveCountry(
          `${process.env.REACT_APP_BASE_URL}/countries`,
          data
        );
      }

      if (response && !response.error) {
        toast(
          "success",
          countryId
            ? "Country Updated successfully"
            : "Country Added successfully"
        );
        // Clear the form and navigate
        setCountry("");
        setCountryName("");
        setlanguage("");
        setAmountFormat("");
        setDateFormat("");
        setThousandSeparator("");
        setDecimalSeparator("");
        setAcceleratorForThousands("");
        setAcceleratorForLakhs("");
        setAcceleratorForMillions("");
        setTimeFormat("");
        setTimeZone("");
        setreceiveSystemMail("");
        setdownloadData("");
        setchangesInCashflow("");
        setchangeVarient("");
        setdeleteAllRecords("");
        setchangeAccountingCode("");
        setupdateCountry("");
        setSelectedLanguae("");
        setIsValidationTriggered(false);


        // Redirect after saving
      } else {
        toast("error", "Error adding/updating Country");
      }
    } catch (error) {
      console.error("Request error:", error);
      setAlert({
        severity: "error",
        message: `Error adding/updating country: ${error.message}`,
      });
    }
  };
  const checkDuplicateCountrycode = async (code) => {
    try {
      const response = await CountryServices.checkDuplicateCountrycode(
        `/getduplicatecountry`,
        { country: code }  // Sending roleName to backend
      );


      console.log("Response from server:", response);

      if (response.message === "country code already exists") {
        // Check if it's a duplicate
        console.log("Response from internal server:", response);
        setErrors({ country: " " });

        toast("error", "country already exists.");
      }
      console.log("Response from external server:", response);
    } catch (error) {
      console.error("Error checking duplicate country:", error);
      // Handle potential errors when making the API call
      setErrors((prev) => ({ ...prev, country: "Error checking role name" }));
    }
  };

  const handleCountryChange = (e) => {
    const code = e.target.value.toUpperCase(); // Ensure input is uppercase
    setUnsavedChanges(true);
    // Limit input to 3 letters
    if (errors.country) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        country: "",
      }));
      return; // Stop further processing
    }

    // Clear errors if any


    // Update the calendar code
    setCountry(code);

    // Check for duplicate Calendar Code locally
    if (code.trim() !== "") {
      checkDuplicateCountrycode(code);
    }
  };
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const handleThousandChange = (e) => {
    const value = e.target.value;
    setUnsavedChanges(true);

    // Only allow '.' or ',' in the thousand separator field
    if (value === "" || value === "." || value === ",") {
      setThousandSeparator(value);
      setErrors((prevError) => ({ ...prevError, thousand: false })); // Reset thousand separator error

      // Check if thousand and decimal separators are the same
      if (value === decimalSeparator && value !== "") {
        setErrors((prevError) => ({ ...prevError, same: true }));
      } else {
        setErrors((prevError) => ({ ...prevError, same: false }));
      }
    } else {
      setErrors((prevError) => ({ ...prevError, thousand: true }));
    }
  };

  const handleDecimalChange = (e) => {
    const value = e.target.value;
    setUnsavedChanges(true);
    // Only allow '.' or ',' in the decimal separator field
    if (value === "" || value === "." || value === ",") {
      setDecimalSeparator(value);
      setErrors((prevError) => ({ ...prevError, decimal: false })); // Reset decimal separator error

      // Check if thousand and decimal separators are the same
      if (value === thousandSeparator && value !== "") {
        setErrors((prevError) => ({ ...prevError, same: true }));
      } else {
        setErrors((prevError) => ({ ...prevError, same: false }));
      }
    } else {
      setErrors((prevError) => ({ ...prevError, decimal: true }));
    }
  };

  const handleLanguageCheckBoxChange = (event) => {
    console.log(`this is the language ${languages}`)
    setUnsavedChanges(true);
    const selectedName = event.target.value; // Get the selected country name
    setSelectedLanguae(selectedName);

    const selectedCountryData = languages.find(le => le.LE === selectedName);
    if (selectedCountryData) {
      setSelectedLanguae(selectedCountryData.LE); // Set the country code
      setlanguage(selectedCountryData.LE);
      // Filter states by the selected country code
      // const filteredStates = stateProvinces.filter(state => state.Country_Code === selectedCountryData.country);
      // setFilteredStateProvinces(filteredStates); // Assuming you have state to hold the filtered states
    }
  };
  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === "Global Data") {
      console.log("Navigating to /2/master-data");
      navigate("/2/master-data");
    } else if (crumb.title === "Country") {
      console.log("Navigating to /country");
      navigate("/2/master-data/1/country");
    } else if (crumb.title === "Primary Data") {
      console.log("Navigating to /2/master-data/1/country");
      navigate("/2/master-data");
    } else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const tabs = [
    { value: "addCountry", label: "Add Country" },
    { value: "userPreferences", label: "Country Preferences" },
  ];
  const errorss = {
    addCountry:
      !country || !countryName, // Replace with your validation logic
  };
  const nextToAddress = () => {
    setActiveTab("userPreferences");
  };
  const languageOptions = [
    { value: "", label: "Select Language" },
    ...languages.map((language) => ({
      value: language.LE,
      label: language.LE,
    })),
  ];
  const dateFormatOptions = [
    { value: "", label: "Select Date Format" },
    { value: "DD-MM-YYYY", label: "DD-MM-YYYY" },
    { value: "DD/MM/YYYY", label: "DD/MM/YYYY" }, 
    { value: "MM/DD/YYYY", label: "MM/DD/YYYY" },
    { value: "DD/MM/YY", label: "DD/MM/YY" },
    { value: "MM/DD/YY", label: "MM/DD/YY" },
    { value: "DD.MM.YYYY", label: "DD.MM.YYYY" },
    { value: "DDMMYY", label: "DDMMYY" },
  ];
  const amountFormatOptions = [
    { value: "", label: "Select Amount Format" },
    { value: "1,234,567.89", label: "1,234,567.89" },
    { value: "1.234.567,890", label: "1.234.567,890" },
    { value: "12,34,567.890", label: "12,34,567.890" },

  ];
  const timeFormatOptions = [
    { value: "", label: "Select Time Format" },
    { value: "24 hours", label: "24 hours" },
    { value: "12 hours", label: "12 hours" },

  ];

  const handleToggle = (dropdownId) => {
    setOpenDropdown((prev) => (prev === dropdownId ? null : dropdownId)); // Toggle dropdown
  };
  const handleReset = () => {
    setSearchQuery(''); // Reset search query
    setSelectedLanguae(""); // Reset selected bank ID
    setlanguage("");
  };
  return (
    <div className="min-h-screen flex flex-col">
      <Header
        title={`Primary Data > Global Data > Country > ${countryId ? (isViewMode ? "View" : "Edit") : "Add"
          }`}
        onBreadcrumbClick={handleBreadcrumbClick}
        hasUnsavedChanges={unsavedChanges}
        backRoute={"/2/master-data/1/country"}
      />
      <Tabs tabs={tabs} errors={errorss} activeTab={activeTab} onTabClick={handleTabClick} isValidationTriggered={isValidationTriggered} />

      {/* Tabs */}
      {/* <div className="ml-2 pl-8 pt-2 flex flex-row space-x-6 bg-customBlue">
        <h6
          className="font-small"
          style={tabStyle("addCountry")}
          onClick={() => handleTabClick("addCountry")}
        >
          Add Country
        </h6>
        <h6
          className="font-small"
          style={tabStyle("userPreferences")}
          onClick={() => handleTabClick("userPreferences")}
        >
          Country Preferences
        </h6>
      </div> */}

      {/* Content */}
      {/* Content */}
      <div className="flex-grow">
        {activeTab === "addCountry" && (
          <div className="m-6 border border-gray-200 p-5  rounded-lg">

            <div className="flex flex-col space-y-12 col-span-1 p-6">
              {/* Country Field */}

              <label className="flex items-center">
                <span className="w-48 font-small text-sm text-customGray">
                  Country Code  <span className="text-red-500 ml-1">*</span>
                </span>
                <span className="ml-4">:</span>
                <input
                  type="text"
                  value={country}
                  onChange={handleCountryChange}
                  maxLength={2}
                  className={`border ${errors.country ? "border-red-500" : "border-gray-300"
                    } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-sm font-sm text-customGray`}
                  required
                  disabled={isViewMode || isEditMode}
                />
              </label>

              {/* Country Name Field */}
              <label className="flex items-center">
                <span className="w-48 font-small text-customGray text-sm">
                  Country Name <span className="text-red-500 ml-1">*</span>
                </span>
                <span className="ml-4">:</span>
                <input
                  type="text"
                  value={countryName}
                  maxLength={30}

                  onChange={(e) => {
                    setCountryName(e.target.value);
                    setUnsavedChanges(true);
                    if (errors.countryName) {
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        countryName: "",
                      }));
                    }
                  }} className={`border ${errors.countryName ? "border-red-500" : "border-gray-300"
                    } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-sm font-sm text-customGray`}
                  required
                  disabled={isViewMode}
                />
              </label>
            </div>
            {/* <CustomButton onClick={nextToAddress} title={"Next"} /> */}
          </div>
        )}

        {activeTab === "userPreferences" && (
          <div className="flex flex-col ">
            <div className="m-6 mb-20 border border-gray-200 p-5  rounded-lg ">
              <TableWrapper maxHeight="65vh">
                <div className="flex-grow p  ">
                  <div className="grid grid-cols-2 gap-x-6 gap-y-6 mt-2 mb-6">
                    {/* Language Field */}
                    <div className="flex flex-col space-y-6">
                      <div className="flex items-center">

                        <label className="w-48 font-small text-customGray text-sm">Language</label>
                        <span className="ml-3">:</span>
                        <button
                          onClick={() => setIslanguageModalOpen(true)}
                          disabled={isViewMode}
                          className={`border border-gray-300 p-1 rounded w-72 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 text-left text-customGray flex items-center justify-between ${errors.countryCode ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
                        >
                          <span className='font-small text-customGray text-sm'>{selectedlanguage || 'Select Language'}</span>
                          <RxDropdownMenu className="ml-2" />
                        </button>

                        <ReusableModal
                          title="Select Language Code"
                          isOpen={islanguageModalOpen}
                          onClose={() => {
                            setIslanguageModalOpen(false);
                            setSearchQuery('');
                          }}
                          onSave={() => {
                            setIslanguageModalOpen(false);
                            setSearchQuery('');
                          }}
                          searchPlaceholder="Search language..."
                          searchQuery={searchQuery}
                          onSearchChange={handleSearchChange}
                          isViewMode={isViewMode}
                          showReset={true}
                          onReset={handleReset}
                        >
                          {languages.length > 0 ? (
                            languages
                              .filter(
                                (language) =>
                                  language.LE &&
                                  language.LE.toLowerCase().includes(
                                    searchQuery.toLowerCase()
                                  )
                              )
                              .sort((a, b) => a.LE.localeCompare(b.LE)) // Sort in alphabetical order

                              .map((language, index) => (
                                <div
                                  key={language.id}
                                  className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""
                                    }`}
                                >
                                  <input
                                    type="radio"
                                    name="language"
                                    value={language.LE}
                                    checked={
                                      selectedlanguage ===
                                      language.LE
                                    }
                                    onChange={handleLanguageCheckBoxChange}
                                    className="mr-2 accent-customBlue"
                                    disabled={isViewMode}
                                  />
                                  <label>{language.LE}</label>
                                </div>
                              ))

                          ) : (
                            <p>Loading Currencies...</p>
                          )}
                        </ReusableModal>
                      </div>
                      <label className="flex items-center">
                        <span className="w-48 text-sm font-small text-customGray">
                          Amount Format
                        </span>
                        <span className="ml-3">:</span>
                        {/* <input
          type="text"
          value={amountFormat}
          onChange={(e) => setamountFormat(e.target.value)}

          className="border border-gray-300 p-1 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-4"
        /> */}
                        <CustomDropdown
                          value={amountFormat}
                          options={amountFormatOptions}
                          onChange={(value) => {
                            setAmountFormat(value);
                            setUnsavedChanges(true); // Mark as unsaved
                            setOpenDropdown(false);
                          }} isOpen={openDropdown === "amountFormat"}
                          onToggle={() => handleToggle("amountFormat")}
                          isDisabled={isViewMode}
                          setUnsavedChanges={setUnsavedChanges}
                          width="w-72"

                          className="ml-4"
                          placeholder="Select Amount Format"
                        />
                      </label>

                      {/* Date Format Field */}
                      <label className="flex items-center">
                        <span className="w-48 text-sm font-small text-customGray">
                          Date Format
                        </span>
                        <span className="ml-3">:</span>
                        <CustomDropdown
                          value={dateFormat}
                          options={dateFormatOptions}
                          onChange={(value) => {
                            setDateFormat(value);
                            setUnsavedChanges(true); // Mark as unsaved
                            setOpenDropdown(false);
                          }}
                          // isDisabled={isViewMode}
                          isDisabled={isViewMode}

                          isOpen={openDropdown === "dateFormat"}
                          onToggle={() => handleToggle("dateFormat")}
                          width="w-72"


                          setUnsavedChanges={setUnsavedChanges}
                          className="ml-4"
                          placeholder="Select Date Format"
                        />
                      </label>

                      {/* Thousand Separator Field */}

                      <label className="flex items-center">
                        <span className="w-48 text-sm font-small text-customGray">
                          Thousand Separator
                        </span>
                        <span className="ml-3">:</span>
                        <input
                          type="text"
                          value={thousandSeparator}
                          maxLength={1}
                          onChange={handleThousandChange}
                          className={`border p-1.5 rounded text-customGray w-72 h-8 focus:outline-none ml-4 ${errors.thousand || errors.same
                              ? "border-red-500"
                              : "border-gray-300 hover:border-blue-400"
                            }`}
                          disabled={isViewMode}
                        />
                      </label>
                      {errors.thousand && (
                        <p className="text-red-500 text-sm mt-1">
                          Only '.' and ',' are allowed for thousand separator.
                        </p>
                      )}

                      {/* Decimal Separator Field */}
                      <label className="flex items-center mt-4">
                        <span className="w-48 text-sm font-small text-customGray">
                          Decimal Separator
                        </span>
                        <span className="ml-3">:</span>
                        <input
                          type="text"
                          value={decimalSeparator}
                          maxLength={1}
                          onChange={handleDecimalChange}
                          className={`border p-1.5 text-customGray  rounded w-72 h-8 focus:outline-none ml-4 ${errors.decimal || errors.same
                              ? "border-red-500"
                              : "border-gray-300 hover:border-blue-400"
                            }`}
                          disabled={isViewMode}
                        />
                      </label>
                      {errors.decimal && (
                        <p className="text-red-500 text-sm mt-1">
                          Only '.' and ',' are allowed for decimal separator.
                        </p>
                      )}

                      {errors.same && (
                        <p className="text-red-500 text-sm mt-1">
                          Thousand and decimal separators cannot be the same.
                        </p>
                      )}

                      <label className="flex items-center">
                        <span className="w-48 text-sm font-small text-customGray">
                          Accelerator for Thousands
                        </span>
                        <span className="ml-3">:</span>
                        <input
                          type="text"
                          value={acceleratorForThousands}
                          maxLength={1}
                          onChange={(e) => {
                            const value = e.target.value.toUpperCase(); // Convert input to uppercase
                            setUnsavedChanges(true);
                            // Only allow 'K' or 'T'
                            if (value === "K" || value === "T" || value === "") {
                              setAcceleratorForThousands(value); // Set the state if valid
                              setErrorThousands(false); // Reset the error state
                            } else {
                              setErrorThousands(true); // Set error state if invalid
                            }
                          }}
                          className={`border p-1.5 text-customGray rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-4 ${errorThousands ? "border-red-500" : "border-gray-300"
                            }`}
                          disabled={isViewMode}
                        />
                      </label>
                      {errorThousands && (
                        <p className="text-red-500 text-sm mt-1">
                          Only uppercase 'K' or 'T' is allowed.
                        </p>
                      )}

                      <label className="flex items-center">
                        <span className="w-48 text-sm font-small text-customGray">
                          Accelerator for Lakhs
                        </span>
                        <span className="ml-3">:</span>
                        <input
                          type="text"
                          value={acceleratorForLakhs}
                          maxLength={1}
                          onChange={(e) => {
                            const value = e.target.value.toUpperCase(); // Convert input to uppercase
                            setUnsavedChanges(true);
                            // Only allow 'L' or empty
                            if (value === "L" || value === "") {
                              setAcceleratorForLakhs(value); // Set the state if valid
                              setErrorLakhs(false); // Reset error if input is valid
                            } else {
                              setErrorLakhs(true); // Set error if input is invalid
                            }
                          }}
                          className={`border p-1.5 text-customGray rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-4 ${errorLakhs ? "border-red-500" : "border-gray-300"
                            }`}
                          disabled={isViewMode}
                        />
                      </label>
                      {errorLakhs && (
                        <p className="text-red-500 text-sm mt-1">
                          Only uppercase 'L' is allowed.
                        </p>
                      )}

                      <label className="flex items-center">
                        <span className="w-48 text-sm font-small text-customGray">
                          Accelerator for Millions
                        </span>
                        <span className="ml-3">:</span>
                        <input
                          type="text"
                          value={acceleratorForMillions}
                          maxLength={1}
                          onChange={(e) => {
                            const value = e.target.value.toUpperCase(); // Convert input to uppercase
                            setUnsavedChanges(true);
                            // Only allow 'M' or empty
                            if (value === "M" || value === "") {
                              setAcceleratorForMillions(value); // Set the state if valid
                              setErrorMillions(false); // Reset error if input is valid
                            } else {
                              setErrorMillions(true); // Set error if input is invalid
                            }
                          }}
                          className={`border p-1.5 text-customGray rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-4 ${errorMillions ? "border-red-500" : "border-gray-300"
                            }`}
                          disabled={isViewMode}
                        />
                      </label>
                      {errorMillions && (
                        <p className="text-red-500 text-sm mt-1">
                          Only uppercase 'M' is allowed.
                        </p>
                      )}

                      {/* Time Format Field */}
                      <label className="flex items-center">
                        <span className="w-48 text-sm font-small text-customGray">
                          Time Format
                        </span>
                        <span className="ml-3">:</span>
                        <CustomDropdown
                          value={timeFormat}
                          options={timeFormatOptions}
                          onChange={(value) => {
                            setTimeFormat(value);
                            setUnsavedChanges(true); // Mark as unsaved
                            setOpenDropdown(false);
                          }} isDisabled={isViewMode}
                          width="w-72"

                          isOpen={openDropdown === "timeFormat"}
                          onToggle={() => handleToggle("timeFormat")}
                          setUnsavedChanges={setUnsavedChanges}
                          className="ml-4"
                          placeholder="Select Time Format"
                        />
                        {errors.timeFormat && (
                          <p className="text-red-500 text-sm ml-4">
                            {errors.timeFormat}
                          </p>
                        )}
                      </label>

                      {/* Time Zone Field */}
                      <label className="flex items-center">
                        <span className="w-48 text-sm font-small text-customGray">
                          Time Zone
                        </span>
                        <span className="ml-3">:</span>
                        <input
                          type="text"
                          value={timeZone}
                          onChange={(e) => {
                            setTimeZone(e.target.value)
                            setUnsavedChanges(true);
                          }}
                          className="border border-gray-300 text-customGray p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-4"
                          disabled={isViewMode}
                        />
                      </label>
                    </div>
                    <div className="border-l border-gray-300 mr-4 ">
                      <div className="grid grid-cols-2 gap-x-8 gap-y-8 ml-12">
                        <div className="flex flex-col space-y-6">
                          {/* Existing checkboxes */}
                          <label className="flex items-center">
                            <input

                              type="checkbox"
                              checked={receiveSystemMail === 1}
                              onChange={(e) => {
                                setreceiveSystemMail(e.target.checked ? 1 : 0)
                                setUnsavedChanges(true);
                              }}
                              className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded accent-customBlue whitespace-nowrap"
                              disabled={isViewMode}
                            />
                            <p className="ml-4 font-small text-sm text-customGray whitespace-nowrap">
                              Dont want to receive System emails
                            </p>
                          </label>

                          <label className="flex items-center">
                            <input
                              type="checkbox"
                              checked={downloadData === 1}
                              onChange={(e) => {
                                setdownloadData(e.target.checked ? 1 : 0)
                                setUnsavedChanges(true);
                              }}
                              className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded accent-customBlue accent-customBlue"
                              disabled={isViewMode}
                            />
                            <p className="ml-4 font-small text-sm text-customGray whitespace-nowrap">
                              Download the Data
                            </p>
                          </label>

                          <label className="flex items-center">
                            <input
                              type="checkbox"
                              checked={changesInCashflow === 1}
                              onChange={(e) => {
                                setchangesInCashflow(e.target.checked ? 1 : 0)
                                setUnsavedChanges(true);
                              }}
                              className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded accent-customBlue"
                              disabled={isViewMode}
                            />
                            <p className="ml-4 font-small text-sm text-customGray whitespace-nowrap">
                              Change the Cash flow code
                            </p>
                          </label>

                          <label className="flex items-center">
                            <input
                              type="checkbox"
                              checked={changeVarient === 1}
                              onChange={(e) => {
                                setchangeVarient(e.target.checked ? 1 : 0)
                                setUnsavedChanges(true);
                              }}
                              className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded accent-customBlue"
                              disabled={isViewMode}
                            />
                            <p className="ml-4 font-small text-sm text-customGray whitespace-nowrap">
                              Can Change the varient
                            </p>
                          </label>

                          <label className="flex items-center">
                            <input
                              type="checkbox"
                              checked={deleteAllRecords === 1}
                              onChange={(e) => {
                                setdeleteAllRecords(e.target.checked ? 1 : 0)
                                setUnsavedChanges(true);
                              }}
                              className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded accent-customBlue"
                              disabled={isViewMode}
                            />
                            <p className="ml-4 font-small text-sm text-customGray whitespace-nowrap">
                              Can Delete all records
                            </p>
                          </label>

                          <label className="flex items-center">
                            <input
                              type="checkbox"
                              checked={changeAccountingCode === 1}
                              onChange={(e) => {
                                setchangeAccountingCode(e.target.checked ? 1 : 0)
                                setUnsavedChanges(true);
                              }}
                              className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded accent-customBlue"
                              disabled={isViewMode}
                            />
                            <p className="ml-4 font-small text-sm text-customGray whitespace-nowrap">
                              Change the Accounting Code
                            </p>
                          </label>

                          <label className="flex items-center">
                            <input
                              type="checkbox"
                              checked={updateCountry === 1}
                              onChange={(e) => {
                                setupdateCountry(e.target.checked ? 1 : 0)
                                setUnsavedChanges(true);
                              }}
                              className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded accent-customBlue"
                              disabled={isViewMode}
                            />
                            <p className="ml-4 font-small text-sm text-customGray whitespace-nowrap">
                              Do Not Update Country
                            </p>
                          </label>

                          {/* New checkboxes */}
                          <label className="flex items-center">
                            <input
                              type="checkbox"
                              // checked={updateCountry===1}
                              // onChange={(e) => setreceiveSystemMail(e.target.checked ? 1 : 0)}                         
                              className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded accent-customBlue"
                              disabled={isViewMode}
                            />
                            <p className="ml-4 font-small text-sm text-customGray whitespace-nowrap">
                              Update All the User Preferences
                            </p>
                          </label>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TableWrapper>
            </div>
          </div>
        )}
      </div>

      <Footer>
        {/* Footer */}
        {!isViewMode && (
          <ButtonGroup
            onSave={handleSave}
            onSaveContinue={handleSaveAndContinue}
            hasId={!!countryId}
          />
        )}
      </Footer>
    </div>
  );
};

export default AddCountry;
