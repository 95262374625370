import React, { useCallback, useEffect, useState } from 'react'
import Header from '../../../../../../../components/Header'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AdminActions from '../../../../Components/AdminActions';
import { AdminActionsConfig } from '../../../../Components/ActionConfig';
import { IoMdCheckmark, IoMdClose, IoMdCreate, IoMdRefresh, IoMdTrash } from 'react-icons/io';
import { IoAddCircleOutline, IoSettingsOutline } from 'react-icons/io5';
import ReTable from '../../../../../../../components/Table';
import UserService from '../../../../../../../rest-services/UserServices';
import { useToast } from '../../../../../../../components/toast/toast';
import ColumnSettingsPopup from '../../../../../Masterdata/Components/ColumnSettingPopup';
import { MdOutlineFileDownload, MdOutlineHistory } from 'react-icons/md';
import { TbFlag3Filled } from 'react-icons/tb';
import CustomeLoading from '../../../../../../../components/CustomeLoading';
import SearchInput from '../../../../../../../components/SearchInput';
import { VscVmActive } from 'react-icons/vsc';
import Pagination from '../../../../../../../components/PageNation';
import { debounce } from 'lodash';
import { PiCopy } from 'react-icons/pi';
import { GiSettingsKnobs } from 'react-icons/gi';
import HoverButton from '../../../../../../../components/HoverButton';
import FilterDropdown from '../../../../../../../components/FilterDropdown';
import ConfirmationDialog from '../../../../../../../components/Deletionpopup';
import { RxDropdownMenu } from 'react-icons/rx';
import CustomButton from '../../../../../../../components/CustomButton';
import Draggable from 'react-draggable';

export default function RolesScreen() {
  const navigate = useNavigate();
  const { iconId } = useParams();
  const [searchQuery, setSearchQuery] = useState(""); // Manage search input
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userType, setUserType] = useState('');
  const [userTypes, setUserTypes] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [rolesPermissions, setRolesPermissions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRoles, setselectedRoles] = useState({});

  const [isAllSelected, setIsAllSelected] = useState(false); // Manage "Select All" checkbox state
  const [filterType, setFilterType] = useState("1"); // 'createdBy' or 'updatedBy'
  const [isCreatedByPopupOpen, setIsCreatedByPopupOpen] = useState(false);
    const [selectedCreatedBy, setSelectedCreatedBy] = useState([]);
    const [isUpdatedByPopupOpen, setIsUpdatedByPopupOpen] = useState(false);
    const [updatedbysearchquery, setupdatedbysearchquery] = useState("");
      const [hasFiltersApplied, setHasFiltersApplied] = useState(false);
    const [userPreference, setUserPreference] = useState("");
  const [error, setError] = useState(null);

  const [tempSelectedcreatedby, setTempSelectedcreatedby] =
    useState(selectedCreatedBy);
      const [selectedUpdatedBy, setSelectedUpdatedBy] = useState([]);
    
  const [tempSelectedupdatedby, setTempSelectedupdatedby] =
    useState(selectedUpdatedBy);

    const handleApplyCreatedby = () => {
      setSelectedCreatedBy(tempSelectedcreatedby); // Apply temporary selection to actual selection state
      setFilters((prevFilters) => ({
        ...prevFilters,
        createdBy: tempSelectedcreatedby, // Set the filter
      }));
      setIsCreatedByPopupOpen(false); // Close the popup
    };

     const handleUpdateBySelection = (changedby) => {
        setTempSelectedupdatedby((prev) => {
          return prev.includes(changedby)
            ? prev.filter((item) => item !== changedby)
            : [...prev, changedby];
        });
      };

    const handleApplyUpdatedby = () => {
      setSelectedUpdatedBy(tempSelectedupdatedby); // Apply temporary selection to actual selection state
      setFilters((prevFilters) => ({
        ...prevFilters,
        updatedBy: tempSelectedupdatedby, // Set the filter
      }));
      setIsUpdatedByPopupOpen(false); // Close the popup
    };
    
  const handleCreatedBySelection = (createdBy) => {
    setTempSelectedcreatedby((prev) => {
      // Add or remove company from temporary selection list
      return prev.includes(createdBy)
        ? prev.filter((item) => item !== createdBy) // Remove if already selected
        : [...prev, createdBy]; // Add if not selected
    });
  };

  const handleFilterDateFrom = (e) => {
    console.log(filterType);
    if (filterType === "1") {
      setFilters((prevFilters) => ({
        ...prevFilters,
        created_date_from: e.target.value,
      }));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        updated_date_from: e.target.value,
      }));
    }
  };

  const handleFilterDateto = (e) => {
    if (filterType === "1") {
      setFilters((prevFilters) => ({
        ...prevFilters,
        created_date_to: e.target.value,
      }));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        updated_date_to: e.target.value,
      }));
    }
  };

    useEffect(() => {
      fetchAssignee();
      fetchUserTypes();
    }, []);

  const fetchUserTypes = async () => {
    try {
      const response = await UserService.getUsers(
        `${process.env.REACT_APP_BASE_URL}/get-user-type`
      );
      console.log("set user types", response.data.data);
      setUserTypes(response.data.data);
    } catch (error) {
      console.error("Error fetching user types:", error);
    }
  };

 

  const handleNavigate = (path) => {
    navigate(path);
  };
  
const formatDate = (dateString, format) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const shortYear = String(year).slice(-2);

  const formatMap = {
      "DD/MM/YYYY": `${day}/${month}/${year}`,
      "MM/DD/YYYY": `${month}/${day}/${year}`,
      "DD/MM/YY": `${day}/${month}/${shortYear}`,
      "MM/DD/YY": `${month}/${day}/${shortYear}`,
      "DD.MM.YYYY": `${day}.${month}.${year}`,
      "DDMMYY": `${day}${month}${shortYear}`,
      "DD-MM-YYYY": `${day}-${month}-${year}`,
  };

  return formatMap[format] || dateString;
};
const dateFormat = userPreference?.dateFormat || "DD-MM-YYYY"; // Default format



  const columns = [


    {
      Header: (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <TbFlag3Filled style={{ color: "#ffff" }} />
        </div>
      ),
      accessor: "status",
      visible: true,
      width: 100,
      Cell: ({ row }) => {
        const { status } = row.original;
        const colorMap = {
          0: "gray",
          1: "#AF52DE",
          2: "yellow",
          3: "orange",
          4: "#29CB00",
          5: "red",
        };
        return (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <TbFlag3Filled style={{ color: colorMap[status] || "black" }} />
          </div>
        );
      }
    },
    { Header: 'Role ID', accessor: 'roleId', visible: true, width: 200, alignment: "left" },
    { Header: 'Role Name', accessor: 'name', visible: true, width: 200, alignment: "left" },
    { Header: 'User Type', accessor: 'userType', visible: true, width: 200, Cell: ({ row }) => { const user = row.original; return user.userType == 1 ? 'User' : user.userType == 2 ? 'Super User' : user.userType == 3 ? 'Approvals' : user.userType == 4 ? 'Admin' : user.userType == 5 ? 'Consultant' : user.userType == 6 ? 'Support' : user.userType == 7 ? 'System' : user.userType == 8 ? 'Kickstart' : ' '; } },
    { Header: 'Permissions', accessor: 'permissionsCount', visible: true, width: 200 },
    { Header: 'Created By', accessor: 'createdBy', visible: true, width: 200, alignment: "left" },
    // { Header: 'Created Date', accessor: 'createdDate', visible: true, width: 200 },
    // { Header: 'Created Date', accessor: 'createdDate', visible: true, width: 200, canResize: true, Cell: ({ value }) => { if (!value) return ""; const date = new Date(value); return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`; } },
{
      Header: "Created Date",
      accessor: "createdDate",
      visible: true,
      width: 15,
      canResize: true,
      Cell: ({ value }) => formatDate(value, dateFormat),
  },

    { Header: 'Created Time', accessor: 'createdTime', visible: true, width: 200 },
    { Header: 'Updated By', accessor: 'updatedBy', visible: true, width: 200, alignment: "left" },
    // { Header: 'Updated Date', accessor: 'updatedDate', visible: true, width: 200 },
    // { Header: 'Updated Date', accessor: 'updatedDate', visible: true, width: 200, canResize: true, Cell: ({ value }) => { if (!value) return ""; const date = new Date(value); return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`; } },

    {
          Header: "Updated Date",
          accessor: "updatedDate",
          visible: true,
          width: 15,
          canResize: true,
          Cell: ({ value }) => formatDate(value, dateFormat),
      },

    { Header: 'Updated Time', accessor: 'updatedTime', visible: true, width: 200 },
  ];

  const [columnSettings, setColumnSettings] = useState(columns);
  const [selectedRows, setSelectedRows] = useState([]);
  const [Icons, setIcons] = useState([]);
  const [filteredRoles, setFilteredRoles] = useState([]);
  const [roles, setRoles] = useState([]);
  const [alert, setAlert] = useState(null);
  const [assignee, setAssignee] = useState({ id: "", username: "" });

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const { toast } = useToast();
  const [loading, setLoading] = useState(false);
  const [isSettingsPopupOpen, setIsSettingsPopupOpen] = useState(false);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [usersToShow, setUsersToShow] = useState([]);
  const [createdbydatasearchQuery, setcreatedbydatasearchQuery] = useState("");
  const [filteredcreatedbydatas, setFilteredcreatedbydatas] = useState([]);


  const handleCheckboxChangefilter = (e) => {
    const { name, checked } = e.target;
    setselectedRoles((prev) => ({ ...prev, [name]: checked }));
  };

  
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  
  const closeUserModal = () => {
    setIsUserModalOpen(false);
  };

  
  const closeModal = () => {
    setIsModalOpen(false);
  };


  const debouncedFetchUsers = useCallback(
    debounce((page, query) => {
      fetchRolesDetails(page, query);
    }, 500), // 500ms debounce delay
    []
  );

  useEffect(() => {
    debouncedFetchUsers(currentPage, searchQuery);

    // Cleanup debounce on unmount
    return () => debouncedFetchUsers.cancel();

  }, [currentPage, searchQuery, debouncedFetchUsers]);

  useEffect(() => {
    if (assignee.clientId) {
      fetchRolesDetails();
    }
  }, [assignee]);

  const handleApplyFilter = async () => {
    try {
      setIsModalOpen(false);
      // Prepare the filter payload with dynamic filters from state

      // localStorage.setItem('fetchUsersData', JSON.stringify(filteredUsers));
      // console.log()
      const filterPayload = {
        clientId: assignee.clientId,
        status: filters.status,
        roleId: usersToShow,
        // country: countryToShow,
        userType: filters.userType,
        updatedBy: filters.updatedBy,
        // validTo: filters.validTo,
        createdBy: filters.createdBy,
        created_date_from: filters.created_date_from,
        created_date_to: filters.created_date_to,
        updated_date_from: filters.updated_date_from,
    updated_date_to: filters.updated_date_to,
      };
      console.log("filters userid", usersToShow);
      console.log("Filter payload:", filterPayload);

      // Fetch data from the server using the filter payload
      const response = await UserService.saveapproved(
        `${process.env.REACT_APP_BASE_URL}/get-role-filter`,
        filterPayload
      );

      console.log("Response from server for filtered data:", response);

      const { data, error, message } = response.data;

      // Handle errors if any
      if (error) {
        console.error("Error fetching Users data:", message);
        setAlert({ severity: "error", message });
        return;
      }

      console.log("testing 1", response.data);
      if(response.status === 200){
        setHasFiltersApplied(true);
        setFilteredRoles(response.data);
      }
      // setUsers(response.data); // Set user preference data
      // Store filtered users in session storage
      localStorage.setItem("filterData", JSON.stringify(filters));
      // localStorage.setItem("fetchUsersData", JSON.stringify(response.data));
      localStorage.setItem("usersToShow", JSON.stringify(usersToShow));
    } catch (error) {
      console.error("Error fetching Users data:", error);
      setAlert({
        severity: "error",
        message: `Error fetching Users data. Please check your connection: ${error.message}`,
      });
    }
  };

  
  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          clientId: userDetail.clientId || "",
          assigner: userDetail.username || "",
          userId: userDetail.userId || "",
        };

        setAssignee(assigneeData);

        // Log the id and assigner values to the console
        console.log("Assignee ID:", assigneeData.id);
        console.log("Assigner:", assigneeData.assigner);
        console.log("Assigner clientId:", assigneeData.clientId);
        console.log("Assigner userId:", assigneeData.userId);

      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

  useEffect(() => {
    if (assignee.id) {
      fetchUserPreferenceDetails();
    }
  }, [assignee.id]);

  const fetchUserPreferenceDetails = async () => {
    try {
      const data = { userId: assignee.userId }; // Assuming assignee.id is the user ID
      console.log("Requestfor user pref Data:", data);

      setLoading(true); // Set loading state
      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-edit-user-preference-details-by-Id`,
        data
      );
      console.log("user prefference details", response.data);
      setUserPreference(response.data); // Set user preference data
    } catch (error) {
      setError(error.message); // Set error message
    } finally {
      setLoading(false); // Reset loading state
    }
  };


  useEffect(() => {
    if (assignee.userId) {
      console.log("Assigner ID has role id changed:", assignee.userId);
      fetchUserPermissionDetails();
    }
  }, [assignee.userId]);
  
const fetchUserPermissionDetails = async () => {
  try {
    const data = { userId: assignee.userId };
    console.log("Request Data:", data);

    const response = await UserService.saveContactDetails(
      `${process.env.REACT_APP_BASE_URL}/get-users-permissions-by-userId`,
      data
    );
    console.log("user permissions details", response);
    const responseData = response.data;
    setRolesPermissions(responseData.rolesPermissionsTables || []); // Save permission details
    console.log("Set Permission details for userscreen", responseData.rolesPermissionsTables);
  } catch (error) {
    console.error(error.message); // Set error message
  } finally {
    setLoading(false); // Reset loading state
  }
};

  const handleUpdateColumnSettings = (updatedSettings) => {
    setColumnSettings(updatedSettings);
  };


  const handleCancelClick = async () => {
    if (selectedRows.length > 0) {
      try {
        const response = await UserService.saveapproved(
          `${process.env.REACT_APP_BASE_URL}/edit-rejected-roles`,
          { roleIds: selectedRows, createdBy: assignee.userId, }  // Pass selectedUserIds in the body
        );

        if (response.status === 200) {
          toast("error", "Roles successfully Rejected");
          // navigate("/userscreen");
          await fetchRolesDetails();
        }
        else if (response.status === 400) {
          toast("error", "User is unable to Reject.");
        } else {
          console.log("Error updating users.");
        }
      } catch (error) {
        console.error("Error updating users:", error);
      }
    } else {
      console.log("Please select at least one user to Reject.");
    }
  };

  const resetFilters = () => {
    setFilters({
      status: "",
      roleId: "",
      userType: "",
      validFrom: "",
      validTo: "",
      createdBy: "",
      createdDate: "",
      created_date_from: "",
      created_date_to: "",
      updatedBy: "",
      updated_date_from: "",
      updated_date_to: "",
    });
    // Clear selected users
    setHasFiltersApplied(false)
    
    setUsersToShow([]);
    setselectedRoles({}); // Reset selected users (uncheck all checkboxes)
    setSearchTerm("");
    localStorage.removeItem("filterData");
    localStorage.removeItem("fetchUsersData");
    localStorage.removeItem("usersToShow");
  };

  const handleApproveClick = async () => {
    if (selectedRows.length > 0) {
      setLoading(true);  // Start loading
      try {
        console.log("selected roles is", selectedRows);
        const response = await UserService.saveapproved(
          `${process.env.REACT_APP_BASE_URL}/edit-roles-approved`,
          { roleIds: selectedRows, createdBy: assignee.userId, }  // Pass selectedUserIds in the body
        );
        console.log("Response status:", response.status);

        if (response.status === 200) {
          toast("success", "Role successfully Approved");
          // navigate("/userscreen");
          await fetchRolesDetails();
          // if (refreshTable.current) {
          //   refreshTable.current();  // Call the refresh function to reload the user data
          // }
        }
        else if (response.status === 400) {
          toast("error", "User is unable to approve.");
        }
        else if (response.status === 550) {
          toast("warning", "User Email is Not Sent.");
        }
        else {
          console.log("Error updating users.");
        }
      } catch (error) {
        console.error("Error updating users:", error);
      }
      finally {
        setLoading(false);  // Stop loading
      }
    } else {
      console.log("Please select at least one user to Approve.");
    }
  };


  const handleEditClick = () => {
    if (selectedRows.length === 1) {
      console.log("entered user id");
      // console.log()
      const selectedRole = roles.find((role) => role.id === selectedRows[0]);
      console.log('get roles', selectedRole);
      console.log('get role id', selectedRows[0]);
      // console.log('single user id', selectedUser);

      if (selectedRows[0]) {

        navigate(`/14/admin/111/roles-and-permission/roles-edit/${selectedRows[0]}`, { state: { mode: 'Edit' } });
      } else {
        toast("error", "Selected user not found.");
      }
    } else {
      toast("error", "Please select exactly one user to edit.", {
        duration: 10000, // Set the toast duration to 10 seconds (5000ms)
      });
    }
  };

  const handleAddcopyClick = () => {
    if (selectedRows.length === 1) {
      console.log('check user ids', selectedRows);
      const selectedRole = roles.find((role) => role.id === selectedRows[0]);
      console.log('user id', selectedRole);

      if (selectedRows[0]) {
        navigate(`/14/admin/111/roles-and-permission/roles-copy-add/${selectedRows[0]}`, { state: { mode: 'add' } });
      } else {
        toast("error", "Selected user not found.");
      }
    } else {
      toast("error", "Please select exactly one user to Copy.", {
        duration: 10000, // Set the toast duration to 10 seconds (5000ms)
      });
    }
  };

  const handleAuditTrail = () => {
    if (selectedRows.length === 1) {
      const selectedRole = roles.find((role) => role.id === selectedRows[0]);
      console.log('role id', selectedRows[0]);
      console.log('selected roles id', selectedRole);
      console.log("Icons id", iconId);

      if (selectedRows[0]) {
        // Show the selected user's details using toast
        // toast("info", `Selected User: \nID: ${selectedUser.userId}\nName: ${selectedUser.username}`);

        // Navigate to the edit screen, passing the selected user as state
        navigate(`/audit-trail-screen/${iconId}/${selectedRows[0]}`);
      } else {
        toast("error", "Selected user not found.");
      }
    } else {
      toast("error", "Please select exactly one user to edit.");
    }
  };

  const handleInactiveClick = async () => {
    if (selectedRows.length > 0) {
      try {
        const response = await UserService.saveapproved(
          `${process.env.REACT_APP_BASE_URL}/edit-inactive-roles`,
          { roleIds: selectedRows, createdBy: assignee.userId }  // Pass selectedUserIds in the body
        );

        if (response.status === 200) {
          toast("success", "Role successfully Change Status.");
          // toast("success", "User successfully Change Status");
          // navigate("/userscreen");
          // window.location.reload(); // This will refresh the page

          await fetchRolesDetails();
          await reload();

        }
        else if (response.status === 400) {
          toast("error", "The Roles cannot be deactivated at this time.");
        }
        else {
          console.log("Error updating users.");
        }
      } catch (error) {
        console.error("Error updating users:", error);
      }
    } else {
      console.log("Please select at least one user to Inactive.");
    }
  };

  // const handleDeleteClick = async () => {
  //   if (selectedRows.length === 1) {
  //     try {
  //       console.log("selected role is for delete ", selectedRows);
  //       console.log("selected role is for delete single row", selectedRows[0]);
  //       const response = await UserService.saveapproved(
  //         `${process.env.REACT_APP_BASE_URL}/delete-role`,
  //         { roleId: selectedRows[0], createdBy: assignee.userId }  // Pass selectedUserIds in the body
  //       );

  //       if (response.status === 200) {
  //         toast("error", "Role successfully Deleted.");

  //         // Remove deleted users from the selectedRows
  //         setSelectedRows((prevSelectedRows) =>
  //           prevSelectedRows.filter((rowId) => !selectedRows.includes(rowId))
  //         );
  //         await fetchRolesDetails();

  //       } else {
  //         console.log("Error updating users.");
  //       }
  //     } catch (error) {
  //       console.error("Error updating users:", error);
  //     }
  //   } else {
  //     console.log("Please select at least one user to Delete.");
  //   }
  // };

  const handleDeleteClick = async () => {
    if (selectedRows.length === 0) return;
    setShowConfirmation(true);
  };

  
  const cancelDeletion = () => {
    setShowConfirmation(false);
  };

  const confirmDeletion = async () => {
    setShowConfirmation(false);
    setLoading(true);
    try {
        console.log("selected role is for delete ", selectedRows);
        console.log("selected role is for delete single row", selectedRows[0]);
        const response = await UserService.saveapproved(
          `${process.env.REACT_APP_BASE_URL}/delete-role`,
          { roleId: selectedRows[0], createdBy: assignee.userId }  // Pass selectedUserIds in the body
        );

        if (response.status === 200) {
          toast("error", "Role successfully Deleted.");

          // Remove deleted users from the selectedRows
          setSelectedRows((prevSelectedRows) =>
            prevSelectedRows.filter((rowId) => !selectedRows.includes(rowId))
          );
          await fetchRolesDetails();

        } else {
          console.log("Error updating users.");
        }
      } catch (error) {
        console.error("Error updating users:", error);
      } finally {
        setLoading(false);
      }
    };


    
  const pageSize = 20;

  const fetchRolesDetails = async (page = 1, searchQuery = "") => {
    setLoading(true);
    try {
      if (!assignee.clientId) {
        return false;
      }

      console.log("assignees data", assignee);
      const searchParam = searchQuery ? `&searchQuery=${encodeURIComponent(searchQuery)}` : "";

      const data = {
        clientId: assignee.clientId,
        limit: pageSize,
        offset: (page - 1) * pageSize,
        searchParam
      };
      console.log("Request Data for users:", data);

      const response = await UserService.saveContactDetails(`${process.env.REACT_APP_BASE_URL}/get-roles`,
        data
      );
      console.log("Response from server fetching roles:", response);

      let { searchUsers, totalCount } = response;

      if (!searchUsers) {
        searchUsers = [];
      } else if (!Array.isArray(searchUsers)) {
        searchUsers = [searchUsers];
      }


      // const responseData = response.data;
      console.log('set roles details', searchUsers);
      console.log('set roles totalCount details', totalCount);
      setRoles(searchUsers);
      setTotalCount(totalCount);
      setTotalPages(Math.ceil(totalCount / pageSize));

      // if (responseData.error) {
      //   console.error("Error fetching roles data:", responseData.message);
      //   setAlert({ severity: "error", message: responseData.message });
      //   return;
      // }
    } catch (error) {
      console.error("Error fetching roles data", error);
      setAlert({ severity: "error", message: `Error fetching roles data, kindly check the connection ${error.message}` });
    }
    finally {
      setLoading(false);
    }
  };

  const [filters, setFilters] = useState({
      status: "",
      roleId: "",
      userType: "",
      createdBy: "",
      createdDate: "",
      created_date_from: "",
      created_date_to: "",

      updatedBy: "",
      updated_date_from: "",
      updated_date_to: "",
    });

    const statusOptions = [
      { value: "0", label: "Created", className: "text-gray-500" },
      { value: "2", label: "Modified", className: "text-orange-400" },
      { value: "1", label: "Change", className: "text-yellow-500" },
      { value: "3", label: "Approved", className: "text-[#29CB00]" },
      { value: "4", label: "Rejected", className: "text-red-500" },
      { value: "5", label: "Inactive", className: "text-black" },
    ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("set usertypes list data", value);
    setUserType(value);
    // If you want to update filters as well:
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const reload = async () => {
    setLoading(true);  // Start loading
    try {
      await fetchRolesDetails();  // Fetch users
    } catch (error) {
      console.error('Error reloading users:', error);
    } finally {
      setLoading(false);  // Stop loading
    }
  };

  const extraButtons = [

    {
      icon: IoMdCheckmark, // Pass the component itself
      onClick: handleApproveClick,
      title: "Approve",
      disabled: selectedRows.length === 0,

    },
    {
      icon: IoMdClose,
      onClick: handleCancelClick,
      title: "Reject",
      disabled: selectedRows.length === 0,
    },

    {
      icon: IoMdCreate,
      onClick: handleEditClick,
      title: "Edit",
      disabled: selectedRows.length === 0,
    },
    {
      icon: PiCopy,
      onClick: handleAddcopyClick,
      title: "Copy",
      disabled: selectedRows.length === 0,
    },
    {
      icon: MdOutlineHistory,
      onClick: handleAuditTrail,
      title: "Audit Trail",
      disabled: selectedRows.length === 0,
    },
    {
      icon: IoMdTrash,
      onClick: handleDeleteClick,
      title: "Delete",
      disabled: selectedRows.length === 0,
    },
    {
      icon: VscVmActive,
      onClick: handleInactiveClick,
      title: "Inactive/Active",
      disabled: selectedRows.length === 0,
    },
  ];

  const handleSettingsButtonClick = () => {
    setIsSettingsPopupOpen(true);
  };

  const existingButtons = AdminActionsConfig.screen4.map((button) => {
    let onClick;
    let title;


    if (button.icon === IoMdRefresh) {
      onClick = reload; // Reference the reload function, not invoking it
      title = "Refresh";
    }
    else if (button.icon === IoAddCircleOutline) {
      onClick = () => handleNavigate("/14/admin/111/roles-and-permission/roles/add");
      title = "Add Roles";
    }
    else if (button.icon === IoSettingsOutline) {
      onClick = handleSettingsButtonClick;
      title = "Table Settings";
    }
    else if (button.icon === GiSettingsKnobs) {
          // onClick = handleFilterPopup;
          onClick = () => {
            setIsModalOpen(true);
          }
          title = "Filter";
        }
    else if (button.icon === MdOutlineFileDownload) {
      // onClick = handleDownload;
      title = "Download File";
    }

    else {
      onClick = button.onClick || (() => { }); // Provide a default no-op function if onClick is undefined
      title = button.title || ""; // Use existing title or default to an empty string
    }

    return {
      ...button,
      onClick, // Reference the function without invoking it
      title,   // Add the title property to the button configuration
    };
  });


  const modifiedColumns = columnSettings
    .filter(col => col.visible)
    .map(col => ({ ...col, width: col.width }));

  const handleCheckboxChange = (id) => {
    console.log('checked user id', id);
    const updatedSelectedRows = selectedRows.includes(id)
      ? selectedRows.filter((rowId) => rowId !== id) // Deselect if already selected
      : [...selectedRows, id]; // Add to selection if not selected

    setSelectedRows(updatedSelectedRows);
    console.log('check box:', updatedSelectedRows);

  };


  const handleSelectAll = () => {
    handleSelectAllChange();
  };

  const handleSelectAllChange = () => {
    console.log("roles data check all",roles);
    if (isAllSelected) {
      setSelectedRows([]); // Deselect all
    } else {
      const allIds = roles.map((item) => item.roleId); // Select all IDs
      setSelectedRows(allIds);
    }
    setIsAllSelected(!isAllSelected); // Toggle select all state
  };


  const handleSelectRow = (id) => {
    handleCheckboxChange(id);
  };



  const actionButtons = existingButtons.concat(
    extraButtons.map((button) => ({
      ...button,
      disabled: selectedRows.length === 0, // Disable button if no users are selected
      onClick: selectedRows.length > 0 ? button.onClick : null, // Enable onClick only if users are selected
    }))
  );


  useEffect(() => {
    console.log("check users filtered", roles);
    console.log("check users data", roles);

    if (roles && roles) {
      const filtered = roles.filter((role) =>
        role.roleId && role.roleId.toLowerCase().includes(searchQuery.toLowerCase())
      );
      console.log("Filtered roles data",filtered);
      setFilteredRoles(filtered);
    }
  }, [searchQuery, roles]);


  const handleUserFilterPopup = () => {
    console.log("entered");
    setIsUserModalOpen(true);
  };

  const getSelectDisplayLabel = () => {
    const count = usersToShow.length;
    console.log("count", count);

    if (count === 1) {
      console.log("equal to one count");
      // Show the single selected user ID when only one is selected
      return usersToShow[0];
    } else if (count > 1) {
      console.log("more than 1 count");
      console.log(`more than 1 count display ${usersToShow[0]} +${count - 1}`);
      // Show the first user ID and the number of remaining users
      return `${usersToShow[0]} +${count - 1}`;
    } else {
      // Default text when no users are selected
      return "Select Role";
    }
  };


  const handleDoubleClick = (id) => {
    console.log("check roles id", id);
    const hasPermission = rolesPermissions.some(
      (permission) => permission.permissionsId === 3 && permission.iconId === parseInt(iconId)
    );
    if (hasPermission) {
    navigate(`/14/admin/111/roles-and-permission/roles-view/${id}`, { state: { mode: 'view' } });
    console.log("Navigated to user view screen.");
  } else {
    console.log(
      "No permission with permissionsId === 3 and matching iconId, navigation skipped."
    );
  }
  };

  const handleSubmitUsers = () => {
    // Collect selected user IDs and close modal
    const selectedRolesIds = Object.keys(selectedRoles).filter(
      (roleId) => selectedRoles[roleId]
    );
    console.log("selected users:", selectedRoles);
    console.log("selected :", selectedRolesIds);
    setUsersToShow(selectedRolesIds); // Set selected IDs to be shown in the form
    closeUserModal(); // Close modal after selection
  };


  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === 'User & SOD') {
      navigate('/14/admin');
    } else if (crumb.title === 'Admin') {
      navigate('/14/admin');
    }
    // else if (crumb.title === 'Permission') {
    //     navigate('/14/admin/111/roles-and-permission');
    //   } 
    else if (crumb.path) {
      navigate(crumb.path); // Navigate to the specified path in the breadcrumb
    }
  };

  return (
    <div>
      <Header
        title="Admin > User & SOD > Roles"
        onBreadcrumbClick={handleBreadcrumbClick}
      />
      {/* <div className="flex justify-start ml-5 mt-5 items-center">
                <span
                    className={`text-xl font-md cursor-pointer text-[#0B5299]`}
                >
                Available Roles
                </span>
            </div> */}

      <div>
        <div className="flex justify-between items-center -ml-1 pl-4">
          <div className="flex items-center">

            <SearchInput
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              placeholder="Search Role Id"
            />

            <AdminActions
              icons={iconId}
              buttons={actionButtons}
              // extraButtons={extraButtons}
              selectedCount={selectedRows.length}
              selectUserIds={selectedRows} 
              hasFiltersApplied={hasFiltersApplied}
              />
          </div>

          <div className="flex items-center mr-4">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              totalCount={totalCount}
            />
          </div>

        </div>
      </div>

  {/* Modal */}
  {isModalOpen && (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">

  <Draggable handle=".modal-header">   
       {/* Backdrop */}
     <div className="fixed inset-0 flex items-center justify-center bg-opacity-50 z-50">

       {/* Modal Content */}
       <div className="relative bg-white rounded-lg p-6 w-10/12 max-w-3xl shadow-lg">
         <div className="modal-header flex justify-between items-center cursor-move p-4 bg-white-100 rounded-t-lg">
               <h2 className="text-customBlue font-semibold mb-5">
                 Apply Filters
               </h2>

               {/* <div className={"p-2"}> */}
               <HoverButton
                 onClick={resetFilters}
                 title={"Reset Filter"}
               />
               {/* </div> */}

         </div>

         <div className="grid grid-cols-2 gap-4">
           {/* Form grid */}
           <div className="col-span-2 sm:col-span-1">
               {/* Status */}
               <div className="flex items-center mb-4">
                 <label className="text-customGray text-sm mr-2 w-[140px]">
                   Status:
                 </label>
               
                 {/* <FilterDropdown
                   name="status"
                   value={filters.status}
                   onChange={handleChange}
                   options={statusOptions}
                   placeholder="Select Status"
                   className="bg-white text-sm"
                 /> */}
                 <select
                   name="status"
                   value={filters.status}
                   onChange={handleChange}
                   className="text-customGray border border-gray-300 pl-1 rounded-md w-[165px] h-[40px] focus:outline-none hover:border-blue-400 "
                 >
                 <option value="" className=" text-blue-500">
                     Select Status
                   </option>
                   <option value="0" className=" text-gray-500">
                     Created
                   </option>
                   <option value="1" className=" text-[#AF52DE]">
                     Changed
                   </option>
                  
                   <option value="2 " className=" text-yellow-500">
                     Waiting for Approval
                   </option>
                   <option value="3" className=" text-orange-400">
                     Modified
                   </option>
                   <option value="4" className=" text-[#29CB00]">
                     Approved
                   </option>
                   <option value="5" className=" text-red-500">
                     Rejected
                   </option>
                   <option value="6" className=" text-black">
                     Inactive
                   </option>
                 </select>
               </div>

               {/* Role ID - Searchable and Multi-Select */}
               <div className="flex items-center  mb-4">
                 <label className="text-customGray text-sm mr-2 w-[140px]">
                   Role ID:
                 </label>
               

                 <button
                   onClick={handleUserFilterPopup} // Trigger modal or selection dropdown
                   className={`border border-gray-300 p-2 rounded-md w-[165px] h-[40px] text-customGray focus:outline-none hover:border-blue-400 text-left flex items-center justify-between border-gray-300 hover:border-blue-400'
             }`}
                 >
                   <span className="truncate text-sm">
                     {filters.roleId.length > 0
                       ? filters.roleId.join(", ") // Display selected users
                       : getSelectDisplayLabel() || "Select Role"}
                   </span>
                   <RxDropdownMenu className="ml-2 text-lg" />
                 </button>
                   {isUserModalOpen && (
                         <div className="fixed inset-0 bg-black bg-opacity-50 z-50">
                         <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-xl z-50">
                           <div className="flex justify-between items-center mb-4">
                             <h2 className="text-customBlue font-semibold">
                                 Select Roles
                               </h2>
                               {/* <button className="text-red-500" onClick={closeUserModal}>
                                 &times;
                               </button> */}
                               <HoverButton
                                 // onClick={handleBankAccountIdReset}
                                 title={"Reset"}
                               />
                             </div>
                 
                             {/* Search Input */}
                             <input
                               type="text"
                               placeholder="Search Roles..."
                               value={searchTerm}
                               onChange={(e) => setSearchTerm(e.target.value)}
                               className="border border-gray-300 p-2 rounded-md mb-4 w-full"
                             />
                 
                             {/* Scrollable Checkbox Grid */}
                             {/* <div className="grid grid-cols-4 gap-x-10 gap-y-4 overflow-y-auto max-h-[60vh]"> */}
                              <div className="space-y-2 max-h-80 overflow-y-auto">

                               {roles
                                 .filter(
                                   (role) =>
                                     role.roleId &&
                                     role.roleId.toLowerCase().includes(searchTerm.toLowerCase())
                                 )
                                 .reduce((acc, role, index) => {
                                   const columnIndex = Math.floor(index / 10);
                                   acc[columnIndex] = acc[columnIndex] || [];
                                   acc[columnIndex].push(role);
                                   return acc;
                                 }, [])
                                 .map((column, columnIndex) => (
                                   <div key={columnIndex} className="flex flex-col space-y-2">
                                     {column.map((role) => (
                                       <label
                                         key={role.value}
                                         className="flex items-center text-customGray"
                                       >
                                         <input
                                           type="checkbox"
                                           name={role.roleId}
                                           checked={selectedRoles[role.roleId] || false}
                                           onChange={handleCheckboxChangefilter}
                                           className="mr-2"
                                         />
                                         {role.roleId}
                                       </label>
                                     ))}
                                   </div>
                                 ))}
                             </div>
                 
                             <div className="flex justify-end mt-4">
                               <HoverButton
                                 title={"Cancel"}
                                 className={"mr-2"}
                                 onClick={closeUserModal}
                               />
                               <CustomButton
                                 onClick={() => {
                                   // setIsBankAccountIdPopupOpen(false);
                                   handleSubmitUsers();
                                 }}
                                 title={"Apply"}
                               />
                             </div>
                           </div>
                         </div>
                       )}

               </div>

               {/* User Type */}
               <div className="flex items-center">
                 <label className="text-customGray text-sm mr-2 w-[140px]">
                   User Type:
                 </label>
                 <select
                   name="userType"
                   value={filters.userType}
                   onChange={handleChange}
                   className="text-customGray text-sm border border-gray-300 p-2 rounded-md w-[165px] h-[40px] focus:outline-none hover:border-blue-400"
                 >
                   <option className="text-sm" value="">Select user type</option>
                   {userTypes.map((type) => (
                     <option key={type.id} value={type.id}>
                       {type.name}
                     </option>
                   ))}
                 </select>

                 {/* <FilterDropdown
                   name="status"
                   value={filters.userType}
                   onChange={handleChange}
                   options={statusOptions}
                   placeholder="Select Status"
                   className="bg-white text-sm"
                 /> */}

               </div>

           </div>

           <div className="col-span-2 flex justify-between items-center">
                           
           </div>

              


                <div className="flex items-center mb-2">
                               {/* Select Dropdown */}
                               <select
                                 name="filterType"
                                 value={filterType} // State to track the selected filter option
                                 onChange={(e) => {
                                   setFilterType(e.target.value); // Update the selected filter
                                 }}
                                 className="text-customGray text-sm border border-gray-300 p-2 rounded-md w-[120px] h-[40px] focus:outline-none hover:border-blue-400"
                               >
                                 <option value="1">Created By</option>
                                 <option value="2">Updated By</option>
                               </select>
               
                               {/* Filter Button */}
                               <button
                                 className="text-customGray text-sm border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer ml-6 "
                                 onClick={() => {
                                   if (filterType === "1") {
                                     setIsCreatedByPopupOpen(true);
                                     setTempSelectedcreatedby(selectedCreatedBy);
                                   } else if (filterType === "2") {
                                     setIsUpdatedByPopupOpen(true);
                                     setTempSelectedupdatedby(selectedUpdatedBy);
                                   }
                                 }}
                                 disabled={!filterType} // Disable button if no filter is selected
                               >
                                 <span className="truncate">
                                   {filterType === "1"
                                     ? selectedCreatedBy.length > 0
                                       ? selectedCreatedBy.join(", ")
                                       : "Created By"
                                     : filterType === "2"
                                       ? selectedUpdatedBy.length > 0
                                         ? selectedUpdatedBy.join(", ")
                                         : "Updated By"
                                       : "Select Filter"}
                                 </span>
                                 <RxDropdownMenu className="ml-2 text-lg" />
                               </button>
                             </div>
           
                 {/* Created/Updated by */}
                 
   
                 {isCreatedByPopupOpen && (
                   <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
                     <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-xl z-50">
                       <div className="flex justify-between items-center mb-4">
                         <h2 className="text-customBlue font-semibold">
                           Select Created By
                         </h2>
                         <HoverButton
                           // onClick={handleCreatedbyReset}
                           title={"Reset"}
                         />
                       </div>
                       {/* Search and Filter */}
                       <input
                         type="text"
                         className="border border-gray-300 p-2 rounded-md mb-4 w-full"
                         placeholder="Search User Id"
                         value={createdbydatasearchQuery}
                         onChange={(e) => setcreatedbydatasearchQuery(e.target.value)}
                       />
                       <div className="space-y-2 max-h-80 overflow-y-auto">
                        
                         {[...new Set(filteredRoles.map((created) => created.createdBy))].map((uniqueCreatedBy) => (
                           <div key={uniqueCreatedBy} className="flex items-center">
                             <input
                               type="checkbox"
                               checked={tempSelectedcreatedby.includes(uniqueCreatedBy)}
                               onChange={() => handleCreatedBySelection(uniqueCreatedBy)}
                             />
                             <label className="ml-2">{uniqueCreatedBy}</label>
                           </div>
                         ))}


                       </div>
   
                       {/* Actions */}
                       <div className="flex justify-end mt-4">
                         <HoverButton
                           title={"Cancel"}
                           className={"mr-2"}
                           onClick={() => setIsCreatedByPopupOpen(false)}
                         />
                         <CustomButton
                           onClick={() => {
                             setIsCreatedByPopupOpen(false);
                             handleApplyCreatedby();                          // applyCreatedByFilter(); // Apply the selected filter
                           }}
                           title={"Apply"}
                         />
                       </div>
                     </div>
                   </div>
                 )}
   
                 {/* Updated By Popup */}
                 {isUpdatedByPopupOpen && (
                   <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
                     <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-xl z-50">
                       <div className="flex justify-between items-center mb-4">
                         <h2 className="text-customBlue font-semibold">
                           Select Updated By
                         </h2>
                         <HoverButton
                           //  onClick={resetSelectedUpdatedBy}
                           title={"Reset"}
                         />
                       </div>
                       {/* Search and Filter */}
                       <input
                         type="text"
                         className="border border-gray-300 p-2 rounded-md mb-4 w-full"
                         placeholder="Search Updated By"
                       value={updatedbysearchquery}
                       onChange={(e) => setupdatedbysearchquery(e.target.value)}
                       />
                       <div className="space-y-2 max-h-80 overflow-y-auto">
                       
                       {[...new Set(filteredRoles.map((updated) => updated.updatedBy))].map((uniqueUpdatedBy) => (
                           <div key={uniqueUpdatedBy} className="flex items-center">
                             <input
                               type="checkbox"
                               checked={tempSelectedupdatedby.includes(uniqueUpdatedBy)}
                               onChange={() => handleUpdateBySelection(uniqueUpdatedBy)}
                             />
                             <label className="ml-2">{uniqueUpdatedBy}</label>
                           </div>
                         ))}

                       </div>
                       {/* Actions */}
                       <div className="flex justify-end mt-4">
                         <HoverButton
                           title={"Cancel"}
                           className={"mr-2"}
                           onClick={() => setIsUpdatedByPopupOpen(false)}
                         />
                         <CustomButton
                           onClick={() => {
                             handleApplyUpdatedby();
                             setIsUpdatedByPopupOpen(false);
                             // applyUpdatedByFilter(); // Apply the selected filter
                           }}
                           title={"Apply"}
                         />
                       </div>
                     </div>
                   </div>
                 )}
   
                 {filterType && (
                   <div className="col-span-2 flex justify-between items-center">
                     <div className="flex items-center">
                       <label className="text-customGray mr-2 w-[140px]">
                         {filterType === "createdBy"
                           ? " Date From:"
                           : " Date From:"}
                       </label>
                       <input
                         type="date"
                         name="dateFrom"
                         value={
                           filterType === "1"
                             ? filters.created_date_from
                             : filters.updated_date_from
                         }
                         onChange={(e) => handleFilterDateFrom(e)}
                         className="text-customGray border border-gray-300 p-2 rounded-md w-[165px] h-[40px] focus:outline-none hover:border-blue-400"
                       />
                     </div>
                     <div className="flex items-center">
                       <label className="text-customGray mr-2 w-[140px]">
                         {filterType === "createdBy" ? " Date To:" : " Date To:"}
                       </label>
                       <input
                         type="date"
                         name="dateTo"
                         value={
                           filterType === "1"
                             ? filters.created_date_to
                             : filters.updated_date_to
                         }
                         onChange={(e) => handleFilterDateto(e)}
                         className="text-customGray border border-gray-300 p-2 rounded-md w-[165px] h-[40px] focus:outline-none hover:border-blue-400"
                       />
                     </div>
                   </div>
                 )}

        

         </div>
       
         {/* Apply Button */}
         {/* <div className="mt-6 text-right">
           <button
             onClick={closeModal}
             className="bg-white text-customBlue border-customTableBorder px-4 py-2"
           >
             Cancel
           </button>
           <button
             className="bg-customBlue text-white px-4 py-2 rounded-md hover:bg-blue-600"
             onClick={handleApplyFilter} // Call the fetch function on Apply
           >
             Apply
           </button>
         </div> */}
       
       <div className="mt-6 flex justify-end space-x-3">
                                   <HoverButton
                                     onClick={closeModal}
                                     title={"Cancel"}
                                   />
                                   <CustomButton
                                     title={"Apply"}
                                     onClick={() => {
                                       handleApplyFilter();
                                     }}
                   
                                   />
                                 </div>
                                 
       </div>
       
       </div>
     </Draggable>
     </div>
   )}

      <div>
        {loading &&
          // <div className="loading-spinner">Loading...</div>
          <div><CustomeLoading /></div>
        }

        {/* Table Component */}
        <ReTable
          data={filteredRoles}
          columns={modifiedColumns} // Use modified columns based on visibility
          selectedRows={selectedRows}
          onSelectRow={handleSelectRow}
          onSelectAll={handleSelectAll}
          onRowDoubleClick={handleDoubleClick}
          rowKey="roleId"  // Pass userId as the key for row selection
        />
      </div>
      {isSettingsPopupOpen && (
        <ColumnSettingsPopup
          columns={columnSettings}
          onClose={() => setIsSettingsPopupOpen(false)}
          onUpdate={handleUpdateColumnSettings}
        />
      )}

<ConfirmationDialog
              open={showConfirmation}
              title="Confirm Deletion"
              message="Are you sure you want to delete this selected Role ID?"
              onConfirm={confirmDeletion}
              onCancel={cancelDeletion}
            />

    </div>
  )
}
