import { IoAddCircleOutline, IoSettingsOutline } from "react-icons/io5";
import { GiSettingsKnobs } from "react-icons/gi";
import { MdOutlineFileDownload, MdOutlineFileUpload } from "react-icons/md";
import { IoMdRefresh } from "react-icons/io";
import { CiSearch } from "react-icons/ci";
import { GiTwirlyFlower } from "react-icons/gi";

export const PrimaryActionsConfig = {
  screen1: [
    { icon: IoAddCircleOutline, onClick: () => {}, rotate: true, title: "Add" },
    { icon: IoSettingsOutline, onClick: () => console.log("Settings"), className: "text-red-500" },
    { icon: MdOutlineFileDownload, onClick: () => {}, className: "text-yellow-500" },
    { 
      icon: IoMdRefresh, 
       // Refreshes the page
      rotate: true, 
    },
  ],
 
  screen2: [
    { icon: IoAddCircleOutline, onClick: () => {}, rotate: true, title: "Add" },
    { icon: GiSettingsKnobs, onClick: () =>{}, rotate: true, className: "text-green-500" ,style: { transform: 'rotate(0deg)' }, },

    { icon: IoSettingsOutline, onClick: () => console.log("Settings"), className: "text-red-500" },
    { icon: MdOutlineFileDownload, onClick: () => {}, className: "text-yellow-500" },
    { icon: MdOutlineFileUpload, onClick: () => {}, className: "text-purple-500" },

    { 
      icon: IoMdRefresh, 
       // Refreshes the page
      rotate: true, 
    },
  ],
  screen3: [
    { icon: IoAddCircleOutline, onClick: () => {}, rotate: true, className: "text-blue-500" },
    {
      icon: GiSettingsKnobs,
      onClick: () => console.log("Settings Knobs"),
      rotate: true,
      className: "text-green-500",
    },
    { icon: IoSettingsOutline, onClick: () => console.log("Settings"), className: "text-red-500" },
    { icon: MdOutlineFileDownload, onClick: () => console.log("Download"), className: "text-yellow-500" },
    {
      icon: IoMdRefresh,
      onClick: () => window.location.reload(),  // Refreshes the page
      rotate: true,
      className: "text-pink-500",
    },
    { icon: GiTwirlyFlower, onClick: () => {}, className: "text-orange-500" },
  ],
  screen4: [
    { icon: GiSettingsKnobs, onClick: () =>{}, rotate: true, className: "text-green-500" ,style: { transform: 'rotate(0deg)' }, },

    { icon: IoSettingsOutline, onClick: () => console.log("Settings"), className: "text-red-500" },
    { icon: MdOutlineFileDownload, onClick: () => {}, className: "text-yellow-500" },
    { icon: MdOutlineFileUpload, onClick: () => {}, className: "text-purple-500" },

    { 
      icon: IoMdRefresh, 
       // Refreshes the page
      rotate: true, 
    },
  ],
};
