
import React, { useState } from 'react';
import { useTable, useResizeColumns, useFlexLayout , useSortBy } from 'react-table';
import { Checkbox } from '@mui/material';
import styled from 'styled-components';

const TableWrapper = styled.div`
  max-height: 80vh;
  width: ${(props) => props.customWidth || '100%'};
  overflow: ${(props) => (props.disableScroll ? 'visible' : 'auto')};
  position: relative;
 
  /* For WebKit-based browsers (Chrome, Edge, Safari) */
  ::-webkit-scrollbar {
    width: 6px; /* Vertical scrollbar width */
    height: 6px; /* Horizontal scrollbar height */
  }
  ::-webkit-scrollbar-thumb {
    background-color: #8aa6c1;
    border-radius: 6px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #6b8db2; /* Slightly darker for hover */
  }
  ::-webkit-scrollbar-track {
    background-color: #f0f4f8;
    border-radius: 6px;
  }
 
  /* For Firefox */
  scrollbar-width: thin; /* Applies to both horizontal and vertical */
  scrollbar-color: #8aa6c1 #f0f4f8; /* Thumb and track colors */
`;

const ReTable = ({
  data,
  columns,
  onSelectRow,
  selectedRows,
  onSelectAll,
  onRowDoubleClick,
  customRowColor,
  rowKey = "id",
  showCheckbox = true,
  showHeaderCheckbox = true,
  customWidth = "100%",
  checkboxPosition = "start",
  className,
  disableScroll = false, // New prop
}) => {
  const safeData = Array.isArray(data) ? data : [data];
  const [overriddenRows, setOverriddenRows] = useState([]); // Tracks rows that were manually toggled by the user

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: safeData,
      },
      useFlexLayout,
      useSortBy,
      useResizeColumns
    );

  const handleRowSelection = (rowId, isMakeAndChecker) => {
    if (isMakeAndChecker) {
      if (overriddenRows.includes(rowId)) {
        setOverriddenRows((prev) => prev.filter((id) => id !== rowId));
      } else {
        setOverriddenRows((prev) => [...prev, rowId]);
      }
    }
    onSelectRow(rowId);
  };

  const isRowChecked = (rowId, isMakeAndChecker) => {
    return (
      (selectedRows.includes(rowId) && !overriddenRows.includes(rowId)) ||
      (isMakeAndChecker && !overriddenRows.includes(rowId))
    );
  };

  return (
    <TableWrapper
      {...getTableProps()}
      className="pr-2 pl-2"
      // style={{
      //   maxHeight: "80vh",
      //   width: customWidth,
      //   overflowY: 'auto',
      //   overflowX: 'auto',
      // }}
      customWidth={customWidth}
      disableScroll={disableScroll} // Apply prop here
    >
      <table
        className="table-fixed min-w-full bg-white border border-customBlue"
        style={{
          borderCollapse: 'collapse', // Add this style to collapse borders
        }}
      >
        <thead className={`bg-customBlue  text-center text-sm font-small border border-customBlue sticky top-0 z-10 ${className}`}>
          <tr className="z-20">
            {/* Conditionally render checkbox column at the start or end */}
            {checkboxPosition === "start" && showCheckbox && (
              <th
                className="text-center text-white font-medium border-r border-customTableBorder whitespace-nowrap bg-customBlue sticky top-0 z-10"
                style={{
                  width: "30px",
                  minWidth: "30px",
                  maxWidth: "30px",
                }}
              >
                {showHeaderCheckbox ? (
                  <Checkbox
                    size="small"
                    
                    checked={selectedRows.length === safeData.length}
                    onChange={onSelectAll}
                    sx={{ transform: "scale(0.8)" }}
                  />
                ) : (
                  <span>Active</span>
                )}
              </th>
            )}

            {headerGroups.map((headerGroup) => (
              <React.Fragment key={headerGroup.id}>
                {headerGroup.headers.map((column, columnIndex) => {
                  const isLastColumn = columnIndex === headerGroup.headers.length - 1;
                  return (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={`px-2 text-center text-white font-medium
            ${!isLastColumn ? 'border-r border-sky-100' : ''}
            whitespace-nowrap bg-customBlue sticky top-0 z-10`}
                      style={{
                        width: `${column.width}px`,
                        height: '30px',
                        textAlign: column.alignment || 'center',
                      }}
                    >
                      {column.render('Header')}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' 🔽' // Descending
                            : ' 🔼' // Ascending
                          : ''}
                      </span>
                      {column.canResize && (
                        <div
                          {...column.getResizerProps()}
                          className="absolute top-0 right-0 w-2 h-full cursor-col-resize"
                          style={{ transform: 'translateX(50%)' }}
                        />
                      )}
                    </th>
                  );
                })}
              </React.Fragment>
            ))}

            {checkboxPosition === "end" && showCheckbox && (
              <th
                className="text-center text-white font-medium border-r border-customTableBorder relative whitespace-nowrap bg-customBlue sticky top-0 z-10"
                style={{
                  width: "50px",
                  minWidth: "50px",
                  maxWidth: "50px",
                }}
              >
                {showHeaderCheckbox ? (
                  <Checkbox
                    size="small"
                    checked={selectedRows.length === safeData.length}
                    onChange={onSelectAll}
                    sx={{ transform: "scale(0.8)" }}
                  />
                ) : (
                  <span>Active</span>
                )}
              </th>
            )}
          </tr>
        </thead>
        <tbody className="text-[11px]" {...getTableBodyProps()}>
          {rows.length === 0 ? (
            <tr>
              <td
                colSpan={columns.length + (showCheckbox ? 1 : 0)}
                className="py-1 px-2 text-center"
              >
                No data found.
              </td>
            </tr>
          ) : (
            rows.map((row, rowIndex) => {
              prepareRow(row);
              const rowId = row.original[rowKey];
              const isMakeAndChecker = row.original.makeAndChecker === 1;
              const isChecked = isRowChecked(rowId, isMakeAndChecker);
              const isLastRow = rowIndex === rows.length - 1; // Check if it's the last row

              return (
                <tr
                  key={rowId}
                  className={
                    row.original.highlight
                      ? "bg-yellow-200" // Highlight color for different userTypes
                      : row.original.successfulrecords
                      ? "bg-green-100" // Background for successful records
                        : selectedRows.includes(rowId)
                          ? "bg-blue-100"
                          : row.index % 2 === 0
                            // table row wise varient color
                            ? "bg-white"
                            : "bg-sky-50"
                  }
                  onDoubleClick={() => onRowDoubleClick(rowId)}
                  style={{
                    height: "20px",
                    // backgroundColor: customRowColor,
                    backgroundColor: row.original.highlight
                    ? "#FFE7B7"
                    : row.original.successfulrecords
                    ? "#C8FACD"
                    : customRowColor, // Inline style for more control
                  }}
                >
                  {checkboxPosition === "start" && showCheckbox && (
                    <td className="text-center border-r border-customTableBorder whitespace-nowrap text-customGray">
                      <Checkbox
                        size="small"
                        checked={isChecked}
                        onChange={() =>
                          handleRowSelection(rowId, isMakeAndChecker)
                        }
                        sx={{ transform: "scale(0.6)" }}
                      />
                    </td>
                  )}

                  {row.cells.map((cell, cellIndex) => {
                    const isLastColumn = cellIndex === row.cells.length - 1; // Check if it's the last column

                    return (
                      <td
                        key={cell.column.id}
                        {...cell.getCellProps()}
                        className={`px-2 pt-6 text-center ${!isLastRow ? 'border-b' : ''} ${!isLastColumn ? 'border-r' : ''} border-customTableBorder whitespace-nowrap h-4 text-customGray`}
                        style={{
                          width: `${cell.column.width}px`,
                          height: "30px",
                          textAlign: cell.column.alignment || "center",
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}

                  {checkboxPosition === "end" && showCheckbox && (
                    <td className="text-center border-r border-customTableBorder whitespace-nowrap text-customGray">
                      <Checkbox
                        size="small"
                        checked={isChecked}
                        onChange={() =>
                          handleRowSelection(rowId, isMakeAndChecker)
                        }
                        sx={{ transform: "scale(0.8)" }}
                      />
                    </td>
                  )}
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </TableWrapper>
  );
};

export default ReTable;


// import React, { useState } from 'react';
// import { useTable, useResizeColumns, useFlexLayout , useSortBy } from 'react-table';
// import { Checkbox } from '@mui/material';
// import styled from 'styled-components';
// const StyledTable = styled.table`
//   border-collapse: collapse;
//   width: 100%;

//   thead th {
//     color: white;
//     padding: 4px 6px;
//     height: 16px; /* Adjusted header height */
//     font-size: 12px; /* Smaller font size */
//     line-height: 1.2;
//     vertical-align: middle;
//   }

//   tbody td {
//     height: 20px; /* Adjusted row height */
//     padding: 2px 4px; /* Reduced padding */
//     line-height: 1.2;
//     vertical-align: middle; /* Ensures content is centered */
//   }

//   th, td {
//     border: 1px solid rgb(217, 236, 250); /* Border to make it visible */
//   }
// `;

// const TableWrapper = styled.div`
//   max-height: 80vh;
//   width: ${(props) => props.customWidth || '100%'};
//   overflow: ${(props) => (props.disableScroll ? 'visible' : 'auto')};
//   position: relative;

//   ::-webkit-scrollbar {
//     width: 6px;
//     height: 6px;
//   }
//   ::-webkit-scrollbar-thumb {
//     background-color: #8aa6c1;
//     border-radius: 6px;
//   }
//   ::-webkit-scrollbar-thumb:hover {
//     background-color: #6b8db2;
//   }
//   ::-webkit-scrollbar-track {
//     background-color: #f0f4f8;
//     border-radius: 6px;
//   }
//   scrollbar-width: thin;
//   scrollbar-color: #8aa6c1 #f0f4f8;
// `;

// const ReTable = ({
//   data,
//   columns,
//   onSelectRow,
//   selectedRows,
//   onSelectAll,
//   onRowDoubleClick,
//   customRowColor,
//   rowKey = "id",
//   showCheckbox = true,
//   showHeaderCheckbox = true,
//   customWidth = "100%",
//   checkboxPosition = "start",
//   className,
//   disableScroll = false, // New prop
// }) => {
//   const safeData = Array.isArray(data) ? data : [data];
//   const [overriddenRows, setOverriddenRows] = useState([]); // Tracks rows that were manually toggled by the user

//   const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
//     useTable(
//       {
//         columns,
//         data: safeData,
//       },
//       useFlexLayout,
//       useSortBy,
//       useResizeColumns
//     );

//   const handleRowSelection = (rowId, isMakeAndChecker) => {
//     if (isMakeAndChecker) {
//       if (overriddenRows.includes(rowId)) {
//         setOverriddenRows((prev) => prev.filter((id) => id !== rowId));
//       } else {
//         setOverriddenRows((prev) => [...prev, rowId]);
//       }
//     }
//     onSelectRow(rowId);
//   };

//   const isRowChecked = (rowId, isMakeAndChecker) => {
//     return (
//       (selectedRows.includes(rowId) && !overriddenRows.includes(rowId)) ||
//       (isMakeAndChecker && !overriddenRows.includes(rowId))
//     );
//   };

//   return (
//     <TableWrapper
//       {...getTableProps()}
//       className="pr-2 pl-2"
//       // style={{
//       //   maxHeight: "80vh",
//       //   width: customWidth,
//       //   overflowY: 'auto',
//       //   overflowX: 'auto',
//       // }}
//       customWidth={customWidth}
//       disableScroll={disableScroll} // Apply prop here
//     >
//     <StyledTable>
//       <table
//         className="table-fixed min-w-full bg-white border border-customBlue"
//         style={{
//           borderCollapse: 'collapse', // Add this style to collapse borders
//         }}
//       >
//         <thead className={`bg-customBlue  text-center text-sm font-small border border-customBlue sticky top-0 z-10 ${className}`}>
//           <tr className="z-20" style={{ height: "30px" }}>
//             {/* Conditionally render checkbox column at the start or end */}
//             {checkboxPosition === "start" && showCheckbox && (
//               <th
//                 className="text-center text-white font-medium border-r border-customTableBorder whitespace-nowrap bg-customBlue sticky top-0 z-10"
//                 style={{
//         padding: "4px 6px", // Reduced padding
//         height: "24px", // Adjusted height
//       }}
//               >
//                 {showHeaderCheckbox ? (
//                   <Checkbox
//                     size="small"
//                     checked={selectedRows.length === safeData.length}
//                     onChange={onSelectAll}
//                     sx={{ transform: "scale(0.8)" }}
//                   />
//                 ) : (
//                   <span>Active</span>
//                 )}
//               </th>
//             )}

//             {headerGroups.map((headerGroup) => (
//               <React.Fragment key={headerGroup.id}>
//                 {headerGroup.headers.map((column, columnIndex) => {
//                   const isLastColumn = columnIndex === headerGroup.headers.length - 1;
//                   return (
//                     <th
//                       {...column.getHeaderProps(column.getSortByToggleProps())}
//                       className={`px-2 text-center text-white font-medium
//             ${!isLastColumn ? 'border-r border-sky-100' : ''}
//             whitespace-nowrap bg-customBlue sticky top-0 z-10`}
//                       style={{
//                         width: `${column.width}px`,
//                         height: '30px',
//                         textAlign: column.alignment || 'center',
//                       }}
//                     >
//                       {column.render('Header')}
//                       <span>
//                         {column.isSorted
//                           ? column.isSortedDesc
//                             ? ' 🔽' // Descending
//                             : ' 🔼' // Ascending
//                           : ''}
//                       </span>
//                       {column.canResize && (
//                         <div
//                           {...column.getResizerProps()}
//                           className="absolute top-0 right-0 w-2  cursor-col-resize"
//                           style={{ transform: 'translateX(50%)' }}
//                         />
//                       )}
//                     </th>
//                   );
//                 })}
//               </React.Fragment>
//             ))}

//             {checkboxPosition === "end" && showCheckbox && (
//               <th
//                 className="text-center text-white font-medium border-r border-customTableBorder relative whitespace-nowrap bg-customBlue sticky top-0 z-10"
              
//               >
//                 {showHeaderCheckbox ? (
//                   <Checkbox
//                     size="small"
//                     checked={selectedRows.length === safeData.length}
//                     onChange={onSelectAll}
//                     sx={{ transform: "scale(0.8)" }}
//                   />
//                 ) : (
//                   <span>Active</span>
//                 )}
//               </th>
//             )}
//           </tr>
//         </thead>
//         <tbody
//   className="text-xs text-center"
//   {...getTableBodyProps()}
//   style={{
//     fontSize: "12px", // Reduce font size
//     lineHeight: "1.2", // Adjust line spacing
//   }}
// >
//   {rows.length === 0 ? (
//     <tr>
//       <td
//         colSpan={columns.length + (showCheckbox ? 1 : 0)}
//         className="py-2"
//         style={{ height: "24px", textAlign: "center" }}
//       >
//         No data found.
//       </td>
//     </tr>
//   ) : (
//     rows.map((row, rowIndex) => {
//       prepareRow(row);
//       const rowId = row.original[rowKey];
//       const isMakeAndChecker = row.original.makeAndChecker === 1;
//       const isChecked = isRowChecked(rowId, isMakeAndChecker);
//       const isLastRow = rowIndex === rows.length - 1; // Check if it's the last row

//       return (
//         <tr
//           key={rowId}
//           className={`${
//             row.original.highlight
//               ? "bg-yellow-200"
//               : selectedRows.includes(rowId)
//               ? "bg-blue-100"
//               : row.index % 2 === 0
//               ? "bg-white"
//               : "bg-sky-50"
//           } text-center border hover:bg-blue-100 transition-all `}
//           onDoubleClick={() => onRowDoubleClick(rowId)}
//           style={{
//             height: "24px", // Reduce row height
//             backgroundColor: row.original.highlight
//               ? "#FFE7B7"
//               : customRowColor,
//           }}
//         >
//           {checkboxPosition === "start" && showCheckbox && (
//             <td
//               className="text-center border-r "
//               style={{
//                 padding: "2px 4px", // Reduce padding
//                 verticalAlign: "middle",
//               }}
//             >
//               <Checkbox
//                 size="small"
//                 checked={isChecked}
//                 onChange={() => handleRowSelection(rowId, isMakeAndChecker)}
//                 sx={{ transform: "scale(0.6)" }}
//               />
//             </td>
//           )}

//           {row.cells.map((cell, cellIndex) => {
//             const isLastColumn = cellIndex === row.cells.length - 1;

//             return (
//               <td
//                 key={cell.column.id}
//                 {...cell.getCellProps()}
//                 className={`${
//                   !isLastRow ? "border-b" : ""
//                 } ${!isLastColumn ? "border-r" : ""} border-customTableBorder text-customGray whitespace-nowrap `}
//                 style={{
//                   padding: "2px 4px", // Reduce padding
//                   height: "16px", // Ensure consistent row height
//                   verticalAlign: "middle",
//                   textAlign: cell.column.alignment || "center",
//                 }}
//               >
//                 {cell.render("Cell")}
//               </td>
//             );
//           })}

//           {checkboxPosition === "end" && showCheckbox && (
//             <td
//               className="text-center border-r"
//               style={{
//                 padding: "2px 4px", // Reduce padding
//                 verticalAlign: "middle",
//               }}
//             >
//               <Checkbox
//                 size="small"
//                 checked={isChecked}
//                 onChange={() => handleRowSelection(rowId, isMakeAndChecker)}
//                 sx={{ transform: "scale(0.6)" }}
//               />
//             </td>
//           )}
//         </tr>
//       );
//     })
//   )}
// </tbody>
//       </table>
//       </StyledTable>
//     </TableWrapper>
//   );
// };

// export default ReTable;
