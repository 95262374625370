import RestService from "./RestServices";

const basePath = "/identifier";
const StatementServices = {
  getAllStatement: async (pageSize, page) => {
    return RestService.GetAllData(
      `${basePath}/get-all?limit=${pageSize}&offset=${(page - 1) * pageSize}`
    );
  },
  autoAssign: async (clientId) => {
    return RestService.GetAllData(`${basePath}/automate-assign?clientId=${clientId}`);
  },
  manualAssign: async (data) => {
    return RestService.UpdateData(`${basePath}/manual-assign`, data);
  },
  searchStatement: async (searchQuery, pageSize, page) => {
    return RestService.GetAllData(`${basePath}/get-search?searchQuery=${searchQuery}&limit=${pageSize}&offset=${(page - 1) * pageSize}`);
  },
};
export default StatementServices;
