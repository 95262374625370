import React, { useState, useEffect } from "react";
import FilterComponent from "../../Components/FilterComponent";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BamActions from "../../Components/BamActions";
import { IoAddCircleOutline, IoSettingsOutline } from "react-icons/io5";
import { GiSettingsKnobs } from "react-icons/gi";
import { MdEdit, MdOutlineFileDownload } from "react-icons/md";
import { BamButtonsConfigs } from "../../Components/BamButtonsConfig";
import ReTable from "../../../../../components/Table";
import axios from "axios";
import * as XLSX from "xlsx";
import Header from "../../../../../components/Header";
import CustomeUpLoader from "../../../../../components/CustomeLoader";
import BamServices from "../../../../../rest-services/BamServices";
import { IoMdRefresh } from "react-icons/io";
import UserService from "../../../../../rest-services/UserServices";

export default function ZBAtypes() {
  const navigate = useNavigate();
  const { iconId } = useParams();
  const [showFilter, setShowFilter] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showExtraIcons, setShowExtraIcons] = useState(false);
   const [rolesPermissions, setRolesPermissions] = useState([]);
  //Assingner
  const [assignee, setAssignee] = useState({
    clientId: "",
    id: "",
    assigner: "",
    userId: "",
  });
  useEffect(() => {
    fetchAssignee();
  }, []);

  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          clientId: userDetail.clientId || "",
          id: userDetail.id || "",
          assigner: userDetail.username || "",
          userId: userDetail.userId || "",
        };
        setAssignee(assigneeData);
      } else {
      }
    } catch (error) { }
  };

     
  useEffect(() => {
    if (assignee.userId) {
      console.log("Assigner ID has changed:", assignee.userId);
      fetchUserPermissionDetails();
    }
  }, [assignee.userId]);

const fetchUserPermissionDetails = async () => {
  try {
    const data = { userId: assignee.userId };
    console.log("Request Data:", data);

    const response = await UserService.saveContactDetails(
      `${process.env.REACT_APP_BASE_URL}/get-users-permissions-by-userId`,
      data
    );
    console.log("user permissions details", response);
    const responseData = response.data;
    setRolesPermissions(responseData.rolesPermissionsTables || []); // Save permission details
    console.log("Set Permission details for userscreen", responseData.rolesPermissionsTables);
  } catch (error) {
    console.error(error.message); // Set error message
  } finally {
    setLoading(false); // Reset loading state
  }
};


  useEffect(() => {
    fetchZBATypes();
  }, [assignee.clientId]);

  const fetchZBATypes = async () => {
    try {
      if (!assignee.clientId) {
        return;
      }
      const response = await BamServices.getAllZBATypes(assignee.clientId);

      setData(response.data.data);
      setOriginalData(response.data.data);
    } catch (error) {
      console.error("Error fetching ZBA types:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = () => {
    const dataToExport = data.map((item) => ({
      "ZBA ID": item.ZBA_ID,
      Description: item.ZBA_Description,
      "Created By": item.created_by,
      "Created Date": item.creationDate || "",
      "Created Time": item.creationTime || "",
      "Updated By": item.changed_by,
      "Updated Date": item.changed_date || "",
      "Updated Time": item.changedTime || "",
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "ZBA Types");

    XLSX.writeFile(workbook, "ZBAtypes_Data.xlsx");
  };

  const buttons = BamButtonsConfigs.AccountStatus.map((button) => ({
    ...button,
    onClick:
      button.icon === IoAddCircleOutline
        ? () =>
          navigate("/ZBA-types-add", {
            state: { mode: "add", idview: 0 },
          })
        : button.icon === GiSettingsKnobs
          ? () => setShowFilter((prev) => !prev)
          : button.icon === MdOutlineFileDownload
            ? handleDownload
            : button.icon === IoMdRefresh
              ? fetchZBATypes
              : null,
  }));

  // const extraButtons = [
  //   {
  //     icon: MdEdit,
  //     title: "Approve",
  //     disabled: selectedRows.length === 0,
  //     onClick: () => {
  //       navigate(`/ZBA-types-add`, {
  //         state: { mode: "edit", idview: selectedRows[0] },
  //       });
  //     },
  //   },
  // ];
  const handleFilter = (filters) => {
    const filteredData = originalData.filter((item) => {
      const createdDate = item.creationDate
        ? new Date(item.creationDate).toISOString().slice(0, 10)
        : null;
      const changedDate = item.changed_date
        ? new Date(item.changed_date).toISOString().slice(0, 10)
        : null;
      const createdDateMatch = filters.createdTime
        ? createdDate === filters.createdTime
        : true;
      const changedByMatch = filters.changedBy
        ? item.changedBy.toLowerCase().includes(filters.changedBy.toLowerCase())
        : true;
      const changedDateMatch = filters.changeDate
        ? changedDate === filters.changeDate
        : true;
      const changedTimeMatch = filters.changeTime
        ? item.changedTime === filters.changeTime
        : true;

      return (
        createdDateMatch &&
        changedByMatch &&
        changedDateMatch &&
        changedTimeMatch
      );
    });

    setData(filteredData);
  };

  const handleReset = () => {
    setData(originalData);
  };

  const columns = React.useMemo(
    () => [
      { Header: "ZBA ID", accessor: "ZBA_ID", width: 100, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>, },
      { Header: "ZBA Description", accessor: "ZBA_Description", width: 200, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>, },
      { Header: "Created By", accessor: "created_by", width: 100, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>, },
      {
        Header: "Created Date",
        accessor: "creationDate",
        width: 120,
        Cell: ({ value }) => {
          if (!value) return "";
          const date = new Date(value);
          const day = String(date.getDate()).padStart(2, "0"); // Adds leading zero if day < 10
          const month = String(date.getMonth() + 1).padStart(2, "0"); // Adds leading zero if month < 10
          const year = date.getFullYear();
          return <div style={{ textAlign: "left" }}> {`${day}-${month}-${year}`}</div>;
        },
      },
      {
        Header: "Created Time",
        accessor: "creationTime",
        width: 120,
        Cell: ({ value }) => {
          if (!value) return <div style={{ textAlign: "left" }}></div>;
          const timeParts = value.split(":");
          const date = new Date();
          date.setHours(
            timeParts[0],
            timeParts[1],
            timeParts[2].split(".")[0],
            timeParts[2].split(".")[1] || 0
          );

          // Format the time
          const formattedTime = date.toLocaleTimeString("en-GB", {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: true,
          });

          return <div style={{ textAlign: "left" }}>{formattedTime}</div>;
        },

      },

      { Header: "Updated By", accessor: "changed_by", width: 100, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>, },
      {
        Header: "Updated Date",
        accessor: "changed_date",
        width: 120,
        Cell: ({ value }) => {
          if (!value) return "";
          const date = new Date(value);
          const day = String(date.getDate()).padStart(2, "0"); // Adds leading zero if day < 10
          const month = String(date.getMonth() + 1).padStart(2, "0"); // Adds leading zero if month < 10
          const year = date.getFullYear();
          return <div style={{ textAlign: "left" }}> {`${day}-${month}-${year}`}</div>;
        },
      },
      {
        Header: "Updated Time",
        accessor: "changedTime",
        width: 120,
        Cell: ({ value }) => {
          if (!value) return <div style={{ textAlign: "left" }}></div>;
          const timeParts = value.split(":");
          const date = new Date();
          date.setHours(
            timeParts[0],
            timeParts[1],
            timeParts[2].split(".")[0],
            timeParts[2].split(".")[1] || 0
          );

          // Format the time
          const formattedTime = date.toLocaleTimeString("en-GB", {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: true,
          });

          return <div style={{ textAlign: "left" }}>{formattedTime}</div>;
        },

      },
    ],
    []
  );

  const handleSelectRow = (rowId) => {
    setSelectedRows((prevSelectedRows) => {
      const isSelected =
        Array.isArray(prevSelectedRows) && prevSelectedRows.includes(rowId);
      const nextSelectedRows = isSelected
        ? prevSelectedRows.filter((id) => id !== rowId)
        : [...(prevSelectedRows || []), rowId]; // Ensure prevSelectedRows is an array

      setShowExtraIcons(nextSelectedRows.length === 1); // Safely access length
      return nextSelectedRows; // Return the new state
    });
  };
  const handleSelectAll = () => {
    if (selectedRows.length === data.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(data.map((row) => row.id));
    }
  };
  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleRowDoubleClick = (id) => {
    const hasPermission = rolesPermissions.some(
      (permission) => permission.permissionsId === 3 && permission.iconId === parseInt(iconId)
    );

    console.log("Check user permissions with iconId", hasPermission);

    if (hasPermission) {
    navigate("/ZBA-types-add", {
      state: { mode: "view", idview: id },
    });
  } else {
    console.log(
      "No permission with permissionsId === 3 and matching iconId, navigation skipped."
    );
  }
  };

  if (loading)
    return (
      <div>
        <CustomeUpLoader />
      </div>
    );

  const existingButtons = BamButtonsConfigs.AccountStatus.map((button) => ({
    ...button,
    title:
      button.icon === IoAddCircleOutline
        ? "Add LegalEntity"
        : button.icon === GiSettingsKnobs
          ? "Filter"
          : button.icon === MdOutlineFileDownload
            ? "Download"
            //  : button.icon === MdOutlineFileUpload
            // ? "Upload"
            : button.icon === IoSettingsOutline
              ? "Settings"
              : button.icon === IoMdRefresh
                ? "Refresh"
                : button.title || "",
    onClick:
      button.icon === IoAddCircleOutline
        ? () => handleNavigate("/ZBA-types-add")
        : button.icon === MdOutlineFileDownload
          ? handleDownload
          // : button.icon === IoSettingsOutline
          // ? handleSettingsButtonClick
          : button.icon === IoMdRefresh
            ? fetchZBATypes
            : button.icon === GiSettingsKnobs
              ? () => setShowFilter((prev) => !prev)
              : button.onClick || (() => { }),
  }));

  const extraButtons = [
    {
      icon: MdEdit,
      title: "Edit",
      disabled: selectedRows.length === 0,
      onClick: () => {
        navigate(`/ZBA-types-add`, {
          state: { mode: "edit", idview: selectedRows[0] },
        });
      },
    },
  ];



  const actionButtons = existingButtons.concat(
    extraButtons.map((button) => ({
      ...button,
      disabled: selectedRows.length === 0,
      onClick: selectedRows.length > 0 ? button.onClick : null,
    }))
  );


  const handleBreadcrumbClick = (crumb) => {
    if (crumb.title === "BAM") {
      navigate("/bam");
    } else if (crumb.title === "Set Up") {
      navigate("/bam");
    } else if (crumb.title === "ZBA Types") {
      navigate("/3/BAM/23/ZBA-types");
    } else if (crumb.path) {
      navigate(crumb.path);
    }
  };

  return (
    <div>
      <div>
        <Header
          title={"BAM > Set Up > ZBA Types"}
          onBreadcrumbClick={handleBreadcrumbClick}
        />
      </div>
      <div>
        {/* <BamActions
          buttons={buttons}
          extraButtons={extraButtons}
          isExtraButtonsVisible={showExtraIcons}
        /> */}
        <BamActions
          icons={iconId}
          buttons={actionButtons}
          selectedCount={selectedRows.length}
          // extraButtons={extraButtons}
          isExtraButtonsVisible={showExtraIcons}
          selectIds={selectedRows}
        />
      </div>

      {showFilter && (
        <FilterComponent onFilter={handleFilter} onReset={handleReset} />
      )}

      <ReTable
        data={data}
        columns={columns}
        selectedRows={selectedRows}
        onSelectRow={handleSelectRow}
        onSelectAll={handleSelectAll}
        onRowDoubleClick={handleRowDoubleClick}
      />
    </div>
  );
}
