import React, { useMemo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CashFlowbuttonsConfigs } from "../../../CashFlow/Components/CashFlowbuttonsConfig";
import CashFlowActions from "../../../CashFlow/Components/CashFlowActions";
import StatementFormatServices from "../../../../../rest-services/StatementFormatServices";
import { IoAddCircleOutline, IoSettingsOutline } from "react-icons/io5";
import Header from "../../../../../components/Header";
import CustomeLoading from "../../../../../components/CustomeLoading";
import ReTable from "../../../../../components/Table";
import BankStatementActions from "../../props/ActionButtons";
import { IoMdCheckmark, IoMdRefresh } from "react-icons/io";
import SearchInput from "../../../../../components/SearchInput";
import Pagination from "../../../../../components/PageNation";
import { MdEdit, MdOutlineFileDownload } from "react-icons/md";
import { GiSettingsKnobs } from "react-icons/gi";
import { useToast } from "../../../../../components/toast/toast";

export default function StatementFormat() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showExtraIcons, setShowExtraIcons] = useState(false);

  const handleNavigate = (path) => {
    navigate(path);
  };

  const { toast } = useToast()


  const [assignee, setAssignee] = useState({
    clientId: "",
    id: "",
    assigner: "",
    userId: "",
  });


  useEffect(() => {
    fetchAssignee();
  }, [])

  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          clientId: userDetail.clientId || "",
          id: userDetail.id || "",
          assigner: userDetail.username || "",
          userId: userDetail.userId || "",
        };

        setAssignee(assigneeData);
      } else {
      }
    } catch (error) { }
  };

  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === "Setups") {
      navigate("/bank-statements");
    }
    else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };

  const buttons = CashFlowbuttonsConfigs.screen1.map((button) => {
    let onClick;
    let title;

    if (button.icon === IoAddCircleOutline) {
      onClick = () => handleNavigate("/add-statement-format/0");
      title = "Add Statement";
    } else if (button.icon === IoMdRefresh) {
      onClick = () => fetchdata();
      title = "View Statement";
    } else if (button.icon === GiSettingsKnobs) {
    }
    else if (button.icon === IoSettingsOutline) {
    }
    else if (button.icon === MdOutlineFileDownload) {
      onClick = () => handleExportData();
      title = "Download Data";
    } else {
      onClick = button.onClick || (() => { });
      title = button.title || "";
    }
    return {
      ...button,
      onClick,
      title,
    };
  });


  const handleExportData = async () => {
    try {
      setLoading(true);
      const response = await StatementFormatServices.exportData(assignee.clientId);
      if (response) {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "bank_account.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        setLoading(false);
        toast("success", "Downloaded Successfully");
      }
    } catch (error) {
      console.error("Error downloading data:", error);
      setLoading(false);
    }
  }

  const pageSize = 20;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    fetchdata();
  }, [assignee.clientId]);
  const fetchdata = async () => {

    if (!assignee.clientId) return;
    try {

      const response = await StatementFormatServices.getAllStatementFormat(assignee.clientId);
      setTableData(response.data.data);
    } catch (error) {
      console.error("Error fetching data", error);
    } finally {
      setLoading(false);
    }

  }

  const columns = useMemo(
    () => [
      {
        Header: "Statement Type",
        accessor: "statement_type",
      },
      {
        Header: "Statement Identifier",
        accessor: "statement_description",
      },
      {
        Header: "Statement Format",
        accessor: "statement_format",
        Cell: ({ value }) => {
          switch (value) {
            case "0":
              return "940 WHF";
            case "1":
              return "940 HF";
            case "2":
              return "Portal";
            default:
              return "940 HF";
          }
        },
      },
      {
        Header: "Format Description",
        accessor: "format_description",
      },
      {
        Header: "Country",
        accessor: "country",
      },
      {
        Header: "Created By",
        accessor: "created_by",
      },
      {
        Header: "Created Date",
        accessor: "creationDate",
        Cell: ({ value }) => {
          if (!value) return "";
          const date = new Date(value);
          const day = String(date.getDate()).padStart(2, "0"); // Adds leading zero if day < 10
          const month = String(date.getMonth() + 1).padStart(2, "0"); // Adds leading zero if month < 10
          const year = date.getFullYear();
          return `${day}-${month}-${year}`;
        },
      },
      {
        Header: "Created Time",
        accessor: "creationTime",
        Cell: ({ value }) => {
          if (!value) return "";
          const date = new Date(value);
          return date.toLocaleTimeString("en-GB", {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: true, // Ensures the time is in AM/PM format
          });
        },

      },
      {
        Header: "Updated By",
        accessor: "changed_by",
      },
      {
        Header: "Updated Date",
        accessor: "changedDate",
        Cell: ({ value }) => {
          if (!value) return "";
          const date = new Date(value);
          const day = String(date.getDate()).padStart(2, "0"); // Adds leading zero if day < 10
          const month = String(date.getMonth() + 1).padStart(2, "0"); // Adds leading zero if month < 10
          const year = date.getFullYear();
          return `${day}-${month}-${year}`;
        },
      },
      {
        Header: "Updated time",
        accessor: "changedTime",
        Cell: ({ value }) => {
          if (!value) return "";
          const date = new Date(value);
          return date.toLocaleTimeString("en-GB", {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: true, // Ensures the time is in AM/PM format
          });
        },
      },
    ],
    []
  );
  const onSelectRow = (rowId) => {
    setSelectedRows((prevSelectedRows) => {
      const isSelected = prevSelectedRows.includes(rowId);
      const nextSelectedRows = isSelected
        ? prevSelectedRows.filter((id) => id !== rowId)
        : [...prevSelectedRows, rowId];
      setShowExtraIcons(nextSelectedRows.length === 1);

      return nextSelectedRows;
    });
  };

  const onSelectAll = () => {
    if (selectedRows.length === tableData.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(tableData.map((row) => row.id));
    }
  };

  const extraButtons = [
    {
      icon: MdEdit,
      title: "Edit",
      disabled: selectedRows.length === 0,
      onClick: () => {
        navigate(`/add-statement-format/1/${selectedRows[0]}`, {
          state: { mode: "edit" },
        })
      },
    },
  ];



  const handleDoubleClick = (id) => {
    navigate(`/add-statement-format/2/${id}`, {
      state: { mode: "view" },
    });
  };









  if (loading) return <div><CustomeLoading /></div>;
  return (
    <div>
      <div>
        <Header title="Bank Statement > Setups > Statement format" onBreadcrumbClick={handleBreadcrumbClick} />
      </div>

      <div className="flex justify-between items-center -ml-1 pl-4">
        <div className="flex items-center">
          <SearchInput
            // searchQuery={searchQuery}
            // setSearchQuery={setSearchQuery}
            // onChange={(e) => fetchBankAccount(searchQuery)}
            placeholder="Search by Statement Type"
          />
          <BankStatementActions
            buttons={buttons}
            extraButtons={extraButtons}
            isExtraButtonsVisible={showExtraIcons}
          />
        </div>
        <div className="flex items-center mr-4">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            totalCount={totalCount}
          />
        </div>
      </div>
      <div >
        <ReTable
          data={tableData}
          columns={columns}
          onSelectRow={onSelectRow}
          selectedRows={selectedRows}
          onSelectAll={onSelectAll}
          onRowDoubleClick={handleDoubleClick}
        />
      </div>
    </div>
  );
}
