import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Header from "../../../../../components/Header";
import { useToast } from "../../../../../components/toast/toast";
import BamServices from "../../../../../rest-services/BamServices";
import Footer from "../../../../../components/footer";
import { use } from "react";

export default function ZBAtypesAdd() {
  const navigate = useNavigate();

  const [id, setId] = useState("");
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState({});
  const { toast } = useToast();
  const [assignee, setAssignee] = useState({ assigner: "" });
  const location = useLocation();
  const { mode, idview } = location.state || {};
  const isViewMode = mode === "view";
  const isEditMode = mode === "edit";
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  useEffect(() => {
    if (idview) {
      handleView();
    }
  }, [assignee.clientId]);

  const handleView = async () => {
    try {
      if (!assignee.clientId) {
        return;
      }
      const response = await BamServices.getZBAById(idview, assignee.clientId);
      if (response && response.data) {
        setId(response.data.data.ZBA_ID);
        setDescription(response.data.data.ZBA_Description);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAssignee();
  }, []);

  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          assigner: userDetail.username || "",
          userid: userDetail.userId || "",
          clientId: userDetail.clientId || "",
        };
        setAssignee(assigneeData);
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          api: "No user detail found in session storage.",
        }));
      }
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        api: `Error fetching user details: ${error.message}`,
      }));
    }
  };

  const handleEdit = async () => {
    try {
      const payload = {
        clientId: assignee.clientId,
        id: idview,
        ZBA_ID: id,
        ZBA_Description: description,
        changed_by: assignee.userid || "Unknown",
      };
      const response = await BamServices.updateZBA(payload);
      if (response && response.data) {
        toast("success", "Data Edited Successfully");
        navigate("/3/BAM/23/ZBA-types");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [zbaTypeExists, setZbaTypeExist] = useState(false)
  const handleSaveDetails = async (navigateAfterSave = false) => {
    setErrors({});

    if (!id || !description) {
      const newErrors = {};
      if (!id) newErrors.id = "ZBA ID is required.";
      if (!description) newErrors.description = "Description is required.";
      setErrors(newErrors);
      return;
    }

    if (zbaTypeExists) {
      toast("error", "ZBA type already exists.")
      return;
    }

    const payload = {
      clientId: assignee.clientId,
      ZBA_ID: id,
      ZBA_Description: description,
      created_by: assignee.userid || "Unknown",
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/create-zba-types`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast("success", "Added successfully");

      setId("");
      setDescription("");

      if (navigateAfterSave) {
        navigate("/3/BAM/23/ZBA-types", { replace: true });
      }
    } catch (error) {
      console.error("Error saving ZBA Type:", error);
      setErrors((prevErrors) => ({
        ...prevErrors,
        api: "Failed to create ZBA Type. Please try again later.",
      }));
    }
  };

  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === "BAM") {
      console.log("Navigating to /bam");
      navigate("/bam");
    } else if (crumb.title === "Set Up") {
      console.log("Navigating to /bam");
      navigate("/bam");
    } else if (crumb.title === "ZBA Types") {
      console.log("Navigating to /ZBA-types");
      navigate("/3/BAM/23/ZBA-types");
    } else if (crumb.title === "Add") {
      console.log("Navigating to /ZBA-types-add");
      navigate("/ZBA-types-add");
    } else if (crumb.path) {
      navigate(crumb.path);
    }
  };


  const checkifZBATypeExist = async () => {
    try {
      if (isEditMode || isViewMode) return;
      const response = await BamServices.checkZBATypeExist(id, assignee.clientId);
      if (response.status === 201) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          id: "ZBA type already exists.",
        }));
        toast("error", "ZBA type already exists.")
        setZbaTypeExist(true)
      } else {
        setZbaTypeExist(false)
      }
    } catch (error) {

    }
  }
  return (
    <div className="flex flex-col h-screen">
      <div>
        <Header
          title={`BAM > Set Up > ZBA Types > ${isViewMode ? "View" : isEditMode ? "Edit" : "Add"
            }`}
          onBreadcrumbClick={handleBreadcrumbClick}
          hasUnsavedChanges={unsavedChanges}
        />
      </div>

      <div className="flex-grow p-10">
        <label className="flex items-center mb-4 ">
          <span className="w-48 text-sm font-medium text-gray-500">
            ZBA ID <span className="text-red-500 ml-1">*</span>
          </span>
          <span className="ml-3">:</span>
          <input
            type="text"
            value={id}
            onChange={(e) => {
              setId(e.target.value);
              if (errors.id) {
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  id: "",
                }));
              }
              setUnsavedChanges(true);
            }}
            maxLength={30}
            required
            placeholder="ZBA ID"
            onBlur={() => { checkifZBATypeExist() }}
            readOnly={isViewMode || isEditMode}
            className={`border border-gray-300 p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-5
              ${errors.id ? "border-red-500 hover:border-red-500" : ""}`}
          />
          {/* {errors.id && (
            <p className="text-red-500 text-sm ml-4">{errors.id}</p>
          )} */}
        </label>

        <label className="flex items-center mb-4 py-3">
          <span className="w-48 text-sm font-medium text-gray-500">
            ZBA Description <span className="text-red-500 ml-1">*</span>
          </span>
          <span className="ml-3">:</span>
          <input
            type="text"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
              if (errors.description) {
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  description: "",
                }));
              }
              setUnsavedChanges(true);
            }}
            required
            maxLength={30}
            readOnly={isViewMode}

            placeholder="Description"
            className={`border border-gray-300 p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-5
            ${errors.description ? "border-red-500 hover:border-red-500" : ""}`}
          />
          {errors.description && (
            <p className="text-red-500 text-sm ml-4">{errors.description}</p>
          )}
          {errors.api && (
            <p className="text-red-500 text-sm ml-4">{errors.api}</p>
          )}
        </label>
      </div>

      <Footer>
        {!isViewMode && (
          <button
            onClick={() => {
              isEditMode ? handleEdit() : handleSaveDetails(true);
            }}
            className="bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16"
          >
            Save
          </button>
        )}
        {!isViewMode && !isEditMode && (
          <button
            onClick={() => handleSaveDetails(false)}
            className="ml-4 border border-blue-500 text-sm text-blue-500 py-1 px-2 rounded hover:bg-blue-100 transition duration-200 ease-in-out w-30"
          >
            Save & Continue
          </button>
        )}
      </Footer>
    </div>
  );
}
