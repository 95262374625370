import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Header from "../../../../../components/Header";
import { useToast } from "../../../../../components/toast/toast";
import BamServices from "../../../../../rest-services/BamServices";
import Footer from "../../../../../components/footer";
import { set } from "lodash";

export default function AccountTypesAdd() {
  const navigate = useNavigate();

  const [accountType, setAccountType] = useState("");
  const [description, setDescription] = useState("");
  const { toast } = useToast();
  const [notes, setNotes] = useState("");
  const [errors, setErrors] = useState({});
  const [assignee, setAssignee] = useState({
    clientId: "",
    assigner: "",
    userid: "",
  });
  const location = useLocation();
  const { mode, idview } = location.state || {};
  const isViewMode = mode === "view";
  const isEditMode = mode === "edit";

  const [unsavedChanges, setUnsavedChanges] = useState(false);

  useEffect(() => {
    const fetchAssignee = async () => {
      try {
        const userDetailString = localStorage.getItem("userDetail");
        if (userDetailString) {
          const userDetail = JSON.parse(userDetailString);
          const assigneeData = {
            accountType: userDetail.accountType || "",
            assigner: userDetail.username || "",
            userid: userDetail.userId || "",
            clientId: userDetail.clientId || "",
          };
          setAssignee(assigneeData);
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            api: "No user detail found in session storage.",
          }));
        }
      } catch (error) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          api: `Error fetching user details: ${error.message}`,
        }));
      }
    };

    fetchAssignee();
  }, []);

  useEffect(() => {
    if (idview) {
      handleView();
    }
  }, [isEditMode, assignee.clientId]);

  const handleEdit = async () => {
    try {
      const payload = {
        clientId: assignee.clientId,
        id: idview,
        Account_Type: accountType,
        Account_Description: description,
        notes: notes,
        changed_by: assignee.userid || "Unknown",
      };
      const response = await BamServices.updateAccountType(payload);
      if (response.status === 200) {
        toast("success", "Data Edited Successfully");
        navigate("/3/BAM/25/account-types");
        setUnsavedChanges(false);
      } else {
        toast("error", "Failed to edit data. Please try again.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [accountExists, setAccountExists] = useState(false);

  const checkAcountTypeExist = async () => {
    try {
      if (isEditMode || isViewMode) return;
      const response = await BamServices.checkAccountTypeExist(accountType, assignee.clientId);
      if (response.status === 201) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          account: "Account type already exists.",
        }));
        toast("error", "Account Type already exists")
        setAccountExists(true);

      } else {
        setAccountExists(false)
      }
    } catch (error) {

    }
  }

  const handleView = async () => {
    try {
      if (!assignee.clientId) {
        return;
      }
      const response = await BamServices.getAccountTypesById(
        idview,
        assignee.clientId
      );
      if (response && response.data) {
        setAccountType(response.data.data.Account_Type);
        setDescription(response.data.data.Account_Description);
        setNotes(response.data.data.notes);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const validateForm = () => {
    const newErrors = {};
    if (!accountType) newErrors.account = "Account type is required.";
    if (!description) newErrors.description = "Description is required.";
    if (!notes) newErrors.notes = "Notes is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const handleSaveDetails = async (navigateAfterSave = false) => {
    setErrors({});

    if (!validateForm()) {
      return;
    }
    if (accountExists) {
      toast("error", "Account Type already exists")
      return;
    }

    const payload = {
      clientId: assignee.clientId,
      Account_Type: accountType,
      Account_Description: description,
      notes: notes,
      created_by: assignee.userid || "Unknown",
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/create-account-types`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response) {
        toast("success", "Added successfully");
      }
      setAccountType("");
      setDescription("");
      setNotes("");
      setUnsavedChanges(false);

      if (navigateAfterSave) {
        navigate("/3/BAM/25/account-types", { replace: true });
      }
    } catch (error) {
      console.error("Error saving Account Types:", error);
      setErrors((prevErrors) => ({
        ...prevErrors,
        api: "Failed to create Account Types. Please try again later.",
      }));
    }
  };

  const handleBreadcrumbClick = (crumb) => {
    if (crumb.title === "BAM") {
      navigate("/bam", { replace: true });
    } else if (crumb.title === "Set Up") {
      navigate("/bam", { replace: true });
    } else if (crumb.title === "Account Types") {
      navigate("/3/BAM/25/account-types", { replace: true });
    } else if (crumb.title === "Add") {
      navigate("/account-types-add", { replace: true });
    } else if (crumb.path) {
      navigate(crumb.path, { replace: true });
    }
  };

  return (
    <div className="flex flex-col h-screen">
      <div>
        <Header
          title={`BAM > Set Up > Account Types > ${isViewMode ? "View" : isEditMode ? "Edit" : "Add"
            }`}
          onBreadcrumbClick={handleBreadcrumbClick}
          hasUnsavedChanges={unsavedChanges}
        />
      </div>

      <div className="flex-grow p-10">
        <label className="flex items-center mb-4">
          <span className="w-48 text-sm font-medium text-gray-500">
            Account Type <span className="text-red-500 ml-1">*</span>
          </span>
          <span className="ml-3">:</span>
          <input
            type="text"
            value={accountType}
            onChange={(e) => {
              setAccountType(e.target.value);
              setUnsavedChanges(true);
              if (errors.account) {
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  account: "",
                }));
              }
            }}
            onBlur={() => checkAcountTypeExist()}
            maxLength={30}
            required
            readOnly={isViewMode || isEditMode}
            placeholder="Account Type"
            className={`border border-gray-300 p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-5
                ${errors.account ? "border-red-500 hover:border-red-500" : ""}
            `}
          />
          {/* {errors.account && (
            <p className="text-red-500 text-sm ml-4">{errors.account}</p>
          )} */}
        </label>

        <label className="flex items-center mb-4 py-3">
          <span className="w-48 text-sm font-medium text-gray-500">
            Account Description <span className="text-red-500 ml-1">*</span>
          </span>
          <span className="ml-3">:</span>
          <input
            type="text"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
              setUnsavedChanges(true);
              if (errors.description) {
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  description: "",
                }));
              }
            }}
            maxLength={30}
            required
            placeholder="Description"
            readOnly={isViewMode}
            className={`border border-gray-300 p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-5
            ${errors.description ? "border-red-500 hover:border-red-500" : ""}`}
          />
          {errors.description && (
            <p className="text-red-500 text-sm ml-4">{errors.description}</p>
          )}
        </label>

        <label className="flex items-start mb-4">
          <span className="w-48 text-sm font-medium text-gray-500">
            Notes <span className="text-red-500 ml-1">*</span>
          </span>
          <span className="ml-3">:</span>
          <textarea
            value={notes}
            max
            onChange={(e) => {
              setNotes(e.target.value);
              setUnsavedChanges(true);
              if (errors.notes) {
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  notes: "",
                }));
              }
            }}
            required
            placeholder="Notes"
            rows={1}
            maxLength={150}
            readOnly={isViewMode}
            className={`border border-gray-300 p-1.5 rounded w-72 focus:outline-none hover:border-blue-400 ml-5 resize-none overflow-hidden
            ${errors.notes ? "border-red-500 hover:border-red-500" : ""}`}
            style={{ minHeight: "2rem", height: "auto" }}
            onInput={(e) => {
              e.target.style.height = "auto";
              e.target.style.height = `${e.target.scrollHeight}px`;
            }}
          />

          {errors.notes && (
            <p className="text-red-500 text-sm ml-4">{errors.notes}</p>
          )}
        </label>
      </div>

      <Footer>
        {!isViewMode && (
          <button
            onClick={() => {
              isEditMode ? handleEdit() : handleSaveDetails(true);
            }}
            className="bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16"
          >
            Save
          </button>
        )}
        {!isViewMode && !isEditMode && (
          <button
            onClick={() => handleSaveDetails(false)}
            className="ml-4 border border-blue-500 text-sm text-blue-500 py-1 px-2 rounded hover:bg-blue-100 transition duration-200 ease-in-out w-30"
          >
            Save & Continue
          </button>
        )}
      </Footer>
    </div>
  );
}
