
import Header from "../../../../../components/Header";
import SearchInput from "../../../../../components/SearchInput";
import BankStatementActions from "../../props/ActionButtons";
import { bankStatementActionsConfig } from "../../props/ActionButtonConfig";
import { IoMdRefresh } from "react-icons/io";
import { MdEdit, MdOutlineFileDownload } from "react-icons/md";
import { IoAddCircleOutline } from "react-icons/io5";
import { useEffect, useState } from "react";
import ReTable from "../../../../../components/Table";
import { useNavigate } from "react-router-dom";
import Footer from "../../../../../components/footer";
import CustomButton from "../../../../../components/CustomButton";
import { useToast } from "../../../../../components/toast/toast";
import BankStatementServices from "../../../../../rest-services/BankStatementServices";
import Pagination from "../../../../../components/PageNation";
import { format } from "date-fns";

export default function ManageStatementControlls() {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedId, setSelectedId] = useState(null);
    const [pageSize, setPageSize] = useState(15);
    const [assignee, setAssignee] = useState({
        clientId: "",
        id: "",
        assigner: "",
        userId: "",
    });

    const navigate = useNavigate()

    const { toast } = useToast();
    useEffect(() => {
        fetchAssignee();
    }, [])

    useEffect(() => {
        fetchDataForManagedStatmentControls()
    }, [assignee.clientId])

    //FUnction For Fetch Assignee
    const fetchAssignee = async () => {
        try {
            const userDetailString = localStorage.getItem("userDetail");
            if (userDetailString) {
                const userDetail = JSON.parse(userDetailString);
                const assigneeData = {
                    clientId: userDetail.clientId || "",
                    id: userDetail.id || "",
                    assigner: userDetail.username || "",
                    userId: userDetail.userId || "",
                };
                setAssignee(assigneeData);
            } else {
            }
        } catch (error) { console.log(error) }
    };
    const fetchDataForManagedStatmentControls = async () => {
        try {
            if (!assignee.clientId) {
                return;
            }
            const response = await BankStatementServices.getManagedStatementControls(pageSize, currentPage, assignee.clientId);

            if (response.status === 200) {
                const totalCount = Number(response.data.total) || 0;
                const pages = Math.ceil(totalCount / (pageSize || 1));
                setData(response.data.data);
                setTotalCount(response.data.total);
                setTotalPages(pages);
            }
        } catch (error) {
            console.log(error)
        }
    }

    const buttons = bankStatementActionsConfig.screen4.map((button) => {
        let onClick;
        let title;
        if (button.icon === MdEdit) {
            onClick = () => { handleNavigateEdit() };
            title = "Edit";
        }
        else if (button.icon === IoMdRefresh) {
            onClick = () => { fetchDataForManagedStatmentControls() };
            title = "Refresh";
        } else if (button.icon === MdOutlineFileDownload) {
            onClick = () => { };
            title = "Download";
        }
        return {
            ...button,
            onClick,
            title,
        };
    });
    const handleNavigateEdit = () => {
        if (selectedRows.length === 0) {
            toast("error", "Select Atleast One Row");
            return
        }
        navigate(`/edit-manage-statement-controlls/${selectedRows[0]}`, { state: { mode: "edit" } })
    }


    const columns = [
        {
            Header: "Statement Type",
            accessor: "statementType",
            visible: true,
            width: 200,
            Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
        },
        {
            Header: "Statement Identifier",
            accessor: "statementIdentifier",
            visible: true,
            width: 200,
            Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
        },
        {
            Header: "BAnk Account ID",
            accessor: "bankAccountId",
            visible: true,
            width: 200,
        },
        { Header: "Account No", accessor: "bankAccountNo", visible: true, width: 200, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>, },
        {
            Header: "IBAN",
            accessor: "iban",
            visible: true,
            width: 200,
            Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
        },
        {
            Header: " Company Code",
            accessor: "companyCode",
            visible: true,
            width: 200,
            Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
        },

        {
            Header: "Bank ID",
            accessor: "bank",
            visible: true,
            width: 200,
            Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
        },
        {
            Header: "Bank Branch",
            accessor: "bankBranch",
            visible: true,
            width: 200,
            Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
        },
        {
            Header: "Currency",
            accessor: "currency",
            visible: true,
            width: 200,
            Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
        },
        {
            Header: "Last Statement Date",
            accessor: "lastStatementDate",
            visible: true,
            width: 200,
            Cell: ({ value }) => {
                const formattedDate = value ? format(new Date(value), "dd-MM-yyyy") : "-";
                return <div style={{ textAlign: "left" }}>{formattedDate}</div>;
            },
        },
        {
            Header: "Balance",
            accessor: "balance",
            visible: true,
            width: 200,
            Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
        },
        {
            Header: "Last Statementn No",
            accessor: "lastStatementNo",
            visible: true,
            width: 200,
            Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
        },

        { Header: "Created By", accessor: "createdBy", visible: true, width: 200, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>, },
        {
            Header: "Created Date",
            accessor: "creationDate",
            visible: true,
            width: 15,
            Cell: ({ value }) => {
                if (!value) return;
                const date = new Date(value);
                const day = String(date.getDate()).padStart(2, "0")
                const month = String(date.getMonth() + 1).padStart(2, "0")
                const year = String(date.getFullYear())

                return <div style={{ textAlign: "left" }}>{`${day}-${month}-${year}`}</div>
            }
        },
        {
            Header: "Created Time",
            accessor: "creationTime",
            visible: true,
            width: 15,
            Cell: ({ value }) => {
                if (!value) return ""; // Handle null or undefined values
                const date = new Date(value);
                const formattedTime = date.toLocaleTimeString("en-GB", {
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                    hour12: true, // Ensures 12-hour format with AM/PM
                });
                return <div style={{ textAlign: "left" }}>{formattedTime}</div>;
            },
        },
        {
            Header: "Updated By",
            accessor: "changedBy",
            visible: true,
            width: 15,
            canResize: true,
            Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
        },
        {
            Header: "Updated Date",
            accessor: "changedDate",
            visible: true,
            width: 15,
            Cell: ({ value }) => {
                if (!value) return;
                const date = new Date(value);
                const day = String(date.getDate()).padStart(2, "0")
                const month = String(date.getMonth() + 1).padStart(2, "0")
                const year = String(date.getFullYear())

                return <div style={{ textAlign: "left" }}>{`${day}-${month}-${year}`}</div>
            }
        },
        {
            Header: "Updated Time",
            accessor: "changedTime",
            visible: true,
            width: 15,
            Cell: ({ value }) => {
                if (!value) return ""; // Handle null or undefined values
                const date = new Date(value);
                const formattedTime = date.toLocaleTimeString("en-GB", {
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                    hour12: true, // Ensures 12-hour format with AM/PM
                });
                return <div style={{ textAlign: "left" }}>{formattedTime}</div>;
            },
        },
    ];
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    const handleSelectRow = (rowId) => {

        setSelectedRows((prevSelectedRows) => {
            const isSelected = prevSelectedRows.includes(rowId);
            const nextSelectedRows = isSelected
                ? prevSelectedRows.filter((id) => id !== rowId)
                : [...prevSelectedRows, rowId];

            return nextSelectedRows;
        });
    };

    const onSelectAll = () => {
        if (selectedRows.length === data.length) {
            setSelectedRows([]);
            setSelectedId(null);

        } else {
            setSelectedRows(data.map((row) => row.id));
        }
    };
    const handleDoubleClick = (id) => {
        navigate(`/edit-manage-statement-controlls/${id}`, {
            state: { mode: "view" },
        });
    };



    return (
        <div>
            <Header title="BankStatement > MT940 > Manage Statement Controlls" />

            <div className="flex justify-between items-center -ml-1 pl-4">
                <div className="flex items-center">
                    <SearchInput
                        // searchQuery={searchQuery}
                        // setSearchQuery={setSearchQuery}
                        // onChange={(e) => fetchBankAccount(searchQuery)}
                        placeholder="Search by Type"
                    />

                    <BankStatementActions buttons={buttons} />
                </div>
                <div className="flex items-center mr-4">
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                        totalCount={totalCount}
                    />
                </div>

            </div>
            <div>
                <ReTable
                    data={data}
                    columns={columns}
                    selectedRows={selectedRows}
                    onSelectRow={handleSelectRow}
                    onSelectAll={onSelectAll}
                    onRowDoubleClick={handleDoubleClick}
                />
            </div>
            <div>
                <Footer>
                    <CustomButton
                        title="Save"
                    />
                </Footer>
            </div>

        </div>
    )
}