import React, { useState, useEffect } from "react";
import NoActionLayout from "../../../../../components/NoActionLayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import StateProvinceServices from "../../../../../rest-services/StateProvinceServices";
import { useToast } from "../../../../../components/toast/toast";
import UserService from "../../../../../rest-services/UserServices";
import { RxDropdownMenu } from "react-icons/rx";
import Header from "../../../../../components/Header";
import Footer from "../../../../../components/footer";
import ButtonGroup from "../../../../../components/FooterButton";
import ReusableModal from "../../../../../components/PopupComponent";
export default function StateProvinceAdd() {
  const { statementId } = useParams();
  const location = useLocation();
  const { mode } = location.state || {}; // Get the mode from the state
  const isViewMode = mode === 'view';
  const [state, setState] = useState(""); // State
  const [stateName, setStateName] = useState(""); // State Name
  const [countryCode, setCountryCode] = useState(""); // Country Code
  const [countryName, setCountryName] = useState(""); // Country Name
  const [errors, setErrors] = useState({}); // Validation errors
  const [assignee, setAssignee] = useState({ id: "", assigner: "" }); // State to store assignee
  const [alert, setAlert] = useState(null);
  const [countries, setCountries] = useState([]);
  const [countryId, setCountryId] = useState('');
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const isEditMode = !!statementId;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(countryCode || "");
  const { toast } = useToast();
  const navigate = useNavigate();
  useEffect(() => {
    fetchcountry();
  }, []);
  useEffect(() => {
    if (countries.length > 0 && statementId) {
      getDataForEdit();
    }
    fetchAssignee();

  }, [countries, statementId]);

  const getDataForEdit = async () => {
    try {
      const response = await StateProvinceServices.getStateProvince(statementId);
      setState(response.data.state);
      setStateName(response.data.State_Name);
      setCountryCode(response.data.Country_Code);

      // Set the country name based on the country code from the response
      const country = countries.find(c => c.country === response.data.Country_Code);

      if (country) {
        setCountryName(country.countryName);
        setSelectedCountry(country.country); // Set the selected country based on countryName
      } else {
        console.error("Country not found for the provided country code.");
      }
    } catch (error) {
      console.error("Error fetching state/province data:", error);
    }
  };


  const validateForm = () => {
    const newErrors = {};
    if (!state) newErrors.state = "State is required.";
    if (!stateName) newErrors.stateName = "State Name is required.";
    if (!countryCode) newErrors.countryCode = "Country Code is required.";

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      toast("error", "Missing Required Fields");
    }
    return Object.keys(newErrors).length === 0;
  };

  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.userId || "",
        };
        setAssignee(assigneeData);
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      // Skip duplication check for edit mode
      if (!statementId) {
        const duplicateCheckResponse = await StateProvinceServices.checkDuplicateStatecode(
          `${process.env.REACT_APP_BASE_URL}/getduplicatestate`,
          { state: state }
        );

        if (duplicateCheckResponse.message === "state code already exists") {
          toast("error", "State already exists.");
          setErrors((prev) => ({
            ...prev,
            state: "state already exists.",
          }));
          return; // Stop further execution if duplicate exists
        }
      }
      const data = {
        id: statementId,
        State: state,
        State_Name: stateName,
        Country_Code: countryCode,
        created_by: assignee.assigner,
        changed_by: assignee.assigner,
      };


      let response;
      if (statementId) {
        // Updating the existing country
        response = await StateProvinceServices.addStateProvince(
          `${process.env.REACT_APP_BASE_URL}/create-stateprovince`, // Ensure this endpoint is for updates
          data
        );
      } else {
        // Creating a new country
        response = await StateProvinceServices.addStateProvince(
          `${process.env.REACT_APP_BASE_URL}/create-stateprovince`,
          data
        );
      }

      if (response && !response.error) {
        toast(
          "success",
          statementId
            ? "State Updated successfully"
            : "State Added successfully"
        );
        // Clear the form and navigate
        setState("");
        setStateName("");
        setCountryCode("");
        setCountryName("");
        navigate("/2/master-data/2/state-province"); // Redirect after saving
      } else {
        toast("error", "Error adding/updating state");
      }
    } catch (error) {
      console.error("Request error:", error);
      setAlert({
        severity: "error",
        message: `Error adding/updating state: ${error.message}`,
      });
    }
  };

  const handleSaveAndContinue = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      // Skip duplication check for edit mode
      if (!statementId) {
        const duplicateCheckResponse = await StateProvinceServices.checkDuplicateStatecode(
          `${process.env.REACT_APP_BASE_URL}/getduplicatestate`,
          { state: state }
        );

        if (duplicateCheckResponse.message === "state code already exists") {
          toast("error", "State already exists.");
          setErrors((prev) => ({
            ...prev,
            state: "state already exists.",
          }));
          return; // Stop further execution if duplicate exists
        }
      }
      const data = {
        id: statementId,
        State: state,
        State_Name: stateName,
        Country_Code: countryCode,
        created_by: assignee.assigner,
        changed_by: assignee.assigner,
      };


      let response;
      if (statementId) {
        // Updating the existing country
        response = await StateProvinceServices.addStateProvince(
          `${process.env.REACT_APP_BASE_URL}/create-stateprovince`, // Ensure this endpoint is for updates
          data
        );
      } else {
        // Creating a new country
        response = await StateProvinceServices.addStateProvince(
          `${process.env.REACT_APP_BASE_URL}/create-stateprovince`,
          data
        );
      }

      if (response && !response.error) {
        toast(
          "success",
          statementId
            ? "State Updated successfully"
            : "State Added successfully"
        );
        // Clear the form and navigate
        setState("");
        setStateName("");
        setCountryCode("");
        setSelectedCountry("");
        setCountryName("");
        // Redirect after saving
      } else {
        toast("error", "Error adding/updating state");
      }
    } catch (error) {
      console.error("Request error:", error);
      setAlert({
        severity: "error",
        message: `Error adding/updating state: ${error.message}`,
      });
    }
  };
  const fetchcountry = async () => {
    try {
      const response = await UserService.getUsers(`${process.env.REACT_APP_BASE_URL}/get-country`);
      console.log("Fetched countries:", response.data.data); // Add this log
      setCountries(response.data.data); // Ensure this is correct
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };
  const checkDuplicateStatecode = async (code) => {
    try {
      const response = await StateProvinceServices.checkDuplicateStatecode(
        `/getduplicatestate`,
        { state: code }  // Sending roleName to backend
      );

      console.log("Response from server:", response);

      if (response.message === "state code already exists") {
        // Check if it's a duplicate
        console.log("Response from internal server:", response);
        setErrors({ state: " " });

        toast("error", "State already exists.");
      }
      console.log("Response from external server:", response);
    } catch (error) {
      console.error("Error checking duplicate state:", error);
      // Handle potential errors when making the API call
      setErrors((prev) => ({ ...prev, state: "Error checking role name" }));
    }
  };

  const handleStateChange = (e) => {
    const code = e.target.value.toUpperCase(); // Ensure input is uppercase
    setUnsavedChanges(true);
    // Limit input to 3 letters
    if (errors.state) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        state: "",
      }));
      return; // Stop further processing
    }

    // Clear errors if any
    setErrors((prevErrors) => ({
      ...prevErrors,
      state: "",
    }));

    // Update the calendar code
    setState(code);

    // Check for duplicate Calendar Code locally
    if (code.trim() !== "") {
      checkDuplicateStatecode(code);
    }
  };

  const handleCountryCodeChange = (e) => {
    if (errors.countryCode) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        countryCode: "",
      }))
    }
    const selectedCode = e.target.value;
    console.log("Selected country code:", selectedCode); // Log the selected country code
    setCountryCode(selectedCode);

    const selectedCountry = countries.find((country) => country.country === selectedCode);
    if (selectedCountry) {
      setCountryId(selectedCountry.id); // Store country ID if needed
      setCountryCode(selectedCountry.country);
      setCountryName(selectedCountry.countryName); // Set the corresponding country name
    } else {
      setCountryName(""); // Clear the country name if no match is found
    }
  };


  // const handleCheckboxChange = (event) => {
  //   const selectedCode = event.target.value;
  //   setSelectedCountry(selectedCode);
  //   setCountryCode(selectedCode);

  //   const selectedCountryData = countries.find(country => country.country === selectedCode);
  //   if (selectedCountryData) {
  //     setCountryName(selectedCountryData.countryName);
  //   }
  // };
  const handleCheckboxChange = (event) => {
    const selectedName = event.target.value; // Change from value to country name
    setSelectedCountry(selectedName);
    setUnsavedChanges(true);

    // Find the selected country data by country name instead of code
    const selectedCountryData = countries.find(country => country.country === selectedName);
    if (selectedCountryData) {
      setCountryCode(selectedCountryData.country); // Set the country code based on the selected country name
      setCountryName(selectedCountryData.countryName);
    }
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === 'Global Data') {

      navigate('/2/master-data');
    } else if (crumb.title === 'State/Province') {

      navigate('/2/master-data/2/state-province');
    }
    else if (crumb.title === 'Primary Data') {
      console.log("Navigating to /country");
      navigate('/2/master-data');

    }
    else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };
  const handleReset = () => {
    setSearchQuery(''); // Reset search query
    setSelectedCountry(""); // Reset selected bank ID
    setCountryCode("");
    setCountryName("");
  };

  const handleRefreshcountry = async () => {
    try {
      await fetchcountry();  // Fetch Branchs
    } catch (error) {
      console.error('Error reloading Branchs:', error);
    } finally {
    }
  };
  return (
    <div>
      <Header
        title={`Primary Data > Global Data > State/Province > ${statementId ? (isViewMode ? 'View' : 'Edit') : 'Add'}`
        }
        onBreadcrumbClick={handleBreadcrumbClick}
        hasUnsavedChanges={unsavedChanges}
        backRoute={"/2/master-data/2/state-province"}
      />
      <div className="m-6 border border-gray-200 p-5  rounded-lg">

        <div className="flex flex-col space-y-12 col-span-1 p-6">
          <label className="flex items-center">
            <span className="w-48 font-small text-sm text-customGray">State<span className="text-red-500 ml-1">*</span></span>
            <span className="ml-4">:</span>
            <input
              type="text"
              value={state}
              maxLength={3}

              onChange={handleStateChange}
              className={`border ${errors.state ? "border-red-500" : "border-gray-300"} p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 font-small text-sm text-customGray`}
              required
              disabled={isViewMode || isEditMode}
            />
          </label>

          <label className="flex items-center">
            <span className="w-48 font-small text-sm text-customGray">State Name<span className="text-red-500 ml-1">*</span></span>
            <span className="ml-4">:</span>
            <input
              type="text"
              value={stateName}
              maxLength={30}

              onChange={(e) => {
                setStateName(e.target.value);
                setUnsavedChanges(true);
                if (errors.stateName) {
                  setErrors(prevErrors => ({ ...prevErrors, stateName: "" }));
                }
              }}
              className={`border ${errors.stateName ? "border-red-500" : "border-gray-300"} p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 font-small text-sm text-customGray`}
              required
              disabled={isViewMode}
            />
          </label>

          <div className="flex items-center">
            <label className="w-48 font-small text-sm text-customGray" disabled={isViewMode}>Country Code <span className="text-red-500 ml-1">*</span></label>
            <span className="ml-4">:</span>
            <button
              onClick={toggleModal}
              disabled={isViewMode}
              className={`border border-gray-300 disabled:bg-[#FAFAFA] p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-left flex items-center justify-between ${errors.countryCode ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
            >
              <span className="text-customGray text-sm font-small">{selectedCountry || 'Select Country Code'}</span>
              <RxDropdownMenu className="ml-2" />
            </button>

            <ReusableModal
              title="Select Country Code"
              isOpen={isModalOpen}
              onClose={() => {
                setIsModalOpen(false);
                setSearchQuery('');
              }}
              onSave={() => {
                setIsModalOpen(false);
                setSearchQuery('');
              }}
              searchPlaceholder="Search country..."
              searchQuery={searchQuery}
              onSearchChange={handleSearchChange}
              isViewMode={isViewMode}
              showReset={true}
              onRefresh={handleRefreshcountry}
              onReset={handleReset}
            >
              {countries.length > 0 ? (
                countries
                  .filter((country) =>
                    country.country.toLowerCase().includes(searchQuery.toLowerCase())

                  )
                  .sort((a, b) => a.country.localeCompare(b.country)) // Sort in alphabetical order

                  .map((country) => (
                    <div key={country.id} className="flex items-center">
                      <input
                        type="radio"
                        name="country"
                        value={country.country}
                        checked={selectedCountry === country.country}
                        onChange={handleCheckboxChange}
                        
                        className="mr-2 accent-customBlue text-customGray text-sm font-small"
                        disabled={isViewMode}
                      />
                      <label>{country.country}</label>
                    </div>
                  ))
              ) : (
                <p>Loading countries...</p>
              )}
            </ReusableModal>


          </div>

          <div className="flex items-center">
            <label className="w-48 font-small text-sm text-customGray">Country Name</label>
            <span className="ml-4">:</span>
            <input
              type="text"
              value={countryName}
              readOnly
              disabled={isViewMode}
              className="border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none ml-8 text-customGray text-sm font-small"
            />
          </div>


        </div>

      </div>
      <Footer>
        {/* Footer */}
        {!isViewMode && (
          <ButtonGroup
            onSave={handleSubmit}
            onSaveContinue={handleSaveAndContinue}
            hasId={!!statementId}
          />
        )}
      </Footer>
    </div>
  );
}
