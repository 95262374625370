import React, { useState, useEffect } from "react";
import NoActionLayout from "../../../../../components/NoActionLayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CalendarServices from "../../../../../rest-services/CalendarServices";
import { useToast } from "../../../../../components/toast/toast";
import Header from "../../../../../components/Header";
import CustomButton from "../../../../../components/CustomButton";
import BuGroupServices from "../../../../../rest-services/BuGroupServices";
import { TbFlag3Filled } from "react-icons/tb";
import Footer from "../../../../../components/footer";
import ButtonGroup from "../../../../../components/FooterButton";

export default function BuGroupAdd() {
  const { buGroupId } = useParams();
  const location = useLocation();
  const { mode } = location.state || {}; // Get the mode from the state
  const isViewMode = mode === 'view';
  const [buGroup, setBuGroup] = useState("");
  const [bugroupDescription, setBugroupDescription] = useState("");
  const [errors, setErrors] = useState({});
  const [assignee, setAssignee] = useState({ id: "", assigner: "",clientId:"" });
  const [alert, setAlert] = useState(null);
  const { toast } = useToast();
  const [status, setStatus] = useState("");
  const [isCopyMode, setIsCopyMode] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const isEditMode = !!buGroupId;

  const navigate = useNavigate();
  useEffect(() => {
    if (location.state?.copiedData) {
      setIsCopyMode(true);
      const { BU_Group, BU_Group_Description } = location.state.copiedData;
      setBuGroup(BU_Group);
      setBugroupDescription(BU_Group_Description);
    } else if (buGroupId) {
      getDataForEdit();
    }
    fetchAssignee();
  }, [buGroupId, location.state]);

  const getDataForEdit = async () => {
    try {
      const clientId = localStorage.getItem("clientId");
  
      if (!clientId) {
        console.error("Client ID is missing from local storage");
        return; // Exit the function if clientId is missing
      }
  
      if (!buGroupId) {
        console.error("BU Group ID is missing from local storage or state");
        return; // Exit the function if buGroupId is missing
      }
  
      const response = await BuGroupServices.getBuGroupById(clientId, buGroupId);
      const data = response.data.data;
  
      setBuGroup(data.BU_Group);
      setBugroupDescription(data.BU_Group_Description);
      setStatus(data.status);
    } catch (error) {
      console.error("Error fetching BUGroup for edit:", error);
    }
  };
  

  const validateForm = () => {
    const newErrors = {};
    if (!buGroup) newErrors.buGroup = "BuGroup  is required.";
    if (!bugroupDescription) newErrors.bugroupDescription = "BuGroup Description is required.";

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      toast("error", "Missing Required Fields");
    }
    return Object.keys(newErrors).length === 0;
  };

  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.userId || "",
          clientId: userDetail.clientId || "",
        };
        setAssignee(assigneeData);
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the form
    if (!validateForm()) return;

    // Check for duplicate BU Group code
    try {
      // Skip duplication check for edit mode
      if (!buGroupId || isCopyMode) {
        const duplicateCheckResponse = await BuGroupServices.checkDuplicateBugroupcode(
          `${process.env.REACT_APP_BASE_URL}/getduplicatebugroup`,
          { BU_Group: buGroup }
        );

        if (duplicateCheckResponse.message === "BU group code already exists") {
          toast("error", "BuGroup Code already exists.");
          setErrors((prev) => ({
            ...prev,
            buGroup: "BuGroup Code already exists.",
          }));
          return; // Stop further execution if duplicate exists
        }
      }

      // Prepare the data for submission
      const data = {
        id: isCopyMode ? null : buGroupId,
        clientId: assignee.clientId,
        BU_Group: buGroup,
        BU_Group_Description: bugroupDescription,
        created_by: assignee.assigner,
        changed_by: assignee.assigner,
      };

      console.log("BuGroup data", data);


      let response;
      if (isCopyMode || !buGroupId) {
        // Create a new BU Group
        response = await BuGroupServices.addBuGroup(
          `${process.env.REACT_APP_BASE_URL}/create-bugroup`,
          data
        );
      } else {
        // Update an existing BU Group
        response = await BuGroupServices.addBuGroup(
          `${process.env.REACT_APP_BASE_URL}/update-bugroup`,
          data
        );
      }

      if (response && !response.error) {
        toast(
          "success",
          buGroupId && !isCopyMode
            ? "Bu Group Updated successfully"
            : "Bu Group Added successfully"
        );

        // Clear the form and navigate
        setBuGroup("");
        setBugroupDescription("");


        navigate("/2/master-data/8/bugroup"); // Redirect after saving, only if Save and Continue is false

      } else {
        toast("error", "Error adding/updating Bu Group.");
      }
    } catch (error) {
      console.error("Request error:", error);
      setAlert({
        severity: "error",
        message: `Error adding/updating Bu Group: ${error.message}`,
      });
    }
  };
  const handleSaveandContinue = async (e) => {
    e.preventDefault();

    // Validate the form
    if (!validateForm()) return;

    // Check for duplicate BU Group code
    try {
      // Skip duplication check for edit mode
      if (!buGroupId || isCopyMode) {
        const duplicateCheckResponse = await BuGroupServices.checkDuplicateBugroupcode(
          `${process.env.REACT_APP_BASE_URL}/getduplicatebugroup`,
          { BU_Group: buGroup }
        );

        if (duplicateCheckResponse.message === "BU group code already exists") {
          toast("error", "BuGroup Code already exists.");
          setErrors((prev) => ({
            ...prev,
            buGroup: "BuGroup Code already exists.",
          }));
          return; // Stop further execution if duplicate exists
        }
      }

      // Prepare the data for submission
      const data = {
        id: isCopyMode ? null : buGroupId,
        clientId: assignee.clientId,
        BU_Group: buGroup,
        BU_Group_Description: bugroupDescription,
        created_by: assignee.assigner,
        changed_by: assignee.assigner,
      };

      console.log("BuGroup data", data);


      let response;
      if (isCopyMode || !buGroupId) {
        // Create a new BU Group
        response = await BuGroupServices.addBuGroup(
          `${process.env.REACT_APP_BASE_URL}/create-bugroup`,
          data
        );
      } else {
        // Update an existing BU Group
        response = await BuGroupServices.addBuGroup(
          `${process.env.REACT_APP_BASE_URL}/update-bugroup`,
          data
        );
      }

      if (response && !response.error) {
        toast(
          "success",
          buGroupId && !isCopyMode
            ? "Bu Group Updated successfully"
            : "Bu Group Added successfully"
        );

        // Clear the form and navigate
        setBuGroup("");
        setBugroupDescription("");


        // Redirect after saving, only if Save and Continue is false

      } else {
        toast("error", "Error adding/updating Bu Group.");
      }
    } catch (error) {
      console.error("Request error:", error);
      setAlert({
        severity: "error",
        message: `Error adding/updating Bu Group: ${error.message}`,
      });
    }
  };

  const checkDuplicateBugroupcode = async (code) => {
    try {
      const clientId = assignee.clientId;
      const response = await BuGroupServices.checkDuplicateBugroupcode(
        `/getduplicatebugroup`,
        { BU_Group: code , clientId}
      );
      console.log("Response from server:", response);

      if (response.message === "BU group code already exists") {
        // Check if it's a duplicate
        console.log("Response from internal server:", response);
        setErrors({ buGroup: " " });

        toast("error", "BuGroup Code already exists.");
      }
      console.log("Response from external server:", response);
    } catch (error) {
      console.error("Error checking duplicate buGroup:", error);
      // Handle potential errors when making the API call
      setErrors((prev) => ({ ...prev, buGroup: "Error checking role name" }));
    }
  };

  const handleBugroupCodeChange = (e) => {
    const code = e.target.value;
    setBuGroup(code);
    setUnsavedChanges(true);
    // Clear previous errors when typing
    setErrors(prev => ({ ...prev, buGroup: "" }));

    // Check for duplicate LGE Code locally
    if (code.trim() !== "") {
      checkDuplicateBugroupcode(code);
    }
  };
  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === 'Company & Bank Data') {
      navigate('/2/master-data');
    } else if (crumb.title === 'Bu Group') {
      navigate('/2/master-data/8/bugroup');
    }
    else if (crumb.title === 'Primary Data') {
      navigate('/2/master-data');

    } else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };
  const getApprovedIcon = () => {
    let color = "black"; // Default color
    // Determine the color based on the approved status
    if (status === 4) {
      color = "#29CB00"; // Green
    } else if (status === 0) {
      color = "gray"; // Gray
    } else if (status === 1) {
      color = "#AF52DE"; 
    } else if (status === 2) {
      color = "yellow"; 
    } else if (status === 3) {
      color = "orange"; 
    }
    else if (status === 5) {
      color = "red"; // Red
    }

    return <TbFlag3Filled style={{ color, marginLeft: '8px' }} />; // Return the icon with the determined color
  };
  return (
    <div>
<Header
  title={`Primary Data > Company & Bank Data > Bu Group > ${
    isCopyMode 
      ? 'Add' 
      : buGroupId 
        ? (isViewMode ? "View" : "Edit") 
        : "Add"
  }${buGroupId && (isViewMode || !isCopyMode) ? ` > ${buGroup}` : ''}`}
  onBreadcrumbClick={handleBreadcrumbClick} 
  extraContent={buGroupId && !isCopyMode ? getApprovedIcon() : null}
    hasUnsavedChanges={unsavedChanges}
    backRoute={"/2/master-data/8/bugroup"}
  />
          <div className="m-6 border border-gray-200 p-5  rounded-lg">

        <div className="flex flex-col space-y-12 col-span-1 p-6">
          <label className="flex items-center">
            <span className="w-48 font-small text-customGray text-sm">
              Bu Group<span className="text-red-500 ml-1">*</span>
            </span>
            <span className="ml-4">:</span>
            <input
              type="text"
              value={buGroup}
              maxLength={10}

              onChange={handleBugroupCodeChange}

              className={`border font-small text-customGray text-sm ${errors.buGroup ? "border-red-500" : "border-gray-300"
                } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
              required
              disabled={((isEditMode && !isCopyMode) || isViewMode)}
            />
          </label>


          <label className="flex items-center">
            <span className="w-48 font-small text-customGray text-sm">
              BuGroup Description<span className="text-red-500 ml-1">*</span>
            </span>
            <span className="ml-4">:</span>
            <input
              type="text"
              value={bugroupDescription}
              maxLength={40}

              onChange={(e) => {
                setBugroupDescription(e.target.value);
                setUnsavedChanges(true);
                if (errors.bugroupDescription) {
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    bugroupDescription: "",
                  }))
                }
              }}
              className={`border font-small text-customGray text-sm ${errors.bugroupDescription ? "border-red-500" : "border-gray-300"
                } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
              required
              disabled={isViewMode}
            />
          </label>


        </div>

      </div>
      <Footer>
        {(!isViewMode && (<ButtonGroup onSave={handleSubmit} onSaveContinue={handleSaveandContinue} hasId={!!buGroupId} />))}
      </Footer>
    </div>
  );
}
