import React, { useContext, useEffect, useState } from "react";
import logo from "../../assets/image.png";
import backImage from "../../assets/Loginpage.jpg";
import { AuthContext } from "../../context/AuthContext";
import { useToast } from "../../components/toast/toast";
import UserService from "../../rest-services/UserServices";
import CustomButton from "../../components/CustomButton";
import HoverButton from "../../components/HoverButton";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import AdminService from "../../rest-services/AdminServices";
import PasswordField from "../../components/Textfield";

function LoginComponent() {
  const [clientId, setClientId] = useState("");
  const [userId, setUserId] = useState("");

  const [password, setPassword] = useState("");
  const { state: ContextState, login, loginOtp } = useContext(AuthContext);
  const [forgotPasswordStep, setForgotPasswordStep] = useState(0);
  const { toast } = useToast();
  const [passwordPolicy, setPasswordPolicy] = useState(null);

  const [selectedQuestionId, setSelectedQuestionId] = useState("");
  const [answer, setAnswer] = useState("");
  const [securityQuestions, setSecurityQuestions] = useState([]);
  const [incorrectQuestions, setIncorrectQuestions] = useState([]);
  const [otp, setOtp] = useState("");
  const [userOtp, setUserOtp] = useState("");
  const [isPasswordPolicyOpen, setIsPasswordPolicyOpen] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isFirtTimeLogin, setIsFirtTimeLogin] = useState(true);
  //check for Otp Login Enabled
  const [isotpLogin, setOtpLogin] = useState(false);
  //check for user is blocked
  const [isblocked, setIsblocked] = useState("");
  //Remember Me
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined" && window.localStorage) {
      const storedClientId = localStorage.getItem("clientId");
      const storedUserId = localStorage.getItem("userId");
      const storePassword = localStorage.getItem("password");

      const storedRememberMe = localStorage.getItem("rememberMe") === "true";

      if (storedClientId && storedUserId) {
        setClientId(storedClientId);
        setUserId(storedUserId);
        setPassword(storePassword);
      }

      setRememberMe(storedRememberMe);
    }
  }, []);

  const handleSubmit = async () => {
    if (!userId.trim() || !password.trim()) {
      if (!clientId.trim()) {
        toast("error", "Client Id is required");
      }
      if (!userId.trim()) {
        toast("error", "UserId is required");
      }
      if (!password.trim()) {
        toast("error", "Password is required");
      }
      return;
    }
    try {
      const response = await login(userId, password, clientId);

      if (response.message === "Login successful") {
        const userDetailString = localStorage.getItem("userDetail");
        if (userDetailString) {
          const userDetail = JSON.parse(userDetailString);
          const assigneeData = {
            firstName: userDetail.firstName || "",
            oneTimePassword: userDetail.oneTimePassword || "",
          };
          if (assigneeData.oneTimePassword === "2") {
            toast("success", `Welcome Back ${assigneeData.firstName}`);
          } else {
            toast("success", `Welcome ${assigneeData.firstName}`);
          }

          // Store login time
          localStorage.setItem("loginTime", Date.now());

          if (rememberMe) {
            localStorage.setItem("clientId", clientId);
            localStorage.setItem("userId", userId);
            localStorage.setItem("password", password); // Or an auth token instead of password
            console.log("Data saved to localStorage:", {
              clientId,
              userId,
              password,
            });
          }
        }
      }
    } catch (err) {
      const errorMessage =
        err.response?.data?.message || "An unknown error occurred.";
      if (
        err.response?.status === 400 &&
        errorMessage === "Enter Correct password"
      ) {
        toast("error", errorMessage);
      } else if (
        err.response?.status === 404 &&
        errorMessage === "Unknown User"
      ) {
        toast("error", "User Does Not Exist");
      } else {
        toast("error", errorMessage);
      }
    }
  };

  const handleOtpSubmit = async () => {
    if (!userId.trim() || !userOtp.trim()) {
      if (!userId.trim()) {
        toast("error", "UserId is required");
      }
      if (!password.trim()) {
        toast("error", "OTP is required");
      }
      if (!password.trim()) {
        toast("error", "Password is required");
      }
      return;
    }
    try {
      const response = await loginOtp(userId, userOtp, clientId);

      if (response.message === "Login successful") {
        toast("success", "Successfully Logged in");
      }
    } catch (err) {
      const errorMessage =
        err.response?.data?.message || "An unknown error occurred.";
      if (
        err.response?.status === 400 &&
        errorMessage === "Enter Correct password"
      ) {
        toast("error", errorMessage);
      } else if (
        err.response?.status === 404 &&
        errorMessage === "Unknown User"
      ) {
        toast("error", "User Does Not Exist");
      } else {
        toast("error", errorMessage);
      }
    }
  };

  useEffect(() => {
    if (forgotPasswordStep === 3) {
      handleGetPolicy();
    }
  }, [forgotPasswordStep]);

  const onOpenPasswordPolicy = () => {
    setIsPasswordPolicyOpen(true);
  };

  const onClosePasswordPolicy = () => {
    setIsPasswordPolicyOpen(false);
  };

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await UserService.getSecurityQuestions(userId);

        if (response.data.message === "User Is Blocked Contact Admin") {
          toast(
            "error",
            `${response.data.message} \n Can't reset the password`
          );
          return;
        }
        if (Array.isArray(response.data)) {
          setSecurityQuestions(response.data);
        } else {
          console.error(
            "Expected an array in 'data', received:",
            response.data
          );
        }
      } catch (error) {
        console.error("Failed to fetch security questions:", error);
      }
    };
    fetchQuestions();
  }, [forgotPasswordStep]);

  const availableQuestions = securityQuestions.filter(
    (q) => !incorrectQuestions.includes(q.question.question)
  );

  const handleForgotPasswordClick = () => {
    if (isblocked === "1") {
      toast("error", "User Is Blokced Contact Admin");
      return;
    }
    if (userId.length >= 2) {
      setForgotPasswordStep(1);
    } else {
      toast("error", "UserID is required");
    }
  };

  const handleTryAnotherMethodClick = () => {
    setForgotPasswordStep((prevStep) => (prevStep === 1 ? 2 : 1));
  };
  const handleTryAnotherLoginMethodClick = () => {
    setForgotPasswordStep((prevStep) => (prevStep === 0 ? 4 : 0));
    setUserId("");
    setIsFirtTimeLogin(true);
  };

  const handleBackClick = () => {
    setForgotPasswordStep(0);
  };

  const handleQuestionVerify = async () => {
    const selectedQuestion = securityQuestions.find(
      (q) => q.question.question === selectedQuestionId
    );
    if (!selectedQuestion) {
      toast("error", "Please Select Question");
      return;
    }
    if (availableQuestions.length - 1 === 0) {
      const blockUser = await UserService.blockUser(userId);
      if (blockUser === 200) {
        toast("error", "User Blocked Contact admin");
      }
    }
    if (answer.toLowerCase() === selectedQuestion.answer.toLowerCase()) {
      setForgotPasswordStep(3);
      setAnswer("");
      toast("success", "Question Verified Succefully");
    } else {
      setIncorrectQuestions((prev) => [...prev, selectedQuestionId]);
      toast(
        "error",
        `Answer is Incorrect you have only ${availableQuestions.length - 1
        } Question left`
      );
    }
  };

  const [passwordMismatch, setPasswordMismatch] = useState(false);

  const [userResetOtp, setuserResetOtp] = useState("");

  const handleSendOtp = async () => {
    if (!userId) {
      toast("error", "UserID is required!");
      return;
    }

    try {
      const response = await UserService.generatOtp(userId);
      if (response.status === 200) {
        setOtp(response.data.data);
        toast("success", "OTP sent successfully!");
      }
    } catch (error) {
      toast("error", "Failed to send OTP. Please try again.");
    }
  };

  const handleGetPolicy = async () => {
    try {
      const response = await AdminService.getPasswordPolicy();
      setPasswordPolicy(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleRememberMeChange = (event) => {
    const checked = event.target.checked;
    setRememberMe(checked);

    if (typeof window !== "undefined" && window.localStorage) {
      localStorage.setItem("rememberMe", checked); // Store the checkbox state in localStorage
    }
  };

  const handleOtpverify = async () => {
    try {
      if (userResetOtp === otp) {
        setForgotPasswordStep(3);
        toast("success", "OTP verified Successfully");
      } else {
        toast("error", "Invalid OTP");
      }
    } catch (error) { }
  };

  const handlePasswordChange = async () => {
    try {
      if (newPassword !== confirmPassword) {
        setPasswordMismatch(true);
      } else {
        setPasswordMismatch(false);

        const response = await UserService.updatePassword(
          newPassword,
          confirmPassword,
          userId
        );
        if (response.status === 200) {
          setForgotPasswordStep(0);
          toast("success", `${response.message}`);
        } else {
          toast("error", `${response.message}`);
        }
      }
    } catch (error) {
      toast("error", `${error.message}`);
    }
  };

  useEffect(() => { }, [isFirtTimeLogin]);

  const handleCheckIsFirstLogin = async () => {
    try {
      if (userId.length === 0) {
        setIsFirtTimeLogin(true);
        setOtpLogin(false);
        return;
      }
      const response = await UserService.checkLogin(userId);
      setIsFirtTimeLogin(response.data.firstimeLogin);
      setOtpLogin(response.data.is_otp_enabled);
      setIsblocked(response.data.is_blocked);
    } catch (error) {
      console.error(error);
    }
  };

  const styles = {
    container: {
      display: "flex",
      height: "100vh",
      overflow: "hidden",
      position: "fixed",
      width: "100%",
    },
    leftPane: {
      width: "85%",
      background: "linear-gradient(110deg, #0A5295 70%, #ffff 5%)",
      position: "relative",
    },
    bgImage: {
      position: "absolute",
      top: "50%",
      left: "42%",
      transform: "translate(-50%, -50%)",
      width: "70%",
      height: "80%",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      // border: "2px solid #052748", // Change the color and width as needed
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Adjust the offset, blur, and color as needed
    },
  };

  return (
    <div style={styles.container}>
      {/* <div style={styles.leftPane}>
  <img
    src={backImage}
    alt="Background"
    style={styles.bgImage}
  />
  
</div> */}

      <div className="flex justify-center  items-center overflow-hidden w-[50%] relative ">
        <div
          className="absolute inset-0"
          style={{
            background: "linear-gradient(  120deg, #0B5299 55%, #ffff 5%)", // Gradient background
            zIndex: 0, // Ensure it's behind other content
          }}
        ></div>
        <img
          src={backImage}
          alt="Background"
          className="w-7/10 h-4/5 bg-no-repeat bg-cover shadow-md object-contain absolute  z-10 " // z-10 to ensure the image is above the gradient
        />
      </div>

      <div className="flex flex-col justify-center items-center w-[50%] ">
        <div className="text-center ">
          <img
            src={logo}
            alt="NRG Phoenix Technology Logo"
            className="w-[25rem]"
          />
        </div>
        <div className="flex flex-col justify-start items-left flex-grow  mr-20 mb-40">
          {" "}
          {forgotPasswordStep === 0 && (
            <>
              <div className=" ">
                <h1 className="text-lg text-customBlue font-medium mb-8">
                  Account Login
                </h1>
                <div className="mb-4">
                  <label
                    htmlFor="userId"
                    className="block mb-2 text-customGray"
                  >
                    Client ID
                  </label>
                  <input
                    type="text"
                    id="userId"
                    value={clientId}
                    placeholder="Enter your client Id"
                    className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:border-blue-500 mb-4"
                    onChange={(e) => setClientId(e.target.value)}
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="userId"
                    className="block mb-2 text-customGray"
                  >
                    UserID
                  </label>
                  <input
                    type="text"
                    id="userId"
                    value={userId}
                    placeholder="Enter your userId"
                    className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:border-blue-500 mb-4"
                    onChange={(e) => setUserId(e.target.value)}
                    onBlur={handleCheckIsFirstLogin}
                  />
                </div>

                <div className="mb-4">
                  <PasswordField
                    label={"Password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder={"Enter your password"}
                    className={
                      "w-full border border-gray-300 p-2 rounded focus:outline-none focus:border-blue-500 mb-4"
                    }
                  />
                </div>

                <div className="flex justify-between items-center mb-4 gap-4">
                  {!isFirtTimeLogin && (
                    <a
                      href="#"
                      className="text-customBlue text-sm cursor-pointer whitespace-nowrap"
                      onClick={handleForgotPasswordClick}
                    >
                      Forgot password?
                    </a>
                  )}
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      checked={rememberMe}
                      onChange={handleRememberMeChange}
                      id="rememberMe"
                    />
                    <label className="text-sm text-customGray whitespace-nowrap">
                      Remember Me
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input type="checkbox" className="mr-2" />
                    <label className="text-sm text-customGray whitespace-nowrap">
                      Single Sign On
                    </label>
                  </div>
                </div>

                <CustomButton
                  onClick={handleSubmit}
                  title={"Login"}
                  className={"w-32 h-8 ml-32"}
                />
                {isotpLogin === true && (
                  <>
                    <div className="mt-4 w-full flex justify-center  font-small text-sm ">
                      <button
                        onClick={handleTryAnotherLoginMethodClick}
                        className="text-customBlue underline mb-4 mr-16"
                      >
                        Try another method
                      </button>
                    </div>
                  </>
                )}
                <div className="mt-20 text-xs text-center text-customGray whitespace-nowrap">
                  <p>Copyrights @ TMS . All rights reserved</p>
                </div>
              </div>
            </>
          )}
          {forgotPasswordStep === 4 && (
            <>
              <div className=" ">
                <h1 className="text-lg text-customBlue font-medium mb-8">
                  Account Login
                </h1>
                <div className="mb-4">
                  <label
                    htmlFor="userId"
                    className="block mb-2 text-customGray"
                  >
                    Client ID
                  </label>
                  <input
                    type="text"
                    id="userId"
                    placeholder="Enter your client Id"
                    className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:border-blue-500 mb-4"
                    onChange={(e) => setClientId(e.target.value)}
                  />
                </div>
                <div>
                  <label
                    htmlFor="userId"
                    className="block mb-2 text-customGray"
                  >
                    UserID
                  </label>
                  <input
                    type="text"
                    id="userId"
                    placeholder="Enter your userId"
                    className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:border-blue-500"
                    onChange={(e) => setUserId(e.target.value)}
                    onBlur={handleCheckIsFirstLogin}
                  />
                </div>
                <div className="flex justify-end">
                  <label
                    className="text-right underline text-customBlue cursor-pointer"
                    onClick={handleSendOtp}
                  >
                    Send OTP
                  </label>
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="password"
                    className="block mb-2 text-customGray"
                  >
                    OTP
                  </label>
                  <input
                    type="password"
                    id="password"
                    placeholder="Enter your OTP"
                    className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:border-blue-500 mb-4"
                    onChange={(e) => setUserOtp(e.target.value)}
                  />
                </div>

                <div className="flex justify-between items-center mb-4 gap-4">
                  {!isFirtTimeLogin && (
                    <a
                      href="#"
                      className="text-customBlue text-sm cursor-pointer whitespace-nowrap"
                      onClick={handleForgotPasswordClick}
                    >
                      Forgot password?
                    </a>
                  )}
                  <div className="flex items-center">
                    <input type="checkbox" className="mr-2" />
                    <label className="text-sm text-customGray whitespace-nowrap">
                      Remember Me
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input type="checkbox" className="mr-2" />
                    <label className="text-sm text-customGray whitespace-nowrap">
                      Single Sign On
                    </label>
                  </div>
                </div>

                <CustomButton
                  onClick={handleOtpSubmit}
                  title={"Login"}
                  className={"w-32 h-8 ml-32"}
                />
                <div className="mt-4 w-full flex justify-center  font-small text-sm ">
                  <button
                    onClick={handleTryAnotherLoginMethodClick}
                    className="text-customBlue underline mb-4 mr-16"
                  >
                    Try another method
                  </button>
                </div>
                <div className="mt-20 text-xs text-center text-customGray whitespace-nowrap">
                  <p>Copyrights @ TMS . All rights reserved</p>
                </div>
              </div>
            </>
          )}
          {forgotPasswordStep === 1 && (
            <>
              <div className=" ">
                <h4 className="mb-4 text-customBlue text-md font-md">
                  Security Questions
                </h4>
                <p className="mb-6 text-sm text-gray-500 whitespace-nowrap">
                  Select any one question from {availableQuestions.length}{" "}
                  available questions user id {userId}
                </p>
                <div className="mb-4 mt-4">
                  <label className="flex items-center">
                    <select
                      value={selectedQuestionId}
                      onChange={(e) => setSelectedQuestionId(e.target.value)}
                      className="border border-customGray p-2 pl-4 rounded w-60 h-10 mr-4"
                    >
                      <option value="">Select a question</option>
                      {availableQuestions.map((q) => (
                        <option
                          key={q.question.question}
                          value={q.question.question}
                        >
                          {q.question.question}
                        </option>
                      ))}
                    </select>

                    <input
                      type="text"
                      value={answer}
                      onChange={(e) => setAnswer(e.target.value)}
                      placeholder="Answer"
                      className="border border-gray-300 p-1.5 rounded w-60 h-10 focus:outline-none hover:border-blue-400 ml-5 peer"
                    />
                  </label>
                </div>

                <div className="w-full flex justify-end  font-small text-sm ">
                  <button
                    onClick={handleTryAnotherMethodClick}
                    className="text-customBlue underline mb-4 mr-16"
                  >
                    Try another method
                  </button>
                </div>
                <div className="flex  mb-4 gap-4">
                  <CustomButton
                    title={"Verify"}
                    onClick={handleQuestionVerify}
                    className={"h-8  w-32 "}
                  />
                  <HoverButton
                    title={"Back"}
                    onClick={handleBackClick}
                    className={"h-8  w-32 "}
                  />
                </div>
              </div>
            </>
          )}
          {forgotPasswordStep === 2 && (
            <>
              <div className="  ">
                <h4 className="mb-4 text-customBlue text-md font-md">
                  OTP Verification
                </h4>
                <div className="flex items-center p-1">
                  <p className="mb-6 text-sm text-gray-500 whitespace-nowrap mr-4">
                    OTP will be shared on your registered email id & user id{" "}
                    {userId}
                  </p>
                  <button
                    onClick={handleSendOtp}
                    className="text-customBlue mb-6 text-sm  whitespace-nowrap "
                  >
                    Send OTP
                  </button>
                </div>
                <div className="flex space-x-2 ">
                  <input
                    type="text"
                    value={userResetOtp}
                    onChange={(e) => setuserResetOtp(e.target.value)}
                    placeholder="Enter OTP"
                    className="border border-gray-300 p-1.5 rounded w-full h-10 focus:outline-none hover:border-customBlue  peer"
                  />
                </div>

                <div className=" flex justify-end  font-small text-sm ">
                  <button
                    onClick={handleTryAnotherMethodClick}
                    className="text-customBlue underline mb-4 mt-4 "
                  >
                    Try another method
                  </button>
                </div>
                <div className="flex  mb-4 gap-4">
                  <CustomButton
                    title={"Verify"}
                    className={"h-8  w-32 "}
                    onClick={handleOtpverify}
                  />
                  <HoverButton
                    title={"Back"}
                    onClick={handleBackClick}
                    className={"h-8  w-32 "}
                  />
                </div>
              </div>
            </>
          )}
          {forgotPasswordStep === 3 && (
            <>
              <div className="">
                <div className="flex items-center mb-4">
                  <div>
                    <button
                      className="absolute top-5 right-16 text-gray-600 hover:text-gray-800"
                      onClick={onOpenPasswordPolicy}
                    >
                      <BsFillQuestionCircleFill />
                    </button>
                    {isPasswordPolicyOpen && (
                      <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex items-center justify-center z-50">
                        <div className="bg-white p-6 rounded-lg shadow-lg w-1/2 max-w-lg">
                          <h3 className="text-lg font-semibold mb-4">
                            Password Policy
                          </h3>
                          <p>
                            - Minimum length:{" "}
                            {passwordPolicy.password_length_min} <br />- Maximum
                            length: {passwordPolicy.password_length_max} <br />-
                            Requires capital letters:{" "}
                            {passwordPolicy.capital_letters} <br />- Requires
                            numerical characters:{" "}
                            {passwordPolicy.numerical_characters} <br />-
                            Requires special characters:{" "}
                            {passwordPolicy.special_characters} <br />
                          </p>
                          <button
                            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg"
                            onClick={onClosePasswordPolicy}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  <button
                    className="absolute top-5 right-16 text-gray-600 hover:text-gray-800"
                    onClick={onOpenPasswordPolicy}
                  >
                    <BsFillQuestionCircleFill />
                  </button>

                  <h3 className="mr-2 whitespace-nowrap text-customGray">
                    Enter New Password<span className="ml-12">:</span>
                  </h3>

                  <PasswordField
                    placeholder=""
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="border rounded-lg p-2 w-full"
                  />
                </div>
                <div className="flex items-center mb-4">
                  <h3 className="mr-2 whitespace-nowrap text-customGray">
                    Confirm New Password<span className="ml-6">:</span>
                  </h3>
                  <PasswordField
                    placeholder=""
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="border rounded-lg p-2 w-full"
                  />
                </div>

                {newPassword &&
                  confirmPassword &&
                  newPassword !== confirmPassword && (
                    <p className="text-red-500">Passwords do not match!</p>
                  )}

                <div className="flex justify-end font-small text-sm mb-4"></div>
                <div className="flex gap-8 mb-4">
                  <CustomButton
                    title={"Change Password"}
                    className={"h-8 w-40 "}
                    onClick={handlePasswordChange}
                  />
                  <HoverButton
                    title={"Back"}
                    onClick={handleBackClick}
                    className={"h-8 w-32 "}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default LoginComponent;
