import { Pagination } from "@mui/material";
import Header from "../../../../../components/Header";
import SearchInput from "../../../../../components/SearchInput";
import BankStatementActions from "../../props/ActionButtons";
import { bankStatementActionsConfig } from "../../props/ActionButtonConfig";
import { IoMdRefresh } from "react-icons/io";
import { MdOutlineFileDownload } from "react-icons/md";
import { IoAddCircleOutline } from "react-icons/io5";
import { useState } from "react";
import ReTable from "../../../../../components/Table";
import { useNavigate } from "react-router-dom";

export default function TransferToBs() {

    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [selectedRows, setSelectedRows] = useState([]);
    const pageSize = 20;
    const navigate = useNavigate()

    const buttons = bankStatementActionsConfig.screen1.map((button) => {
        let onClick;
        let title;
        if (button.icon === IoAddCircleOutline) {
            onClick = () => { };
            title = "Add";
        }
        else if (button.icon === IoMdRefresh) {
            onClick = () => { };
            title = "Refresh";
        } else if (button.icon === MdOutlineFileDownload) {
            onClick = () => { };
            title = "Download";
        }
        return {
            ...button,
            onClick,
            title,
        };
    });


    const columns = [
        {
            Header: "Statement Type",
            accessor: "statement_type",
            visible: true,
            width: 200,
        },
        {
            Header: "Statement Identifier",
            accessor: "statement_identifier",
            visible: true,
            width: 200,
        },
        {
            Header: "Corporate BIC",
            accessor: "corporate_bic",
            visible: true,
            width: 200,
        },
        { Header: "Bank BIC", accessor: "bank_bic", visible: true, width: 200 },
        {
            Header: "Active",
            accessor: "status",
            visible: true,
            width: 200,
            Cell: ({ value }) => (
                <input type="checkbox" checked={value === 1} readOnly />
            ),
        },

        {
            Header: "Bank account Id",
            accessor: "bank_account_id",
            visible: true,
            width: 200,
        },
        {
            Header: " Company Code",
            accessor: "statement_no1",
            visible: true,
            width: 200,
        },
        {
            Header: "Bank",
            accessor: "statement_no2",
            visible: true,
            width: 200,
        },
        {
            Header: "Bank Branch",
            accessor: "statement_o3",
            visible: true,
            width: 200,
        },
        {
            Header: "Bank Account No",
            accessor: "statement_no4",
            visible: true,
            width: 200,
        },
        {
            Header: "IBAN",
            accessor: "statement_no5",
            visible: true,
            width: 200,
        },
        {
            Header: "Bank Statement Updated",
            accessor: "statement_no6",
            visible: true,
            width: 200,
        },
        {
            Header: "Bank Branch",
            accessor: "statement_no7",
            visible: true,
            width: 200,
        },
        { Header: "Created By", accessor: "created_by", visible: true, width: 200 },
        {
            Header: "Created Date",
            accessor: "creationDate",
            visible: true,
            width: 15,
            canResize: true,
        },
        {
            Header: "Created Time",
            accessor: "creationTime",
            visible: true,
            width: 15,
            canResize: true,
        },
        {
            Header: "Updated By",
            accessor: "changed_by",
            visible: true,
            width: 15,
            canResize: true,
        },
        {
            Header: "Updated Date",
            accessor: "changedDate",
            visible: true,
            width: 15,
            canResize: true,
        },
        {
            Header: "Updated Time",
            accessor: "changedTime",
            visible: true,
            width: 15,
            canResize: true,
        },
    ];
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <div>
            <Header title="BankStatement > Mt940 > TransferToBs" />

            <div className="flex justify-between items-center -ml-1 pl-4">
                <div className="flex items-center">
                    <SearchInput
                        // searchQuery={searchQuery}
                        // setSearchQuery={setSearchQuery}
                        // onChange={(e) => fetchBankAccount(searchQuery)}
                        placeholder="Search by Identifiers"
                    />

                    <BankStatementActions buttons={buttons} />
                </div>
                <div className="flex items-center mr-4">
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                        totalCount={totalCount}
                    />
                </div>

            </div>
            <div>
                <ReTable
                    data={data}
                    columns={columns}
                    selectedRows={selectedRows}
                // onSelectRow={handleSelectRow}
                // onSelectAll={handleSelectAll}
                //onRowDoubleClick={handleDoubleClick}
                />
            </div>

        </div>
    )
}