import React, { useState, useEffect, useCallback } from "react";
import Header from "../../../../../components/Header";
import PrimaryActions from "../../Components/ActionButton";
import {
  IoMdCheckmark,
  IoMdClose,
  IoMdCreate,
  IoMdRefresh,
  IoMdTrash,
} from "react-icons/io";
import {
  IoAddCircleOutline,
  IoMdDownload,
  IoSettingsOutline,
} from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { PrimaryActionsConfig } from "../../Components/ActionButtonConfig";
import StateProvinceServices from "../../../../../rest-services/StateProvinceServices"; // Import new service
import { useToast } from "../../../../../components/toast/toast";
import { Checkbox } from "@mui/material";
import * as XLSX from "xlsx"; // Import xlsx library
import { MdOutlineFileDownload } from "react-icons/md";
import ReusableTable from "../../Components/Table";
import ColumnSettingsPopup from "../../Components/ColumnSettingPopup";
import CustomeUpLoader from "../../../../../components/CustomeLoader";
import ReTable from "../../../../../components/Table";
import SearchInput from "../../../../../components/SearchInput";
import Footer from "../../../../../components/footer";
import Pagination from "../../../../../components/PageNation";
import { debounce } from "lodash";
import UserService from "../../../../../rest-services/UserServices";

export default function StateprovinceScreen() {
  const { iconId } = useParams();
  const [showExtraIcons, setShowExtraIcons] = useState(false);
  const [stateProvinces, setStateProvinces] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [stateCode, setStateCode] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false); // Manage "Select All" checkbox state
  const { toast } = useToast();
  const [filteredStateprovinces, setFilteredStateprovinces] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // Manage search input
  const navigate = useNavigate();
  const [isSettingsPopupOpen, setIsSettingsPopupOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [rolesPermissions, setRolesPermissions] = useState([]);
  const [assignee, setAssignee] = useState({ id: "", username: "" });
const [alert, setAlert] = useState(null);

  useEffect(() => {
    fetchAssignee();
  }, []);

const fetchAssignee = async () => {
  try {
    const userDetailString = localStorage.getItem("userDetail");

    if (userDetailString) {
      const userDetail = JSON.parse(userDetailString);
      const assigneeData = {
        id: userDetail.id || "",
        assigner: userDetail.username || "",
        clientId: userDetail.clientId || "",
        assignerId: userDetail.userId || "",
      };
      console.log("assigneeData is", assigneeData);

      setAssignee(assigneeData);

      // Log the id and assigner values to the console
      console.log("Assignee ID:", assigneeData.id);
      console.log("Assigner:", assigneeData.assigner);
      console.log("Assigner userId:", assigneeData.assignerId);
      console.log("Assigner clientId:", assigneeData.clientId);
    } else {
      setAlert({
        severity: "error",
        message: "No user detail found in session storage.",
      });
    }
  } catch (error) {
    setAlert({
      severity: "error",
      message: `Error fetching user details: ${error.message}`,
    });
  }
};

  useEffect(() => {
    if (assignee.assignerId) {
      console.log("Assigner ID has changed:", assignee.assignerId);
      fetchUserPermissionDetails();
    }
  }, [assignee.assignerId]);

const fetchUserPermissionDetails = async () => {
  try {
    const data = { userId: assignee.assignerId };
    console.log("Request Data:", data);

    const response = await UserService.saveContactDetails(
      `${process.env.REACT_APP_BASE_URL}/get-users-permissions-by-userId`,
      data
    );
    console.log("user permissions details", response);
    const responseData = response.data;
    setRolesPermissions(responseData.rolesPermissionsTables || []); // Save permission details
    console.log("Set Permission details for userscreen", responseData.rolesPermissionsTables);
  } catch (error) {
    console.error(error.message); // Set error message
  } finally {
    setLoading(false); // Reset loading state
  }
};


  // const handleDelete = async (id) => {
  //   const confirmed = window.confirm(
  //     "Are you sure you want to delete this stateprovinces?"
  //   );
  //   if (confirmed) {
  //     setLoading(true);
  //     try {
  //       await StateProvinceServices.deleteStateProvince(id);
  //       toast("success", "stateprovinces deleted successfully");
  //       // Refetch stateProvinces after deletion
  //       fetchStateProvinces();
  //     } catch (error) {
  //       console.error("Error deleting stateprovinces:", error);
  //       toast("error", "Failed to delete stateprovinces");
  //     } finally {
  //       setLoading(false);
  //     }
  //   }
  // };
  const columns = [
    {
      Header: "State",
      accessor: "state",
      visible: true,
      width: 200,
      canResize: true,
      alignment: "left"
    },
    {
      Header: "State Name",
      accessor: "State_Name",
      visible: true,
      width: 200,
      canResize: true,
      alignment: "left"
    },
    {
      Header: "Country Code",
      accessor: "Country_Code",
      visible: true,
      width: 200,
      canResize: true,
      alignment: "left"
    },
    {
      Header: "Created By",
      accessor: "created_by",
      visible: true,
      width: 200,
      canResize: true,
      alignment: "left"
    },
    {
      Header: "Created Date",
      accessor: "creationDate",
      visible: true,
      width: 200,
      canResize: true,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`; // Change here
      },
    },
    {
      Header: "Created Time",
      accessor: "creationTime",
      visible: true,
      width: 200,
      canResize: true,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        return date.toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
        });
      },
    },
    {
      Header: "Updated By",
      accessor: "changed_by",
      visible: true,
      width: 200,
      canResize: true,
      alignment: "left"
    },
    {
      Header: "Updated Date",
      accessor: "changedDate",
      visible: true,
      width: 200,
      canResize: true,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`; // Change here
      },
    },
    {
      Header: "Updated Time",
      accessor: "changedTime",
      visible: true,
      width: 200,
      canResize: true,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        return date.toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
        });
      },
    },
  ];
  const [columnSettings, setColumnSettings] = useState(columns);
  const [pageSize, setPageSize] = useState(15); // Default page size

  const fetchStateProvinces = async (page, searchQuery = "") => {
    setLoading(true);
    try {
      const response = await StateProvinceServices.getAllStateprovincewithPage(
        pageSize,
        page,
        searchQuery
      );
      let { state, totalCount } = response.data.data;
      if (!state) {
        state = [];
      } else if (!Array.isArray(state)) {
        state = [state];
      }
      setFilteredStateprovinces(state);
      setTotalCount(totalCount);
      setTotalPages(Math.ceil(totalCount / pageSize));
    } catch (error) {
      console.error("Error fetching StateProvince data", error);
    } finally {
      setLoading(false);
    }
  };

  // Debounced fetch function (to avoid too many API calls)
  const debouncedFetchStateProvinces = useCallback(
    debounce((page, query) => {
      fetchStateProvinces(page, query);
    }, 500), // 500ms debounce delay
    []
  );

  // UseEffect to trigger API call on query or page change
  useEffect(() => {
    debouncedFetchStateProvinces(currentPage, searchQuery);
    // Cleanup debounce on unmount
    return () => debouncedFetchStateProvinces.cancel();
  }, [currentPage, searchQuery, debouncedFetchStateProvinces]);
  const handleShowAll = () => {
    setPageSize(totalCount); // Set page size to total count to display all records
    setCurrentPage(1); // Reset to the first page
    fetchStateProvinces(1, searchQuery); // Fetch data with updated page size
  };
  const modifiedColumns = columnSettings
    .filter((col) => col.visible)
    .map((col) => ({
      ...col,
      width: col.width, // This should hold the updated width
    }));

  const handleUpdateColumnSettings = (updatedSettings) => {
    setColumnSettings(updatedSettings);
  };

  const handleSettingsButtonClick = () => {
    setIsSettingsPopupOpen(true);
  };
  const handleSelectRow = (id) => {
    handleCheckboxChange(id);
  };

  const handleSelectAll = () => {
    handleSelectAllChange();
  };
  const extraButtons = [
    {
      icon: IoMdCreate,
      onClick: () => {
        navigateToForm(stateCode);
      },
      title: "Edit StateProvince",
      disabled: selectedRows.length === 0,
    },
  ];

  const handleDownload = () => {
    // Create an array of data to download
    const dataToExport = filteredStateprovinces.map((state) => ({
      State: state.state,
      "State Name": state.State_Name,
      "Country Code": state.Country_Code,
      "Created By": state.created_by,
      "Created Date": state.creationDate
        ? new Date(state.creationDate).toLocaleDateString()
        : "",
      "Created Time": state.creationTime
        ? new Date(state.creationTime).toLocaleTimeString()
        : "",
      "Changed By": state.changed_by,
      "Changed Date": state.changedDate
        ? new Date(state.changedDate).toLocaleDateString()
        : "",
      "Changed Time": state.changedTime
        ? new Date(state.changedTime).toLocaleTimeString()
        : "",
    }));

    // Create a new worksheet from the data
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);

    // Create a new workbook and add the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "StateProvinces");

    // Generate Excel file and trigger download
    XLSX.writeFile(workbook, "StateProvinces_Data.xlsx");
  };
  const handleReload = async () => {
    setLoading(true);  // Start loading
    try {
      await fetchStateProvinces();  // Fetch users
    } catch (error) {
      console.error('Error reloading users:', error);
    } finally {
      setLoading(false);  // Stop loading
    }
  };
  const existingButtons = PrimaryActionsConfig.screen1.map((button) => ({
    ...button,
    title:
      button.icon === IoAddCircleOutline
        ? "Add StateProvince"
        : button.icon === MdOutlineFileDownload
        ? "Download"
        : button.icon === IoSettingsOutline
        ? "Settings"
            : button.icon === IoMdRefresh
      ? "Refresh"
        : button.title || "", // If a title already exists, use it, otherwise empty
    onClick:
      button.icon === IoAddCircleOutline
        ? () => handleNavigate("/state-province-add")
        : button.icon === MdOutlineFileDownload
        ? handleDownload
        : button.icon === IoSettingsOutline
        ? handleSettingsButtonClick
        : button.icon === IoMdRefresh
        ? handleReload
        : button.onClick || (() => {}),
  }));
  const actionButtons = existingButtons.concat(
    extraButtons.map((button) => ({
      ...button,
      disabled: selectedRows.length === 0, // Disable button if no users are selected
      onClick: selectedRows.length > 0 ? button.onClick : null, // Enable onClick only if users are selected
    }))
  );
  const handleNavigate = (path) => {
    navigate(path);
  };

  

  // Handle individual checkbox change
  const handleCheckboxChange = (id) => {
    const updatedSelectedRows = selectedRows.includes(id)
      ? selectedRows.filter((rowId) => rowId !== id)
      : [...selectedRows, id];

    setSelectedRows(updatedSelectedRows);
    setStateCode(id);
    setShowExtraIcons(updatedSelectedRows.length > 0);
  };

  // Handle "Select All" checkbox change
  const handleSelectAllChange = () => {
    if (isAllSelected) {
      setSelectedRows([]); // Deselect all
    } else {
      const allIds = filteredStateprovinces.map((item) => item.id); // Select all IDs
      setSelectedRows(allIds);
    }
    setIsAllSelected(!isAllSelected); // Toggle select all state
  };

  const navigateToForm = (id) => {
    navigate(`/state-province-add/${id}`);
  };
  const handleDoubleClick = (id) => {
    const hasPermission = rolesPermissions.some(
      (permission) => permission.permissionsId === 3 && permission.iconId === parseInt(iconId)
    );

    console.log("Check user permissions with iconId", hasPermission);

    if (hasPermission) {
    navigate(`/state-province-view/${id}`, { state: { mode: "view" } });
    console.log("Navigated to user view screen.");
  } else {
    console.log(
      "No permission with permissionsId === 3 and matching iconId, navigation skipped."
    );
  }
  };

  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === "Global Data") {
      navigate("/2/master-data");
    } else if (crumb.title === "State/Province") {
      navigate("/2/master-data/2/state-province");
    } else if (crumb.title === "Primary Data") {
      console.log("Navigating to /country");
      navigate("/2/master-data");
    } else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };
  if (loading)
    return (
      <div>
      </div>
    );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  return (
    <div>
      <Header
        title={"Primary Data > Global Data > State/Province"}
        onBreadcrumbClick={handleBreadcrumbClick}
        backRoute={"/2/master-data"}
      />
      <div>
        <div className="flex justify-between items-center -ml-1 pl-4">
        <div className="flex items-center">

        <SearchInput
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              placeholder="Search by State"
            />


          <PrimaryActions
          icons={iconId}
            buttons={actionButtons}
            selectedCount={selectedRows.length}
            isExtraButtonsVisible={showExtraIcons}
            selectIds={selectedRows}
          />
          </div>
          <div className="flex items-center mr-4">

          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            totalCount={totalCount}
            onShowAll={handleShowAll}
          />
          
        </div>
        </div>

        <div>
         
        </div>

        <ReTable
          data={filteredStateprovinces}
          columns={modifiedColumns}
          selectedRows={selectedRows}
          onSelectRow={handleSelectRow}
          onSelectAll={handleSelectAll}
          onRowDoubleClick={handleDoubleClick}
        />
      </div>
      {isSettingsPopupOpen && (
        <ColumnSettingsPopup
          columns={columnSettings}
          onClose={() => setIsSettingsPopupOpen(false)}
          onUpdate={handleUpdateColumnSettings}
        />
      )}
      <Footer />
    </div>
  );
}


