
import CustomButton from "../../../../components/CustomButton";
import Footer from "../../../../components/footer";
import HoverButton from "../../../../components/HoverButton";
import Header from "../../../../components/Header";
import { useState } from "react";
import { IoIosList } from "react-icons/io";
import Tabs from "../../../../components/Tabs";
import { useNavigate } from "react-router-dom";

export default function AddStatementAnalyzerBs() {
    //Tabs
    const [activeTab, setActiveTab] = useState("masterdata");
    const [isValidationTriggered, setIsValidationTriggered] = useState(false);
    const tabs = [
        { value: "masterdata", label: "Master Data" },
        { value: "yearmonth", label: "Year/Month" },
        { value: "statuscontrols", label: "Status/Controls" },
    ]

    const errorss = {

    };
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    const navigate = useNavigate()
    const handleBreadcrumbClick = (crumb) => {
        console.log("Breadcrumb clicked:", crumb.title);
        if (crumb.title === "Statement Identifier") {
            navigate("/statement-identifire");
        } else if (crumb.title === "Bank Statements") {
            navigate("/bank-statements");
        } else if (crumb.title === "MT940") {
            navigate("/bank-statements");
        } else if (crumb.path) {
            navigate(crumb.path); // Navigate to the path specified in the crumb
        }
    };
    return (

        <div>
            <div><Header
                title={`Bank Statements > MT940 > Statment Analyser 2 `} onBreadcrumbClick={handleBreadcrumbClick} /></div>

            <Tabs
                tabs={tabs}
                activeTab={activeTab}
                errors={errorss}
                onTabClick={handleTabClick}
                isValidationTriggered={isValidationTriggered}
            />
            {activeTab === "masterdata" && (
                <div>
                    <div className="m-2 flex flex-col space-y-8 col-span-1 p-10 border  rounded-md">
                        <label className="flex items-center">
                            <span className="w-48 font-small text-gray-500">Process Name</span>
                            <span className="ml-4">:</span>
                            <input
                                type="text"
                                // onChange={(e) => setProcessName(e.target.value)}
                                className={`border  border-gray-300
                             p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
                                required
                            />
                            <button>
                                <IoIosList className="ml-6 border  h-6 w-6  hover hover:border-customBlue" onClick={() => navigate("/statement-analyser-bs")} />
                            </button>
                        </label>
                        <div className="flex flex-row space-x-[11%] row-span-2">
                            <label className="flex items-center">
                                <span className="w-48 font-small text-gray-500">Statment Format</span>
                                <span className="ml-4">:</span>
                                <input
                                    type="text"
                                    // onChange={(e) => setProcessName(e.target.value)}
                                    className={`border border-gray-300 p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
                                    required
                                />

                            </label>
                            <label className="flex items-center">
                                <span className="w-48 font-small text-gray-500">Statment Type</span>
                                <span className="ml-4">:</span>
                                <input
                                    type="text"
                                    // onChange={(e) => setProcessName(e.target.value)}
                                    className={`border border-gray-300 p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
                                    required
                                />
                            </label>
                        </div>
                    </div>
                    {/* Second Block */}
                    <div className="flex flex-col space-y-8 col-span-1 p-10 border rounded-md mt-2">
                        <div className="flex flex-row space-x-[35%] row-span-2">
                            <label className="flex items-center">
                                <span className="w-48 font-small font-bold text-gray-500">Company</span>
                            </label>
                            <label className="flex items-center">
                                <span className="w-48 font-small font-bold text-gray-500">Bank</span>
                            </label>
                        </div>
                        <div className="flex flex-row space-x-[11.5%] row-span-2">
                            <label className="flex items-center">
                                <span className="w-48 font-small text-gray-500">Company Code</span>
                                <span className="ml-4">:</span>
                                <input
                                    type="text"
                                    className={`border border-gray-300 p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
                                    required
                                />
                            </label>
                            <label className="flex items-center">
                                <span className="w-48 font-small text-gray-500">Bank</span>
                                <span className="ml-4">:</span>
                                <input
                                    type="text"
                                    className={`border border-gray-300 p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
                                    required
                                />
                            </label>
                        </div>
                        <div className="flex flex-row space-x-[11.5%] row-span-2">
                            <label className="flex items-center">
                                <span className="w-48 font-small text-gray-500">Legal Entity</span>
                                <span className="ml-4">:</span>
                                <input
                                    type="text"
                                    className={`border border-gray-300 p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
                                    required
                                />
                            </label>
                            <label className="flex items-center">
                                <span className="w-48 font-small text-gray-500">Bank ID</span>
                                <span className="ml-4">:</span>
                                <input
                                    type="text"
                                    className={`border border-gray-300 p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
                                    required
                                />
                            </label>
                        </div>
                        <div className="flex flex-row space-x-[11.5%] row-span-2">
                            <label className="flex items-center">
                                <span className="w-48 font-small text-gray-500">Bu Group</span>
                                <span className="ml-4">:</span>
                                <input
                                    type="text"
                                    className={`border border-gray-300 p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
                                />
                            </label>
                            <label className="flex items-center">
                                <span className="w-48 font-small text-gray-500">Bank Account ID</span>
                                <span className="ml-4">:</span>
                                <input
                                    type="text"
                                    className={`border border-gray-300 p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
                                />
                            </label>
                        </div>
                        <div className="flex flex-row space-x-[11.5%] row-span-2">
                            <label className="flex items-center">
                                <span className="w-48 font-small text-gray-500">Country</span>
                                <span className="ml-4">:</span>
                                <input
                                    type="text"
                                    className={`border border-gray-300 p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
                                />
                            </label>
                            <label className="flex items-center">
                                <span className="w-48 font-small text-gray-500">Bank Country</span>
                                <span className="ml-4">:</span>
                                <input
                                    type="text"
                                    className={`border border-gray-300 p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
                                />
                            </label>
                        </div>
                        <div className="flex flex-row space-x-[34.9%] row-span-2">
                            <label className="flex items-center">
                                <span className="w-48 font-small text-gray-500"></span>

                            </label>
                            <label className="flex items-center">
                                <span className="w-48 font-small text-gray-500">Bank Swift</span>
                                <span className="ml-4">:</span>
                                <input
                                    type="text"
                                    className={`border border-gray-300 p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
                                />
                            </label>
                        </div>
                    </div>
                </div>)}
            {activeTab === "yearmonth" && (<div className=" m-2 flex flex-col space-y-8 col-span-1 p-10 border  rounded-md mt-2">
                <div className="flex flex-row  space-x-[35%] row-span-2 ">
                    <label className="flex items-center">
                        <span className="w-48 font-small font-bold  text-gray-500">Transaction</span>

                    </label>
                    <label className="flex items-center">
                        <span className="w-48 font-small font-bold text-gray-500">Imported Dates</span>

                    </label>
                </div>
                <div className="flex flex-row  space-x-[7.5%] row-span-2 ">
                    <label className="flex items-center">
                        <span className="w-48 font-small text-gray-500">Year</span>
                        <span className="ml-4">:</span>
                        <input
                            type="text"
                            // onChange={(e) => setProcessName(e.target.value)}
                            className={`border border-gray-300 p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
                            required
                        />
                    </label>
                    <label className="flex items-center">
                        <span className="w-48 font-small text-gray-500">Year</span>
                        <span className="ml-4">:</span>
                        <input
                            type="text"
                            // onChange={(e) => setProcessName(e.target.value)}
                            className={`border border-gray-300 p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
                            required
                        />
                    </label>
                </div>
                <div className="flex flex-row  space-x-[7.5%] row-span-2 ">
                    <label className="flex items-center">
                        <span className="w-48 font-small text-gray-500">Month</span>
                        <span className="ml-4">:</span>
                        <input
                            type="text"
                            // onChange={(e) => setProcessName(e.target.value)}
                            className={`border border-gray-300 p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
                            required
                        />
                    </label>
                    <label className="flex items-center">
                        <span className="w-48 font-small text-gray-500">Month</span>
                        <span className="ml-4">:</span>
                        <input
                            type="text"
                            // onChange={(e) => setProcessName(e.target.value)}
                            className={`border border-gray-300 p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
                            required
                        />
                    </label>
                </div>
                <div className="flex flex-row  space-x-[7.5%] row-span-2 ">
                    <label className="flex items-center">
                        <span className="w-48 font-small text-gray-500">Valu date</span>
                        <span className="ml-4">:</span>
                        <input
                            type="text"
                            // onChange={(e) => setProcessName(e.target.value)}
                            className={`border border-gray-300 p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
                            required
                        />
                    </label>
                    <label className="flex items-center">
                        <span className="w-48 font-small text-gray-500">Value date</span>
                        <span className="ml-4">:</span>
                        <input
                            type="text"
                            // onChange={(e) => setProcessName(e.target.value)}
                            className={`border border-gray-300 p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
                            required
                        />
                    </label>
                </div>
            </div>)}
            {activeTab === "statuscontrols" && (
                <div>
                    <div className="m-2 flex flex-col space-y-8 col-span-1 p-7 border  rounded-md">
                        <label className="flex items-center">
                            <span className="w-48 font-small font-bold  text-gray-500">Status</span>
                        </label>
                        <label className="flex items-center">
                            <span className="w-48 font-small text-gray-500">Imported Status</span>
                            <span className="ml-4">:</span>
                            <input
                                type="text"
                                // onChange={(e) => setProcessName(e.target.value)}
                                className={`border  border-gray-300
                             p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
                                required
                            />
                        </label>
                        <div className="flex flex-row space-x-8 col-span-1">
                            <label className="flex items-center">
                                <span className="w-48 font-small text-gray-500">Transfer Status</span>
                                <span className="ml-4">:</span>
                                <input
                                    type="text"
                                    // onChange={(e) => setProcessName(e.target.value)}
                                    className={`border border-gray-300 p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
                                    required
                                />
                            </label>
                        </div>
                        <label className="flex items-center">
                            <span className="w-48 font-small text-gray-500">Uploded By</span>
                            <span className="ml-4">:</span>
                            <input
                                type="text"
                                // onChange={(e) => setProcessName(e.target.value)}
                                className={`border border-gray-300 p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
                                required
                            />
                        </label>
                    </div>
                    <div className="m-2 flex flex-col space-y-1 col-span-0 p-7 border  rounded-md">
                        <label className="flex items-center">
                            <span className="w-48 font-small font-bold  text-gray-500">Controlls</span>
                        </label>
                        <label className="flex items-center">
                            <input
                                type="checkbox"
                                // onChange={(e) => setProcessName(e.target.value)}
                                className={`accent-customBlue`}
                                required
                            />
                            <span className="m-5 w-48 font-small text-gray-500">Date/Balance</span>
                        </label>
                        <div className="flex flex-row space-x-8 col-span-1">
                            <label className="flex items-center">
                                <input
                                    type="checkbox"
                                    // onChange={(e) => setProcessName(e.target.value)}
                                    className={`accent-customBlue`}
                                    required
                                />
                                <span className=" m-5 w-48 font-small text-gray-500">Duplicate</span>
                            </label>

                        </div>
                        <label className="flex items-center">
                            <input
                                type="checkbox"
                                // onChange={(e) => setProcessName(e.target.value)}
                                className={`accent-customBlue`}
                                required
                            />
                            <span className=" m-5 w-48 font-small text-gray-500">BTC</span>
                        </label>
                    </div>
                </div>
            )}

            <Footer>
                <div>
                    <CustomButton
                        onClick={() => { }}
                        title={"Save"}
                        className="mr-2"
                    />
                    <HoverButton
                        title={"Save & Continue"}
                        className={"mr-2"}
                        onClick={() => { }}
                    />
                    <HoverButton
                        title={"Run >"}
                        className={"mr-2"}
                        onClick={() => { }}
                    />
                </div>
            </Footer>

        </div>
    )
}