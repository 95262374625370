import React, { useEffect, useState } from "react";
import NoActionLayout from "../../../../../components/NoActionLayout";
import CashFlowActions from "../../../CashFlow/Components/CashFlowActions";
import { CashFlowbuttonsConfigs } from "../../../CashFlow/Components/CashFlowbuttonsConfig";
import { CiSearch } from "react-icons/ci";
import { IoMdRefresh } from "react-icons/io";
import { Checkbox } from "@mui/material";
import Mt940Service from "../../../../../rest-services/Mt940Services";
import Pagination from "../../../../../components/PageNation";
import { MdOutlineFileDownload } from "react-icons/md";
import ReusableTable from "../../props/Table";
import { useNavigate } from "react-router-dom";
import ReTable from "../../../../../components/Table";
import Header from "../../../../../components/Header";
import SearchInput from "../../../../../components/SearchInput";
import BankStatementActions from "../../props/ActionButtons";

export default function StatementAnalyzerr() {
  const [isExpanded, setIsExpanded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const pageSize = 20;

  const [data, setData] = useState("");

  const toggleLayout = () => {
    setIsExpanded((prevState) => !prevState);
  };

  const downloadExcel = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/statement-analyser/export-data`,
        {
          method: "GET",
        }
      );

      if (!response.ok) {
        throw new Error("Error downloading file");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "statement_data.xlsx");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  const buttons = CashFlowbuttonsConfigs.screen2.map((button) => {
    let onClick;
    let title;
    if (button.icon === CiSearch) {
      onClick = toggleLayout;
      title = "toggle";
    } else if (button.icon === MdOutlineFileDownload) {
      onClick = () => downloadExcel();
      title = "download";
    } else if (button.icon === IoMdRefresh) {
      onClick = () => fetchDataForTable(currentPage);
      title = "download";
    }

    return {
      ...button,
      onClick,
      title,
    };
  });

  useEffect(() => {
    fetchDataForTable(currentPage);
  }, [currentPage]);

  const fetchDataForTable = async (page) => {
    try {
      const response = await Mt940Service.getmt940(pageSize, page);

      let { mt940, totalCount } = response.data.data;

      if (!mt940) {
        mt940 = [];
      } else if (!Array.isArray(mt940)) {
        mt940 = [mt940];
      }

      setData(mt940);
      setTotalCount(totalCount);
      setTotalPages(Math.ceil(totalCount / 20));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getSerialNumber = (index) => {
    return (currentPage - 1) * 20 + index + 1;
  };

  const columns = [
    // { Header: "S.NO", accessor: "sno", visible: true, width: 200 },
    { Header: "File Name", accessor: "file_name", visible: true, width: 200 },
    {
      Header: "MT940 Unique ID",
      accessor: "mt940_unique_id",
      visible: true,
      width: 200,
    },
    {
      Header: "Statement Type",
      accessor: "statement_type",
      visible: true,
      width: 200,
    },
    { Header: "Tags", accessor: "tags", visible: true, width: 200 },
    {
      Header: "Transaction indicator",
      accessor: "transaction_indicator",
      visible: true,
      width: 200,
    },
    {
      Header: "Corporate BIC",
      accessor: "corprate_bic",
      visible: true,
      width: 200,
    },
    { Header: "Bank BIC", accessor: "bank_bic", visible: true, width: 200 },
    { Header: "Reference", accessor: "reference", visible: true, width: 200 },
    {
      Header: "Statement Identifier",
      accessor: "statement_identifier",
      visible: true,
      width: 200,
    },
    {
      Header: "Statement No",
      accessor: "statement_no",
      visible: true,
      width: 200,
    },
    { Header: "Dr/Cr", accessor: "dr_cr", visible: true, width: 200 },
    { Header: "Fund Code", accessor: "funCode", visible: true, width: 200 },
    {
      Header: "Value Date",
      accessor: "value_date",
      visible: true,
      width: 200,
      // Cell: ({ value }) => {
      //   if (!value) return "";
      //   const date = new Date(value);
      //   return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
      // },
    },
    {
      Header: "Entry Date",
      accessor: "entry_date",
      visible: true,
      width: 200,
      // Cell: ({ value }) => {
      //   if (!value) return "";
      //   const date = new Date(value);
      //   return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
      // },
    },
    { Header: "Currency", accessor: "currency", visible: true, width: 200 },
    { Header: "Amount", accessor: "amount", visible: true, width: 200 },
    { Header: "BTC Code", accessor: "btc_code", visible: true, width: 200 },
    {
      Header: "Tag61Description1",
      accessor: "tag61_description1",
      visible: true,
      width: 200,
    },
    {
      Header: "Tag61 Reference",
      accessor: "tag61_reference",
      visible: true,
      width: 200,
    },
    {
      Header: "Tag61Description2",
      accessor: "tag61_description2",
      visible: true,
      width: 200,
    },
    {
      Header: "Tag86Description1",
      accessor: "tag81_description1",
      visible: true,
      width: 200,
    },
    {
      Header: "Tag86Description2",
      accessor: "tag81_description2",
      visible: true,
      width: 200,
    },
    {
      Header: "Tag86Description3",
      accessor: "tag81_description3",
      visible: true,
      width: 200,
    },
    {
      Header: "Tag86Description4",
      accessor: "tag81_description4",
      visible: true,
      width: 200,
    },
    {
      Header: "Tag86Description5",
      accessor: "tag81_description5",
      visible: true,
      width: 200,
    },
    {
      Header: "Tag86Description6",
      accessor: "tag81_description6",
      visible: true,
      width: 200,
    },
    { Header: "BTCE", accessor: "btce", visible: true, width: 200 },
    {
      Header: "Statement Imported Date",
      accessor: "statement_imported_date",
      visible: true,
      width: 200,
      // Cell: ({ value }) => {
      //   if (!value) return "";
      //   const date = new Date(value);
      //   return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
      // },
    },
    {
      Header: "Time Stamp",
      accessor: "time_stamp",
      visible: true,
      width: 200,
      // Cell: ({ value }) => {
      //   if (!value) return "";
      //   const date = new Date(value);
      //   return date.toLocaleTimeString("en-GB", {
      //     hour: "2-digit",
      //     minute: "2-digit",
      //     hour12: true,
      //   });
      // },
    },
    {
      Header: "Statement Imported by",
      accessor: "statement_imported_by",
      visible: true,
      width: 200,
    },
    {
      Header: "Statement Status",
      accessor: "statement_status",
      visible: true,
      width: 200,
    },
    {
      Header: "Bank Account ID",
      accessor: "bank_account_id",
      visible: true,
      width: 200,
    },
    {
      Header: "Company Code",
      accessor: "company_code",
      visible: true,
      width: 200,
    },
    { Header: "Bank", accessor: "bank", visible: true, width: 200 },
    {
      Header: "Bank Branch",
      accessor: "bank_branch",
      visible: true,
      width: 200,
    },
    { Header: "Country", accessor: "country", visible: true, width: 200 },
    {
      Header: "Bank Country",
      accessor: "bank_country",
      visible: true,
      width: 200,
    },
    {
      Header: "Imported Status",
      accessor: "imported_status",
      visible: true,
      width: 200,
    },
    { Header: "User ID", accessor: "user_id", visible: true, width: 200 },
    {
      Header: "Imported Date",
      accessor: "imported_date",
      visible: true,
      width: 200,
      // Cell: ({ value }) => {
      //   if (!value) return "";
      //   const date = new Date(value);
      //   return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
      // },
    },
    {
      Header: "Time Stamp",
      accessor: "imported_time",
      visible: true,
      width: 200,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        return date.toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });
      },
    },
    { Header: "Control", accessor: "control", visible: true, width: 200 },
  ];

  const handleDoubleClick = (id) => {
    // navigate(`/bank-view/${id}`, { state: { mode: "view" } });
  };

  const [columnSettings, setColumnSettings] = useState(columns);

  const modifiedColumns = columnSettings
    .filter((col) => col.visible)
    .map((col) => ({
      ...col,
      width: col.width,
    }));

  const handleUpdateColumnSettings = (updatedSettings) => {
    setColumnSettings(updatedSettings);
  };

  const handleSelectRow = (id) => {
    handleCheckboxChange(id);
  };

  const handleSelectAll = () => {
    handleSelectAllChange();
  };
  const handleSelectAllChange = () => {
    if (isAllSelected) {
      setSelectedRows([]);
    } else {
      const allIds = data.map((item) => item.id);
      setSelectedRows(allIds);
    }
    setIsAllSelected(!isAllSelected);
  };

  const handleCheckboxChange = (id) => {
    const updatedSelectedRows = selectedRows.includes(id)
      ? selectedRows.filter((rowId) => rowId !== id)
      : [...selectedRows, id];

    setSelectedRows(updatedSelectedRows);
    // setShowExtraIcons(updatedSelectedRows.length > 0); // Show extra icons if any row is selected
  };
  const navigate = useNavigate()
  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === "Statment Analyser") {
      navigate("/add-statement-analyser");
    } else if (crumb.title === "Bank Statements") {
      navigate("/bank-statements");
    } else if (crumb.title === "MT940") {
      navigate("/bank-statements");
    } else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };
  return (
    <div>
      <div>
        <Header title="Bank Statements > MT940 > Statment Analyser > Process List " onBreadcrumbClick={handleBreadcrumbClick} backRoute={"/bank-statements"} />
      </div>
      <div className="flex justify-between">

        {isExpanded && (
          <div className="flex mr-16 p-4">
            <button className=" mr-4 border border-blue-500 text-sm text-blue-500 py-1 px-2   rounded hover:bg-blue-100 transition duration-200 ease-in-out w-16 h-8 ">
              Reset
            </button>
            <button className="mr-4 bg-blue-500 text-sm text-white py-1 px-2   rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 h-8 ">
              Save
            </button>
          </div>
        )}
      </div>
      <div
        className={`transition-opacity duration-900 ease-in-out ${isExpanded ? "opacity-100" : "opacity-0"
          }`}
      >
        {isExpanded && (
          <div className="grid grid-cols-2 gap-2 w-full  p-4">
            <div className="grid grid-cols-2 gap-2 col-span-2 h-48 ">
              <div className="border p-4 rounded-md bg-[#f3f4f6] ">
                <div className="grid grid-cols-2 gap-x-8 gap-y-6">
                  <label className="flex items-center group">
                    <span className="w-48 text-sm font-medium text-gray-500">
                      CompanyCode
                    </span>
                    <span className="ml-4">:</span>
                    <input
                      type="text"
                      className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                      placeholder="Enter main head"
                    />
                  </label>

                  <label className="flex items-center group">
                    <span className="w-48 text-sm font-medium text-gray-500">
                      Bank ID
                    </span>
                    <span className="ml-4">:</span>
                    <input
                      type="text"
                      className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                      placeholder="Enter sub heads"
                    />
                  </label>

                  <label className="flex items-center group">
                    <span className="w-48 text-sm font-medium text-gray-500">
                      Country
                    </span>
                    <span className="ml-4">:</span>
                    <input
                      type="text"
                      className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                    />
                  </label>

                  <label className="flex items-center group">
                    <span className="w-48 text-sm font-medium text-gray-500">
                      Branch{" "}
                    </span>
                    <span className="ml-4">:</span>
                    <input
                      type="text"
                      className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                      placeholder="Enter additional info"
                    />
                  </label>
                  <label className="flex items-center group">
                    <span className="w-48 text-sm font-medium text-gray-500">
                      Bank Country
                    </span>
                    <span className="ml-4">:</span>
                    <input
                      type="text"
                      className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                    />
                  </label>

                  <label className="flex items-center group">
                    <span className="w-48 text-sm font-medium text-gray-500">
                      Account ID
                    </span>
                    <span className="ml-4">:</span>
                    <input
                      type="text"
                      className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                      placeholder="Enter additional info"
                    />
                  </label>
                </div>
              </div>
              <div className="border p-4 rounded-md bg-[#f3f4f6] ">
                <div className="grid grid-cols-2 gap-x-8 gap-y-6">
                  <label className="flex items-center group">
                    <span className="w-48 text-sm font-medium text-gray-500">
                      Value Date
                    </span>
                    <span className="ml-4">:</span>
                    <input
                      type="text"
                      className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                      placeholder="Enter main head"
                    />
                  </label>

                  <label className="flex items-center group">
                    <span className="w-48 text-sm font-medium text-gray-500">
                      Statement Imported Date
                    </span>
                    <span className="ml-4">:</span>
                    <input
                      type="text"
                      className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                      placeholder="Enter sub heads"
                    />
                  </label>

                  <label className="flex items-center group">
                    <span className="w-48 text-sm font-medium text-gray-500">
                      Uploaded By
                    </span>
                    <span className="ml-4">:</span>
                    <input
                      type="text"
                      className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                    />
                  </label>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-2 col-span-1 h-48 rounded ">
              <div className="border p-4 rounded-md bg-[#f3f4f6] space-y-8">
                <div></div>
                <label className="flex items-center group">
                  <span className="w-48 text-sm font-medium text-gray-500">
                    Cash Flow{" "}
                  </span>
                  <span className="ml-4">:</span>
                  <input
                    type="text"
                    className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 "
                  />
                </label>
                <label className="flex items-center group">
                  <span className="w-48 text-sm font-medium text-gray-500">
                    Cash Flow{" "}
                  </span>
                  <span className="ml-4">:</span>
                  <input
                    type="text"
                    className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                  />
                </label>
              </div>
              <div className="border p-4 rounded-md bg-[#f3f4f6]">
                <div className="flex ">
                  <Checkbox className="form-checkbox text-blue-500 border-blue-500 rounded transform scale-75" />
                  <p className="ml-4 mt-2 font-small text-gray-600">
                    Manually Changed
                  </p>
                </div>
                <div className="flex ">
                  <Checkbox className="form-checkbox text-blue-500 border-blue-500 rounded transform scale-75" />
                  <p className="ml-4 mt-2 font-small text-gray-600">
                    Not Assigned
                  </p>
                </div>
                <div className="flex ">
                  <Checkbox className="form-checkbox text-blue-500 border-blue-500 rounded transform scale-75" />
                  <p className="ml-4 mt-2 font-small text-gray-600">
                    Manually Changed
                  </p>
                </div>
              </div>
            </div>

            <div className="col-span-1 border p-4 rounded-md bg-[#f3f4f6]">
              <div className="flex space-x-8">
                <label className="flex items-center group">
                  <span className="w-48 text-sm font-medium text-gray-500">
                    Company Code
                  </span>
                  <span className="ml-4">:</span>
                  <input
                    type="text"
                    className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                  />
                </label>
                <label className="flex items-center group">
                  <span className="w-48 text-sm font-medium text-gray-500">
                    Company Code
                  </span>
                  <span className="ml-4">:</span>
                  <input
                    type="text"
                    className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                  />
                </label>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="p-0">
        <div className="flex justify-between items-center -ml-1 pl-4">
          <div className="flex items-center">
            <SearchInput
              // searchQuery={searchQuery}
              // setSearchQuery={setSearchQuery}
              // onChange={(e) => fetchBankAccount(searchQuery)}
              placeholder="Search by Identifiers"
            />

            <BankStatementActions buttons={buttons} />
          </div>
          <div className="flex items-center mr-4">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              totalCount={totalCount}
            />
          </div>

        </div>
        <div>
          <ReTable
            data={data}
            columns={modifiedColumns}
            selectedRows={selectedRows}
            onSelectRow={handleSelectRow}
            onSelectAll={handleSelectAll}
            onRowDoubleClick={handleDoubleClick}
          />
        </div>
      </div>
    </div>
  );
}
